import EmailType from "./EmailType";

export default function QueueType({queueType}){

    // queueType represents the type of service it is i.e hotel Queue or Visa queue etc..

    // Types stores an array of objects where objects contains=
    // name = this is used to show headings on UI
    // value = this is keyword to be passed in api as type to fetch email
    const Types = [
        {
            name:"Booking Requests",
            value:"request"
        },
        {
            name:"Booking Confirmation",
            value:"booking"
        },
        {
            name:"Booking Cancellation",
            value:"cancel"
        }
    ]
    return (
        <>
            <div className="ml1 mb2">
                <h2 className="fw-light mt4 mb2 fw-600 ">
                    {queueType.name}
                </h2>
                {
                    Types.map((item)=>{
                        return (
                            <>
                                <EmailType key={item} queueType={queueType} type={item}/>
                            </>
                        )
                    })
                }
			</div>
        </>
    )
}