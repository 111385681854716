import { useEffect, useState } from "react";
import { Button, ChipsSelect, FullModal, SearchBox } from "retro/index";


{/*
    component to select multiple client while creating an ADMIN.
*/}

const Index = ({onClose,onNext,allClients,selectedClients=[]}) => {

    const [clients,setClients] = useState(selectedClients.length>0?selectedClients:[]);
    const [filterClient,setFilterClient] = useState([]);
    const [search,setSearch] = useState('');


    useEffect(()=>{
        if(search.length>2) {
            setFilterClient(allClients.filter(i=>{
                if(i.name.toLowerCase().includes(search.toLowerCase())) {
                    return true
                } else if(i.email.toLowerCase().includes(search.toLowerCase())) {
                    return true
                }
                return false
            }))
        } else {
            setFilterClient([]);
        }
    },[search])


    return(
        <FullModal
            blank={true}
            title="Select Client"
            description=""
            onClose={onClose}
        >
          <SearchBox 
             placeholder="Enter your search criteria"
             onChange={setSearch}
          />
          <ChipsSelect 
            options={filterClient.length>0?filterClient.map(i=>i.email):allClients.map(i=>i.email)}
            onSelect={setClients}
            selected={clients}
          />

          <Button onClick={()=>{
            onNext(clients)
          }}>
             Continue
          </Button>
        </FullModal>
    )
}

export default Index;