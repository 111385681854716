import React, { useState } from 'react';
import { Loader, SidePane, StatelessTab } from 'retro/index';
import SampleBulkUploadFormat from './SampleBulkUploadFormat';
import UploadFile from './UploadFile';
import ShowUploadStatus from './ShowUploadStatus';

const index = ({ setBulkRecharge }) => {
  const [tab, setTab] = useState('Sample Format');
  const [uploading, setUploading] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [status, setStatus] = useState([]);
  const [showStatusButton, setShowStatusButton] = useState(false);
  const [uploaded, setUploaded] = useState(false);

  return (
    <div>
      <SidePane
        title="Bulk Recharge"
        tabs={
          <StatelessTab
            onClick={setTab}
            style={{ marginBottom: 'calc(-1rem - 1px)' }}
            tabs={['Sample Format', 'Upload File', 'Report Status']}
            active={tab}
          />
        }
        description="Upload a csv file with proper fields"
        onClose={() => {
          setBulkRecharge(false);
          window.location.reload();
        }}
      >
        {tab === 'Sample Format' && (
          <>
            <SampleBulkUploadFormat />
          </>
        )}
        {tab === 'Upload File' && (
          <>
            {uploading ? (
              <>
                <div
                  className="mt3"
                  style={{
                    padding: '6px 12px',
                    border: '1.25px solid #F79009',
                    borderRadius: 4,
                    marginBottom: 18,
                  }}
                >
                  <span style={{ color: '#F79009' }}>
                    <i className="fa fa-exclamation-triangle mr1" /> Please
                    don't switch the tab until the data uploading process is
                    complete.
                  </span>
                </div>
                <Loader />
              </>
            ) : (
              <UploadFile
                setUploading={setUploading}
                setShowStatusButton={setShowStatusButton}
                csvData={csvData}
                setStatus={setStatus}
                setCsvData={setCsvData}
				uploaded={uploaded}
				setUploaded={setUploaded}
              />
            )}
          </>
        )}

        {tab === 'Report Status' && (
          <>
            {!showStatusButton && (
              <div>
                <p className="mt4">Please upload a report to view status</p>
              </div>
            )}
            {showStatusButton && (
              <div>
                <ShowUploadStatus status={status} csvData={csvData} />
              </div>
            )}
          </>
        )}
      </SidePane>
    </div>
  );
};

export default index;
