import email from "@atlaskit/icon/glyph/email";
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Button, Modal, TOAST } from "retro/index";
import {get, post} from "../../../../../Network/Axios";

export default function EmailType({type,queueType}){
    const [email,setEmail] = useState('');
    // value is a state for the textarea field in modal 
    const [value,setValue] = useState()
    const [modal,setModal] = useState(false);
    const {id} = useParams();

    // load function to fetch data of emails based on type and queueType that is passed as props

    const load = id => {
		get(`/clients/queue/${id}/${queueType.value}/${type.value}`, (e, r) => {
			if (r) {
				setEmail(r);
                // we are setting value state here to prefill the textarea in modal 
                setValue(r);
			}
		})
	}
    const handleChange = (e)=>{
        setValue(e.target.value);
    }

    // useEffect to fetch emails data while component mounts
    useEffect(()=>{
        load(id);
    },[])

    return (
        <>
            <div className="border pd2 flex horizontally center-vertically mb1">
                <div className="flex-1">
                    <h3 className="flex-1">
                        {type.name}
                    </h3>
                    <p className="mt1">{email?email:"No email set"}</p>
                    <Button onClick={()=>{
                        setModal(true)
                    }} className="btn-link">
                        Update
                    </Button>
                </div>
            </div>
            {
                modal&&
				<Modal title="Update Email" description="Separate the CC emails with comma" onClose={()=>{
					setModal(false)
				}}>
                    <textarea className="border-dark" value={value} onChange={handleChange} rows={10}/>
					<Button debounce={true} className="btn-primary btn-max mt4" onClick={()=>{
                        let emails = value
                        if(!emails){
                            TOAST.error("Please Enter email");
                            return;
                        }
                        post(`/clients/queue/${id}/${queueType.value}/${type.value}`,{emails,serviceType:queueType.value},(e,r)=>{
							if(r){
								setModal(false)
								TOAST.success("Email Updated Successfully!")
                                load(id)
							}else{
								TOAST.handleError(e)
							}
						})
					}}>
						Update
					</Button>
				</Modal>
            }
        </>
    )
}