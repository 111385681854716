import React, {useCallback, useEffect, useState} from 'react'
import {
	Button,
	DateRange,
	Loader,
	Navigation,
	ScrollableDynamicTable,
	TOAST,
	TOAST as Alert,
	Wrapper
} from "../../../../../retro";
import {get} from "../../../../Network/Axios";
import OpenIcon from '@atlaskit/icon/glyph/open'
import Moment from 'moment'
import CopyIcon from "@atlaskit/icon/glyph/copy";
import {DownloadCsv} from "../../../../../retro/Utils";

export default function AllClients({}) {
	let [loading, setLoading] = useState(true);
	const [search, setSearch] = useState({
		page: 0,
		sort: 'id',
		order: 'desc',
		q: undefined
	})
	const [range, setRange] = useState(undefined)
	const [Data, SetData] = useState({
		total: 0,
		currentPage: 0,
		totalPages: 0,
		items: []
	})
	const downloadDump = () => {
		get(`/personal/flights/dump`, (e, r) => {
			if (r) {
				DownloadCsv('flights_dump.csv',r.data)
			} else {
				Alert.handleError(e)
			}
		}, {
			start: search.startDate,
			end: search.endDate
		})
	}
	const loadPage = useCallback((search) => {
		get(`/personal/flights`, (e, r) => {
			if (r) {
				SetData({
					...r
				})
				setLoading(false)
			} else {
				Alert.handleError(e)
			}
		}, {
			offset: search.page,
			sort: search.sort,
			order: search.order,
			start: search.startDate,
			end: search.endDate
		})
	}, [SetData])

	useEffect(() => {
		if (search.startDate) {
			loadPage(search)
		}
	}, [search])
	useEffect(() => {
		if (range) {
			setSearch({
				...search,
				page: 0,
				startDate: Moment(range.startDate).valueOf(),
				endDate: Moment(range.endDate).valueOf()
			})
		}
	}, [range, SetData])
	return (
		<Wrapper id="wrapper-nav" className="w-sidebar-minus">
			<Navigation title="All Flights" chips={["Perks","Flights"]} backUrl="back" description="Details of all flight bookings appears below."/>
			{
				loading ?
					<div style={{
						display: 'none'
					}}>
						<Loader/>
						<DateRange type="range" onChange={setRange}/>
					</div>
					:
					<div className="relative">

						<ScrollableDynamicTable
							onRange={setRange}
							tableStyle={{
								borderSpacing: 0
							}}
							width={150}
							setSearch={(search) => {
								setSearch({
									...search,
									page: 0
								})
							}} search={search}
							setPage={page => {
								setSearch({
									...search,
									page
								})
							}}
							data={{
								current: Data.currentPage,
								total: Data.totalPages,
								items: Data.items.map(item => {
									const click = () => {
										onClick(item)
									}
									return {
										content: item,
										render: [
											{
												class: "table-sticky-row-first",
												children: (
													<div>
														<p className="text">
															{item.transId}
														</p>
														{item.paymentId?<p className="text-small text-light flex horizontally center-vertically">
															{item.paymentId}
															<span className="ml1" onClick={()=>{
																navigator.clipboard.writeText(item.paymentId).then(function() {
																	TOAST.success("Copy Completed")
																}, function(err) {
																	TOAST.error("Could not copy text")
																});
															}}>
																<CopyIcon size="small"/>
															</span>
														</p>:undefined}
														<p className="text-small text-light">
															{Moment(item.updatedAt).format("DD MMM YYYY HH:mm a")}
														</p>
													</div>
												),
												weight: 1.6,
											},
											{
												children: (
													<div>
														<p className="text">
															{item.user.name}
														</p>
														<p className="text-small text-light">
															{item.user.email}
														</p>
													</div>
												),
												weight: 2,
											},
											{
												weight: 1.5,
												title: item.category
											},
											{
												weight: 1.5,
												children: (
													<div>
														<div>
															{
																item.ticket?item.ticket.bookingId:""
															}
														</div>
														<div>
															{
																item.ticket?item.ticket.flowId:"Not Defined"
															}
															<span className="ml1" onClick={()=>{
																navigator.clipboard.writeText(item.ticket.flowId).then(function() {
																	TOAST.success("Copy Completed")
																}, function(err) {
																	TOAST.error("Could not copy text")
																});
															}}>
																<CopyIcon size="small"/>
															</span>
														</div>
													</div>
												)
											},
											{
												weight: 1,
												title: item.status
											},
											{
												weight: 1,
												title: item.amount
											},
											{
												weight: 1,
												children:(
													<div>

														{
															item.ticket&&item.ticket.travellers.length&&
															<div>
																{item.ticket.travellers[0].fName} 	{item.ticket.travellers[0].lName}
															</div>
														}
														<div>
															{
																item.ticket?item.ticket.travellers.length>1?`+ ${item.ticket.travellers.length-1} travellers`:'':"-"
															}
														</div>
													</div>
												)
											},
											{
												weight: 2,
												children:(
													<div>

														{
															item.ticket&&item.ticket.travellers.length&&
															<div>
																{JSON.stringify(item.ticket.travellers[0].pnrs)}
															</div>
														}
														<div>
															{
																item.ticket?item.ticket.travellers.length>1?`+ ${item.ticket.travellers.length-1} travellers`:'':"-"
															}
														</div>
													</div>
												)
											},
											{
												weight: 1,
												actions: [
													{
														title: "Quick Actions",
														actions: [
															{
																title: "View",
																before: (<OpenIcon size="small"/>),
																onClick: click
															}
														]
													}
												]
											}
										]
									}
								})
							}} headers={[
							{
								class: "table-sticky-row-first",
								weight: 1.6,
								title: "Details",
							},
							{
								weight: 2,
								title: "User",
							},
							{
								weight: 1.5,
								title: "Category",
								sort: 'category'
							},
							{
								weight: 1.5,
								title: "Flow Id",
							},
							{
								weight: 1,
								title: "Status",
							},
							{
								weight: 1,
								title: "Amount",
								sort: 'amount'
							},
							{
								weight: 1,
								title: "Travellers",
							},
							{
								weight: 2,
								title: "Pnrs",
							},
							{
								weight: 1,
								title: "Actions",
								style: {
									justifyContent: "end"
								}
							}
						]}>
							<div className="flex-1">
								<Button debounce={true} onClick={downloadDump} className="btn-primary" margin="">
									Download Dump
								</Button>
							</div>
						</ScrollableDynamicTable>
					</div>
			}
		</Wrapper>
	)
}
