import React, {useEffect, useRef, useState} from 'react'
import {get, post} from "../../../../../Network/Axios";
import {Input, Loader, Modal, Pages, Seperator, Switch, TOAST} from "../../../../../../retro";
import Moment from "moment";

export default function ManageContract({id}) {
    const [data,setData] = useState({
        loading:true
    })
    const PRICE = useRef();
    const [modal,setModal] = useState(undefined)
    const load = (id) => {
        get(`/contracted/client/${id}`,(e,r)=>{
            if(r){
                setData({
                    ...r,
                    loading:false
                })
            }
        })
    }
    useEffect(()=>{
        load(id)
    },[id])
    if(data.loading){
        return  (<Loader/>)
    }
    const mapping = {}
    data.roomTypes.forEach(item=>{
        mapping[item.id]  = item;
    })
	return (
		<div className="pt4">
            {
                modal!==undefined&&
                <Modal onClose={()=>{
                    setModal(undefined)
                }} button="Add Price" onSubmit={()=>{
                    let price = parseInt(PRICE.current.value,10);
                    if(price){
                        post(`/contracted/client/${id}/price`,{
                            room:modal,
                            price,
                            onePrice:price,
                            s:Moment().valueOf(),
                            e:Moment().valueOf()
                        },(e,r)=>{
                            if(r){
                                setModal(undefined)
                                load(id)
                            }else{
                                TOAST.handleError(e)
                            }
                        })
                    }
                }} title="Room Contract" description="Enter details to map contracted price" >
                    <Input type="number" ref={PRICE} label="Price Per Night" placeholder="Enter price per night" morph={true}/>
                </Modal>
            }
            <Pages.Title title="Hotel Details" description="Details of selected hotel appears below."/>
            <div className="flex horizontally center-vertically mt4">
                <div>
                    <h3 className="fw-bold mt0">
                        {data.hotel.name}
                    </h3>
                    <p>
                        {data.hotel.address}
                    </p>
                </div>
                <img alt="hotel-image" src={data.hotel.image} style={{
                    borderRadius:25,
                    height:50,
                    width:50,
                    border:'1px solid #333',
                    marginLeft:12
                }}/>
            </div>
            <Seperator margin={4}/>
            <Pages.Title title="Client Details" description="Details of selected client appears below."/>
            <h3 className="fw-bold mt4">
                {data.client.name}
            </h3>
            <p>
                {data.client.code}
            </p>
            <Seperator margin={4}/>
            <Pages.Title className="mb4" title="Room Mapping" description="Mapping of room for this client appears below."/>
            {
                data.hotel.roomTypes.map(item=>{
                    return (
                        <div id={item.id} className="relative border rounded-sm pd2 mt2" >
                            <h3 className="fw-bold mt0">
                                {item.name}
                            </h3>
                            <p>
                                {item.mealType}
                            </p>
                            <p>
                                {item.cancellationPolicy}
                            </p>
                            <div className="mt2">
                                <p onClick={()=>{
                                    setModal(item.id)
                                }} className="btn fw-bold  btn-link btn-sm pd0">
                                    {
                                        mapping[item.id]?`${mapping[item.id]?.priceContract?.pricePerNight} per night`:"Map Room"
                                    }
                                </p>
                            </div>
                            <div className="absolute" style={{
                                right:15,
                                top:10
                            }}>
                                <Switch state={mapping[item.id]?.available||false} onChange={()=>{
                                    post(`/contracted/client/${id}/toggle`,{
                                        room:item.id,
                                    },(e,r)=>{
                                        if(r){
                                            load(id)
                                        }else{
                                            TOAST.handleError(e)
                                        }
                                    })
                                }} size="large" />
                            </div>
                        </div>
                    )
                })
            }
		</div>
	)
}
