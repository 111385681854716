import React, {useCallback, useEffect, useState} from 'react'
import {Loader, ScrollableDynamicTable, SearchBox, TOAST as Alert} from "retro";
import {get} from "App/Network/Axios";
import OpenIcon from "@atlaskit/icon/glyph/open";
import ViewRequest from "../ViewRequest";
import Moment from "moment";
import Filter from 'retro/Filter';
import FilterDropDown from 'App/Components/Filter/FilterDropDown';

export default function index() {
	let [loading, setLoading] = useState(true);
	const [id,setId] = useState(undefined)
	const [sort,setSort] = useState("createdAt")
	const [search, setSearch] = useState({
		page: 0,
		sort: 'createdAt',
		order: 'desc'
	})
	const [Data, SetData] = useState({
		total: 0,
		currentPage: 0,
		totalPages: 0,
		items: []
	})
	const [filterModal,setFilterModal] = useState(false);
	const [filter, setFilter] = useState({
		client: [],
	});
	// const loadPage = useCallback((search) => {
	// 	get(`/meta/train/assigned`, (e, r) => {
	// 		if (r) {
	// 			SetData({
	// 				...r,
	// 				total:r.total||Data.total,
	// 				totalPages:r.totalPages||Data.totalPages
	// 			})
	// 			setLoading(false)
	// 		} else {
	// 			Alert.handleError(e)
	// 		}
	// 	}, {
	// 		offset: search.page,
	// 		order: search.order,
	// 		q:search.q,
	// 		sort : search.sort
	// 	})
	// }, [Data,SetData])
	const loadPage = useCallback(
		(search) => {
			const clients = filter.client.map(client => client.id).join(',');
	
		  get(
			`/meta/train/assigned`,
			(e, r) => {
			  if (r) {
				SetData({
				  ...r,
				  total: r.total || Data.total,
				  totalPages: r.totalPages || Data.totalPages,
				});
				setLoading(false);
			  } else {
				Alert.handleError(e);
			  }
			},
			{
			  offset: search.page,
			  order: search.order,
			  q: search.q,
			  sort : search.sort,
			  client: clients.length > 0 ? clients : undefined
			}
		  );
		},
		[Data, SetData, filter.client]
	  );

	useEffect(() => {
		loadPage(search)
	}, [search, filter.client])
	useEffect(()=>{
		setSearch({
			...search,
			page : 0,
			sort
		})
	},[sort])
	if(loading){
		return (<Loader/>)
	}
	return (
		<div>
			{/* <SearchBox onChange={q=>{
				setSearch({
					...search,
					page: 0,
					q
				})
			}} placeholder="Enter your search criteria"/> */}
		<Filter
			margin="0 -4rem"
			onSearch={(q) => {
				setSearch({
					...search,
					page: 0,
					q,
				})
			}}
			onFilter={() => {
				setFilterModal(true)
			}}
		/>

		<FilterDropDown
			shown={filterModal}
			filters={filter}
			setFilters={setFilter}
			choices={{
				client: [],
			}}
			types={['Client']}
			onClose={() => {
				setFilterModal(false);
			}}
		/>
			<div className="mt4">
				<p>
					Sort By
				</p>
				<div className="flex horizontally center-vertically mt1 mb2">
					<div  onClick={()=>{
						setSort('createdAt')
					}} className={`chip ${sort==="createdAt"?'active chip-active':''}`}>
						Created At
					</div>
					<div onClick={()=>{
						setSort('travelDate')
					}} className={`chip ${sort!=="createdAt"?'active  chip-active':''}`}>
						Travel Date
					</div>
				</div>
			</div>
			<ScrollableDynamicTable
				setSearch={(search) => {
					setSearch({
						...search,
						page: 0
					})
				}} search={search}
				tableStyle={{
					borderSpacing: 0
				}}
				setPage={page => {
					setSearch({
						...search,
						page
					})
				}}
				data={{
					current: Data.currentPage,
					total: Data.totalPages,
					items: Data.items.map(item => {
						const click = () => {
							setId(item.queueId)
						}
						return {
							content: item,
							render: [
								{
									children: (
										<div>
											<p className="text">
												{
													item.requestId
												}
											</p>
											<p className="text">
												{item.request.origin} ({item.request.attrs.from||"-"})  - {item.request.destination} ({item.request.attrs.to||"-"})
											</p>

											<p className="text-small text-light">
												Quota: {item.request.attrs.quota||"-"}
											</p>
											<p className="text-small text-light">
												Train: {item.request.attrs.train||"-"}
											</p>
										</div>
									),
									weight: 1.5,
								},
								{
									weight: 1.5,
									children: (
										<div>
											<p>
												{item.client.clientCode}
											</p>
											<p className="text-small">
												{item.guests[0].name}
											</p>
											<p className="text-small">
												{item.guests[0].email}
											</p>
										</div>
									)
								},
								{
									weight: 1,
									title: Moment(item.request.date,"DD-MM-YYYY").format("DD MMM YYYY")
								},
								{
									weight: 1,
									title: Moment(item.createdAt).format("DD MMM YYYY HH:mm a")
								},
								{
									weight: 1,
									buttons: [
										{
											title: "View",
											className: 'btn-secondary btn-sm',
											before: (<OpenIcon size="small"/>),
											onClick:click
										}
									],
									actions: [
										{
											title: "Quick Actions",
											actions: [
												{
													title: "View",
													before: (<OpenIcon size="small"/>),
													onClick:click
												}
											]
										}
									]
								}
							]
						}
					})
				}} headers={[
				{
					weight: 1.5,
					title: "Details",
				},
				{
					weight: 1.5,
					title: "Client",
				},
				{
					weight: 1,
					title: "Travel Date",
				},
				{
					weight: 1,
					title: "Requested Date",
				},
				{
					weight: 1,
					title: "Actions",
					style: {
						justifyContent: "end"
					}
				}
				]}/>
			{
				id!==undefined && <ViewRequest  id={id} onClose={()=>{
					setId(undefined)
					loadPage(search)
				}}/>
			}
		</div>
	)
}
