import React from 'react';
import { NavDivider,Navigation } from '../../../../../retro';
import { useSelector } from 'react-redux';
import { ReportRouteCard } from '../All/routes';
import ROLES from 'App/Constants/Roles';
import useRoleHook from 'App/Hooks/useRoleHook';

export default function index() {
  // Origin Code
  const { code } = useSelector((data) => ({
    code: data?.auth?.config?.origin?.code || '',
  }));
  const isReports = useRoleHook(ROLES.REPORTS);
  const isSupport = useRoleHook(ROLES.SUPPORT);
  // show PERKS for CLRTRP only
  const routes = []
  if (isReports){
    routes.push(
      {
        title: 'BOS File',
        desc: 'Traveller wise bos file',
        path: '/app/apps/meta/bus/bos',
        btnLabel: 'View File',
        codes: [],
      },
      {
        title: 'Perks BOS File',
        desc: 'Traveller wise perks bos file',
        path: '/app/apps/meta/bus/bos/perks',
        btnLabel: 'View File',
        codes: ['CLRTRP'],
      },
    )
  }

  if(isSupport){
    routes.push(
      {
        title: 'Bus Refund Queue',
        desc: 'Bus Refund Queue',
        path: '/app/apps/meta/bus/queue/refunds',
        btnLabel: 'View Queue',
        codes: [],
      },
      {
        title: 'Bookings',
        desc: 'All bus bookings appears here',
        path: '/app/apps/meta/bus/bookings',
        btnLabel: 'View Bookings',
        codes: [],
      },
      {
        title: 'Booking Queue',
        desc: 'Bus booking queue appears here',
        path: '/app/apps/meta/bus/queue/bookings',
        btnLabel: 'View Queue',
        codes: [],
      },
    )
  }
  
  return (
    <div>
      <Navigation
        backUrl=""
        title="Bus Bookings"
        description="All bus queue appears below."
      />
      <NavDivider />
      <div className="card-container mt4">
        {routes.map(
          (report) =>
            // return only if noone  or the current one is not restricted
            (report.codes.length === 0 || report.codes.includes(code)) ? (
              <ReportRouteCard reportRoute={report} />
            ):<></>
        )}
      </div>
    </div>
  );
}
