/**
 * Author[Shubham Rawat]
 * Version 1.0.0
 * 26th December | 1.0.0 | Shubham Rawat | created a overview compnent to show the data and graphs 
*/
import React from 'react';
import ToggleButtonGroup from 'App/Components/ToggleButtonGroup';
import { Graphs } from 'retro/index';
import flightIcon from "./flight.svg"
import busIcon from "./bus.svg"
import trainIcon from "./train.svg"
import hotelIcon from "./hotel.svg"

const Overview = ({ data = {}, toggleHandler }) => {
  return (
    <div className="flex vertically w-100 border rounded-sm mt2 mb2 pd2">
      <div className="flex justify-between w-100 pd1">
        <h2>Overview</h2>
        <ToggleButtonGroup 
          options={['Agency', 'Reseller']} 
          defaultValue='Agency' 
          onSwitch={toggleHandler} 
        />
      </div>
      <div className="flex w-100 flex-wrap justify-between mt2">
        {Object.keys(data).map((type) => {
          // setting the icons conditionally
          let icon;
          switch (type) {
            case 'flight':
              icon=flightIcon
              break;
            case 'train':
              icon=trainIcon
              break;
            case 'bus':
              icon=busIcon
              break;
            case 'hotel':
              icon=hotelIcon
              break;
            default:
              icon=flightIcon
              break;
          }
        return(
          <OverViewCard
              icon={icon}
              name={type}
              amount={data[type].amount}
              total={data[type].total}
            />
          )
        })}
      </div>
    </div>
  );
};

const OverViewCard = ({ icon, name, amount, total }) => {
  return (
    <div
      className="flex-1 pd1 rounded-md mg1"
      style={{ border: '1px solid #E4E7EC' }}
    >
      <img src={icon} style={{width:32}} className="ml2 mb1"/>
      <div className='ml2 mb1 uppercase'>{name}</div>
      <div className='ml2 mb1'>
        <span className='bold'>₹ {amount}</span>
        <span className='ml2 pl2 border-left'>{total}</span>
      </div>
    </div>
  );
};

export const VisChart = ({data})=>{
  return <div className="flex w-100 mt2 mb2">
    <Graphs.DonutGraph 
      title={"Service Charges"} 
      labels={Object.keys(data).map((type) => `${type.toUpperCase()} - ₹${data[type].serviceCharge.toFixed(2)}`)} 
      series={Object.keys(data).map((type) => data[type]?.serviceCharge || 0)}
      className="mr1"
      />
    <Graphs.DonutGraph 
      title={"Cancellation Service Charges"} 
      labels={Object.keys(data).map((type) => `${type.toUpperCase()} - ₹${data[type].cancellationCharge.toFixed(2)}`)} 
      series={Object.keys(data).map((type) => data[type]?.cancellationCharge || 0)}
    />
  </div>
} 

export default Overview;
