import React, { useEffect, useState } from 'react';
import {
  Button,
  DateRange,
  Loader,
  ScrollableDynamicTable,
  TOAST,
} from 'retro';
import Moment from 'moment';
import { get } from 'App/Network/Axios';
import { DownloadCsv } from 'retro/Utils';
import Graph from './graph-stats-circle.svg';
import RBAC from 'App/HOCs/RBAC';
import ROLES from 'App/Constants/Roles';

export default function index() {
  const [Dates, setDates] = useState();
  const [loading, setLoading] = useState(false);
  const [Data, SetData] = useState({
    total: 0,
    currentPage: 0,
    totalPages: 0,
    items: [],
  });
  const downloadData = () => {
    setLoading(true);
    get(
      `/meta/flight/perks/all`,
      (e, r) => {
        if (e) {
          setLoading(false);
          console.log(e);
          TOAST.error('Unable to download');
          return;
        }
        let data = [];
        let headers = [
          'Id',
          'Provider',
          'Category',
          'Booking Id',
          'Status',
          'Provider Id',
          'Payment Type',
          'Amount',
          'Ledger Id',
          'Ledger Amount',
          'Ledger Balance',
          'Reseller',
          'Svc Invoice',
          'Svc Invoice Irn',
          'Flight Invoice Irn',
          'Flight Invoice',
          'Voucher',
          'Service Charges',
          'Service Charge Hsn',
          'Flight Hsn',
          'CT Svc Gstin',
          'CT Invoice Gstin',
          'User Gstin',
          'Client Code',
          'Client Name',
          'Created On',
          'Updated On',
          'Origin',
          'Destination',
          'Departure Date',
          'Arrival Date',
          'Departure Airline',
          'Departure Flight Code',
          'Departure Flight Number',
          'Traveller Name',
          'Traveller Gender',
          'Traveller Type',
          'PNR',
          'Refund Amount',
          'Base Fare',
          'Total Taxes',
          'Gst Charges',
          'Other Misc',
        ];
        data.push(headers);
        r.bookings.map((item) => {
          if (item.ticket) {
            if (item.ticket.segments) {
              item.ticket.segments.forEach((segment) => {
                item.ticket.travellers.forEach((traveller) => {
                  data.push([
                    item.id,
                    item.provider,
                    item.category,
                    item.transId,
                    item.status,
                    item.ticket?.flowId || item.providerId || '-',
                    item.paymentType,
                    item.amount,
                    item.ledger?.ledgerId || '-',
                    item.ledger?.amount || '-',
                    item.ledger?.balance || '-',
                    item.invoiceConfig?.reseller ? 'Yes' : 'No',
                    item.invoiceConfig?.serviceResponse?.response?.data?.Irn ||
                      '-',
                    item.invoiceConfig?.serviceInvoice || '-',
                    item.invoiceConfig?.invoiceResponse?.response?.data?.Irn ||
                      '-',
                    item.invoiceConfig?.invoice || '-',
                    item.invoiceConfig?.voucher || '-',
                    item.invoiceConfig?.serviceCharges || '0',
                    '998551',
                    '996425',
                    '29AAHCC1775A1ZT',
                    '29AAHCC1775A1ZT',
                    item.invoiceConfig?.userGst?.gstin || '-',
                    item.client.code,
                    item.client.name,
                    Moment(item.createdAt).format('DD MMM YYYY HH:mm a'),
                    Moment(item.updatedAt).format('DD MMM YYYY HH:mm a'),
                    segment.departure.city,
                    segment.arrival.city,
                    Moment(segment.departureTime).format('DD MMM YYYY HH:mm a'),
                    Moment(segment.arrivalTime).format('DD MMM YYYY HH:mm a'),
                    segment.airline,
                    segment.flightCode,
                    segment.flightNumber,
                    traveller.name,
                    traveller.gender,
                    traveller.type,
                    traveller.pnrs[
                      `${segment.departure.code}-${segment.arrival.code}`
                    ] || '-',
                    item.refundAmount || '-',
                    item.ticket.fareComponent?.baseFare || '-',
                    item.ticket.fareComponent?.taxes?.total || '-',
                    item.ticket.fareComponent?.taxes?.breakups['Gst Charges'] ||
                      '-',
                    item.ticket.fareComponent?.taxes?.breakups[
                      'Other Charges'
                    ] || '-',
                  ]);
                });
              });
            }
          } else {
            data.push([
              item.id,
              item.provider,
              item.category,
              item.transId,
              item.status,
              item.ticket?.flowId || item.providerId || '-',
              item.paymentType,
              item.amount,
              item.ledger?.ledgerId || '-',
              item.ledger?.amount || '-',
              item.ledger?.balance || '-',
              item.invoiceConfig?.reseller ? 'Yes' : 'No',
              item.invoiceConfig?.serviceResponse?.response?.data?.Irn || '-',
              item.invoiceConfig?.serviceInvoice || '-',
              item.invoiceConfig?.invoiceResponse?.response?.data?.Irn || '-',
              item.invoiceConfig?.invoice || '-',
              item.invoiceConfig?.voucher || '-',
              item.invoiceConfig?.serviceCharges || '0',
              '998551',
              '996425',
              '29AAHCC1775A1ZT',
              '29AAHCC1775A1ZT',
              item.invoiceConfig?.userGst?.gstin || '-',
              item.client.code,
              item.client.name,
              Moment(item.createdAt).format('DD MMM YYYY HH:mm a'),
              Moment(item.updatedAt).format('DD MMM YYYY HH:mm a'),
              '-',
              '-',
              '-',
              '-',
              '-',
              '-',
              '-',
              '-',
              '-',
              '-',
              '-',
              item.refundAmount || '-',
              '-',
              '-',
              '-',
              '-',
            ]);
          }
        });
        DownloadCsv(
          `flight_bos_${Moment(Dates.startDate).format('DD_MM_YYYY')}_${Moment(
            Dates.endDate
          ).format('DD_MM_YYYY')}.csv`,
          data
        );
        setLoading(false);
      },
      {
        start: Dates.startDate,
        end: Dates.endDate,
      }
    );
  };
  useEffect(() => {
    if (Dates) {
      get(
        `/meta/flight/perks`,
        (e, r) => {
          if (r) {
            SetData({
              ...r,
              currentPage: Dates.page ? Dates.page : 0,
              totalPages: r.totalPages > 0 ? r.totalPages : Data.totalPages,
              total: r.total > 0 ? r.total : Data.total,
            });
          } else {
            Alert.handleError(e);
          }
        },
        {
          start: Dates.startDate,
          end: Dates.endDate,
          offset: Dates.page,
        }
      );
    }
  }, [Dates]);
  if (loading) {
    return (
      <div>
        <div
          style={{
            margin: '0 -4rem',
            padding: '2rem 4rem',
            borderBottom: '1px solid #dedede',
          }}
        >
          <div className='flex horizontally center-vertically'>
            <div className='flex-1'>
              <DateRange
                defaultValue={{
                  startDate: Moment().add(-31, 'd').valueOf(),
                  endDate: Moment().valueOf(),
                }}
                onChange={() => {}}
                className=''
                label='Export from and to'
                type='range'
              />
            </div>
          </div>
        </div>
        <div className='pd6'>
          <Loader />
        </div>
      </div>
    );
  }
  let items = [];
  Data.items.map((item) => {
    if (item.status !== 'FAILED' && item.status !== 'ONGOING') {
      item.ticket.segments.forEach((segment) => {
        item.ticket.travellers.forEach((traveller) => {
          items.push({
            content: item,
            render: [
              item.id,
              item.provider,
              item.category,
              item.transId,
              item.status,
              item.ticket?.flowId || item.providerId || '-',
              item.paymentType,
              item.amount,
              item.ledger?.ledgerId || '-',
              item.ledger?.amount || '-',
              item.ledger?.balance || '-',
              item.invoiceConfig?.reseller ? 'Yes' : 'No',
              item.invoiceConfig?.serviceResponse?.response?.data?.Irn || '-',
              item.invoiceConfig?.serviceInvoice || '-',
              item.invoiceConfig?.invoiceResponse?.response?.data?.Irn || '-',
              item.invoiceConfig?.invoice || '-',
              item.invoiceConfig?.voucher || '-',
              item.invoiceConfig?.serviceCharges || '0',
              '998551',
              '996425',
              '29AAHCC1775A1ZT',
              '29AAHCC1775A1ZT',
              item.invoiceConfig?.userGst?.gstin || '-',
              item.client.code,
              item.client.name,
              Moment(item.createdAt).format('DD MMM YYYY HH:mm a'),
              Moment(item.updatedAt).format('DD MMM YYYY HH:mm a'),
              segment.departure.city,
              segment.arrival.city,
              Moment(segment.departureTime).format('DD MMM YYYY HH:mm a'),
              Moment(segment.arrivalTime).format('DD MMM YYYY HH:mm a'),
              segment.airline,
              segment.flightCode,
              segment.flightNumber,
              traveller.name,
              traveller.gender,
              traveller.type,
              traveller.pnrs[
                `${segment.departure.code}-${segment.arrival.code}`
              ] || '-',
              item.refundAmount || '-',
              item.ticket.fareComponent?.baseFare || '-',
              item.ticket.fareComponent?.taxes?.total || '-',
              item.ticket.fareComponent?.taxes?.breakups['Gst Charges'] || '-',
              item.ticket.fareComponent?.taxes?.breakups['Other Charges'] ||
                '-',
              ,
              '',
            ].map((title) => ({ weight: 2, title })),
          });
        });
      });
    } else {
      items.push({
        content: item,
        render: [
          item.id,
          item.provider,
          item.category,
          item.transId,
          item.status,
          item.ticket?.flowId || item.providerId || '-',
          item.paymentType,
          item.amount,
          item.ledger?.ledgerId || '-',
          item.ledger?.amount || '-',
          item.ledger?.balance || '-',
          item.invoiceConfig?.reseller ? 'Yes' : 'No',
          item.invoiceConfig?.serviceResponse?.response?.data?.Irn || '-',
          item.invoiceConfig?.serviceInvoice || '-',
          item.invoiceConfig?.invoiceResponse?.response?.data?.Irn || '-',
          item.invoiceConfig?.invoice || '-',
          item.invoiceConfig?.voucher || '-',
          item.invoiceConfig?.serviceCharges || '0',
          '998551',
          '996425',
          '29AAHCC1775A1ZT',
          '29AAHCC1775A1ZT',
          item.invoiceConfig?.userGst?.gstin || '-',
          item.client.code,
          item.client.name,
          Moment(item.createdAt).format('DD MMM YYYY HH:mm a'),
          Moment(item.updatedAt).format('DD MMM YYYY HH:mm a'),
          '-',
          '-',
          '-',
          '-',
          '-',
          '-',
          '-',
          '-',
          '-',
          '-',
          '-',
          item.refundAmount || '-',
          '-',
          '-',
          '-',
          '-',
        ].map((title) => ({ weight: 2, title })),
      });
    }
  });
  return (
    <div style={{ width: '100%' }}>
      <div
        style={{
          margin: '0 -4rem',
          padding: '2rem 4rem',
          paddingTop: 0,
          borderBottom: '1px solid #dedede',
        }}
      >
        <div className='flex horizontally center-vertically'>
          <div className='flex-1'>
            <DateRange
              defaultValue={{
                startDate: Moment().add(-31, 'd').valueOf(),
                endDate: Moment().valueOf(),
              }}
              onChange={(dates) => {
                setDates({
                  ...dates,
                  page: 0,
                });
              }}
              className=''
              label='Export from and to'
              type='range'
            />
          </div>
        </div>
      </div>
      <RBAC role={ROLES.REPORTS}>
        <Button onClick={downloadData} className='btn btn-black'>
          Download Data
        </Button>
      </RBAC>
      {Data.items.length === 0 && (
        <div className='border pd6 mt4 flex vertically center'>
          <img
            alt='fetch'
            src={Graph}
            style={{ width: '5rem', height: '5rem' }}
          />
          <h3 className='fw-bold mt4'>No Data Found</h3>
          <p>No data found in this configuration</p>
        </div>
      )}
      <ScrollableDynamicTable
        autoTrigger={false}
        tableStyle={{
          borderSpacing: 0,
        }}
        width={150}
        search={Dates}
        setPage={(page) => {
          setDates({
            ...Dates,
            page,
            total: Dates.totalPages,
          });
        }}
        data={{
          current: Data.currentPage,
          total: Data.totalPages,
          items,
        }}
        headers={[
          'Id',
          'Provider',
          'Category',
          'Booking Id',
          'Status',
          'Provider Id',
          'Payment Type',
          'Amount',
          'Ledger Id',
          'Ledger Amount',
          'Ledger Balance',
          'Reseller',
          'Svc Invoice',
          'Svc Invoice Irn',
          'Flight Invoice Irn',
          'Flight Invoice',
          'Voucher',
          'Service Charges',
          'Service Charge Hsn',
          'Flight Hsn',
          'CT Svc Gstin',
          'CT Invoice Gstin',
          'User Gstin',
          'Client Code',
          'Client Name',
          'Created On',
          'Updated On',
          'Origin',
          'Destination',
          'Departure Date',
          'Arrival Date',
          'Departure Airline',
          'Departure Flight Code',
          'Departure Flight Number',
          'Traveller Name',
          'Gender',
          'Type',
          'PNRs',
          'Refund Amount',
          'Base Fare',
          'Total Taxes',
          'Gst Charges',
          'Other Misc',
          '',
        ].map((item) => ({
          weight: 2,
          title: item,
        }))}
      />
    </div>
  );
}
