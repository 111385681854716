import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  DateRange,
  NavDivider,
  Navigation,
  ScrollableDynamicTable,
  TOAST as Alert,
} from 'retro';
import { get } from 'App/Network/Axios';
import Moment from 'moment';
import { DownloadCsv } from 'retro/Utils';
import RBAC from 'App/HOCs/RBAC';
import ROLES from 'App/Constants/Roles';

export default function AllClients({}) {
  const [Dates, setDates] = useState(undefined);
  const [Data, SetData] = useState({
    total: 0,
    currentPage: 0,
    totalPages: 0,
    items: [],
  });
  const loadPage = useCallback(
    (search) => {
      get(
        `/meta/da/recharges`,
        (e, r) => {
          if (r) {
            SetData({
              ...r,
            });
          } else {
            Alert.handleError(e);
          }
        },
        {
          start: search.startDate,
          end: search.endDate,
          offset: search.page,
        }
      );
    },
    [SetData]
  );
  useEffect(() => {
    if (Dates && Dates.startDate) {
      loadPage(Dates);
    }
  }, [Dates]);
  return (
    <>
      <Navigation
        backUrl={'back'}
        title='DA Recharges'
        chips={['All Recharges']}
        description='All recharges are listed below.'
      />
      <div className='flex horizontally center-vertically'>
        <div className='flex-1'>
          <DateRange
            defaultValue={{
              startDate: Moment().add(-31, 'd').valueOf(),
              endDate: Moment().valueOf(),
            }}
            onChange={(dates) => {
              setDates({
                ...dates,
                page: 0,
              });
            }}
            className='mt2'
            label=''
            type='range'
          />
        </div>
      </div>
      <NavDivider />
      <div className='mt4'>
        <div className='relative'>
          <RBAC role={ROLES.REPORTS}>
            <div className='flex horizontally center-vertically'>
              <div className='flex-1 flex horizontally'>
                <Button
                  onClick={() => {
                    get(
                      `/meta/da/recharges/download`,
                      (e, r) => {
                        if (r) {
                          let data = [];
                          let headers = [
                            'Id',
                            'Client Code',
                            'Client Name',
                            'Account Id',
                            'Account Number',
                            'Ifsc Code',
                            'Ledger Id',
                            'Reference Type',
                            'Amount',
                            'Balance',
                            'Admin Name',
                            'Remarks',
                            'Created At',
                          ];
                          data.push(headers);
                          r.transfers.map((item) => {
                            data.push([
                              item.id,
                              item.clientCode,
                              item.clientName,
                              item.account,
                              item.accountNumber,
                              item.ifsc,
                              item.ledgerId,
                              item.referenceType,
                              item.amount,
                              item.balance,
                              item.attrs['admin.name'],
                              item.remarks,
                              Moment(item.createdAt).format(
                                'DD MMM YYYY HH:mm a'
                              ),
                            ]);
                          });
                          DownloadCsv(`recharges.csv`, data);
                        } else {
                          console.log(e);
                          Alert.handleError(e);
                        }
                      },
                      {
                        start: Dates.startDate,
                        end: Dates.endDate,
                      }
                    );
                  }}
                  className='btn btn-black'
                  margin=''
                >
                  Download Data
                </Button>
              </div>
            </div>
          </RBAC>
          <ScrollableDynamicTable
            setPage={(page) => {
              setDates({
                ...Dates,
                page,
                total: Dates.totalPages,
              });
            }}
            width={175}
            search={Dates}
            tableStyle={{
              borderSpacing: 0,
            }}
            data={{
              current: Data.currentPage,
              total: Data.totalPages,
              items: Data.items.map((item) => {
                return {
                  content: item,
                  render: [
                    {
                      children: (
                        <div>
                          <p className='text'>{item.clientName}</p>
                          <p className='text-small text-light'>
                            {item.clientCode}
                          </p>
                        </div>
                      ),
                      weight: 2,
                    },
                    {
                      children: (
                        <div>
                          <p className='text'>{item.accountNumber}</p>
                          <p className='text-small text-light'>
                            {item.account}
                          </p>
                          <p className='text-small text-light'>{item.ifsc}</p>
                        </div>
                      ),
                      weight: 2,
                    },
                    {
                      weight: 1,
                      title: item.ledgerId,
                    },
                    {
                      weight: 2,
                      title: item.remarks,
                    },
                    {
                      weight: 1,
                      title: Moment(item.createdAt).format(
                        'DD MMM YYYY HH:mm a'
                      ),
                    },
                    {
                      weight: 1,
                      title: item.amount,
                    },
                    {
                      weight: 1,
                      title: item.balance || '0',
                    },
                    {
                      children: (
                        <div>
                          <p className='text'>
                            {item.attrs['admin.name'] || '-'}
                          </p>
                        </div>
                      ),
                      weight: 1,
                    },
                  ],
                };
              }),
            }}
            headers={[
              {
                weight: 2,
                title: 'Client Details',
              },
              {
                weight: 1,
                title: 'Code',
              },
              {
                weight: 1,
                title: 'Ledger Id',
              },
              {
                weight: 2,
                title: 'Remarks',
              },
              {
                weight: 1,
                title: 'Created On',
              },
              {
                weight: 1,
                title: 'Amount',
              },
              {
                weight: 1,
                title: 'Balance',
              },
              {
                weight: 1,
                title: 'Added By',
              },
            ]}
          />
        </div>
      </div>
    </>
  );
}
