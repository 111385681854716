import React from 'react';
import { useSelector } from 'react-redux';
import { ReportRouteCard } from '../All/routes';
import { NavDivider, Navigation } from 'retro/index';
import ROLES from 'App/Constants/Roles';
import useRoleHook from 'App/Hooks/useRoleHook';

export default function index() {
  // Origin Code
  const { code } = useSelector((data) => ({
    code: data?.auth?.config?.origin?.code || '',
  }));
  // dont show Agency to ADANI
  // show Reseller to CLRTRP
  const isReports = useRoleHook(ROLES.REPORTS);
  const isSupport = useRoleHook(ROLES.SUPPORT);
  const routes = [];
  if (isReports){
    routes.push(
      {
        title: 'Agency BOS File',
        desc: 'Booking wise agency bos file',
        path: '/app/apps/meta/hotel/agency',
        btnLabel: 'View File',
        codes: [],
      },
      {
        title: 'Reseller BOS File',
        desc: 'Booking wise reseller bos file',
        path: '/app/apps/meta/hotel/reseller',
        btnLabel: 'View File',
        codes: ['CLRTRP'],
      }
    )
  }
    if (isSupport){
      routes.push(
        {
          title: 'Hotel Refund Queue',
          desc: 'Hotel refund queue',
          path: '/app/apps/meta/hotel/queue/refunds',
          btnLabel: 'View Queue',
          codes: [],
        },
        {
          title: 'Bookings',
          desc: 'All hotel bookings appears here',
          path: '/app/apps/meta/hotel/bookings',
          btnLabel: 'View Bookings',
          codes: [],
        },
        {
          title: 'Invoice Queue',
          desc: 'Hotel which are waiting for invoice generation appears here',
          path: '/app/apps/meta/hotel/invoice',
          btnLabel: 'View Queue',
          codes: ['CLRTRP'],
        },
        {
          title: 'Booking Queue',
          desc: 'Hotel booking queue appears here',
          path: '/app/apps/meta/hotel/queue/bookings',
          btnLabel: 'View Queue',
          codes: [],
        }
      )
    }

  return (
    <div>
      <Navigation
        backUrl=""
        title="Hotel Bookings"
        description="All hotel queue appears below."
      />
      <NavDivider />
      <div className="card-container mt4">
        {routes.map((report) => (
          // return only if noone  or the current one is not restricted
          (report.codes.length === 0 || report.codes.includes(code)) && (
            <ReportRouteCard reportRoute={report} />
          )
        ))}
      </div>
    </div>
  );
}
