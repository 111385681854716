import React, { useState } from 'react';
import { Button, DateRange, Loader, Navigation } from 'retro';
import Moment from 'moment';
import { get } from 'App/Network/Axios';
import { DownloadCsv } from 'retro/Utils';
import Graph from './graph-stats-circle.svg';
import RBAC from 'App/HOCs/RBAC';
import ROLES from 'App/Constants/Roles';

export default function AdaniAgencyBos() {
  const [Dates, setDates] = useState({
    startDate: Moment().add(-31, 'd').valueOf(),
    endDate: Moment().valueOf(),
  });
  const [loading, setLoading] = useState(false);

  const numberOfSegmentsForHotels = (item) => {
    let booking = item?.booking?.ticket;
    if (booking) {
      let inDate = booking.date.in || booking.criteria.dates.in;
      let outDate = booking.date.out || booking.criteria.dates.out;
      let diff = Moment(outDate, 'DD-MM-YYYY').diff(
        Moment(inDate, 'DD-MM-YYYY'),
        'day'
      );
      return diff * booking.criteria.rooms.length;
    }
    return 0;
  };

  // get the number of segements for refunded passengers
  const numberOfSeqments = (item) => {
    let refunds = item?.booking?.data?.refundInfos;
    if (!refunds) {
      return 0;
    } else {
      let refundData = refunds.find(
        (refund) => refund.refundId === item.ledgerId
      );
      return refundData?.numberOfSegments || 0;
    }
  };

  const downloadData = () => {
    setLoading(true);
    get(
      `/meta/da/ledger/download`,
      (e, r) => {
        if (e) {
          setLoading(false);
          console.log(e);
          return;
        }
        let data = [];
        let headers = [
          'Date',
          'Ledger Id',
          'Dice Id',
          'Adani Id',
          'Category',
          'Journey Type',
          'Transaction Type',
          'GSTIN',
          'Client Code',
          'Client Name',
          'Account Type',
          'CREDIT/DEBIT',
          'Segments',
          'Ticket Amount',
          'Service Charge',
          'Service Charge GST (INR)',
          'Total service Charge',
          'Total/Refund Amount',
          'Client Settled Amount',
          'Client Settled',
          'Client UTR',
          'Client TDS Amont',
          'Client Paid Amont',
          'Client Payment Date',
          'CT Settled',
          'CT UTR',
          'CT Payment Date',
        ];
        data.push(headers);
        r.items.forEach((item) => {
          console.log(item);
          let line = [];
          line.push(Moment(item.createdAt).format('DD-MMM-YYYY'));
          line.push(item.id);
          line.push(item.booking?.transId || '-');
          line.push(item.booking?.referenceId || '-');
          if (item.booking?.category.includes('FLIGHT')) {
            line.push(
              item.booking?.category === 'DOMESTICROUNDFLIGHT'
                ? 'Flight Round Trip'
                : 'Flight One Way'
            );
          } else {
            line.push(item.booking?.category || '-');
          }
          if (item.type === 'DASHBOARD') {
            line.push('RECHARGE');
            line.push('RECHARGE');
          } else {
            line.push(
              item.booking?.international ? 'International' : 'Domestic'
            );
            line.push(item.type);
          }
          line.push(item.booking?.gstin || '-');
          line.push(item.clientCode);
          line.push(item.clientName);
          line.push(item.accountType || '-');
          line.push(item.financeType || '-');
          if (item.type === 'REFUND') {
            if (item.booking?.category === 'HOTEL') {
              line.push(numberOfSegmentsForHotels(item) || '-');
            } else if (item.booking?.category === 'BUS') {
              let travellers = item.booking?.ticket?.travellers?.length || 0;
              line.push(travellers || '-');
            } else {
              let sg = numberOfSeqments(item);
              if (sg === 0) {
                let travellers = item.booking?.ticket?.travellers?.length || 0;
                if (item.booking?.category === 'DOMESTICROUNDFLIGHT') {
                  travellers = travellers * 2;
                }
                sg = travellers;
              }
              line.push(sg);
            }
          } else {
            if (item.booking?.category === 'HOTEL') {
              line.push(numberOfSegmentsForHotels(item) || '-');
            } else {
              let travellers = item.booking?.ticket?.travellers?.length || 0;
              if (item.booking?.category === 'DOMESTICROUNDFLIGHT') {
                travellers = travellers * 2;
              }
              line.push(travellers || '-');
            }
          }
          if (item.type === 'REFUND') {
            let cancellationCharges;
            // partial cancellation is only for flights
            if(item.booking?.category.includes('FLIGHT')){
              cancellationCharges =
              item.booking?.data?.refundInfos.find(
                (refunddata) => refunddata.refundId === item.ledgerId
              )?.platformCharges || 0;
            }else{
              cancellationCharges = item.booking?.config?.platformCancellationCharges || 0;
            }
            line.push(item.amount + cancellationCharges);
            cancellationCharges =
              cancellationCharges - cancellationCharges * 0.1525;
            line.push(cancellationCharges.toFixed(2));
            let gst = cancellationCharges * 0.18;
            line.push(gst.toFixed(2));
            line.push(parseInt(cancellationCharges + gst));
            line.push(parseFloat(item.amount).toFixed(2));
          } else {
            let serviceCharges = item.booking?.config?.serviceCharges || 0;
            line.push(item.amount - serviceCharges || '-');
            serviceCharges = serviceCharges - serviceCharges * 0.1525;
            line.push(serviceCharges.toFixed(2));
            let gst = serviceCharges * 0.18;
            line.push(gst.toFixed(2));
            line.push(parseInt(serviceCharges + gst));
            line.push(parseFloat(item.amount).toFixed(2));
          }
          line.push(item.settledAmount || '0');
          line.push(item.settled ? 'YES' : 'NO');
          line.push(item.utr);
          line.push(item.tdsAmount);
          line.push(item.paidAmount);
          line.push(item.paymentDate);
          line.push(item.ctSettled ? 'YES' : 'NO');
          line.push(item.ctUtr);
          line.push(item.ctPaymentDate);
          data.push(line);
        });
        DownloadCsv(
          `mis_${Moment(Dates.startDate).format('DD_MM_YYYY')}_${Moment(
            Dates.endDate
          ).format('DD_MM_YYYY')}.csv`,
          data
        );
        setLoading(false);
      },
      {
        start: Dates.startDate,
        end: Dates.endDate,
      }
    );
  };

  if (loading) {
    return (
      <div>
        <div className="pd6">
          <Loader />
        </div>
      </div>
    );
  }

  return (
    <div style={{ width: '100%' }}>
      <Navigation
        backUrl={'back'}
        title="Agency Bookings"
        chips={['Meta', 'Agency']}
        description="Agency bookings appears below."
      />
      <div
        style={{
          margin: '0 -4rem',
          padding: '2rem 4rem',
          borderBottom: '1px solid #dedede',
        }}
      >
        <div className="flex horizontally center-vertically">
          <div className="flex-1">
            <DateRange
              defaultValue={Dates}
              onChange={(dates) => {
                setDates({
                  ...dates,
                  page: 0,
                });
              }}
              className=""
              label="Export from and to"
              type="range"
            />
          </div>
        </div>
      </div>
      <div>
        <div className="border pd6 mt4 flex vertically center">
          <img
            alt="fetch"
            src={Graph}
            style={{ width: '5rem', height: '5rem' }}
          />
          <RBAC role={ROLES.REPORTS}>
            <Button onClick={downloadData} className="btn btn-black mt4">
              Download Data
            </Button>
          </RBAC>

          <p className="mt2">Download agency bos data</p>
        </div>
      </div>
    </div>
  );
}
