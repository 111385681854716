import React, { useEffect, useState } from 'react';
import {
  Button,
  DateRange,
  Loader,
  ScrollableDynamicTable,
  TOAST,
} from 'retro';
import Moment from 'moment';
import { get } from 'App/Network/Axios';
import { DownloadCsv } from 'retro/Utils';
import Graph from './graph-stats-circle.svg';
import Airlines from './airlines.json';
import { placeOfSupply } from './Utils';
import RBAC from 'App/HOCs/RBAC';
import ROLES from 'App/Constants/Roles';

export default function index() {
  const [Dates, setDates] = useState();
  const [loading, setLoading] = useState(false);
  const airlines = {};
  Airlines.forEach((item) => {
    airlines[item.iata] = item.name;
  });
  airlines['QP'] = 'Akasa Air';
  const [Data, SetData] = useState({
    total: 0,
    currentPage: 0,
    totalPages: 0,
    items: [],
  });
  const downloadData = () => {
    setLoading(true);
    get(
      `/meta/flight/all`,
      (e, r) => {
        if (e) {
          console.log(e);
          setLoading(false);
          TOAST.error('Unable to download');
          return;
        }
        let data = [];
        let headers = [
          'Id',
          'Trans Id',
          'Provider',
          'Provider Id',
          'Cart Type',
          'Booking Status',
          'Provider Id',
          'Journey Type',
          'Category',
          'Cabin Class',
          'Client Code',
          'Client Customer Code',
          'Client Name',
          'Booker Name',
          'Booker Email Address',
          'Created On Date',
          'Created On Time',
          'Updated On Date',
          'Updated On Time',
          'CT Gstin',
          'Ct State',
          'Buyer Gstin',
          'State Of Supply',
          'Payment Type',
          'Amount Collected',
          'Line Item Amount',
          'Ledger Id',
          'Ledger Amount',
          'Ledger Balance',
          'Pg Order Id',
          'Pg Payment Id',
          'Pg Refund Id',
          'Hsn',
          'Document Type',
          'Document Number',
          'Document Irn',
          'Document File',
          'Base Fare',
          'Slab',
          'Other Charges',
          'SGST',
          'CGST',
          'IGST',
          'TOTAL',
        ];
        data.push(headers);
        r.bookings.map((item) => {
          try {
            if (
              item.status !== 'FAILED' &&
              item.status !== 'ONGOING' &&
              (item.invoiceConfig?.reseller ||
                (item.invoiceConfig != null &&
                  item.invoiceConfig.serviceInvoice !== 'SKIPPED'))
            ) {
              if (item.invoiceConfig?.reseller)
                data.push([
                  item.id,
                  item.transId,
                  item.provider,
                  item.ticket?.flowId || item.providerId || '-',
                  'Booking',
                  item.status,
                  item.ticket?.flowId || item.providerId || '-',
                  item.category === 'DOMESTICROUNDFLIGHT'
                    ? 'Round Trip'
                    : 'One Way',
                  'Domestic',
                  'Economy',
                  item.client.code,
                  item.client.attrs?.CUST_CODE||"-",
                  item.client.name,
                  item.attrs['bookedBy'] || '-',
                  item.attrs['bookedByEmail'] || '-',
                  Moment(item.createdAt).format('DD MMM YYYY'),
                  Moment(item.createdAt).format('HH:mm a'),
                  Moment(item.updatedAt).format('DD MMM YYYY'),
                  Moment(item.updatedAt).format('HH:mm a'),
                  '27AAHCC1775A1ZX',
                  'Maharashtra',
                  item.invoiceConfig?.userGst?.gstin || '-',
                  placeOfSupply(
                    item.invoiceConfig?.userGst?.gstin || undefined
                  ),
                  item.paymentType,
                  item.amount,
                  item.invoiceConfig?.irnFare?.total,
                  item.ledger?.ledgerId || '-',
                  item.ledger?.amount || '-',
                  item.ledger?.balance || '-',
                  item.attrs['orderId'] || '-',
                  item.attrs['paymentId'] || '-',
                  item.attrs['refundId'] || '-',
                  '998551',
                  'Invoice',
                  item.invoiceConfig?.invoiceNo || '-',
                  item.invoiceConfig?.invoiceIrn,
                  item.invoiceConfig?.invoice || '-',
                  item.invoiceConfig?.irnFare?.base || '-',
                  item.invoiceConfig?.irnFare?.slab || '-',
                  item.invoiceConfig?.irnFare?.oth || '-',
                  item.invoiceConfig?.irnFare?.sGst || '-',
                  item.invoiceConfig?.irnFare?.cGst || '-',
                  item.invoiceConfig?.irnFare?.iGst || '-',
                  item.invoiceConfig?.irnFare?.total || '-',
                ]);
              if (
                item.invoiceConfig != null &&
                item.invoiceConfig.serviceInvoice != null &&
                item.invoiceConfig.serviceInvoice !== 'SKIPPED'
              ) {
                data.push([
                  item.id,
                  item.transId,
                  item.provider,
                  item.ticket?.flowId || item.providerId || '-',
                  'Service Invoice',
                  item.status,
                  item.ticket?.flowId || item.providerId || '-',
                  item.category === 'DOMESTICROUNDFLIGHT'
                    ? 'Round Trip'
                    : 'One Way',
                  'Domestic',
                  'Economy',
                  item.client.code,
                  item.client.name,
                  item.attrs['bookedBy'] || '-',
                  item.attrs['bookedByEmail'] || '-',
                  Moment(item.createdAt).format('DD MMM YYYY'),
                  Moment(item.createdAt).format('HH:mm a'),
                  Moment(item.updatedAt).format('DD MMM YYYY'),
                  Moment(item.updatedAt).format('HH:mm a'),
                  '27AAHCC1775A1ZX',
                  'Maharashtra',
                  item.invoiceConfig?.userGst?.gstin || '-',
                  placeOfSupply(
                    item.invoiceConfig?.userGst?.gstin || undefined
                  ),
                  item.paymentType,
                  item.amount,
                  item.invoiceConfig?.serviceFare.total,
                  item.ledger?.ledgerId || '-',
                  item.ledger?.amount || '-',
                  item.ledger?.balance || '-',
                  item.attrs['orderId'] || '-',
                  item.attrs['paymentId'] || '-',
                  item.attrs['refundId'] || '-',
                  '996425',
                  'Service Invoice',
                  item.invoiceConfig?.serviceInvoiceNo || '-',
                  item.invoiceConfig?.serviceResponse?.response?.data?.Irn ||
                    '-',
                  item.invoiceConfig?.serviceInvoice || '-',
                  item.invoiceConfig?.serviceFare.base || '-',
                  item.invoiceConfig?.serviceFare.slab || '-',
                  item.invoiceConfig?.serviceFare.oth || '-',
                  item.invoiceConfig?.serviceFare.sGst || '-',
                  item.invoiceConfig?.serviceFare.cGst || '-',
                  item.invoiceConfig?.serviceFare.iGst || '-',
                  item.invoiceConfig?.serviceFare.total || '-',
                ]);
              }
              if (
                item.invoiceConfig !== null &&
                item.invoiceConfig.creditNote !== null &&
                item.invoiceConfig.creditNote !== 'PENDING'
              ) {
                let lineItem = JSON.parse(item.invoiceConfig.creditRequest);
                data.push([
                  item.id,
                  item.transId,
                  item.provider,
                  item.ticket?.flowId || item.providerId || '-',
                  'Credit Note',
                  item.status,
                  item.ticket?.flowId || item.providerId || '-',
                  item.category === 'DOMESTICROUNDFLIGHT'
                    ? 'Round Trip'
                    : 'One Way',
                  'Domestic',
                  'Economy',
                  item.client.code,
                  item.client.name,
                  item.attrs['bookedBy'] || '-',
                  item.attrs['bookedByEmail'] || '-',
                  Moment(item.updatedAt).format('DD MMM YYYY'),
                  Moment(item.updatedAt).format('HH:mm a'),
                  Moment(item.updatedAt).format('DD MMM YYYY'),
                  Moment(item.updatedAt).format('HH:mm a'),
                  '27AAHCC1775A1ZX',
                  'Maharashtra',
                  item.invoiceConfig?.userGst?.gstin || '-',
                  placeOfSupply(
                    item.invoiceConfig?.userGst?.gstin || undefined
                  ),
                  item.paymentType,
                  item.amount,
                  item.invoiceConfig?.irnFare?.total,
                  item.ledger?.ledgerId || '-',
                  item.ledger?.amount || '-',
                  item.ledger?.balance || '-',
                  item.attrs['orderId'] || '-',
                  item.attrs['paymentId'] || '-',
                  item.attrs['refundId'] || '-',
                  '998551',
                  'Credit Note',
                  lineItem?.data?.docDtls?.no || '-',
                  item.invoiceConfig?.creditIrn || '-',
                  item.invoiceConfig?.creditNote || '-',
                  item.invoiceConfig?.irnFare?.base || '-',
                  item.invoiceConfig?.irnFare?.slab || '-',
                  item.invoiceConfig?.irnFare?.sGst || '-',
                  item.invoiceConfig?.irnFare?.cGst || '-',
                  item.invoiceConfig?.irnFare?.iGst || '-',
                  item.invoiceConfig?.irnFare?.oth || '-',
                  item.invoiceConfig?.irnFare?.total || '-',
                ]);
              }
              if (
                item.invoiceConfig !== null &&
                item.invoiceConfig.cancellationCharges !== null &&
                item.invoiceConfig.cancellationCharges !== 'PENDING'
              ) {
                let lineItem = JSON.parse(
                  item.invoiceConfig.cancellationRequest
                );
                data.push([
                  item.id,
                  item.transId,
                  item.provider,
                  item.ticket?.flowId || item.providerId || '-',
                  'Cancellation Charges',
                  item.status,
                  item.ticket?.flowId || item.providerId || '-',
                  item.category === 'DOMESTICROUNDFLIGHT'
                    ? 'Round Trip'
                    : 'One Way',
                  'Domestic',
                  'Economy',
                  item.client.code,
                  item.client.name,
                  item.attrs['bookedBy'] || '-',
                  item.attrs['bookedByEmail'] || '-',
                  Moment(item.updatedAt).format('DD MMM YYYY'),
                  Moment(item.updatedAt).format('HH:mm a'),
                  Moment(item.updatedAt).format('DD MMM YYYY'),
                  Moment(item.updatedAt).format('HH:mm a'),
                  '27AAHCC1775A1ZX',
                  'Maharashtra',
                  item.invoiceConfig?.userGst?.gstin || '-',
                  placeOfSupply(
                    item.invoiceConfig?.userGst?.gstin || undefined
                  ),
                  item.paymentType,
                  item.amount,
                  lineItem?.data?.valDtls?.totInvVal || '-',
                  item.ledger?.ledgerId || '-',
                  item.ledger?.amount || '-',
                  item.ledger?.balance || '-',
                  item.attrs['orderId'] || '-',
                  item.attrs['paymentId'] || '-',
                  item.attrs['refundId'] || '-',
                  '998551',
                  'Cancellation Charges',
                  lineItem?.data?.docDtls?.no || '-',
                  item.invoiceConfig?.cancellationIrn || '-',
                  item.invoiceConfig?.cancellationCharges || '-',
                  lineItem?.data?.valDtls?.assVal || '-',
                  item.invoiceConfig?.irnFare?.slab || '-',
                  `0`,
                  lineItem?.data?.valDtls?.sgstVal || '-',
                  lineItem?.data?.valDtls?.cgstVal || '-',
                  lineItem?.data?.valDtls?.igstVal || '-',
                  lineItem?.data?.valDtls?.totInvVal || '-',
                ]);
              }
              if (
                item.invoiceConfig !== null &&
                item.invoiceConfig.ancillaryInvoice !== null &&
                item.invoiceConfig.ancillaryInvoice !== 'SKIPPED'
              ) {
                let lineItem = JSON.parse(item.invoiceConfig.ancillaryRequest);
                data.push([
                  item.id,
                  item.transId,
                  item.provider,
                  item.ticket?.flowId || item.providerId || '-',
                  'Cancellation Charges',
                  item.status,
                  item.ticket?.flowId || item.providerId || '-',
                  item.category === 'DOMESTICROUNDFLIGHT'
                    ? 'Round Trip'
                    : 'One Way',
                  'Domestic',
                  'Economy',
                  item.client.code,
                  item.client.name,
                  item.attrs['bookedBy'] || '-',
                  item.attrs['bookedByEmail'] || '-',
                  Moment(item.updatedAt).format('DD MMM YYYY'),
                  Moment(item.updatedAt).format('HH:mm a'),
                  Moment(item.updatedAt).format('DD MMM YYYY'),
                  Moment(item.updatedAt).format('HH:mm a'),
                  '27AAHCC1775A1ZX',
                  'Maharashtra',
                  item.invoiceConfig?.userGst?.gstin || '-',
                  placeOfSupply(
                    item.invoiceConfig?.userGst?.gstin || undefined
                  ),
                  item.paymentType,
                  item.amount,
                  lineItem?.data?.valDtls?.totInvVal || '-',
                  item.ledger?.ledgerId || '-',
                  item.ledger?.amount || '-',
                  item.ledger?.balance || '-',
                  item.attrs['orderId'] || '-',
                  item.attrs['paymentId'] || '-',
                  item.attrs['refundId'] || '-',
                  '998551',
                  'Ancillery Charges',
                  lineItem?.data?.docDtls?.no || '-',
                  item.invoiceConfig?.ancillaryIrn || '-',
                  item.invoiceConfig?.ancillaryFare?.total || '-',
                  lineItem?.data?.valDtls?.assVal || '-',
                  item.invoiceConfig?.ancillaryFare?.slab || '-',
                  `0`,
                  lineItem?.data?.valDtls?.sgstVal || '-',
                  lineItem?.data?.valDtls?.cgstVal || '-',
                  lineItem?.data?.valDtls?.igstVal || '-',
                  lineItem?.data?.valDtls?.totInvVal || '-',
                ]);
              }
            }
          } catch (e) {}
        });
        DownloadCsv(
          `flight_gst_bos_${Moment(Dates.startDate).format(
            'DD_MM_YYYY'
          )}_${Moment(Dates.endDate).format('DD_MM_YYYY')}.csv`,
          data
        );
        setLoading(false);
      },
      {
        start: Dates.startDate,
        end: Dates.endDate,
      }
    );
  };
  useEffect(() => {
    if (Dates) {
      get(
        `/meta/flight`,
        (e, r) => {
          if (r) {
            SetData({
              ...r,
              currentPage: Dates.page ? Dates.page : 0,
              totalPages: r.totalPages > 0 ? r.totalPages : Data.totalPages,
              total: r.total > 0 ? r.total : Data.total,
            });
          } else {
            Alert.handleError(e);
          }
        },
        {
          start: Dates.startDate,
          end: Dates.endDate,
          offset: Dates.page,
        }
      );
    }
  }, [Dates]);
  if (loading) {
    return (
      <div>
        <div
          style={{
            margin: '0 -4rem',
            padding: '2rem 4rem',
            borderBottom: '1px solid #dedede',
          }}
        >
          <div className='flex horizontally center-vertically'>
            <div className='flex-1'>
              <DateRange
                defaultValue={{
                  startDate: Moment().add(-31, 'd').valueOf(),
                  endDate: Moment().valueOf(),
                }}
                onChange={() => {}}
                className=''
                label='Export from and to'
                type='range'
              />
            </div>
          </div>
        </div>
        <Loader />
      </div>
    );
  }
  let items = [];
  Data.items.map((item) => {
    if (
      item.status !== 'FAILED' &&
      item.status !== 'ONGOING' &&
      (item.invoiceConfig?.reseller ||
        (item.invoiceConfig != null &&
          item.invoiceConfig.serviceInvoice !== 'SKIPPED'))
    ) {
      if (item.invoiceConfig?.reseller)
        items.push({
          content: item,
          render: [
            item.id,
            item.transId,
            item.provider,
            item.ticket?.flowId || item.providerId || '-',
            'Booking',
            item.status,
            item.ticket?.flowId || item.providerId || '-',
            item.category === 'DOMESTICROUNDFLIGHT' ? 'Round Trip' : 'One Way',
            'Domestic',
            'Economy',
            item.client.code,
            item.client.name,
            item.attrs['bookedBy'] || '-',
            item.attrs['bookedByEmail'] || '-',
            Moment(item.createdAt).format('DD MMM YYYY'),
            Moment(item.createdAt).format('HH:mm a'),
            Moment(item.updatedAt).format('DD MMM YYYY'),
            Moment(item.updatedAt).format('HH:mm a'),
            '27AAHCC1775A1ZX',
            'Maharashtra',
            item.invoiceConfig?.userGst?.gstin || '-',
            placeOfSupply(item.invoiceConfig?.userGst?.gstin || undefined),
            item.paymentType,
            item.amount,
            item.invoiceConfig?.irnFare?.total,
            item.ledger?.ledgerId || '-',
            item.ledger?.amount || '-',
            item.ledger?.balance || '-',
            item.attrs['orderId'] || '-',
            item.attrs['paymentId'] || '-',
            item.attrs['refundId'] || '-',
            '998551',
            'Invoice',
            item.invoiceConfig?.invoiceNo || '-',
            <div className='text-truncate'>
              {item.invoiceConfig?.invoiceIrn}
            </div>,
            <a target='_blank' href={item.invoiceConfig?.invoice || '-'}>
              Download
            </a>,
            item.invoiceConfig?.irnFare?.base || '-',
            item.invoiceConfig?.irnFare?.slab || '-',
            item.invoiceConfig?.irnFare?.oth || '-',
            item.invoiceConfig?.irnFare?.sGst || '-',
            item.invoiceConfig?.irnFare?.cGst || '-',
            item.invoiceConfig?.irnFare?.iGst || '-',
            item.invoiceConfig?.irnFare?.total || '-',
          ].map((title) => ({ weight: 2, title })),
        });
      if (
        item.invoiceConfig != null &&
        item.invoiceConfig.serviceInvoice != null &&
        item.invoiceConfig.serviceInvoice !== 'SKIPPED'
      ) {
        items.push({
          content: item,
          render: [
            item.id,
            item.transId,
            item.provider,
            item.ticket?.flowId || item.providerId || '-',
            'Service Invoice',
            item.status,
            item.ticket?.flowId || item.providerId || '-',
            item.category === 'DOMESTICROUNDFLIGHT' ? 'Round Trip' : 'One Way',
            'Domestic',
            'Economy',
            item.client.code,
            item.client.name,
            item.attrs['bookedBy'] || '-',
            item.attrs['bookedByEmail'] || '-',
            Moment(item.createdAt).format('DD MMM YYYY'),
            Moment(item.createdAt).format('HH:mm a'),
            Moment(item.updatedAt).format('DD MMM YYYY'),
            Moment(item.updatedAt).format('HH:mm a'),
            '27AAHCC1775A1ZX',
            'Maharashtra',
            item.invoiceConfig?.userGst?.gstin || '-',
            placeOfSupply(item.invoiceConfig?.userGst?.gstin || undefined),
            item.paymentType,
            item.amount,
            item.invoiceConfig?.serviceFare.total,
            item.ledger?.ledgerId || '-',
            item.ledger?.amount || '-',
            item.ledger?.balance || '-',
            item.attrs['orderId'] || '-',
            item.attrs['paymentId'] || '-',
            item.attrs['refundId'] || '-',
            '996425',
            'Service Invoice',
            item.invoiceConfig?.serviceInvoiceNo || '-',
            <div className='text-truncate'>
              {item.invoiceConfig?.serviceResponse?.response?.data?.Irn || '-'}
            </div>,
            <a target='_blank' href={item.invoiceConfig?.serviceInvoice || '-'}>
              Download
            </a>,
            item.invoiceConfig?.serviceFare.base || '-',
            item.invoiceConfig?.serviceFare.slab || '-',
            item.invoiceConfig?.serviceFare.sGst || '-',
            item.invoiceConfig?.serviceFare.cGst || '-',
            item.invoiceConfig?.serviceFare.iGst || '-',
            item.invoiceConfig?.serviceFare.oth || '-',
            item.invoiceConfig?.serviceFare.total || '-',
          ].map((title) => ({ weight: 2, title })),
        });
      }
      if (
        item.invoiceConfig !== null &&
        item.invoiceConfig.creditNote !== null &&
        item.invoiceConfig.creditNote !== 'PENDING'
      ) {
        let data = JSON.parse(item.invoiceConfig.creditRequest);
        items.push({
          content: item,
          render: [
            item.id,
            item.transId,
            item.provider,
            item.ticket?.flowId || item.providerId || '-',
            'Credit Note',
            item.status,
            item.ticket?.flowId || item.providerId || '-',
            item.category === 'DOMESTICROUNDFLIGHT' ? 'Round Trip' : 'One Way',
            'Domestic',
            'Economy',
            item.client.code,
            item.client.name,
            item.attrs['bookedBy'] || '-',
            item.attrs['bookedByEmail'] || '-',
            Moment(item.updatedAt).format('DD MMM YYYY'),
            Moment(item.updatedAt).format('HH:mm a'),
            Moment(item.updatedAt).format('DD MMM YYYY'),
            Moment(item.updatedAt).format('HH:mm a'),
            '27AAHCC1775A1ZX',
            'Maharashtra',
            item.invoiceConfig?.userGst?.gstin || '-',
            placeOfSupply(item.invoiceConfig?.userGst?.gstin || undefined),
            item.paymentType,
            item.amount,
            item.invoiceConfig?.irnFare?.total,
            item.ledger?.ledgerId || '-',
            item.ledger?.amount || '-',
            item.ledger?.balance || '-',
            item.attrs['orderId'] || '-',
            item.attrs['paymentId'] || '-',
            item.attrs['refundId'] || '-',
            '998551',
            'Credit Note',
            data?.data?.docDtls?.no || '-',
            <div className='text-truncate'>
              {item.invoiceConfig?.creditIrn || '-'}
            </div>,
            <a target='_blank' href={item.invoiceConfig?.creditNote || '-'}>
              Download
            </a>,
            item.invoiceConfig?.irnFare?.base || '-',
            item.invoiceConfig?.irnFare?.slab || '-',
            item.invoiceConfig?.irnFare?.sGst || '-',
            item.invoiceConfig?.irnFare?.cGst || '-',
            item.invoiceConfig?.irnFare?.iGst || '-',
            item.invoiceConfig?.irnFare?.oth || '-',
            item.invoiceConfig?.irnFare?.total || '-',
          ].map((title) => ({ weight: 2, title })),
        });
      }
      if (
        item.invoiceConfig !== null &&
        item.invoiceConfig.ancillaryInvoice !== null &&
        item.invoiceConfig.ancillaryError === null &&
        item.invoiceConfig.ancillaryInvoice !== 'SKIPPED'
      ) {
        let data = JSON.parse(item.invoiceConfig.cancellationRequest);
        items.push({
          content: item,
          render: [
            item.id,
            item.transId,
            item.provider,
            item.ticket?.flowId || item.providerId || '-',
            'Cancellation Charges',
            item.status,
            item.ticket?.flowId || item.providerId || '-',
            item.category === 'DOMESTICROUNDFLIGHT' ? 'Round Trip' : 'One Way',
            'Domestic',
            'Economy',
            item.client.code,
            item.client.name,
            item.attrs['bookedBy'] || '-',
            item.attrs['bookedByEmail'] || '-',
            Moment(item.createdAt).format('DD MMM YYYY'),
            Moment(item.createdAt).format('HH:mm a'),
            Moment(item.updatedAt).format('DD MMM YYYY'),
            Moment(item.updatedAt).format('HH:mm a'),
            '27AAHCC1775A1ZX',
            'Maharashtra',
            item.invoiceConfig?.userGst?.gstin || '-',
            placeOfSupply(item.invoiceConfig?.userGst?.gstin || undefined),
            item.paymentType,
            item.amount,
            data?.data?.valDtls?.totInvVal || '-',
            item.ledger?.ledgerId || '-',
            item.ledger?.amount || '-',
            item.ledger?.balance || '-',
            item.attrs['orderId'] || '-',
            item.attrs['paymentId'] || '-',
            item.attrs['refundId'] || '-',
            '998551',
            'Cancellation Charges',
            data?.data?.docDtls?.no || '-',
            <div className='text-truncate'>
              {item.invoiceConfig?.cancellationIrn || '-'}
            </div>,
            <a
              target='_blank'
              href={item.invoiceConfig?.cancellationCharges || '-'}
            >
              Download
            </a>,
            data?.data?.valDtls?.assVal || '-',
            item.invoiceConfig?.irnFare?.slab || '-',
            `0`,
            data?.data?.valDtls?.sgstVal || '-',
            data?.data?.valDtls?.cgstVal || '-',
            data?.data?.valDtls?.igstVal || '-',
            data?.data?.valDtls?.totInvVal || '-',
          ].map((title) => ({ weight: 2, title })),
        });
      }
      if (
        item.invoiceConfig !== null &&
        item.invoiceConfig.cancellationCharges !== null &&
        item.invoiceConfig.cancellationCharges !== 'PENDING'
      ) {
        let data = JSON.parse(item.invoiceConfig.ancillaryRequest);
        items.push({
          content: item,
          render: [
            item.id,
            item.transId,
            item.provider,
            item.ticket?.flowId || item.providerId || '-',
            'Cancellation Charges',
            item.status,
            item.ticket?.flowId || item.providerId || '-',
            item.category === 'DOMESTICROUNDFLIGHT' ? 'Round Trip' : 'One Way',
            'Domestic',
            'Economy',
            item.client.code,
            item.client.name,
            item.attrs['bookedBy'] || '-',
            item.attrs['bookedByEmail'] || '-',
            Moment(item.createdAt).format('DD MMM YYYY'),
            Moment(item.createdAt).format('HH:mm a'),
            Moment(item.updatedAt).format('DD MMM YYYY'),
            Moment(item.updatedAt).format('HH:mm a'),
            '27AAHCC1775A1ZX',
            'Maharashtra',
            item.invoiceConfig?.userGst?.gstin || '-',
            placeOfSupply(item.invoiceConfig?.userGst?.gstin || undefined),
            item.paymentType,
            item.amount,
            data?.data?.valDtls?.totInvVal || '-',
            item.ledger?.ledgerId || '-',
            item.ledger?.amount || '-',
            item.ledger?.balance || '-',
            item.attrs['orderId'] || '-',
            item.attrs['paymentId'] || '-',
            item.attrs['refundId'] || '-',
            '998551',
            'Cancellation Charges',
            data?.data?.docDtls?.no || '-',
            <div className='text-truncate'>
              {item.invoiceConfig?.cancellationIrn || '-'}
            </div>,
            <a
              target='_blank'
              href={item.invoiceConfig?.cancellationCharges || '-'}
            >
              Download
            </a>,
            data?.data?.valDtls?.assVal || '-',
            item.invoiceConfig?.ancillaryFare?.slab || '-',
            `0`,
            data?.data?.valDtls?.sgstVal || '-',
            data?.data?.valDtls?.cgstVal || '-',
            data?.data?.valDtls?.igstVal || '-',
            data?.data?.valDtls?.totInvVal || '-',
          ].map((title) => ({ weight: 2, title })),
        });
      }
    }
  });
  return (
    <div style={{ width: '100%' }}>
      <div
        style={{
          margin: '0 -4rem',
          padding: '2rem 4rem',
          paddingTop: 0,
          borderBottom: '1px solid #dedede',
        }}
      >
        <div className='flex horizontally center-vertically'>
          <div className='flex-1'>
            <DateRange
              defaultValue={{
                startDate: Moment().add(-31, 'd').valueOf(),
                endDate: Moment().valueOf(),
              }}
              onChange={(dates) => {
                setDates({
                  ...dates,
                  page: 0,
                });
              }}
              className=''
              label='Export from and to'
              type='range'
            />
          </div>
        </div>
      </div>
      <RBAC role={ROLES.REPORTS}>
        <Button onClick={downloadData} className='btn btn-black'>
          Download Data
        </Button>
      </RBAC>
      {Data.items.length === 0 && (
        <div className='border pd6 mt4 flex vertically center'>
          <img
            alt='fetch'
            src={Graph}
            style={{ width: '5rem', height: '5rem' }}
          />
          <h3 className='fw-bold mt4'>No Data Found</h3>
          <p>No data found in this configuration</p>
        </div>
      )}
      <ScrollableDynamicTable
        autoTrigger={false}
        tableStyle={{
          borderSpacing: 0,
        }}
        width={150}
        search={Dates}
        setPage={(page) => {
          setDates({
            ...Dates,
            page,
            total: Dates.totalPages,
          });
        }}
        data={{
          current: Data.currentPage,
          total: Data.totalPages,
          items,
        }}
        headers={[
          'Id',
          'Trans Id',
          'Provider',
          'Provider Id',
          'Cart Type',
          'Booking Status',
          'Provider Id',
          'Journey Type',
          'Category',
          'Cabin Class',
          'Client Code',
          'Client Name',
          'Booker Name',
          'Booker Email Address',
          'Created On Date',
          'Created On Time',
          'Updated On Date',
          'Updated On Time',
          'CT Gstin',
          'Ct State',
          'Buyer Gstin',
          'State Of Supply',
          'Payment Type',
          'Amount Collected',
          'Line Item Amount',
          'Ledger Id',
          'Ledger Amount',
          'Ledger Balance',
          'Pg Order Id',
          'Pg Payment Id',
          'Pg Refund Id',
          'Hsn',
          'Document Type',
          'Document Number',
          'Document Irn',
          'Document File',
          'Base Fare',
          'Slab',
          'Other Charges',
          'SGST',
          'CGST',
          'IGST',
          'TOTAL',
        ].map((item) => ({
          weight: 2,
          title: item,
        }))}
      />
    </div>
  );
}
