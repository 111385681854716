import React, {useCallback, useEffect, useState} from 'react'
import {
	Button,
	Loader,
	Modal,
	ScrollableDynamicTable,
	SearchInput,
	SidePane,
	TOAST as Alert
} from "../../../../../../retro";
import {get, post} from "../../../../../Network/Axios";
import EditIcon from "@atlaskit/icon/glyph/open";
import {useHistory, useParams} from "react-router-dom";
import ManageContract from "./ManageContract";

export default function index() {
	let [loading, setLoading] = useState(true);
	const [selected, setSelect] = useState(undefined)
	const [clientModal,setClientModal] = useState(0)
	const HISTORY = useHistory();
	const {id} = useParams()
	const [modal, setModal] = useState(false)
	const [search, setSearch] = useState({
		page: 0,
		sort: 'id',
		order: 'desc',
		q: undefined
	})
	const [Data, SetData] = useState({
		total: 0,
		currentPage: 0,
		totalPages: 0,
		items: []
	})
	const loadPage = useCallback((search) => {
		get(`/contracted/${id}/contracts`, (e, r) => {
			if (r) {
				SetData({
					...r
				})
				setLoading(false)
			} else {
				Alert.handleError(e)
			}
		}, {
			offset: search.page,
			sort: search.sort,
			order: search.order,
			q: search.q
		})
	}, [SetData])
	useEffect(() => {
		loadPage(search)
	}, [search])
	if (loading) {
		return (<Loader/>)
	}
	return (
		<div>
			<Button onClick={() => {
				setModal(true)
			}} className="btn btn-black mt4">
				+ Add Contract
			</Button>
			{
				clientModal!==0&&<SidePane onClose={()=>{setClientModal(0)}} title="Room Contracts" description="Details of contracts with this hotel and client.">
					<ManageContract id={clientModal}/>
				</SidePane>
			}
			{
				modal &&
				<Modal onClose={() => {
					setModal(false)
				}} button="Create Contract" onSubmit={() => {
					if (selected) {
						post(`/contracted/contract/${id}`, {
							client : selected.id,
							contract:id
						},(e, r) => {
							if (r) {
								setSearch({
									...search,
									page: 0
								})
								setModal(false)
							} else {
								Alert.handleError(e)
							}
						})
					}
				}} title="Add Contract" description="Enter details to add a new contract">
					<SearchInput resultKey="items" api="clients" morph={true} label="Select Client" onSelect={setSelect}
								 selected={selected}/>
				</Modal>
			}
			<div className="relative">

				<ScrollableDynamicTable
					setSearch={(search) => {
						setSearch({
							...search,
							page: 0
						})
					}} search={search}
					setPage={page => {
						setSearch({
							...search,
							page
						})
					}}
					data={{
						current: Data.currentPage,
						total: Data.totalPages,
						items: Data.items.map(item => {
							const click = () => {
								setClientModal(item.id)
							}
							return {
								content: item,
								render: [
									{
										children: (
											<div onClick={() => {

											}}>
												<h3 className="fw-bold">
													{item.clientName}
												</h3>
												<p className="text">
													{item.code}
												</p>
											</div>
										),
										weight: 2,
									},
									{
										weight: 1,
										title: item.active?"Active":"In Active"
									},
									{
										weight: 1,
										buttons: [
											{
												title: "Edit",
												className: 'btn-secondary btn-sm',
												before: (<EditIcon size="small"/>),
												onClick: click
											}
										],
										actions: [
											{
												title: "Quick Actions",
												actions: [
													{
														title: "Edit",
														before: (<EditIcon size="small"/>),
														onClick: click
													}
												]
											}
										]
									}
								]
							}
						})
					}} headers={[
					{
						weight: 2,
						title: "Name",
					},
					{
						weight: 1,
						title: "Status",
					},
					{
						weight: 1,
						title: "Actions",
						style: {
							justifyContent: "end",
							paddingRight: 18
						}
					}
				]}/>
			</div>
		</div>
	)
}
