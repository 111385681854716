import React, {useRef, useState} from 'react'
import {Button, Input, KeyValue, Loader, Modal, NavDivider, Navigation, Seperator, TOAST} from "retro/index";
import {get, post} from "App/Network/Axios";

export default function UpdateBreakupController() {
	const [loading, setLoading] = useState(false)
	const TRANSID = useRef()
	const [modal, setModal] = useState(undefined)
	const [data, setData] = useState(undefined)
	const [error, setError] = useState(undefined)

    const reload = () => {
        get(`/meta/${data.transId}/details`, (e, r) => {
            setLoading(false)
            if (r) {
                setData(r)
            } else {
                TOAST.handleError(e)
            }
        })
    }

	const BASE = useRef();
	const OTHER = useRef();
	const GST = useRef();

	return (
		<div>
			{
				modal && <Modal onClose={() => {
					setModal(undefined)
				}} title="Edit Breakup" description="Edit details to added here">
					<Input defaultValue={modal.gst ? modal.gst * 20 : modal.baseFare} ref={BASE} label="Taxable Fare" type="number" placeholder="Enter Base Fare"/>
					<Input defaultValue={modal.total - (modal.gst?(modal.gst * 21):(modal.baseFare + modal.baseFare/20))} ref={OTHER} label="Other Fare" type="number" placeholder="Enter Other Fare"/>
					<Input defaultValue={modal.gst ? modal.gst:modal.baseFare/20} ref={GST} label="Gst Fare" type="number" placeholder="Enter Gst Fare"/>
					<div className="mt2">
                        <KeyValue  title="TOTAL" value={`${modal.total}`}/>
                    </div>
                    <Button onClick={() => {

						let base = BASE.current.value;
						base = parseFloat(base).toFixed(2);
						if (!base) {
							TOAST.error("Enter a valid base fare")
							return
						}

						let other = OTHER.current.value;
						other = parseFloat(other).toFixed(2);
						if (!other) {
							TOAST.error("Enter a valid other fare")
							return
						}

						let gst = GST.current.value;
						gst = parseFloat(gst).toFixed(2);
						if (!gst) {
							TOAST.error("Enter a valid gst fare")
							return
						}

						post(`/meta/${data.transId}/flight/breakup/update`, {
							traveller: modal.traveller,
							origin: modal.origin,
							destination: modal.destination,
							base : parseFloat(base),
                            other : parseFloat(other),
                            oth : parseFloat(other),
                            gst : parseFloat(gst),
							total : parseFloat(base) + parseFloat(gst) + parseFloat(other)
						}, (e, r) => {
                            setModal(false)
							if (e) {
								TOAST.handleError(e)
							} else {
								TOAST.success("Update done successfully!!")
                                reload()
							}
						})

					}}>
						Update Breakup
					</Button>
				</Modal>
			}
			<Navigation backUrl={'back'} title="Update Breakup"/>
			<div className="flex center-vertically horizontally mt2 mb2">
				<div className="flex-1 mr2">
					<Input
						ref={TRANSID}
						type="text"
						label="Transaction Id"
						placeholder="Transaction Id"
					/>
				</div>
				<Button
					onClick={() => {
						setLoading(true)
						setError(undefined)
						let transId = TRANSID.current.value;
						get(`/meta/${transId}/details`, (e, r) => {
							setLoading(false)
							if (r) {
								setData(r)
							} else {
								TOAST.handleError(e)
							}
						})
					}}
					className="btn-black btn-primary"
					margin="pd2 mt4"
					loading={loading}
				>
					Fetch Ticket
				</Button>
			</div>
			<NavDivider/>
			<div className="flex horizontally center-vertically">
				<div className="flex-2">
					<div className="mt4 border rounded-md pd4">
						{
							error ? error : loading ? <Loader/> : <>
								{
									!data && "Fetch details by clicking fetch"
								}
								{
									data && <>
										<h3 className="mb2">
											Pax Breakup
										</h3>
										<div className="flex horizontally center-vertically">
											<div className="flex-1">
												Traveller
											</div>
											<div className="flex-1">
												PNR
											</div>
											<div className="flex-1">
												Sector
											</div>
											<div className="flex-1">
												Taxable
											</div>
											<div className="flex-1">
												Other
											</div>
											<div className="flex-1">
												Gst
											</div>
											<div className="flex-1">
												Total
											</div>
											<div className="flex-1 align-end">
												Actions
											</div>
										</div>
										{
											(data?.ticket?.recko?.breakups || []).map(item => {
												return (<div className="flex mt2 horizontally center-vertically">
													<div className="flex-1 text-small text-muted">
														{item.traveller}
													</div>
													<div className="flex-1 text-small text-muted">
														{item.pnr}
													</div>
													<div className="flex-1 text-small text-muted">
														{item.origin} - {item.destination}
													</div>
													<div className="flex-1 text-small text-muted">
														{item.baseFare}
													</div>
													<div className="flex-1 text-small text-muted">
														{item.otherCharges}
													</div>
													<div className="flex-1 text-small text-muted">
														{item.gst}
													</div>
													<div className="flex-1 text-small text-muted">
														{item.total}
													</div>
													<div className="flex-1 align-end">
														<p className="fw-600" style={{
															color: 'red',
															cursor: 'pointer'
														}} onClick={() => {
															setModal(item)
														}}>
															Edit
														</p>
													</div>
												</div>)
											})
										}
										<Seperator/>
										<h3 className="mb2">
											Gst Breakup
										</h3>
										<div className="flex horizontally center-vertically">
											<KeyValue value={`${parseFloat(data.invoice?.irnFare?.base || "0").toFixed(2)}`}
													  title="Base Charges"/>
											<KeyValue value={`${parseFloat(data.invoice?.irnFare?.oth || "0").toFixed(2)}`}
													  title="Other Charges"/>
											<KeyValue value={`${parseFloat(data.invoice?.irnFare?.cGst || "0").toFixed(2)}`}
													  title="CGst Charges"/>
											<KeyValue value={`${parseFloat(data.invoice?.irnFare?.sGst || "0").toFixed(2)}`}
													  title="Sgst Charges"/>
											<KeyValue value={`${parseFloat(data.invoice?.irnFare?.iGst || "0").toFixed(2)}`}
													  title="IGst Charges"/>
											<KeyValue value={`${parseFloat(data.invoice?.irnFare?.slab || "0").toFixed(2)}`}
													  title="Slab"/>
											<KeyValue
												value={`${parseFloat(data.invoice?.irnFare?.total || "0").toFixed(2)}`}
												title="Total"/>
										</div>
									</>
								}
							</>
						}
					</div>
				</div>
				{
					data && !error && <iframe src={data.invoice.invoice && data.invoice.invoice!=="SKIPPED"? data.invoice.invoice: data.invoice.serviceInvoice} style={{
						height: 375,
						width: 375,
						marginLeft: 36
					}}/>
				}
			</div>
		</div>
	)
}
