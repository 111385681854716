import React, {useState} from 'react'
import {NavDivider, Navigation} from "../../../../../../retro";
import Step1 from './Step1'
import Step2 from './Step2'

export default function index() {
	const [data,setData] = useState({
		step : 0
	})
	return (
		<div>
			<Navigation backUrl={''} title="Create Client" chips={['All Clients', "Create Client"]} description="Enter details to add a new client."/>
			<NavDivider/>
			{
				data.step===0&&<Step1 data={data} setData={setData} onNext={newdata=>{
					setData({
						...data,
						...newdata,
						step:1
					})
				}}/>
			}{
			data.step===1&&<Step2 onBack={(newdata)=>{
				setData({
					...data,
					...newdata,
					step:0
				})
			}} onReset={()=>{
				setData({
					step:0
				})
			}} data={data}/>
		}
		</div>
	)
}
