import Common,{Common2,sellerAddress} from "./Common";
import Moment from "moment";

const roundOff = num => {
	return (Math.round(num * 100) / 100).toFixed(2);
}


let traveller = (item) => {
	let string = undefined;
	try {
		item.ticket.travellers.forEach((traveller) => {
			if (!string) {
				string = `${traveller.name}`;
			} else {
				string = `${string} , ${traveller.name}`;
			}
		});
	}catch (e){
		return "-";
	}
	return string;
};


let airlines = (item) => {
	let string = undefined;
	try {
		item.ticket.segments.forEach((segment) => {
			if (!string) {
				string = `${segment.airline}`;
			} else {
				string = `${string} , ${segment.airline}`;
			}
		});
	}catch (e){
		return "-";
	}
	return string;
};
let flightCode = (item) => {
	let string = undefined;
	try{
		item.ticket.segments.forEach((segment) => {
			if (!string) {
				string = `${segment.flightCode} ${segment.flightNumber}`;
			} else {
				string = `${string} , ${segment.flightCode}  ${segment.flightNumber}`;
			}
		});
	}catch (e){
		return "-"
	}
	return string;
};
let segments = (item) => {
	let string = undefined;
	try {
		item.ticket.segments.forEach((segment) => {
			if (!string) {
				string = `${segment.departure.code}-${segment.arrival.code}`;
			} else {
				string = `${string} , ${segment.departure.code}-${segment.arrival.code}`;
			}
		});
	}catch (e){
		return "-"
	}
	return string;
};
let pnrs = (item) => {
	let set = {};
	let string = undefined;
	try {
		item.ticket.travellers.forEach((traveller) => {
			Object.values(traveller.pnrs).forEach((item) => {
				set[item] = item;
			});
		});
		Object.values(set).forEach((value) => {
			if (!string) {
				string = `${value}`;
			} else {
				string = `${string} , ${value}`;
			}
		});
	}catch (e){
		return "-"
	}
	return string;
};
let tickets = (item) => {
	let set = {};
	let string = undefined;
	try {
		item.ticket.travellers.forEach((traveller) => {
			Object.values(traveller.tickets).forEach((item) => {
				set[item] = item;
			});
		});
		Object.values(set).forEach((value) => {
			if (!string) {
				string = `${value}`;
			} else {
				string = `${string} , ${value}`;
			}
		});
	}catch (e){
		return "-";
	}
	return string;
};


function CancellationInvoiceDate(settlement) {
	if(settlement?.config?.cancellationDate){
		return settlement.config.cancellationDate
	}
	if(settlement?.config?.cancellationRequest){
		let parsedCancellationRequest = JSON.parse(
			settlement?.config?.cancellationRequest
		);
		let parsedCancelledDate = Moment(
			parsedCancellationRequest.data?.docDtls?.dt,
			'DD/MM/YYYY'
		).format('DD-MMM-YYYY');
		return parsedCancelledDate
	}
	return  "-";
}

export default function flight(item,dates){
	let values = []
	values.push(...Common(item,dates))
	try {
		values.push(
			...[
				flightCode(item),
				airlines(item),
				traveller(item),
				'-',
				'-',
				'-',
				'-',
				'-',
				'-',
				'-',
				'-',
				'-',
				'-',
				'-',
				'-',
				'-',
				'-',
				segments(item),
				item.ticket.segments && item.ticket.segments.length ? item.ticket.segments[0].departure.city:"-",
				item.ticket.segments && item.ticket.segments.length ? item.ticket.segments[0].arrival.city:"-",
				pnrs(item),
				tickets(item),
				item.ticket.cabinClass || 'ECONOMY',
				item.ticket.fareType || '-',
				item.ticket.segments && item.ticket.segments.length ?Moment(item.ticket.segments[0].departureTime).format('DD MMM YYYY'):"-",
				item.ticket.segments && item.ticket.segments.length ?Moment(
					item.ticket.segments[item.ticket.segments.length - 1].arrivalTime
				).format('DD MMM YYYY'):"-",
				item.service.toUpperCase() === 'FLIGHT' ? 'One Way' : 'Round',
			]
		)
	}catch (e){
		console.log(e)
	}
	let settlement = item.item;
	let transId = item?.transId;
	values.push(...[
		"YES",
		settlement.config.cancellationIrn?'Generated':"Pending",
		'Cancellation Invoice',
		settlement.config?.cancellationInvoiceNo||"-",
		CancellationInvoiceDate(settlement),
		settlement.config?.cancellationIrn||"-",
		settlement.config?.cancellationCharges||"-",
		'-',
		roundOff(settlement.config.cancellationFare?.base || 0),
		roundOff(settlement.config.cancellationFare?.oth || 0),
		roundOff(settlement.config.cancellationFare?.slab || 0),
		roundOff(settlement.config.cancellationFare?.iGst || 0),
		roundOff(settlement.config.cancellationFare?.sGst || 0),
		roundOff(settlement.config.cancellationFare?.cGst || 0),
		roundOff(settlement.config.cancellationFare?.total || 0),
		"-",
		settlement.config.sellerGst?.gstin||"27AAHCC1775A1ZX",
		sellerAddress(settlement.config.sellerGst||{gstin:"27AAHCC1775A1ZX"}),
		settlement.config.userGst.gstin,
		settlement.config.userGst.address,
		'Maharashtra',
		settlement.config.placeOfSupply,
		'996425',
		`CNI - ${transId}`,
	]);
	values.push(...Common2(item,settlement.config.invoiceNo||"-"))
	return values;
}
