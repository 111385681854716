import React, { useEffect, useState, useRef } from 'react';
import {
  Loader,
  Navigation,
  ScrollableDynamicTable,
  TOAST,
  DateInput, SelectInput,
  Button
} from 'retro';
import Moment from 'moment';
import { get } from 'App/Network/Axios';
import MultiSelectDropdown from 'App/Components/MultiSelectDropdown';
import RBAC from 'App/HOCs/RBAC';
import ROLES from 'App/Constants/Roles';
import { DownloadCsv } from 'retro/Utils';

export default function index() {
  const [loading, setLoading] = useState(false);
  const DATETYPE = useRef();
  const STARTDATE = useRef();
  const ENDDATE = useRef();
  const [filters,setFilters] = useState({
    start: Moment().add(-31,'d').valueOf(),
    end: Moment().valueOf(),
    page: 0,
    totalPages: 0,
    filterOn: 'createdAt',
  });
  const [Data, SetData] = useState({
    total: 0,
    currentPage: 0,
    totalPages: 0,
    items: [],
  });
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [allClients] = useState([]);

  // setting the client list on load
  useEffect(()=>{
    let templist = [];
    get('/clients/dump',(e,r)=>{
      if(r){
        r.items.map((client)=>{
          templist.push(client.code)
        })
        allClients.push(...r.items);
        setClientList([...templist]);
      }
      },{
      active:true
    })
  },[])
  const downloadData = () => {
      setLoading(true);
      let clientIds = [];
      if(selectedOptions.length > 0){
        clientIds = allClients.filter((client)=> selectedOptions.includes(client.code)).map((client)=>client.id);
      }
      get(
        `/meta/insurance/all`,
        (e, r) => {
          if (e) {
            setLoading(false);
            console.log(e);
            TOAST.error('Unable to download');
            return;
          }
          let data = [];
          let headers = [
            'Id',
            'Provider',
            'Category',
            'Booking Id',
            'Status',
            'Provider Id',
            'Payment Type',
            'Amount',
            'Ledger Id',
            'Ledger Amount',
            'Ledger Balance',
            'Voucher',
            'Gstin',
            'Seller Gstin',
            'Client Code',
            'Client Name',
            'Created On',
            'Updated On',
            'Base Amount',
            'Service Charge',
            'Document IRN',
            'Document Number',
            'Service Charge Invoice',
            'Service Charges Slab',
            'Service Charges Base Fare',
            'Service Charges Other Charges',
            'Service Charges SGST',
            'Service Charges CGST',
            'Service Charges IGST',
            'Refund Amount',
            'Insurance Handling Charges'
          ];
          data.push(headers);
          r?.items?.map((item) => {
                data.push([
                  item.id,
                  item.provider,
                  item.category,
                  item.transId,
                  item.status,
                  item.ticket?.ticket || item.ticket?.trainPnr || '-',
                  item.paymentType,
                  item.amount,
                  item.ledger?.ledgerId || '-',
                  item.ledger?.amount || '-',
                  item.ledger?.balance || '-',
                  item.invoiceConfig?.file || '-',
                  item.invoiceConfig?.userGst?.gstin || '-',
                  item.invoiceConfig?.sellerGst?.gstin || '27AAHCC1775A1ZX',
                  item.client.code,
                  item.client.name,
                  Moment(item.createdAt).format('DD MMM YYYY HH:mm a'),
                  Moment(item.updatedAt).format('DD MMM YYYY HH:mm a'),
                  '-',
                  item.invoiceConfig?.serviceCharges || '-',
                  item.invoiceConfig?.serviceIrn || '-',
                  item.invoiceConfig?.serviceInvoiceNo || '-',
                  item.invoiceConfig?.serviceInvoice || '-',
                  item.invoiceConfig?.serviceFare?.slab || '-',
                  item.invoiceConfig?.serviceFare?.base || '-',
                  item.invoiceConfig?.serviceFare?.oth || '-',
                  item.invoiceConfig?.serviceFare?.sGst || '-',
                  item.invoiceConfig?.serviceFare?.cGst || '-',
                  item.invoiceConfig?.serviceFare?.iGst || '-',
                  item.refundAmount || '-',
                  item?.ticket?.vendorAmount || '-'
                ]);
          });
          DownloadCsv(
            `insurance_bos_${Moment(STARTDATE.current.value).format('DD_MM_YYYY')}_${Moment(
              ENDDATE.current.value
            ).format('DD_MM_YYYY')}.csv`,
            data
          );
          setLoading(false);
        },
        {
          start: Moment(STARTDATE.current.value).valueOf(),
          end: Moment(ENDDATE.current.value).add(1,'d').valueOf(),
          filterOn: DATETYPE.current.value,
          client: clientIds.toString(),
        }
      );
    };
  useEffect(() => {
    setLoading(true);
    let clientIds = [];
    if(selectedOptions.length > 0){
      clientIds = allClients.filter((client)=> selectedOptions.includes(client.code)).map((client)=>client.id);
    }
    if (filters) {
      get(
        `/meta/insurance`,
        (e, r) => {
          if (r) {
            SetData({
              ...r,
              currentPage: filters.page ? filters.page : 0,
              totalPages: r.totalPages > 0 ? r.totalPages : Data.totalPages,
              total: r.total > 0 ? r.total : Data.total,
            });
          } else {
            TOAST.handleError(e);
          }
        setLoading(false);
        },
        {
          start: Moment(STARTDATE.current.value).valueOf(),
          end: Moment(ENDDATE.current.value).add(1,'d').valueOf(),
          offset: filters.page,
          filterOn: DATETYPE.current.value,
          client: clientIds.toString(),
        }
      );
    }
  }, [filters,selectedOptions]);
  let items = [];
  Data.items.forEach((item) => {
    items.push({
      content: item,
      render: [
        item.id,
        item.provider,
        item.category,
        item.transId,
        item.status,
        item.ticket?.ticket || item.ticket?.trainPnr || '-',
        item.paymentType,
        item.amount,
        item.ledger?.ledgerId || '-',
        item.ledger?.amount || '-',
        item.ledger?.balance || '-',
        item.invoiceConfig?.file || '-',
        item.invoiceConfig?.userGst?.gstin || '-',
        item.invoiceConfig?.sellerGst?.gstin || '27AAHCC1775A1ZX',
        item.client.code,
        item.client.name,
        Moment(item.createdAt).format('DD MMM YYYY HH:mm a'),
        Moment(item.updatedAt).format('DD MMM YYYY HH:mm a'),
        '-',
        item.invoiceConfig?.serviceCharges || '-',
        item.invoiceConfig?.serviceIrn || '-',
        item.invoiceConfig?.serviceInvoiceNo || '-',
        item.invoiceConfig?.serviceInvoice || '-',
        item.invoiceConfig?.serviceFare?.slab || '-',
        item.invoiceConfig?.serviceFare?.base || '-',
        item.invoiceConfig?.serviceFare?.oth || '-',
        item.invoiceConfig?.serviceFare?.sGst || '-',
        item.invoiceConfig?.serviceFare?.cGst || '-',
        item.invoiceConfig?.serviceFare?.iGst || '-',
        item.refundAmount || '-',
        item?.ticket?.vendorAmount || '-'
      ].map((title) => {
        if (title.content) {
          return { weight: 2, children: title.content };
        } else {
          return { weight: 2, title };
        }
      }),
    });
  });
  return (
    <div style={{ width: "100%" }}>
      <Navigation
        backUrl={"back"}
        title='Insurance Bookings'
        chips={["Meta", "Insurance"]}
        description='Insurance bookings appears below.'
      />
      <div
        style={{
          margin: "0 -4rem",
          padding: "1rem 4rem",
          borderBottom: "1px solid #dedede",
        }}
      >
        <div className='flex horizontally center-vertically'>
          <SelectInput
            options={[
              {label: "Created At Date", value: "createdAt"},
              {label: "Document", value: "document"},
            ]}
            ref={DATETYPE}
            type='text'
            label='Date Range Filter'
            hidePlaceholder={true}
            className='mr1 flex-1'
            onChange={()=>{
              setFilters({
                ...filters, filterOn: DATETYPE.current.value
              })
            }}
          />
          <DateInput
            label="From Date"
            className='flex-1 ml1 mr1 mt1'
            ref={STARTDATE}
            defaultValue={filters.start}
            onChange={()=>{
              setFilters({
                ...filters, start: STARTDATE.current.value, page:0
              })
            }}
          />
          <DateInput
            label="To Date"
            ref={ENDDATE}
            defaultValue={filters.end}
            className='flex-1 mr1 ml1 mt1'
            onChange={()=>{
              setFilters({
                ...filters, end: ENDDATE.current.value
              })
            }}
          />
          <MultiSelectDropdown
            label="Clients"
            placeholder="Select Clients"
            options={clientList}
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
            className='ml1 mr1 mt1 flex-1'
          />
        </div>
      </div>
      {
        loading ?
        <div className='pd6'>
          <Loader />
      </div>
      :
      <div>
        <RBAC>
          <Button onClick={downloadData} className='btn btn-black'>
            Download Data
          </Button>
        </RBAC>
        <ScrollableDynamicTable
          autoTrigger={false}
          tableStyle={{
            borderSpacing: 0,
          }}
          width={150}
          search={filters}
          setPage={(page) => {
            setFilters({
              ...filters,
              page,
              totalPages: Data.totalPages,
            });
          }}
          data={{
            current: Data.currentPage,
            total: Data.totalPages,
            items,
          }}
          headers={[
            'Id',
            'Provider',
            'Category',
            'Booking Id',
            'Status',
            'Provider Id',
            'Payment Type',
            'Amount',
            'Ledger Id',
            'Ledger Amount',
            'Ledger Balance',
            'Voucher',
            'Gstin',
            'Seller Gstin',
            'Client Code',
            'Client Name',
            'Created On',
            'Updated On',
            'Base Amount',
            'Service Charge',
            'Document IRN',
            'Document Number',
            'Service Charge Invoice',
            'Service Charges Slab',
            'Service Charges Base Fare',
            'Service Charges Other Charges',
            'Service Charges SGST',
            'Service Charges CGST',
            'Service Charges IGST',
            'Refund Amount',
            'Insurance Handling Charges'
          ].map((item) => ({
            weight: 2,
            title: item,
          }))}
        />
      </div>
      }
    </div>
  );
}
