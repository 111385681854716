import moment from 'moment';
import React from 'react';
import {Grids} from '../../../../../retro';

function Bus({amount, bookedBy, service, createdAt, status, ticket, documents}) {

    const bus = ticket.bus
    const boardPoints = bus?.boarding
    const dropPoints = bus?.dropping

    return (
        <div className='text-small'>
            <Grids.Row>
                <Grids.Column grid={{mobile:12, medium:6, large:6}}>
                    <div className=' fw-bold'>Booking Date</div>
                    <div className=''>{moment(createdAt).format('DD MMM YYYY')}</div>
                </Grids.Column>
                <Grids.Column grid={{mobile:12, medium:6, large:6}}>
                    <div className=' fw-bold'>Booking ID</div>
                    <div className=''>{ticket.bookingId}</div>
                </Grids.Column>
            </Grids.Row>
            <Grids.Row className='mt3'>
                <Grids.Column grid={{mobile:12, medium:6, large:6}}>
                    <div className=' fw-bold'>Booking Amount</div>
                    <div className=''>Rs. {ticket.amount}</div>
                </Grids.Column>
                <Grids.Column grid={{mobile:12, medium:6, large:6}}>
                    <div className=' fw-bold'>PNR</div>
                    <div>{ticket.pnr}</div>
                </Grids.Column>
            </Grids.Row>
            <Grids.Row className='mt3'>
                <Grids.Column grid={{mobile:12, medium:6, large:6}}>
                    <div className=' fw-bold'><i className='fa fa-user mr1'/>Travellers</div>
                    {
                        ticket.travellers.map((traveller,index)=>{
                            return <div>{index+1}) {traveller.name}</div>
                        })
                    }
                </Grids.Column>
                <Grids.Column grid={{mobile:12, medium:6, large:6}}>

                </Grids.Column>
            </Grids.Row>
            {
                documents&&documents.length>0&&
                <div className='mt3'>
                    <div className=' fw-bold'>Files</div>
                    <div className='flex flex-erap align-center mt2'>
                        {
                            documents.map((doc,index)=>{
                                return <div key={index} onClick={()=>window.open(doc.path,'_blank')} className='pd1 pl2 pr2 rounded-pill text-small border hoverable mr1'><i className='far fa-file-alt mr1'/>{doc.title}</div>
                            })
                        }
                    </div>
                </div>
            }
            {
                bus&&
                <>
                <div className='row text-small'>
                    <div className='col-12 border bg-light rounded-3 pd1 mt3'>
                        <div>
                            {bus.provider}<br/>
                            <b>{bus.label}</b><br/>
                            Seats {ticket?.travellers.map((traveller, i)=>{
                                return `[${traveller?.seatNo}] `
                            })}
                        </div>
                    </div>
                </div>
                <div className='row text-small mt2'>
                    <div className='col-6 pr1'>
                        <div className='border bg-light rounded-md pd1 h-100'>
                            <label className='text-muted'>BOARD</label>
                            {
                                boardPoints.map((point, i)=>{
                                    if(point.code===ticket.pickupId){
                                        return <div key={i}>{point.name} at <b>{moment(point.time).format('hh:MM A on DD MMM YY')}</b></div>
                                    }
                                    return <></>
                                })
                            }
                        </div>
                    </div>
                    <div className='col-6 pl1'>
                        <div className='border bg-light rounded-md pd1 h-100'>
                            <label className='text-muted'>DROP</label>
                            {
                                dropPoints.map((point, i)=>{
                                    if(point.code===ticket.dropId){
                                        return <div key={i}>{point.name} at <b>{moment(point.time).format('hh:MM A on DD MMM YY')}</b></div>
                                    }
                                    return <></>
                                })
                            }
                        </div>
                    </div>
                </div>
                </>
            }
        </div>
    );
}

export default Bus;
