import ReactHelmet from 'react-helmet'

const Helmet = () => {
	if(window.origin.includes("cleartrip")){
		return (
			<ReactHelmet>
				<link rel="icon" href="/favicons/cleartrip.png" />
				<link rel="manifest" href="/manifests/cleartrip.json" />
				<meta name="description" content="Cleartrip Super Dashboard"/>
				<title>Cleartrip Super Dashboard</title>
			</ReactHelmet>
		)
		return <></>
	}
	if(window.origin.includes("yespayleaf") || window.origin.includes('yesleap')){
		return (
			<ReactHelmet>
				<link rel="icon" href="https://www.yesbank.in/_cache_6faa/favicon.ico" />
				<meta name="description" content="Yespayleaf Super Dashboard"/>
				<title>Yes Pay Leaf Super Dashboard</title>
			</ReactHelmet>
		)
		return <></>
	}
}

export default Helmet;
