import React, {useCallback, useEffect, useState} from 'react'
import {Loader, NavDivider, Navigation, ScrollableDynamicTable, TOAST as Alert, SearchBox} from "../../../../../../retro";
import {get} from "../../../../../Network/Axios";
import EditIcon from "@atlaskit/icon/glyph/open";
import {useHistory} from "react-router-dom";

export default function index() {
	let [loading, setLoading] = useState(true);
	const HISTORY = useHistory();
	const [search, setSearch] = useState({
		page: 0,
		sort: 'id',
		order: 'desc',
		q:undefined
	})
	const [Data, SetData] = useState({
		total: 0,
		currentPage: 0,
		totalPages: 0,
		items: []
	})
	const loadPage = useCallback((search) => {
		get(`/contracted`, (e, r) => {
			if (r) {
				SetData({
					...r,
					total: r.total||Data.total,
					totalPages: r.totalPages||Data.totalPages,
				})
				setLoading(false)
			} else {
				Alert.handleError(e)
			}
		}, {
			offset: search.page,
			sort: search.sort,
			order: search.order,
			q:search.q
		})
	}, [SetData,Data])
	useEffect(() => {
		loadPage(search)
	}, [search])
	if(loading){
		return  (<Loader/>)
	}
	return (
		<div>
			<Navigation backUrl={''} title="All Hotels" chips={['Contracted Hotels', "All Hotels"]} description="All hotels appears below."/>
			<NavDivider/>
			<SearchBox onChange={(val)=>{setSearch({...search,q:val})}} placeholder="Search Hotels"></SearchBox>
			<div className="relative">
				<ScrollableDynamicTable
					setSearch={(search) => {
						setSearch({
							...search,
							page: 0
						})
					}} search={search}
					setPage={page => {
						setSearch({
							...search,
							page
						})
					}}
					data={{
						current: Data.currentPage,
						total: Data.totalPages,
						items: Data.items.map(item => {
							const click = () => {
								HISTORY.push(`/app/apps/contracted/${item.id}`)
							}
							return {
								content: item,
								render: [
									{
										children: (
											<div onClick={()=>{

											}}>
												<p className="text">
													{item.name}
												</p>
											</div>
										),
										weight: 2,
									},
									{
										weight: 1,
										title: item.email
									},
									{
										weight: 1,
										buttons: [
											{
												title: "View",
												className: 'btn-secondary btn-sm',
												before: (<EditIcon size="small"/>),
												onClick:click
											}
										],
										actions: [
											{
												title: "Quick Actions",
												actions: [
													{
														title: "View",
														before: (<EditIcon size="small"/>),
														onClick:click
													}
												]
											}
										]
									}
								]
							}
						})
					}} headers={[
					{
						weight: 2,
						title: "Name",
						sort: 'name'
					},
					{
						weight: 1,
						title: "Email",
						sort: 'email'
					},
					{
						weight: 1,
						title: "Actions",
						style: {
							justifyContent: "end",
							paddingRight:18
						}
					}
				]}/>
			</div>
		</div>
	)
}
