import React from 'react'
import User from './User'
import Admin from './Admin'
import Travel from './Travel'

export default function Index(){
	return(
		<div>
			<User/>
			<Admin/>
			<Travel/>
		</div>
	)
}
