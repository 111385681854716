import React, {useEffect, useRef, useState} from 'react'
import {Empty, Input, Modal, Pages, Seperator, Thumbnail} from "../../../../../retro";
import {useParams} from "react-router-dom";
import {get, post} from "../../../../Network/Axios";
import useFunctionallityHook from "../../../../Hooks/useFunctionallityHook";

export default function ApiUser() {
    const {id} = useParams();
    const EMAIL = useRef();
    const [modal,setModal] = useState(false)
    const [users,setUsers] = useState([])
    const load = id => {
        get(`/clients/users/${id}/users`,(e,r)=>{
            if(r){
                setUsers(r.users)
            }
        })
    }
    let API = useFunctionallityHook().api
    useEffect(()=>{
        load(id)
    },[id])
    if(!API){
        return (<></>)
    }
	return (
		<div>
            <Seperator margin={5}/>
            {
                modal && <Modal onClose={()=>{setModal(false)}} title="Add User" description="Enter details below to add user">
                    {
                        modal && <div>
                                <Input ref={EMAIL} type="email" label="Email Address" placeholder="Enter email address"/>
                            <p onClick={()=>{
                              let email = EMAIL.current.value;
                                post(`/clients/users/${id}/users`,{email},(e,r)=>{
                                    if(r){
                                        load(id)
                                        setModal(false)
                                    }
                                })
                            }} className="btn btn-black  mt4" style={{
                                maxWidth:100
                            }}>
                                Add
                            </p>
                        </div>
                    }
                </Modal>
            }
            <Pages.Title title="Api Users" description="Manage your api users for this account."/>
            <div className="border pd4 mt4 rounded-sm">
                <p className="fw-bold">
                    All Users
                </p>
                {
                    users.map(item=>{
                        return (
                            <div className="border flex center-vertically border-sm pd2 rounded-sm mt2">
                                <Thumbnail size="L" letter="A"/>
                                <div className="ml2">
                                    <p className="fw-bold">
                                        {item.name}
                                    </p>
                                    <p>
                                        {item.email}
                                    </p>
                                    <p onClick={()=>{
                                        post(`/clients/users/${id}/users/remove`,{email:item.email},(e,r)=>{
                                            if(r){
                                                load(id)
                                            }
                                        })
                                    }} className="btn btn-link">
                                        Delete
                                    </p>
                                </div>
                            </div>
                        )
                    })
                }
                {users.length===0&&<Empty/>}
                <p onClick={()=>{
                    setModal(true)
                }} className="btn btn-black  mt4" style={{
                    maxWidth:200
                }}>
                    + Add User
                </p>
            </div>
        </div>
	)
}
