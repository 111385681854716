import React from 'react'
import EmailApis from "./EmailApis";
import {Route, Switch} from "react-router-dom";

export default function Router() {
	return (
		<Switch>
			<Route path='/app/client/:id/apis/:type'  component={EmailApis}/>
		</Switch>
	)
}
