import moment from 'moment';
import React from 'react';
import {Grids} from '../../../../../retro';

function Hotel({amount, bookedBy, service, status, createdAt, ticket, documents}) {
    const nights = moment(ticket.date.in, "DD-MM-YYYY").diff(moment(ticket.date.out, "DD-MM-YYYY"), 'days')*-1
    return (
        <div className='text-small'>
            <Grids.Row>
                <Grids.Column grid={{mobile:12, medium:6, large:6}}>
                    <div className=' fw-bold'>Booking Date</div>
                    <div className=''>{moment(createdAt).format('DD MMM YYYY')}</div>
                </Grids.Column>
                <Grids.Column grid={{mobile:12, medium:6, large:6}}>
                    <div className=' fw-bold'>Booking ID</div>
                    <div className=''>{ticket.bookingId}</div>
                </Grids.Column>
            </Grids.Row>
            <Grids.Row className='mt3'>
                <Grids.Column grid={{mobile:12, medium:6, large:6}}>
                    <div className=' fw-bold'>Booking Amount</div>
                    <div className=''>Rs. {ticket.amount}</div>
                </Grids.Column>
                <Grids.Column grid={{mobile:12, medium:6, large:6}}>
                    <div className=' fw-bold'>PNR</div>
                    <div>{ticket.pnr}</div>
                </Grids.Column>
            </Grids.Row>
            <Grids.Row className='mt3'>
                <Grids.Column grid={{mobile:12, medium:6, large:6}}>
                    <div className=' fw-bold'><i className='fa fa-user mr1'/>Travellers</div>
                    {
                        ticket.travellers.map((traveller,index)=>{
                            return <div>{index+1}) {traveller.name}</div>
                        })
                    }
                </Grids.Column>
                <Grids.Column grid={{mobile:12, medium:6, large:6}}>
                    <div className=' fw-bold'>Stay Duration ({nights} Night)</div>
                    <div>
                        {moment(ticket.date.in, "DD-MM-YYYY").format('DD MMM YYYY')} to {moment(ticket.date.out, "DD-MM-YYYY").format('DD MMM YYYY')}
                    </div>
                </Grids.Column>
            </Grids.Row>
            {
                documents&&documents.length>0&&
                <div className='mt3'>
                    <div className=' fw-bold'>Files</div>
                    <div className='flex flex-nowrap align-center mt2'>
                        {
                            documents.map((doc,index)=>{
                                return <div key={index} onClick={()=>window.open(doc.path,'_blank')} className='pd1 pl2 pr2 rounded-pill text-small text-nowrap border hoverable mr1'><i className='far fa-file-alt mr1'/>{doc.title}</div>
                            })
                        }
                    </div>
                </div>
            }
            <div className='rounded-md bg-fff border pd2 mt3'>
                <Grids.Row>
                    <Grids.Column grid={{mobile:12, medium:3, large:3}} className='pr2'>
                        <img src={ticket.image} loading='lazy' className='rounded-md w-100 h-100' alt='Room' style={{objectFit:'cover'}}/>
                    </Grids.Column>
                    <Grids.Column grid={{mobile:12, medium:9, large:9}}>
                        <h2 className="fw-bold">{ticket.name}</h2>
                        <div>{ticket.roomId?.split('|')[1]}</div>
                        <div className='text-small'>Address: {ticket.address}, {ticket.city}</div>
                        <h3 className='mt1'>Rs. {Math.ceil(ticket.amount)} for {nights} Night</h3>
                    </Grids.Column>
                </Grids.Row>
            </div>
        </div>
    );
}

export default Hotel;
