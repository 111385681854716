import React from 'react'
import {SidePane} from "../../../../../../retro";

export default function BulkUpload({onClose}) {
	return (
		<SidePane title="Bulk Upload" description="Reward coins in bulk" onClose={onClose}>


		</SidePane>
	)
}
