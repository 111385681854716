import React from 'react'
import {Route, Switch} from "react-router-dom";
import {NotFound} from "../../../../../retro";
import Routes from "./routes";
import BosFile from './BosFile'

export default function AllBooking() {

	return (
		<Switch>
			<Route path="/app/apps/meta/all" exact>
				<Routes/>
			</Route>
			<Route path="/app/apps/meta/all/bos" exact>
				<BosFile/>
			</Route>
			<NotFound/>
		</Switch>
	)
}
