import { post } from "App/Network/Axios";
import CsvToJson from "App/Utils/Csv";
import React, { useEffect, useRef, useState } from "react";
import {
    Button,
    NavDivider,
    Navigation,
    FileInput,
    BorderTable,
    Loader,
    TOAST,
} from "retro";
import { DownloadCsv } from "retro/Utils";
import moment from "moment";

export default function UploadSetllement() {
    const [loading, setLoading] = useState(true);
    const [csvData, setCsvData] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [uploads, setUploads] = useState({});
    const [uploadCompleted, setUploadCompleted] = useState(false);

    const file = useRef();
    const uploadCsv = (file) => {
        setCsvData([]);
        setUploading(true);
        setUploadCompleted(false);
        CsvToJson(
            file,
            (rows) => {
                setCsvData(rows);
                setUploading(false);
                setLoading(false);
            },
            (row) => {
                if (row.transaction_id) return true;
                TOAST.error('Please upload a file with correct fields')
                return false;
            }
        );
    };

    useEffect(() => {
        // When csvData changes, trigger the recursiveUpload function
        if (csvData.length > 0 && !uploadCompleted) {
            recursiveUpload(csvData, 0);
        }
    }, [csvData]);

    const upload = async (csvDataRow, cb) => {
        const {
            transaction_id,
            key,
            value
        } = csvDataRow;
        if(!transaction_id){
            cb("Enter a valid transaction id");
            return;
        }
        post(
            `/meta/${transaction_id}/tags/add`,
            {
               key,value
            },
            (e, r) => {
                if (r) {
                    cb("Upload completed");
                } else {
                    cb(e.data.message);
                }
            }
        );
    };

    const recursiveUpload = (csvData, i = 0, mState = {}) => {
        if (i >= csvData.length) {
            setUploading(false);
            setUploads({ ...mState });
            setUploadCompleted(true);
        } else {
            let row = csvData[i];
            upload(row, (message) => {
                let key = row.transaction_id; //need to change this according to the data from api
                let state = { ...mState };
                state[key] = message;
                setUploads(state);
                recursiveUpload(csvData, i + 1, state);
            });
        }
    };

    const rows = () => {
        let array = [];
        // console.log(data);
        csvData.forEach((value) => {
            array.push([value.transaction_id,  uploads[value.transaction_id]||"Upload Pending"]);
        });
        return array;
    };

    const downloadSample = ()=>{
        const headers = ['transaction_id','key','value']
        DownloadCsv('Sample_CSV_file',[headers]);
    }

    return (
        <>
            <Navigation
                backUrl={"back"}
                title='Tags'
                chips={["All Tags"]}
                description='All tags are listed below.'
            />
            <NavDivider />
            <div className='button_with_table'>
                {uploading ? (
                    <div>
                        <Loader />
                    </div>
                ) : null}

                <FileInput
                    onSelect={uploadCsv}
                    extensions={[".csv"]}
                    className='display-none'
                    ref={file}
                />
                {uploadCompleted ? null : (
                    <div className='flex-1 flex horizontally'>
                        <Button
                            onClick={() => file.current.click()}
                            className='btn btn-black'
                        >
                            <i className='fa fa-upload mr1' />
                            Upload CSV
                        </Button>
                        <Button
                            onClick={downloadSample}
                            className='btn btn-black ml5'
                        >
                            <i className='fa fa-download mr1' />
                            Download Sample Format
                        </Button>
                    </div>
                )}

                {loading ? null : (
                    <div className='mt-4'>
                        <h2>Preview</h2>
                        <p>All Lines appears below</p>
                        {
                            !uploading && <Button
                                // onClick={uploadSheet}
                                onClick={() => file.current.click()}
                                className='btn btn-black'
                            >
                                <i className='fa fa-upload mr1' />
                                Upload CSV
                            </Button>
                        }
                        <BorderTable
                            rows={rows()}
                            headers={["Transaction Id",  "Status"]}
                        />
                    </div>
                )}
            </div>
        </>
    );
}
