import React, {useCallback, useEffect, useState} from 'react'
import {Loader, ScrollableDynamicTable as DynamicTable, SearchBox, TOAST as Alert} from "../../../../../../../retro";
import {get} from "../../../../../../Network/Axios";
import {useParams} from "react-router-dom";
import Moment from 'moment'

export default function AllScanners() {
	let [loading, setLoading] = useState(true);
	const {id} = useParams()
	const [search, setSearch] = useState({
		page: 0,
		sort: 'id',
		order: 'desc',
		q:undefined
	})
	const [Data, SetData] = useState({
		total: 0,
		currentPage: 0,
		totalPages: 0,
		items: []
	})
	const loadPage = useCallback((search) => {
		get(`/users/${id}`, (e, r) => {
			if (r) {
				SetData({
					...r
				})
				setLoading(false)
			} else {
				Alert.handleError(e)
			}
		}, {
			offset: search.page,
			sort: search.sort,
			order: search.order,
			q:search.q
		})
	}, [SetData])
	useEffect(() => {
		loadPage(search)
	}, [search])
	return (
		<div>
			{
				loading ?
					<Loader/> :
					<div className="relative">
						<DynamicTable
							setSearch={(search) => {
								setSearch({
									...search,
									page: 0
								})
							}} search={search}
							setPage={page => {
								setSearch({
									...search,
									page
								})
							}}
							data={{
								current: Data.currentPage,
								total: Data.totalPages,
								items: Data.items.map(item => {
									return {
										content: item,
										render: [
											{
												container:(
													<div>
														<p className="text">
															{item.title}
														</p>
														<p className="text-small text-light">
															{item.email}
														</p>
														{
															item.lastLogin&&<p>
																Last login on
																{
																	Moment(item.lastLogin).format("DD MMM YYYY HH:mm")
																}
															</p>
														}

													</div>
												),
												weight: 2,
											},{
												title:`${item.code}`,
												weight: .5,
											},
											{
												weight: 1,
												title: item.currency
											},
											{
												weight: 1,
												title: `${Moment(item.createdAt).format("DD MMM YYYY HH:mm")}`
											}
										]
									}
								})
							}} headers={[
							{
								weight: 2,
								sort: 'name',
								title: "Details",
							},
							{
								weight: .5,
								sort: 'code',
								title: "Code",
							},
							{
								weight: 1,
								title: "Currency"
							},
							{
								weight: 1,
								title: "Created On",
							}
						]}>

							<SearchBox onChange={(q)=>{
								setSearch({
									...search,
									q:q && q.length>0?q:undefined,
									page: 0
								})
							}} containerClass="" style={{
								width:'40%',
								zIndex:2
							}} placeholder="Enter your search criteria"/>
						</DynamicTable>
					</div>
			}
		</div>
	)
}
