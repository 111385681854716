import React, {useRef} from 'react'
import Graph from "../Flight/BosTravellerFile/graph-stats-circle.svg";
import {Button, Input} from "../../../../../retro";
import {useHistory} from "react-router-dom";

export default function index() {
	const REF = useRef();
	const HISTORY = useHistory();
	return (
		<div>
			<div style={{
				margin:'0 -4rem',
				padding:'2rem 4rem',
				paddingTop:0,
				borderBottom:'1px solid #dedede'
			}}>
				<div className="flex horizontally center-vertically">
					<div className="flex-1">
						<Input ref={REF} label="Search Criteria" placeholder="Enter your search criteria"/>
					</div>
					<Button margin="ml2 mt4" onClick={()=>{
						HISTORY.push(`/app/apps/meta/transaction/${REF.current.value}`)
					}}>
						Fetch Details
					</Button>
				</div>
			</div>
			<div className="border pd6 mt4 flex vertically center">
				<img alt="fetch" src={Graph} style={{width:'5rem',height:'5rem'}}/>
				<h3 className="fw-bold mt4">
					No Data Found
				</h3>
				<p>
					No data found in this configuration
				</p>
			</div>
		</div>
	)
}
