import React, {useEffect, useState} from 'react'
import {Button, ChipsSelect, Loader} from "../../../../../retro";
import {useParams} from "react-router-dom";

export default function ApiLogs() {
	const [loading,setLoading] = useState(false)
	const [selected,setSelected] = useState('OCR-A')
	const {id} = useParams();
	useEffect(()=>{

	},[])
	let submit = () => {

	}
	if(loading){
		return (<div>
			<Loader/>
		</div>)
	}
	return (
		<div id="non-sticky" className="FadeInRight-Effect">
			<div>
				<ChipsSelect type="single" label="Select Type" selected={selected} onSelect={setSelected} options={['OCR-A','OCR-B','OCR-A + OCR-B']}/>
			</div>
			<Button onClick={submit} className="mt5 btn-primary">
				Update Settings
			</Button>
		</div>
	)
}
