import React from 'react';

export default function NewSwitchTabs({
  title,
  titleStyle = {},
  style = {},
  tabs,
  active,
  onClick,
}) {
  return (
    <>
      <p className="text-secondary-color mt2 mb2 fw-400" style={titleStyle}>
        {title}
      </p>
      <div className="flex" style={style}>
        {tabs.map((item, index) => {
          const mActive = item.name === active;
          const isDisabled = item.disabled;
          return (
            <p
              className={`chip ${mActive && 'chip-active'} ${
                isDisabled && 'chip-disabled'
              }`}
              onClick={!isDisabled ? () => onClick(item.name) : null}
              key={item.name}
              style={{
                cursor: isDisabled ? 'not-allowed' : 'pointer',
                opacity: isDisabled ? 0.5 : 1,
              }}
            >
              {item.name}
            </p>
          );
        })}
      </div>
    </>
  );
}
