import React from "react";
import {Route, Switch} from "react-router-dom";
import Routes from "./routes";
import BosTravellerFile from "./BosTravellerFile";
import GstBosTravellerFile from "./GstBosTravellerFile";
import AgencyBosTravellerFile from "./AgencyBosTravellerFile";
import ResellerBosTravellerFile from "./ResellerBosTravellerFile";
import Bookings from "./Logs";
import BookingQueue from "./BookingQueue";
import RefundQueue from "./RefundQueue";
import FailedTravellerFile from "./FailedTravellerFile";
import PerksTravellerFile from "./PerksTravellerFile";
import InvoiceQueue from "./InvoiceQueue";
import HoldedInvoiceQueue from "./HoldedInvoiceQueue";
import {NotFound} from "retro";
import Repository from "./Repository";
import ServiceFeeMIS from "./ServiceFeeMIS";

export default function index() {
	return (
		<Switch>
			<Route path="/app/apps/meta/flight" exact>
				<Routes/>
			</Route>
			<Route path="/app/apps/meta/flight/bos/travellers" exact>
				<BosTravellerFile/>
			</Route>
			<Route path="/app/apps/meta/flight/bos/reseller" exact>
				<ResellerBosTravellerFile/>
			</Route>
			<Route path="/app/apps/meta/flight/invoices" exact>
				<InvoiceQueue/>
			</Route>
			<Route path="/app/apps/meta/flight/invoices/holded" exact>
				<HoldedInvoiceQueue/>
			</Route>
			<Route path="/app/apps/meta/flight/bos/gst" exact>
				<GstBosTravellerFile/>
			</Route>
			<Route path="/app/apps/meta/flight/bos/agency" exact>
				<AgencyBosTravellerFile/>
			</Route>
			<Route path="/app/apps/meta/flight/bos/serviceMis" exact>
				<ServiceFeeMIS />
			</Route>
			<Route path="/app/apps/meta/flight/failed" exact>
				<FailedTravellerFile/>
			</Route>
			<Route path="/app/apps/meta/flight/perks/travellers" exact>
				<PerksTravellerFile/>
			</Route>
			<Route path="/app/apps/meta/flight/bookings" exact>
				<Bookings/>
			</Route>
			<Route path="/app/apps/meta/flight/queue/refunds" exact>
				<RefundQueue/>
			</Route>
			<Route path="/app/apps/meta/flight/queue/bookings" exact>
				<BookingQueue/>
			</Route>
			<Route path="/app/apps/meta/flight/repository" exact>
				<Repository/>
			</Route>
			<NotFound/>
		</Switch>
	);
}
