import React, {useRef, useState} from 'react'
import {Button, Form, Input, Loader, NavDivider, Navigation, SearchInput, TOAST} from "../../../../../../retro";
import {post} from "../../../../../Network/Axios";


export default function index() {
	const [hotel,setHotel] = useState(undefined)
	const [pageReloading,SetPageReloading] = useState(false);
	const EMAIL = useRef();

	if(pageReloading){
		return <><Loader/></>
	}

	return (
		<>
			<Navigation backUrl={''} title="Link Hotel" chips={['Contracted Hotels', "New"]} description="Enter details to add a new hotel."/>
			<NavDivider/>

			<Form className="mt4">
				<SearchInput renderResult={item=>{
					return (
						<div>
							<p style={{fontSize:14,fontWeight:600}}>
								{item.name}
							</p>
							<p>
								{item.city}
							</p>
						</div>
					)
				}} resultKey="hotels" api="contracted/search" morph={false} placeholder="Hotel" label="Select Hotel"
							 onSelect={item => {
								 setHotel(item)
							 }} selected={undefined}/>
				<Input ref={EMAIL} className="mb2 mt4" type="text" placeholder="Enter email address" label="Enter email address"
					   morph={false}/>
				<Button onClick={()=>{
					if(!hotel){
						TOAST.error("Invalid hotel")
						return;
					}
					let email = EMAIL.current.value;
					if(!email){
						TOAST.error("Invalid email")
						return;
					}
					post(`contracted/contract`,{
						hotel:hotel.id,
						email
					},(e,r)=>{
						if(r){
							TOAST.success("Hotel on-boarded successfully!!")
							SetPageReloading(true);
							setTimeout(()=>{
								SetPageReloading(false);
							},100)
						}else{
							TOAST.handleError(e)
						}
					})
				}} className="btn-primary mt2">
					Link Hotel
				</Button>
			</Form>
		</>
	)
}
