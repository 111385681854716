import {combineReducers} from 'redux';
import {auth} from './auth'

const origin = () => {
	return {
		loading:false,navigation:'topbar'
	}
}

const rootReducer = combineReducers({
	auth,origin
});
export default rootReducer;
