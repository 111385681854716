import React, {useCallback, useEffect, useState} from 'react'
import {Loader, ScrollableDynamicTable as DynamicTable, SearchBox, TOAST as Alert} from "../../../../../../../retro";
import {get} from "../../../../../../Network/Axios";
import {useParams} from "react-router-dom";
import Moment from 'moment'

export default function AllScanners() {
	let [loading, setLoading] = useState(true);
	const {id} = useParams()
	const [search, setSearch] = useState({
		page: 0,
		sort: 'id',
		order: 'desc',
		q:undefined
	})
	const [Data, SetData] = useState({
		total: 0,
		currentPage: 0,
		totalPages: 0,
		items: []
	})
	const loadPage = useCallback((search) => {
		get(`/offices/${id}`, (e, r) => {
			if (r) {
				if(r?.totalPages===0){
					SetData((prev)=>{
						return {...prev,items:r?.items,currentPage:!r.currentPage?search.page:r?.currentPage}
					})
				}else{
					SetData({
						...r
					})
				}
				setLoading(false)
			} else {
				setLoading(false)
				Alert.handleError(e)
			}
		}, {
			offset: search.page,
			sort: search.sort,
			order: search.order,
			q:search.q
		})
	}, [SetData])
	useEffect(() => {
		loadPage(search)
	}, [search])
	return (
		<div>
			{
				loading ?
					<Loader/> :
					<div className="relative">
						<DynamicTable
							setSearch={(search) => {
								setSearch({
									...search,
									page: 0
								})
							}} search={search}
							setPage={page => {
								setSearch({
									...search,
									page
								})
							}}
							data={{
								current: Data.currentPage,
								total: Data.totalPages,
								items: Data.items.map(item => {
									return {
										content: item,
										render: [
											{
												container:(
													<div>
														<p className="text">
															{item.name}
														</p>
													</div>
												),
												weight: 1.5,
											},{
												title:`${item.registeredAddress}`,
												weight: 1,
											},
											{
												weight: 1,
												title: item.currency
											},
											{
												weight: 1,
												title: `${Moment(item.createdAt).format("DD MMM YYYY HH:mm")}`
											}
										]
									}
								})
							}} headers={[
							{
								weight: 1.5,
								sort: 'name',
								title: "Name",
							},
							{
								weight: 1,
								// sort: 'addrss',
								title: "Address",
							},
							{
								weight: 1,
								title: "Currency"
							},
							{
								weight: 1,
								title: "Created On",
							}
						]}>

							<SearchBox onChange={(q)=>{
								setSearch({
									...search,
									q:q && q.length>0?q:undefined,
									page: 0
								})
							}} containerClass="" style={{
								width:'40%',
								zIndex:2
							}} placeholder="Enter your search criteria"/>
						</DynamicTable>
					</div>
			}
		</div>
	)
}
