import { post } from 'App/Network/Axios';
import React, { useRef, useState } from 'react';
import { Button, InlineTable, Input, Modal, Pages, TOAST } from 'retro/index';

const AddTags = ({ tags, transId, load }) => {
  const [showTagModal, setShowTagModal] = useState(false);

  const KEY = useRef(),
    VALUE = useRef();

  const handleAddTag = () => {
    let key = KEY.current.value;
    let value = VALUE.current.value;
    if (!key) {
      TOAST.error('Please enter key');
      return;
    }
    if (!value) {
      TOAST.error('Please enter value');
      return;
    }
    post(
      `/meta/${transId}/tags/add`,
      { key, value: value.toLowerCase() },
      (e, r) => {
        if (r) {
          TOAST.success('Tag added');
          setShowTagModal(false);
          load();
        }
        if (e) {
          TOAST.handleError(e);
        }
      }
    );
  };

  const handleDeleteTag = (value) => {
    post(`/meta/${transId}/tags/remove`, { key: value }, (e, r) => {
      if (r) {
        TOAST.success('Tag Removed');
        load();
      }
      if (e) {
        TOAST.handleError(e);
      }
    });
  };

  return (
    <div className="mt3">
      <div className="border-sm pd4  mt4 rounded-md">
        <InlineTable
          headers={['Key', 'Value', 'Actions']}
          rows={Object.keys(tags).map((value) => {
            return [
              value,
              tags[value],
              value !== 'gstin' && value !== 'old_gstin' ? (
                <Button
                  onClick={()=>handleDeleteTag(value)}
                  margin="mt1 mb1"
                  className="btn-link btn-sm btn-link-danger"
                >
                  - Delete
                </Button>
              ) : (
                <></>
              ),
            ];
          })}
        />
        <Button
          onClick={() => {
            setShowTagModal(true);
          }}
          className="btn-link"
        >
          + Add Tag
        </Button>
      </div>
      {showTagModal && (
        <Modal
          title="Add Tags"
          description="Enter details to add a new tags"
          onClose={() => setShowTagModal(false)}
        >
          <Input type="text" ref={KEY} label="Enter key details" morph={true} />
          <Input
            type="text"
            ref={VALUE}
            label="Enter value details"
            morph={true}
          />
          <Button onClick={handleAddTag} className="btn btn-primary mt4">
            + Add Tag
          </Button>
        </Modal>
      )}
    </div>
  );
};

export default AddTags;
