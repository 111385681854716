import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { get, post } from "App/Network/Axios";
import {
  Button,
  CheckBox,
  Empty,
  Headings,
  Input,
  Loader,
  Seperator,
  SidePane,
  Switch,
  Texts,
  TOAST,
} from "retro";

import ExpenseIcon from "./expense.png";
import QrIcon from "./scan.png";
import TravelIcon from "./trip.png";
import InvoiceIcon from "./invoice.png";
import CardIcon from "./importCard.SVG";
import SSOIcon from "./sso.svg";
import useFunctionallityHook from "../../../../Hooks/useFunctionallityHook";
import { v4 } from "uuid";
import useConfigHook from "App/Hooks/useConfigHook";

export default function AllApis({getModule}) {
  const SPENDS = useFunctionallityHook().spends;
  const CONFIG=useConfigHook()
  let ORIGIN = CONFIG.origin.code;



  const { id } = useParams()
  const [loder,setLoder]= useState(false)

  const QR = useFunctionallityHook().qr;
  const [error, setError] = useState(false);
  const [BulkPeModel, setBulkPeModel] = useState(false);
  const [isWhitelistAccount, setisWhitelistAccount] = useState(false);
  const [ClientDetails, setClientDetails] = useState(undefined)
  const NAME = useRef();
  const UDF1 = useRef();
  const UDF2 = useRef();
  const UDF3 = useRef();
  const [WhitelistAccounts, setWhitelistAccounts] = useState([
    {
      name: "",
      accountNumber: "",
      ifsc: "",
    },
  ]);

  function addBulkPe() {
    try {
      setLoder(true)
      const gstinRegex = /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/;

      const panPattern = /^[A-Z]{3}P[A-Z0-9]{1}[0-9]{4}[A-Z]{1}$/;
 
      if(UDF1.current.value){
        if (!panPattern.test(UDF1.current.value)) {
          TOAST.error('Invalid PAN card number');
          return;
        }  
      }
            
            if(UDF2.current.value){
              if (!gstinRegex.test(UDF2.current.value)) {
                TOAST.error('Invalid GST number');
                return;
              } 
            }
      
            if(!UDF1.current.value && !UDF2.current.value){
              TOAST.error('Please Enter GST or PAN Number');
      
              return
            }
           
       
      let data = {
        name: NAME.current.value,
        settleTo: "PARENT",
        referenceId: v4(), // base64
        udf1: UDF1.current.value,
        udf2: UDF2.current.value,
        udf3: UDF3.current.value,
        isWhitelistAccount: isWhitelistAccount, //
        WhitelistAccounts: [],
      };
      if (isWhitelistAccount) {
        data.WhitelistAccounts = WhitelistAccounts;
      }
        post(
          `/bulkPe/create-va/${id}`,
          data,
          (e, r) => {
            if (r) {

              if(!r.status){
                TOAST.error(JSON.stringify(r?.message));
                return


              }else{

                LoadConfig();
                getModule()
                setBulkPeModel(false)
                TOAST.success("Bulkpe add successfully");
                post(
                  `/clients/modules/${id}`,
                  {
                    key: "bulkPe",
                    value:"false" ,
                  },
                  (e, r) => {
                    if (r) {
                      LoadConfig();
                      TOAST.success("Request sent for approval");
                    }
                  }
                );

              }
             
            }
          }
        );


         
          setLoder(false)

        
    } catch (error) {
      setLoder(false)

      console.log(error);
    }
  }

  const  [AllCards] = useState([
      {
        name: "Expense Module",
        icon: (
          <img
            src={ExpenseIcon}
            style={{ width: 40, height: 40 }}
            alt="expense"
          />
        ),
        type: "expense",
        des: "Enable or disable expense module for this organisation",
      },
      {
        name: "Travel",
        icon: (
          <img
            src={TravelIcon}
            style={{ width: 40, height: 40 }}
            alt="expense"
          />
        ),
        type: "travel",
        des: "Enable or disable travel module for this organisation",
      },
      {
        name: "Only Implants",
        icon: (
          <img
            src={TravelIcon}
            style={{ width: 40, height: 40 }}
            alt="expense"
          />
        ),
        type: "onlyImplant",
        upload: "onlyImplants",
        des: "Enable or disable employee module for this organisation",
      },
      ...(QR
        ? [
            {
              name: "Qr Payments",
              icon: (
                <img
                  src={QrIcon}
                  style={{ width: 40, height: 40 }}
                  alt="expense"
                />
              ),
              type: "qrPayments",
              upload: "qr",
              des: "Enable or disable qr payments module for this organisation",
            },
          ]
        : []),

      ...(SPENDS
        ? [
            {
              name: "Invoice",
              icon: (
                <img
                  src={InvoiceIcon}
                  style={{ width: 40, height: 40 }}
                  alt="expense"
                />
              ),
              type: "vendorPayments",
              des: "Enable or disable invoice module for this organisation",
            },
          ]
        : []),
      {
        name: "Utilities",
        icon: (
          <img
            src={TravelIcon}
            style={{ width: 40, height: 40 }}
            alt="utility"
          />
        ),
        type: "utilityBillPayments",
        des: "Enable or disable utility module for this organisation",
      },
      {
        name: "Dice Cards",
        icon: (
          <img
            src={CardIcon}
            style={{ width: 40, height: 40 }}
            alt="Dice Card"
          />
        ),
        type: "diceCards",
        des: "Enable or disable dice cards for this organisation",
      },
      {
        name: "Corporate Cards",
        icon: (
          <img
            src={CardIcon}
            style={{ width: 40, height: 40 }}
            alt="Corporate Card"
          />
        ),
        type: "corporateCards",
        des: "Enable or disable corporate cards for this organisation",
      },
      {
        name: "SSO Login",
        icon: (
          <img
            src={SSOIcon}
            style={{ width: 40, height: 40 }}
            alt="SSO Login"
          />
        ),
        type: "ssoLogin",
        des: "Enable or disable SSO login for this organisation",
      },


 ...((ORIGIN!=="YESUAT" && ORIGIN!=='YES-PROD')
        ? [
          {
            name: "BulkPe",
            icon: (
              <img src={CardIcon} style={{ width: 40, height: 40 }} alt="BulkPe" />
            ),
            type: "bulkPe",
            des: "Enable or disable BulkPe  for this organisation",
          },
          ]
        : []),

     
      // {
      //   name: "Card UPI",
      //   icon: (
      //     <img
      //       src={CardIcon}
      //       style={{ width: 40, height: 40 }}
      //       alt="cardPlusUPI"
      //     />
      //   ),
      //   type: "cardPlusUPI",
      //   des: "Enable or disable Card & UPI  for this organisation",
      // },


    ]),


    [Data, SetData] = useState({
      loading: true,
    }),


    LoadConfig = useCallback(() => {
      get(`/clients/modules/${id}`, (e, r) => {
        if (r) {
          SetData({
            ...r,
            loading: false,
          });
        } else {
          setError(true);
        }
      });
    }, [id]);


  useEffect(() => {
    LoadConfig();
  }, [LoadConfig]);

  useEffect(()=>{
		get(`/clients/${id}`, (e, r) => {
			if (r) {
        setClientDetails(r)
			}
		})
	},[id])


  if (error) {
    return (
      <div>
        <Empty />
      </div>
    );
  }
  if (Data.loading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }
  const SET = {};
  Object.keys(Data).forEach((key) => (SET[key] = true));
  const item = (item) => {
    var checked = Data[item.type];
    return (
      <div key={item.type} className="retro-card">
        <div
          style={{
            position: "absolute",
            right: 12,
            top: 12,
            zIndex: 2,
            color: "#fff",
          }}
        >
          <Switch
            state={checked}
            onChange={() => {
              if(item?.type==="bulkPe" &&!checked){


                get(`/bulkPe/get-va/${id}`, (e, r) => {
                  if (!r?.status) {
                    setBulkPeModel(true)
                    return
                  }else{

                    post(
                      `/clients/modules/${id}`,
                      {
                        key: item.upload || item.type,
                        value: checked ? "false" : "true",
                      },
                      (e, r) => {
                        if (r) {
                          getModule()
                          LoadConfig();
                          TOAST.success("Request sent for approval");
                        }
                      }
                    );
                  }
                });
                return
              }

              post(
                `/clients/modules/${id}`,
                {
                  key: item.upload || item.type,
                  value: checked ? "false" : "true",
                },
                (e, r) => {
                  if (r) {
                    getModule()
                    LoadConfig();
                    TOAST.success("Request sent for approval");
                  }
                }
              );
            }}
            size="large"
          />
        </div>
        <div
          key={item.name}
          style={{
            opacity: checked ? 1 : 0.37,
          }}
          className="relative "
        >
          <div>{item.icon}</div>
          <Headings.Regular margin="mt4">{item.name}</Headings.Regular>
          <Texts.Small style={{ maxLines: 2 }} className="mt1">
            {item.des}
          </Texts.Small>
        </div>
      </div>
    );
  };
  return (
    <div className="card-container">
      {BulkPeModel && (
        <SidePane
          onClose={() => {
            setBulkPeModel(undefined);
          }}
          description="Enter details below to add BulkPe"
          title="Add BulkPe"
          width={700}
        >
          <Input
          defaultValue={ClientDetails?.name}
          ref={NAME} placeholder="Enter Name" label="Name *" />
          <Input ref={UDF1} placeholder="Enter Pan Number" label="PAN Number" />
          <Input ref={UDF2} placeholder="Enter GST Number" label="GST Number" />
          <Input ref={UDF3} placeholder="Enter Others" label="Others " />
          <CheckBox
            onChange={(checked) => {
              setisWhitelistAccount(checked);
            }}
            className="mt3"
            label="Whitelist Account"
          />

          <Seperator />

          {isWhitelistAccount && (
            <div>
              <div className="flex flex-1">
                <div className="flex flex-1">
                  <div>Add Whitelist Account</div>
                </div>
                <div
                  className="flex flex-1 justify-end mr2"
                  onClick={() => {
                    setWhitelistAccounts((p) => {
                      return [
                        ...p,
                        {
                          name: "",
                          accountNumber: "",
                          ifsc: "",
                        },
                      ];
                    });
                  }}
                >
                  <i className="fa fa-plus-circle fa-2x mr1" />
                </div>
              </div>

              <div className="mt2">
                <div className="flex flex-row">
                  <p className="flex-1">Name</p>
                  <p className="flex-1">IFC code</p>
                  <p className="flex-1">Account Number</p>
                </div>

                {WhitelistAccounts.map((i, index) => {
                  return (
                    <>
                      <div className="flex flex-row">
                        <Input
                          onChange={(e) => {
                            setWhitelistAccounts((p) => {
                              let newData = p;
                              newData[index].name = e;
                              return newData;
                            });
                          }}
                          label=""
                        />
                        <Input
                          className="ml2 mr2 mt1"
                          onChange={(e) => {
                            setWhitelistAccounts((p) => {
                              let newData = p;
                              newData[index].ifsc = e;
                              return newData;
                            });
                          }}
                          label=""
                        />
                        <Input
                          onChange={(e) => {
                            setWhitelistAccounts((p) => {
                              let newData = p;
                              newData[index].accountNumber = e;
                              return newData;
                            });
                          }}
                          label=""
                        />

                        <div
                          onClick={() => {
                            let LocalWhitelistAccounts = WhitelistAccounts;

                            LocalWhitelistAccounts =
                              LocalWhitelistAccounts.filter(
                                (i, ind) => ind !== index
                              );
                            setWhitelistAccounts([...LocalWhitelistAccounts]);
                          }}
                        >
                          <i className="fa fa-trash  mt4 ml2" />
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          )}

          <Button onClick={addBulkPe} className="btn-primary mt5">
            Add Bulkpe
          </Button>
        </SidePane>
      )}

      {AllCards.filter((i) => SET[i.type]).map(item)}
    </div>
  );
}
