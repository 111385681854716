import React, {useEffect, useState} from 'react'
import { useRef } from 'react';
// import {fetchData} from "App/Pages/App/Reports/Export/MergeUtils";
import { fetchData, fetchDataComplementSettlement } from 'App/Utils/Utils';

import {Button, DateRange, Loader, SidePane, TOAST} from "retro/index";
import Moment from "moment/moment";
import {downloadPendingSettlement} from './ReportUtils'
// import Graph from "App/Pages/App/Reports/Export/Travel/CL/Settlement/graph-stats-circle.svg";
import { get,post} from 'App/Network/Axios';
import { Headings,CheckBox } from 'retro/index';
import { DownloadCsv } from 'retro/Utils';
import MultiSelectDropdown from 'App/Components/MultiSelectDropdown';
// import Multiselect from 'retro/MultiSelect/MultiSelct';

export default function index() {
	const [loading, setLoading] = useState(false)
	const [AllKeys, setAllKeys] = useState([])
	const [Modal,setModal] = useState(false)
	const [SelectedColumns, setSelectedColumns] = useState([]);
	const [allBasicKeys, setAllBasicKeys]= useState([]);
	const SelectedProducts = useRef({getValue: () => {}});
	const AllProducts = ['Flight','Hotel','Bus','Visa','RoundFlight','Insurance'];

	const [Dates, setDates] = useState({
		page: 0,
		startDate: Moment().add(-31, 'd').valueOf(),
		endDate: Moment().valueOf(),
	  });
	  const [selectedClients, setselectedClients] = useState([]);
	  const [clients, setClients] = useState([]);
	  // get the list of clients for dorpdown
	  useEffect(() => {
		get(
		  '/clients/dump',
		  (e, r) => {
			if (r) {
			  setClients([
				...r.items.map((client) => {
				  return { code: client.code, id: client.id };
				}),
			  ]);
			}
		  },
		  { active: true }
		);
	  }, []);



	
	const downloadAllData = () => {
		setLoading(true);
		// setting the filter by ids based on client code
		let clientsFilter = [];
		if (selectedClients.length > 0) {
		  clientsFilter = clients
			.filter((client) => {
			  if (selectedClients.includes(client.code)) {
				return client.id;
			  }
			})
			.map((client) => client.id);
		}


		fetchDataComplementSettlement(`/meta/da/completeSettlement`,Dates.startDate,Dates.endDate,(items,e)=>{



let updateditems=[]

items.map((i)=>{

	let xx=updateditems.filter((it)=>it.item.financeType===i.item.financeType&& it.transId===i.transId)

	if(xx.length){
		updateditems=updateditems.map((updateitem)=>{

			if(updateitem.item.financeType===i.item.financeType&& updateitem.transId===i.transId){

				let locacalupdateItem=JSON.parse(JSON.stringify(updateitem))

				locacalupdateItem.item.amount=locacalupdateItem?.item?.amount+i?.item?.amount

				return {...locacalupdateItem}


			}else{
				return {...updateitem}
			}
		})


		return

	}

	updateditems.push(i)


})



			downloadPendingSettlement(updateditems,Dates)
			setLoading(false)
		},
		{
			client: clientsFilter.toString(),
		  },
		  365

	)
	}

	const downloadSelectedData = () => {
		setLoading(true);
		const selectedProducts = SelectedProducts.current.getValue().split(';').filter(Boolean);
		fetchData(`/reports/9cvHY3r3EMoMp`,Dates.startDate,Dates.endDate,(items)=>{
			downloadPendingSettlement(items,Dates,SelectedColumns,selectedProducts)
			setLoading(false)
		})
		setModal(false)
		};

	const addField = (field) => {
        setSelectedColumns(prev=>[...prev, field])
    }

	const removeField = (field) =>{
		setSelectedColumns(SelectedColumns.filter(selected => (selected.key!==field.key)))
	}

	const selectAllKeys = () => {
		if (SelectedColumns.length=== AllKeys.length) {
			setSelectedColumns([]);

		} else {
			setSelectedColumns([...AllKeysWithLabels]);
		}
	}

	const AllKeysWithLabels = AllKeys.map(data => ({
		...data,
		label: data.value
	}));

	return (
		<div>
			<div style={{
				margin: '0 -4rem',
				padding: '2rem 4rem',
				borderBottom: '1px solid #dedede'
			}}>
				  <div className="flex horizontally center-vertically">
        <DateRange
          defaultValue={{
            startDate: Moment().add(-31, 'd').valueOf(),
            endDate: Moment().valueOf(),
          }}
          onChange={(dates) => {
            setDates({
              ...dates,
              page: 0,
            });
          }}
          className="flex-1 mt1"
          label=""
          type="range"
        />
        <MultiSelectDropdown
          className="flex-1 mt1 ml1"
          options={clients.map((client) => client.code)}
          selectedOptions={selectedClients}
          setSelectedOptions={setselectedClients}
          placeholder="Select Clients"
          showSelectedOptins={false}
        />
      </div>
				
			</div>
			<div className="border pd6 mt4 flex vertically center">
				{/* <img alt="fetch" src={Graph} style={{width: '5rem', height: '5rem'}}/> */}
				<h3 className="fw-bold mt4">
					No Data Found
				</h3>
				<p>
					No data found in this configuration
				</p>
				{
					loading ? <Loader/>: <div className='flex horizontal'><Button onClick={downloadAllData} className="btn btn-black" style={{marginRight:'10px'}}> 
					Download All Data
				</Button>
				{/* <Button className='btn btn-black' onClick={()=>setModal(true)} style={{marginLeft:'10px'}}>Download Selected Data</Button> */}
				</div>
				}
			{/* {Modal && <SidePane title='All Fields' description='Select The Fields To Include' onClose={()=>setModal(false)}>

				<>
				<div>	
					<Multiselect
						ref={SelectedProducts}
						label="Products"
						options={AllProducts.map(item => {
							return {
								value: item,
								label: item
							}
						})}
					/>
					</div>
			<div className='mt2'>Selected Fields</div>
			<div className='mt2 flex align-center flex-wrap'>	
			{SelectedColumns.map((field,index)=>{
			return (
				<div className='chip-active'
				onClick={()=>{removeField(field)}}>
				{field.value}
				</div>
			)
			})}
			</div>
      <div className="flex justify-between">
		
        <Headings.Small className="mt4">
          Select fields to include in export file
        </Headings.Small>
        <CheckBox
          onChange={selectAllKeys}
          label="Select All"
          className="mt4 flex item-center justify-center"
          style={{ alignItems: 'center' }}
        />
      </div>
      <div className="mt3 flex align-center flex-wrap">
        {AllKeys.map((field, index) => {
          let active = false;
          SelectedColumns.forEach((element) => {
        	if (element.key === field.key) active = true;
          });

          if (!field) return <></>;
		  const HandleSelect =()=>{
			if(!active){
				addField(field)} 
			else{
				removeField(field) }
		  }

          return (
            <div
              key={index}
              onClick={HandleSelect}
              className={`${active ? 'chip-active' : 'chip'}`}
            >
              {field.value}
            </div>
          );
        })}
      </div>
    </>
	

	<Button className='btn btn-black center' onClick={downloadSelectedData}>Download Selected Data</Button>
				
				</SidePane>} */}
			</div>
		</div>
	)
}