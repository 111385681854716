import {shallowEqual, useSelector} from "react-redux";

function useAppHook() {
	const {authenticated,loading} = useSelector(state => ({authenticated:state.auth.authenticated,loading: state.auth.config.loading}),shallowEqual)
	return {
		authenticated,
		loading
	}
}


export {
	useAppHook
}
