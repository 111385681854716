import React, {useRef, useState} from 'react'
import {Button, ChipsSelect, Headings, Input, Modal, Seperator, StatelessTab, Texts, TOAST} from "../../../../../retro";
import AppSwitcherIcon from '@atlaskit/icon/glyph/app-switcher'
import {useParams} from "react-router-dom";
import {post} from "../../../../Network/Axios";

export default function MarketInstaller({onClose}) {
    const [tabs,setTabs] = useState("Browse")
    const [type,setType] = useState("Middleware")
    const TITLE = useRef();
    const DES = useRef();
    const {id} = useParams()
	return (
        <Modal style={{
            maxWidth:900,
            width:'100%',
            height:'80%'
        }} tabs={(
            <StatelessTab onClick={setTabs} style={{marginBottom:0,marginTop:0,paddingTop:0,borderBottom:'none',paddingLeft:20,paddingRight:20}} active={tabs} tabs={[
                "Browse",
                "Catalog",
                "Install App"
            ]}/>
        )} onClose={onClose} title="Add App" description="Install app from marketplace or create a new app" >
            {
                tabs!=="Install App" &&
                <div style={{minHeight:400}} className="flex h-100 center vertically">
                    <AppSwitcherIcon size="xlarge"/>
                    <Headings.Regular className="mt2">
                        App directory
                    </Headings.Regular>
                    <Texts.Regular>
                        No app found
                    </Texts.Regular>
                </div>
            }
            {
                tabs==="Install App"&&
                <div className="FadeInRight-Effect">
                    <div className="mb3">
                        <Headings.Regular>
                            Create App
                        </Headings.Regular>
                        <Texts.Regular>
                            add following details to create a new app.
                        </Texts.Regular>
                    </div>
                    <Input ref={TITLE} className="mb2" label="Title" placeholder="Enter title" morph={false}/>
                    <Input ref={DES} className="mb2" label="Description" placeholder="Enter description" morph={false}/>
                    <Seperator/>
                    <ChipsSelect type="single" selected={type} onSelect={setType} options={[
                        "Middleware","DashboardInject",
                    ]} label="Select Type"/>
                    <Button onClick={()=>{
                        let title = TITLE.current.value;
                        let description = DES.current.value;
                        if(!title){
                            TOAST.error("Please enter a valid title")
                            return
                        }
                        if(!description){
                            TOAST.error("Please enter a valid description")
                            return
                        }
                        let data = {
                            name:title,
                            des:description,
                            type
                        }
                        post(`/clients/market/${id}`,data,(e,r)=>{
                            if(e){
                               TOAST.handleError(e)
                            }else{
                                onClose()
                                TOAST.success("App installed successfully")
                            }
                        })
                    }} margin="mt4" className="btn-primary">
                        + Create App
                    </Button>
                </div>
            }
        </Modal>
	)
}
