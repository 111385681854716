import React from "react";
import { Route, Switch } from "react-router-dom";
import Routes from "./routes";
import { NotFound } from "../../../../../retro";
import Bookings from "./Logs";
import BookingQueue from "./BookingQueue";
import RefundQueue from "./RefundQueue";
import PerksBos from "./PerksBos";
import ResellerBos from "./ResellerBosHotelFile";
import AgencyBos from './AgencyBosHotelFile'
import InvoiceQueue from './InvoiceQueue'

export default function index() {
  return (
    <Switch>
      <Route path='/app/apps/meta/hotel' exact>
        <Routes />
      </Route>
      {/* <Route path='/app/apps/meta/hotel/bos' exact>
        <Bos />
      </Route> */}
      <Route path='/app/apps/meta/hotel/reseller' exact>
        <ResellerBos />
      </Route>
        <Route path='/app/apps/meta/hotel/agency' exact>
            <AgencyBos />
        </Route>
        <Route path='/app/apps/meta/hotel/invoice' exact>
            <InvoiceQueue />
        </Route>
      <Route path='/app/apps/meta/hotel/perks/bos' exact>
        <PerksBos />
      </Route>
      <Route path='/app/apps/meta/hotel/bookings' exact>
        <Bookings />
      </Route>
      <Route path='/app/apps/meta/hotel/queue/refunds' exact>
        <RefundQueue />
      </Route>
      <Route path='/app/apps/meta/hotel/queue/bookings' exact>
        <BookingQueue />
      </Route>
      <NotFound />
    </Switch>
  );
}
