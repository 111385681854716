import React, {useRef, useState} from 'react'
import {Button, Input, NavDivider, Navigation, TOAST} from "retro/index";
import {post} from "App/Network/Axios";

export default function RegenerateCancellationChargesInvoice() {
	const TRANSID = useRef()
	const AMOUNT = useRef()
	const REMARKS = useRef()
    const [loading,setLoading] = useState(false)
	return (
		<div>
            <Navigation backUrl={'back'} title="Cancel Reseller Cancellation Charge Invoice Generation" />
            <NavDivider />
			<Input
				ref={TRANSID}
				type="text"
				label="Transaction Id"
				placeholder="Transaction Id"
			/>
			<Input
				ref={AMOUNT}
				type="number"
				label="Cancellation Charges"
				placeholder="Cancellation Charges"
			/>
			<Input
				ref={REMARKS}
				type="text"
				label="Remarks"
				placeholder="Remarks"
			/>
            <Button
                onClick={()=>{
					let transId = TRANSID.current.value;
					let amount = AMOUNT.current.value;
					let remarks = REMARKS.current.value;
					if(!transId){
						TOAST.error("Enter a valid trans id")
						return
					}
					amount = parseInt(amount,10)
					if(amount<0)
					amount = 0;
					if(!amount){
						TOAST.error("Enter a amount")
						return
					}
					setLoading(true)
					post(`/meta/${transId}/flight/reseller/cancellation`,{
						remarks,
						cancellation:amount,
						inv : "CNC",
						crn : "CRCN"
					},(e,r)=>{
						setLoading(false)
						if(r){
							TOAST.success("Regeneration done successfully")
							TRANSID.current.value = ""
							REMARKS.current.value = ""
						}else{
							TOAST.handleError(e)
						}
					})
                }}
                className="btn-black btn-primary"
                margin="pd2 mt4"
                loading={loading}
            >
                Regenerate
            </Button>
		</div>
	)
}
