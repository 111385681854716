import React, {useEffect, useRef, useState} from 'react'
import {Button, ColorInput, Pages, SelectInput, TOAST} from "../../../../../retro";
import {get, post} from "../../../../Network/Axios";
import {useParams} from "react-router-dom";

export default function Theme() {
    const {id} = useParams();
    const [color,setColor] = useState("#6367FA")
    const [fontFamily,setFontFamily] = useState("Dice")
    const FONTFAMILY = useRef();
    const load = id => {
        get(`/clients/theme/${id}`,(e,r)=>{
            if(r){
                if(r.color)
                    setColor(r.color)
                if(r.font)
                   setFontFamily(r.font)
            }
        })
    }
    useEffect(()=>{
       load(id)
    },[id])
    const submit = () => {
        const data = {
            color,
            fontSize:14,
            font:fontFamily,
        }
        post(`/clients/theme/${id}`,data,(e,r)=>{
            if(r){
                TOAST.success("Theme updated successfully")
            }else{
                TOAST.handleError(e)
            }
        })
    }
	return (
		<div>
            <Pages.Title title="Theme" description="Manage theme of this client below."/>
            <div className="mt4">
                <ColorInput onChange={hex => {
                    setColor(hex)
                }} morph={true} defaultColor={color} label="Platform Color"/>
                <SelectInput onChange={() => {
                    setFontFamily(FONTFAMILY.current.value)
                }} ref={FONTFAMILY} defaultValue={fontFamily} label="Font Family" options={[
                    {
                        label: "Dice",
                        value: 'Dice'
                    },
                    {
                        label: "Roboto",
                        value: 'Roboto'
                    },
                    {
                        label: "Gilroy",
                        value: 'Gilroy'
                    },
                    {
                        label: "Proxima",
                        value: 'proxima-nova'
                    },
                    {
                        label: "Lato",
                        value: 'Lato'
                    },
                    {
                        label: "Open Sans",
                        value: 'Open Sans'
                    },
                    {
                        label: "Rubik",
                        value: 'Rubik'
                    }
                ]}/>
                <Button onClick={submit} className="btn-secondary mt3">
                    Update Platform Settings
                </Button>
            </div>
		</div>
	)
}
