import React, {useRef, useState} from 'react'
import {Button, Input, NavDivider, Navigation, TOAST} from "retro/index";
import {post} from "App/Network/Axios";

export default function RegenerateAncillaryInvoiceController() {
    const TRANSID = useRef()
    const INVPREFIX = useRef()
    const [loading,setLoading] = useState(false)
    const CRNPREFIX = useRef()
    const REMARKS = useRef()
	return (
		<div>
            <Navigation backUrl={'back'} title="Regenerate Financial Cancellation Charges" />
            <NavDivider />
            <Input
                ref={TRANSID}
                type="text"
                label="Transaction Id"
                placeholder="Transaction Id"
            />
            <Input
                defaultValue="ANC"
                ref={INVPREFIX}
                type="text"
                label="Invoice Prefix"
                placeholder="Invoice Prefix"
            />
            <Input
                defaultValue="ANCR"
                ref={CRNPREFIX}
                type="text"
                label="Credit Note Prefix"
                placeholder="Credit Note Prefix"
            />
            <Input
                ref={REMARKS}
                type="text"
                label="Remarks"
                placeholder="Remarks"
            />
            <Button
                onClick={()=>{
                    setLoading(true)
                    let transId = TRANSID.current.value;
                    if(!transId) {
                        TOAST.error("Enter a valid transaction id")
                        return
                    }
                    let inv = INVPREFIX.current.value;
                    if(!inv) {
                        TOAST.error("Enter a valid invoice id")
                        return
                    }
                    let crn = CRNPREFIX.current.value;
                    if(!crn) {
                        TOAST.error("Enter a valid credit note id")
                        return
                    }
                    let remarks = REMARKS.current.value;
                    if(!remarks) {
                        TOAST.error("Enter a valid remarks")
                        return
                    }
                    post(`/meta/${transId}/flight/regenerate/ancillary`,{
                        crn,
                        inv,
                        remarks
                    },(e,r)=>{
                        setLoading(false)
                        if(r){
                            TOAST.success("Regeneration done successfully")
                            TRANSID.current.value = ""
                            INVPREFIX.current.value = ""
                            CRNPREFIX.current.value = ""
                        }else{
                            TOAST.handleError(e)
                        }
                    })
                }}
                className="btn-black btn-primary"
                margin="pd2 mt4"
                loading={loading}
            >
               Regenerate
            </Button>
		</div>
	)
}
