import React, {useCallback, useEffect, useState} from 'react'
import {Loader, ScrollableDynamicTable as DynamicTable, TOAST as Alert} from "../../../../../../../retro";
import {get} from "../../../../../../Network/Axios";
import {useParams} from "react-router-dom";
import Moment from 'moment'

export default function AllScanners() {
	let [loading, setLoading] = useState(true);
	const {id} = useParams()
	const [search, setSearch] = useState({
		page: 0,
		sort: 'id',
		order: 'desc',
		q:undefined
	})
	const [Data, SetData] = useState({
		total: 0,
		currentPage: 0,
		totalPages: 0,
		items: []
	})
	const loadPage = useCallback((search) => {
		get(`/scanners/${id}`, (e, r) => {
			if (r) {
				SetData({
					...r
				})
				setLoading(false)
			} else {
				Alert.handleError(e)
			}
		}, {
			offset: search.page,
			sort: search.sort,
			order: search.order,
			q:search.q
		})
	}, [SetData])
	useEffect(() => {
		loadPage(search)
	}, [search])
	return (
		<div>
			{
				loading ?
					<Loader/> :
					<div className="relative">
						<DynamicTable
							setSearch={(search) => {
								setSearch({
									...search,
									page: 0
								})
							}} search={search}
							setPage={page => {
								setSearch({
									...search,
									page
								})
							}}
							data={{
								current: Data.currentPage,
								total: Data.totalPages,
								items: Data.items.map(item => {
									return {
										content: item,
										render: [
											{
												title:`#${item.id}`,
												weight: .3,
											},{
												title:item.title,
												weight: 1,
											},
											{
												weight: 2,
												title: item.email
											},
											{
												weight: 1,
												title: `${Moment(item.createdAt).format("DD MMM YYYY HH:mm")}`
											}
										]
									}
								})
							}} headers={[
							{
								weight: .3,
								sort: 'id',
								title: "Id",
							},{
								weight: 1,
								sort: 'name',
								title: "Name",
							},
							{
								weight: 2,
								title: "Email",
								sort: 'email'
							},
							{
								weight: 1,
								title: "Created On",
							}
						]}/>
					</div>
			}
		</div>
	)
}
