/**
 * Author[Shubham Rawat]
 * Version 1.0.0
 * 26th December | 1.0.0 | Shubham Rawat | created a dropdown component with multiple select functionality 
*/

import React, { useState, useEffect, useRef, useId } from 'react';
import './style.css';

const MultiSelectDropdown = ({
  label,
  placeholder = '',
  className,
  style = {},
  options,
  selectedOptions,
  setSelectedOptions,
  selectAllOption = false,
  showSelectedOptins = true,
  enableSearch = true,
}) => {
  const ID = useId();

  const [showOptions, setShowOptions] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const dropdownRef = useRef(null);

  const filteredOptions = options.filter((option) =>
    option.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleCheckboxChange = (option) => {
    if (selectedOptions.includes(option)) {
      // Remove the option if already selected
      setSelectedOptions(
        selectedOptions.filter((selected) => selected !== option)
      );
    } else {
      // Add the option if not selected
      setSelectedOptions([option, ...selectedOptions]);
    }
  };

  const toggleOptions = () => {
    setShowOptions(!showOptions);
  };

  const toggleAllSelect = () => {
    if (selectedOptions.length === options.length) {
      setSelectedOptions([]);
    } else {
      setSelectedOptions([...options]);
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      // Clicked outside the dropdown, close it
      setShowOptions(false);
    }
  };

  useEffect(() => {
    // Add event listener when the component mounts
    window.addEventListener('click', handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div className={`relative ${className}`} ref={dropdownRef}>
      <div className="text-start mb1">
        {label && (
          <label htmlFor={ID} className={`text-input-label`}>
            {label}
          </label>
        )}
      </div>
      <div
        className="select-wrapper"
        style={{ maxWidth: style.maxWidth ? style.maxWidth : 'auto' }}
      >
        <div onClick={toggleOptions} className="border multi-select">
          {selectedOptions.length > 0 ? (
            <div
              className="flex flex-wrap"
              style={{ maxHeight: '1.5rem', overflow: 'clip' }}
            >
              {selectedOptions.map((option, index) => (
                <div
                  key={index}
                  onClick={() => handleCheckboxChange(option)}
                  className="mr1 pr1 pl1 rounded-sm pointer"
                  style={{
                    color: 'blue',
                    backgroundColor: '#f0f0f0',
                  }}
                >
                  {option}
                </div>
              ))}
            </div>
          ) : (
            <div className="text-muted">
              {placeholder ? placeholder : 'Select Options'}
            </div>
          )}
          <i
            className="fas fa-chevron-down"
            style={{ pointerEvents: 'none' }}
          />
        </div>
        {showOptions && (
          <div className="searchable-result FadeInDown-Effect">
            {enableSearch && (
              <input
                type="text"
                placeholder="Search..."
                className="w-100 pd1"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            )}
            {selectAllOption && (
              <div className="pd1 flex vertical reverse">
                <label onClick={toggleAllSelect} className="text-underline">
                  {selectedOptions.length === options.length
                    ? 'Deselect All'
                    : 'Select All'}
                </label>
              </div>
            )}
            {showSelectedOptins && selectedOptions.length > 0 && (
              <div className="pd1 border-bottom">
                <strong>Selected Options:</strong>
              </div>
            )}
            {showSelectedOptins &&
              selectedOptions.map((option, index) => (
                <Option
                  key={index}
                  option={option}
                  checked={selectedOptions.includes(option)}
                  handleCheckboxChange={handleCheckboxChange}
                />
              ))}
            {showSelectedOptins && selectedOptions.length > 0 && (
              <div className="border-bottom" />
            )}
            <div className="pd1 border-bottom">
              <strong>Available Options:</strong>
            </div>
            {filteredOptions.map((option, index) => (
              <Option
                key={index}
                option={option}
                checked={selectedOptions.includes(option)}
                handleCheckboxChange={handleCheckboxChange}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const Option = ({ option, checked, handleCheckboxChange }) => {
  return (
    <label className="flex align-center pd1">
      <input
        type="checkbox"
        value={option}
        checked={checked}
        className="mr1"
        onChange={() => handleCheckboxChange(option)}
      />
      {option}
    </label>
  );
};

export default MultiSelectDropdown;
