import moment from 'moment'

const calculateFlights = expenses =>{
	const overview = {
		total:0,
		value:0,
		round:0,
		oneWay:0
	}
	let dateSeries = {}
	let statusSeries = {}
	const byStatus = {}
	const byStatusValue = {}
	const byDomain = {}
	const byDomainSeries = {}
	const byAirline = {}
	expenses.forEach(item=>{
		let date = moment(item.createdAt).format("DD-MM-YYYY");
		if(item.status==="COMPLETED"){
			overview.total = overview.total + 1;
			overview.value = overview.value + item.amount;
			if(item.category==="DOMESTICFLIGHT"){
				overview.oneWay = overview.oneWay + 1;
			}else{
				overview.round = overview.round + 1;
			}
			{
				let dateItem = dateSeries[date] || 0;
				dateItem = dateItem +  parseInt(item.amount,10);
				dateSeries[date] = dateItem;
			}
			{
				let domain = byDomain[item.domain] || 0;
				domain = domain + 1;
				byDomain[item.domain] = domain;
			}
			{
				let domain = byDomainSeries[item.domain] || {};
				let domainItem = domain[date] || {count:0,value:0}
				domainItem.count = domainItem.count + 1;
				domainItem.value = domainItem.value +item.amount;
				domain[date] = domainItem;
				byDomainSeries[item.domain] = domain;
			}
			{
				let segment = item.ticket?.segments;
				if(segment && segment.length){
					segment = segment[0]
					let sector = `${segment.departure.code}-${segment.arrival.code}`
					let airline = segment.airline || "Not Defined";
					let airlineItem = byAirline[airline]||{
						count:0,
						value:0,
						round:0,
						oneWay:0,
						sectors:{

						}
					}
					let sectorItem = airlineItem.sectors[sector]||{count:0, value:0,round:0,
						oneWay:0}
					airlineItem.count = airlineItem.count + 1;
					airlineItem.value = airlineItem.value + item.amount;
					sectorItem.count = sectorItem.count + 1;
					sectorItem.value = sectorItem.value + item.amount;
					if(item.category==="DOMESTICFLIGHT"){
						airlineItem.oneWay = airlineItem.oneWay + 1;
						sectorItem.oneWay = sectorItem.oneWay + 1;
					}else{
						airlineItem.round = airlineItem.round + 1;
						sectorItem.round = sectorItem.round + 1;
					}
					airlineItem.sectors[sector] = sectorItem;
					byAirline[airline] = airlineItem;
				}
			}
		}
		{
			let statusItem = statusSeries[item.status] || {};
			let status = statusItem[date] || {count:0,value:0};
			status.value = status.value +  parseInt(item.amount,10);
			status.count = status.count + 1;
			statusItem[date] = status;
			statusSeries[item.status] = statusItem;
		}
		{
			let status = byStatus[item.status] || 0;
			status = status + 1;
			byStatus[item.status] = status;
		}
		{
			let status = byStatusValue[item.status] || 0;
			status = status + item.amount;
			byStatusValue[item.status] = status;
		}
	})
	return{
		byAirline,
		tiles : [
			{
				title:"Total Flights",
				value : overview.total
			},
			{
				title:"Total Value",
				value : `${parseInt(overview.value/1000,10)}k`
			},
			{
				title:"One Way Flights",
				value : overview.oneWay
			},
			{
				title:"Round Flights",
				value : overview.round
			},
		],
		byDomainSeries,
		dateSeries:Object.keys(dateSeries).map(item=>({
			time:moment(item,"DD-MM-YYYY").valueOf(),
			value:dateSeries[item]
		})).sort((a,b)=>a.time-b.time).map(a=>{
			return [a.time,a.value]
		}),
		statusSeries,
		byStatus,
		byDomain,
		byStatusValue
	}
}

export {
	calculateFlights
}
