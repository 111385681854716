import React, {useEffect, useRef, useState} from 'react'
import {Button, Input, Modal, Pages, TOAST} from "../../../../../retro";
import {get, post} from "../../../../Network/Axios";
import {useParams} from "react-router-dom";

export default function Manager() {
	const [modal,setModal] = useState(false)
	const NAME = useRef();
	const [data,setData] = useState({})
	const EMAIL = useRef();
	const MOBILE = useRef();
	const BRANCH = useRef();
	const CODE = useRef();
	const {id} = useParams();
	const load = id => {
		get(`/clients/${id}`, (e, r) => {
			if (r) {
				setData({
					...r,
					loading: false
				})
			}
		})
	}
	useEffect(()=>{
		load(id)
	},[id])
	function validateEmail(email) {
		var pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
		return pattern.test(email);
		}
	function validateName(name) {
		// Regular expression pattern to allow alphabets, spaces, and apostrophes
		var pattern = /^[a-zA-Z\s']+$/;
	
		// Test the name against the pattern
		return pattern.test(name);
	}
	function validateEmpCode(code) {
		// Regular expression pattern to allow alphanumeric characters, spaces, and apostrophes
		var pattern = /^[a-zA-Z0-9\s']+$/;
	
		// Test the name against the pattern
		return pattern.test(code);
	}
	return (
		<div>
			<Pages.Title title="Relationship Manager" description="Relationship manager assigned to this account."/>
			<div className="border mt2 mb2 rounded-sm pd2">
				{
					data.manager?<>
						<p className="fw-bold">
							{data.manager.name}
						</p>
						<p className="text-small">
							{data.manager.email}
						</p>
						<p className="text-small">
							(Mobile: {data.manager.mobile} ,Code: {data.manager.code}, Branch: {data.manager.branch})
						</p>
					</>:<p>
						No manager assigned.
					</p>
				}
			</div>
			<Button onClick={()=>{
				setModal(true)
			}} className="btn btn-black">
				+ Assign Manager
			</Button>
			{
				modal&&
				<Modal title="Assign Relationship Manager" description="Select relationship to account" onClose={()=>{
					setModal(false)
				}}>
					<Input ref={NAME} type="text" label="Name" placeholder="Enter Name Of Person"/>
					<Input ref={EMAIL} type="text" label="Email Address" placeholder="Enter email address Of person"/>
					<Input ref={MOBILE} type="number" label="Contact Number" placeholder="Enter contact details of person"/>
					<Input ref={CODE} type="text" label="Employee Id" placeholder="Enter employee id of person"/>
					<Input ref={BRANCH} type="text" label="Branch" placeholder="Enter branch person"/>
					<Button debounce={true} className="btn-primary btn-max mt4" onClick={()=>{
						let name = NAME.current.value;
						if(!name || name.length<2 || !validateName(name)){
							TOAST.error("Enter a valid name")
							return
						}
						let email = EMAIL.current.value;
						if(!email || email.length<2 || !validateEmail(email)){
							TOAST.error("Enter a valid email")
							return
						}
						let mobile = MOBILE.current.value;
						if(!mobile || mobile.length<10 || mobile.length>10){
							TOAST.error("Enter a valid mobile number")
							return
						}
						let code = CODE.current.value;
						if(!code || code.length<2 || !validateEmpCode(code)){
							TOAST.error("Enter a valid code")
							return
						}
						let branch = BRANCH.current.value;
						if(!branch || branch.length<2 || !validateName(branch)){
							TOAST.error("Enter a valid branch")
							return
						}
						post(`/clients/${id}/manager`,{name,email,mobile,code,branch},(e,r)=>{
							if(r){
								load(id)
								setModal(false)
								TOAST.success("Relation ship manager updated successfully!")
							}else{
								TOAST.handleError(e)
							}
						})
					}}>
						Assign Relationship Manager
					</Button>
				</Modal>
			}
		</div>
	)
}
