import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {get} from "../Network/Axios";
import {AuthActions} from "../Redux/Reducers/auth";

export default function useConfigHook(){
	const dispatch = useDispatch();
	const {config,authenticated} = useSelector(state => ({authenticated:state.auth.authenticated,config:state.auth.config}));
	useEffect(()=>{
		if(config.loading && authenticated){
			get('/config',(e,r)=>{
				if(r){
					dispatch({
						type: AuthActions.Config,
						data:r
					})
				}
			})
		}
	},[config.loading,authenticated,dispatch])
	return {
		...config
	}
}
