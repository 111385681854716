import NoAccess from 'App/Common/NoAccess';
import useRoleHook from 'App/Hooks/useRoleHook';

const RBAC = ({ role, children, renderNoAccess = false }) => {
  const isAllowed = useRoleHook(role);

  if (!isAllowed && renderNoAccess) {
    return <NoAccess />;
  }
  if (!isAllowed) return null;
  return <>{children}</>;
};

export default RBAC;
