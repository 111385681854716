import React, {useState} from 'react'
import {Modal} from "retro/index";
import StatelessTabs from "retro/Tabs/StatelessTabs";
import FullCancellation from './FullCancellation'
import PartialCancellation from './PartialCancellation'


export default function FlightCancelBooking({onReload,data,transId, setCancellationModal}) {
	console.log({data});
	const [tab,setTab] = useState(data.status.toLowerCase().includes('partial')?"Partial Cancellation":"Full Cancellation")
	let tabs = ["Full Cancellation","Partial Cancellation"]
	if(data.service==="Flight" && data.ticket.travellers.length===1){
		tabs = ["Full Cancellation"]
	}
	return (
		<Modal width={550} description="Choose details below to cancel booking" tabs={!data.status.toLowerCase().includes('partial') && <StatelessTabs style={{marginTop:"-1rem"}} className="mb0 pl2 pr2" onClick={setTab} active={tab}  tabs={tabs}/>} title="Cancel Flight Booking" onClose={()=> setCancellationModal(false)}>
			{tab==="Full Cancellation" &&  <FullCancellation transId={transId}  data={data} onReload={onReload} setCancellationModal={setCancellationModal}/>}
			{tab==="Partial Cancellation" && <PartialCancellation transId={transId} data={data} onReload={onReload} setCancellationModal={setCancellationModal}/>}
		</Modal>
	)
}
