import React, {useEffect, useRef, useState} from 'react'
import {Button, Input, Loader, TextEditor, TOAST as Alert} from "../../../../../retro";
import {get, post} from "../../../../Network/Axios";
import {useParams} from "react-router-dom";

export default function ApiLogs() {
	let Header = useRef();
	let Footer = useRef();
	let From = useRef();
	const [loading,setLoading] = useState(true)
	const [data,setData] = useState({
		header:"",
		footer:"",
		from:""
	})
	const {id} = useParams();
	useEffect(()=>{
		get(`/apis/${id}/settings/email`, (e, r) => {
			if (r) {
				setData(r)
				setLoading(false)
			} else {
				Alert.handleError(e)
			}
		})
	},[setData])
	let submit = () => {
		let header = Header.current.root.innerHTML;
		let footer = Footer.current.root.innerHTML;
		let data = {
			header,
			footer,
			from:From.current.value
		}
		post(`/apis/${id}/settings/email`,data, (e, r) => {
			if (r) {
				Alert.success("Settings posted successfully!")
			} else {
				Alert.handleError(e)
			}
		})
	}
	if(loading){
		return (<div>
			<Loader/>
		</div>)
	}
	return (
		<div id="non-sticky" className="FadeInRight-Effect">
			<Input defaultValue={data.from} className="mb3" label="From Address" morph={true} placeholder="Enter From Address" ref={From}/>
			<p className="mb3" style={{
				fontWeight:600,
				fontSize:16
			}}>
				Email Header:
			</p>
			<TextEditor prefill={data.header} id="header" ref={Header}/>
			<p className="mb3 mt5" style={{
				fontWeight:600,
				fontSize:16
			}}>
				Email Footer:
			</p>
			<TextEditor prefill={data.footer} id="footer" ref={Footer}/>
			<Button onClick={submit} className="mt4 btn-primary">
				Update Email Settings
			</Button>
		</div>
	)
}
