import React, { useEffect, useState } from 'react';
import {
  Button,
  DateRange,
  Loader,
  ScrollableDynamicTable,
  TOAST,
} from 'retro';
import Moment from 'moment';
import { get } from 'App/Network/Axios';
import { DownloadCsv } from 'retro/Utils';
import Graph from './graph-stats-circle.svg';
import Airlines from './airlines.json';
import RBAC from 'App/HOCs/RBAC';
import ROLES from 'App/Constants/Roles';

export default function index() {
  const [Dates, setDates] = useState();
  const [loading, setLoading] = useState(false);
  const airlines = {};
  Airlines.forEach((item) => {
    airlines[item.iata] = item.name;
  });
  airlines['QP'] = 'Akasa Air';
  const [Data, SetData] = useState({
    total: 0,
    currentPage: 0,
    totalPages: 0,
    items: [],
  });
  const downloadData = () => {
    setLoading(true);
    get(
      `/meta/flight/all`,
      (e, r) => {
        if (e) {
          setLoading(false);
          console.log(e);
          TOAST.error('Unable to download');
          return;
        }
        let data = [];
        let headers = [
          'Id',
          'Provider',
          'Category',
          'Journey Type',
          'Cabin Class',
          'Booking Id',
          'Status',
          'Provider Id',
          'Payment Type',
          'Amount',
          'Ledger Id',
          'Ledger Amount',
          'Ledger Balance',
          'Pg Order Id',
          'Pg Payment Id',
          'Pg Refund Id',
          'Booker Name',
          'Booker Email Address',
          'Client Code',
          'Client Name',
        ];
        data.push(headers);
        r.bookings.map((item) => {
          if (item.status === 'FAILED') {
            data.push([
              item.id,
              item.provider,
              item.category === 'DOMESTICROUNDFLIGHT'
                ? 'Round Trip'
                : 'One Way',
              'Domestic',
              'Economy',
              item.transId,
              item.status,
              item.ticket?.flowId || item.providerId || '-',
              item.paymentType,
              item.amount,
              item.ledger?.ledgerId || '-',
              item.ledger?.amount || '-',
              item.ledger?.balance || '-',
              item.attrs['orderId'] || item.attrs['order_id'] || '-',
              item.attrs['paymentId'] || item.attrs['payment_id'] || '-',
              item.attrs['refundId'] || item.attrs['refund_id'] || '-',
              item.attrs['bookedBy'] || '-',
              item.attrs['bookedByEmail'] || '-',
              item.client.code,
              item.client.name,
            ]);
          }
        });
        DownloadCsv(
          `flight_failed_bos_${Moment(Dates.startDate).format(
            'DD_MM_YYYY'
          )}_${Moment(Dates.endDate).format('DD_MM_YYYY')}.csv`,
          data
        );
        setLoading(false);
      },
      {
        start: Dates.startDate,
        end: Dates.endDate,
      }
    );
  };
  useEffect(() => {
    if (Dates) {
      get(
        `/meta/flight/failed`,
        (e, r) => {
          if (r) {
            SetData({
              ...r,
              currentPage: Dates.page ? Dates.page : 0,
              totalPages: r.totalPages > 0 ? r.totalPages : Data.totalPages,
              total: r.total > 0 ? r.total : Data.total,
            });
          } else {
            Alert.handleError(e);
          }
        },
        {
          start: Dates.startDate,
          end: Dates.endDate,
          offset: Dates.page,
        }
      );
    }
  }, [Dates]);
  if (loading) {
    return (
      <div>
        <div
          style={{
            margin: '0 -4rem',
            padding: '2rem 4rem',
            borderBottom: '1px solid #dedede',
          }}
        >
          <div className='flex horizontally center-vertically'>
            <div className='flex-1'>
              <DateRange
                defaultValue={{
                  startDate: Moment().add(-31, 'd').valueOf(),
                  endDate: Moment().valueOf(),
                }}
                onChange={() => {}}
                className=''
                label='Export from and to'
                type='range'
              />
            </div>
          </div>
        </div>
        <Loader />
      </div>
    );
  }
  let items = [];
  let codes = (code) => {
    if (airlines[code]) return airlines[code];
    return code;
  };
  Data.items.map((item) => {
    if (item.status === 'FAILED') {
      items.push({
        content: item,
        render: [
          item.id,
          item.provider,
          item.category === 'DOMESTICROUNDFLIGHT' ? 'Round Trip' : 'One Way',
          'Domestic',
          'Economy',
          item.transId,
          item.status,
          item.ticket?.flowId || item.providerId || '-',
          item.paymentType,
          item.amount,
          item.ledger?.ledgerId || '-',
          item.ledger?.amount || '-',
          item.ledger?.balance || '-',
          item.attrs['orderId'] || '-',
          item.attrs['paymentId'] || '-',
          item.attrs['refundId'] || item.attrs['refund_id'] || '-',
          item.attrs['bookedBy'] || '-',
          item.attrs['bookedByEmail'] || '-',
          item.client.code,
          item.client.name,
        ].map((title) => ({ weight: 2, title })),
      });
    }
  });
  return (
    <div style={{ width: '100%' }}>
      <div
        style={{
          margin: '0 -4rem',
          padding: '2rem 4rem',
          paddingTop: 0,
          borderBottom: '1px solid #dedede',
        }}
      >
        <div className='flex horizontally center-vertically'>
          <div className='flex-1'>
            <DateRange
              defaultValue={{
                startDate: Moment().add(-31, 'd').valueOf(),
                endDate: Moment().valueOf(),
              }}
              onChange={(dates) => {
                setDates({
                  ...dates,
                  page: 0,
                });
              }}
              className=''
              label='Export from and to'
              type='range'
            />
          </div>
        </div>
      </div>
      <RBAC role={ROLES.REPORTS}>
        <Button onClick={downloadData} className='btn btn-black'>
          Download Data
        </Button>
      </RBAC>
      {Data.items.length === 0 && (
        <div className='border pd6 mt4 flex vertically center'>
          <img
            alt='fetch'
            src={Graph}
            style={{ width: '5rem', height: '5rem' }}
          />
          <h3 className='fw-bold mt4'>No Data Found</h3>
          <p>No data found in this configuration</p>
        </div>
      )}
      <ScrollableDynamicTable
        autoTrigger={false}
        tableStyle={{
          borderSpacing: 0,
        }}
        width={150}
        search={Dates}
        setPage={(page) => {
          setDates({
            ...Dates,
            page,
            total: Dates.totalPages,
          });
        }}
        data={{
          current: Data.currentPage,
          total: Data.totalPages,
          items,
        }}
        headers={[
          'Id',
          'Provider',
          'Category',
          'Journey Type',
          'Cabin Class',
          'Booking Id',
          'Status',
          'Provider Id',
          'Payment Type',
          'Amount',
          'Ledger Id',
          'Ledger Amount',
          'Ledger Balance',
          'Pg Order Id',
          'Pg Payment Id',
          'Pg Refund Id',
          'Booker Name',
          'Booker Email Address',
          'Client Code',
          'Client Name',
        ].map((item) => ({
          weight: 2,
          title: item,
        }))}
      />
    </div>
  );
}
