import React, { useCallback, useEffect, useState } from 'react';
import {
  Loader,
  ScrollableDynamicTable,
  SearchBox,
  TOAST as Alert,
  Button,
  Navigation,
} from 'retro';
import { get } from 'App/Network/Axios';
import OpenIcon from '@atlaskit/icon/glyph/open';
import ViewRequest from './ViewRequest';
import moment from 'moment';
import { DownloadCsv } from 'retro/Utils';
import RBAC from 'App/HOCs/RBAC';
import ROLES from 'App/Constants/Roles';
import Filter from 'retro/Filter';
import FilterDropDown from 'App/Components/Filter/FilterDropDown';

export default function index() {
  let [loading, setLoading] = useState(true);
  const [id, setId] = useState(undefined);
  const [search, setSearch] = useState({
    page: 0,
    sort: 'id',
    order: 'desc',
  });
  const [Data, SetData] = useState({
    total: 0,
    currentPage: 0,
    totalPages: 0,
    items: [],
  });
  const [filterModal,setFilterModal] = useState(false);
	const [filter, setFilter] = useState({
		client: [],
	});
  // const loadPage = useCallback(
  //   (search) => {
  //     get(
  //       `/meta/train/history`,
  //       (e, r) => {
  //         if (r) {
  //           SetData({
  //             ...r,
  //             total: r.total || Data.total,
  //             totalPages: r.totalPages || Data.totalPages,
  //           });
  //           setLoading(false);
  //         } else {
  //           Alert.handleError(e);
  //         }
  //       },
  //       {
  //         offset: search.page,
  //         order: search.order,
  //         q: search.q,
  //       }
  //     );
  //   },
  //   [Data, SetData]
  // );
  const loadPage = useCallback(
    (search) => {
		const clients = filter.client.map(client => client.id).join(',');

      get(
        `/meta/train/history`,
        (e, r) => {
          if (r) {
            SetData({
              ...r,
              total: r.total || Data.total,
              totalPages: r.totalPages || Data.totalPages,
            });
            setLoading(false);
          } else {
            Alert.handleError(e);
          }
        },
        {
          offset: search.page,
          order: search.order,
          q: search.q,
          sort : search.sort,
		      client: clients.length > 0 ? clients : undefined
        }
      );
    },
    [Data, SetData, filter.client]
  );
  
  useEffect(() => {
    loadPage(search);
  }, [search, filter.client]);
  if (loading) {
    return <Loader />;
  }
  return (
    <div className='overScrollY pd6' style={{ height: 'calc(100vh - 120px)' }}>
      <div className='mb2 relative'>
        <Navigation title='Past Queue' />
        <RBAC role={ROLES.REPORTS}>
          <Button
            onClick={() => {
              setLoading(true);
              get(`/meta/train/history/all`, (e, r) => {
                if (r) {
                  let data = [];
                  const headers = [
                    'Request Id',
                    'Origin',
                    'Destination',
                    'Quota',
                    'Train',
                    'Client',
                    'Guest Name',
                    'Guest Email',
                    'Travel Date',
                    'Request Date',
                  ];
                  data.push(headers);
                  r.items.forEach((item) => {
                    const {
                      request: { origin, destination, attrs },
                      client,
                      requestId,
                      guests,
                      request,
                      createdAt,
                    } = item;
                    let line = [];
                    line.push([requestId]);
                    line.push([
                      `${origin} ${attrs.from ? `(${attrs.from})` : ''}`,
                    ]);
                    line.push([
                      `${destination} ${attrs.to ? `(${attrs.to})` : ''}`,
                    ]);

                    line.push([`${attrs.quota || ''}`]);
                    line.push([`${attrs.train || ''}`]);
                    line.push([`${client.clientCode}`]);
                    line.push([
                      `${guests[0].name}
                  	`,
                    ]);
                    line.push([
                      `${guests[0].email}
                  	`,
                    ]);
                    line.push([
                      moment(request.date, 'DD-MM-YYYY').format('DD MMM YYYY'),
                    ]);
                    line.push([
                      moment(createdAt).format('DD MMM YYYY HH:mm a'),
                    ]);
                    data.push(line);
                  });
                  DownloadCsv(
                    `past_queue_${moment().format('DD_MM_YYYY')}}.csv`,
                    data
                  );
                }
                setLoading(false);
              });
            }}
            loading={loading}
            className='btn btn-primary btn-sm absolute top-2 right-0'
            style={{ zIndex: 10 }}
          >
            <i className='fa fa-download mr1' />
            Download Data
          </Button>
        </RBAC>
      </div>
      {/* <SearchBox
        onChange={(q) => {
          setSearch({
            ...search,
            page: 0,
            q,
          });
        }}
        placeholder='Enter your search criteria'
      /> */}
      <Filter
        margin="0 -4rem"
        onSearch={(q) => {
          setSearch({
            ...search,
            page: 0,
            q,
          })
        }}
        onFilter={() => {
          setFilterModal(true)
        }}
      />

      <FilterDropDown
        shown={filterModal}
        filters={filter}
        setFilters={setFilter}
        choices={{
          client: [],
        }}
        types={['Client']}
        onClose={() => {
          setFilterModal(false);
        }}
      />
      <ScrollableDynamicTable
        setSearch={(search) => {
          setSearch({
            ...search,
            page: 0,
          });
        }}
        search={search}
        tableStyle={{
          borderSpacing: 0,
        }}
        setPage={(page) => {
          setSearch({
            ...search,
            page,
          });
        }}
        data={{
          current: Data.currentPage,
          total: Data.totalPages,
          items: Data.items.map((item) => {
            const click = () => {
              setId(item.queueId);
            };
            return {
              content: item,
              render: [
                {
                  children: (
                    <div>
                      <p className='text'>{item.requestId}</p>
                      <p className='text'>
                        {item.request.origin} ({item.request.attrs.from || '-'})
                        - {item.request.destination} (
                        {item.request.attrs.to || '-'})
                      </p>
                      <p className='text-small text-light'>
                        Quota: {item.request.attrs.quota || '-'}
                      </p>
                      <p className='text-small text-light'>
                        Train: {item.request.attrs.train || '-'}
                      </p>
                    </div>
                  ),
                  weight: 1.5,
                },
                {
                  weight: 1.5,
                  children: (
                    <div>
                      <p>{item.client.clientCode}</p>
                      <p className='text-small'>{item.guests[0].name}</p>
                      <p className='text-small'>{item.guests[0].email}</p>
                    </div>
                  ),
                },
                {
                  weight: 1,
                  title: item.status,
                },
                {
                  weight: 1,
                  title: moment(item.request.date, 'DD-MM-YYYY').format(
                    'DD MMM YYYY'
                  ),
                },
                {
                  weight: 1,
                  title: moment(item.createdAt).format('DD MMM YYYY HH:mm a'),
                },
                {
                  weight: 1,
                  buttons: [
                    {
                      title: 'View',
                      className: 'btn-secondary btn-sm',
                      before: <OpenIcon size='small' />,
                      onClick: click,
                    },
                  ],
                  actions: [
                    {
                      title: 'Quick Actions',
                      actions: [
                        {
                          title: 'View',
                          before: <OpenIcon size='small' />,
                          onClick: click,
                        },
                      ],
                    },
                  ],
                },
              ],
            };
          }),
        }}
        headers={[
          {
            weight: 1.5,
            title: 'Details',
          },
          {
            weight: 1.5,
            title: 'Client',
          },
          {
            weight: 1,
            title: 'Status',
          },
          {
            weight: 1,
            title: 'Travel Date',
          },
          {
            weight: 1,
            title: 'Request Date',
          },
          {
            weight: 1,
            title: 'Actions',
            style: {
              justifyContent: 'end',
            },
          },
        ]}
      />
      {id !== undefined && (
        <ViewRequest
          id={id}
          onClose={() => {
            setId(undefined);
            loadPage(search);
          }}
        />
      )}
    </div>
  );
}
