import moment from 'moment'

const calculate = expenses => {
	const overview = {
		total:0,
		categorySets:{},
		value:0,
		currency:"INR",
		claimed:0
	}
	const dateSeries = {}
	const serviceHeatMap = {}
	const service = {}
	const merchant = {};
	const status = {}
	const office = {}


	expenses = expenses.sort((a,b)=>a.date-b.date)
	expenses.forEach(item=>{
		overview.total = overview.total + 1;
		overview.categorySets[item.category] = true;
		overview.value = overview.value + item.amount;
		overview.claimed = overview.claimed + item.amount;
		let date = moment(item.date).format("DD-MM-YYYY");
		let officeName = item?.officeName||"Not Defined"
		let officeMap = office[officeName] || {
			claimed:0,
			amount:0,
			count : 0,
			dates : {}
		};
		let dates = officeMap.dates[date] || 0;
		dates = dates + 1;
		officeMap.dates[date] = dates;
		officeMap.count = officeMap.count + 1;
		officeMap.amount = officeMap.amount + item.amount;
		office[officeName] = officeMap;
		let merchantValue = merchant[item.title] || 0;
		merchantValue = merchantValue + 1;
		merchant[item.title] = merchantValue;
		let serviceItem = service[item.category];
		if(!serviceItem){
			serviceItem = {
				claimed:0,
				amount:0,
				count : 0
			};
		}
		serviceItem.count = serviceItem.count + 1;
		serviceItem.amount = serviceItem.amount +  parseInt(item.amount,10);
		service[item.category] = serviceItem;



		{
			let dateItem = dateSeries[date] || 0;
			dateItem = dateItem +  parseInt(item.amount,10);
			dateSeries[date] = dateItem;
		}

		let serviceHeatValues = serviceHeatMap[item.category] || {}
		let serviceDailyHeatValues = serviceHeatValues[date] || 0
		serviceDailyHeatValues =  serviceDailyHeatValues  +  parseInt(item.amount,10);
		serviceHeatValues[date] = serviceDailyHeatValues;
		serviceHeatMap[item.category] = serviceHeatValues;

	})
	return {
		merchant,
		serviceHeatMap,
		office,
		status,
		series:Object.keys(dateSeries).map(item=>({
			time:moment(item,"DD-MM-YYYY").valueOf(),
			value:dateSeries[item]
		})).sort((a,b)=>a.time-b.time).map(a=>{
			return [a.time,a.value]
		}),
		tiles : [
			{
				title:"Total Expenses",
				value : overview.total
			},
			{
				title:"Total Categories",
				value : Object.keys(overview.categorySets).length
			},
			{
				title:"Total Value",
				value : `${(overview.value/1000).toFixed(2)}k`
			},
			{
				title:"Claimed Value",
				value : `${(overview.claimed/1000).toFixed(2)}k`
			},
		],
		service
	}
}

export {calculate}
