import React, {useEffect, useRef, useState} from 'react'
import {Attachment, Button, FileInput, Pages, TOAST} from "../../../../../retro";
import {get, post} from "../../../../Network/Axios";
import {useParams} from "react-router-dom";

export default function Logo() {
    const {id} = useParams();
    const FILE = useRef();
    const [file,setFile] = useState(undefined);
    const load = id => {
        get(`/clients/theme/${id}`,(e,r)=>{
            if(r){
                setFile(r.logo)
            }
        })
    }
    const submit = () => {
        const data = {
            logo : file
        }
        post(`/clients/theme/${id}`,data,(e,r)=>{
            if(r){
                TOAST.success("Theme updated successfully")
            }else{
                TOAST.handleError(e)
            }
        })
    }
    useEffect(()=>{
        load(id)
    },[id])
	return (
		<>
            <Pages.Title title="Logo" description="Manage logo client below."/>
            <div className="mt4">
                <p>
                    Select File
                </p>
                <div className="flex mb2 mt2">
                    <FileInput updateFiles={(url)=>{
                        setFile(url)
                    }} label="" ref={FILE}/>
                    <Attachment url={file}/>
                </div>
                <Button onClick={submit} className="btn-secondary mt3">
                    Update Logo
                </Button>
            </div>
		</>
	)
}
