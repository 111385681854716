import React, {useRef, useState} from 'react'
import {Button, CheckBox, Form, Input, NavDivider, Navigation, TOAST} from "../../../../../../retro";
import {post} from "App/Network/Axios";
import useConfigHook from "../../../../../Hooks/useConfigHook";

export default function CreateClient() {
    const NAME = useRef();
    const REGISTERED = useRef();
    const REFERENCE = useRef();
    const EMAIL = useRef();
    const CODE = useRef();

    const GSTIN = useRef();
    const TAN = useRef();
    const PAN = useRef();

    const [checked,setChecked] = useState(true)
    const [loading,setLoading] = useState(false);
    const CONFIG = useConfigHook();
    return (
        <>
            <Navigation backUrl={''} title="Create Client" chips={['All Clients', "Create Client"]} description="Enter details to add a new client."/>
            <NavDivider/>
            <Form focus={true} className="mt6" containerStyle={{width:'100%',maxWidth:600}}>
                <Input ref={NAME} type="text" label="Client Name" placeholder="enter client name" morph={true}/>
                <Input ref={REGISTERED} type="text" label="Registered Name" placeholder="enter registered name" morph={true}/>
                <Input ref={CODE} type="text" label="Client Code" placeholder="enter client code" morph={true}/>
                <Input ref={EMAIL} type="text" label="Email Address" placeholder="enter email address" morph={true}/>
                <Input ref={REFERENCE} type="text" label="Reference/Onboarding Id" placeholder="enter onboarding id" morph={true}/>

                <Input ref={GSTIN} type="text" label="Client Gstin(Optional)" placeholder="enter client gstin" morph={true}/>
                <Input ref={PAN} type="text" label="Client Pan(Optional)" placeholder="enter client pan" morph={true}/>
                <Input ref={TAN} type="text" label="Client Tan(Optional)" placeholder="enter client tan" morph={true}/>


                {
                    CONFIG.origin.origin==="CLRTRP"&&	<div className="mt4 mb2" >
                        <CheckBox checked={checked} onChange={()=>{
                            setChecked(!checked)
                        }} label="Do you want to create ooo for this client."/>
                    </div>
                }
                <Button onClick={()=>{
                    let name = NAME.current.value;
                    let registered = REGISTERED.current.value;
                    let email = EMAIL.current.value;
                    let reference = REFERENCE.current.value;
                    let code = CODE.current.value;
                    let pan = PAN.current.value;
                    let tan = TAN.current.value;
                    let gstin = GSTIN.current.value;
                    if(!name){
                        TOAST.error("Enter a valid name")
                        return
                    }
                    if(!registered){
                        TOAST.error("Enter a valid registered name")
                        return
                    }
                    if(!email){
                        TOAST.error("Enter a valid email")
                        return
                    }
                    if(!code){
                        TOAST.error("Enter a valid code")
                        return
                    }
                    let data = {
                        name,email,reference,registered,code,ooo:checked?'yes':"no",
                        pan,tan,gstin
                    }
                    setLoading(true);
                    post('/clients',data,(e,r)=>{
                        if(r){
                            REGISTERED.current.value = ""
                            NAME.current.value = "";
                            REFERENCE.current.value = "";
                            EMAIL.current.value = "";
                            CODE.current.value = "";
                            TOAST.success("Client on-boarded successfully!!")
                        }else{
                            TOAST.handleError(e)
                        }
                        setLoading(false);
                    })
                }} margin="mt3" loading={loading}>
                    Create Client
                </Button>
            </Form>
        </>
    )
}
