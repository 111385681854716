import React, {useEffect, useRef, useState} from 'react';
import {
	Attachment,
	Button, FileInput,
	Input,
	NavDivider,
	Navigation,
	SelectInput,
	TOAST,
} from 'retro/index';
import Moment from 'moment';
import {get, post} from 'App/Network/Axios';
import {PrefferedGST} from '../ImportHotel';

export default function index() {
	const [formData, setFormData] = useState({
		prefferedGst: undefined,
		prefferedGstName: undefined,
		clientCode: undefined,
		clientId: undefined,
		email: undefined,
		allGsts: [],
	});
	const GSTIN = useRef();
	const EMAIL = useRef();
	const PG = useRef();
	const ACCOUNT = useRef();

	const INSURANCE = useRef();
	const VENDORNAME = useRef();
	const VENDORINVOICE = useRef();
	const VENDORAMOUNT = useRef();
	const VISA_START = useRef();
	const PNR = useRef();
	const VISA_END = useRef();
	const VISA_CONFIRMATION = useRef();
	const REMARKS = useRef();

	const [file, setFile] = useState(undefined)
	const FILE = useRef()
	const [loading, setLoading] = useState(false);
	const [accounts, setAccounts] = useState([]);
	const [accountType, setAccountType] = useState(undefined);
	// set dropdowns as per the cleint
	useEffect(() => {
		setAccounts([]);
		if (formData.clientCode) {
			// get the list of accounts for the client
			get(`/accounts/${formData.clientId}`, (e, r) => {
				if (r) {
					setAccounts(r.items);
					setAccountType(undefined);
				}
			});
		}
	}, [formData.clientCode]);

	const importBooking = () => {
		if (!file) {
			TOAST.error('Kindly select a attachment');
			return;
		}
		let insurer = INSURANCE.current.value;
		if (!insurer) {
			TOAST.error('Enter a valid insurer');
			return;
		}
		let email = EMAIL.current.value;
		if (!email) {
			TOAST.error('Enter a valid email address');
			return;
		}let pnr = PNR.current.value;
		if (!pnr) {
			TOAST.error('Enter a valid pnr');
			return;
		}

		let vendorName = VENDORNAME.current.value;
		if (!vendorName) {
			TOAST.error('Enter a valid vendorName');
			return;
		}

		let vendorInvoice = VENDORINVOICE.current.value;
		if (!vendorInvoice) {
			TOAST.error('Enter a valid vendorInvoice');
			return;
		}

		let vendorAmount = VENDORAMOUNT.current.value;
		if (!vendorAmount) {
			TOAST.error('Enter a valid vendorAmount');
			return;
		}

		let start = VISA_START.current.value;
		if (!vendorName) {
			TOAST.error('Enter a valid start date');
			return;
		}

		let end = VISA_END.current.value;
		if (!end) {
			TOAST.error('Enter a valid end date');
			return;
		}


		let confirmation = VISA_CONFIRMATION.current.value;
		if (!confirmation) {
			TOAST.error('Enter a valid confirmation');
			return;
		}

		let remarks = REMARKS.current.value;
		if (!remarks) {
			TOAST.error('Enter a valid remarks');
			return;
		}
		setLoading(true);
		let data = {
			email,
			ticket: {
				vendorName,
				vendorInvoice,
				vendorAmount,
				start,
				end,
				confirmation,
				insurer,
				pnr,
				file,
				remarks
			},
			file,
			account: ACCOUNT.current.value,
			pgId: PG.current?.value,
			client: formData.clientCode,
			gstin:GSTIN.current.value,
			code: formData.clientCode,
		};
		post(`/meta/insurance`, data, (e, r) => {
			if (e) {
				TOAST.handleError(e);
			} else {
				TOAST.success('Insurance imported successfully!');
				if(r?.refId){
					HISTORY.push(`/app/apps/meta/transaction/${r.refId}`)
				}
				EMAIL.current.value = '';
			}
			setLoading(false);
		});
	};
	return (
		<div>
			<Navigation backUrl={'back'} title="Import Insurance"/>
			<NavDivider/>
			{!formData.prefferedGst ? (
				<PrefferedGST data={formData} setData={setFormData} category="visa"/>
			) : (
				<div className="mt4 pd4 border rounded-md">
					<SelectInput
						placeholder="Select"
						hidePlaceholder={false}
						options={[
							...accounts.map((item) => {
								return {
									label: `${item.title} (INR ${item.balance})`,
									value: item.accountId,
								};
							}),
							{label: 'RAZORPAY', value: 'RAZORPAY'},
						]}
						onChange={setAccountType}
						ref={ACCOUNT}
						label="Select Deposit Account"
						className="flex-1"
					/>
					<SelectInput
						placeholder="Select"
						hidePlaceholder={false}
						options={[
							{
								label: `${formData.prefferedGst} - ${formData.prefferedGstName} (Preffered GST)`,
								value: formData.prefferedGst,
							},
							...formData.allGsts.map((gst) => {
								return {
									label: `${gst.gstin} - ${gst.name}`,
									value: gst.gstin,
								};
							}),
						]}
						defaultValue={formData.prefferedGst}
						ref={GSTIN}
						label="Select the GSTIN"
						className="flex-1 mt2"
					/>
					{accountType === 'RAZORPAY' && (
						<Input
							ref={PG}
							type="text"
							label="Enter Pg Id"
							placeholder="Enter Pg Id"
							className="mb2"
						/>
					)}
					<Input
						ref={EMAIL}
						type="text"
						label="Traveller Email Address"
						placeholder="Traveller Address"
						className="flex-1 mt2"
						defaultValue={formData.email}
					/>

					<div className="flex horizontally center-vertically mt2">
						<Input
							ref={INSURANCE}
							type="text"
							label="Insurance Company Name"
							placeholder="Insurance Company Name"
							className="flex-1 mr2"
						/>
						<Input
							ref={PNR}
							type="text"
							label="Travel Ticket Pnr Number"
							placeholder="Travel Ticket Pnr Number"
							className="flex-1 ml2"
						/>
					</div>
					<div className="flex horizontally center-vertically mt2">
						<Input
							ref={VENDORNAME}
							type="text"
							label="Vendor Name"
							placeholder="Vendor Name"
							className="flex-1 mr2"
						/>
						<Input
							ref={VENDORINVOICE}
							type="text"
							label="Vendor Invoice"
							placeholder="Vendor Invoice"
							className="flex-1 ml2"
						/>
					</div>
					<div className="flex horizontally center-vertically mt2">
						<Input
							ref={VENDORAMOUNT}
							type="number"
							label="Insurance Amount"
							placeholder="Insurance Amount"
							className="flex-1 mr2"
						/>
						<Input
							ref={VISA_CONFIRMATION}
							type="text"
							label="Insurance Confirmation Number"
							placeholder="Insurance Confirmation Number"
							className="flex-1 ml2"
						/>
					</div>
					<div className="flex horizontally center-vertically mt2">
						<Input
							ref={VISA_START}
							type="date"
							label="Insurance Start Date"
							placeholder="Insurance Start Date"
							className="flex-1 mr2"
						/>
						<Input
							ref={VISA_END}
							type="date"
							label="Insurance End Date"
							placeholder="Insurance End Date"
							className="flex-1 ml2"
						/>
					</div>
					<Input
						ref={REMARKS}
						type="text"
						label="Remarks"
						placeholder="Enter Remarks"
						className="flex-1 mt2"
					/>
					<div className='flex mb2 mt2'>
						<FileInput
							updateFiles={(url) => {
								setFile(url);
							}}
							label=''
							ref={FILE}
						/>
						<Attachment url={file} />
					</div>
					<Button
						onClick={() => setFormData({})}
						className="btn-black btn-primary mr4"
						margin="pd2 mt4"
					>
						<i className="fa-solid fa-arrow-left mr1"/>
						Previous
					</Button>
					<Button
						onClick={importBooking}
						loading={loading}
						className="btn-black btn-primary mr4"
						margin="pd2 mt4"
					>
						Import Insurance
					</Button>
				</div>
			)}
		</div>
	);
}
