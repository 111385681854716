import React, {useState} from 'react'
import Moment from "moment/moment";
import {Button, DateRange, Navigation, TOAST} from "retro/index";
import RBAC from "App/HOCs/RBAC";
import ROLES from "App/Constants/Roles";
import {post} from "App/Network/Axios";

export default function AccountMis() {
	const [Dates, setDates] = useState({
		startDate: Moment().add(-31, 'd').valueOf(),
		endDate: Moment().valueOf(),
	});
	const [loading, setLoading] = useState(false);
	const downloadData = () => {
		post(
			`/meta/report`,
			{
				start:`${Dates.startDate}`,
				end:`${Dates.endDate}`,
				type:'date'
			},
			(e,r) => {
				if(e){
					TOAST.handleError(e)
				}else{
					var blob = new Blob([r], { type: 'text/csv;charset=utf-8;' });
					if (navigator.msSaveBlob) { // IE 10+
						navigator.msSaveBlob(blob, "reports.csv");
					} else {
						var link = document.createElement("a");
						if (link.download !== undefined) { // feature detection
							// Browsers that support HTML5 download attribute
							var url = URL.createObjectURL(blob);
							link.setAttribute("href", url);
							link.setAttribute("download",  "reports.csv");
							link.style.visibility = 'hidden';
							document.body.appendChild(link);
							link.click();
							document.body.removeChild(link);
						}
					}
				}
			},
		)
	}
	return (
		<div>
			<Navigation
				backUrl={'back'}
				title="Account MIS"
				chips={['Meta', 'MIS', 'Failed', 'Invoices']}
				description="Account Invoices MIS"
			/>
			<div>
				<div className="border pd4 flex center">
					<DateRange
						defaultValue={{
							startDate: Moment().add(-31, 'd').valueOf(),
							endDate: Moment().valueOf(),
						}}
						onChange={(dates) => {
							setDates({
								...dates,
								page: 0,
							});
						}}
						className="flex-1 mt1"
						label=""
						type="range"
					/>
					<RBAC role={ROLES.REPORTS}>
						<Button
							onClick={downloadData}
							className="btn btn-black mt4"
							loading={loading}
						>
							Download Data
						</Button>
					</RBAC>
				</div>
			</div>
		</div>
	)
}
