/**
 * Author[Lakshay Jain]
 * Version 1.0.1
 * 5th January 2024 | 1.0.1 | Shubham Rawat | added filters and documents download option
 */
import React, { useEffect, useRef, useState } from "react";
import { Button, DateInput, DateRange, Loader, Navigation, SelectInput, TOAST } from "retro";
import Moment from "moment";
import { get } from "App/Network/Axios";
import { DownloadCsv } from "retro/Utils";
import Graph from "./graph-stats-circle.svg";
import RBAC from "App/HOCs/RBAC";
import ROLES from "App/Constants/Roles";
import MultiSelectDropdown from "App/Components/MultiSelectDropdown";
import { downloadAll } from "../Repository/utils";
import useConfigHook from "App/Hooks/useConfigHook";

export default function index() {
  const [loading, setLoading] = useState(false);
  const CONFIG=useConfigHook();
  const DATETYPE = useRef();

  const [dates,setDates] = useState({
    start: Moment().add(-31,'d').valueOf(),
    end: Moment().valueOf(),
  });
  const STARTDATE = useRef();
  const ENDDATE = useRef();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [clientList, setClientList] = useState([]);
  const PERSONS = {
	"aditya.vyawhare@antrepriz.com":true,
	"aavishkar@antrepriz.com":true,

}


  

  // setting the client list on load
  useEffect(()=>{
    let templist = [];
    get('/clients/dump',(e,r)=>{
      if(r){
        r.items.map((client)=>{
          templist.push(client.code)
        })
        setClientList([...templist]);
      }
      },{
      active:true
    })
  },[]);

	let airlines = (item) => {
		let string = undefined
		if(!item?.ticket?.segments){
			return "-";
		}
		item.ticket.segments.forEach(segment => {
			if (!string) {
				string = `${segment.airline}`
			} else {
				string = `${string} , ${segment.airline}`
			}
		});
		return string;
	}
	let flightCode = (item) => {
		let string = undefined
		if(!item?.ticket?.segments){
			return "-";
		}
		item.ticket.segments.forEach(segment => {
			if (!string) {
				string = `${segment.flightCode} ${segment.flightNumber}`
			} else {
				string = `${string} , ${segment.flightCode}  ${segment.flightNumber}`
			}
		});
		return string;
	}
	let segments = (item) => {
		let string = undefined
		if(!item?.ticket?.segments){
			return "-";
		}
		item.ticket.segments.forEach(segment => {
			if (!string) {
				string = `${segment.departure.code}-${segment.arrival.code}`
			} else {
				string = `${string} , ${segment.departure.code}-${segment.arrival.code}`
			}
		});
		return string;
	}
	let times = (item) => {
		let string = undefined
		if(!item?.ticket?.segments){
			return "-";
		}
		item.ticket.segments.forEach(segment => {
			if (!string) {
				string = `${segment.departureDate}-${segment.arrrivalDate}`
			} else {
				string = `${string} , ${segment.departureDate}-${segment.arrrivalDate}`
			}
		});
		return string;
	}
	let traveller = (item) => {
		let string = undefined
		if(!item?.ticket?.travellers){
			return "-";
		}
		item.ticket.travellers.forEach(traveller => {
			if (!string) {
				string = `${traveller.name}`
			} else {
				string = `${string} , ${traveller.name}`
			}
		});
		return string;
	}
	let pnrs = (item) => {
		let set = {}
		let string = undefined
		if(!item?.ticket?.travellers){
			return "-";
		}
		item.ticket.travellers.forEach(traveller => {
			Object.values(traveller.pnrs).forEach(item => {
				set[item] = item;
			})
		});
		Object.values(set).forEach(value => {
			if (!string) {
				string = `${value}`
			} else {
				string = `${string} , ${value}`
			}
		})
		return string;
	}
	let tickets = (item) => {
		let set = {}
		let string = undefined
		if(!item?.ticket?.travellers){
			return "-";
		}
		item.ticket.travellers.forEach(traveller => {
			Object.values(traveller.tickets).forEach(item => {
				set[item] = item;
			})
		});
		Object.values(set).forEach(value => {
			if (!string) {
				string = `${value}`
			} else {
				string = `${string} , ${value}`
			}
		})
		return string;
	}

	// download docs function
	const downloadDocs = () => {
		setLoading(true);
		get(
			'/meta/flight/agency',
			(e, r) => {
			let documents = [];
			if (e) {
				console.log("e", e);
				setLoading(false);
				TOAST.error("Unable to download");
				return;
			}
			r.bookings.map((item) => {
				try {
				// filter data based on selected clients
				if(selectedOptions.length>0 && !selectedOptions.includes(item.client?.code)){
					return; // return if client code does not match
				}
				// filter the documents on the basis of doctype
				if(
					item.invoiceConfig.serviceInvoice &&
					item.invoiceConfig.serviceInvoice!=="SKIPPED" &&
					item.invoiceConfig.serviceInvoice!=="PENDING" &&
					item.invoiceConfig.serviceInvoice!=="FAILED"
				){
					documents.push({
					title: `${item.transId}_${item.client.code}_Service_Invoice.pdf`,
					file: item.invoiceConfig.serviceInvoice
					});
				}
				}
				catch(e){}
				})
				// download and zip if the docs are found
				if(documents.length>0){
				downloadAll(`document_collection`,documents,()=>{
					setLoading(false);
				})
				}else{
				TOAST.error("Found No Documents");
				}
			},{
			start: Moment(STARTDATE.current.value).valueOf(),
			end: Moment(ENDDATE.current.value).add(1,'d').valueOf(),
			filterOn: DATETYPE.current.value,
			}
		)
		}

	const downloadData = () => {
		setLoading(true);
		get(
			`/meta/flight/agency`,
			(e, r) => {
				if (e) {
					console.log("e", e);
					setLoading(false);
					return;
				}
				let data = [];
				let headers = [
					"Id",
					"Trans Id",
					"Reference Id",
					"Provider",
					"Provider Id",
					"Cart Type",
					"Booking Status",
					"Category",
					"Client Code",
					"Client Name",
					"Booker Name",
					"Booker Email Address",
					"Created On Date",
					"Created On Time",
					"Updated On Date",
					"Updated On Time",
					"Cust Code",
					"Gstin",
					"Pan",
					"Tan",
					"Payment Type",
					"Amount",
					"Ledger Id",
					"Ledger Amount",
					"Ledger Balance",
					"Pg Order Id",
					"Pg Payment Id",
					"Pg Refund Id",
					"Refund Ledger Id",
					"Refund Amount",
					"Refund Voucher",
					"Refund Ledger Balance",
					"Voucher",
					"Document Date", /* Invoice Date */
					"CT Gstin",
					"Ct State",
					"Buyer Entity",
					"Buyer Gstin",
					"HSN",
					"Service Charge",
					"Service Charges Document Type",
					"Service Charges Document Number",
					"Service Charges Document Irn", //
					"Service Charges Document File",
					"Service Charges Base Fare",
					"Service Charges Slab",
					"Service Charges Other Charges",
					"Service Charges SGST",
					"Service Charges CGST",
					"Service Charges IGST",
					"Service Charges TOTAL",
					"Airlines",
					"Segments",
					"Dates",
					"Flight Code/Number",
					"Travellers",
					"PNRs",
					"Tickets",
					"Statement Period",
					"Cancelled At",
					"Cancelled By",
					"Cancelled By Email",
					"Manual Refunder Email"
				];

                if(PERSONS[CONFIG.email]){
	               headers.push("Platform Markup")
                 }


				data.push(headers);
				r.bookings.map((item) => {
					const { serviceRequest } = item?.invoiceConfig || {};
					const cancelledAtDate = item?.invoiceConfig?.cancelledAt === 0
											? '-'
											: Moment(item?.invoiceConfig?.cancelledAt).format("DD MMM YYYY hh:mm A")
												|| '-';
					/* Parse Service date if present */
					const parsedServiceRequest = serviceRequest ? JSON.parse(serviceRequest) : undefined;
					const serviceDate = parsedServiceRequest ? Moment(parsedServiceRequest.data.docDtls.dt, 'DD/MM/YYYY').format('DD MMM YYYY') : '-'
					try {
						// filter data based on selected clients
						if(selectedOptions.length>0 && !selectedOptions.includes(item.client?.code)){
							return; // return if client code does not match
						}
					if (item.ticket) {


						let localItem =[
							item.id,
							item.transId,
							item.referenceId||"-",
							item.provider,
							item.providerId || item.ticket?.flowId ||  "-",
							"Booking",
							item.status,
							item.category === "DOMESTICROUNDFLIGHT"
								? "Round Trip"
								: "One Way",
							item.client.code,
							item.client.name,
							item.attrs?.bookedBy || "-",
							item.attrs?.bookedByEmail || "-",
							Moment(item.createdAt).format("DD MMM YYYY"),
							Moment(item.createdAt).format("HH:mm a"),
							Moment(item.updatedAt).format("DD MMM YYYY"),
							Moment(item.updatedAt).format("HH:mm a"),
							item.client?.attrs?.CUST_CODE || "-",
							item.invoiceConfig?.userGst?.gstin || "-",
							item.client?.attrs?.PAN || "-",
							item.client?.attrs?.tan || "-",
							item.paymentType,
							item.amount,
							item.ledger?.ledgerId || "-",
							item.ledger?.amount || "-",
							item.ledger?.balance || "-",
							item.attrs?.orderId || "-",
							item.attrs?.paymentId || "-",
							item.attrs?.refundId || "-",
							item.refund?.ledgerId || "-",
							item.refund?.amount || "-",
							item.invoiceConfig?.refundVoucher || '-',
							item.refund?.balance || "-",
							item.invoiceConfig?.file || "-",
							serviceDate,
							"27AAHCC1775A1ZX",
							"Maharashtra",
							item.invoiceConfig?.userGst?.name || "-",
							item.invoiceConfig?.userGst?.gstin || "-",
							"998551",
							item.invoiceConfig?.serviceCharges || "-",
							"Service Invoice",
							item.invoiceConfig?.serviceInvoiceNo || "-",
							item.invoiceConfig?.serviceResponse?.response?.data?.Irn || "-",
							item.invoiceConfig?.serviceInvoice || "-",
							item.invoiceConfig?.serviceFare?.base || "-",
							item.invoiceConfig?.serviceFare?.slab || "-",
							item.invoiceConfig?.serviceFare?.oth || "-",
							item.invoiceConfig?.serviceFare?.sGst || "-",
							item.invoiceConfig?.serviceFare?.cGst || "-",
							item.invoiceConfig?.serviceFare?.iGst || "-",
							item.invoiceConfig?.serviceFare?.total || "-",
							airlines(item),
							segments(item),
							times(item),
							flightCode(item),
							traveller(item),
							pnrs(item),
							tickets(item),
							`${Moment(STARTDATE.current.value).format("DD_MM_YYYY")}-${Moment(ENDDATE.current.value).format("DD_MM_YYYY")}`,
							cancelledAtDate,
							item?.invoiceConfig?.cancelledByName || '-',
							item?.invoiceConfig?.cancelledByEmail || '-',
							item?.invoiceConfig?.cancelledBy || '-'
						]


						if(PERSONS[CONFIG.email]){
							localItem.push(item.invoiceConfig?.platformMarkup || "-")
						  }
						  data.push(localItem);


					}
				} catch(error){}
				});
				DownloadCsv(
					`flight_agency_bos_${Moment(STARTDATE.current.value).format(
						"DD_MM_YYYY"
					)}_${Moment(ENDDATE.current.value).format("DD_MM_YYYY")}.csv`,
					data
				);
				setLoading(false);
			},
			{
				start: Moment(STARTDATE.current.value).valueOf(),
				end: Moment(ENDDATE.current.value).add(1,'d').valueOf(),
				filterOn: DATETYPE.current.value,
			  }
		);
	};
	return (
		<div style={{ width: "100%" }}>
		  <Navigation
			backUrl={"back"}
			title='Flight Agency Bookings'
			chips={["Meta", "Flight", "Agency"]}
			description='Agency bookings appears below.'
		  />
		  <div
			style={{
			  margin: "0 -4rem",
			  padding: "1rem 4rem",
			  borderBottom: "1px solid #dedede",
			}}
		  >
			<div className='flex horizontally center-vertically'>
			  <SelectInput
				options={[
				  {label: "Created At Date", value: "createdAt"},
				  {label: "Document", value: "document"},
				]}
				ref={DATETYPE}
				type='text'
				label='Date Range Filter'
				hidePlaceholder={true}
				className='mr1 flex-1'
			  />
			  <DateRange
				label="From Date"
				className='flex-1 ml1 mr1 mt1'
				ref={STARTDATE}
				defaultValue={dates.start}
				onChange={()=>{
				  setDates({
					...dates, start: STARTDATE.current.value
				  })
				}}
			  />
			  <DateRange
				label="To Date"
				ref={ENDDATE}
				defaultValue={dates.end}
				className='flex-1 mr1 ml1 mt1'
				onChange={()=>{
				  setDates({
					...dates, end: ENDDATE.current.value
				  })
				}}
			  />
			  <MultiSelectDropdown
				label="Clients"
				placeholder="Select Clients"
				options={clientList}
				selectedOptions={selectedOptions}
				setSelectedOptions={setSelectedOptions}
				className='ml1 mr1 mt1 flex-1'
			  />
			</div>
		  </div>
		  {
			loading
			  ? ( <div className='pd6'>
				<Loader />
				</div>
			  )
			  : (
				<div>
				<div className='border pd6 mt4 flex vertically center'>
				  <img
					alt='fetch'
					src={Graph}
					style={{ width: "5rem", height: "5rem" }}
				  />
				  <div className="flex mt4">
					<RBAC role={ROLES.REPORTS}>
					  <Button onClick={downloadData} className='btn btn-black '>
						Download Data
					  </Button>
					  <Button onClick={downloadDocs} className='btn btn-black ml4'>
						Download Documents
					  </Button>
					</RBAC>
				  </div>
				  <p className='mt2'>Download agency bos data</p>
				</div>
			  </div>
			)
		  }
		</div>
	  );
}
