import React, { useEffect, useState } from "react";
import { fetchData, fetchDataChunked, fetchDataComplementSettlement } from "App/Utils/Utils";
import { Button, DateRange, Loader, Navigation } from "retro/index";
import Moment from "moment/moment";
import { downloadPendingSegmentSettlement } from "./SegmentReport/ReportUtils";
import { get, post } from "App/Network/Axios";
import Graph from "../graph-stats-circle.svg";
import MultiSelectDropdown from "App/Components/MultiSelectDropdown";

export default function FlightSegmentSettlementReport() {
  const [loading, setLoading] = useState(false);
  const [Dates, setDates] = useState({
    page: 0,
    startDate: Moment().add(-31, "d").valueOf(),
    endDate: Moment().valueOf(),
  });
  const [selectedClients, setselectedClients] = useState([]);
  const [clients, setClients] = useState([]);

  useEffect(() => {
    get(
      "/clients/dump",
      (e, r) => {
        if (r) {
          setClients([
            ...r.items.map((client) => {
              return { code: client.code, id: client.id };
            }),
          ]);
        }
      },
      { active: true }
    );
  }, []);

  let segments = (item) => {
    let string = undefined;
    item?.ticket?.segments.forEach((segment) => {
      if (!string) {
        string = `${segment?.departure?.code}-${segment?.arrival?.code}`;
      } else {
        string = `${string} , ${segment?.departure?.code}-${segment?.arrival?.code}`;
      }
    });
    return string;
  };
  const downloadData = () => {
    try {
      setLoading(true);
      let clientsFilter = [];
      if (selectedClients.length > 0) {
        clientsFilter = clients
          .filter((client) => {
            if (selectedClients.includes(client.code)) {
              return client.id;
            }
          })
          .map((client) => client.id);
      }
      get(
        `/meta/flight/all`,
        (e,r) => {
          if(e){
            console.log(e);
            return;
          }
          console.log(r)
          let bookings = r.bookings
          console.log("bookings",bookings);
          downloadPendingSegmentSettlement(bookings, Dates);
          setLoading(false);
        },
        {
          client: clientsFilter.toString(),
          start: Dates.startDate,
          end: Dates.endDate,
        }
      );
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <Navigation
        backUrl={"back"}
        title="Flight Segment Report"
        chips={["All Transfers"]}
        description="All Flight segment settlement can be downloaded here."
      />
      <div
        style={{
          margin: "0 -4rem",
          padding: "2rem 4rem",
          borderBottom: "1px solid #dedede",
        }}
      >
        <div
          style={{
            margin: "0 -4rem",
            padding: "2rem 4rem",
            borderBottom: "1px solid #dedede",
          }}
        >
          <div className="flex horizontally center-vertically">
            <DateRange
              defaultValue={{
                startDate: Moment().add(-31, "d").valueOf(),
                endDate: Moment().valueOf(),
              }}
              onChange={(dates) => {
                setDates({
                  ...dates,
                  page: 0,
                });
              }}
              className="flex-1 mt1"
              label=""
              type="range"
            />
            <MultiSelectDropdown
              className="flex-1 mt1 ml1"
              options={clients.map((client) => client.code)}
              selectedOptions={selectedClients}
              setSelectedOptions={setselectedClients}
              placeholder="Select Clients"
              showSelectedOptins={false}
            />
          </div>
        </div>
      </div>
      <div className="border pd6 mt4 flex vertically center">
        <img
          alt="fetch"
          src={Graph}
          style={{ width: "5rem", height: "5rem" }}
        />
        <h3 className="fw-bold mt4">No Data Found</h3>
        <p>No data found in this configuration</p>
        {loading ? (
          <Loader />
        ) : (
          <Button onClick={downloadData} className="btn btn-black">
            Download Data
          </Button>
        )}
      </div>
    </div>
  );
}
