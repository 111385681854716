import React, { useEffect, useRef, useState } from 'react';
import { Button, Input, Modal, TOAST } from 'retro/index';
import { post } from 'App/Network/Axios';

export default function Index({ onReload, data, transId, setOtherServiceCancellation }) {
  const [loading, setLoading] = useState(false);
  const REMARKS = useRef();

  return (
    <>
      {
        <Modal
          onClose={() => {
            setOtherServiceCancellation(false);
          }}
          title={`Cancel ${data?.service.toLowerCase()} booking`}
          description="Enter details to cancel booking"
        >
          <Input
            type="text"
            ref={REMARKS}
            label="Remarks"
            placeholder="Enter remark"
          />
          <Button
            loading={loading}
            onClick={() => {
              let remarks = REMARKS.current.value;
              setLoading(true);
              post(
                `/meta/cancel/${transId}/${data.service.toLowerCase()}`,
                { remarks },
                (e, r) => {
                  setLoading(false);
                  if (e) {
                    TOAST.handleError(e);
                  } else {
                    TOAST.success('Booking cancelled successfully');
                    setOtherServiceCancellation(false);
                    onReload();
                  }
                }
              );
            }}
            className="btn btn-black mt3"
            margin=""
          >
            Cancel Booking
          </Button>
        </Modal>
      }
    </>
  );
}
