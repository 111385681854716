import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Loader,
  NavDivider,
  Navigation,
  ScrollableDynamicTable,
  TOAST as Alert,
} from 'retro';
import { get } from 'App/Network/Axios';
import OpenIcon from '@atlaskit/icon/glyph/open';
import moment from 'moment';
import ViewRequest from './ViewRequest';
import { DownloadCsv } from 'retro/Utils';
import RBAC from 'App/HOCs/RBAC';
import ROLES from 'App/Constants/Roles';

/**
 * Search Criteria
 * Trip Id
 * Guest Name
 * Guest Mobile
 *
 *
 * Break Up
 * @returns {JSX.Element}
 */
export default function ongoing() {
  let [loading, setLoading] = useState(true);
  const [id, setId] = useState(undefined);
  const [search, setSearch] = useState({
    page: 0,
    sort: 'createdAt',
    order: 'desc',
  });
  const [Data, SetData] = useState({
    total: 0,
    currentPage: 0,
    totalPages: 0,
    items: [],
  });
  const loadPage = useCallback(
    (search) => {
      get(
        `/approvals/approved`,
        (e, r) => {
          if (r) {
            SetData({
              ...r,
              total: r.total ? r.total : Data.total,
              currentPage: r.currentPage,
              totalPages: r.totalPages ? r.totalPages : Data.totalPages,
            });
            setLoading(false);
          } else {
            Alert.handleError(e);
          }
        },
        {
          offset: search.page,
          order: search.order,
          sort: search.sort,
          q: search.q,
        }
      );
    },
    [Data, SetData]
  );
  useEffect(() => {
    loadPage(search);
  }, [search]);
  if (loading) {
    return <Loader />;
  }
  return (
    <div>
      {id && (
        <ViewRequest
          item={id}
          onClose={() => {
            setId(undefined);
            loadPage(search);
          }}
        />
      )}
      <Navigation
        title='Past Approvals'
        description='All past approvals appears below'
      />
      <RBAC role={ROLES.REPORTS}>
        <Button
          onClick={() => {
            setLoading(true);
            get(`/approvals/download/approved`, (e, r) => {
              console.log(e);
              if (r) {
                let data = [];
                let headers = [
                  'Id',
                  'Raised For',
                  'Raised For Email',
                  'Created On Date',
                  'Created On Time',
                  'Type',
                  'Raised By Name',
                  'Raised By Email',
                  'Approved By Name',
                  'Approved By Email',
                ];
                data.push(headers);
                r.items.forEach((item) => {
                  let request = JSON.parse(item.request);
                  let line = [];
                  line.push(item.queueId);
                  line.push(
                    item.raisedFor?.name || request.clientName || request.name
                  );
                  line.push(
                    item.raisedFor?.email ||
                      request.clientEmail ||
                      request.email
                  );
                  line.push(moment(item.createdAt).format('DD-MMM-YYYY'));
                  line.push(moment(item.createdAt).format('HH:mm'));
                  line.push(item.type);
                  line.push(item.raisedBy?.adminName || "-");
                  line.push(item.raisedBy?.adminEmail || "-");
                  line.push(item.verifiedBy?.adminName || "-");
                  line.push(item.verifiedBy?.adminEmail || "-");
                  data.push(line);
                });
                DownloadCsv(
                  `approval_past_${moment().format('DD_MM_YYYY')}}.csv`,
                  data
                );
              }
              setLoading(false);
            });
          }}
          style={{ zIndex: 11 }}
          loading={loading}
          className='btn absolute top-3 right-6 btn-primary'
        >
          <i className='fa fa-download mr1' />
          Download Data
        </Button>
      </RBAC>
      <NavDivider />
      <ScrollableDynamicTable
        setSearch={(search) => {
          setSearch({
            ...search,
            page: 0,
          });
        }}
        search={search}
        tableStyle={{
          borderSpacing: 0,
        }}
        setPage={(page) => {
          setSearch({
            ...search,
            page,
          });
        }}
        data={{
          current: Data.currentPage,
          total: Data.totalPages,
          items: Data.items.map((item) => {
            const click = () => {
              setId(item);
            };
            let request = JSON.parse(item.request);
            return {
              content: item,
              render: [
                {
                  children: (
                    <div>
                      <p className='text fw-bold truncate'>
                        {item.raisedFor?.name ||
                          request.clientName ||
                          request.name}
                      </p>
                      <p className='text-small truncate'>
                        {item.raisedFor?.email ||
                          request.clientEmail ||
                          request.email}
                      </p>
                    </div>
                  ),
                  weight: 1.5,
                },
                {
                  weight: 1.25,
                  children: (
                    <>{moment(item.createdAt).format('DD MMM YYYY HH:mm')}</>
                  ),
                },

                {
                  weight: 1.5,
                  children: <p className='truncate'>{item.type}</p>,
                },
                {
                  weight: 1.5,
                  children: (
                    <div>
                      <p className='truncate'>{item.raisedBy.adminName}</p>
                      <p className='text-small truncate'>{item.raisedBy.adminEmail}</p>
                    </div>
                  ),
                },
                {
                  weight: 1.5,
                  children: (
                    <div>
                      <p className='truncate'>{item.verifiedBy?.adminName || "-"}</p>
                      <p className='text-small truncate'>{item.verifiedBy?.adminEmail || "-"}</p>
                    </div>
                  ),
                },
                {
                  weight: 1,
                  buttons: [
                    {
                      title: 'View',
                      className: 'btn-secondary btn-sm',
                      before: <OpenIcon size='small' />,
                      onClick: click,
                    },
                  ],
                  actions: [
                    {
                      title: 'Quick Actions',
                      actions: [
                        {
                          title: 'View',
                          before: <OpenIcon size='small' />,
                          onClick: click,
                        },
                      ],
                    },
                  ],
                },
              ],
            };
          }),
        }}
        headers={[
          {
            weight: 1.5,
            title: 'Details',
          },
          {
            weight: 1.25,
            title: 'Created At',
            sort: 'createdAt',
          },
          {
            weight: 1.5,
            title: 'Type',
          },
          {
            weight: 1.5,
            title: 'Raised By',
          },
          {
            weight: 1.5,
            title: 'Approved By',
          },
          {
            weight: 1,
            title: 'Actions',
            style: {
              justifyContent: 'end',
            },
          },
        ]}
      />
    </div>
  );
}
