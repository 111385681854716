import React from 'react'
import Perks from './Perks'
import ContractedHotels from './ContractedHotels'
import {Route, Switch} from "react-router-dom";
import {NotFound} from "../../../../retro";

export default function index() {
	return (
		<Switch>
			<Route path="/app/apps/perks" component={Perks}/>
			<Route path="/app/apps/contracted" component={ContractedHotels}/>
			<Route path="/app/apps/guestHouse" component={ContractedHotels}/>
			<NotFound/>
		</Switch>
	)
}
