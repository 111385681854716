import {Route, Switch, useHistory, useLocation} from "react-router-dom";
import {ExpandableSidebar, TOAST as Alert} from "retro/index";
import React, {useEffect, useState} from "react";
import AllQueued from './AllQueued'
import Tatkal from './Tatkal'
import General from './General'
import Assigned from './Assigned'
import {get} from "App/Network/Axios";

export default function index() {
	const History = useHistory();
	const location = useLocation();
	const [pending,setPending] = useState(0)
	const [tatkal,setTatkal] = useState(0)
	const [assigned,setAssigned] = useState(0)
	const [general,setGeneral] = useState(0)
	useEffect(()=>{
		get(`/meta/train/pending`, (e, r) => {
			if (r) {
				setPending(r.total)
			}
		}, {
			offset: 0
		})
		get(`/meta/train/assigned`, (e, r) => {
			if (r) {
				setAssigned(r.total)
			}
		}, {
			offset: 0
		})
		get(`/meta/train/pending/Tatkal`, (e, r) => {
			if (r) {
				setTatkal(r.total)
			} else {
				Alert.handleError(e)
			}
		}, {
			offset: 0
		})
		get(`/meta/train/pending/General`, (e, r) => {
			if (r) {
				setGeneral(r.total)
			} else {
				Alert.handleError(e)
			}
		}, {
			offset: 0
		})
	},[])
	return (
		<div className="flex h-100h w-100">
			<ExpandableSidebar  items={ [
				{
					title:"My Actions",
					actions:[
						{
							title: assigned===0?"Assigned":"Assigned"+" ("+assigned+")",
							active:location.pathname==='/app/apps/meta/trainQueue/assigned',
							onClick:()=>{
								History.push('/app/apps/meta/trainQueue/assigned')
							},
						},
					]
				},
				{
					title:"All Queue",
					actions:[
						{
							title: pending===0?"All Queued":"All Queued"+" ("+pending+")",
							active:location.pathname==='/app/apps/meta/trainQueue',
							onClick:()=>{
								History.push('/app/apps/meta/trainQueue')
							},
						},
						{
							title: general===0?"General Queue":"General Queue"+" ("+general+")",
							active:location.pathname==='/app/apps/meta/trainQueue/general',
							onClick:()=>{
								History.push('/app/apps/meta/trainQueue/general')
							},
						},
						{
							title: tatkal===0?"Tatkal Queue":"Tatkal Queue"+" ("+tatkal+")",
							active:location.pathname==='/app/apps/meta/trainQueue/tatkal',
							onClick:()=>{
								History.push('/app/apps/meta/trainQueue/tatkal')
							},
						}
					]
				}
			]}/>
			<div className="flex-1 overScrollY pd6" style={{
				height:'calc(100vh - 100px)'
			}}>
				<Switch>
					<Route path="/app/apps/meta/trainQueue" component={AllQueued} exact/>
				</Switch>
				<Switch>
					<Route path="/app/apps/meta/trainQueue/tatkal" component={Tatkal} exact/>
				</Switch>
				<Switch>
					<Route path="/app/apps/meta/trainQueue/general" component={General} exact/>
				</Switch>
				<Switch>
					<Route path="/app/apps/meta/trainQueue/assigned" component={Assigned} exact/>
				</Switch>
			</div>
		</div>
	)
}
