import React from 'react';
import NoFileIcon from '../HotelQueue/assests/no_file.png';
import PdfIcon from '../HotelQueue/assests/pdf.png';
import CsvIcon from '../HotelQueue/assests/csv.png';
import XlxxIcon from '../HotelQueue/assests/xlxx.png';
import { UrlListener } from 'retro/index';

export default function FileAvatar({ file, size = 30 }) {
  if (!file) {
    return (
      <img
        src={NoFileIcon}
        alt="No file found"
        className="rounded-md mr2"
        style={{ width: size, height: size, objectFit: 'cover' }}
      />
    );
  }
  if (file.includes('.pdf')) {
    return (
      <img
        onClick={(e) => {
          e.stopPropagation();
          UrlListener.$emit(file);
        }}
        src={PdfIcon}
        alt="PDF file found"
        className="rounded-md mr2 pointer"
        style={{ width: size, height: size, objectFit: 'cover' }}
      />
    );
  }
  if (file.includes('.xlxs')) {
    return (
      <img
        onClick={(e) => {
          e.stopPropagation();
          UrlListener.$emit(file);
        }}
        src={XlxxIcon}
        alt="Excel file found"
        className="rounded-md mr2 pointer"
        style={{ width: size, height: size, objectFit: 'cover' }}
      />
    );
  }
  if (file.includes('.csv')) {
    return (
      <img
        onClick={(e) => {
          e.stopPropagation();
          UrlListener.$emit(file);
        }}
        src={CsvIcon}
        alt="CSV file found"
        className="rounded-md mr2 pointer"
        style={{ width: size, height: size, objectFit: 'cover' }}
      />
    );
  }
  return (
    <div>
      <img
        onClick={(e) => {
          e.stopPropagation();
          UrlListener.$emit(file);
        }}
        src={file}
        alt="File"
        className="border rounded-md mr2 pointer"
        style={{ width: size, height: size, objectFit: 'cover' }}
      />
    </div>
  );
}
