import React, { useState } from 'react';
import { DownloadCsv } from 'retro/Utils';
import { BorderTable, Button } from 'retro/index';

const ShowUploadStatus = ({ csvData, status }) => {
  //   const [rowsArray, setRowsArray] = useState([]);

  const handleClick = (csvData) => {
    let data = [];
    let headers = [];

    if (csvData.length > 0) {
      // Assuming all objects have the same keys, so we use the keys of the first object
      const firstObject = csvData[0];
      headers = Object.keys(firstObject);

      // Add the "Status" and "Remarks" fields to the headers
      headers.push('Status', 'Remarks');
    }

    data.push(headers);

    if (csvData.length > 0) {
      // Iterate through each object in csvData
      csvData.forEach((item, index) => {
        // Flatten the object and push its values into the data array
        const row = Object.values(item);
        row.push(status[index].uploadStatus || '-');
        row.push(status[index].remarkMessage || '-');
        data.push(row);
      });
    }

    DownloadCsv('Bulk_recharge_status_report', data);
  };

  const rows = (csvData, status) => {
    const rowsArray = [];
    // Count the number of "Success" and "Failed" occurrences in the status array
    const successCount = status.filter(
      (item) => item.uploadStatus === 'Success'
    ).length;

    const failedCount = status.filter(
      (item) => item.uploadStatus === 'Failed'
    ).length;
    rowsArray.push([`${csvData.length}`, `${successCount}`, `${failedCount}`]);
    return rowsArray;
  };

  return (
    <div>
      <Button
        onClick={() => handleClick(csvData, status)}
        className="btn btn-secondary rounded-pill text-small mr2 mt4"
      >
        <i className="fa fa-download mr1" />
        View Status
      </Button>
      <div>
        <p className="mt4 ">Summary of file uploaded</p>
        <BorderTable
          className=""
          rows={rows(csvData, status)}
          headers={['Total', 'Success', 'Failed']}
        />
      </div>
    </div>
  );
};

export default ShowUploadStatus;
