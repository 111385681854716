import React, {useCallback, useEffect, useState} from 'react'
import {useParams} from "react-router-dom";
import {get} from "../../../Network/Axios";
import {Loader, ScrollableDynamicTable, TOAST as Alert} from "../../../../retro";
import Moment from "moment";

export default function Settings() {
	const {id} = useParams();
	let [loading, setLoading] = useState(true);
	const [search, setSearch] = useState({
		page: 0,
		sort: 'id',
		order: 'desc'
	})
	const [Data, SetData] = useState({
		total: 0,
		currentPage: 0,
		totalPages: 0,
		items: []
	})
	const loadPage = useCallback((search) => {
		get(`/accounts/account/${id}/ledgers`, (e, r) => {
			if (r) {
				SetData({
					...r
				})
				setLoading(false)
			} else {
				Alert.handleError(e)
			}
		}, {
			offset: search.page,
			sort: search.sort,
			order: search.order,
		})
	}, [SetData])
	useEffect(() => {
		loadPage(search)
	}, [search])
	return (
		<>
			<div className="mt4">
				{
					loading ?
						<Loader/> :
						<div className="relative">
							<ScrollableDynamicTable
								setSearch={(search) => {
									setSearch({
										...search,
										page: 0
									})
								}} search={search}
								tableStyle={{
									borderSpacing: 0
								}}
								setPage={page => {
									setSearch({
										...search,
										page
									})
								}}
								data={{
									current: Data.currentPage,
									total: Data.totalPages,
									items: Data.items.map(item => {
										const click = () => {
											onClick(item)
										}
										return {
											content: item,
											render: [
												{
													children: (
														<div>
															<p className="text">
																#{item.id}
															</p>
															<p className="text-small text-light">
																{item.remarks}
															</p>
														</div>
													),
													weight: 2,
												},
												{
													weight: 1,
													title: Moment(item.date).format("DD MMM YYYY HH-mm a")
												},
												{
													weight: 1,
													title: item.transaction?.transId||"-"
												},
												{
													weight: 2,
													title: item.transaction?.category||"-"
												},
												{
													weight: 1,
													title: item.type
												},
												{
													weight: 1,
													title: `INR ${item.amount}`
												},
												{
													weight: 1,
													title: `INR ${item.balance}`
												},
												{
													weight: 1,
													title: `INR ${Math.abs(item?.startBalance || 0)} ${item?.startBalance>=0?"CR":"DB"}`
												},
												{
													weight: 1,
													title: `INR ${Math.abs(item?.endBalance || 0)} ${item?.endBalance>=0?"CR":"DB"}`
												}
											]
										}
									})
								}} headers={[
								{
									weight: 2,
									title: "Details",
								},
								{
									weight: 1,
									title: "Date",
								},
								{
									weight: 1,
									title: "Transaction Id",
								},
								{
									weight: 2,
									title: "Type",
								},
								{
									weight: 1,
									title: "Reference Type",
								},
								{
									weight: 1,
									title: "Amount",
								},
								{
									weight: 1,
									title: "Balance",
								},
								{
									weight: 1,
									title: "Start Balance",
								},
								{
									weight: 1,
									title: "End Balance",
								}
							]}/>
						</div>
				}
			</div>
		</>
	)
}
