import moment from 'moment';
import React, { useState } from 'react';
import Itenary from './Itenary'
import { Button, Modal } from 'retro/index';
import { post } from 'App/Network/Axios';

function Flight({status,id, createdAt, ticket}) {
    const [modal,setModal] = useState(false)
    return (
        <div className=''>
            <div className=''>
                <Button onClick={()=>{
                    setModal(true)
                }}>
                    Download Voucher
                </Button>
                {
                    modal && <Modal title='Download Voucher' description='Download voucher for particular client' onClose={()=>{
                        setModal(false)
                    }}>
                            {
                                ticket.travellers.map(item=>{
                                    return <>
                                        <div style={{marginBottom:12}} className='flex horizontally center-vertically'>
                                            <div className='flex-1'>
                                                <p className="fw-500">
                                                    {item.name}
                                                </p>
                                            </div>
                                            <p onClick={()=>{
                                                post(`/meta/${id}/masked`,{name:item.name},(e,r)=>{
                                                    if(r){
                                                       window.open(r.file,"_blank")
                                                    }else{
                                                        TOAST.handleError(e)
                                                    }
                                                })
                                            }} style={{
                                                cursor:'pointer',
                                                color : "orange"
                                            }}>
                                                Download
                                            </p>
                                        </div>
                                    </>
                                })
                            }
                    </Modal>
                }
                <div className="pd2 border-bottom">
                    <h2 className="mb2">
                        Flight Voucher
                    </h2>
                    <div className="flex horizontally  center-vertically">
                        <p className="fw-500 flex-1">
                            {ticket.bookingId}
                        </p>
                        <p className="fw-500 flex-1 text-end">
                            {moment(createdAt).format('DD MMM YYYY')}
                        </p>
                    </div>
                </div>
                <div className="pd2 border-bottom">
                    <h3>
                        Itinerary
                    </h3>
                    {
                        ticket.segments.map((segment,index)=>{
                            return <Itenary key={index} {...segment}/>
                        })
                    }
                </div>
                <div className="pd2 border-bottom">
                    <h3 className="mb2">
                       Travellers
                    </h3>
                    {
                        ticket.travellers.map((traveller,index)=>{
                            let segmentStatus = {}
                            if(!traveller.allSequences){
                                traveller.allSequences = []
                            }
                            traveller.allSequences.forEach(item=>{
                                segmentStatus[item.sector] = item.status
                            })
                            return (
                                <>
                                    {
                                        ticket.segments.map(item=>{
                                            let segment = `${item.departure.code}-${item.arrival.code}`
                                            return (
                                                <div className="flex horizontally center-vertically mt2">
                                                    <div className="flex-1">
                                                        <p className="fw-500">
                                                            {traveller.name}
                                                        </p>
                                                        <p className="text-small">
                                                            {segment.replace('-',' to ')}({traveller.pnrs?traveller.pnrs[segment]:"-"})
                                                        </p>
                                                    </div>
                                                    <div className="flex-1 text-end">{status==="REFUNDED"?"REFUNDED":status==="REFUNDING"?"REFUNDING":segmentStatus[segment]?segmentStatus[segment]==="PENDING"?"CONFIRMED":segmentStatus[segment]:"Confirmed"}</div>
                                                </div>
                                            )
                                        })
                                    }
                                </>
                            )
                        })
                    }
                </div>
                <div className="pd2">
                    <h3 className="mb2">
                        ABOUT THIS TRIP
                    </h3>
                    <ul className="text-small">
                        <li>
                            Use your Trip ID for all communication with Cleartrip about this booking
                        </li>
                        <li>
                            Please reach the Airport 3 hours prior to departure for Domestic flights and 4hrs prior to departure for
                            International flights
                        </li>
                        <li>
                            Check-in counters at the Airport close 1 hour prior to departure time
                        </li>
                        <li>
                            You must present valid photo identification in original at the time of check-in
                        </li>
                        <li>
                            You must Web Check-in for your Domestic flight and carry a soft copy or print out of your Boarding
                            pass
                        </li>
                        <li>
                            For hassle free processing, Cancel/Amend your tickets with Cleartrip customer care instead of doing
                            directly with Airline
                        </li>
                        <li>
                            For Guidelines on safe travel, please refer to www.cleartrip.com/travelsafe
                        </li>
                        <li>
                            For safety reasons, dangerous goods must not be carried in either checked or carry-on baggage.
                        </li>
                        <li>
                            Details of items that are prohibited and/or classified as Dangerous Goods may be found on Airline
                            website
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Flight;
