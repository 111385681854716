import React, {useCallback, useEffect, useState} from 'react'
import {Loader, Navigation, ScrollableDynamicTable, SearchBox, TOAST as Alert, Wrapper} from "../../../../../retro";
import {get} from "../../../../Network/Axios";
import OpenIcon from '@atlaskit/icon/glyph/open'
import {useHistory} from "react-router-dom";
import Moment from 'moment'

export default function AllClients({}) {
	let [loading, setLoading] = useState(true);
	const History = useHistory()
	const [search, setSearch] = useState({
		page: 0,
		sort: 'id',
		order: 'desc',
		q: undefined
	})
	const [Data, SetData] = useState({
		total: 0,
		currentPage: 0,
		totalPages: 0,
		items: []
	})
	const loadPage = useCallback((search) => {
		get(`/personal/users`, (e, r) => {
			if (r) {
				SetData({
					...r
				})
				setLoading(false)
			} else {
				Alert.handleError(e)
			}
		}, {
			offset: search.page,
			sort: search.sort,
			order: search.order,
			q: search.q
		})
	}, [SetData])
	useEffect(() => {
		loadPage(search)
	}, [search])
	return (
		<Wrapper id="wrapper-nav">
			<Navigation title="All Users" chips={["Perks","Users"]} description="Details of all users appears below."/>
			{
				loading ?
					<Loader/> :
					<div className="relative mt4">
						<SearchBox onChange={(q) => {
							setSearch({
								...search,
								q: q && q.length > 0 ? q : undefined,
								page: 0
							})
						}} containerClass="" placeholder="Enter your search criteria"/>
						<ScrollableDynamicTable
							setSearch={(search) => {
								setSearch({
									...search,
									page: 0
								})
							}} search={search}
							tableStyle={{
								borderSpacing: 0
							}}
							width={150}
							setPage={page => {
								setSearch({
									...search,
									page
								})
							}}
							data={{
								current: Data.currentPage,
								total: Data.totalPages,
								items: Data.items.map(item => {
									const click = () => {
										onClick(item)
									}
									return {
										content: item,
										render: [
											{
												class: "table-sticky-row-first",
												children: (
													<div>
														<p className="text">
															{item.name}
														</p>
														<p className="text-small text-light">
															{item.email}
														</p>
														<p className="text-small text-light">
															{Moment(item.updatedAt).format("DD MMM YYYY HH:mm a")}
														</p>
													</div>
												),
												weight: 1.5,
											},
											{
												weight: 1,
												title: item.mobile
											},
											{
												weight: 1,
												buttons: [
													{
														title: "View",
														className: 'btn-secondary btn-sm',
														before: (<OpenIcon size="small"/>),
														onClick: click
													}
												],
												actions: [
													{
														title: "Quick Actions",
														actions: [
															{
																title: "View",
																before: (<OpenIcon size="small"/>),
																onClick: click
															}
														]
													}
												]
											}
										]
									}
								})
							}} headers={[
							{
								class: "table-sticky-row-first",
								weight: 1.5,
								title: "Details",
							},
							{
								weight: 1,
								title: "Mobile",
								sort: 'mobile'
							},
							{
								weight: 1,
								title: "Actions",
								style: {
									justifyContent: "end"
								}
							}
						]}/>
					</div>
			}
		</Wrapper>
	)
}
