import React, {useEffect, useState} from 'react'
import {
	NavDivider,
	Navigation,
	ScrollableDynamicTable,
	Seperator,
	TableViewSwitch
} from "../../../../../../retro";
import Moment from 'moment'
import {get} from "../../../../../Network/Axios";
import {useHistory} from "react-router-dom";
import Filter from "retro/Filter";

export default function index() {
	const [Dates, setDates] = useState({
		page:0
	});
	const [VIEW, SETVIEW] = useState("list")
	const HISTORY = useHistory();
	const [Data, SetData] = useState({
		total: 0,
		currentPage: 0,
		totalPages: 0,
		items: []
	})
	useEffect(()=>{
		if(Dates){
			get(`/meta/flight/ongoing`,(e,r)=>{
				if(r){
					SetData({
						...r,
						currentPage: Dates.page ? Dates.page : 0,
						totalPages: r.totalPages > 0 ? r.totalPages : Data.totalPages,
						total: r.total > 0 ? r.total : Data.total
					})
				} else {
					Alert.handleError(e)
				}
			},{
				offset:Dates.page
			})
		}
	},[Dates])
	let items = [];
	Data.items.forEach(item=>{
		items.push({
			content: item,
			render: [
				item.transId,item.provider,item.providerId,Moment(item.createdAt).format("DD MMM YYYY hh:mm a"),Moment(item.updatedAt).format("DD MMM YYYY hh:mm a"),item.client.code,item.client.name,item.paymentType
				,item.amount,{content:(<p className="fw-bold pointer" onClick={()=>{
						HISTORY.push(`/app/apps/meta/transaction/${item.transId}`)
					}}>View</p>)}

			].map(title=>{
				if(title.content){
					return {weight:2,children:title.content}
				}else{
					return {weight:2,title}
				}
			})
		})
	})
	return (
		<div style={{width:'100%'}}>
			<Navigation backUrl={''} title="Flight Ongoing Queue"  description="Flight ongoing file appears below."/>
			<NavDivider/>
			<Filter>
				<TableViewSwitch size="small" selected={VIEW} setSelected={SETVIEW}/>
			</Filter>
			<div>
				<ScrollableDynamicTable
					autoTrigger={false}
					tableStyle={{
						borderSpacing: 0
					}}
					View={VIEW}
					width={150}
					search={Dates}
					setPage={page => {
						setDates({
							...Dates,
							page,
							total:Dates.totalPages
						})
					}}
					data={{
						current: Data.currentPage,
						total: Data.totalPages,
						items,
						cards: Data.items.map(item => ({
							content: item,
							render: (
								<div onClick={()=>{
									HISTORY.push(`/app/apps/meta/transaction/${item.transId}`)
								}} className="retro-card">
									<p className="fw-bold">
										INR {item.amount}
									</p>
									<h3 className="fw-bold">
										{item.transId}
									</h3>
									<p>
										{item.client.name}
									</p>
									<p>
										{item.provider}
									</p>
									<Seperator/>
									<p>
										{Moment(item.createdAt).format("DD MMM YY HH:mm")}
									</p>
									<p>
										{item.status}
									</p>
								</div>
							)
						}))
					}} headers={[
					"Trans Id","Provider","ProviderId","Created At","Last Synced","Client Code","Client Name","Payment Type",
					"Amount","Actions"
				].map((item)=>({
					weight: 2,
					title: item,
				}))}>
				</ScrollableDynamicTable>
			</div>
		</div>
	)
}
