import React from 'react'
import {Route, Switch} from "react-router-dom";
import {NotFound} from "../../../../../retro";
import AllInstalled from './AllInstalled'
import AppDetails from './AppDetails'

export default function index() {
	return (
		<Switch>
			<Route path="/app/client/:id/market" exact component={AllInstalled}/>
			<Route path="/app/client/:id/market/:appId" exact component={AppDetails}/>
			<NotFound/>
		</Switch>
	)
}
