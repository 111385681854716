import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Button,
  ChipsSelect,
  Input,
  Loader,
  NavDivider,
  Navigation,
  ScrollableDynamicTable,
  SidePane,
  TOAST,
  TOAST as Alert,
  SearchBox,
} from "../../../../../retro";
import { get, post } from "../../../../Network/Axios";
import EditIcon from "@atlaskit/icon/glyph/edit";
import DeleteIcon from "@atlaskit/icon/glyph/trash";

export default function index() {
  const NAME = useRef();
  const EMAIL = useRef();
  const MOBILE = useRef();
  const EMPLOYEEID = useRef();
  const [roles, setRoles] = useState([]);
  let [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(undefined);
  const [search, setSearch] = useState({
    page: 0,
    sort: "id",
    order: "desc",
    q: undefined,
  });
  const [Data, SetData] = useState({
    total: 0,
    currentPage: 0,
    totalPages: 0,
    items: [],
  });
  const loadPage = useCallback(
    (search) => {
      get(
        `/admins/inactive`,
        (e, r) => {
          if (r) {
            SetData({
              ...r,
            });
            setLoading(false);
          } else {
            Alert.handleError(e);
          }
        },
        {
          offset: search.page,
          sort: search.sort,
          order: search.order,
          q: search.q,
        }
      );
    },
    [SetData]
  );
  useEffect(() => {
    loadPage(search);
  }, [search]);
  if (loading) {
    return <Loader />;
  }
  const deleteItem = (item) => {
    post(`/admins/${item.id}/activate`, {}, (e, r) => {
      if (r) {
        TOAST.success("User activated successfully");
        loadPage(search);
      } else {
        TOAST.handleError(e);
      }
    });
  };
  function validateEmail(email) {
    var pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return pattern.test(email);
    }

  const updateUser = () => {
    let name = NAME.current.value;
    let email = EMAIL.current.value;
    let mobile = MOBILE.current.value;
    let code = EMPLOYEEID.current.value;
    if (!name || name.length < 2) {
      TOAST.error("Kindly enter a valid name");
      return;
    }
    if (!email) {
      TOAST.error("Kindly enter a valid email");
      return;
    }
    if (!validateEmail(email)) {
      TOAST.error("Kindly enter a valid email");
      return;
    }
    if (!mobile || mobile.length < 10 || mobile.length > 14) {
      TOAST.error("Kindly enter a valid mobile");
      return;
    }
    if (!code || code.length < 2) {
      TOAST.error("Kindly enter a valid code");
      return;
    }
    if (roles.length < 1) {
      TOAST.error("Kindly select atleast one role.");
      return;
    }
    let data = {
      name,
      email,
      mobile,
      code,
      roles,
    };
    post(`/admins/${modal.id}/update`, data, (e, r) => {
      if (r) {
        NAME.current.value = "";
        EMPLOYEEID.current.value = "";
        EMAIL.current.value = "";
        MOBILE.current.value = "";
        TOAST.success("User updated successfully");
        setModal(undefined);
        loadPage(search);
      } else {
        TOAST.handleError(e);
      }
    });
  };
  return (
    <div>
      {modal !== undefined && (
        <SidePane
          onClose={() => {
            setModal(undefined);
          }}
          description='Enter details below to edit admin'
          title='Edit Admin'
        >
          <Input ref={NAME} defaultValue={modal.name} label='Name' />
          <Input ref={EMAIL} defaultValue={modal.email} label='Email Address' />
          <Input
            ref={MOBILE}
            defaultValue={modal.mobile}
            label='Mobile Number'
          />
          <Input
            ref={EMPLOYEEID}
            defaultValue={modal.code}
            label='Employee Id'
          />
          <ChipsSelect
            options={[
              "SUPER",
              "CLIENTS",
              "APPROVAL.ADMIN",
              "APPROVAL.CLIENTS",
              "TRANSACTIONS",
              "SUPPORT",
            ]}
            label='Select roles'
            type='multiple'
            selected={roles}
            onSelect={setRoles}
          />
          <Button onClick={updateUser} className='btn-primary mt5'>
            + Update Admin
          </Button>
        </SidePane>
      )}
      <div className='relative'>
        <div
          className='flex horizontally center-vertically'
          style={{ width: "40%" }}
        >
          <div className='flex-1'>
            <SearchBox
              onChange={(q) => {
                setSearch({
                  ...search,
                  q: q && q.length > 0 ? q : undefined,
                  page: 0,
                });
              }}
              containerClass=''
              style={{
                zIndex: 2,
              }}
              placeholder='Enter your search criteria'
            />
          </div>
        </div>
        <ScrollableDynamicTable
          setSearch={(search) => {
            setSearch({
              ...search,
              page: 0,
            });
          }}
          search={search}
          setPage={(page) => {
            setSearch({
              ...search,
              page,
            });
          }}
          data={{
            current: Data.currentPage,
            total: Data.totalPages,
            items: Data.items.map((item) => {
              return {
                content: item,
                render: [
                  {
                    children: (
                      <div
                        onClick={() => {
                          History.push(`/app/tasks/${item.id}`);
                        }}
                      >
                        <p className='text'>{item.name}</p>
                      </div>
                    ),
                    weight: 2,
                  },
                  {
                    weight: 1,
                    title: item.email,
                  },
                  {
                    weight: 1,
                    buttons: [
                      {
                        title: "Activate",
                        className: "btn-secondary btn-sm",
                        before: <EditIcon size='small' />,
                        onClick: () => {
                          deleteItem(item);
                        },
                      },
                    ],
                    actions: [
                      {
                        title: "Quick Actions",
                        actions: [
                          {
                            title: "Activate",
                            before: <EditIcon size='small' />,
                            onClick: () => {
                              deleteItem(item);
                            },
                          },
                        ],
                      },
                    ],
                  },
                ],
              };
            }),
          }}
          headers={[
            {
              weight: 2,
              title: "Name",
              sort: "name",
            },
            {
              weight: 1,
              title: "Email",
              sort: "email",
            },
            {
              weight: 1,
              title: "Actions",
              style: {
                justifyContent: "end",
              },
            },
          ]}
        />
      </div>
    </div>
  );
}
