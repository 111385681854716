/**
 * Author[Lakshay Jain]
 * Version 1.0.1
 * 7th December | 1.0.1 | Shubham Rawat | Hid the Travel and fee Config option from nav
 * 26th December | 1.0.2 | Shubham Rawat | Added Reports Dashboard
*/
import React, {useEffect, useState} from 'react'
import {
	ExpandableSidebar,
	Loader,
	NavDivider,
	Navigation,
	NotFound,
	PageLoad,
	Pages,
	Tabs,
	Wrapper
} from "../../../../retro";
import {Route, Switch, useHistory, useLocation, useParams} from "react-router-dom";
import Users from './Users'
import VirtualAccounts from "./VirtualAccounts/VirtualAccounts";
import Bulkpe from "./Bulkpe/Bulkpe"
import ApiWallet from "./Wallet/ApiWallet";
import Settings from "./Settings";
import Apps from "./Apps";
import RouteR from './Apis/Route'
import AllApis from "./Apis/allApis";
import {get} from "../../../Network/Axios";
import AllUsers from './Users/Users'
import AllAdmins from './Users/Admins'
import AllOffices from "./Users/Offices"
import AllScanners from './Users/Scanners'
import Modules from './Modules'
import Deployments from './TravelConfig'
import Market from './Market'
import Authentication from './Authentication'
import QueuesIcon from "@atlaskit/icon/glyph/queues";
import PeopleGroupIcon from "@atlaskit/icon/glyph/people-group";
import useFunctionallityHook from "../../../Hooks/useFunctionallityHook";
import Dashboard from './Dashboard';

export default function Index() {
	return (
		<ClientDetails/>
	)
}

function ClientDetails() {
	const {id} = useParams();
	const [module, setmodule] = useState(undefined)
	const [Data, SetData] = useState({
		loading: true,
	})
	const items = [

	]
	const HISTORY = useHistory();
	const Location = useLocation();
	
	const FUNCTIONALITIES = useFunctionallityHook();

	useEffect(() => {
		if (Data.loading) {
			get(`/clients/${id}`, (e, r) => {
				if (r) {
					PageLoad(`All Apis for ${r.name}`)
					SetData({
						...r,
						loading: false
					})
				}
			})
		}
	}, [id, Data.loading])
useEffect(()=>{
	getModule()
},[])

function getModule(){

	get(`/clients/modules/${id}`, (e, r) => {
        if (r) {
			setmodule(r)
        } else {
          setError(true);
        }
      });

}


	if (Data.loading) {
		return (
			<div>
				<Loader/>
			</div>
		)
	}
	let controlRoomItems = [
		{
			title:"All clients",
			icon : (<QueuesIcon size="small"/>),
			active:Location.pathname === '/app/clients',
			onClick:()=>{
				HISTORY.push('/app/clients')
			}
		},
		
		{
			title:"Create New Client",
			icon : (<PeopleGroupIcon size="small"/>),
			active:Location.pathname.includes('/app/clients/new'),
			onClick:()=>{
				HISTORY.push('/app/clients/new')
			}
		},
	]

	if(FUNCTIONALITIES.gstUtility) {
		controlRoomItems.splice(1,0,{
				title: 'Gst Utility',
				icon: <QueuesIcon size='small' />,
				active: Location.pathname === '/app/clients/gst',
				onClick: () => {
					HISTORY.push('/app/clients/gst');
				},
		})
	}
	items.push({
		title:"Control Room",
		actions:controlRoomItems
	})

	

	return (
		<>
			<div className="flex h-100h w-100">
				<ExpandableSidebar items={items}/>
				<Wrapper>
					<Navigation backUrl={''} title={Data.name} chips={['All Clients', Data.code]} description={Data.name}/>
					<Tabs style={{
						marginBottom:'calc(-2rem - 1px)',
					}} className="" tabs={[
						{
							title: "Dashboard",
							href: "/app/client/" + id + "/dashboard",
						},
						{
							title: "All Users",
							href: "/app/client/" + id + "/users",
						},
						{
							title: "Modules",
							href: "/app/client/" + id + "/modules",
						},
						{
							title: "Functionalities",
							href: "/app/client/" + id + "/apis",
						},
						...FUNCTIONALITIES.deposit?[
								{
									title: "Virtual Accounts",
									href: "/app/client/" + id + "/accounts",
								}
							]:[],

						...module?.bulkPe?	[{
								title: "Bulkpe",
								href: "/app/client/" + id + "/Bulkpe",
							}]:[],
						...FUNCTIONALITIES.travelAndFeeConfig?[
							{
							title: "Travel Fee & Configuration",
							href: "/app/client/" + id + "/deployments",
						}]:[],
						...FUNCTIONALITIES.sso?[{
							title: "Saml/Sso",
							href: "/app/client/" + id + "/authentication",
						}]:[],
						{
							title: "Settings",
							href: "/app/client/" + id + "/settings",
						}
					]}/>
					<NavDivider/>
					<div style={{marginTop:-18}}>
						<Pages.Page>
							<Switch>
								<Route component={Dashboard} path="/app/client/:id/dashboard"/>
								<Route component={Users} path="/app/client/:id/users"/>
								<Route exact={true} component={AllApis} path="/app/client/:id/apis"/>
								<Route component={RouteR} path="/app/client/:id/apis"/>
								<Route component={ApiWallet} path="/app/client/:id/api/wallet"/>
								<Route component={Settings} path="/app/client/:id/settings"/>
								<Route component={Apps} path="/app/client/:id/apps"/>
								<Route component={Market} path="/app/client/:id/market"/>
								<Route  render={(props) => (
            <Modules {...props} getModule={getModule} />
          )} path="/app/client/:id/modules"/>
								<Route component={Authentication} path="/app/client/:id/authentication"/>
								<Route component={VirtualAccounts} path="/app/client/:id/accounts"/>
								<Route component={Bulkpe} path="/app/client/:id/Bulkpe"/>
								<Route component={Deployments} path="/app/client/:id/deployments"/>
								<Route component={AllUsers} path="/app/client/:id/employees"/>
								<Route component={AllAdmins} path="/app/client/:id/admins"/>
								<Route component={AllOffices} path="/app/client/:id/offices"/>
								<Route component={AllScanners} path="/app/client/:id/scanners"/>
								<NotFound/>
							</Switch>
						</Pages.Page>
					</div>
				</Wrapper>
			</div>
		</>
	)
}
