import React, {useRef, useState} from 'react'
import {Button, Input, Modal, SelectInput, Seperator, SidePane, TOAST} from "retro/index";
import Moment from "moment/moment";
import {post} from "App/Network/Axios";

export default function Manual({data}) {
	const [flightManual, setFlightManual] = useState(undefined)
	const [loading, setLoading] = useState(false)
	let TYPE = useRef();
	let REFERENCE = useRef();
	let REMARKS = useRef();
	let AMOUNT = useRef();
	let CATEGORY = useRef();
	const [selected, setSelected] = useState([])
	let EMAIL = useRef();
	let DATE = useRef();
	const debitFlightMoney = () => {
		try {
			let type = TYPE.current.value;
			if (!type || type.length < 2) {
				TOAST.error("Enter a valid type")
				return
			}
			let reference = REFERENCE.current.value;
			if (!reference || reference.length < 2) {
				TOAST.error("Enter a valid reference number")
				return
			}
			let remarks = REMARKS.current.value;
			if (!remarks) {
				TOAST.error("Enter a valid remarks")
				return
			}
			let category = CATEGORY.current.value;
			if (!category || category.length < 2) {
				TOAST.error("Enter a valid category")
				return
			}
			let email = EMAIL.current.value;
			if (!email || email.length < 2) {
				TOAST.error("Enter a valid email")
				return
			}
			if (selected.length === 0) {
				TOAST.error("Select atleast one traveller")
				return;
			}
			let amount = AMOUNT.current.value;
			if (!amount || amount.length < 2) {
				TOAST.error("Enter a valid amount")
				return
			}
			amount = parseInt(amount, 10)
			if (amount < 10) {
				TOAST.error("Enter a valid amount")
				return
			}
			let date = DATE.current.value;
			if (!date) {
				TOAST.error("Enter a valid date")
				return
			}
			date = Moment(date, 'YYYY-MM-DD').format("DD-MM-YYYY")
			setLoading(true)
			let mData = {
				reference,
				date,
				category,
				amount,
				remarks,
				email,
				type,
				clientCode: data.clientCode,
				travellers: selected,
				tripId: data.transId
			}
			post(`/meta/manual/flight`, mData, (e, r) => {
				setLoading(false)
				if (r) {
					alert("Debit added successfully!")
					setFlightManual(false)
				} else {
					TOAST.handleError(e)
				}
			})
		} catch (e) {
			console.log(e)
		}
	}
	return (
		<>
			{
				flightManual && <SidePane actions={
					<Button loading={loading} onClick={debitFlightMoney} className="btn btn-black" margin="mt4">
						Debit Money
					</Button>
				} title="Manual Debit" blank={true} onClose={() => {
					setFlightManual(undefined)
				}}>
					<div className="pd4">
						<div className="flex horizontally center-vertically">
							<div className="flex-1 mr2">
								<SelectInput options={[
									{
										label: "FARE_DIFFERENCE",
										value: "FARE_DIFFERENCE"
									},
									{
										label: "FLIGHT_AMENDMENT",
										value: "FLIGHT_AMENDMENT"
									},
									{
										label: "FLIGHT_ADD_ON",
										value: "FLIGHT_ADD_ON"
									}
								]} ref={CATEGORY} type="text" placeholder="Enter category" label="Category"/>
							</div>
							<div className="flex-1 ml2">
								<SelectInput options={[
									{
										label: "MEAL",
										value: "MEAL"
									},
									{
										label: "SEAT",
										value: "SEAT"
									},
									{
										label: "BAGGAGE",
										value: "BAGGAGE"
									},
									{
										label: "DATE_CHANGE",
										value: "DATE_CHANGE"
									},
									{
										label: "OTHER",
										value: "OTHER"
									}
								]} ref={TYPE} type="text" placeholder="Enter type" label="Type"/>
							</div>
						</div>
						<div className="flex horizontally center-vertically">
							<div className="flex-1 mr2">
								<Input ref={DATE} type="date" placeholder="Select Add On Date" label="Date"/>
							</div>
							<div className="flex-1 ml2">
								<Input defaultValue={data.attrs.bookedByEmail} ref={EMAIL} type="text"
									   placeholder="Enter email address for who asked these changes" label="Email Address"/>
							</div>
						</div>
						<div className="flex horizontally center-vertically">
							<div className="flex-1 mr2">
								<Input ref={REFERENCE} type="text" placeholder="Enter supplier reference number"
									   label="Supplier Reference Number"/>
							</div>
							<div className="flex-1 ml2">
								<Input ref={AMOUNT} type="text" placeholder="Enter amount" label="Amount"/>
							</div>
						</div>
						<Input ref={REMARKS} type="text" placeholder="Enter Remarks" label="Remarks"/>
						<Seperator/>
						<p className="mb2">
							Travellers:
						</p>
						{
							data.ticket.travellers.map(item => {
								return (
									<>
										{
											Object.keys(item.pnrs).map(pnr => {
												console.log(selected)
												let isSelected = [...selected].filter(i => i.name === item.name && i.sector === pnr)
												return <div className="horizontally flex center-vertically">
													<div onClick={() => {
														let mSelected = [...selected].filter(i => {
															return !(i.name === item.name && i.sector === pnr)
														});
														if (!isSelected.length) {
															mSelected.push({
																sector: pnr,
																name: item.name
															})
														}
														setSelected(mSelected)
													}} style={{
														width: 14,
														cursor: 'pointer',
														height: 14,
														border: '1px solid #dedede',
														background: isSelected.length ? "#6367FA" : "#FFFFFF"
													}}/>
													<div className="ml1">
														<p>
															{item.name}
														</p>
														<p className="text-small">
															{pnr}
														</p>
													</div>
												</div>
											})
										}
									</>
								)
							})
						}
					</div>
				</SidePane>
			}
			{
				data.service === "DOMESTICFLIGHT" || data.service === "DOMESTICROUNDFLIGHT" ? <Button onClick={() => {
					setFlightManual(data)
				}} className="btn btn-primary" margin="mb2 ml2">
					Manual Debit
				</Button> : undefined
			}
		</>
	)
}
