/**
 * Author[Lakshay Jain]
 * Version 1.0.1
 * 7th December | 1.0.1 | Shubham Rawat | changed the password policy text
*/
import React, {useRef} from 'react';
import {Button, Grids, Headings, Input, Texts, TOAST} from 'retro';
import {post} from 'App/Network/Axios';
import { hasWhiteSpace } from 'App/Utils/WhiteSpaceCheck';

function Password() {

	let PASS = useRef(), OLD = useRef(), CONFIRM = useRef();

	const changePassword = (params) => {
		let password = PASS.current.value;
		let old = OLD.current.value;
		let confirm = CONFIRM.current.value;

		if(!old){
			TOAST.error('Enter Old Password')
			return
		}
		if(!password){
			TOAST.error('Enter Password')
			return
		}
		if(!confirm){
			TOAST.error('Enter Confirm Password')
			return
		}
		if(password!==confirm){
			TOAST.error('Password do not match')
			return
		}

		if(hasWhiteSpace(password)) {
			TOAST.error('Password cannot contain spaces')
            return
		}

		post('config/password',{old,new:confirm},(e,r)=>{
			if(r){
				TOAST.success('Password Changed')
				PASS.current.value = ''
				CONFIRM.current.value = ''
				window.location.reload()
			}else if(e){
				TOAST.handleError(e)
			}
		})

	}

    return (
        <>
        <Grids.Row>
            <Grids.Column grid={{mobile:12, medium:6, large: 6}}>
                <div className='bg-white rounded-md pd4 border mt3'>
                    <Headings.Regular>Change Password</Headings.Regular>
                    <Texts.Regular>You can change your account password here</Texts.Regular>
                    <Input morph={true} type='password' ref={OLD} label='Old Password' className="mt2" placeholder='Enter Password'/>
					<Input morph={true} type='password' ref={PASS} label='New Password' placeholder='New Password'/>
					<Input morph={true} type='password' ref={CONFIRM} label='Confirm Password' placeholder='Confirm Password'/>
					<p className="text-small primary-color mt2">
					Password Policy: Password length should be minimum 8 characters with One upper case and one lower case and 1 special character and at least one number(0-9).
					</p>
					<Button onClick={changePassword} className='btn-black mt4 mr1'>Change Password</Button>
                </div>
            </Grids.Column>
        </Grids.Row>
        </>
    );
}

export default Password;
