import ROLES from 'App/Constants/Roles';
import useConfigHook from './useConfigHook';

const NONE = 'NONE';
export default function useRoleHook(role = NONE) {
  const config = useConfigHook();
  let newRole = role;

  newRole = newRole.toUpperCase();
  if (newRole === NONE) {
    return true;
  }

  if (config.roles.includes(ROLES.SUPER) || config.roles.includes(newRole)) {
    return true;
  }
  return false;
}
