import {axios} from "./Axios";
import qs from 'qs'
import {parseJson} from "../Utils/Utils";
import * as Crypto from "../Utils/Crypto";

class Wrapper{

	getUrl = () => {
		if(window.location.href.includes('cockpit-qa') || window.location.href.includes('cockpit-stage') || window.location.href.includes('localhost')) {
			return 'https://provider-qa.dice.tech/admin/'
		}

		if(window.location.href.includes('superadmin.yesleap.in')) {
			return 'https://provider.yesleap.in/admin/'
		}

		if(window.location.href.includes('admin.yespayleaf.in')) {
			return 'https://provider.yespayleaf.in/admin/'
		}

		return "https://provider.dice.tech/admin/"
	}

	Cancellable=(promise, mCancel = false)=>{
		let hasCompleted = false;
		const wrappedPromise = new Promise((resolve, reject) => {
			promise.then(
				val => {
					if (hasCompleted) {
						reject({ isCanceled: true })
					} else {
						hasCompleted = true;
						resolve(val)
					}
				},
				error => {
					if (hasCompleted) {
						reject({ isCanceled: true })
					} else {
						reject(error);
					}
				}
			);
		});
		return {
			promise: wrappedPromise,
			cancel() {
				hasCompleted = true;
				if (mCancel) {
					mCancel()
				}
			},
			isCancellable() {
				return !hasCompleted;
			}
		};
	};



	get=(url, cb, data = {})=>{
		if(url.startsWith("/")){
			url = url.substr(1,url.length)
		}
		let self = this;
		let cancel = false;
		let cancelToken = new axios.CancelToken(function executor(c) {
			cancel = c;
		});
		let cancellable = self.Cancellable(
			axios.get(`${this.getUrl()}${url}`, {
				params: data,
				paramsSerializer: params => {
					return qs.stringify(params, { arrayFormat: 'repeat' })
				},
				cancelToken
			}), cancel
		);
		cancellable.promise.then(res => {
			if (url.includes(".csv")) {
				cb(null, parseJson(res.data))
			} else {
				cb(null, res.data)
			}
		}).catch(err => {
			if (err && !err.isCanceled) {
				cb(err)
			}
		});
		return cancellable;
	}


	delete = (url, cb,data={}) => {
		if(url.startsWith("/")){
			url = url.substr(1,url.length)
		}
		let self = this;
		let cancellable = self.Cancellable(
			axios.delete(`${self.getUrl()}${url}`, {
				data
			})
		);
		cancellable.promise.then(res => {
			cb(null, res.data)
		}).catch(err => {
			if (err && !err.isCanceled) {
				cb(err)
			}
		});
		return cancellable;
	}

	post=(url, data, cb)=>{
		if(url.startsWith("/")){
			url = url.substr(1,url.length)
		}
		let self = this;
		let cancellable = null;
		if(data.baseUrl){
			let baseUrl = data.baseUrl;
			delete data.baseUrl;
			cancellable = self.Cancellable(
				axios.post(`${baseUrl}admin/${url}`, data, {
					headers: {
						'Content-Type': 'application/json',
					}
				})
			);
		}else{
			cancellable = self.Cancellable(
				axios.post(`${self.getUrl()}${url}`, data, {
					headers: {
						'Content-Type': 'application/json',
					}
				})
			);
		}
		cancellable.promise.then(res => {
			cb(null, res.data)
		}).catch(err => {
			console.log(err)
			if (err && !err.isCanceled) {
				cb(err)
			}
		});
		return cancellable;
	}


	patch = (url, data, cb) => {
		if(url.startsWith("/")){
			url = url.substr(1,url.length)
		}
		let self = this;
		self.pushRequest(url, data, "patch");
		let cancellable = self.Cancellable(
			axios.patch(`${this.getUrl()}${url}`, data)
		);
		cancellable.promise.then(res => {
			cb(null, res.data)
		}).catch(err => {
			if (err && !err.isCanceled) {
				cb(err)
			}
		});
		return cancellable;
	}



}

const getBaseUrl = () => {
	return "https://provider-qa.dice.tech/admin";
}



const uploadUrl = "https://ooo-upload.cleartrip.com/dice/super/upload";

const getUploadUrl = () => {
	return uploadUrl;
}

const getToken = () => {
	const token = Crypto.get('token');
	return token;
}

export {
	Wrapper,getBaseUrl,getToken,uploadUrl,getUploadUrl
}
