import React, {useCallback, useEffect, useState} from 'react'
import {
	Loader,
	Navigation,
	ScrollableDynamicTable,
	SearchBox,
	TOAST as Alert,
	TOAST,
	Wrapper
} from "../../../../../retro";
import {get} from "../../../../Network/Axios";
import OpenIcon from '@atlaskit/icon/glyph/open'
import {useHistory} from "react-router-dom";
import Moment from 'moment'
import CopyIcon from "@atlaskit/icon/glyph/copy";

export default function AllClients({}) {
	let [loading, setLoading] = useState(true);
	const History = useHistory()
	const [search, setSearch] = useState({
		page: 0,
		sort: 'id',
		order: 'desc',
		q: undefined
	})
	const [Data, SetData] = useState({
		total: 0,
		currentPage: 0,
		totalPages: 0,
		items: []
	})
	const loadPage = useCallback((search) => {
		get(`/personal/bookings`, (e, r) => {
			if (r) {
				SetData({
					...r
				})
				setLoading(false)
			} else {
				Alert.handleError(e)
			}
		}, {
			offset: search.page,
			sort: search.sort,
			order: search.order,
			q: search.q
		})
	}, [SetData])
	useEffect(() => {
		loadPage(search)
	}, [search])
	const onClick = item => {
		History.push(`/app/apps/perks/booking/${item.transId}`)
	}
	return (
		<Wrapper  className="w-sidebar-minus" id="wrapper-nav">
			<Navigation title="All Bookings" chips={["Perks","Bookings"]} description="Details of all bookings appears below."/>
			<div>
				{
					loading ?
						<Loader/> :
						<div className="relative mt4">
							<SearchBox onChange={(q) => {
								setSearch({
									...search,
									q: q && q.length > 0 ? q : undefined,
									page: 0
								})
							}} containerClass="" style={{
								zIndex: 2
							}} placeholder="Enter your search criteria"/>
							<ScrollableDynamicTable
								tableStyle={{
									borderSpacing: 0
								}}
								width={150}
								setSearch={(search) => {
									setSearch({
										...search,
										page: 0
									})
								}} search={search}
								setPage={page => {
									setSearch({
										...search,
										page
									})
								}}
								data={{
									current: Data.currentPage,
									total: Data.totalPages,
									items: Data.items.map(item => {
										const click = () => {
											onClick(item)
										}
										return {
											content: item,
											render: [
												{
													class: "table-sticky-row-first",
													children: (
														<div>
															<p className="text">
																{item.transId}
															</p>
															{item.paymentId?<p className="text-small text-light flex horizontally center-vertically">
																{item.paymentId}
																<span className="ml1" onClick={()=>{
																	navigator.clipboard.writeText(item.paymentId).then(function() {
																		TOAST.success("Copy Completed")
																	}, function(err) {
																		TOAST.error("Could not copy text")
																	});
																}}>
																<CopyIcon size="small"/>
															</span>
															</p>:undefined}
															<p className="text-small text-light">
																{Moment(item.updatedAt).format("DD MMM YYYY HH:mm a")}
															</p>
														</div>
													),
													weight: 1.6,
												},
												{
													children: (
														<div>
															<p className="text">
																{item.user.name}
															</p>
															<p className="text-small text-light">
																{item.user.email}
															</p>
														</div>
													),
													weight: 2,
												},
												{
													weight: 1,
													title: item.status
												},
												{
													weight: 1,
													title: item.amount
												},
												{
													weight: 1.5,
													title: item.category
												},
												{
													weight: 1,
													actions: [
														{
															title: "Quick Actions",
															actions: [
																{
																	title: "View",
																	before: (<OpenIcon size="small"/>),
																	onClick: click
																}
															]
														}
													]
												}
											]
										}
									})
								}} headers={[
								{
									class: "table-sticky-row-first",
									weight: 1.6,
									title: "Details",
								},
								{
									weight: 2,
									title: "User",
								},
								{
									weight: 2,
									title: "Status",
								},
								{
									weight: 1,
									title: "Amount",
									sort: 'amount'
								},
								{
									weight: 1.5,
									title: "Category",
									sort: 'category'
								},
								{
									weight: 1,
									title: "Actions",
									style: {
										justifyContent: "end"
									}
								}
							]}/>
						</div>
				}
			</div>
		</Wrapper>
	)
}
