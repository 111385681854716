import React, {useCallback, useEffect, useState} from 'react'
import {get} from "../../../../Network/Axios";
import {Loader, ScrollableDynamicTable, TOAST as Alert} from "../../../../../retro";
import {useParams} from "react-router-dom";
import Moment from 'moment'

export default function ApiWallet() {
	let [loading, setLoading] = useState(true);
	const {id} = useParams();
	const [search, setSearch] = useState({
		page: 0,
		sort: 'id',
		order: 'desc'
	})
	const [Data, SetData] = useState({
		total: 0,
		currentPage: 0,
		totalPages: 0,
		items: []
	})
	const loadPage = useCallback((search) => {
		get(`/apis/${id}/ledger`, (e, r) => {
			if (r) {
				SetData({
					...r
				})
				setLoading(false)
			} else {
				Alert.handleError(e)
			}
		}, {
			offset: search.page,
			sort: search.sort,
			order: search.order
		})
	}, [SetData])
	useEffect(() => {
		loadPage(search)
	}, [search])
	return (
		<div>
			{
				loading ?
					<Loader/> :
					<>
						<ScrollableDynamicTable
							setSearch={(search) => {
								setSearch({
									...search,
									page: 0
								})
							}} search={search}
							setPage={page => {
								setSearch({
									...search,
									page
								})
							}}
							data={{
								current: Data.currentPage,
								total: Data.totalPages,
								items: Data.items.map(item => {
									const click = () => {
										onClick(item)
									}
									return {
										content: item,
										render: [
											{
												children: (
													<div>
														<p className="text">
															#{item.id}
														</p>
														<p className="text-small text-light">
															{item.remarks}
														</p>
														<p className="text-small text-light">
															Initiated on {Moment(item.createdAt).format("DD MMM YYYY HH:mm")}
														</p>
													</div>
												),
												weight: 2,
											},
											{
												weight: 1,
												title: item.type
											},
											{
												weight: 1,
												title: item.amount
											},
											{
												weight: 1,
												title: item.balance
											}
										]
									}
								})
							}} headers={[
							{
								weight: 2,
								title: "Details",
							},
							{
								weight: 1,
								title: "Type",
							},
							{
								weight: 1,
								title: "Amount",
							},
							{
								weight: 1,
								title: "Balance",
							}
						]}/>
					</>
			}
		</div>
	)
}
