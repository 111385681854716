import React, {useEffect, useState} from 'react'
import {ChipsSelect} from "../../../../../retro";
import {useParams} from "react-router-dom";
import {get, post} from "../../../../Network/Axios";

export default function ApiLogs() {
	const [selected,setSelected] = useState('OCR-A')
	const {id} = useParams();
	useEffect(()=>{
		get(`/clients/${id}`, (e, r) => {
			if (r) {
				setSelected(!r.invoiceOcr?"OCR-A":"OCR-B")
			}
		})
	},[id])
	let submit = () => {
		post(`/clients/${id}/attrs`,{key:"invoiceOcr",value:"invoiceOcr"}, (e, r) => {
			if (r) {
				get(`/clients/${id}`, (e, r) => {
					if (r) {
						setSelected(!r.invoiceOcr?"OCR-A":"OCR-B")
					}
				})
			}
		})
	}
	return (
		<div id="non-sticky" className="FadeInRight-Effect mt2">
			<div>
				<ChipsSelect type="single" label="Select Type" selected={selected} onSelect={submit} options={['OCR-A','OCR-B']}/>
			</div>
		</div>
	)
}
