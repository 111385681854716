import React, {useRef, useState} from 'react'
import {Button, Input, KeyValue, Modal, TOAST} from "retro/index";
import {post} from "App/Network/Axios";

export default function GstFare({transId,reload,invoice,service,invoiceStatus}) {
    const [gstModal,setGstModal] = useState(false)
    const BASE = useRef(),SLAB = useRef(), GST = useRef(),
        OTHER = useRef(),ANCILLARY = useRef(),TOTAL = useRef();


    if(service.includes("FLIGHT")){
        if(invoice.reseller){
            console.log((invoiceStatus==="QUEUED" || invoiceStatus==="FAILED") && (invoice.irnFare || service==="HOTEL") )
            let slab = invoice?.irnFare?.slab || 0;
            let  base = invoice?.irnFare?.base || 0;
            let gst = ((invoice?.irnFare?.slab || 0)/100) * base;
            gst = parseInt(gst,10)
            let total = invoice?.irnFare?.total
            let oth = total - (base + gst);
            oth = parseInt(oth,10);
            return (
                <div className="retro-card">
                    <h3 className="mb2">
                        Invoice
                    </h3>
                    {
                        !invoice.irnFare && "Not a reseller item."
                    }
                    {
                        invoice.irnFare && <div className="flex flex-wrap">
                            <div className="mr2">
                                <KeyValue title="Base Fare + Non Taxable" value={`${invoice.irnFare.base} base fare + ${invoice.irnFare.oth} other charges`}/>
                                <KeyValue title="Gst Slab" value={invoice.irnFare.slab}/>
                                <KeyValue title="Gst" value={`${invoice.irnFare.cGst} cgst + ${invoice.irnFare.sGst} sgst + ${invoice.irnFare.iGst} igst`}/>
                                <KeyValue title="Total Charges" value={invoice.irnFare.total}/>
                                <KeyValue title="Generation Message" value={invoice?.invoiceResponse?.message||"-"}/>
                            </div>
                        </div>
                    }
                    {
                        invoiceStatus==="COMPLETED" && !invoice.invoiceResponse?.success && <Button onClick={()=>{
                            post(`/meta/${transId}/flight/regenerate`,{},(e,r)=>{
                                if(r){
                                    TOAST.success("Invoice queued successfully")
                                    reload()
                                }
                            })
                        }}>
                            Regenerate
                        </Button>
                    }

                    {
                        (invoiceStatus==="QUEUED" || invoiceStatus==="FAILED") && (invoice.irnFare || service==="HOTEL")  && <Button onClick={()=>{
                            setGstModal(true)
                        }}>
                            Update Gst BreakUp
                        </Button>
                    }

                    {
                        gstModal && <Modal actions={<Button onClick={()=>{
                            let base = parseInt(BASE.current.value,10);
                            let gst = parseInt(GST.current.value,10);
                            let oth = parseInt(OTHER.current.value,10);
                            let total = parseInt(TOTAL.current.value,10);
                            let ancillary = parseInt(ANCILLARY.current.value,10);
                            let slab = parseInt(SLAB.current.value);
                            if(!base){
                                TOAST.error("Enter a valid base charges")
                                return
                            }
                            if(!gst){
                                TOAST.error("Enter a valid gst charges")
                                return
                            }
                            if(!oth){
                                TOAST.error("Enter a valid oth charges")
                                return
                            }
                            if(!total){
                                TOAST.error("Enter a valid total charges")
                                return
                            }
                            if(ancillary<0){
                                TOAST.error("Enter a valid ancillary charges")
                                return
                            }
                            if(!slab){
                                TOAST.error("Enter a valid gst slab")
                                return
                            }
                            if(slab>20){
                                TOAST.error("Enter a valid gst slab")
                                return
                            }
                            let data ={
                                base,
                                oth,
                                gst,
                                slab,
                                ancillary,
                                total
                            }
                            post(`/meta/${transId}/flight/gst/update`,data,(e,r)=>{
                                if(r){
                                    TOAST.success("Invoice queued successfully")
                                    reload()
                                    setGstModal(false)
                                }else{
                                    TOAST.handleError(e)
                                }
                            })
                        }}>
                            Update Gst
                        </Button>} title="Change Gst" blank="true" onClose={()=>{
                            setGstModal(false)
                        }}>
                            <Input defaultValue={base} type="number" ref={BASE} label="Base Charges" placeholder="Enter base charges"/>
                            <Input defaultValue={gst} type="number" ref={GST} label="Gst Charges" placeholder="Enter gst charges"/>
                            <Input defaultValue={oth} type="number" ref={OTHER} label="Other Charges" placeholder="Enter other charges"/>
                            <Input defaultValue={0} type="number" ref={ANCILLARY} label="ANCILLARY Charges" placeholder="Enter ancillary charges"/>
                            <Input defaultValue={slab} type="number" ref={SLAB} label="Gst Slab" placeholder="Enter gst slab"/>
                            <Input defaultValue={total} type="number" ref={TOTAL} label="Total Charges" placeholder="Enter total charges"/>
                        </Modal>
                    }
                </div>
            )
        }
    }
	return (
        <div className="retro-card">
            <h3 className="mb2">
                Invoice
            </h3>
            {
                !invoice.irnFare && "Not a reseller item."
            }
            {
                invoice.irnFare && <div className="flex flex-wrap">
                    <div className="mr2">
                        <KeyValue title="Base Fare + Non Taxable" value={`${invoice.irnFare.base} base fare + ${invoice.irnFare.oth} other charges`}/>
                        <KeyValue title="Gst Slab" value={invoice.irnFare.slab}/>
                        <KeyValue title="Gst" value={`${invoice.irnFare.cGst} cgst + ${invoice.irnFare.sGst} sgst + ${invoice.irnFare.iGst} igst`}/>
                        <KeyValue title="Total Charges" value={invoice.irnFare.total}/>
                        <KeyValue title="Generation Message" value={invoice?.invoiceResponse?.message||"-"}/>
                    </div>
                </div>
            }
            {
                (invoiceStatus==="QUEUED" || invoiceStatus==="FAILED") && (service==="HOTEL")  && <Button onClick={()=>{
                    post(`/meta/${transId}/flight/regenerate`,{},(e,r)=>{
                        if(r){
                            TOAST.success("Invoice queued successfully")
                            reload()
                        }
                    })
                }}>
                    Regenerate Invoice
                </Button>
            }
        </div>
	)
}
