import React, { useEffect, useState } from 'react';
import {
  Button,
  Loader,
  NavDivider,
  Navigation,
  SearchInput,
  TOAST,
} from 'retro/index';
import { get, post } from 'App/Network/Axios';
import { DownloadCsv } from 'retro/Utils';
import useConfigHook from 'App/Hooks/useConfigHook';

export default function Gstins() {
  const [gstins, setGstins] = useState([]);
  const [selected, setSelect] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [allDataLoading, setAllDataLoading] = useState(false);

  const CONFIG = useConfigHook();

  const PERSONS = {
    'saurabh.mhaske@antrepriz.com': true,
    'aditya.vyawhare@antrepriz.com': true,
  };

  // set dropdowns as per the cleint
  useEffect(() => {
    if (selected) {
      setLoading(true);
      setGstins([]);
      if (selected) {
        // get the list of accounts for the client
        // get the list of gsts for the client
        get(
          `/meta/gstins`,
          (e, r) => {
            if (r) {
              setGstins(r.gstins);
              setLoading(false);
            }
          },
          {
            clientCode: selected.code,
          }
        );
      }
    }
  }, [selected]);

  useEffect(() => {}, []);

  const handleDownloadAllGstData = () => {
    setAllDataLoading(true);
    post(`/meta/gstins/all`, {}, (e, r) => {
      if (r) {
        let data = [];
        let headers = [
          'Name',
          'Gstin',
          'State',
          'Entity',
          'Address',
          'Client Code',
        ];
        data.push(headers);
        r?.items?.forEach((item) => {
          item.gstins.forEach((gst) => {
            data.push([
              gst?.name || '-',
              gst?.gstin || '-',
              gst?.state || '-',
              gst?.entity || '-',
              gst?.address || '-',
              item?.clientCode || '-',
            ]);
          });
        });
        DownloadCsv('all_gstins_data', data);
      } else {
        // TOAST.handleError(e);
        console.log('err', e);
      }
      setAllDataLoading(false);
    });
  };

  let mapping = {};
  gstins.forEach((item) => {
    let gsts = mapping[item.entity] || [];
    gsts.push(item);
    mapping[item.entity] = gsts;
  });
  return (
    <div>
      <Navigation
        backUrl={''}
        title="Gstin Utility"
        chips={[]}
        description="All txns pending for settlements are listed below."
      />
      <div className="flex horizontally mt2 center-vertically">
        <SearchInput
          renderResult={(item) => (
            <div>
              <p>{item.name}</p>
              <p>{item.code}</p>
            </div>
          )}
          resultKey="items"
          api="clients"
          morph={true}
          label="Select Client"
          onSelect={setSelect}
          selected={selected}
          className="flex-1"
        />
        {PERSONS[CONFIG.email] && (
          <Button
            loading={allDataLoading}
            className="btn btn-black ml3 mt3"
            onClick={handleDownloadAllGstData}
          >
            Download All
          </Button>
        )}
      </div>
      <NavDivider />
      {loading && <Loader />}
      {!selected && (
        <div className="border pd2">
          Kindly select the client to view all gsts.
        </div>
      )}
      {gstins.length > 0 && (
        <div>
          {Object.keys(mapping).map((item) => {
            return (
              <div>
                <h2 className="mt4">{item}</h2>
                <div className=" mt2 card-container">
                  {mapping[item].map((item) => {
                    return (
                      <div className="retro-card">
                        <p className="text-small">{item.state}</p>
                        <h3>{item.name}</h3>
                        <p>{item.gstin}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
