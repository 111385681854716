import React, {useEffect, useRef, useState} from 'react'
import {Button, Input, Modal, Pages, SearchInput, TOAST} from "../../../../../retro";
import {get, post} from "../../../../Network/Axios";
import {useParams} from "react-router-dom";

export default function ImplementationManager() {
	const [modal,setModal] = useState(false)
    const [selectedManager,setSelectedManager] = useState();
	const [data,setData] = useState()
	const {id} = useParams();
	const load = id => {
		get(`/clients/manager/${id}/implManager`, (e, r) => {
			if (r) {
				setData({
					...r
				})
			}
		})
	}
    const handleDeleteManager = () => {
        post(`/clients/manager/${id}/implManager/remove`, {}, (e, r) => {
          if (r) {
            TOAST.success('Manager Removed');
            setData(undefined);
            load(id);
          }
          if (e) {
            TOAST.handleError(e);
          }
        });
    };
	useEffect(()=>{
		load(id)
	},[id]) 
	return (
		<div>
			<Pages.Title title="KAM Manager" description="KAM manager assigned to this account."/>
			<div className="border mt2 mb2 rounded-sm pd2">
				{
					data?<>
						<p className="fw-bold">
							{data?.name} - {data?.id}
						</p>
						<p className="text-small">
							{data?.email}
						</p>
                        <Button
                            onClick={()=>handleDeleteManager()}
                            margin="mt1 mb1"
                            className="btn-link btn-sm btn-link-danger"
                            >
                            Remove
                        </Button>
					</>:<p>
						No manager assigned.
					</p>
				}
			</div>
			<Button onClick={()=>{
				setModal(true)
			}} className="btn btn-black">
				+ Assign Manager
			</Button>
			{
				modal&&
				<Modal
                    onClose={() => {
                        setModal(false);
                        setSelectedManager(undefined);
                    }}
                    title="Assign KAM Manager"
                    description="Select a manager to assign"
                    style={{
                        height: 500
                    }}
                >
                    <SearchInput
                        renderResult={item => {
                            return (
                                <p style={{ fontSize:14 }}>
                                    {item.name} ({item.email})
                                </p>
                            )
                        }}
                        resultKey="items"
                        api="/admins"
                        morph={false}
                        label="Select KAM Manager"
                        onSelect={admin => {
                            setSelectedManager(admin);
                        }}
                        placeholder="Select a manager"
                    />
                    <Button
                        onClick={() => {
                            if (!selectedManager) {
                                return TOAST.error('Select a manager to assign');
                            }

                            post(`/clients/manager/${id}/implManager`, {adminId:String(selectedManager.id)}, (e, r) => {
                                if (r) {
                                    load(id);
                                    TOAST.success(`${selectedManager.name} is assigned as KAM manager`);

                                    setSelectedManager(undefined);
                                    setModal(false);
                                } else {
                                    Alert.handleError(e);
                                }
                            });
                        }}
                        className='btn-black btn-primary'
                    >
                        Assign
                    </Button>
                </Modal>
			}
		</div>
	)
}
