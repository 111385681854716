import useConfigHook from "./useConfigHook";
import _ from 'lodash'

/**
 * Author[Lakshay Jain]
 * Version 1.0.2
 * 7th December | 1.0.1 | Shubham Rawat | Added configuration for yes bank to show approvals and hide travel and fee config
 * 20th December | 1.0.2 | Shubham Rawat | Added configuration for MMT to show hide reports and travel and fee config
*/

export default function useFunctionallityHook(){
	const CONFIG = useConfigHook();
	let ORIGIN = CONFIG.origin.code;
	let sso = true;
	let api = true;
	let scanner = true;
	let deposit = true;
	let reportTabName = "Reports";
	let jumpServer = false;
	let multiDeposit = true;
	let qr = false;
	let localDa = true;
	let dealCodes = true;
	let spends = true;
	let tags = true;
	let theme = true;
	let domain = "dice.tech";
	let financeDomain = "//finance.dice.tech/"
	let offlineQueues = true;
	let travelAndFeeConfig = true;
	let approvalsRequired = true;
	let showReports = true;
	let gstUtility = true;
	let apiOrigin="https://ooo-api.cleartrip.com"
	if(_.includes(["YESUAT","YES-PROD"],ORIGIN)){
		sso = false;
		api = false;
		multiDeposit = false;
		scanner = false;
		tags = true;
		dealCodes = false;
		theme = false;
		localDa = false;
		domain = "yesleaf.in"
		offlineQueues = false;
		qr = true;
		reportTabName = "MIS"
		travelAndFeeConfig=false;
		approvalsRequired = true;
		gstUtility = false;
	}
	if(_.includes(["CLRTRP"],ORIGIN)){
		scanner = false;
		spends = false;
		domain = "cleartrip.com"
		dealCodes = true;
		reportTabName = "Meta Apis";
		jumpServer = true;
		financeDomain = "//ooo-finance.cleartrip.com/",
		apiOrigin="https://ooo-api.cleartrip.com"
	}
	if(_.includes(["ADANI"],ORIGIN) || _.includes(["ADANI-PROD"],ORIGIN)){
		scanner = false;
		spends = false;
		dealCodes = true
		jumpServer = true;
		domain = "adanionect.com"
		offlineQueues = false;
		approvalsRequired = false;
		financeDomain="//finance.adanionect.com/";
		apiOrigin="https://adl-api.adanionect.com/";
	}
	if(_.includes(["MMT"],ORIGIN)){
		scanner = false;
		deposit = false;
		api = true;
		sso = false;
		jumpServer = true;
		offlineQueues = false;
		spends = false;
		domain = "makemytrip.com"
		travelAndFeeConfig=false;
		showReports=false;
		financeDomain = "//myclaims-finance.makemytrip.com/",
		apiOrigin="https://myclaims-backend.makemytrip.com"


	}
	return {
		sso,
		spends,
		api,
		deposit,
		localDa,
		reportTabName,
		dealCodes,
		jumpServer,
		qr,
		theme,
		financeDomain,
		multiDeposit,
		tags,
		offlineQueues,
		scanner,
		domain,
		travelAndFeeConfig,
		approvalsRequired,
		showReports,
		gstUtility,
		apiOrigin
	}
}
