import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {  Pages } from 'retro';
import ApiLogs from './ApiLogs';
import EmailSettings from './EmailSettings';
import InvoiceSettings from './InvoiceSettings';
import ExpenseSettings from './ExpenseSettings';
import RBAC from 'App/HOCs/RBAC';
import ROLES from 'App/Constants/Roles';

export default function EmailApis({}) {
  const { type } = useParams();
  const [Tab, SetTab] = useState('apis');
  return (
    <RBAC role={ROLES.API} renderNoAccess>
      <div>
        <Pages.Title title={type.toUpperCase()} className='mt2' />
        {Tab === 'apis' && <ApiLogs />}
        {type === 'email' && Tab === 'settings' && <EmailSettings />}
        {type === 'invoice' && Tab === 'settings' && <InvoiceSettings />}
        {type === 'expense' && Tab === 'settings' && <ExpenseSettings />}
      </div>
    </RBAC>
  );
}
