import React, {useEffect, useRef, useState} from 'react'
import {Attachment, Button, DeltaInput, FileInput, Form, Input, NavDivider, Navigation, SearchInput, TOAST, SelectInput, Loader} from "../../../../../../retro";
import {post} from "../../../../../Network/Axios";
import StatesList from './StatesList';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import HotelSearchInput from 'App/Components/Inputs/HotelSearchInput/HotelSearchInput';

export default function index() {
	const [images,setImages] = useState([]);
	// loading State for showing loader while form is getting submitted
	const [loading,setLoading] = useState(false);
	const [Rating,setRating] = useState(1);
	const [Url,setUrl] = useState();
	// adding pageReloading state for making the fields empty after successful submission
	const [pageReloading,SetPageReloading] = useState(false);
	const NAME = useRef();
	const DESCRIPTION = useRef();
	const ADDRESS = useRef();
	const [Citi, setCiti] = useState({country:"india"});
	const LATITUDE = useRef();
	const LONGITUDE = useRef();
	const STATE = useRef();
	const IMAGES = useRef();

	const deleteImage = (image)=>{
		let filteredImages = images.filter((img)=>{
			return img!==image
		})
		setImages(filteredImages);
	}

	const ratingOptions = [
		{
			label: '1',
			value: 1
		},
		{
			label: '2',
			value: 2
		},
		{
			label: '3',
			value: 3
		},
		{
			label: '4',
			value: 4
		},
		{
			label: '5',
			value: 5
		}
	]

	// useEffect to upload multiple images at once, updateFiles in FileInput component with type=="multiple" calls multiple asynchronus upload api
	useEffect(()=>{
		if(Url){
			setImages([...images,Url]);
		}
	},[Url]);	



	if(pageReloading){
		return <><Loader/></>
	}

	return (
		<>
			<Navigation backUrl={''} title="Create Hotel Shell" chips={['Contracted Hotels', "New"]} description="Enter details to link a new hotel."/>
			<NavDivider/>

			<Form className="mt4">
				<Input ref={NAME} className="mb2 mt4" type="text" placeholder="Enter hotel name" label="Enter hotel name"
					   morph={false}/>
				<Input ref={DESCRIPTION} className="mb2 mt4" type="text" placeholder="Enter description of Hotel" label="Hotel Description"
					   morph={false}/>
				<Input ref={ADDRESS} className="mb2 mt4" type="text" placeholder="Enter hotel address" label="Hotel Address"
					   morph={false}/>
				<div style={{marginTop:'.5rem'}}>
                <label>Select City</label>
                <HotelSearchInput 
                recentCities={true}
                hide={true}
                className="mt1"
                api="/meta/cities/search"
                onSelect={(city)=>{
					setCiti(city);
                }}
                selected={Citi}
                placeholder={"Search City"}
                renderResult={(item) => (
                  <div>
                    <p>{item?.name}{item.country==='India' ? `, ${item?.state}` : ''}, {item?.country}</p>
                  </div>
                )}
                 />
                 </div>
				<SelectInput label="Hotel Rating" className="mt2" def onChange={(val)=>{setRating(val)}} options={ratingOptions}/>
				<Input ref={LATITUDE} className="mb2 mt4" type="number" placeholder="Enter latitude of Hotel" label="Latitude of Hotel"
					   morph={false} min={0} max={90}/>
				<Input ref={LONGITUDE} className="mb2 mt4" type="number" placeholder="Enter longitude of Hotel" label="Longitude of Hotel"
					   morph={false} min={0} max={180} />
				<Input label="Hotel State" className="mt2" defaultValue={Citi?.state} disabled={true}  placeholder="Select State" />
				<div className="mt2">
					<FileInput
						label="Add images of hotel"
						extensions = {['.jpg','.jpeg','.png']}
						updateFiles={(url) => {
							setUrl(url);
						}}
						type="multiple"
						ref={IMAGES}
					/>
					{
						images &&
						<div className='flex flex-wrap'>
							{
								images.map((imageUrl,index)=>{
									return (
										<Attachment url={imageUrl} key={index} onDelete={deleteImage} />
									)
								})
							}
						</div>
					}	
				</div>
				
				{loading? <div className='mini-loader mt2'></div>:<Button onClick={()=>{
					let name = NAME.current.value;
					let description = DESCRIPTION.current.value;
					let address = ADDRESS.current.value;
					let city = Citi.name;
					let rating = Rating
					let longitude = LONGITUDE.current.value;
					let latitude = LATITUDE.current.value;
					let state = Citi.state  ;
					if(!name){
						TOAST.error("Kindly enter name")
						return;
					}
					if(!description){
						TOAST.error("Enter hotel description")
						return;
					}
					if(!address){
						TOAST.error("Enter hotel address")
						return;
					}
					if(!city){
						TOAST.error("Enter hotel city")
						return;
					}
					if(!rating){
						TOAST.error("Enter hotel rating")
						return;
					}
					if(!longitude){
						TOAST.error("Enter hotel longitude")
						return;
					}
					if(!latitude){
						TOAST.error("Enter hotel latitude")
						return;
					}
					if(!state || state==="NONE"){
						TOAST.error("Enter hotel state")
						return;
					}
					if(!images || images.length<3){
						TOAST.error("Upload minimum of 3 hotel images")
						return;
					}
					setLoading(true);
					post(`/contracted/newhotel`,{
						name,
						description,
						address,
						city,
						rating:Number(rating),
						longitude:Number(longitude),
						latitude:Number(latitude),
						state:state?.trim().replaceAll(" ","").toUpperCase(),
						images:images
					},(e,r)=>{
						if(r){
							TOAST.success("Hotel shell created successfully!!")
							setLoading(false);
							SetPageReloading(true);
							setCiti({})
							setImages([])
							// after 100 ms of successful submission pageReloading wil be updated to false leading to reload of component
							setTimeout(()=>{
								SetPageReloading(false)
							},100)
						}else{
							TOAST.handleError(e)
							setLoading(false);
						}
					})
				}} className="btn-primary mt2">
					Create Hotel Shell
				</Button>}
			</Form>
		</>
	)
}
