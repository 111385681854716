import { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';

import { DateRange, Loader, ScrollableDynamicTable, TOAST } from 'retro';
import Filter from 'retro/Filter';

import { get } from 'App/Network/Axios';

const RemoteLedger = () => {
	const { id } = useParams();
	const [range, setRange] = useState({
		startDate: moment().add(-28, 'd').valueOf(),
		endDate: moment().add(1, 'd').valueOf()
	});
	const [loading, setLoading] = useState(true);
	const [search, setSearch] = useState({
		page: 0,
		sort: 'id',
		order: 'desc'
	});
	const [ledgers, setLedgers] = useState({
		total: 0,
		currentPage: 0,
		totalPages: 0,
		items: []
	});

	const loadPage = useCallback(
		search => {
			get(
				`/accounts/account/${id}/ledgers.remote`,
				(error, response) => {
					if (error) {
						TOAST.error('No txns found');
					}
					setLedgers({
						items: response.remoteTxns,
						currentPage: parseInt(response.currentPage, 10),
						totalPages: parseInt(response.totalPages, 10),
						total: parseInt(response.totalDocuments, 10)
					});
					setLoading(false);
				},
				{
					offset: search.page,
					startDate: search.startDate,
					endDate: moment().valueOf() < search.endDate ? moment().valueOf() : search.endDate
				}
			);
		},
		[setLedgers]
	);

	useEffect(() => {
		if (range) {
			setSearch({
				...search,
				page: 0,
				startDate: moment(range.startDate).valueOf(),
				endDate: moment(range.endDate).valueOf()
			});
		}
	}, [range, setLedgers]);

	useEffect(() => {
		if (search.startDate) {
			loadPage(search);
		}
	}, [search]);

	return (
		<>
			<div className="mt4">
				{loading ? (
					<div>
						<Loader />
						<div
							style={{
								display: 'none'
							}}
						>
							<DateRange
								defaultValue={{
									startDate: moment().add(-28, 'd').valueOf(),
									endDate: moment().add(1, 'd').valueOf()
								}}
								type="range"
								onChange={setRange}
							/>
						</div>
					</div>
				) : (
					<div className="relative">
						<Filter
							onDateChange={{
								default: range,
								setRange
							}}
						/>
						<ScrollableDynamicTable
							setSearch={search => {
								setSearch({
									...search,
									page: 0
								});
							}}
							search={search}
							tableStyle={{
								borderSpacing: 0
							}}
							setPage={page => {
								setSearch({
									...search,
									page
								});
							}}
							data={{
								current: ledgers.currentPage,
								total: ledgers.totalPages,
								items: ledgers.items.map(ledger => {
									return {
										content: ledger,
										render: [
											{
												children: (
													<div>
														<p title={ledger.transId} className="text text-truncate">
															#{ledger.transId}
														</p>
														<p
															title={ledger.details}
															className="text-small text-light text-truncate"
														>
															{ledger.details}
														</p>
													</div>
												),
												weight: 2
											},
											{
												weight: 1,
												title: moment(ledger.createdAt).format('DD-MMM-YYYY HH:MM a')
											},
											{
												weight: 1,
												title: ledger.financeType
											},
											{
												weight: 1,
												children: (
													<>
														<p className="text-truncate mr1">{ledger.type}</p>
														<p className="text-light mr1">
															{ledger.subType === 'LEAF_BBPS'
																? 'BBPS Payments'
																: ledger.subType}
														</p>
													</>
												)
											},
											{
												weight: 1,
												title: `INR ${ledger.amount}`
											},
											{
												weight: 1,
												title: `INR ${ledger.startBalance}`
											},
											{
												weight: 1,
												title: `INR ${ledger.endBalance}`
											}
										]
									};
								})
							}}
							headers={[
								{
									weight: 2,
									title: 'Details'
								},
								{
									weight: 1,
									title: 'Date'
								},
								{
									weight: 1,
									title: 'Credit/Debit'
								},
								{
									weight: 1,
									title: 'Type'
								},
								{
									weight: 1,
									title: 'Amount'
								},
								{
									weight: 1,
									title: 'Start Balance'
								},
								{
									weight: 1,
									title: 'End Balance'
								}
							]}
						/>
					</div>
				)}
			</div>
		</>
	);
};

export default RemoteLedger;
