import React, { useRef, useState } from 'react';
import {
  Button,
  Input,
  SidePane,
  SelectInput,
  TOAST as Alert,
  TOAST,
} from '../../../../../../retro';
import { post } from '../../../../../Network/Axios';
import { MEALPLAN } from 'App/Pages/App/MetaApis/HotelQueue/Queued/Utils';
import axios from 'axios';

export default function SendToHotel({ item, onRefresh }) {
  const [modal, setModal] = useState(false);
  console.log(item);
  const HOTEL_EMAIL = useRef();
  const MEAL_PLAN = useRef();
  const ROOM_TYPE = useRef();
  const HOTEL_NAME = useRef();
  const OCCUPANCY = useRef();
  const PRICE = useRef();
  const REMARKS = useRef();

  return (
    <div>
      {modal && (
        <SidePane
          onClose={() => {
            setModal(false);
          }}
          title="Send To Hotel"
          description="Enter details to send this request to hotel"
        >
          <Input
            defaultValue={item.hotel.name}
            label="Hotel Name"
            ref={HOTEL_NAME}
            placeholder="Enter name"
          />
          <Input
            label="Hotel Email Address"
            ref={HOTEL_EMAIL}
            allowSpecialCharacters={true}
            placeholder="Enter email address"
          />
          <Input
            label="Room Type"
            ref={ROOM_TYPE}
            placeholder="Enter room type"
          />
          <SelectInput
            hidePlaceholder={true}
            label="Meal Plan"
            ref={MEAL_PLAN}
            placeholder="Select Meal Plan"
            options={MEALPLAN.map((value) => ({
              label: value,
              value,
            }))}
          />
          <Input
            defaultValue={item.guests.length > 1 ? 'Double' : 'Single'}
            label="Occupancy"
            ref={OCCUPANCY}
            placeholder="Enter occupancy"
          />
          <Input
            label="Contracted Price"
            ref={PRICE}
            placeholder="Price Per Night"
          />
          <Input label="Remarks" ref={REMARKS} placeholder="Enter remarks" />
          <Button
            className="btn btn-primary"
            margin="mt4"
            onClick={() => {
              const DATA = {
                to: HOTEL_EMAIL.current.value,
                from: item.admin.adminEmail,
                name: item.admin.adminName,
                subject: ' Booking Confirmation Required || ' + item.requestId,
                content:
                  'Kindly take a look for below Guest Info for upcoming booking.',
                requestedBy: item.admin.adminName,
                requestedByNumber: item.admin.adminEmail,
                amountPerNight: parseInt(PRICE.current.value, 10),
                remark: REMARKS.current.value,
                requestId: item.requestId,
                guests: item.guests,
                hotelDetails: {
                  name: HOTEL_NAME.current.value,
                  city: item.hotel.city,
                  checkInDate: item.searchRequest.dates.in,
                  checkOutDate: item.searchRequest.dates.out,
                  roomType: ROOM_TYPE.current.value,
                  numberOfRooms: item.numberOfRooms,
                  mealPlan: MEAL_PLAN.current.value,
                  occupancy: OCCUPANCY.current.value,
                },
                companyDetails: item.gstDetails
                  ? item.gstDetails
                  : {
                      name: item.client.clientName,
                    },
              };
              if (!DATA.to) {
                TOAST.error('Kindly enter a valid email address');
                return;
              }
              if (!DATA.amountPerNight) {
                TOAST.error('Kindly enter a valid amountPerNight');
                return;
              }
              post(
                `/meta/contracted/${item.queueId}/status`,
                {
                  status: 'CONTACTED',
                  ...DATA.hotelDetails,
                  email: DATA.to,
                  pricePerNight: DATA.amountPerNight,
                },
                (e, r) => {
                  if (r) {
                    onRefresh();
                    axios
                      .post(
                        'https://provider.dice.tech/events/mails/hotelConfirmationRequired',
                        DATA
                      )
                      .then((value) => {
                        TOAST.success('Email sent successfully!!');
                      });
                  } else {
                    Alert.handleError(e);
                  }
                }
              );
            }}
          >
            Send To Hotel
          </Button>
        </SidePane>
      )}
      <Button
        onClick={() => {
          setModal(true);
        }}
        margin=""
        className="btn-sm btn btn-primary"
      >
        Send To Hotel
      </Button>
    </div>
  );
}
