import React from "react";
import {Route, Switch} from "react-router-dom";
import Routes from "./routes";
import Transfers from "./Transfers";
import Recharges from "./Recharges";
import Accounts from "./Accounts";
import Ledger from "./Ledger";
import Settlement from "./Settlement";
import CompletementSettlementReport from "./Reports"
import UploadSetllement from "./UploadSetllement";
import UploadCtSetllement from "./UploadCtSetllement";
import {NotFound} from "../../../../../retro";
import AdaniAgencyBos from "./AgencyBOS";
import Gstins from './Gstins'
import Invoices from "./Invoices";
import FailedInvoices from "./FailedInvoices";
import AmendmentReport from "./AmendmentReport";
import AccountMis from "../All/AccountMis";
import FlightSegmentSettlementReport from "./Reports/CompleteSegmentSettlementReport";


export default function index() {
	return (
		<Switch>
			<Route path='/app/apps/meta/da' exact>
				<Routes/>
			</Route>
			<Route path='/app/apps/meta/da/transfers' exact>
				<Transfers/>
			</Route>
			<Route path='/app/apps/meta/da/recharges' exact>
				<Recharges/>
			</Route>
			<Route path='/app/apps/meta/da/accounts' exact>
				<Accounts/>
			</Route>
			<Route path='/app/apps/meta/da/ledger' exact>
				<Ledger/>
			</Route>
			<Route path='/app/apps/meta/da/settlements' exact>
				<Settlement/>
			</Route>
			<Route path='/app/apps/meta/da/completementSettlements' exact>
				<CompletementSettlementReport/>
			</Route>
			<Route path='/app/apps/meta/da/flightSegment' exact>
				<FlightSegmentSettlementReport/>
			</Route>
			<Route path='/app/apps/meta/da/uploadSetllement' exact>
				<UploadSetllement/>
			</Route>
			<Route path='/app/apps/meta/da/ct/uploadSetllement' exact>
				<UploadCtSetllement/>
			</Route>
			<Route path='/app/apps/meta/da/adaniAgencyBos' exact>
				<AdaniAgencyBos />
			</Route>
			<Route path='/app/apps/meta/da/gstins' exact>
				<Gstins />
			</Route>
			<Route path='/app/apps/meta/da/amendmentReport' exact>
				<AmendmentReport />
			</Route>
			<Route path='/app/apps/meta/da/invoices' exact>
				<Invoices />
			</Route>
			<Route path='/app/apps/meta/da/invoices/failed' exact>
				<FailedInvoices />
			</Route>
			<NotFound/>
		</Switch>
	);
}
