/**
 * Author[Shubham Rawat]
 * Version 1.0.0
 * 26th December | 1.0.0 | Shubham Rawat | created a client specific dashboard
 */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Moment from "moment";
import {
  DateRange,
  Loader,
  Graphs,
  NavDivider,
  Navigation,
  TOAST,
} from "../../../../../retro";
import { get } from "App/Network/Axios";
import Overview, { VisChart } from "../../MetaApis/Dashboard/Overview";
import { calculate } from "./calculate";

export default function Dashboard() {
  if (
    window.origin.includes("yesleap") ||
    window.origin.includes("yespayleaf")
  ) {
    return <YesbankDashboard />;
  }
  return <OtherDashboard />;
}

function OtherDashboard() {
  const [filters, setFilters] = useState({
    startDate: Moment().add(-31, "d").valueOf(),
    endDate: Moment().valueOf(),
    reseller: false,
  });
  const { id } = useParams();
  const [data, setData] = useState({
    activeClients: {},
    visualization: {},
  });
  const [loading, setLoading] = useState(false);

  // get the client data based on dates
  useEffect(() => {
    setLoading(true);
    get(
      "/meta/dashboard",
      (e, r) => {
        if (r) {
          setData({ ...r });
        } else if (e) {
          TOAST.handleError(e);
        }
        setLoading(false);
      },
      {
        start: filters.startDate,
        end: filters.endDate,
        client: id,
        reseller: filters.reseller,
      },
    );
  }, [filters]); // update on change of filters

  // handle reseller-agency switch
  const toggleReseller = () => {
    setFilters({ ...filters, reseller: !filters.reseller });
  };

  return (
    <div>
      <Navigation
        backUrl=""
        title="Reports"
        description="Preview of all the report data"
        children={
          <>
            <div className="flex" style={{ width: "25%" }}>
              <DateRange
                defaultValue={{
                  startDate: filters.startDate,
                  endDate: filters.endDate,
                }}
                onChange={(dates) => {
                  setFilters({
                    ...filters,
                    ...dates,
                  });
                }}
                className="mt2 flex-1"
                type="range"
              />
            </div>
            <div className="flex vertically justify-between h-100 w-50 absolute top-0 right-0 pl2">
              <h4>Active Users</h4>
              <div className="flex w-100">
                <div className="flex vertically flex-1">
                  <div>1 month</div>
                  <div className="bold">453</div>
                </div>
                <div className="flex vertically flex-1">
                  <div>3 months</div>
                  <div className="bold">4530</div>
                </div>
                <div className="flex vertically flex-1">
                  <div>6 months</div>
                  <div className="bold">45300</div>
                </div>
                <div className="flex vertically flex-1">
                  <div>12 months</div>
                  <div className="bold">453000</div>
                </div>
              </div>
            </div>
          </>
        }
      />
      <NavDivider />
      {loading ? (
        <Loader />
      ) : (
        <>
          <Overview data={data.visualization} toggleHandler={toggleReseller} />
          <VisChart data={data.visualization} />
        </>
      )}
    </div>
  );
}

const YesbankDashboard = () => {
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    startDate: Moment().add(-31, "d").valueOf(),
    endDate: Moment().valueOf(),
  });
  const { id } = useParams();
  const [data, setData] = useState({
    tiles: [
      {
        title: "Total Invoices",
        value: "0",
      },
      {
        title: "Total Categories",
        value: "0",
      },
      {
        title: "Total Value",
        value: "0",
      },
      {
        title: "Claimed Value",
        value: "0",
      },
    ],
  });
  // get the client data based on dates
  useEffect(() => {
    setLoading(true);
    get(
      `meta/dashboard/${id}/bbps/data`,
      (e, r) => {
        if (r) {
          setData(
            calculate(
              r.data.map((item) => ({
                ...item,
                service: item.category.toUpperCase(),
                category: item.category.toUpperCase(),
              })),
            ),
          );
        } else if (e) {
          TOAST.handleError(e);
        }
        setLoading(false);
      },
      {
        start: filters.startDate,
        end: filters.endDate,
        client: id,
      },
    );
  }, [filters]); // update on change of filters

  // handle reseller-agency switch
  let { service, serviceHeatMap, office } = data;
  const SERIES = [];
  const DATES = [];
  if (filters && serviceHeatMap) {
    let startDate = filters.endDate;
    let endDate = filters.endDate;

    for (let i = startDate; i < endDate; i = i + 86400000) {
      DATES.push(Moment(i).format("DD-MM-YYYY"));
    }
    Object.keys(serviceHeatMap).forEach((value) => {
      const A = [];
      const itemMap = serviceHeatMap[value];
      DATES.forEach((xValue) => {
        if (itemMap[xValue]) {
          A.push(itemMap[xValue]);
        } else {
          A.push(0);
        }
      });
      SERIES.push({
        name: value,
        data: A,
      });
    });
  }
  return (
    <div className="mt4">
      <DateRange
        defaultValue={{
          startDate: filters.startDate,
          endDate: filters.endDate,
        }}
        onChange={(dates) => {
          setFilters({
            ...filters,
            ...dates,
          });
        }}
        type="range"
      />
      {loading ? (
        <Loader />
      ) : (
        <div className="mt4">
          <Graphs.AnalyticTiles items={data.tiles} title="Overview" />
          <div className="card-container">
            <Graphs.LineGraph
              type="line"
              series={[
                {
                  name: "Amount",
                  data: Object.keys(service).map((item) => {
                    return service[item]?.amount || 0;
                  }),
                },
              ]}
              labels={Object.keys(service)}
              title="Service Spends"
            />
            <Graphs.DonutGraph
              labels={Object.keys(service)}
              series={Object.keys(service).map((item) => {
                return service[item].count || 0;
              })}
              title="Service Plot"
            />
            <Graphs.HeatMap
              labels={DATES}
              series={SERIES}
              title="Service Map"
            />
            <Graphs.TimeseriesGraph
              type="line"
              series={[
                {
                  name: "Amount",
                  data: data.series,
                },
              ]}
              title="Expense"
            />
            <Graphs.WordCloud
              title="Merchant"
              words={Object.keys(data.merchant).map((item) => {
                return {
                  text: item,
                  value: data.merchant[item],
                };
              })}
            />
            <Graphs.ScatterMap
              series={Object.keys(office).map((item) => {
                return {
                  name: item,
                  data: Object.keys(office[item].dates).map((x) => {
                    return [
                      Moment(x, "DD-MM-YYYY").valueOf(),
                      office[item].dates[x],
                    ];
                  }),
                };
              })}
              title="Office Plot"
            />
            <Graphs.LineGraph
              type="line"
              series={[
                {
                  name: "Amount",
                  data: Object.keys(office).map((item) => {
                    return office[item].amount || 0;
                  }),
                },
              ]}
              labels={Object.keys(office)}
              title="Office Spends"
            />
          </div>
        </div>
      )}
    </div>
  );
};
