import React, { useEffect, useRef, useState } from 'react';
import { Button, Input, Modal, TOAST } from 'retro/index';
import { post } from 'App/Network/Axios';

export default function Index({ onReload, data, transId, setCancellationModal }) {
  const [loading, setLoading] = useState(false);
  const REMARKS = useRef();
  const [cancellationData, setCancellationData] = useState({});
  const [fetchedCancelledData, setFetchedCancelletedData] = useState(false);
  useEffect(() => {
    post(`meta/${transId}/sequences/charges/full`, {}, (e, r) => {
      if (r) {
        setFetchedCancelletedData(true);
        setCancellationData(r);
      } else {
        TOAST.handleError(e);
      }
      setLoading(false);
    });
  }, []);

  if (
    data.service === 'DOMESTICFLIGHT' ||
    data.service === 'DOMESTICROUNDFLIGHT'
  ) {
    return (
      <>
        {
          <>
            {cancellationData?.refundAmount === undefined ? (
              <div className="bg-container rounded-md pd2 flex horizontally fw-500 mb2 center-vertically">
                <div
                  style={{
                    height: 30,
                    width: 4,
                    background: '#6367FA',
                    marginRight: 12,
                  }}
                />
                We are fetching cancellation charges for this booking.
              </div>
            ) : cancellationData?.refundAmount > 10 ? (
              <div className="bg-container rounded-md pd2 flex horizontally fw-500 mb2 center-vertically">
                <div
                  style={{
                    height: 30,
                    width: 4,
                    background: '#6367FA',
                    marginRight: 12,
                  }}
                />
                You will get {cancellationData?.refundAmount} refund amount for
                selected booking.
              </div>
            ) : (
              <div className="bg-container rounded-md pd2 flex horizontally fw-500">
                <div
                  style={{
                    height: 30,
                    width: 4,
                    background: '#6367FA',
                    marginRight: 12,
                  }}
                />
                We are unable to fetch refund amount. You can still go ahead
                with cancellation
              </div>
            )}
            {fetchedCancelledData && (
              <>
                <div className="mt2">
                  Here is the breakup of cancellation charges
                </div>
                <div className="mt2">
                  <p className="mb2">
                    <span className="fw-bold">Airline Charges: </span>{' '}
                    {cancellationData?.airlineCharges}
                  </p>
                  <p className="mb2">
                    <span className="fw-bold">Platform Charges: </span>{' '}
                    {cancellationData?.platformCharges}
                  </p>
                  <p className="mb2">
                    <span className="fw-bold">Refund Amount: </span>{' '}
                    {cancellationData?.refundAmount}
                  </p>
                </div>
              </>
            )}
            <Input
              type="text"
              ref={REMARKS}
              label="Remarks"
              placeholder="Enter remarks"
            />
            <Button
              onClick={() => {
                let remarks = REMARKS.current.value;
                if (!remarks) {
                  TOAST.error('Please enter remarks');
                  return;
                }
                setLoading(true);
                post(`/meta/cancel/${transId}/flight`, { remarks }, (e, r) => {
                  setLoading(false);
                  if (e) {
                    TOAST.handleError(e);
                  } else {
                    TOAST.success('Booking cancelled successfully');
                    setCancellationModal(false);
                    onReload();
                  }
                });
              }}
              loading={loading}
              className="btn btn-black mt3"
              margin=""
            >
              Cancel Booking
            </Button>
          </>
        }
      </>
    );
  }
  return (
    <>
      {
        <Modal
          onClose={() => {
            setModal(false);
          }}
          title={`Cancel ${data?.service} Booking`}
          description="Enter details to cancel booking"
        >

          <Input
            type="text"
            ref={REMARKS}
            label="Remarks"
            placeholder="Enter remark"
          />
          <Button
            loading={loading}
            onClick={() => {
              let remarks = REMARKS.current.value;
              setLoading(true);
              post(
                `/meta/cancel/${transId}/${data.service.toLowerCase()}`,
                { remarks },
                (e, r) => {
                  setLoading(false);
                  if (e) {
                    TOAST.handleError(e);
                  } else {
                    TOAST.success('Booking cancelled successfully');
                    setCancellationModal(false);
                    onReload();
                  }
                }
              );
            }}
            className="btn btn-black mt2"
            margin=""
          >
            Cancel Booking
          </Button>
        </Modal>
      }
      <Button
        onClick={() => {
          setModal(true);
        }}
        className="btn btn-black"
        margin="ml2 mb2 "
      >
        Cancel Booking
      </Button>
    </>
  );
}
