import React, {useEffect, useState} from 'react'
import {get} from "../../../Network/Axios";
import {Button, Loader} from "../../../../retro";

export default function Domain() {
	const [mapping,setMapping] = useState({loading:true})
	useEffect(()=>{
		get(`origin/domains`,(e,r)=>{
			if(r){
				setMapping(r.mapping)
			}
		})
	},[])
	if(mapping.loading){
		return (<Loader/>)
	}
	return (
		<div className="card-container mt2">
			<div className="retro-card">
				<h3 className="fw-bold">
					User Platform
				</h3>
				<div className="mt3 mb3">
					No domain mapped
				</div>
				<div className="flex horizontally center-vertically">
					<Button className="btn-sm btn-primary">
						+ Map Domain
					</Button>
				</div>
			</div>
			<div className="retro-card">
				<h3 className="fw-bold">
					Finance Platform
				</h3>
				<div className="mt3 mb3">
					No domain mapped
				</div>
				<div className="flex horizontally center-vertically">
					<Button className="btn-sm btn-primary">
						+ Map Domain
					</Button>
				</div>
			</div>
			<div className="retro-card">
				<h3 className="fw-bold">
					Scanner Platform
				</h3>
				<div className="mt3 mb3">
					No domain mapped
				</div>
				<div className="flex horizontally center-vertically">
					<Button className="btn-sm btn-primary">
						+ Map Domain
					</Button>
				</div>
			</div>
		</div>
	)
}
