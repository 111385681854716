import React, {useState,useEffect} from 'react'
import FilterIcon from './filter.svg'
import {Button, Modal, SearchInput} from "retro/index";

export default function FilterDropDown({onClose,shown=false,choices={},setFilters,filters={},types=[]}){
	const [filterType,setFilterType] = useState(undefined)
	const [selected,setSelected] = useState([])
	useEffect(()=>{
		if(filterType){
			setSelected(filters[filterType.toLowerCase()]||[])
		}
	},[filterType])
	let baseFilters = () => {
		let ft = {};
		Object.keys(filters).forEach(item=>{
			ft[item] = []
		})
		return ft;
	}
	const filter = () => {
		if(filterType){
			let allChoices = choices[filterType.toLowerCase()];

			if (filterType === 'Client') {
				return (
					<Modal
						width={400}
						title={filterType}
						onClose={() => setFilterType(undefined)}
						description="Select an admin to assign to"
						style={{
							height: 600
						}}
						blank={true}
					>
						<SearchInput
							renderResult={item => {
								return (
									<div>
										{item.name} ({item.email})
									</div>
								)
							}}
							resultKey="items"
							api="/clients"
							morph={false}
							label="Select Client"
							onSelect={item => {
								setFilters(filters => ({
									...filters,
									client: [...filters.client, item]
								}))
							}}
							placeholder="Select a client"
						/>
						<Button onClick={()=>{
							setFilterType(undefined);
						}} className="btn btn-black" margin="mt4">
							Apply
						</Button>
					</Modal>
				)
			}

			return (
				<Modal width={400} title={filterType} onClose={()=>{setFilterType(undefined)}} blank={true}>
					{
						allChoices.map(item=>{
							return (
								<div key={item.title} className="flex mt1">
									<input checked={selected.includes(item.title)} name={item.type} onChange={(e) => {
										if (item.type === 'radio') {
											setSelected([item.title]);
											return;
										}

										if(selected.includes(item.title)) {
											let newSelected = [];
											selected.forEach(a => {
												if(a.title !== item.title) {
													newSelected.push(a);
												}
											})
											setSelected(newSelected);
										} else {
											let newSelected = [...selected];
											newSelected.push(item.title);
											setSelected(newSelected);
										}
									}} className="form-check-input mr1" style={{marginTop:4}} type={item.type} />
									<div className="flex flex-1 center-vertically horizontally">
										<div className="flex-1 ml1">
											<p style={{fontSize: 14}}>{item.title}</p>
										</div>
									</div>
								</div>
							)
						})
					}
					<Button onClick={()=>{
						let newFilters = {...filters}
						newFilters[filterType.toLowerCase()] = selected;
						setFilters(newFilters)
						setFilterType(undefined)
						setSelected([])
					}} className="btn btn-black" margin="mt4">
						Apply
					</Button>
				</Modal>
			)
		}
	}
	let allItems = []
	Object.values(filters).map(item => {
		allItems.push(...item)
	})
	return (
		<>
			{filter()}
			{
				shown && <div style={{background:"rgb(250, 251, 251)"}} className="FadeIn-Effect flex horizontally center-vertically mt1 mb4 pr2 pl2 pt1 pb1 rounded-sm">
					<div className="flex-1 flex horizontally center-vertically ">
						<img src={FilterIcon} style={{width:14}} className="mr4"/>
						{
							types.map(item=>{
								return (
									<div onClick={()=>{
										setSelected([])
										setFilterType(item)
									}} className="flex horizontally pointer ml4 btn center-vertically" style={{padding:'6px 4px'}}>
										<p className="fw-500 mr2">
											{item}
										</p>
										<i className="fa fa-chevron-down"/>
									</div>
								)
							})
						}
					</div>
					<i className="fa fa-close pointer" onClick={onClose}/>
				</div>
			}
			{
				allItems.length>0 && <div className="mt2 mb4 flex horizontally center-vertically flex-wrap">
					{
						allItems.map(item=>{
							return (
								<div key={item} className="chip mb0">
									{item?.name ?? item}
								</div>
							)
						})
					}
					<div onClick={()=>{
						setFilters(baseFilters())
					}} className="mt0 pointer">
						<i className="fa fa-close mr1"/>Clear
					</div>
				</div>
			}

		</>
	)
}
