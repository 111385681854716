import { post } from 'App/Network/Axios';
import CsvToJson from 'App/Utils/Csv';
import React, { useEffect, useRef } from 'react';
import { Button, FileInput, Loader, TOAST } from 'retro/index';

const UploadCsv = (props) => {
  let {
    setCsvData,
    csvData,
    setUploading,
    status,
    setStatus,
    search,
    allQueuData,
    uploads,
    setUploads,
    uploading,
    loadPage,
    setShowStatusButton,
  } = props;

  const file = useRef();
  const uploadCsv = (file) => {
    setUploading(true);
    // setBulkUploading(true);
    CsvToJson(
      file,
      (rows) => {
        // Ensure csvData is always an array
        setCsvData(rows);
        setUploading(false);
      },
      (row) => {
        if (row['TransId']) return true;
        setUploading(false);
        return false;
      }
    );
  };

  useEffect(() => {
    // When csvData changes, trigger the recursiveUpload function
    if (csvData.length > 0) {
      recursiveUpload(csvData, 0);
    }
  }, [csvData]);

  const upload = (csvData, cb) => {
    const {
      'Payment Type': paymentType,
      UTR: utr,
      Remarks: remarks,
      TransId: transId,
    } = csvData;

    let data = {
      type: paymentType.trim(),
      utr:utr.trim(),
      remarks:remarks.trim(),
    };

    // looping over all hotel queue data to and compare RI id's from csv and allQueueData
    const queueData = allQueuData.find((data) => data.requestId === transId.trim());
    if (!queueData) {
      TOAST.error('Please Check your RI Id');
      setStatus((prevState) => [
        ...prevState,
        {
          uploadStatus: 'Failed',
          remarkMessage: 'Please Check RI id',
        },
      ]);
      cb('Wrong fields');
    }

    post(`/meta/hotel/queue/${queueData?.queueId}/payment`, data, (e, r) => {
      if (r) {
        TOAST.success('Payment added successfully');
        setStatus((prevState) => [
          ...prevState,

          {
            uploadStatus: 'Success',
            remarkMessage: 'Payment added successfully',
          },
        ]);

        cb('Upload completed');
      } else {
        TOAST.error(e?.data?.message);
        setStatus((prevState) => [
          ...prevState,
          {
            uploadStatus: 'Failed',
            remarkMessage: e?.data?.message,
          },
        ]);
        cb(e.data.message);
      }
    });
  };

  const recursiveUpload = (csvData, i = 0, mState = {}) => {
    if (i >= csvData.length) {
      setUploading(false);
      setUploads({ ...mState });
      setShowStatusButton(true);
      loadPage(search);
    } else {
      const row = csvData[i];
      upload(row, (message) => {
        const key = row['TransId']; // Assuming "FIRST NAME" is unique
        const state = { ...mState };
        state[key] = message;
        setUploads(state);
        recursiveUpload(csvData, i + 1, state);
      });
    }
  };

  return (
    <>
      {uploading ? (
        <Loader />
      ) : (
        <>
          <FileInput
            onSelect={uploadCsv}
            extensions={['.csv']}
            className="display-none"
            ref={file}
          />
          <Button
            onClick={() => {
              file.current.click();
              setShowStatusButton(false);
            }}
            className="btn  btn-secondary text-small rounded-pill mr1"
          >
            <i className="fa fa-upload mr1" />
            Upload Csv
          </Button>
        </>
      )}
    </>
  );
};

export default UploadCsv;
