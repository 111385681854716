import React, {useRef, useState} from 'react'
import {Button, CardTableSwitch, Input, Modal, SelectInput} from "../../../../../../retro";
import {post} from "../../../../../Network/Axios";
import {useParams} from "react-router-dom";


export default function RoomTypes({roomTypes,onAdd}) {
    const [modal,setModal] = useState(false)
	const TITLE = useRef();
	const MAX = useRef();
    const {id} = useParams();
    const MEALINFO = useRef();
    const CANCELLATION = useRef();
	return (
		<div>
            {
                modal&&
                <Modal onClose={()=>{
                    setModal(false)
                }} button="Add Room Type" onSubmit={()=>{
                    let title = TITLE.current.value;
                    let mealInfo = MEALINFO.current.value;
                    let cancellation = CANCELLATION.current.value;
                    if(title && mealInfo && cancellation){
                        post(`/contracted/${id}/types`,{
                            name:title,
                            mealInfo,
                            cancellation
                        },(e,r)=>{
                            onAdd()
							setModal(false)
                        })
                    }
                }} title="Add Room Type" description="Enter details to add a new room type" >
                    <Input type="text" ref={TITLE} label="Title" placeholder="Enter title" morph={true}/>
					<Input type="number" ref={MAX} label="Max Occupancy" placeholder="Enter max occupancy" morph={true}/>
					<SelectInput options={[
                        {
                            value:"ROOMONLY",
                            label:"Room Only"
                        },
                        {
                            value:"BREAKFAST",
                            label:"Break fast"
                        },
                        {
                            value:"HALFBOARD",
                            label:"Half Board"
                        },
                        {
                            value:"FULLBOARD",
                            label:"Full Board"
                        }
                    ]} type="text" ref={MEALINFO} label="Meal Info"  morph={true}/>
                    <SelectInput options={[
                        {
                            value:"NOTREFUNDABLE",
                            label:"Not Refundable"
                        },
                        {
                            value:"REFUNDABLEONEDAY",
                            label:"Refundable if canceled 30 hours before check in time"
                        },
                        {
                            value:"FULLYREFUNDABLE",
                            label:"Fully Refundable before check in time"
                        }
                    ]} type="text" ref={CANCELLATION} label="Cancellation Policy"  morph={true}/>
                </Modal>
            }
            <Button onClick={()=>{
                setModal(true)
            }} className="btn btn-black mt4">
                + Add Room Type
            </Button>
            <CardTableSwitch
                mode={'list'}
                data={roomTypes}
                freeze={true}
                row={row=>[
                    row.name,
					row.mealType,
					<p  style={{width:100,textAlign:'left'}} className="btn-link-danger btn mg0 pd0" onClick={()=>{
						post(`/contracted/${id}/types/remove`,{
							id:row.id,
						},(e,r)=>{
							onAdd()
						})
					}}>
						Delete
					</p>
                ]}
                headers={['Name','Meal Type','Actions']}
                empty='No Data found'
            />
		</div>
	)
}
