import React, {useEffect, useRef, useState} from 'react'
import {get, post} from "../../../Network/Axios";
import {Attachment, Button, Empty, FileInput, Modal, SelectInput, TOAST} from "../../../../retro";

const TYPES = [
	{label:"Platform Css",value:"CSS.Platform"},
	{label:"User Css",value:"CSS.User"},
	{label:"Finance Css",value:"CSS.Finance"},
	{label:"User Image",value:"IMAGE.User"},
	{label:"Finance Image",value:"IMAGE.Finance"},
	{label:"Logo",value:"IMAGE.logo"},
	{label:"Inverse Logo",value:"IMAGE.logo.inverse"},
	{label:"Fav Icon",value:"IMAGE.fav"},
]

export default function Theme() {
	const [assets,setAssets] = useState({
		assets:[]
	})
	const [file,setFile] = useState(undefined)
	const FILE = useRef();
	const TYPE = useRef();
	const load = () => {
		get(`/origin/assets`,(e,r)=>{
			if(r){
				setAssets(r)
			}
		})
	}
	const [update,setUpdate] = useState(undefined)
	const [modal,setModal] = useState(false)
	useEffect(()=>{
		load()
	},[])
	return (
		<div className="relative">
			{
				modal &&
				<Modal title="Add Asset" onClose={()=>{setModal(false)}}>
					<p>
						Select File
					</p>
					<div className="flex mb2 mt2">
						<FileInput extensions={[".css",".png",".jpeg",".jpg",".js"]} updateFiles={(url)=>{
							setFile(url)
						}} label="" ref={FILE}/>
						<Attachment url={file}/>
					</div>
					<SelectInput ref={TYPE} label="Select Type" options={TYPES}/>
					<p onClick={()=>{
						let FILE = file;
						if(!FILE){
							TOAST.error("Select a file")
							return
						}
						if(!TYPE.current.value){
							TOAST.error("Select a type")
							return
						}
						let data = {
							file : FILE,
							type:TYPE.current.value
						}
						post(`/origin/assets`,data,(e,r)=>{
							if(r){
								setModal(false)
								setFile(undefined)
								load()
							}else{
								TOAST.handleError(e)
							}
						})
					}} className="btn mt4 btn-black">
						 Add
					</p>
				</Modal>
			}
			{
				update!==undefined &&
				<Modal title="Add Asset" onClose={()=>{setUpdate(false);setFile(undefined)}}>
					<p>
						Select File
					</p>
					<div className="flex mb2 mt2">
						<FileInput updateFiles={(url)=>{
							setFile(url)
						}} label="" ref={FILE}/>
						<Attachment url={file}/>
					</div>
					<p onClick={()=>{
						let FILE = file;
						if(!FILE){
							TOAST.error("Select a file")
							return
						}
						let data = {
							file : FILE,
							id:update
						}
						post(`/origin/assets/update`,data,(e,r)=>{
							if(r){
								setUpdate(undefined)
								setFile(undefined)
								load()
							}else{
								TOAST.handleError(e)
							}
						})
					}} className="btn mt4 btn-black">
						Update
					</p>
				</Modal>
			}
			<p onClick={()=>{
				setModal(true)
			}} className="btn mt2 btn-black" style={{width:150}}>
				+ Add Asset
			</p>
			{
				assets.assets.length===0&&<div className="pd6">
					<Empty/>
				</div>
			}
			<div className="card-container mt4">
				{
					assets.assets.map(item=>{
						return(
							<div className="retro-card" key={item.url}>
								<p className="fw-bold">
									{item.id}
								</p>
								<p className="text-light">
									Type: {item.type}
								</p>
								<div  className="mt4">
									<Attachment className="border" url={item.url}/>
								</div>
								<div className="flex mt2">
									<Button onClick={()=>{
										setUpdate(item.id)
									}} className="btn-link btn">
										Update File
									</Button>
									<Button onClick={()=>{
										post(`/origin/assets/delete`,{
											id:item.id
										},(e,r)=>{
											if(r){
												setModal(false)
												setFile(undefined)
												load()
											}else{
												TOAST.handleError(e)
											}
										})
									}} className="btn-link-danger btn">
										Delete File
									</Button>
								</div>
							</div>
						)
					})
				}
			</div>
		</div>
	)
}
