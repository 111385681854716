import React, {useEffect, useRef, useState} from 'react'
import {
	Attachment,
	Button,
	FileInput,
	Input,
	KeyValue,
	Loader,
	Modal, SelectInput,
	Seperator,
	SidePane,
	TOAST as Alert,
	TOAST
} from "../../../../../retro";
import {get, post} from "../../../../Network/Axios";
import SectionMessage from "@atlaskit/section-message";
import Moment from "moment";

export default function ViewRequest({id, onClose}) {
	const [data, setData] = useState({
		status: "LOADING"
	})
	const ORIGIN  = useRef();
	const DESTINATION  = useRef();
	const TRAINNAME  = useRef();
	const TRAINNUMBER  = useRef();
	const TRAINPNR  = useRef();
	const TRAINSEAT  = useRef();
	const AMOUNT  = useRef();
	const TYPE  = useRef();
	const [decline, setDecline] = useState(false)
	const REMARKS = useRef()
	const FILE = useRef();
	const [file, setFile] = useState(undefined);
	const load = id => {
		get(`/meta/train/${id}/details`, (e, r) => {
			if (r) {
				setData(r)
			} else {
				Alert.handleError(e)
			}
		})
	}
	useEffect(() => {
		load(id)
	}, [id])
	if (data.status === "LOADING") {
		return (
			<SidePane title="Train Request" description="Details of this train request queue appears below."
					  onClose={onClose}>
				<Loader/>
			</SidePane>)
	}
	return (
		<SidePane preview={<div className="pd4" style={{width: 500}}>
			<h3 className="fw-bold">
				Queue Details
			</h3>
			<div className="border pd4 mt4">
				<h3 className="fw-bold">
					Train Request
				</h3>
				<p className="mb2">
					Details of this train request appears below.
				</p>
				<div className="flex mt4 horizontally center-vertically">
					<KeyValue title="Status" value={data.status}/>
					<KeyValue title="Request Id" value={data.requestId}/>
				</div>
				<div className="flex mt4 horizontally center-vertically">
					<KeyValue title="Client Code" value={data.client?.clientCode}/>
					<KeyValue title="Client Name" value={data.client?.clientName}/>
				</div>
				<Seperator margin={2}/>
				<div className="mt4">
					<KeyValue title="Client Name" value={data.userDetails?.name}/>
					<KeyValue title="Client Gstin" value={data.userDetails?.gstin}/>
				</div>
				<Seperator margin={2}/>
				<div>
					<div className="flex horizontally center-vertically  mt2">
						<KeyValue title="Origin Station" value={data.request.attrs.from || "-"}/>
						<KeyValue title="Destination  Station" value={data.request.attrs.to || "-"}/>
						<KeyValue title="Travel Date" value={data.request.date}/>
					</div>
					<div className="flex horizontally center-vertically mt2">
						<KeyValue title="Origin City" value={data.request.origin}/>
						<KeyValue title="Destination City" value={data.request.destination}/>
						<KeyValue title="Travel Time" value={data.request.attrs.time||"-"}/>
					</div>
					<div className="flex mt2 horizontally center-vertically mt2">
						<KeyValue title="Quota" value={data.request.attrs.quota}/>
						<KeyValue title="Train Number" value={data.request.attrs.train}/>
						<KeyValue title="Coach Type" value={data.request.coach}/>
					</div>
					<div className="flex mt2 horizontally center-vertically mt2">
						<KeyValue title="Berth" value={data.request.attrs.berth||"-"}/>
						<KeyValue title="Notes" value={data.request.attrs.notes}/>
					</div>
				</div>
				<Seperator margin={2}/>
				<div>
					<p className="fw-bold">
						Travellers:
					</p>
					{
						data.guests.map((item, index) => {
							return (
								<>
								<div key={item.email} className="mt2 flex horizontally">
									<p className="fw-bold">
										{index + 1})
									</p>
									<div className="ml1 flex-2">
										<p>
										<span className='fw-bold'>Name:</span> {item.name}
										</p>
										<p className="text-small">
										<span className='fw-bold'>Email:</span> {item.email}
										</p>
										<p className="text-small">
										<span className='fw-bold'>Mobile:</span> {item.mobile}
										</p>
									</div>
									<div className="flex-1">
										{item.gender}({Moment().diff(Moment(item.dob), 'years',false)} yrs old)
									</div>
								</div>
								<div className='flex horizontally ml2'>
								<div className='flex-1'>
									<p className='text-small'>
									<span className='fw-bold'>Office:</span>  {item.office}
									</p>
									<p className='text-small'>
									<span className='fw-bold'>Department:</span> {item.department}
									</p>
									</div>
									<div className='flex-1'>
									<p className='text-small '>
									<span className='fw-bold'>Team:</span> {item.team}
									</p>
									<p className='text-small'>
									<span className='fw-bold'>Policy:</span> {item.policy}
									</p>
									</div>
								</div>
							</>
							)
						})
					}
				</div>
			</div>
		</div>} title="Train Request" description="Details of this train request queue appears below."
				  onClose={onClose}>
			{
				data.status === "QUEUED" && <div className="mt4">
					<div className="mb2">
						<SectionMessage title={data.admin.adminName}>
							<p>
								This ticket is currently handled by {data.admin.adminEmail}
							</p>
							<div className="pt2">
								<p onClick={() => {
									post(`/meta/train/${id}/self`, {}, (e, r) => {
										if (r) {
											load(id)
											TOAST.success("Ticket assigned to you.")
										} else {
											Alert.handleError(e)
										}
									})
								}} className="btn-link btn">
									Assign To Yourself
								</p>
							</div>
						</SectionMessage>
					</div>
					<div className="flex horizontally center-vertically">
						<Input ref={ORIGIN} type="text" label="Origin Station Code" placeholder="Enter origin station code"
							   className="mr1 flex-1"/>
						<Input ref={DESTINATION} type="text" label="Destination Station Code" placeholder="Enter destination station code"
							   className="ml1 flex-1"/>
					</div>
					<div className="flex horizontally center-vertically mt2">
						<Input ref={TRAINNAME} type="text" label="Train Name" placeholder="Enter train name" className="mr1 flex-1"/>
						<Input ref={TRAINNUMBER} type="text" label="Train Number" placeholder="Enter train number" className="ml1 flex-1"/>
					</div>
					<div className="flex horizontally center-vertically mt2">
						<Input ref={TRAINPNR} type="text" label="Train Pnr" placeholder="Enter train pnr" className="mr1 flex-1"/>
						<Input ref={TRAINSEAT} type="text" label="Train Seat No." placeholder="Enter train seat number"
							   className="ml1 flex-1"/>
					</div>
					<div className="flex horizontally center-vertically mt2">
						<Input ref={AMOUNT} type="number" label="Amount" placeholder="Enter Amount" className="mr1 flex-1"/>
					</div>
					<div className="flex horizontally center-vertically mt2">
						<SelectInput options={[{label:"GENERAL",value:"GENERAL"},{label:"AC",value:"AC"},{label:"TATKAL",value:"TATKAL"}]} ref={TYPE}  label="Booking Type" hidePlaceholder={true} className="mr1 flex-1"/>
					</div>
					<div className="flex horizontally center-vertically mt2">
						<div className="flex mb2 mt2">
							<FileInput updateFiles={(url) => {
								setFile(url)
							}} label="" ref={FILE}/>
							<Attachment url={file}/>
						</div>
					</div>
					<div className="flex horizontally center-vertically">
						<Button onClick={()=>{
							let origin = ORIGIN.current.value;
							let destination = DESTINATION.current.value;
							let trainName = TRAINNAME.current.value;
							let trainNumber = TRAINNUMBER.current.value;
							let trainPnr = TRAINPNR.current.value;
							let trainSeat = TRAINSEAT.current.value;
							let type = TYPE.current.value;
							let amount = parseInt(AMOUNT.current.value,10)
							if(!origin){
								TOAST.error("Enter a valid origin")
								return
							}
							if(!destination){
								TOAST.error("Enter a valid destination")
								return
							}
							if(!trainName){
								TOAST.error("Enter a valid train name")
								return
							}
							if(!trainNumber){
								TOAST.error("Enter a valid train number")
								return
							}
							if(!trainPnr){
								TOAST.error("Enter a valid train pnr")
								return
							}
							if(!trainSeat){
								TOAST.error("Enter a valid train seat")
								return
							}
							if(!file){
								TOAST.error("Select a valid file")
								return
							}
							if(amount<100){
								TOAST.error("Enter a valid amount")
								return
							}
							let data = {
								origin,
								destination,
								trainNumber,
								trainName,
								trainPnr,
								trainSeat,
								type,
								amount,
								file
							}
							post(`/meta/train/${id}/order`, data, (e, r) => {
								if (r) {
									TOAST.success("Booking created successfully!")
									onClose()
								} else {
									Alert.handleError(e)
								}
							})
						}} className="mt4 btn-black btn-primary" margin="">
							Confirm Ticket
						</Button>
						<Button onClick={() => {
							setDecline(true)
						}} className="mt4 btn-danger btn-primary" margin="ml2">
							Decline Booking
						</Button>
					</div>
				</div>
			}
			{
				decline && <Modal onClose={() => {
					setDecline(false)
				}} title="Decline Remarks" description="Enter your decline remarks">
					<Input label="Remarks" placeholder="Enter your remarks" ref={REMARKS}/>
					<Button onClick={() => {
						let remarks = REMARKS.current.value;
						if (!remarks) {
							TOAST.error("Enter a valid remarks")
							return
						}
						post(`/meta/train/${id}/decline`, {remarks}, (e, r) => {
							if (r) {
								TOAST.success("Declined successfully!")
								onClose()
							} else {
								Alert.handleError(e)
							}
						})
					}}>
						Cancel Booking
					</Button>
				</Modal>
			}
		</SidePane>
	)
}
