import React, {useState} from 'react'
import {post} from "../../../../../Network/Axios";
import {BorderTable, Button, KeyValue, Loader} from "../../../../../../retro";

export default function Step2({data,onNext}) {
	const  [uploading,setUploading] = useState(false)
	const  [uploads,setUploads] = useState({})
	const  [uploadCompleted,setUploadCompleted] = useState(false)
	const upload = (data,cb) => {
		console.log(data)
		post(`/contracted/contract/bulk`,{
			hotel:data.HotelId,
			email:data.Email,
			roomName:data.RoomName,
			roomMealInfo:data.RoomMealInfo,
			roomCancellation:data.RoomCancellation,
			client:data.ClientCode,
			onePax:parseInt(data.OnePax,10),
			twoPax:parseInt(data.TwoPax,10),
			supplierName:data.SupplierName,
			supplierMobile:data.SupplierMobile,
			supplierEmail:data.SupplierEmail,
		},(e,r)=>{
			if(r){
				cb("Upload completed")
			}else{
				cb(e.data.message)
			}
		})

	}
	const recursiveUpload = (data, i=0,mState={}) => {
		if(i>=data.length){
			setUploading(false)
			setUploads({...mState})
			setUploadCompleted(true)
		}else{
			let row = data[i]
			upload(row,message=>{
				let key = row.HotelId
				let state = {...mState}
				state[key] = message;
				setUploads(state)
				recursiveUpload(data,i+1,state)
			})
		}
	}
	const uploadSheet = () => {
		setUploading(true)
		recursiveUpload(data,0)
	}
	const rows = () => {
		let array = [];
		console.log(data)
		data.forEach(value => {
			array.push([
				value.HotelId,
				value.RoomName,
				value.OnePax,
				value.TwoPax,
				uploads[value.HotelId]||"Upload Pending"
			])
		})
		return array;
	}
	return (
		<>
			{
				uploadCompleted?<div>
					<div className="pd4 border mt4">
						<h2>
							Upload Summary
						</h2>
						<p>
							Your upload summary appears below
						</p>
						<div className="flex horizontally center-vertically flex-wrap mt4">
							<div className="mr6">
								<KeyValue title="New Created" value={`${Object.values(uploads).filter(i=>{return i==="Upload completed"}).length}`}/>
							</div>
							<div>
								<KeyValue title="Failed" value={`${Object.values(uploads).filter(i=>{return i!=="Upload completed" && i!=="Sync completed"}).length}`}/>
							</div>
						</div>
					</div>
				</div>:uploading?
					<div>
						<Loader/>
						<p className="mt2">
							{Object.keys(uploads).length}  of {data.length} completed
						</p>
					</div>
					:<Button onClick={uploadSheet} className='btn btn-primary' margin="mt2"><i className='fa fa-upload mr1'/>Upload Hotels</Button>
			}
			{
				uploadCompleted?
					<div>
						<BorderTable rows={rows()} headers={["Hotel Id","Room Name","Price Per Night(One Pax)","Price Per Night Two Pax","Status"]}/>
					</div>:uploading?<></>:
						<div className="mt4">
							<h2>
								Preview
							</h2>
							<p>
								All hotel mappings appears below
							</p>
							<BorderTable rows={rows()} headers={["Hotel Id","Room Name","Price Per Night(One Pax)","Price Per Night Two Pax","Status"]}/>
						</div>
			}
		</>
	)
}
