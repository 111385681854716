import React, {useCallback, useEffect, useState} from 'react';
import {
	Button,
	NavDivider,
	Navigation,
	ScrollableDynamicTable,
	TOAST as Alert,
} from 'retro';
import {get} from 'App/Network/Axios';
import {DownloadCsv} from 'retro/Utils';
import RBAC from 'App/HOCs/RBAC';
import ROLES from 'App/Constants/Roles';

export default function AllClients({}) {
	const [Dates, setDates] = useState({
		page: 0,
	});
	const [Data, SetData] = useState({
		total: 0,
		currentPage: 0,
		totalPages: 0,
		items: [],
	});
	const loadPage = useCallback(
		(search) => {
			get(
				`/meta/da/da`,
				(e, r) => {
					if (r) {
						SetData({
							...r,
						});
					} else {
						Alert.handleError(e);
					}
				},
				{
					offset: search.page,
				}
			);
		},
		[SetData]
	);
	useEffect(() => {
		loadPage(Dates);
	}, [Dates]);
	return (
		<>
			<Navigation
				backUrl={'back'}
				title='DA Accounts'
				chips={['All Accounts']}
				description='All recharges are listed below.'
			/>
			<NavDivider/>
			<div className='mt4'>
				<div className='relative'>
					<RBAC role={ROLES.REPORTS}>
						<div className='flex horizontally center-vertically'>
							<div className='flex-1 flex horizontally'>
								<Button
									onClick={() => {
										get(
											`/meta/da/da/download`,
											(e, r) => {
												if (r) {
													let data = [];
													let headers = [
														'Id',
														'Client Code',
														'Client Name',
														'Account Number',
														'Ifsc Code',
														'Balance',
														'Credit Limit',
														'Admin Name',
														"CUST_CODE",
														"Reference Id"
													];
													data.push(headers);
													r.items.map((item) => {
														data.push([
															item.id,
															item.clientCode,
															item.clientName,
															item.accountNumber,
															item.ifsc,
															item.balance,
															item.creditLimit,
															item.attrs['created.name'],
                                                            item.clientAttrs?.CUST_CODE||"-",
															item.attrs?.referenceId || "NA",
														]);
													});
													DownloadCsv(`accounts.csv`, data);
												} else {
													console.log(e);
													Alert.handleError('Unable to download report');
												}
											},
											{}
										);
									}}
									className='btn btn-black'
									margin=''
								>
									Download Data
								</Button>
							</div>
						</div>
					</RBAC>
					<ScrollableDynamicTable
						setPage={(page) => {
							setDates({
								...Dates,
								page,
								total: Dates.totalPages,
							});
						}}
						width={175}
						search={Dates}
						tableStyle={{
							borderSpacing: 0,
						}}
						data={{
							current: Data.currentPage,
							total: Data.totalPages,
							items: Data.items.map((item) => {
								return {
									content: item,
									render: [
										{
											children: (
												<div>
													<p className='text'>{item.clientName}</p>
													<p className='text-small text-light'>
														{item.clientCode}
													</p>
												</div>
											),
											weight: 2,
										},
										{
											children: (
												<div>
													<p className='text'>{item.accountNumber}</p>
													<p className='text-small text-light'>
														{item.accountId}
													</p>
													<p className='text-small text-light'>{item.ifsc}</p>
												</div>
											),
											weight: 2,
										},
										{
											weight: 1,
											title: item.balance || `0`,
										},
										{
											weight: 1,
											title: item.creditLimit || `0`,
										},
										{
											weight: 1,
											title: item.attrs['created.name'] || '-',
										},
									],
								};
							}),
						}}
						headers={[
							{
								weight: 2,
								title: 'Client Details',
							},
							{
								weight: 2,
								title: 'Account Details',
							},
							{
								weight: 1,
								title: 'Balance',
							},
							{
								weight: 1,
								title: 'Credit Limit',
							},
							{
								weight: 1,
								title: 'Added By',
							},
						]}
					/>
				</div>
			</div>
		</>
	);
}
