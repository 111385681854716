import React from 'react'
import {Route, Switch} from "react-router-dom";
import Routes from './routes'
import {NotFound} from "../../../../../retro";
import Bookings from './Logs'
import BookingQueue from './BookingQueue'
import RefundQueue from './RefundQueue'
import BosFile from './BosFile'
import PerksBosFile from './PerksBosFile'

export default function index() {

	return (
		<Switch>
			<Route path="/app/apps/meta/bus" exact>
				<Routes/>
			</Route>
			<Route path="/app/apps/meta/bus/bos" exact>
				<BosFile/>
			</Route>
			<Route path="/app/apps/meta/bus/bos/perks" exact>
				<PerksBosFile/>
			</Route>
			<Route path="/app/apps/meta/bus/bookings" exact>
				<Bookings/>
			</Route>
			<Route path="/app/apps/meta/bus/queue/refunds" exact>
				<RefundQueue/>
			</Route>
			<Route path="/app/apps/meta/bus/queue/bookings" exact>
				<BookingQueue/>
			</Route>
			<NotFound/>
		</Switch>
	)
}
