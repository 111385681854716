import React from 'react';
import { DownloadCsv } from 'retro/Utils';
import { Button } from 'retro/index';

const SampleBulkUploadFormat = () => {
  const downloadSampleData = () => {
    let data = [];
    let headers = ['Client Name', 'Status', 'Code'];
    data.push(headers);

    // First Row of Data
    data.push(['Sanket Test', 'DEACTIVATE', '0232']);

    DownloadCsv('Sample Format For Bulk Recharge', data);
  };

  return (
    <div>
      <Button
        onClick={downloadSampleData}
        className="btn btn-secondary text-small rounded-pill mr1 mt2"
        // style={{ float: "right" }}
      >
        <i className="fa fa-download mr1" />
        Download Sample Format
      </Button>
    </div>
  );
};

export default SampleBulkUploadFormat;
