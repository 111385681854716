import React, {useState} from 'react'
import Step1 from './Step1'
import Step2 from './Step2'
import {NavDivider, Navigation} from "../../../../../../retro";

export default function index() {
	const [step,setStep] = useState(1)
	const [data,setData] = useState([])
	return (
		<div>
			<Navigation backUrl={''} title="Upload Hotels" chips={['Contracted Hotels', "Bulk Upload Hotels"]} description="Bulk upload hotels utility appears below."/>
			<NavDivider/>
			{step===1?<Step1  onNext={data=>{
				setData(data)
				setStep(2)
			}}/>:undefined}
			{step===2?<Step2 data={data}  onNext={data=>{
				setStep(3)
			}}/>:undefined}
		</div>
	)
}
