import React, {useEffect, useRef, useState} from 'react'
import {useParams} from "react-router-dom";
import {Button, Input, Loader, Pages, Seperator, TOAST} from "../../../../retro";
import {get, post} from "../../../Network/Axios";
import {useSelector} from "react-redux";

export default function Settings() {
    const {id,clientId} = useParams();
    const ALERTEMAIL = useRef();
    const MINBALANCE = useRef();
    const REFID = useRef();
    const PARTNER = useRef();
    const TSP = useRef()
    const [Data,setData] = useState({
        type : "CREDIT",
        loading:true,
        attrs : {}
    })
    const {code} = useSelector(data=>({code:data?.auth?.config?.origin?.code||""}));
    useEffect(()=>{
        get(`/accounts/account/${id}`,(e,r)=>{
            if(r){
                setData({
                    ...r,
                    loading: false
                })
            }
        })
    },[id])
    if(Data.loading){
        return (<Loader/>)
    }
    console.log(code)
    const settings = () => {
        if(code==="YESUAT" || code==='YES-PROD'){
            return (
                <div className="border pd4 round mt4">
                    <h2 className="fw-bold">
                        Partner Keys
                    </h2>
                    <p>
                        Update partner keys for this virtual accounts
                    </p>
                    <Input defaultValue={Data.attrs.PAYOUT_KEY} ref={PARTNER} label="Partner Key" placeholder="Enter partner key" />
                    <Input defaultValue={Data.attrs.TSP_KEY} ref={TSP} label="Tsp Key" placeholder="Enter tsp key" />
                    <Button onClick={()=>{
                        let partner = PARTNER.current.value;
                        let tsp = TSP.current.value;
                        post(`/accounts/account/${id}/tsp`,{partner,tsp},(e,r)=>{
                            if(r){
                                TOAST.success("Keys updated successfully!")
                            }else{
                                TOAST.handleError(e)
                            }
                        })
                    }} className="btn btn-danger" margin="mt4">
                        Update Partner Keys
                    </Button>
                </div>
            )
        }
		// function to update the alert email
        const updateAlert = ()=>{
            let alertTo = ALERTEMAIL.current.value;
            let minBalance = MINBALANCE.current.value;
			// check for empty fields
            if(!alertTo || !minBalance){
                TOAST.error("Enter Email and Minimum Balance");
                return;
            }
			// check for valid email
			let emailRegex = new RegExp(/\S+@\S+\.\S+/);
         	let isEmail = emailRegex.test(alertTo);
			if (!isEmail) {
				TOAST.error("Enter a valid email");
				return;
			}
            post(
                `/accounts/account/${id}/daBalanceAlert/addAlert`,
                {alertTo,minBalance},
                (e,r)=>{
                    if(r){
                        TOAST.success("Alert Configuration Updated");
                    }else{
                        TOAST.handleError(e);
                    }
                }
            );
        }
		// function to update reference id for the da
        const updateRefId = ()=>{
            let referenceId = REFID.current.value;
            // check for empty field
			if(!referenceId){
                TOAST.error("Enter Reference Id First");
                return;
            }
            post(
                `accounts/account/${clientId}/${id}/tags/add`,
                {key:'referenceId', value:referenceId},
                (e,r)=>{
                    if(r){
                        TOAST.success("Reference Id Updated");
                    }else{
                        TOAST.handleError(e);
                    }
                }
            );
        }
        return (
            <div className="pd2 round mt2">
                <Pages.Title title="Minimum Balance Alert" description="Set the minimum balance alert configuration" className="mb4" />
                <div style={{width: '50%'}}>
                    <Input defaultValue={Data.attrs.alertTo} ref={ALERTEMAIL} label="Minimum  Balance Alert Email" placeholder="Enter email id" />
                    <Input defaultValue={Data.attrs.minBalance} ref={MINBALANCE} label="Minimum Balance Amount" placeholder="Enter amount" />
                    <Button onClick={updateAlert} className="btn btn-black" margin="mt4" >
                        Update Alert
                    </Button>
                </div>
                <Seperator margin={4}/>
                <Pages.Title title="Reference Id" className="mb4" />
                <div style={{width: '50%'}}>
                    <Input defaultValue={Data.attrs.referenceId} ref={REFID} label="Reference ID" placeholder="Enter reference id" />
                    <Button onClick={updateRefId} className="btn btn-black" margin="mt4" >
                        Update Reference ID
                    </Button>
                </div>
                <Seperator margin={4}/>
            </div>
        )
    }
    return (
        <div>
            {settings()}
        </div>
    )
}
