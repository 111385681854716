import React, { useEffect, useState } from "react";
import { ExpandableSidebar, TOAST as Alert } from "../../../../../../retro";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import { get } from "App/Network/Axios";
import Pending from "../ContractedQueued";
import Payment from "./Payment";
import InvoiceCollection from "./InvoiceCollection";
import InvoiceCollected from "./InvoiceCollected";
import Assigned from "./Assigned";
import Unassigned from "./Unassigned";
import useConfigHook from "App/Hooks/useConfigHook";

/**
 * Search Criteria
 * Trip Id
 * Guest Name
 * Guest Mobile
 *
 *
 * Break Up
 * @returns {JSX.Element}
 */
export default function QueuedAPI() {
  const History = useHistory();
  const location = useLocation();
  const [assigned, setAssigned] = useState(0);
  const [pending, setPending] = useState(0);
  const [unassigned, setUnassigned] = useState(0);
  const [payment, setPayment] = useState(0);
  const [invoice, setInvoice] = useState(0);
  const [invoiceColleted, setInvoiceCollected] = useState(0);

  const CONFIG = useConfigHook();
  
  useEffect(() => {
    const load = () => {
      get(
        `/meta/contracted/pending`,
        (e, r) => {
          if (r) {
            setPending(r.total);
          }
        },
        {
          offset: 0,
        }
      );
      get(
        `/meta/contracted/assigned`,
        (e, r) => {
          if (r) {
            setAssigned(r.total);
          }
        },
        {
          offset: 0,
        }
      );
      get(
        `/meta/contracted/unassigned`,
        (e, r) => {
          if (r) {
            setUnassigned(r.total);
          }
        },
        {
          offset: 0,
        }
      );
      get(
        `/meta/contracted/completed/QUEUED`,
        (e, r) => {
          if (r) {
            setPayment(r.total);
          }
        },
        {
          offset: 0,
        }
      );
      get(
        `/meta/contracted/completed/INVOICE`,
        (e, r) => {
          if (r) {
            setInvoice(r.total);
          }
        },
        {
          offset: 0,
        }
      );
      get(
        `/meta/contracted/completed/COMPLETED`,
        (e, r) => {
          if (r) {
            setInvoiceCollected(r.total);
          }
        },
        {
          offset: 0,
        }
      );
    };

    load();
    const interval = setInterval(() => {
      load();
    }, 10000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const allSidebarItems = [
    {
      title: "My Actions",
      actions: [
          {
          title:
              !assigned 
              ? "Assigned To Me"
              : `Assigned To Me (${assigned})`,
          active:
              location.pathname === "/app/apps/meta/hotelQueue/apiQueue/assigned",
          onClick: () => {
              History.push("/app/apps/meta/hotelQueue/apiQueue/assigned");
          },
          },
      ],
    },
    {
      title: "All Queue",
      actions: [
        {
          title: pending == 0 
          ? "All Pending Requests"
          : `All Pending Requests (${pending})`,
          active: location.pathname === "/app/apps/meta/hotelQueue/apiQueue",
          onClick: () => {
            History.push("/app/apps/meta/hotelQueue/apiQueue");
          },
        },
        {
          title: unassigned == 0 
          ? "Unassigned"
          : `Unassigned (${unassigned})`,
          active: location.pathname === "/app/apps/meta/hotelQueue/apiQueue/unassigned",
          onClick: () => {
            History.push("/app/apps/meta/hotelQueue/apiQueue/unassigned");
          },
        },
        
        {
          title:
            payment === 0
              ? "Payment Pending"
              : `Payment Pending (${payment})`,
          active:
            location.pathname === "/app/apps/meta/hotelQueue/apiQueue/payments",
          onClick: () => {
            History.push("/app/apps/meta/hotelQueue/apiQueue/payments");
          },
        },
        {
          title:
            invoice === 0
              ? "Invoice Collection"
              : `Invoice Collection (${invoice})`,
          active:
            location.pathname === "/app/apps/meta/hotelQueue/apiQueue/invoice",
          onClick: () => {
            History.push("/app/apps/meta/hotelQueue/apiQueue/invoice");
          },
        },
        {
          title:
            invoice === 0
              ? "Invoice Collected"
              : `Invoice Collected (${invoiceColleted})`,
          active:
            location.pathname ===
            "/app/apps/meta/hotelQueue/apiQueue/invoiceCollected",
          onClick: () => {
            History.push("/app/apps/meta/hotelQueue/apiQueue/invoiceCollected");
          },
        },
      ],
    },
  ];

  const someSidebarItems = [
    {
      title: "My Actions",
      actions: [
          {
          title:
              !assigned 
              ? "Assigned To Me"
              : `Assigned To Me (${assigned})`,
          active:
              location.pathname === "/app/apps/meta/hotelQueue/apiQueue/assigned",
          onClick: () => {
              History.push("/app/apps/meta/hotelQueue/apiQueue/assigned");
          },
          },
      ],
    },
    {
      title: "All Queue",
      actions: [
        {
          title: pending == 0 
          ? "All Pending Requests"
          : `All Pending Requests (${pending})`,
          active: location.pathname === "/app/apps/meta/hotelQueue/apiQueue",
          onClick: () => {
            History.push("/app/apps/meta/hotelQueue/apiQueue");
          },
        },
        {
          title: unassigned == 0 
          ? "Unassigned"
          : `Unassigned (${unassigned})`,
          active: location.pathname === "/app/apps/meta/hotelQueue/apiQueue/unassigned",
          onClick: () => {
            History.push("/app/apps/meta/hotelQueue/apiQueue/unassigned");
          },
        },
      ]
    }
  ];
  const sidebarItems = CONFIG?.roles.includes("SUPER")  
                        ? allSidebarItems 
                        : CONFIG?.restrictViews?.includes("HOTEL_QUEUE")
                          ? allSidebarItems
                          : someSidebarItems;
  return (
    <div className='flex mt4 h-100h w-100'>
      <ExpandableSidebar items={sidebarItems} />
      <div
      className='flex-1 overScrollY pd3'
      style={{height: "calc(100vh - 100px)"}}
      >
        <Switch>
          <Route path='/app/apps/meta/hotelQueue/apiQueue' exact component={Pending} />
          <Route 
              path='/app/apps/meta/hotelQueue/apiQueue/unassigned' 
              exact 
              component={Unassigned} />
          <Route 
              path='/app/apps/meta/hotelQueue/apiQueue/payments' 
              exact 
              component={Payment}
          />
          <Route 
              path='/app/apps/meta/hotelQueue/apiQueue/invoice' 
              exact 
              component={InvoiceCollection}
          />
          <Route 
              path='/app/apps/meta/hotelQueue/apiQueue/invoiceCollected' 
              exact 
              component={InvoiceCollected}
          />
          <Route 
              path='/app/apps/meta/hotelQueue/apiQueue/assigned' 
              exact 
              component={Assigned}
          />
        </Switch>
      </div>
    </div>
  );
}
