import React, {useCallback, useEffect, useRef, useState} from 'react'
import {useParams} from "react-router-dom";
import {get, post} from "../../../../Network/Axios";
import {
	Button,
	Grids,
	InlineTable,
	Input,
	KeyValue,
	Loader,
	Modal,
	Pages,
	Seperator,
	Texts,
	TOAST
} from "../../../../../retro";
import Theme from './Thme'
import Documents from './Documents'
import CopyIcon from '@atlaskit/icon/glyph/copy'
import Logo from "./Logo";
import Css from './Css'
import ApiUser from "./ApiUser";
import BlackListEmail from './BlackListEmail';
import Status from './Status'
import Manager from "./Manager";
import useFunctionallityHook from "../../../../Hooks/useFunctionallityHook";
import useConfigHook from 'App/Hooks/useConfigHook';
import B2CBilling from './B2CBilling';
import SalesManager from './SalesManager';
import ImplementationManager from './ImplementationManager';
import QueueCommunications from './QueueCommunications/QueueCommunications';

export default function Index() {
	const config = useConfigHook();
	const {id} = useParams();
	const KEY = useRef();
	const VALUE = useRef();
	const [token,setToken] = useState(undefined)
	const [Apis,setApis] = useState({
		apis:[]
	})
	const [Data, SetData] = useState({
		loading: true,
	})
	let ORIGIN = config.origin.code;
	const API = useFunctionallityHook().api
	const THEME = useFunctionallityHook().theme
	const TAGS = useFunctionallityHook().tags
	const [tagModal,setTagModal] = useState(false)
	const loadApis = useCallback(()=>{
		get(`/clients/${id}/keys`, (e, r) => {
			if (r) {
				setApis(r)
			}
		})
	},[id])
	const load = useCallback(()=>{
		get(`/clients/${id}`, (e, r) => {
			if (r) {
				SetData({
					...r,
					loading: false
				})
			}
		})
	},[id])
	useEffect(() => {
		if(Data.loading){
			load()
			loadApis()
		}
	}, [Data.loading,load]);

	const addTag = ({ id, key, value }) => {
		post(`/clients/${id}/attrs`,{key,value: value.toLowerCase()},(e,r)=>{
			if(r){
				load();
				setTagModal(false);
				TOAST.success("GST Added and sent for approval")
			}
			if(e){
				TOAST.handleError(e);
			}
		})
	}

	if (Data.loading) {
		return (
			<div>
				<Loader/>
			</div>
		)
	}
	return (
		<div>
			{
				tagModal &&
				<Modal onClose={()=>{
					setTagModal(false)
				}} button="Add Tags" onSubmit={()=>{
					let key = KEY.current.value;
					let value = VALUE.current.value;
					if(!key){
						TOAST.error("Enter a valid key")
						return
					}
					if(!value){
						TOAST.error("Enter a valid value")
						return
					}

					if (key.toLowerCase().includes('gstin')) {
						post('/validate/gstin',{ gstin: value }, (error, response) => {
							if (error) {
								return TOAST.handleError(error);
							}

							if (!response.valid) {
								return TOAST.error('Enter a valid GSTIN');
							}

							addTag({ id, key, value });
						});
					} else {
						addTag({ id, key, value });
					}
				}} title="Add Tags" description="Enter details to add a new tags" >
					<Input type="text" ref={KEY} label="Enter key details" morph={true}/>
					<Input type="text" ref={VALUE} label="Enter value details" morph={true}/>
				</Modal>
			}
			{
				token &&
				<Modal onClose={()=>{
					setToken(undefined)
				}}  title="Token" description="Please copy the token. All tokens has validity of 1 year." >
					<Texts.Regular style={{
						overflow:'hidden',
						overflowWrap:'anywhere'
					}} className="flex-1">
						{token}
					</Texts.Regular>
					<Button onClick={()=>{
						navigator.clipboard.writeText(token).then(function() {
							TOAST.success("Copy Completed")
						}, function(err) {
							TOAST.error("Could not copy text")
						});
					}} className="btn-secondary" margin="mt4">
						<CopyIcon size="small"/>
						Copy Token
					</Button>
				</Modal>
			}
			{['YESUAT', 'YES-PROD'].includes(ORIGIN) && (
				<>
					<Manager/>
					<Seperator margin={3}/>
				</>
			)}
			{!['YESUAT', 'YES-PROD'].includes(ORIGIN) && (
				<>
					<SalesManager/>
					<Seperator margin={3}/>
					<ImplementationManager/>
					<Seperator margin={3}/>
				</>
			)}
			{
				ORIGIN!=="YESUAT" && ORIGIN!=='YES-PROD' &&
				<>
					<B2CBilling/>
					<Seperator margin={3}/>
				</>
			}
			{!['YESUAT', 'YES-PROD'].includes(ORIGIN) && (ORIGIN==='CLRTRP') && (
				<>
					<QueueCommunications/>
					<Seperator margin={3}/>
				</>
			)}
			<Documents/>
			{
				THEME && <>
					<Seperator margin={5}/>
					<Grids.Row>
						<Grids.Column grid={{mobile:12, medium:6, large:6}} >
							<div style={{paddingRight:32,borderRight:'1px solid var(--theme-border-color)'}}>
								<Theme />
								<Css/>
							</div>
						</Grids.Column>
						<Grids.Column grid={{mobile:12, medium:6, large:6}} >
							<div style={{
								paddingLeft:32
							}}>
								<Logo/>
							</div>
						</Grids.Column>
					</Grids.Row>
				</>
			}
			{
				API &&
				<>
					<ApiUser/>
					<Seperator margin={5}/>
				</>
			}

			{
				TAGS && <>
					<Pages.Title title="Tags" description="Manage all tags for this client."/>
					<div className="border-sm pd4 w-50 mt4 rounded-md">
						<InlineTable maxWidth={550} headers={["Key","Value","Actions"]} rows={
							Object.keys(Data.attrs).filter(item=>{
								if(['accountNumber','tsp','ifscCode', ...["YESUAT","YES-PROD"]?.includes(ORIGIN)?['module']:[]].includes(item)){
									return false;
								}
								return true
							}).map(value =>{
								return [
									value,value.toLowerCase().includes('gstin') ? Data.attrs[value]?.toUpperCase() : Data.attrs[value],(
										!value.toLowerCase().includes('gstin')?<Button onClick={()=>{
											post(`/clients/${id}/attrs/remove`,{key:value},(e,r)=>{
												if(r){
													TOAST.success("Reoved Tag");
													load()
												}
												if(e){
													TOAST.handleError(e);
												}
											})
										}} margin="mt1 mb1" className="btn-link btn-sm btn-link-danger">
											- Delete
										</Button>:<></>
									)
								]
							})
						}/>
						<Button onClick={()=>{setTagModal(true)}} className="btn-link">
							+ Add Tag
						</Button>
					</div>
				</>
			}
			{!['YESUAT',"YES-PROD"].includes(config.origin.code) && <BlackListEmail />}
			{
				API && <>
					<Seperator margin={5}/>
					<div>
						<Pages.Title title="Api Keys" description="Manage your api keys for this account."/>
						<div className="border-sm pd4 mt4 rounded-md relative">
							<Button onClick={()=>{
								get(`/clients/${id}/token`,(e, r) => {
									if (r) {
										setToken(r.token)
									}
								})
							}} className="btn-secondary btn-sm absolute" style={{right:18}} margin="">
								Generate Token
							</Button>
							<div className="mt3 mb3">
								<KeyValue title="Origin" value={Apis.origin}/>
								<KeyValue title="Client Id" value=".child">
									{Apis.clientId}
									<Button
										onClick={() => {
											navigator.clipboard.writeText(Apis.clientId).then(
												() => {
													TOAST.success("Copy Completed");
												},
												() => {
													TOAST.error("Could not copy text");
												}
											);
										}}
										className="btn-link btn-sm"
										margin=""
									>
										Copy
									</Button>
								</KeyValue>
								<KeyValue title="Client Code" value=".child">
									{Apis.clientSecret}
									<Button
										onClick={() => {
											navigator.clipboard.writeText(Apis.clientSecret).then(
												() => {
													TOAST.success("Copy Completed");
												},
												() => {
													TOAST.error("Could not copy text");
												}
											);
										}}
										className="btn-link btn-sm"
										margin=""
									>
										Copy
									</Button>
								</KeyValue>
							</div>
							<InlineTable maxWidth="80%" headers={["Key","Actions"]} rows={
								Apis.apis.map(item => {
									return [
										(
											<div className="">
												{item.apiKey}
												<Button onClick={()=>{
													navigator.clipboard.writeText(item.apiKey).then(function() {
														TOAST.success("Copy Completed")
													}, function(err) {
														TOAST.error("Could not copy text")
													});
												}} className="btn-link btn-sm" margin="">
													Copy
												</Button>
											</div>
										),
										(
											<Button margin="" onClick={()=>{

											}} className="btn-link btn-link-danger btn-sm">
												- Delete Key
											</Button>
										)
									]
								})
							}/>
							<Button onClick={()=>{
								post(`/clients/${id}/keys`, {},(e, r) => {
									if (r) {
										loadApis()
									}
								})
							}} className="btn-link">
								+ Generate Key
							</Button>
						</div>
					</div>
				</>
			}
			<Status/>
		</div>
	)
}
