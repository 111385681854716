import React from 'react';
import { NavDivider, Navigation } from '../../../../../retro';
import { useSelector } from 'react-redux';
import { ReportRouteCard } from '../All/routes';
import useRoleHook from 'App/Hooks/useRoleHook';
import ROLES from 'App/Constants/Roles';

export default function index() {
  // Origin Code
  const { code } = useSelector((data) => ({
    code: data?.auth?.config?.origin?.code || '',
  }));
  const isReports = useRoleHook(ROLES.REPORTS)
  const isSupport = useRoleHook(ROLES.SUPPORT)

  const routes = [];
  if (isReports){
    routes.push(
      {
        title: 'Agency BOS File',
        desc: 'All bookings data distributed traveller wise and segment wise',
        path: '/app/apps/meta/flight/bos/agency',
        btnLabel: 'View File',
        codes: [],
      },
      {
        title: 'Service Fee MIS',
        desc: 'Service Fee MIS',
        path: '/app/apps/meta/flight/bos/serviceMis',
        btnLabel: 'View File',
        codes: [],
      },
      {
        title: 'Reseller BOS File',
        desc: 'All bookings data distributed traveller wise and segment wise',
        path: '/app/apps/meta/flight/bos/reseller',
        btnLabel: 'View File',
        codes: ['CLRTRP',"CLTP-STG"],
      }
    )
  }
  if (isSupport){
    routes.push(
      {
        title: 'Failed Bookings',
        desc: 'All failed bookings data appears here',
        path: '/app/apps/meta/flight/failed',
        btnLabel: 'View Failed',
        codes: [],
      },
      {
        title: 'All Cancellation Queue',
        desc: 'Flight which are waiting for cancellation confirmation appears here',
        path: '/app/apps/meta/flight/queue/refunds',
        btnLabel: 'View Queue',
        codes: [],
      },
      {
        title: 'Invoice Queue',
        desc: 'Flight which are waiting for invoice generation appears here',
        path: '/app/apps/meta/flight/invoices',
        btnLabel: 'View Queue',
        codes: ['CLRTRP',"CLTP-STG"],
      },
      {
        title: 'Zeor Invoice Queue',
        desc: 'Flight which are waiting for correction appears here',
        path: '/app/apps/meta/flight/invoices/holded',
        btnLabel: 'View Queue',
        codes: ['CLRTRP',"CLTP-STG"],
      },
      {
        title: 'All Confirmed Bookings',
        desc: 'All flight confirmed/failed bookings appears here',
        path: '/app/apps/meta/flight/bookings',
        btnLabel: 'View Bookings',
        codes: [],
      },
      {
        title: 'All Pending Queue',
        desc: 'Flight booking which are ongoing appears here',
        path: '/app/apps/meta/flight/queue/bookings',
        btnLabel: 'View Queue',
        codes: [],
      }
    )
  }
  routes.push(
    {
      title: 'Repository',
      desc: 'Flight booking repository appears here',
      path: '/app/apps/meta/flight/repository',
      btnLabel: 'View Repository',
      codes: ['NONE']
    }
  )
    

  return (
    <div>
      <Navigation
        backUrl=""
        title="Flights Bookings"
        description="All flights queue appears below."
      />
      <NavDivider />
      <div className="card-container mt4">
        {routes.map(
          (report) =>
            (report.codes.length === 0 || report.codes.includes(code)) ?  <ReportRouteCard reportRoute={report} /> : <></>
        )}
      </div>
    </div>
  );
}
