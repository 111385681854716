import React, {useEffect, useRef, useState} from 'react'
import {Attachment, Button, FileInput, TOAST} from "../../../../../retro";
import {get, post} from "../../../../Network/Axios";
import {useParams} from "react-router-dom";

export default function Css() {
    const {id} = useParams();
    const FILECSS = useRef();
    const [cssUrl,setCssUrl] = useState(undefined)
    const [css,setCss] = useState([])
    const load = (id)=>{
        get(`/clients/theme/${id}`,(e,r)=>{
            if(r){
                setCss(r.css)
            }
        })
    }
    useEffect(()=>{
       load(id)
    },[id])
	return (
		<div>
            <div>
                <p className="fw-bold mt4 mb2">
                    Css:
                </p>
                <div className="flex flex-wrap">
                    {
                        css.map(item=>{
                            return (
                                <Attachment url={item} onDelete={()=>{
                                    post(`/clients/theme/${id}/css/remove`,{
                                        file:item
                                    },(e,r)=>{
                                        if(r){
                                            TOAST.success("Css removed successfully")
                                            load(id)
                                        }else{
                                            TOAST.handleError(e)
                                        }
                                    })
                                }}/>
                            )
                        })
                    }
                </div>
                <p className="mt2">
                    Select File
                </p>
            </div>
            <div className="flex mb2 mt2">
                <FileInput extensions={['.css']} updateFiles={(url)=>{
                    setCssUrl(url)
                }} label="" ref={FILECSS}/>
                <Attachment url={cssUrl}/>
            </div>
            <Button onClick={()=>{
                if(cssUrl){
                    post(`/clients/theme/${id}/css/add`,{
                        file:cssUrl
                    },(e,r)=>{
                        if(r){
                            TOAST.success("Css updated successfully")
                            setCssUrl(undefined)
                        }else{
                            TOAST.handleError(e)
                        }
                    })
                }
            }} className="btn-secondary mt3">
                Add Css
            </Button>
		</div>
	)
}
