import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { get,post } from 'App/Network/Axios';
import { Button, Headings, Icons, Loader, PageLoad, Switch, Texts, TOAST } from 'retro';
import RBAC from 'App/HOCs/RBAC';
import ROLES from 'App/Constants/Roles';

export default function AllApis() {
  const { id } = useParams(),
    History = useHistory(),
    [AllCards] = useState([
      {
        name: 'Email',
        icon: <Icons.Email size='xlarge' />,
        type: 'EMAIL',
        des: 'All email communications going through your account appears here',
        path: `/app/client/${id}/apis/email`,
        showToggle:false
      },
      {
        name: 'Mobile',
        icon: <Icons.Mobile size='xlarge' />,
        type: 'SMS',
        des: 'All sms communications going through your account appears here',
        path: `/app/client/${id}/apis/sms`,
        showToggle:false
      },
      {
        name: 'WhatsApp',
        icon: <Icons.Mobile size='xlarge' />,
        type: 'WHATSAPP',
        des: 'All whatsapp communications going through your account appears here',
        path: `/app/client/${id}/apis/whatsapp`,
        showToggle:true
      },
      {
        name: 'Fcm',
        icon: <Icons.Notification size='xlarge' />,
        type: 'FCM',
        des: 'All push notification going through your account appears here',
        path: `/app/client/${id}/apis/fcm`,
        showToggle:false
      },
      {
        name: 'Expense Ocr',
        img: <Icons.ExpenseIcon size='large' />,
        type: 'EXPENSE',
        des: 'All expense ocr going through your account appears here',
        path: `/app/client/${id}/apis/expense`,
        showToggle:false
      },
      {
        name: 'Invoice Ocr',
        type: 'INVOICE',
        img: <Icons.InvoiceScan size='large' />,
        des: 'All invoice ocr going through your account appears here',
        path: `/app/client/${id}/apis/invoice`,
        showToggle:false
      },
      {
        name: 'Bank Validation',
        type: 'BANKVALID',
        img: <Icons.Bank size='large' />,
        des: 'All bank validation going through your account appears here',
        path: `/app/client/${id}/apis/bankvalid`,
        showToggle:false
      },
      {
        name: 'Gstin Validation',
        type: 'GSTVALID',
        img: <Icons.Tax size='large' />,
        des: 'All gstin validation going through your account appears here',
        path: `/app/client/${id}/apis/gstvalid`,
        showToggle:false
      },
      {
        name: 'Pan Validation',
        type: 'PANVALID',
        img: <Icons.License size='large' />,
        des: 'All pan validation going through your account appears here',
        path: `/app/client/${id}/apis/panvalid`,
        showToggle:false
      },
      {
        name: 'Maps',
        type: 'MAPS',
        img: <Icons.Maps size='large' />,
        des: 'All maps api going through your account appears here',
        path: `/app/client/${id}/apis/maps`,
        showToggle:false
      },
      {
        name: 'Geo code',
        type: 'GEOCODE',
        img: <Icons.Route size='large' />,
        des: 'All geocode api going through your account appears here',
        path: `/app/client/${id}/apis/geocode`,
        showToggle:false
      },
      {
        name: 'Static maps',
        type: 'STATICMAPS',
        img: <Icons.Maps size='large' />,
        des: 'All maps api going through your account appears here',
        path: `/app/client/${id}/apis/staticmaps`,
        showToggle:false
      },
      {
        name: 'Route Apis',
        type: 'DISTANCE',
        img: <Icons.Route size='large' />,
        des: 'All distance apis going through your account appears here',
        path: `/app/client/${id}/apis/distance`,
        showToggle:false
      },
      {
        name: 'Places Apis',
        type: 'PLACES',
        img: <Icons.Route size='large' />,
        des: 'All places search going through your account appears here',
        path: `/app/client/${id}/apis/places`,
        showToggle:false
      },
      {
        name: 'Currency Service',
        type: 'CURRENCY',
        img: <Icons.Currency size='large' />,
        des: 'All currency going through your account appears here',
        path: `/app/client/${id}/apis/currency`,
        showToggle:false
      },
    ]),
    [Data, SetData] = useState({
      loading: true,
    }),
    LoadConfig = useCallback(() => {
      get(`/clients/${id}/status/api`, (e, r) => {
        if (r) {
          SetData({
            ...r,
            loading: false,
          });
        }
      });
    }, [id]);
  useEffect(() => {
    LoadConfig();
  }, [LoadConfig]);
  useEffect(() => {
    PageLoad(`All Apis for ${Data.name}`);
  }, [Data.name]);
  if (Data.loading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }
  const SET = {};
  Object.keys(Data.map).forEach((key) => (SET[key] = true));
  const item = (item) => {
    var checked = Data.map[item.type];
    let data = Data.data[item.type];
    return (
      <div key={item.type} className='retro-card'>
        <div
          key={item.name}
          style={{
            opacity: checked ? 1 : 0.37,
          }}
          className='relative '
        >
        {
          item?.showToggle && 
          <div
              style={{
                position: "absolute",
                right: 12,
                top: 12,
                zIndex: 2,
                color: "#fff",
              }}
            >
            <Switch
              state={checked}
              onChange={() => {
                post(
                  `clients/${id}/status/api`,
                  {
                    key: item?.upload || item?.type,
                    value: checked ? "false" : "true",
                  },
                  (e, r) => {
                    if (r) {
                      LoadConfig();
                      TOAST.success("Whatsapp trip messages status updated");
                    }
                  }
                );
              }}
              size="large"
            />
          </div>
        }
          {item.icon ? (
            <div
              style={{
                margin: -6,
              }}
            >
              {item.icon}
            </div>
          ) : (
            <div>{item.img}</div>
          )}
          <Headings.Regular margin='mt4'>{item.name}</Headings.Regular>
          <div className='flex mt1'>
            <div
              className='flex center'
              style={{
                border: '1px solid var(--theme-border-color)',
                borderRadius: 12,
                padding: '4px 8px',
                marginRight: 12,
              }}
            >
              <Icons.LatencyIcon size='xsmall' />
              <p
                className='truncate'
                style={{
                  marginLeft: 6,
                  maxWidth: 70,
                  fontSize: 12,
                }}
              >
                {data.average.toFixed(1)} sec
              </p>
            </div>
            <div
              className='flex'
              style={{
                border: '1px solid var(--theme-border-color)',
                borderRadius: 12,
                padding: '4px 8px',
                marginRight: 12,
              }}
            >
              <Icons.CountIcon size='xsmall' />
              <p
                className='truncate'
                style={{
                  marginLeft: 6,
                  maxWidth: 70,
                  fontSize: 12,
                }}
              >
                {data.total}
              </p>
            </div>
            <div
              className='flex'
              style={{
                border: '1px solid var(--theme-border-color)',
                borderRadius: 12,
                padding: '4px 8px',
                marginRight: 12,
              }}
            >
              <Icons.SuccessIcon size='xsmall' />
              <p
                className='truncate'
                style={{
                  marginLeft: 6,
                  maxWidth: 70,
                  fontSize: 12,
                }}
              >
                {data.success}
              </p>
            </div>
          </div>
          <Texts.Small style={{ maxLines: 2 }} className='mt4'>
            {item.des}
          </Texts.Small>
          <RBAC role={ROLES.API}>
            <Button
              onClick={() => {
                if (checked) {
                  History.push(item.path);
                } else {
                  TOAST.error('Enable this api first');
                }
              }}
              className='mt3 btn-max btn-primary'
            >
              <Icons.View size='small' />
              View
            </Button>
          </RBAC>
        </div>
      </div>
    );
  };
  return (
    <div className='card-container'>
      {AllCards.filter((i) => SET[i.type]).map(item)}
    </div>
  );
}
