import React, {useEffect, useState} from 'react'
import {useHistory, useParams} from "react-router-dom";
import {get} from "App/Network/Axios";
import {Loader, Navigation, PageLoad} from "../../../../../../retro";
import AllUsers from './Offices'


export default function Users(){
	const {id} = useParams();
	const HISTORY = useHistory();
	const [Data, SetData] = useState({
		loading: true,
	})
	useEffect(() => {
		if(Data.loading){
			get(`/clients/${id}`, (e, r) => {
				if (r) {
					PageLoad(`All Apis for ${r.name}`)
					SetData({
						...r,
						loading: false
					})
				}
			})
		}
	}, [id,Data.loading])
	if (Data.loading) {
		return (
			<div>
				<Loader/>
			</div>
		)
	}
	return (
		<div>
			<AllUsers/>
		</div>
	)
}
