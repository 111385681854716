import React, {useCallback, useEffect, useState} from 'react';
import {
	Button,
	Loader,
	ScrollableDynamicTable,
	TOAST as Alert,
} from 'retro';
import {get, post} from 'App/Network/Axios';
import OpenIcon from '@atlaskit/icon/glyph/open';
import ViewRequest from '../ViewRequest';
import Moment from 'moment';
import SendToHotel from "./SendToHotel";
import {Highlight} from "App/Pages/App/MetaApis/HotelQueue/Queued/Utils";
import Filter from 'retro/Filter';
import FilterDropDown from 'App/Components/Filter/FilterDropDown';

export default function queued() {
	let [loading, setLoading] = useState(true);
	const [id, setId] = useState(undefined);
	const [search, setSearch] = useState({
		page: 0,
		sort: 'id',
		order: 'desc',
	});
	const [Data, SetData] = useState({
		total: 0,
		currentPage: 0,
		totalPages: 0,
		items: [],
	});
	const [filterModal,setFilterModal] = useState(false);
	const [filter, setFilter] = useState({
		client: [],
		checkin: []
	});
	const loadPage = useCallback(
		(search) => {
			const clientIds = filter.client.map(client => client.id);
			get(
				`/meta/hotel/queue/assigned`,
				(e, r) => {
					if (r) {
						SetData({
							...r,
							total: r.total || Data.total,
							totalPages: r.totalPages || Data.totalPages,
						});
						setLoading(false);
					} else {
						Alert.handleError(e);
					}
				},
				{
					offset: search.page,
					order: search.order,
					q: search.q,
					start: filter.checkin.length > 0 ?
						filter.checkin[0] === 'Upcoming 24 hours' ?
						  Moment().valueOf().toString() : Moment().add(24, 'hours').valueOf().toString()
						: undefined,
					end: filter.checkin.length > 0 ?
					  filter.checkin[0] === 'Upcoming 24 hours' ? Moment().add(24, 'hours').valueOf().toString() : Moment().add(48, 'hours').valueOf().toString()
					  : undefined,
					clientIds: clientIds.length > 0 ? clientIds : undefined
				  }
			);
		},
		[Data, SetData, filter.checkin, filter.client]
	);
	useEffect(() => {
		loadPage(search);
	}, [search, filter.checkin, filter.client]);
	if (loading) {
		return <Loader/>;
	}
	return (
		<div style={{
			width:"calc(100vw - 475px)"
		}} 
		// className="overScrollY"
		>
			      <Filter
			margin="0 -4rem"
			onSearch={(q) => {
				setSearch({
					...search,
					page: 0,
					q,
				})
			}}
			onFilter={() => {
				setFilterModal(true)
			}}
		/>

		<FilterDropDown
			shown={filterModal}
			filters={filter}
			setFilters={setFilter}
			choices={{
				client: [],
				checkin: [
					{
						title: 'Upcoming 24 hours',
						type: 'radio'
					},
					{
						title: 'Upcoming 48 hours',
						type: 'radio'
					}
				]
			}}
			types={["Client", "CheckIn"]}
			onClose={() => {
				setFilterModal(false);
			}}
		/>
			<ScrollableDynamicTable
				setSearch={(search) => {
					setSearch({
						...search,
						page: 0,
					});
				}}
				search={search}
				tableStyle={{
					borderSpacing: 0,
				}}
				setPage={(page) => {
					setSearch({
						...search,
						page,
					});
				}}
				data={{
					current: Data.currentPage,
					total: Data.totalPages,
					items: Data.items.map((item) => {
						const click = () => {
							setId(item.queueId);
						};
						return {
							content: item,
							render: [
								{
									children: (
										<div>
											<Highlight item={item}/>
											<p className='text'>{item.hotel?.city || '-'}</p>

											<p className='text-small text-light'>
												{item.hotel?.name || '-'}
											</p>
											<p className='text-small text-light'>
												{item.hotel?.address || '-'}
											</p>
										</div>
									),
									weight: 1.5,
								},
								{
									weight: 1.5,
									children: (
										<div>
											<p>{item.client.clientCode}</p>
											<p className='text-small'>{item.guests[0].name}</p>
											<p className='text-small'>{item.guests[0].email}</p>
										</div>
									),
								},

								{
									weight: 1.5,
									children: (
										<>
											<p>{item.entity || '-'}</p>
										</>
									),
								},
								{
									weight: 1,
									title: `${item.queueStatus}`,
								},
								{
									weight: 1,
									title: `${item.stayDetails.checkIn} - ${item.stayDetails.checkOut}`,
								},
								{
									weight: 1,
									title: Moment(item.createdAt).format('DD MMM YYYY HH:mm a'),
								},

								{
									weight: 1.5,
									children: (
										<div>
											{
												item.queueStatus==="QUEUED" || item.queueStatus==="ASSIGNED" && <div>
													<SendToHotel item={item} onRefresh={()=>{
														loadPage(search);
													}}/>
													<Button onClick={() => {
														post(`/meta/hotel/queue/${item.queueId}/status`,{status:"CLEARTRIP"}, (e, r) => {
															if (r) {
																loadPage(search);
															} else {
																Alert.handleError(e)
															}
														})
													}} className="btn-sm btn btn-secondary">
														Non-Contracted Queue
													</Button>
												</div>
											}
											{
												item.queueStatus==="CLEARTRIP" && <div>

												</div>
											}
											{
												item.queueStatus==="CONTACTED" && <div>
													<SendToHotel item={item} onRefresh={()=>{
														loadPage(search);
													}}/>
													<Button onClick={click} margin="" className="btn-sm btn btn-primary">
														Confirm Booking
													</Button>
												</div>
											}
										</div>
									)
								},
								{
									weight: 1,
									buttons: [
										{
											title: 'View',
											className: 'btn-secondary btn-sm',
											before: <OpenIcon size='small'/>,
											onClick: click,
										},
									],
									actions: [
										{
											title: 'Quick Actions',
											actions: [
												{
													title: 'View',
													before: <OpenIcon size='small'/>,
													onClick: click,
												},
											],
										},
									],
								},
							],
						};
					}),
				}}
				headers={[
					{
						weight: 1.5,
						title: 'Details',
					},
					{
						weight: 1.5,
						title: 'Client',
					},
					{
						weight: 1.5,
						title: 'Entity/Gst Details',
					},
					{
						weight: 1,
						title: 'Queue Status',
					},
					{
						weight: 1,
						title: 'Travel Date',
					},
					{
						weight: 1,
						title: 'Requested Date',
					},
					{
						weight: 1.5,
						title: 'Automate',
					},
					{
						weight: 1,
						title: 'Actions',
						style: {
							justifyContent: 'end',
						},
					},
				]}
			/>
			{id !== undefined && (
				<ViewRequest
					id={id}
					onClose={() => {
						setId(undefined);
						loadPage(search);
					}}
				/>
			)}
		</div>
	);
}
