import React from 'react'
import {Route, Switch} from "react-router-dom";
import Routes from './routes'
import Pg from './Pg'
import {NotFound} from "../../../../../retro";
import PaytmPg from './PaytmPg';

export default function index() {

	return (
		<Switch>
			<Route path="/app/apps/meta/pg" exact>
				<Routes/>
			</Route>
			<Route path="/app/apps/meta/pg/payments" exact>
				<Pg/>
			</Route>
			<Route path="/app/apps/meta/pg/paytmPayments" exact>
				<PaytmPg/>
			</Route>
			<NotFound/>
		</Switch>
	)
}
