import React, {useCallback, useEffect, useState} from 'react'
import {Button, Headings, Loader, Page, Texts} from "../../../../../retro";
import {useHistory, useParams} from "react-router-dom";
import {get} from "../../../../Network/Axios";
import AppSwitcherIcon from '@atlaskit/icon/glyph/app-switcher'
import MarketInstaller from "./MarketInstaller";
import RoadmapIcon from "@atlaskit/icon/glyph/roadmap";

export default function allInstalled() {
	const {id} = useParams()
	const [apps, setApps] = useState([])
	const HISTORY = useHistory();
	const [modal, setModal] = useState(false)
	const [loading, setLoading] = useState(true)
	const load = useCallback(() => {
		get(`/clients/market/${id}`, (e, r) => {
			if (r) {
				setApps(r.apps)
				setLoading(false)
			}
		})
	}, [id])
	useEffect(() => {
		load()
	}, [load])
	if (loading) {
		return (<Loader/>)
	}
	return (
		<Page>
			{
				modal &&
				<MarketInstaller onClose={() => {
					setModal(false);
					load()
				}}/>
			}
			<Button onClick={() => {
				setModal(true)
			}} margin="" className="btn-primary btn-sm">
				+ Add App
			</Button>
			<div className="mt4 pb5 border-bottom">
				<div className="flex horizontally center-vertically">
					<div className="pr3 flex-1">
						<Headings.Regular>
							User Apps
						</Headings.Regular>
						<Texts.Small>
							All user apps are visible below
						</Texts.Small>
					</div>
				</div>
				<div className="mt4 card-container">
					<div className="retro-card">
						<AppSwitcherIcon size="large"/>
						<Headings.Regular className="mt2">
							Empty
						</Headings.Regular>
						<Texts.Regular>
							No apps found for this type
						</Texts.Regular>
					</div>
				</div>
			</div>
			<div className="pt5 pb5 border-bottom">
				<div className="flex horizontally center-vertically">
					<div className="pr3 flex-1">
						<Headings.Regular>
							Dashboard Apps
						</Headings.Regular>
						<Texts.Small>
							All dashboard apps are available below
						</Texts.Small>
					</div>
				</div>
				<div className="mt4 card-container">
					<div className="retro-card">
						<AppSwitcherIcon size="large"/>
						<Headings.Regular className="mt2">
							Empty
						</Headings.Regular>
						<Texts.Regular>
							No apps found for this type
						</Texts.Regular>
					</div>
				</div>
			</div>
			<div className="pt5 pb5 border-bottom">
				<div className="flex horizontally center-vertically">
					<div className="pr3 flex-1">
						<Headings.Regular>
							Vendor Apps
						</Headings.Regular>
						<Texts.Small>
							All vendor apps are available below
						</Texts.Small>
					</div>
				</div>
				<div className="mt4 card-container">
					<div className="retro-card">
						<AppSwitcherIcon size="large"/>
						<Headings.Regular className="mt2">
							Empty
						</Headings.Regular>
						<Texts.Regular>
							No apps found for this type
						</Texts.Regular>
					</div>
				</div>
			</div>
			<div className="pt5 pb5 border-bottom">
				<div className="flex horizontally center-vertically">
					<div className="pr3 flex-1">
						<Headings.Regular>
							Middlewares
						</Headings.Regular>
						<Texts.Small>
							All middlewares are available below
						</Texts.Small>
					</div>
				</div>
				<div className="mt4 card-container">
					{
						apps.filter(item=>{
							return item.type==="MIDDLEWARE"
						}).map(task=>{
							return(
								<div onClick={()=>{
									HISTORY.push(
										`/app/client/${id}/market/${task.id}`
									)
								}} key={task.id} className='retro-card'>
									<RoadmapIcon size="large"/>
									<Headings.Regular className="mt3">
										{task.title}
									</Headings.Regular>
									<Texts.Regular>
										{task.description}
									</Texts.Regular>
								</div>
							)
						})
					}
					{
						!apps.filter(item=>{
							return item.type==="MIDDLEWARE"
						}).length && <div className="retro-card">
							<AppSwitcherIcon size="large"/>
							<Headings.Regular className="mt2">
								Empty
							</Headings.Regular>
							<Texts.Regular>
								No apps found for this type
							</Texts.Regular>
						</div>
					}
				</div>
			</div>
		</Page>
	)
}
