import React, { useEffect, useState } from 'react';
import { Button, DateRange, Navigation, TOAST } from 'retro/index';
import Moment from 'moment';
import { get } from 'App/Network/Axios';
import { DownloadCsv } from 'retro/Utils';
import Graph from './graph-stats-circle.svg';
import RBAC from 'App/HOCs/RBAC';
import ROLES from 'App/Constants/Roles';
import MultiSelectDropdown from 'App/Components/MultiSelectDropdown';
import { fetchData } from 'App/Utils/Utils';
import { getAirlines, getDocUrl, getFlightNumber, getSegments, getTickets } from './Invoices';
import { placeOfSupply } from '../Flight/BosTravellerFile/Utils';

const FailedInvoices = () => {
  const [Dates, setDates] = useState({
    startDate: Moment().add(-31, 'd').valueOf(),
    endDate: Moment().valueOf(),
  });
  const [loading, setLoading] = useState(false);
  const [selectedClients, setselectedClients] = useState([]);
  const [clients, setClients] = useState([]);

  // get the list of clients for dorpdown
  useEffect(() => {
    get(
      '/clients/dump',
      (e, r) => {
        if (r) {
          setClients([
            ...r.items.map((client) => {
              return { code: client.code, id: client.id };
            }),
          ]);
        }
      },
      { active: true }
    );
  }, []);

  const downloadData = () => {
    setLoading(true);
    // setting the filter by ids based on client code
    let clientsFilter = [];
    if (selectedClients.length > 0) {
      clientsFilter = clients
        .filter((client) => {
          if (selectedClients.includes(client.code)) {
            return client.id;
          }
        })
        .map((client) => client.id);
    }
    fetchData(
      `/meta/mis/invoice/failed`,
      Dates.startDate,
      Dates.endDate,
      (items, e) => {
        if (e) {
          setLoading(false);
          console.log(e);
          TOAST.error('Error downloading report');
          return;
        }
        let data = [];
		let headers = [
			'Provider Id',
			'Trans Id',
			'Reference Id',
			'Reseller',
			'Status',
			'Category',
			'Booking Date',
			'Booking Id',
			'Client Name',
			'Client Code',
			'Amount',
			'Refund Amount',
			'Refund Ledger Id',
			'Refund Balance',
			'Seller Gst Name',
			'Seller Gst Gstin',
			'Buyer Gst Name',
			'Buyer Gst Gstin',
			'Buyer State',
			'Document Type',
			'Document Date',
			'Document Number',
			'Segment',
			'BASE',
			'OTHER CHARGES',
			'IGST',
			'SGST',
			'CGST',
			'TOTAL FARE',
			'GST Slab',
			'HSN Code',
			'Document URL',
			'IRN',
			'Ledger Id',
			'Ledger Amount',
			'Ledger Balance',
			'Payment Type',
			'Fare Type',
			'Booker Name',
			'Booker Email',
			'Cabin Class',
			'Airline',
			'Ticket Number',
			'PNR',
			'Flight Number',
			'Traveller Name',
			'Hotel Name',
			'Hotel Check In',
			'Hotel Check Out',
			'Hotel State',
			'Place of Supply',
			'Provider',
			'State of Supply',
			'Supplier State',
			'Traveller Email ID',
			'Error Remarks'
		];
		data.push(headers);
		items.forEach((item) => {
			let line = [];
			line.push(item.booking?.providerId || '-');
			line.push(item.booking?.transId || '-');
			line.push(item.booking?.referenceId || '-');
			line.push(item.invoiceConfig?.reseller || '-');
			line.push(item.status || '-');
			line.push(item.booking?.category || '-');
			line.push(
				Moment(item.booking?.createdAt).format('DD-MMM-YYYY') || '-'
			);
			line.push(item.booking?.id || '-');
			line.push(item.client?.name || '-');
			line.push(item.client?.code || '-');
			line.push(item.booking?.amount || '-');
			line.push(item.booking?.refundAmount || '0');
			line.push(item.booking?.refund?.ledgerId !== 0 ? item.booking?.refund?.ledgerId : '-');
			line.push(item.booking?.refund?.balance || '-');
			line.push(item.sellerGstin?.name || '-');
			line.push(item.sellerGstin?.gstin || '-');
			line.push(item.buyerGstin?.name || '-');
			line.push(item.buyerGstin?.gstin || '-');
			line.push(placeOfSupply(item.buyerGstin?.gstin) || '-');
			line.push(item.docType || '-');
			line.push(Moment(item.docDate).format('DD-MMM-YYYY'));
			line.push(item.docNo || '-');
			line.push(getSegments(item.booking));
			line.push(item.fare?.base || '0');
			line.push(item.fare?.oth || '0');
			line.push(item.fare?.iGst || '0');
			line.push(item.fare?.sGst || '0');
			line.push(item.fare?.cGst || '0');
			line.push(item.fare?.total || '0');
			line.push(item.fare?.slab || item.booking?.ticket?.gstSlab || '-');
			line.push('998551'); // HSN Code
			line.push(getDocUrl(item.booking?.invoiceConfig, item.docType));
			line.push(item.irn || '-');
			line.push(item.booking?.ledger?.ledgerId || '-');
			line.push(item.booking?.ledger?.amount || '-');
			line.push(item.booking?.ledger?.balance || '-');
			line.push(item.booking?.paymentType || '-');
			line.push(item.booking?.ticket?.fareType || '-');
			line.push(item.booking?.invoiceConfig?.bookedBy || '-');
			line.push(item.booking?.invoiceConfig?.bookedByEmail || '-');
			line.push(item.booking?.ticket?.cabinClass || '-');
			line.push(getAirlines(item.booking));
			line.push(getTickets(item.booking) || "-");
			line.push(item.booking?.ticket?.recko?.breakups[0]?.pnr || "-");
			line.push(getFlightNumber(item.booking));
			line.push(item.booking?.ticket?.travellers[0].name || '-');
			line.push(item.booking?.ticket?.name || '-');
			line.push(item.booking?.ticket?.date?.in || '-');
			line.push(item.booking?.ticket?.date?.out || '-');
			line.push(item.booking?.ticket?.state || '-');
			line.push(item.booking?.invoiceConfig?.placeOfSupply || '-');
			line.push(item.booking?.provider || '-');
			line.push(item.booking?.invoiceConfig?.placeOfSupply || '-');
			line.push(item.booking?.invoiceConfig?.placeOfSupply || '-');
			line.push(item.booking?.ticket?.travellers[0].email || '-');
			line.push(
				item.booking?.invoiceConfig?.serviceResponse?.response?.error
					? item.booking?.invoiceConfig?.serviceResponse?.response?.error[0].errorMsg
					: '-'
			);
			data.push(line);
		});
        DownloadCsv(
          `failed_invoices_mis_${Moment(Dates.startDate).format(
            'DD_MM_YYYY'
          )}_${Moment(Dates.endDate).format('DD_MM_YYYY')}.csv`,
          data
        );
        setLoading(false);
      },
      {
        client: clientsFilter.toString(),
      },
      4
    );
  };

  return (
    <div style={{ width: '100%' }}>
      <Navigation
        backUrl={'back'}
        title="Failed Invoices MIS"
        chips={['Meta', 'MIS', 'Failed', 'Invoices']}
        description="Failed Invoices MIS"
      />
      <div className="flex horizontally center-vertically">
        <DateRange
          defaultValue={{
            startDate: Moment().add(-31, 'd').valueOf(),
            endDate: Moment().valueOf(),
          }}
          onChange={(dates) => {
            setDates({
              ...dates,
              page: 0,
            });
          }}
          className="flex-1 mt1"
          label=""
          type="range"
        />
        <MultiSelectDropdown
          className="flex-1 mt1 ml1"
          options={clients.map((client) => client.code)}
          selectedOptions={selectedClients}
          setSelectedOptions={setselectedClients}
          placeholder="Select Clients"
          showSelectedOptins={false}
        />
      </div>
      <div>
        <div className="border pd6 mt4 flex vertically center">
          <img
            alt="fetch"
            src={Graph}
            style={{ width: '5rem', height: '5rem' }}
          />
          <RBAC role={ROLES.REPORTS}>
            <Button
              onClick={downloadData}
              className="btn btn-black mt4"
              loading={loading}
            >
              Download Data
            </Button>
          </RBAC>
          <p className="mt2">Download MIS Data</p>
        </div>
      </div>
    </div>
  );
};

export default FailedInvoices;
