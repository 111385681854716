import React, {useEffect, useRef, useState} from 'react'
import {useParams} from "react-router-dom";
import {get, post} from "../../../../Network/Axios";
import {
	Attachment, Button,
	Empty, FileInput, Input,
	KeyValue,
	Loader, Modal,
	NavDivider,
	Navigation, Seperator,
	SidePane, StatusChip,
	TOAST
} from "../../../../../retro";
import Hotel from './Hotel'
import Bus from './Bus'
import Flight from './Flight';
import Moment from "moment";
import Manual from './Manual'
import { Letter } from "react-letter";
import { extract } from "letterparser";
import moment from "moment";
import {Comment} from "../../../../../retro/Comments/Comments";
import ReactJson from "react-json-view";
import useConfigHook from "../../../../Hooks/useConfigHook";
import GstFare from "App/Pages/App/MetaApis/TransactionDetails/GstFare";
import CancelBooking from "App/Pages/App/MetaApis/TransactionDetails/CancelBooking";
import OtherCancellation from './CancelBooking/Cancellation';
import AddTags from './AddTags';


const PERSONS = {
	"lakshay.jain@antrepriz.com":true,
	"samarth.b@dice.tech":true,
	"viraf.bharda@cleartrip.com":true,
	"ashwini.kambale@cleartrip.com":true,
	"suchender.negi@cleartrip.com":true,
	"simple.sharma@cleartrip.com":true,
	"nayna.salpe@cleartrip.com":true,
	"shivani.suryavanshi@cleartrip.com":true,
	"dipikad.vc@cleartrip.com ":true,
	"naveen.kumarbn@cleartrip.com ":true,
	"venkatesh.sankaran@cleartrip.com":true,
	"vaibhav.s1@cleartrip.com":true,
	"m.pradeep1@cleartrip.com":true,
	"s.pranith@cleartrip.com":true,
	"kashish.batra@cleartrip.com":true,
	"yousoup.shaikh@cleartrip.com":true,
	"deepti.dhawan@cleartrip.com":true,
	"kiran.singh@cleartrip.com":true,
	"mohamed.mansoor@flipkart.com":true,
	"mathews.sheetal@cleartrip.com":true,
	"dhanrajmanoja.vc@cleartrip.com":true,
	"kavitha.jogi@cleartrip.com":true,
	"ct-stage@dice.tech":true,
	"saurabh.mhaske@antrepriz.com":true,
	"vivek.ghosh@antrepriz.com":true,
	"aditya.vyawhare@antrepriz.com":true,
	"aavishkar@antrepriz.com":true,
	"shakir.shaikh@cleartrip.com":true,
	"kesavan.vemula@flipkart.com":true,
	"zohair.qadri@cleartrip.com":true,
	"Dushyant.Yogi@ienergizer.com":true,
    "mayur.srivastava@ienergizer.com":true,
    "vaibhav.singh@ienergizer.com":true,
    "sanya.bharti@ienergizer.com":true,
	"dushyant.yogi@ienergizer.com":true,
	'kesavan.vemula@cleartrip.com':true,
	"ria.deb@ienergizer.com":true,
	"subham@antrepriz.com":true,
	"nilima.shaw1@cleartrip.com":true,
	"samarth@dice.tech":true
}

export default function index() {
	const {transId} = useParams();
	const COMMENT = useRef();
	const CONFIG = useConfigHook();
	const AMOUNT = useRef();
	const REMARKS = useRef();
	const NUMBEROFNIGHTS = useRef();
	const [refundModal,setRefundModal] = useState(false)
	const [cancellationModal,setCancellationModal] = useState(false);
	const [otherServiceCancellation, setOtherServiceCancellation]=useState(false);
	const [logsModal,setLogsModal] = useState(false)
	const [commentModal,setCommentModal] = useState(false)
	const [addDocModal,setAddDocModal] = useState(false)
	const [mail,setMail] = useState(undefined)
	const [file, setFile] = useState(undefined)
	const FILE = useRef()
	const [data,setData] = useState(undefined)
	const load = () => {
		get(`/meta/${transId}/details`,(e,r)=>{
			if(r){
				setData(r)
			}else{
				TOAST.handleError(e)
			}
		})
	}
	useEffect(()=>{
		get(`/meta/${transId}/details`,(e,r)=>{
			if(r){
				setData(r)
			}else{
				TOAST.handleError(e)
			}
		})
	},[transId])
	
	const checkRoles = () => {
		if (CONFIG !== undefined) {
			return CONFIG.roles.some(role => role === 'SUPPORT' || role === 'SUPPORT_WRITE');
		}
		return false;
	}

	if(!data){
		return (<Loader/>)
	}



	if(data.status==="FAILED" || data.status==="ONGOING"){
		return (
			<div>
				<Navigation backUrl={'back'} title={data.transId} chips={['Meta',"Transaction Details",data.transId]} description="Transaction details appears below."/>
				<div className="flex horizontally center-vertically">
					<Button onClick={()=>{
						setLogsModal(true)
					}} className="btn btn-black" margin="mb2 mt2">
						View Logs
					</Button>
					{
						PERSONS[CONFIG.email] && (data.status==="ONGOING" )?
							<Button onClick={()=>{
								post(`/meta/${transId}/confirm`,{},(e,r)=>{
									if(e){
										TOAST.handleError(e)
									}else{
										TOAST.success("Queued successfully")
									}
								})
							}} className="btn btn-black" margin="ml2 mb2 ">
								Hard Sync
							</Button>:undefined
					}
					{
						PERSONS[CONFIG.email] && (data.status==="COMPLETED" || data.status==="PARTIAL" || data.status==="PENDING" || data.status==="ONGOING" || data.status==="SYNCING" || data.status==="REFUNDING")?
							<Button onClick={()=>{
								setRefundModal(true)
							}} className="btn btn-black" margin="ml2 mb2 ">
								Refund Booking
							</Button>:undefined
					}
				</div>
				{
					logsModal &&
					<SidePane title="Logs" description="Details of all logs appears here" onClose={()=>{
						setLogsModal(false)
					}}>
						<ReactJson src={data}/>
					</SidePane>
				}
				<div className="pd4 border rounded-sm mt4">
					<div className="flex horizontally center-vertically">
						<KeyValue title="Trans Id" value={data.transId}/>
						<KeyValue title="Service" value={data.service}/>
						<KeyValue title="Status" value={data.status}/>
					</div>
					<div className="flex horizontally center-vertically mt2">
						<KeyValue title="Merchant" value={data.merchant}/>
						<KeyValue title="Provider Id" value={data.provider}/>
						<KeyValue title="Payment Type" value={data.paymentType}/>
					</div>
					<div className="flex horizontally center-vertically mt2">
						<KeyValue title="Amount" value={data.amount}/>
						<KeyValue title="Refund Amount" value={data.refundAmount}/>
						<KeyValue title="Created At" value={Moment(data.createdAt).format("DD MMM YYYY hh:mm a")}/>
					</div>
				</div>
				<div className="border mt4 pd4">
					<p className="fw-bold mb4">
						Documents
					</p>
					<div className="flex horizontally">
						<Empty/>
					</div>
				</div>
				<div className="flex mt4 horizontally">
					<div className="flex-2 border pd4">
						<p className="fw-bold mb4">
							Payment Details
						</p>
						{
							data.paymentType==="PG"&&
							<div>

								<KeyValue title="Payment Type" value={data.paymentType}/>
								<KeyValue title="Order Id" value={data.pgPayment.orderId}/>
								<KeyValue title="Payment Id" value={data.pgPayment.paymentId}/>
								<KeyValue title="Refund Id" value={data.pgPayment.refundId}/>
							</div>
						}
						{
							data.paymentType==="DEPOSIT"&&
							<div>
								<KeyValue title="Payment Type" value={data.paymentType}/>
								<p style={{fontSize:10,marginBottom:12}}>
									Ledger Details:
								</p>
								<KeyValue title="Ledger Id" value={data.ledger.id}/>
								<KeyValue title="Amount" value={data.ledger.amount}/>
								<KeyValue title="Balance" value={data.ledger.balance}/>
								<p style={{fontSize:10,marginBottom:12,marginTop:18}}>
									Refund Details:
								</p>
								<KeyValue title="Ledger Id" value={data.refundLedger?.id}/>
								<KeyValue title="Amount" value={data.refundLedger?.amount}/>
								<KeyValue title="Balance" value={data.refundLedger?.balance}/>
							</div>
						}
					</div>
					<div className="border ml2 pd4 flex-3">
						Pending
					</div>
				</div>
				{
					refundModal && <Modal onClose={()=>{setRefundModal(false)}} title="Refund" description="Enter details to refund booking">
						<Input type="number" ref={AMOUNT} label="Amount" placeholder="Enter amount"/>
						<Input type="text" ref={REMARKS} label="Remarks" placeholder="Enter remarks"/>
						<Button onClick={()=>{
							let amount = AMOUNT.current.value;
							if(!amount){
								TOAST.error("Enter a valid amount")
								return
							}
							amount = parseInt(amount,10)
							if(amount<=0){
								TOAST.error("Enter a valid amount")
								return
							}
							let remarks = REMARKS.current.value;
							post(`/meta/${transId}/refund`,{remarks,amount},(e,r)=>{
								if(e){
									TOAST.handleError(e)
								}else{
									TOAST.success("Booking refunded successfully")
									setRefundModal(false)
									load()
								}
							})
						}} className="btn btn-black mt2" margin="">
							Refund
						</Button>
					</Modal>
				}
			</div>
		)
	}
	let invoice = data.invoice;
	return (
		<div>
			<div className="flex horizontally">
				{
					logsModal &&
					<SidePane title="Logs" description="Details of all logs appears here" onClose={()=>{
						setLogsModal(false)
					}}>
						<ReactJson src={invoice}/>
					</SidePane>
				}

			</div>
			{
				commentModal && <Modal onClose={()=>{
					setCommentModal(false)
				}} title="Add Comment" description="Kindly enter details below">
					<Input ref={COMMENT} label="Comment" placeholder="Enter your comment"/>
					<Button onClick={()=>{
						let comment = COMMENT.current.value;
						if(!comment){
							TOAST.error("Kindly enter a valid comment")
							return
						}
						post(`/meta/${transId}/comment`,{remarks:comment},(e,r)=>{
							if(r){
								setCommentModal(false)
								load()
							}else{
								TOAST.handleError(e)
							}
						})
					}} className="btn btn-primary" margin="mt2">
						Add Comment
					</Button>
				</Modal>
			}
			{
				addDocModal && <Modal onClose={()=>{
					setAddDocModal(false)
				}} title="Add Additional Document" description="Select a document to upload">
				<div className='flex mb2 mt2'>
					<FileInput
						updateFiles={(url) => {
						setFile(url);
						}}
						label=''
						ref={FILE}
					/>
					<Attachment url={file} />
				</div>
					<Input ref={COMMENT} label="Document Title" placeholder="Title of the document"/>
					<Button onClick={()=>{
						let title = COMMENT.current.value;
						if(!title){
							TOAST.error("Kindly enter a valid comment")
							return
						}
						post(`/meta/${transId}/attachment`,{title:title, path:file},(e,r)=>{
							if(r){
								setAddDocModal(false)
								load()
							}else{
								TOAST.handleError(e)
							}
						})
					}} className="btn btn-primary" margin="mt2">
						Add Document
					</Button>
				</Modal>
			}
			<Navigation backUrl={'back'} title={data.transId} chips={['Meta',"Transaction Details",data.transId]} description="Transaction details appears below."/>
			<NavDivider/>
			{
				refundModal && <Modal onClose={()=>{setRefundModal(false)}} title="Refund" description="Enter details to refund booking">
					<Input type="number" ref={AMOUNT} label="Amount" placeholder="Enter amount"/>
					{
						data?.service==='HOTEL'
						&&
						<Input type="number" ref={NUMBEROFNIGHTS} label="Number Of Nights" placeholder="Enter Number Of Nights"/>
					}
					<Input type="text" ref={REMARKS} label="Remarks" placeholder="Enter remarks"/>
					<Button onClick={()=>{
						let amount = AMOUNT.current.value;
						if(!amount){
							TOAST.error("Enter a valid amount")
							return
						}
						amount = parseInt(amount,10)
						if(amount<=0){
							TOAST.error("Enter a valid amount")
							return
						}
						let remarks = REMARKS.current.value;
						// number of nights for hotel refund
						let numberOfNights = NUMBEROFNIGHTS?.current?.value || "";
						if(data?.service==='HOTEL'){
							if(!numberOfNights){
								TOAST.error("Enter valid nights")
								return
							}
							numberOfNights = parseInt(numberOfNights,10);
							if(numberOfNights<=0){
								TOAST.error("Enter valid nights")
								return
							}
						}
						post(`/meta/${transId}/refund`,{remarks,amount,numberOfNights},(e,r)=>{
							if(e){
								TOAST.handleError(e)
							}else{
								TOAST.success("Booking refunded successfully")
								setRefundModal(false)
								load()
							}
						})
					}} className="btn btn-black mt2" margin="">
						Refund
					</Button>
				</Modal>
			}
			{cancellationModal && <CancelBooking transId={transId} data={data} onReload={load} setCancellationModal={setCancellationModal}/>  }	
			{otherServiceCancellation && <OtherCancellation transId={transId} data={data} onReload={load} setOtherServiceCancellation={setOtherServiceCancellation}/>  }		

			<div className="border relative">
				<h2 className="fw-bold bg-container pd2 border-bottom">
					{data.transId}
				</h2>
				<div style={{paddingTop:4}} className="absolute top-1 right-1">
					<div className="flex horizontally center-vertically mb2 justify-end">
						<Button onClick={()=>{
							post(`/meta/${transId}/invoice/regenerate`,{},(e,r)=>{
								if(r){
									TOAST.success("Invoice queued successfully")
									reload()
								}
							})
						}} className="btn btn-primary" margin="mr2 mb2">
							Regenerate Invoice
						</Button>
						<Button onClick={()=>{
							post(`/meta/${transId}/cancellation/regenerate`,{},(e,r)=>{
								if(r){
									TOAST.success("Cancellation queued successfully")
									reload()
								}
							})
						}} className="btn btn-primary" margin="mr2 mb2">
							Regenerate Cancellation
						</Button>
						<Button onClick={()=>{
							setLogsModal(true)
						}} className="btn btn-primary" margin="mb2">
							View Logs
						</Button>
						<Manual onClose={()=>{setManual(undefined)}} data={data}/>
						{
							PERSONS[CONFIG.email] && (data.status==="COMPLETED" || data.status==="PARTIAL" || data.status==="REFUNDED" || data.status==="PENDING" || data.status==="ONGOING" || data.status==="SYNCING" || data.status==="REFUNDING")?
								<Button onClick={()=>{
									setRefundModal(true)
								}} className="btn btn-black" margin="ml2 mb2 ">
									Refund Booking
								</Button>:undefined
						}
						{
						    checkRoles() && ((data.status === "COMPLETED" || data.status === "PARTIAL") && data.service.toLowerCase().includes('flight')) ? (
						        <Button onClick={() => { 
						            setCancellationModal(true);
						        }} className="btn btn-black" margin="ml2 mb2 ">
						            Cancel Booking
						        </Button>
						    ) : (
						        <Button onClick={() => {
						            setOtherServiceCancellation(true);
						        }} className="btn btn-black" margin="ml2 mb2 ">
						            Cancel Booking
						        </Button>
						    )
						}


					</div>

				</div>
				<div className="flex horizontally">
					<div className="flex-3 pd3 ">
						<div className="flex horizontally center-vertically">
							<KeyValue title="Status" value={data.status}/>
							<KeyValue title="Service" value={data.service}/>
							<KeyValue title="Email Address" value={data.attrs.bookedByEmail}/>
						</div>
						<div className="flex horizontally center-vertically mt2">
							<KeyValue title="Merchant" value={data.merchant}/>
							<KeyValue title="Provider Id" value={data.provider}/>
							<KeyValue title="Payment Type" value={data.paymentType}/>
						</div>
						<div className="flex horizontally center-vertically mt2">
							<KeyValue title="Amount" value={data.amount}/>
							<KeyValue title="Created At" value={Moment(data.createdAt).format("DD MMM YYYY hh:mm a")}/>
							<KeyValue title="Date" value={Moment(data.date).format("DD MMM YYYY")}/>
						</div>
					</div>
					<div className="flex-1 pd3  border-left">
						<p className="text-small mb2">
							Client Details
						</p>
						<KeyValue title="Client Name" value={data.clientName}/>
						<KeyValue title="Client Code" value={data.clientCode}/>
						<KeyValue title="Reference Id" value={data.referenceId}/>
					</div>
				</div>
			</div>
			<div className="flex mt4 horizontally">
				<div className="flex-2 border relative">
					<h2 className="fw-bold bg-container pd2 border-bottom">
						Payment Details
					</h2>
					<div className="absolute right-1 top-2">
						<StatusChip status={data.paymentType}/>
					</div>
					<div className="pd4">
						{
							data.paymentType==="PG"&&
							<div>
								<KeyValue title="Order Id" value={data.pgPayment.orderId}/>
								<KeyValue title="Payment Id" value={data.pgPayment.paymentId}/>
								<KeyValue title="Refund Id" value={data.pgPayment.refundId}/>
							</div>
						}
						{
							data.paymentType==="DEPOSIT"&&
							<div>
								<p style={{fontSize:12,marginBottom:12}}>
									Ledger Details:
								</p>
								<KeyValue title="Ledger Id" value={data.ledger.id}/>
								<KeyValue title="Amount" value={data.ledger.amount}/>
								<KeyValue title="Balance" value={data.ledger.balance}/>
								<Seperator margin={2}/>
								<p style={{fontSize:12,marginBottom:12,marginTop:18}}>
									Refund Details:
								</p>
								<KeyValue title="Ledger Id" value={data.refundLedger?.id}/>
								<KeyValue title="Amount" value={data.refundLedger?.amount}/>
								<KeyValue title="Balance" value={data.refundLedger?.balance}/>
							</div>
						}
					</div>
				</div>
				{
					data.ticket && <div className="border ml2 flex-3">
						<h2 className="fw-bold pd2 border-bottom bg-container">
							Ticket
						</h2>
						<div className="pd4">
							{
								data?.service==='DOMESTICFLIGHT'
									?<Flight {...data} id={transId}/>
									:data?.service==='DOMESTICROUNDFLIGHT'
										?<Flight {...data} id={transId}/>
										:data?.service==='HOTEL'
											?<Hotel {...data} id={transId}/>
											:data?.service==='BUS'
												?<Bus {...data} id={transId}/>
												:<></>
							}
						</div>
					</div>
				}
			</div>
			<div className="flex horizontally mt4">
				<div className="border   mr2 flex-1">
					<h2 className="fw-bold pd2 border-bottom bg-container">
						Dice Documents
					</h2>
					<div className="flex pd4 horizontally">

						{
							data.invoice?.file ? <div className="mr2">
								<Attachment url={data.invoice.file}/>
								<p>
									Voucher
								</p>
							</div>:undefined
						}
						{
							data.invoice?.masked ? <div className="mr2">
								<Attachment url={data.invoice.masked}/>
								<p>
									Masked Voucher
								</p>
							</div>:undefined
						}
						{
							data.invoice?.invoice!=="SKIPPED" ? <div className="mr2">
								<Attachment url={data.invoice.invoice}/>
								<p>
									Invoice
								</p>
							</div>:undefined
						}
						{
							data.invoice?.serviceInvoice!=="SKIPPED" ?
								<div className="mr2">
									<Attachment url={data.invoice.serviceInvoice}/>
									<p>
										Service Invoice
									</p>
								</div>
								:undefined
						}
						{
							data.invoice?.creditNote!=="PENDING" ?
								<div className="mr2">
									<Attachment url={data.invoice.creditNote}/>
									<p>
										Credit Note
									</p>
								</div>
								:undefined
						}






                         {
                             data.invoice?.ancillaryCredit!=="PENDING" && data.invoice?.ancillaryCredit!=="SKIPPED"?
                                <div className="mr2">
                                    <Attachment url={data.invoice.ancillaryCredit}/>
                                    <p>
                                        Ancillary Credit Note
                                    </p>
                                </div>
                                :undefined
                        }



                        {
                            data.invoice?.creditMemo?.length > 0 && (
                                <>
                                {data.invoice?.creditMemo.map((item, index) => {
                                    return (
                                    <div className="mr2">
                                        <Attachment url={item} />
                                        <p>Credit Memo {index > 0 ? `_${index}` : ""}</p>
                                    </div>
                                    );
                                })}
                                </>
                            )
                        }





						
						{
							data.invoice?.ancillaryInvoice!=="PENDING" && data.invoice?.ancillaryInvoice!=="SKIPPED"  ?
								<div className="mr2">
									<Attachment url={data.invoice.ancillaryInvoice}/>
									<p>
										Ancillary Invoice
									</p>
								</div>
								:undefined
						}
						{
							data.invoice?.cancellationCharges!=="PENDING" ?
								<div>
									<Attachment url={data.invoice.cancellationCharges}/>
									<p>
										Cancellation Charges
									</p>
								</div>
								:undefined
						}
						{
							data.invoice?.paymentReceipt!=="SKIPPED" ? <div className="mr2">
								<Attachment url={data.invoice.paymentReceipt}/>
								<p>
									Payment Receipt
								</p>
							</div>:undefined
						}
						{
							data.invoice?.refundVoucher && data.invoice?.refundVoucher!=="SKIPPED" ? <div className="mr2">
								<Attachment url={data.invoice.refundVoucher}/>
								<p>
									Refund Voucher
								</p>
							</div>:undefined
						}
					</div>

				</div>
				<div className="border  ml2 flex-1">
					<h2 className="fw-bold pd2 border-bottom bg-container">
						External Documents
					</h2>
					<div className="flex horizontally pd4">
						{
							data.data.inboundAttachments.length===0&& <Empty/>
						}
						{
							data.data.inboundAttachments.map((item,index)=>{
								return (
									<div key={index} className="mr2">
										<Attachment url={item}/>
										<p className="text-small mt2">
											Attachment {index}
										</p>
									</div>
								)
							})
						}
					</div>
				</div>
			</div>
			<div className="flex mt4 horizontally">
				<div className="flex vertically justify-between flex-1 border mr2">
					<h2 className="fw-bold pd2 border-bottom bg-container">
						Additional Documents
					</h2>
					{
						data.data.additionalAttachments.length===0
						?
						<div className="pd2">
							<Empty/>
						</div>
						:
						<div className="flex horizontally pd4">
							{
								data.data.additionalAttachments.map((item,index)=>{
									return (
										<div key={index} className="mr2">
											<Attachment url={item.path}/>
											<p className="text-small mt2">
												{item.title}
											</p>
										</div>
									)
								})
							}
						</div>
					}
					<div>
					<Seperator/>
					<Button onClick={()=>{
						setAddDocModal(true)
					}} className="btn btn-primary" margin="ml2 mr2 mb2">
						+ Add Document
					</Button>
					</div>
				</div>
				<div className="flex vertically justify-between flex-1 border mr2">
					<h2 className="fw-bold pd2 border-bottom bg-container">
						Comments
					</h2>
					{
						data.data.comments.length===0 && <div className="pd2">
							<Empty/>
						</div>
					}
					<div className="pl4">
						{
							data.data.comments.map(item=>{
								return(<Comment time={item.time} key={item.time} comment={item.content} author={item.ownerName}/>)
							})
						}
					</div>
					<div>
					<Seperator/>
					<Button onClick={()=>{
						setCommentModal(true)
					}} className="btn btn-primary" margin="ml2 mr2 mb2">
						+ Add Comment
					</Button>
					</div>
				</div>
			</div>
			<div>
			<div className="flex mt4 horizontally">
				<div className="flex vertically justify-between flex-1 border mr2">
					<h2 className="fw-bold pd2 bg-container border-bottom">
						Mails
					</h2>
					{
						data.data.mails.length===0 && <div className="pd4">
							<Empty/>
						</div>
					}
					{
						data.data.mails.map(item=>{
							return (
								<div onClick={()=>{
									get(`/meta/${item.id}/content`,(e,r)=>{
										if(r){
											setMail({
												eml:extract(r.eml),
												...item
											})
										}else{
											TOAST.handleError(e)
										}
									})
								}} key={item.id} className="pd4 pointer border-bottom">
									<h2 className="fw-bold">
										{item.from}
									</h2>
									<p>
										{item.subject}
									</p>
									<div className="text-small">
										Received on {moment(item.createdAt).format("DD MMM YYYY HH:mm a")}
									</div>
								</div>
							)
						})
					}
				</div>
				{/*UI to show tags  */}

				<div className="flex vertically justify-between flex-1 border mr2">
					<h2 className="fw-bold pd2 border-bottom bg-container">
						Tags
					</h2>
					<div className="pl4 pb4">
						 <AddTags tags={data.invoice?.tags} transId={data.transId} load={load}/>
					</div>
					<div>
					</div>
				</div>
			</div>
			</div>
			<div className="mt4">
				<h2>
					Invoice
				</h2>
				<div className="card-container mt2">
					<div className="retro-card">
						<h3 className="mb2">
							User Gst
						</h3>
						{
							!invoice.userGst && "No gstin provided for this client."
						}
						{
							invoice.userGst && <div>
								<KeyValue title="Name" value={invoice.userGst.name}/>
								<KeyValue title="Email Address" value={invoice.userGst.email}/>
								<KeyValue title="Gstin" value={invoice.userGst.gstin}/>
								<KeyValue title="Address" value={invoice.userGst.address}/>
							</div>
						}
					</div>
					<GstFare reload={()=>{
						load()
					}} transId={transId} invoiceStatus={data.invoiceStatus} service={data.service} invoice={invoice}/>
					<div className="retro-card">
						<h3 className="mb2">
							Service Charge
						</h3>
						{
							!invoice.serviceFare && "Not a service charges."
						}
						{
							invoice.serviceFare && <div className="flex flex-wrap">
								<div className="mr2">
									<KeyValue title="Base Fare + Non Taxable" value={`${parseInt(invoice.serviceFare.base,10)} base fare + ${parseInt(invoice.serviceFare.oth,10)} other charges`}/>
									<KeyValue title="Gst Slab" value={invoice.serviceFare.slab}/>
									<KeyValue title="Gst" value={`${parseInt(invoice.serviceFare.cGst,10)} cgst + ${parseInt(invoice.serviceFare.sGst,10)} sgst + ${parseInt(invoice.serviceFare.iGst,10)} igst`}/>
									<KeyValue title="Total Charges" value={parseInt(invoice.serviceFare.total,10)}/>
								</div>
							</div>
						}
					</div>
					<div className="retro-card">
						<h3 className="mb2">
							Ancillary
						</h3>
						{
							!invoice.ancillaryFare && "None ancillary charges."
						}
						{
							invoice.ancillaryFare && <div className="flex flex-wrap">
								<div className="mr2">
									<KeyValue title="Base Fare + Non Taxable" value={`${parseInt(invoice.ancillaryFare.base,10)} base fare + ${parseInt(invoice.ancillaryFare.oth,10)} other charges`}/>
									<KeyValue title="Gst Slab" value={invoice.ancillaryFare.slab}/>
									<KeyValue title="Gst" value={`${parseInt(invoice.ancillaryFare.cGst,10)} cgst + ${parseInt(invoice.ancillaryFare.sGst,10)} sgst + ${parseInt(invoice.ancillaryFare.iGst,10)} igst`}/>
									<KeyValue title="Total Charges" value={parseInt(invoice.ancillaryFare.total,10)}/>
								</div>
							</div>
						}
					</div>
					<div className="retro-card">
						<h3 className="mb2">
							Credit Note
						</h3>
						{
							invoice.creditNote==="PENDING" && "Credit Note not generated."
						}
						{invoice.creditNote!=="PENDING"&&<Button onClick={()=>{
							post(`/meta/${transId}/creditNote/regenerate`,{},(e,r)=>{
								if(e){
									TOAST.handleError(e)
								}else{
									TOAST.success("Queued successfully")
								}
							})
						}} className="btn btn-black btn-sm">
							Regenerate Voucher
						</Button>}
					</div>
					<div className="retro-card">
						<h3 className="mb2">
							Cancellation Charges
						</h3>
						{
							!invoice.cancellationFare && "No cancellation charges."
						}
						{
							invoice.cancellationFare && <div className="flex flex-wrap">
								<div className="mr2">
									<KeyValue title="Base Fare + Non Taxable" value={`${parseInt(invoice.cancellationFare.base,10)} base fare + ${parseInt(invoice.cancellationFare.oth,10)} other charges`}/>
									<KeyValue title="Gst Slab" value={invoice.cancellationFare.slab}/>
									<KeyValue title="Gst" value={`${parseInt(invoice.cancellationFare.cGst,10)} cgst + ${parseInt(invoice.cancellationFare.sGst,10)} sgst + ${parseInt(invoice.cancellationFare.iGst,10)} igst`}/>
									<KeyValue title="Total Charges" value={parseInt(invoice.cancellationFare.total,10)}/>
								</div>
							</div>
						}
					</div>
				</div>
			</div>
			{
				mail !== undefined &&
					<SidePane width={750} title="Mail Details" description="Details of mail appears here" onClose={()=>{setMail(undefined)}}>
						<div className="mt2 mb2">
							<div className="border pd2">
								<KeyValue title="From" value={mail.from}/>
								<KeyValue title="To" value={mail.to}/>
							</div>
							<div className="border pd2">
								<h2 className="fw-bold">
									Subject:
								</h2>
								<p>
									{mail.subject}
								</p>
							</div>
							<div className="mt2">
								<Letter html={mail.eml.html} text={mail.text}/>
							</div>
						</div>
					</SidePane>
			}
		</div>
	)
}




