import React, {useCallback, useEffect, useState} from 'react'
import {useParams} from "react-router-dom";
import {get} from "../../../../Network/Axios";
import {
	Button,
	Heading,
	Loader,
	ScrollableDynamicTable as DynamicTable,
	SidePane,
	Text,
	TOAST as Alert
} from "../../../../../retro";
import Moment from "moment/moment";
import ReactJson from 'react-json-view'
import _ from 'lodash'

export default function ApiLogs() {
	let [loading, setLoading] = useState(true);
	const [modal,setModal] = useState(undefined)
	const [apiData,setApiData] = useState({
		loading:true
	})
	const {id,type} = useParams();
	const [search, setSearch] = useState({
		page: 0,
		sort: 'id',
		order: 'desc'
	})
	const [Data, SetData] = useState({
		total: 0,
		currentPage: 0,
		totalPages: 0,
		items: []
	})
	useEffect(()=>{
		if(modal){
			get(`/apis/${id}/${type.toUpperCase()}/${modal}/details`,(e,r)=>{
				if(r){
					setApiData({
						...r,
						loading:false
					})
				}else{
					Alert.handleError(e)
				}
			})
		}
	},[modal])
	const loadPage = useCallback((search) => {
		get(`/apis/${id}/${type}/logs`, (e, r) => {
			if (r) {
				SetData({
					...r,
					total: r.total||Data.total,
					currentPage: r.currentPage||Data.currentPage,
					totalPages: r.totalPages||Data.totalPages,
				})
				setLoading(false)
			} else {
				Alert.handleError(e)
			}
		}, {
			offset: search.page,
			sort: search.sort,
			order: search.order,
		})
	}, [SetData,Data,id,type])
	useEffect(() => {
		loadPage(search)
	}, [search])
	const response = () => {
		try{
			return (
				<div>
					<Heading className="mt3 mb3">
						Response
					</Heading>
					<ReactJson src={JSON.parse(apiData.response)}/>
				</div>
			)
		}catch (e){
			return (
				<div>
					<Heading className="mt3 mb3">
						Response
					</Heading>
					<ReactJson src={{
						response:apiData.response?apiData.response:"No Response"
					}}/>
				</div>
			)
		}
	}
	return (
		<div className="FadeInLeft-Effect">
			{
				loading ?
					<Loader/> :
					<>
						<DynamicTable
							setSearch={(search) => {
								setSearch({
									...search,
									page: 0
								})
							}} search={search}
							setPage={page => {
								setSearch({
									...search,
									page
								})
							}}
							data={{
								current: Data.currentPage,
								total: Data.totalPages,
								items: Data.items.map(item => {
									const click = () => {
										setModal(item.id)
									}
									return {
										content: item,
										render: [
											{
												children: (
													<div onClick={click}>
														<p className="text">
															#{item.id}
														</p>
														<p className="text-small text-light">
															Initiated on {Moment(item.createdAt).format("DD MMM YYYY HH:mm")}
														</p>
													</div>
												),
												weight: 2,
											},
											{
												weight: 1,
												title: item.credit
											},
											{
												weight: 1,
												title: item.code
											},
											{
												weight: 1,
												title: `${item.latency} ms`
											},
											{
												weight: 1,
												children: (<>
														<Button className="btn-sm btn-secondary" onClick={click}>
															View
														</Button>
													</>)
											}
										]
									}
								})
							}} headers={[
							{
								weight: 2,
								title: "Details",
							},
							{
								weight: 1,
								title: "Code",
							},
							{
								weight: 1,
								title: "Credit",
							},
							{
								weight: 1,
								title: "Latency",
							},
							{
								weight: 1,
								title: "Actions",
							}
						]}/>
					</>
			}
			{
				modal&&<SidePane enableClipboard={false} collapsed={true} loading={apiData.loading} onClose={()=>{setModal(undefined)}} title="Api Details" description="api details of selected element" >
					{
						!apiData.loading&&<div>
							<Heading className="relative mb3 mt3">
								Path
							</Heading>
							<Text>
								{
									apiData.path
								}
							</Text>
							<Heading className="relative mb3 mt3">
								Request
								{
									_.includes(['EMAIL'],type.toUpperCase())&&<div style={{
										position:'absolute',
										right:0,
										top:-10
									}}>
										<Button className="btn-link">
											<i className="fas fa-undo"/>  Reinit Trigger
										</Button>
									</div>
								}
							</Heading>
							<ReactJson src={JSON.parse(apiData.request)}/>
							{response()}
						</div>
					}
				</SidePane>
			}
		</div>
	)
}
