import useConfigHook from "../useConfigHook";
import _ from 'lodash'
import DashboardIcon from './dashboard.svg'
import Admin from './admin.svg'
import Client from './client.svg'
import React from "react";

export default function useSidebarConfig(){
	const ITEMS = [];
	const CONFIG = useConfigHook();
	console.log(CONFIG)
	if(!CONFIG.loading){
		ITEMS.push({
			title:'Home',
			items:[
				{
					name:"Dashboard",
					path:"/app/dashboard",
					type:"includes",
					icon:(<img style={{width:20,height:20}} className="sidebar-icon" alt="icon" src={DashboardIcon}/>),
				},
				{
					name:"Admins",
					path:"/app/admins",
					type:"includes",
					icon:(<img style={{width:20,height:20}} className="sidebar-icon" alt="icon" src={Client}/>),
				},
			]
		})

		ITEMS.push({
			title:"Origin",
			items:[
				{
					name:"Clients",
					path:"/app/clients",
					type:"includes",
					icon:(<img style={{width:20,height:20}} className="sidebar-icon" alt="icon" src={Admin}/>),
					items:[
						{
							name:"All Clients",
							path:'/app/clients',
							type:'equals'
						},
						{
							name:"New Client",
							path:"/app/clients/new"
						}
					]
				}
			]
		})
		const APPS = {
			title:"Apps",
			items:[
				{
					name:"Meta Apis",
					path:"/app/apps/meta",
					type:"includes",
					icon:<img className="topbar-icon" alt="icon" src={DashboardIcon}/>,
				}
			]
		}
		if(_.includes(CONFIG.apps,'PERKS') || window.origin.includes("localhost")){
			APPS.items.push(
				{
					name:"Contracted Hotels",
					path:"/app/apps/contracted",
					type:"includes",
					icon:<img className="topbar-icon" alt="icon" src={DashboardIcon}/>,
				})
			APPS.items.push({
				name:"Perks",
				path:"/app/apps/perks",
				type:"includes",
				icon:<img className="topbar-icon" alt="icon" src={DashboardIcon}/>,
			})
		}
		ITEMS.push(APPS)
	}
	return ITEMS;
}
