import React from 'react'
import {ExpandableSidebar, NotFound, Wrapper} from "../../../../../retro";
import AllHotels from './AllHotels'
import OnBoarded from './Onboarded'
import AddHotel from './AddHotel'
import LinkHotel from './LinkHotel'
import BulkUpload from "./BulkUpload";
import {Route, Switch, useHistory, useLocation} from "react-router-dom";
import QueuesIcon from "@atlaskit/icon/glyph/queues";
import PeopleGroupIcon from "@atlaskit/icon/glyph/people-group";

export default function route() {
	return (
		<Switch>
			<Route component={index} path="/app/apps/contracted/link"/>
			<Route component={index} path="/app/apps/contracted/new"/>
			<Route component={index} path="/app/apps/contracted/bulk"/>
			<Route component={OnBoarded} path="/app/apps/contracted/:id"/>
			<Route component={index} path="/app/apps/contracted"/>
		</Switch>
	)
}

function index() {
	const tabs = [];
	tabs.push({
		title: "All Hotel",
		path: "/app/apps/contracted"
	})
	tabs.push({
		title: "Link Hotel",
		path: "/app/apps/contracted/new"
	})
	tabs.push({
		title: "Bulk Upload",
		path: "/app/apps/contracted/bulk"
	})
	tabs.push({
		title: "Create Hotel Shell",
		path: "/app/apps/contracted/link"
	})
	const items = []
	const HISTORY = useHistory();
	const Location = useLocation();
	items.push({
		title: "Contracted Hotels",
		actions: [
			{
				title: "All Hotel",
				icon: (<QueuesIcon size="small"/>),
				active: Location.pathname === '/app/apps/contracted',
				onClick: () => {
					HISTORY.push('/app/apps/contracted')
				}
			},
			{
				title: "Link Hotel",
				icon: (<PeopleGroupIcon size="small"/>),
				active: Location.pathname.includes('/app/apps/contracted/new'),
				onClick: () => {
					HISTORY.push('/app/apps/contracted/new')
				}
			},
			{
				title: "Bulk Upload",
				icon: (<PeopleGroupIcon size="small"/>),
				active: Location.pathname.includes('/app/apps/contracted/bulk'),
				onClick: () => {
					HISTORY.push('/app/apps/contracted/bulk')
				}
			},
			{
				title: "Create Hotel Shell",
				icon: (<PeopleGroupIcon size="small"/>),
				active: Location.pathname.includes('/app/apps/contracted/link'),
				onClick: () => {
					HISTORY.push('/app/apps/contracted/link')
				}
			},
		]
	})
	return (
		<div className="flex h-100h w-100">
			<ExpandableSidebar items={items}/>
			<Wrapper>
				<Switch>
					<Route component={AllHotels} path="/app/apps/contracted" exact={true}/>
					<Route component={AddHotel} path="/app/apps/contracted/new" exact={true}/>
					<Route component={LinkHotel} path="/app/apps/contracted/link" exact={true}/>
					<Route path="/app/apps/contracted/bulk" exact>
						<BulkUpload/>
					</Route>
					<Route path="/app/apps/contracted/:id" exact>
						<OnBoarded/>
					</Route>
					<NotFound/>
				</Switch>
			</Wrapper>
		</div>
	)
}
