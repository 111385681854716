import Common,{Common2,placeOfSupply,sellerAddress} from "./Common";
import Moment from "moment";

const roundOff = num => {
	return (Math.round(num * 100) / 100).toFixed(2);
}


let traveller = (item) => {
	let string = undefined;
	try {
		item.ticket.travellers.forEach((traveller) => {
			if (!string) {
				string = `${traveller.name}`;
			} else {
				string = `${string} , ${traveller.name}`;
			}
		});
	}catch (e){
		return "-";
	}
	return string;
};


// Checks whether the booking is International based on the segments
const isInternationalHotel = (booking) =>{
	// gets the criteria array from the ticket
	const criteria = booking?.ticket?.criteria;
	// if no criteria object retrun false
	if(!criteria){
		return false;
	}
	const country = criteria.country.toUpperCase();
	return country !== "INDIA";
}


const getMealPlan = (booking) =>{
	// list of allowed meal plans
	const MEALPLANS = [
		'Room Only',
		'Continental Plan',
		'Modified American Plan',
		'American Plan'
	]
	if(booking?.ticket?.mealPlan){
		return booking.ticket.mealPlan;
	}
	let roomdesc = booking?.ticket?.roomDescription || "";
	if(roomdesc){
		roomdesc = roomdesc.split("<")[0];
		if(roomdesc && MEALPLANS.includes(roomdesc)){
			return roomdesc;
		}
	}
	return "-"
}

export default function offline(item,dates){
	console.log(item)
	let values = []
	values.push(...Common(item,dates))
	values.push(
		...[
			'Flight Amendment',
			'-',
			'-',
			'-',
			'-',
			'-',
			'-',
			'-',
			'-',
			'-',
			'-',
			'-',
			'-',
			'-',
			'-',
			'-',
			'-',
			'-',
			'-',
			'-',
			'-',
			'-',
			'-',
			'-',
			'-',
			'-',
			'-',
		]
	)
	let settlement = item.item;
	let transId = item?.transId;
	values.push(...[
		'Yes',
		settlement.config.serviceIrn?'Generated':'Pending',
		'Management Fee',
		settlement.config.serviceInvoiceNo|| "-",
		settlement.config.serviceChargeDate|| "-",
		settlement.config.serviceIrn || "-",
		settlement.config.serviceInvoice|| "-",
		'-',
		roundOff(settlement.config.serviceFare?.base || 0),
		"-",
		roundOff(settlement.config.serviceFare?.slab || 0),
		roundOff(settlement.config.serviceFare?.iGst || 0),
		roundOff(settlement.config.serviceFare?.sGst || 0),
		roundOff(settlement.config.serviceFare?.cGst || 0),
		roundOff(settlement.config.serviceFare?.total || 0),
		"-",

		settlement.config.managementServiceGst?.gstin||settlement.config.sellerGst?.gstin||"-",
		settlement.config.managementServiceGst?.address?sellerAddress(settlement.config.managementServiceGst):settlement.config.sellerGst?.address?sellerAddress(settlement.config.sellerGst):"-",
		settlement.config.managementUserGst?.gstin||settlement.config.userGst?.gstin||"-",
		settlement.config.managementUserGst?.address||settlement.config.userGst?.address||"-",

		'Maharashtra',
		placeOfSupply(settlement.config.userGst?.gstin||"-"),
		'998551',
		`MFI - ${transId}`,
	]);
	return values;
}
