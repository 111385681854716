import React, {useState} from 'react'
import Routes from './routes'
import BosFile from './BosFile'
import {Route, Switch} from "react-router-dom";

export default function index() {
	return (
		<Switch>
			<Route path="/app/apps/meta/train" exact>
				<Routes/>
			</Route>
			<Route path="/app/apps/meta/train/bos" exact>
				<BosFile/>
			</Route>
		</Switch>
	)
}
