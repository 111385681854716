import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  DateRange,
  NavDivider,
  Navigation,
  ScrollableDynamicTable,
  TOAST as Alert,
} from 'retro';
import { get } from 'App/Network/Axios';
import Moment from 'moment';
import { DownloadCsv } from 'retro/Utils';
import RBAC from 'App/HOCs/RBAC';
import ROLES from 'App/Constants/Roles';

export default function PaytmPg({}) {
  const [Dates, setDates] = useState(undefined);
  const [Data, SetData] = useState({
    total: 0,
    currentPage: 0,
    totalPages: 0,
    items: [],
  });
  const loadPage = useCallback(
    (search) => {
      get(
        `/meta/mis/paytmPg`,
        (e, r) => {
          if (r) {
            SetData({
              ...r,
              total: r.total ? r.total : Data.total,
              currentPage: r.currentPage,
              totalPages: r.totalPages ? r.totalPages : Data.totalPages,
            });
          } else {
            Alert.handleError(e);
          }
        },
        {
          start: search.startDate,
          end: search.endDate,
          offset: search.page,
        }
      );
    },
    [Data, SetData]
  );
  useEffect(() => {
    if (Dates && Dates.startDate) {
      loadPage(Dates);
    }
  }, [Dates]);
  return (
    <>
      <Navigation
        backUrl={'back'}
        title='Paytm Pg'
        chips={['Mis', 'Pg']}
        description='All pg related logs are listed below.'
      />
      <div className='flex horizontally center-vertically'>
        <div className='flex-1'>
          <DateRange
            defaultValue={{
              startDate: Moment().add(-31, 'd').valueOf(),
              endDate: Moment().valueOf(),
            }}
            onChange={(dates) => {
              setDates({
                ...dates,
                page: 0,
              });
            }}
            className='mt2'
            label=''
            type='range'
          />
        </div>
      </div>
      <NavDivider />
      <div className='mt4'>
        <div className='relative'>
          <RBAC role={ROLES.REPORTS}>
            <div className='flex horizontally center-vertically'>
              <div className='flex-1 flex horizontally'>
                <Button
                  onClick={() => {
                    get(
                      `/meta/mis/paytmPg/download`,
                      (e, r) => {
                        if (r) {
                          let data = [];
                          let headers = [
                            'Order Id',
                            'Amount',
                            'Pg Amount',
                            'Refund Amount',
                            'Client Name',
                            'Client Code',
                            'Status',
                            'Created On',
                            'Payment Id',
                            'Payment Method',
                            'Currency',
                            'Bank',
                            'RRN',
                            'Vpa',
                            'Fee',
                            'Tax',
                            'Network',
                            'Type',
                            'Issuer',
                            'last4',
                            'Refund ID',
                            'Refund Speed Requested',
                            'Refund Speed Processed',
                            'Provider Id',
                            'Meta Id',
                            'Reference Id',
                          ];
                          data.push(headers);
                          r.items.map((item) => {
                            let payment = JSON.parse(
                              item?.attrs?.payment || '{}'
                            );
                            let refund = JSON.parse(
                              item?.attrs?.refund || '{}'
                            );
                            data.push([
                              item.hexId,
                              item.amount,
                              item.pgAmount,
                              item.refundAmount || '0',
                              item.client.clientName,
                              item.client.clientCode,
                              item.status,
                              Moment(item.createdAt).format(
                                'DD MMM YYYY HH:mm a'
                              ),
                              payment?.id || '-',
                              payment?.method || '-',
                              payment?.currency || '-',
                              payment?.bank || '-',
                              payment?.acquirer_data?.rrn || '-',
                              payment?.vpa || '-',
                              payment?.fee || '-',
                              payment?.tax || '-',
                              payment?.card?.network || '-',
                              payment?.card?.type || '-',
                              payment?.card?.issuer || '-',
                              payment?.card?.last4 || '-',
                              refund?.id || '-',
                              refund?.speed_requested || '-',
                              refund?.speed_processed || '-',
                              item.attrs?.providerId || '-',
                              item.attrs?.metaId || '-',
                              item.attrs?.referenceId || '-',
                            ]);
                          });
                          DownloadCsv(`Paytm_pg.csv`, data);
                        } else {
                          console.log(e);
                          Alert.handleError(e);
                        }
                      },
                      {
                        start: Dates.startDate,
                        end: Dates.endDate,
                      }
                    );
                  }}
                  className='btn btn-black'
                  margin=''
                >
                  Download Data
                </Button>
              </div>
            </div>
          </RBAC>

          <ScrollableDynamicTable
            setPage={(page) => {
              setDates({
                ...Dates,
                page,
                total: Dates.totalPages,
              });
            }}
            width={175}
            search={Dates}
            tableStyle={{
              borderSpacing: 0,
            }}
            data={{
              current: Data.currentPage,
              total: Data.totalPages,
              items: Data.items.map((item) => {
                let payment = JSON.parse(item?.attrs?.payment || '{}');
                let refund = JSON.parse(item?.attrs?.refund || '{}');
                return {
                  content: item,
                  render: [
                    item.queueId,
                    item.hexId,
                    item.amount,
                    item.pgAmount,
                    item.refundAmount || '0',
                    item.client.clientName,
                    item.client.clientCode,
                    item.status,
                    Moment(item.createdAt).format('DD MMM YYYY HH:mm a'),
                    payment?.id || '-',
                    payment?.method || '-',
                    payment?.currency || '-',
                    payment?.bank || '-',
                    payment?.acquirer_data?.rrn || '-',
                    payment?.vpa || '-',
                    payment?.fee || '-',
                    payment?.tax || '-',
                    payment?.card?.network || '-',
                    payment?.card?.type || '-',
                    payment?.card?.issuer || '-',
                    payment?.card?.last4 || '-',
                    refund?.id || '-',
                    refund?.speed_requested || '-',
                    refund?.speed_processed || '-',
                    item.attrs?.providerId || '-',
                    item.attrs?.metaId || '-',
                    item.attrs?.referenceId || '-',
                  ].map((title) => ({
                    weight: 2,
                    title,
                  })),
                };
              }),
            }}
            headers={[
              'Id',
              'Order Id',
              'Amount',
              'Pg Amount',
              'Refund Amount',
              'Client Name',
              'Client Code',
              'Status',
              'Created On',
              'Payment Id',
              'Payment Method',
              'Currency',
              'Bank',
              'RRN',
              'Vpa',
              'Fee',
              'Tax',
              'Network',
              'Type',
              'Issuer',
              'last4',
              'Refund ID',
              'Refund Speed Requested',
              'Refund Speed Processed',
              'Provider Id',
              'Meta Id',
              'Reference Id',
            ].map((title) => ({
              weight: 2,
              title,
            }))}
          />
        </div>
      </div>
    </>
  );
}
