import Common,{Common2,placeOfSupply,sellerAddress} from "./Common";
import Moment from "moment";

const roundOff = num => {
	return (Math.round(num * 100) / 100).toFixed(2);
}


let traveller = (item) => {
	let string = undefined;
	try {
		item.ticket.travellers.forEach((traveller) => {
			if (!string) {
				string = `${traveller.name}`;
			} else {
				string = `${string} , ${traveller.name}`;
			}
		});
	}catch (e){
		return "-";
	}
	return string;
};


// Checks whether the booking is International based on the segments
const isInternationalHotel = (booking) =>{
	// gets the criteria array from the ticket
	const criteria = booking?.ticket?.criteria;
	// if no criteria object retrun false
	if(!criteria){
		return false;
	}
	const country = criteria.country.toUpperCase();
	return country !== "INDIA";
}


const getMealPlan = (booking) =>{
	// list of allowed meal plans
	const MEALPLANS = [
		'Room Only',
		'Continental Plan',
		'Modified American Plan',
		'American Plan'
	]
	if(booking?.ticket?.mealPlan){
		return booking.ticket.mealPlan;
	}
	let roomdesc = booking?.ticket?.roomDescription || "";
	if(roomdesc){
		roomdesc = roomdesc.split("<")[0];
		if(roomdesc && MEALPLANS.includes(roomdesc)){
			return roomdesc;
		}
	}
	return "-"
}

export default function hotelLine(item,dates){
	let nights = 1;
	if (item.ticket && item.ticket.criteria) {
		nights = Moment(item.ticket.criteria?.dates?.out?item.ticket.criteria.dates.out:item.ticket.date.out, 'DD-MM-YYYY').diff(
			Moment(item.ticket.criteria?.dates?.in?item.ticket.criteria.dates.in:item.ticket.date.in, 'DD-MM-YYYY'),
			'days'
		);
	}
	let values = []
	values.push(...Common(item,dates))
	try {
		values.push(
			...[
				'Hotel',
				item.ticket.name,
				traveller(item),
				'-',
				item.ticket.name,
				item.ticket?.category || "-",
				item.ticket.address,
				getMealPlan(item),
				item.ticket.city,
				item.ticket.city,
				isInternationalHotel(item) ? "International" : "Domestic",
				(item.item.config.isInternational || item.ticket.roomId) ? 'Non Contracted' : 'Contracted',
				item.ticket?.bookingId||"-",
				Moment(item.ticket.date.in, 'DD-MM-YYYY').format('DD MMM YYYY'),
				Moment(item.ticket.date.out, 'DD-MM-YYYY').format(
					'DD MMM YYYY'
				),
				item.ticket.criteria?.rooms?.length || 1,
				nights,
				'-',
				'-',
				'-',
				'-',
				'-',
				'-',
				'-',
				'-',
				'-',
				'-',
			]
		)
	}catch (e){

	}
	let settlement = item.item;
	let transId = item?.transId;
	values.push(...[
		'Yes',
		settlement.config.serviceIrn?'Generated':'Pending',
		'Management Fee',
		settlement.config.serviceInvoiceNo|| "-",
		settlement.config.serviceChargeDate|| "-",
		settlement.config.serviceIrn || "-",
		settlement.config.serviceInvoice|| "-",
		'-',
		roundOff(settlement.config.serviceFare?.base || 0),
		"-",
		roundOff(settlement.config.serviceFare?.slab || 0),
		roundOff(settlement.config.serviceFare?.iGst || 0),
		roundOff(settlement.config.serviceFare?.sGst || 0),
		roundOff(settlement.config.serviceFare?.cGst || 0),
		roundOff(settlement.config.serviceFare?.total || 0),
		"-",
		//managementServiceGst managementUserGst
		settlement.config.managementServiceGst?.gstin||settlement.config.sellerGst?.gstin||"-",
		settlement.config.managementServiceGst?.address?sellerAddress(settlement.config.managementServiceGst):settlement.config.sellerGst?.address?sellerAddress(settlement.config.sellerGst):"-",
		settlement.config.managementUserGst?.gstin||settlement.config.userGst?.gstin||"-",
		settlement.config.managementUserGst?.address||settlement.config.userGst?.address||"-",
		'Maharashtra',
		placeOfSupply(settlement.config.userGst?.gstin||"-"),
		'998551',
		`MFI - ${transId}`,
	]);
	values.push(...Common2(item,settlement.config.serviceInvoiceNo))
	return values;
}
