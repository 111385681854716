import React from 'react';
import Bank from './Bank';
import TMC from './TMC';
import ROLES from 'App/Constants/Roles';
import useConfigHook from 'App/Hooks/useConfigHook';
import RBAC from 'App/HOCs/RBAC';
import CLRTRP from './CLRTRP'

export default function CreateClient() {
  const CONFIG = useConfigHook();
  console.log(CONFIG);
  if (CONFIG.origin.code === 'YESUAT' || CONFIG.origin.code==='YES-PROD') {
    return (
      <RBAC role={ROLES.CLIENTS_WRITE} renderNoAccess>
        <Bank />
      </RBAC>
    );
  }
  // if (CONFIG.origin.code === 'CLRTRP') {
  //   return (
  //       <RBAC role={ROLES.CLIENTS_WRITE} renderNoAccess>
  //         <CLRTRP/>
  //       </RBAC>
  //   );
  // }
  return (
    <RBAC role={ROLES.CLIENTS_WRITE} renderNoAccess>
      <TMC />
    </RBAC>
  );
}
