import { ExpandableSidebar, Wrapper } from 'retro';
import AdminApprovals from './AdminApprovals';
import PastApprovals from './PastApprovals';
import AllApprovals from './AllApprovals';
import DeclinedApprovals from './DeclinedApprovals';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import ROLES from 'App/Constants/Roles';
import RBAC from 'App/HOCs/RBAC';

export default function Approval() {
  const History = useHistory();
  const location = useLocation();
  return (
    <RBAC role={ROLES.APP} renderNoAccess>
      <div className='flex h-100h w-100'>
        <ExpandableSidebar
          items={[
            {
              title: 'Actions',
              actions: [
                {
                  title: 'All Approvals',
                  active: location.pathname === '/app/approvals/all',
                  onClick: () => {
                    History.push('/app/approvals/all');
                  },
                },
                {
                  title: 'Pending Approvals',
                  active: location.pathname === '/app/approvals',
                  onClick: () => {
                    History.push('/app/approvals');
                  },
                },
                {
                  title: 'Declined Approvals',
                  active: location.pathname === '/app/approvals/declined',
                  onClick: () => {
                    History.push('/app/approvals/declined');
                  },
                },
                {
                  title: 'Approved Approvals',
                  active: location.pathname === '/app/approvals/approved',
                  onClick: () => {
                    History.push('/app/approvals/approved');
                  },
                },
              ],
            },
          ]}
        />
        <Switch>
          <Route path='/app/approvals/all' exact>
            <Wrapper>
              <AllApprovals />
            </Wrapper>
          </Route>
          <Route path='/app/approvals' exact>
            <Wrapper>
              <AdminApprovals />
            </Wrapper>
          </Route>
          <Route path='/app/approvals/declined' exact>
            <Wrapper>
              <DeclinedApprovals />
            </Wrapper>
          </Route>
          <Route path='/app/approvals/approved' exact>
            <Wrapper>
              <PastApprovals />
            </Wrapper>
          </Route>
        </Switch>
      </div>
    </RBAC>
  );
}
