import React, {useEffect, useState} from 'react'
import {Loader, Navigation, Wrapper} from "../../../../../../retro";
import {useParams} from "react-router-dom";
import {get} from "../../../../../Network/Axios";

export default function index() {
    const {transId} = useParams()
    const [data,setData] = useState({
        loading:true
    })
    useEffect(()=>{
        get(`/personal/${transId}/details`,(e,r)=>{
            if(r){
                setData(r)
            }
        })
    },[])
    if(data.loading){
        return (<Loader/>)
    }
	return (
        <Wrapper className="w-sidebar-minus-two" id="wrapper-nav">
            <Navigation title="Booking Details" chips={["Perks","Bookings",transId]} description="Details of booking appears below."/>

        </Wrapper>
	)
}
