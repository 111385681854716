import React from 'react';
import {Navigation} from '../../../../retro';
import useConfigHook from '../../../Hooks/useConfigHook';

function Dashboard() {
    const CONFIG = useConfigHook();
    return (
        <div>
             <Navigation title={`Welcome ${CONFIG.name}`} chips={["Dashboard"]}/>
            <div className="mt4 pd4 border rounded-md">
                Yet to be configured as per origin requirement.
            </div>
        </div>
    );
}

export default Dashboard;
