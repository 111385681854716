import React, {useEffect, useRef, useState} from 'react'
import {
	Attachment,
	Button,
	Input,
	KeyValue,
	Loader, Modal,
	Seperator,
	SidePane,
	TOAST as Alert,
	TOAST
} from "../../../../../retro";
import {get, post} from "../../../../Network/Axios";
import SectionMessage from "@atlaskit/section-message";

export default function ViewRequest({id, onClose}) {
	const [data, setData] = useState({
		status: "LOADING"
	})
	const [decline,setDecline] = useState(false)
	const REMARKS = useRef();
	const AMOUNT = useRef();
	const [txn,setTxn] = useState(undefined)
	const load = id => {
		get(`/meta/hotel/queue/refund/${id}/details`, (e, r) => {
			if (r) {
				setData(r)
				if(r.transId){
					loadTxn(r.transId)
				}
			} else {
				Alert.handleError(e)
			}
		})
	}
	const loadTxn = id => {
		get(`/meta/${id}/details`,(e,r)=>{
			if(r){
				setTxn(r)
			}else{
				TOAST.handleError(e)
			}
		})
	}
	useEffect(() => {
		load(id)
	}, [id])
	if (data.status === "LOADING" || !txn) {
		return (
			<SidePane title="Hotel Refund Request" description="Details of this refund request appears below."
					  onClose={onClose}>
				<Loader/>
			</SidePane>)
	}
	return (
		<SidePane preview={<div className="pd4" style={{width: 475}}>

			{
				decline && <Modal onClose={()=>{setDecline(false)}} title="Decline Remarks" description="Enter your decline remarks">
					<Input label="Remarks" placeholder="Enter your remarks" ref={REMARKS}/>
					<Button onClick={()=>{
						let remarks = REMARKS.current.value;
						if(!remarks){
							TOAST.error("Enter a valid remarks")
							return
						}
						post(`/meta/hotel/queue/refund/${id}/cancel`,{remarks}, (e, r) => {
							if (r) {
								load(id)
								TOAST.success("Ticket declined successfully!")
								setDecline(false)
								onClose()
							} else {
								Alert.handleError(e)
							}
						})
					}}>
						Cancel
					</Button>
				</Modal>
			}
			<h3 className="fw-bold">
				Refund Request
			</h3>
			<p className="mb4">
				Details of this hotel request appears below.
			</p>
			<KeyValue title="Booking Id" value={data.transId}/>
			<KeyValue title="Reference Id" value={data.referenceId}/>
			<KeyValue title="Pnr" value={txn.ticket.pnr}/>
			<div className="flex mt2 horizontally center-vertically">
				<KeyValue title="Client Code" value={data?.client?.clientCode||"-"}/>
				<KeyValue title="Status" value={data.status}/>
			</div>
			<Seperator margin={2}/>
			<div className="mt2">
				<p className="fw-bold">
					{data.hotel.name}
				</p>
				<p>
					{data.hotel.address}
				</p>
				<p>
					{data.hotel.city}
				</p>
			</div>
			<Seperator margin={2}/>
			<div className="flex mt2 horizontally center-vertically">
				<KeyValue title="Room Name" value={data.room.name}/>
				<KeyValue title="Meal Type" value={data.room.mealType}/>
				<KeyValue title="Cancellation Policy" value={txn.ticket.cancellationPolicy}/>
			</div>
			<Seperator margin={2}/>
			<p className="mb2">Search Criteria</p>
			<KeyValue title="City" value={txn.ticket.city}/>
			<KeyValue title="Address" value={txn.ticket.address}/>
			<div className="flex horizontally center-vertically">
				<KeyValue title="Check In" value={txn.ticket.date.in}/>
				<KeyValue title="Check Out" value={txn.ticket.date.out}/>
			</div>
			<KeyValue title="Number Of Rooms" value={txn.ticket.criteria.rooms.length}/>
			<Seperator margin={2}/>
			<KeyValue title="Remarks" value={data.remarks}/>
			<KeyValue title="Booking Amount" value={data.amount}/>
		</div>} title="Hotel Refund Request" description="Details of the hotel refund request appears below." onClose={onClose}>
			<div className="mb2 mt2">
				<SectionMessage title={data.admin.adminName}>
					<p>
						This ticket is currently handled by {data.admin.adminEmail}
					</p>
					<div className="pt2">
						<p onClick={()=>{
							post(`/meta/hotel/queue/refund/${id}/self`,{}, (e, r) => {
								if (r) {
									load(id)
									TOAST.success("Ticket assigned to you.")
								} else {
									Alert.handleError(e)
								}
							})
						}} className="btn-link btn">
							Assign To Yourself
						</p>
					</div>
				</SectionMessage>
			</div>
			<Input ref={AMOUNT} type="text" label="Refund Amount" placeholder="Enter refund amount"/>
			<div className="flex horizontally center-vertically mt4 ">
				<Button onClick={()=>{
					let amount = parseInt(AMOUNT.current.value,10);
					post(`/meta/hotel/queue/refund/${id}/confirm`,{amount}, (e, r) => {
						if (r) {
							load(id)
							TOAST.success("Ticket confirmed successfully!")
							onClose()
						} else {
							Alert.handleError(e)
						}
					})
				}} className="btn-black btn-primary" margin="">
					Confirm
				</Button>
				<Button onClick={()=>{
					setDecline(true)
				}} className="btn btn-danger" margin="ml2">
					Decline
				</Button>
			</div>
			<Seperator margin={4}/>
			<p className="mb2">
				Attachments:
			</p>
			<Attachment url={txn.invoice.file}/>
		</SidePane>
	)
}
