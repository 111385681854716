import React, { useCallback, useEffect, useState } from 'react';
import {
  Loader,
  ScrollableDynamicTable,
  SearchBox,
  TOAST as Alert,
  Navigation,
  Button,
} from 'retro';
import { get } from 'App/Network/Axios';
import OpenIcon from '@atlaskit/icon/glyph/open';
import RefundRequest from './RefundRequest';
import moment from 'moment';
import { DownloadCsv } from 'retro/Utils';
import RBAC from 'App/HOCs/RBAC';
import ROLES from 'App/Constants/Roles';
import Filter from 'retro/Filter';
import FilterDropDown from 'App/Components/Filter/FilterDropDown';

const Refund = () => {
  let [loading, setLoading] = useState(true);
  const [id, setId] = useState(undefined);
  const [filterModal,setFilterModal] = useState(false);
  const [filter, setFilter] = useState({
		client: [],
		traveldate: []
	});
  const [search, setSearch] = useState({
    page: 0,
    sort: 'id',
    order: 'desc',
  });
  const [Data, SetData] = useState({
    total: 0,
    currentPage: 0,
    totalPages: 0,
    items: [],
  });
  // const loadPage = useCallback(
  //   (search) => {
  //     get(
  //       `/meta/visa/declined`,
  //       (e, r) => {
  //         if (r) {
  //           SetData({
  //             ...r,
  //             total: r.total || Data.total,
  //             totalPages: r.totalPages || Data.totalPages,
  //           });
  //           setLoading(false);
  //         } else {
  //           Alert.handleError(e);
  //         }
  //       },
  //       {
  //         offset: search.page,
  //         order: search.order,
  //         q: search.q,
  //       }
  //     );
  //   },
  //   [Data, SetData]
  // );
  const loadPage = useCallback(
    (search) => {

      const clientIds = filter.client.map(client => client.id);

      get(
        `/meta/visa/declined`,
        (e, r) => {
          if (r) {
            SetData({
              ...r,
              total: r.total || Data.total,
              totalPages: r.totalPages || Data.totalPages,
            });
            setLoading(false);
          } else {
            Alert.handleError(e);
          }
        },
        {
          offset: 0,
          order: search.order,
          q: search.q,
          sort: search.sort,
          start: filter.traveldate.length > 0 ?
					filter.traveldate[0] === 'Upcoming 24 hours' ?
						moment().valueOf().toString() : moment().add(24, 'hours').valueOf().toString()
					: undefined,
			end: filter.traveldate.length > 0 ?
				filter.traveldate[0] === 'Upcoming 24 hours' ? moment().add(24, 'hours').valueOf().toString() : moment().add(48, 'hours').valueOf().toString()
				: undefined,
			clientIds: clientIds.length > 0 ? clientIds : undefined
        }
      );
    },
    [Data, SetData,filter.traveldate, filter.client]
  );
  
  useEffect(() => {
    loadPage(search);
  }, [search,filter.traveldate, filter.client]);
  if (loading) {
    return <Loader />;
  }
  return (
    <div>
      <div className="mb2 relative">
        <Navigation title="Request History" />
        <RBAC role={ROLES.REPORTS}>
          <Button
            onClick={() => {
              setLoading(true);
              get(`/meta/train/refunding/all`, (e, r) => {
                if (r) {
                  let data = [];
                  const headers = [
                    'Transaction Id',
                    'Reference Id',
                    'Origin City',
                    'Destination City',
                    'Train Name',
                    'Train Number',
                    'Train PNR',
                    'Request Date',
                  ];
                  data.push(headers);
                  r.items.forEach((item) => {
                    let line = [];
                    line.push([item.transId]);
                    line.push([item.referenceId]);
                    line.push([item.ticket?.originCity || '-']);
                    line.push([item.ticket?.destinationCity || '-']);
                    line.push([item.ticket?.trainName || '-']);
                    line.push([item.ticket?.trainNumber || '-']);
                    line.push([item.ticket?.trainPnr || '-']);
                    line.push([
                      moment(item.createdAt).format('DD MMM YYYY HH:mm a'),
                    ]);
                    data.push(line);
                  });
                  DownloadCsv(
                    `refund_queue${moment().format('DD_MM_YYYY')}}.csv`,
                    data
                  );
                }
                setLoading(false);
              });
            }}
            loading={loading}
            className="btn btn-primary btn-sm absolute top-2 right-0"
            style={{ zIndex: 10 }}
          >
            <i className="fa fa-download mr1" />
            Download Data
          </Button>
        </RBAC>
      </div>
      {/* <SearchBox
        onChange={(q) => {
          setSearch({
            ...search,
            page: 0,
            q,
          });
        }}
        placeholder="Enter your search criteria"
      /> */}
      <Filter
              margin="0 -4rem"
              onSearch={(q) => {
                setSearch({
                  ...search,
                  page: 0,
                  q,
                })
              }}
              onFilter={() => {
                setFilterModal(true)
              }} 
            />


      <FilterDropDown
            shown={filterModal}
            filters={filter}
            setFilters={setFilter}
            choices={{
              client: [],
              traveldate: [
                {
                  title: 'Upcoming 24 hours',
                  type: 'radio'
                },
                {
                  title: 'Upcoming 48 hours',
                  type: 'radio'
                }
              ]
            }}
            types={["Client", "TravelDate"]}
            onClose={() => {
              setFilterModal(false);
            }}
        />
      <ScrollableDynamicTable
        setSearch={(search) => {
          setSearch({
            ...search,
            page: 0,
          });
        }}
        search={search}
        tableStyle={{
          borderSpacing: 0,
        }}
        setPage={(page) => {
          setSearch({
            ...search,
            page,
          });
        }}
        data={{
          current: Data.currentPage,
          total: Data.totalPages,
          items: Data.items.map((item) => {
            const click = () => {
              setId(item.queueId);
            };
            return {
              content: item,
              render: [
                {
                  children: (
                    <div>
                      <p className="text">{item?.requestId}</p>
                      {/* 
                      <p className='text-small text-light'>
                        Quota: {item.request.attrs.quota || '-'}
                      </p>
                      <p className='text-small text-light'>
                        Train: {item.request.attrs.train || '-'}
                      </p> */}
                    </div>
                  ),
                  weight: 1.5,
                },
                {
                  weight: 1.5,
                  children: (
                    <div>
                      <p>{item?.client?.clientName}</p>
                      
                      <p className='text-small'>{item?.traveller?.name}</p>
                      <p className='text-small'>{item?.traveller?.email}</p>
                    </div>
                  ),
                },
                {
                  weight: 1.5,
                  children: (
                    <div>
                      <p>{item?.visa?.country||"-"}</p>
                      {/* 
                      <p className='text-small'>{item.guests[0].name}</p>
                      <p className='text-small'>{item.guests[0].email}</p> */}
                    </div>
                  ),
                },
                {
                  weight: 1,
                  title: item?.status||"-",
                },
                {
                  weight: 1,
                  title: item?.visa?.startDate||"-",
                },
                {
                  weight: 1,
                  title: moment(item?.createdAt).format('DD-MMM-YYYY')||"-",
                },
               
                {
                  weight: 1,
                  buttons: [
                    {
                      title: 'View',
                      className: 'btn-secondary btn-sm',
                      before: <OpenIcon size="small" />,
                      onClick: click,
                    },
                  ],
                  actions: [
                    {
                      title: 'Quick Actions',
                      actions: [
                        {
                          title: 'View',
                          before: <OpenIcon size="small" />,
                          onClick: click,
                        },
                      ],
                    },
                  ],
                },
              ],
            };
          }),
        }}
        headers={[
          {
            weight: 1.5,
            title: 'Details',
          },
          {
            weight: 1.5,
            title: 'Client',
          },
          {
            weight: 1,
            title: 'Country',
          },
          {
            weight: 1,
            title: 'Queue Status',
          },
          {
            weight: 1,
            title: 'Travel Date',
          },
          {
            weight: 1,
            title: 'Request Date',
          },
          {
            weight: 1,
            title: 'Actions',
            style: {
              justifyContent: 'end',
            },
          },
        ]}
      />
      {id !== undefined && (
        <RefundRequest
          id={id}
          onClose={() => {
            setId(undefined);
            loadPage(search);
          }}
        />
      )}
    </div>
  );
};

export default Refund;
