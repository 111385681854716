import React from 'react';
import { NavDivider, Navigation } from '../../../../../retro';
import { ReportRouteCard } from '../All/routes';
import useRoleHook from 'App/Hooks/useRoleHook';
import ROLES from 'App/Constants/Roles';

export default function routes() {
  const isReports = useRoleHook(ROLES.REPORTS);
  const isSupport = useRoleHook(ROLES.SUPPORT);

  const routes = []
    if (isSupport || isReports){
      routes.push(
        {
          title: 'BOS File',
          desc: 'Traveller wise bos file',
          path: '/app/apps/meta/insurance/bos',
          btnLabel: 'View File',
          codes: [],
        },
      )

    }

  return (
    <div>
      <Navigation
        backUrl=""
        title="Insurance Bookings"
        description="All insurance queue appears below."
      />
      <NavDivider />
      <div className="card-container mt4">
        {routes.map(
          (report) =>
            // return only if noone  or the current one is not restricted
            (report.codes.length === 0 || report.codes.includes(code)) && (
              <ReportRouteCard reportRoute={report}/>
            )
        )}
      </div>
    </div>
  );
}
