import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Loader,
  ScrollableDynamicTable,
  SearchBox,
  TOAST as Alert,
  Navigation,
  Button,
  Modal,
  Input,
  FileInput,
  Attachment,
  TOAST,
} from 'retro';
import { get, post } from 'App/Network/Axios';
import OpenIcon from '@atlaskit/icon/glyph/open';
import ViewRequest from './RefundRequest';
import moment from 'moment';
import { DownloadCsv } from 'retro/Utils';
import RBAC from 'App/HOCs/RBAC';
import ROLES from 'App/Constants/Roles';
import FileAvatar from './FileAvatar';

export default function index() {
  let [loading, setLoading] = useState(true);
  const [id, setId] = useState(undefined);
  const [offlineInvoiceModal, setOfflineInvoiceModal] = useState(false);
  const [file, setFile] = useState(undefined);
  const FILE = useRef();
  const [search, setSearch] = useState({
    page: 0,
    sort: 'id',
    order: 'desc',
  });
  const [Data, SetData] = useState({
    total: 0,
    currentPage: 0,
    totalPages: 0,
    items: [],
  });
  const TRANS_ID = useRef(),
    HOTEL_GST = useRef(),
    HOTEL_INVOICE_NUMBER = useRef();
  const loadPage = useCallback(
    (search) => {
      get(
        `/meta/hotel/offline/invoice/all`,
        (e, r) => {
          if (r) {
            SetData({
              ...r,
              total: r.total || Data.total,
              totalPages: r.totalPages || Data.totalPages,
            });
            setLoading(false);
          } else {
            Alert.handleError(e);
          }
        },
        {
          offset: search.page,
          order: search.order,
          q: search.q,
        }
      );
    },
    [Data, SetData]
  );

  const handleOfflineInvoices = () => {
    let transId = TRANS_ID.current.value;
    let hotelGst = HOTEL_GST.current.value;
    let hotelInvoiceNumber = HOTEL_INVOICE_NUMBER.current.value;

    if (!transId) {
      TOAST.error('Please enter trip id');
      return;
    }

    if (!hotelGst) {
      TOAST.error('Please enter hotel gst');
      return;
    }

    const gstinRegex = /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/;

    /* Only check if GSTIN is entered */
    if (hotelGst.trim() !== '' && !gstinRegex.test(hotelGst)) {
      return TOAST.error('Invalid Hotel GSTIN');
    }

    if (!hotelInvoiceNumber) {
      TOAST.error('Please enter hotel invoice number');
      return;
    }

    if (!file) {
      TOAST.error('Kindly select a file');
      return;
    }

    let data = {
      transId,
      hotelGst,
      hotelInvoiceNumber,
      files: [file],
    };

    post(`/meta/hotel/offline/invoice/create`, data, (e, r) => {
      if (r) {
        TOAST.success('Invoice added successfully');
        loadPage(search);
        setOfflineInvoiceModal(false);
      } else {
        TOAST.handleError(e);
      }
    });
  };

  useEffect(() => {
    loadPage(search);
  }, [search]);
  if (loading) {
    return <Loader />;
  }
  return (
    <div>
      <div className="mb2 relative">
        <Navigation title="Request History" />
        <RBAC role={ROLES.REPORTS}>
          <Button
            onClick={() => {
              setLoading(true);
              post(`/meta/hotel/offline/invoice/all/download`, {}, (e, r) => {
                if (r) {
                  let data = [];
                  const headers = [
                    'Trip Id',
                    'Hotel GST',
                    'Hotel Invoice Number',
                    'Invoice',
                    'Upload Date',
                  ];
                  data.push(headers);
                  r.items.forEach((item) => {
                    let line = [];
                    line.push([item.transId]);
                    line.push([item?.hotelGst || '-']);
                    line.push([item.hotelInvoiceNumber || '-']);
                    line.push([item?.files[0] || '-']);
                    line.push([moment(item.createdAt).format('DD-MMM-YYYY')]);
                    data.push(line);
                  });
                  DownloadCsv(
                    `offile_invoices${moment().format('DD_MM_YYYY')}}.csv`,
                    data
                  );
                }
                setLoading(false);
              });
            }}
            loading={loading}
            className="btn btn-primary btn-sm absolute top-2 right-0"
            style={{ zIndex: 10 }}
          >
            <i className="fa fa-download mr1" />
            Download Data
          </Button>
        </RBAC>
      </div>
      <SearchBox
        onChange={(q) => {
          setSearch({
            ...search,
            page: 0,
            q,
          });
        }}
        placeholder="Enter your search criteria"
      />
      <Button
        className="btn btn-primary mt3"
        onClick={() => setOfflineInvoiceModal(true)}
      >
        {' '}
        + Add invoice
      </Button>
      <ScrollableDynamicTable
        setSearch={(search) => {
          setSearch({
            ...search,
            page: 0,
          });
        }}
        search={search}
        tableStyle={{
          borderSpacing: 0,
        }}
        setPage={(page) => {
          setSearch({
            ...search,
            page,
          });
        }}
        data={{
          current: Data.currentPage,
          total: Data.totalPages,
          items: Data.items.map((item) => {
            const click = () => {
              setId(item.queueId);
            };
            return {
              content: item,
              render: [
                {
                  children: (
                    <div>
                      <p className="text fw-bold">{item.transId}</p>
                      <p className="text">{item.invoiceId || '-'}</p>
                    </div>
                  ),
                  weight: 1.5,
                },
                {
                  weight: 1,
                  title: item?.hotelGst || '-',
                },
                {
                  weight: 1,
                  title: item?.hotelInvoiceNumber || '-',
                },
                {
                  weight: 1,
                  children: (
                    <>
                      <FileAvatar size={40} file={item?.files?.[0]} />
                    </>
                  ),
                },
                {
                  weight: 1,
                  title: moment(item.createdAt).format('DD-MMM-YYYY'),
                },
                // {
                //   weight: 1,
                //   buttons: [
                //     {
                //       title: 'View',
                //       className: 'btn-secondary btn-sm',
                //       before: <OpenIcon size='small' />,
                //       onClick: click,
                //     },
                //   ],
                //   actions: [
                //     {
                //       title: 'Quick Actions',
                //       actions: [
                //         {
                //           title: 'View',
                //           before: <OpenIcon size='small' />,
                //           onClick: click,
                //         },
                //       ],
                //     },
                //   ],
                // },
              ],
            };
          }),
        }}
        headers={[
          {
            weight: 1.5,
            title: 'Details',
          },

          {
            weight: 1,
            title: 'Hotel GST',
          },
          {
            weight: 1,
            title: 'Hotel Invoice Number',
          },
          {
            weight: 1,
            title: 'Invoice',
          },
          {
            weight: 1,
            title: 'Upload  Date',
          },
          //   {
          //     weight: 1,
          //     title: 'Actions',
          //     style: {
          //       justifyContent: 'end',
          //     },
          //   },
        ]}
      />
      {offlineInvoiceModal && (
        <Modal
          title="Add Online Invoice"
          description=""
          onClose={() => setOfflineInvoiceModal(false)}
        >
          <>
            <Input
              label="Cleartrip Trip Id *"
              placeholder="Add Trip Id"
              ref={TRANS_ID}
            />
            <Input
              label="Hotel GST *"
              className="mt2"
              placeholder="Hotel GST"
              ref={HOTEL_GST}
            />
            <Input
              label="Hotel Invoice Number *"
              className="mt2"
              placeholder="Hotel Invoice Number"
              ref={HOTEL_INVOICE_NUMBER}
            />
            {/* <Input label='Amount *' placeholder='Add Amount' ref={AMOUNT}/>
        <Input label='Gst Amount *' placeholder='Add GST Amount' ref={GST_AMOUNT}/> */}
            <div className="flex horizontally center-vertically mt2">
              <div className="flex mb2 mt2">
                <FileInput
                  updateFiles={(url) => {
                    setFile(url);
                  }}
                  label=""
                  ref={FILE}
                />
                <Attachment url={file} />
              </div>
            </div>
            <Button className="btn btn-primary" onClick={handleOfflineInvoices}>
              + Add
            </Button>
          </>
        </Modal>
      )}
      {id !== undefined && (
        <ViewRequest
          id={id}
          onClose={() => {
            setId(undefined);
            loadPage(search);
          }}
        />
      )}
    </div>
  );
}
