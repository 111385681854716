import React, { useEffect, useRef, useState } from 'react';
import {
  BorderTable,
  Button,
  FileInput,
  Loader,
  SidePane,
  StatelessTab,
  TOAST,
} from 'retro';
import CsvToJson from 'App/Utils/Csv';
import { DownloadCsv } from 'retro/Utils';
import { post } from 'App/Network/Axios';


const BulkUploadPayment = ({ onClose }) => {
  const [tab, setTab] = useState('Upload File');
  const [csvData, setCsvData] = useState([]);
  const [uploadStaus, setUploadStaus] = useState([]);
  const [uploadRemarks, setUploadRemarks] = useState([]);


  return (
    <SidePane
      title="Bulk Upload Payment Details"
      tabs={
        <StatelessTab
          onClick={setTab}
          style={{ marginBottom: 'calc(-1rem - 1px)' }}
          tabs={['Sample Format', 'Upload File', 'Report Status']}
          active={tab}
        />
      }
      description="Upload a csv file with proper fields"
      onClose={onClose}
    >
      {tab === 'Sample Format' && <SampleFile />}
      {tab === 'Upload File' && (
        <UploadFile
          csvData={csvData}
          setCsvData={setCsvData}
          uploadStaus={uploadStaus}
          setUploadStaus={setUploadStaus}
          uploadRemarks={uploadRemarks}
          setUploadRemarks={setUploadRemarks}
        />
      )}
      {tab === 'Report Status' && (
        <ReportStatus
          csvData={csvData}
          uploadStaus={uploadStaus}
          uploadRemarks={uploadRemarks}
        />
      )}
    </SidePane>
  );
};


export default BulkUploadPayment;


// sample file component
const SampleFile = () => {
  const data = [];
  const headers = ['S No', 'Queue Id', 'Type', 'UTR', 'Remarks'];
  const sampleRow = [
    '1',
    '1234',
    'Razorpay/Payment Link/CC Auth/TripJack/Others(use one)',
    'test',
    'test remarks',
  ];


  const downloadSampleFile = () => {
    data.push(headers);
    data.push(sampleRow);
    DownloadCsv('Sample_Format_Payment_UTR.csv', data);
  };


  return (
    <div>
      <Button
        onClick={downloadSampleFile}
        className="btn btn-secondary btn-sm rounded-pill mr1"
      >
        <i className="fa fa-download mr1" />
        Download Sample Format
      </Button>
    </div>
  );
};


const UploadFile = ({ csvData, setCsvData, uploadStaus, setUploadStaus, uploadRemarks, setUploadRemarks }) => {
  const file = useRef();
  const [uploads, setUploads] = useState({});
  const [uploading, setUploading] = useState(false);
  const [uploaded, setUploaded] = useState(true);


  const uploadCsv = (file) => {
    setUploading(true);
    setUploadStaus([]);
    setUploadRemarks([]);
    setUploaded(false);
    CsvToJson(
      file,
      (rows) => {
        setCsvData(rows);
      },
      (row) => {
        console.log(row);
        if (row['Queue Id'] && row['Type'] && row['UTR']) return true;
        TOAST.error('Please upload a file with correct fields');
        setUploading(false);
        return false;
      }
    );
  };


  useEffect(() => {
    // When csvData changes, trigger the recursiveUpload function
    if (csvData.length > 0 && !uploaded) {
      recursiveUpload(csvData, 0);
    }
  }, [csvData]); // Add csvData as a dependency to this effect


  const upload = async (csvData, cb) => {
    // Extract relevant data from the row
    let { 'Queue Id': id, Type: type, UTR: utr, Remarks: remarks } = csvData;
    const TYPES = ["Razorpay","Payment Link","CC Auth","TripJack","Others"];

    if (!id) {
      handleUploadError('Enter a valid id', cb);
      return;
    }
    if (!type) {
      handleUploadError('Enter a valid type', cb);
      return;
    }
    if(!TYPES.includes(type)){
      handleUploadError('Enter a valid type', cb);
      return;
    }
    if (!utr) {
      handleUploadError('Enter a valid utr', cb);
      return;
    }
    // post api to add the payment details
    post(`/meta/contracted/${id}/payment`, { type, utr, remarks }, (e, r) => {
      if (r) {
        uploadStaus.push('Success');
        uploadRemarks.push('Debit added successfully!');
        TOAST.success('Debit added successfully!');
        cb('Debit added successfully!');
      } else {
        TOAST.handleError(e);
        uploadStaus.push('Failed');
        uploadRemarks.push(e.data?.message);
        cb(e);
      }
    });
  };


  // handle error to show the toast and add remarks
  const handleUploadError = (errorMsg, cb) => {
    TOAST.error(errorMsg);
    uploadStaus.push('Failed');
    uploadRemarks.push(errorMsg);
    cb(new Error(errorMsg));
  };


  const recursiveUpload = (csvData, i = 0, mState = {}) => {
    if (i >= csvData.length) {
      setUploading(false);
      setUploaded(true);
      setUploads({ ...mState });
    } else {
      let row = csvData[i];
      upload(row, (message) => {
        let key = row['Queue Id']; //need to change this according to the data from api
        let state = { ...mState };
        state[key] = message;
        setUploads(state);
        recursiveUpload(csvData, i + 1, state);
      });
    }
  };


  return (
    <>
      <FileInput
        onSelect={uploadCsv}
        extensions={['.csv']}
        className="display-none"
        ref={file}
      />
      {uploading ? (
        <Loader />
      ) : (
        <Button
          onClick={() => {
            file.current.click();
          }}
          className="btn btn-secondary btn-sm rounded-pill mr1"
        >
          <i className="fa fa-upload mr1" />
          Upload CSV File
        </Button>
      )}
    </>
  );
};


const ReportStatus = ({ csvData, uploadStaus, uploadRemarks }) => {
  
  const handleClick = (csvData) => {
    let data = [];
    let headers = [];


    if (csvData.length > 0) {
      // Assuming all objects have the same keys, so we use the keys of the first object
      const firstObject = csvData[0];
      headers = Object.keys(firstObject);


      // Add the "Status" and "Upload Remarks" fields to the headers
      headers.push('Status', 'Upload Remarks');
    }


    data.push(headers);


    if (csvData.length > 0) {
      // Iterate through each object in csvData
      csvData.forEach((item, index) => {
        // Flatten the object and push its values into the data array
        const row = Object.values(item);
        row.push(uploadStaus[index] || '-');
        row.push(uploadRemarks[index] || '-');
        data.push(row);
      });
    }


    DownloadCsv('bulk_upload_add_payment_status_report', data);
  };


  const rows = (csvData, uploadStaus) => {
    const rowsArray = [];
    // Count the number of "Success" and "Failed" occurrences in the status array
    const successCount = uploadStaus.filter(
      (item) => item === 'Success'
    ).length;
    const failedCount = uploadStaus.filter((item) => item === 'Failed').length;


    rowsArray.push([`${csvData.length}`, `${successCount}`, `${failedCount}`]);
    return rowsArray;
  };


  if (csvData.length <= 0) {
    return <div className="pr2 pt2">Nothing uploaded!</div>;
  }


  return (
    <div>
      <Button
        onClick={() => handleClick(csvData, uploadStaus, uploadRemarks)}
        className="btn btn-secondary rounded-pill mr2 mt2"
      >
        <i className="fa fa-download mr1" />
        View Status
      </Button>
      <div>
        <p className="mt4 ">Summary of file uploaded</p>
        <BorderTable
          className=""
          rows={rows(csvData, uploadStaus)}
          headers={['Total', 'Success', 'Failed']}
        />
      </div>
    </div>
  );
};



