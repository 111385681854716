import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Input,
  Modal,
  NavDivider,
  Navigation,
  SearchInput,
  SelectInput,
  TOAST,
} from 'retro/index';
import { MEALPLAN } from 'App/Pages/App/MetaApis/HotelQueue/Queued/Utils';
import Moment from 'moment/moment';
import { get, post } from 'App/Network/Axios';
import {useHistory} from "react-router-dom";
import { countryData } from './CountryData';
import email from '@atlaskit/icon/glyph/email';
import HotelSearchInput from 'App/Components/Inputs/HotelSearchInput/HotelSearchInput';

export default function ImportHotel() {



  const [formData, setFormData] = useState({
    prefferedGst: undefined,
    prefferedGstName: undefined,
    clientCode: undefined,
    clientId: undefined,
    city: undefined,
    country:undefined,
    email: undefined,
    allGsts: [],
  });
  const HISTORY = useHistory();
  const VOUCHER = useRef();
  const NAME = useRef();
  const ADDRESS = useRef();
  const [price, setPrice] = useState(0);
  const NUMBEROFROOMS = useRef();
  const TYPE = useRef();
  const GUEST = useRef();
  const [loading, setLoading] = useState(false);
  const GENDER = useRef();
  const EMAIL = useRef();
  const CHECKIN = useRef();
  const CHECKOUT = useRef();
  const GSTIN = useRef();
  const PG = useRef();
  const ACCOUNT = useRef();
  const COUNTRY = useRef(formData.country);
  const CITY = useRef(formData.city);
  const ROOMNAME = useRef();
  const ROOMINCULSIONS = useRef();
  const CANCELLATION = useRef();
  const PRICEPERNIGHT = useRef();
  const REMARKS = useRef();
  const [items, setItems] = useState([]);
  const [type, setType] = useState(undefined);
  const [selectedCountry, setSelectedCountry] = useState(undefined);
  const [travellers, setTravellers] = useState([]);



      // Create the options array by mapping allcountries
    const countryOptions = countryData.map((data) => ({
       value: data.text,
       label: data.text,
    }));


  useEffect(() => {
    setItems([]);
    if (formData.clientCode) {
      get(`/accounts/${formData.clientId}`, (e, r) => {
        if (r) {
          setItems(r.items);
          setType(undefined);
        }
      });
    }
  }, [formData.clientCode]);

  const onBlur = () => {
    if (!PRICEPERNIGHT.current.value) {
      return;
    }
    if (!NUMBEROFROOMS.current.value) {
      return;
    }

    if (!CHECKIN.current.value) {
      return;
    }

    if (!CHECKOUT.current.value) {
      return;
    }
    let numberOfRooms = parseInt(NUMBEROFROOMS.current.value, 10);
    let pricePerNight = parseInt(PRICEPERNIGHT.current.value, 10);
    let checkOut = Moment(CHECKOUT.current.value, 'YYYY-MM-DD').format(
      'DD-MM-YYYY'
    );
    let checkIn = Moment(CHECKIN.current.value, 'YYYY-MM-DD').format(
      'DD-MM-YYYY'
    );

    let nights = Moment(checkOut, 'DD-MM-YYYY').diff(
      Moment(checkIn, 'DD-MM-YYYY'),
      'days'
    );
    let amount = numberOfRooms * pricePerNight * nights;
    // adding gst amount only when country is india
    if(COUNTRY.current.value.toLowerCase()==='india'){
      amount = amount + ((pricePerNight > 7500 ? 18 : 12) / 100) * amount;
      if (amount < 0) {
        return;
      }
      setPrice(parseInt(`${amount}`, 10));
    } else{
      if (amount < 0) {
        return;
      }
      setPrice(parseInt(`${amount}`, 10));
    }

  };

  return (
    <div>
      <Navigation backUrl={'back'} title="Import Hotel Booking" />
      <NavDivider />
      {!formData.prefferedGst ? (
        <PrefferedGST data={formData} setData={setFormData} category="hotel" />
      ) : (
        <div className="pd4 border rounded-md">
          <div className="flex horizontally">
            <div className="flex-1">
              <SelectInput
                placeholder="Select"
                hidePlaceholder={false}
                options={[
                  ...items.map((item) => {
                    return {
                      label: `${item.title} (INR ${item.balance})`,
                      value: item.accountId,
                    };
                  }),
                  { label: 'RAZORPAY', value: 'RAZORPAY' },
                ]}
                onChange={setType}
                ref={ACCOUNT}
                label="Select Deposit Account"
                className="flex-1"
              />
            </div>
          </div>
          <div className="flex horizontally center-vertically mt1">
            <SelectInput
              placeholder="Select"
              hidePlaceholder={false}
              options={[
                {
                  label: `${formData.prefferedGst} - ${formData.prefferedGstName} (Preffered GST)`,
                  value: formData.prefferedGst,
                },
                ...formData.allGsts.map((gst) => {
                  return {
                    label: `${gst.gstin} - ${gst.name}`,
                    value: gst.gstin,
                  };
                }),
              ]}
              defaultValue={formData.prefferedGst}
              ref={GSTIN}
              label="Select the GSTIN"
              className="flex-1"
            />
          </div>
          {type === 'RAZORPAY' && (
            <Input
              ref={PG}
              type="text"
              label="Enter Pg Id"
              placeholder="Enter Pg Id"
              className="mt1"
            />
          )}
          <div className="flex horizontally center-vertically mt2">
            <Input
              ref={GUEST}
              type="text"
              label="Guest Name"
              placeholder="Guest Name"
              className="mr1 flex-1"
            />
            <div className="flex-1 ml1">
              <SelectInput
                options={[
                  { label: 'MALE', value: 'MALE' },
                  { label: 'FEMALE', value: 'NON-FEMALE' },
                ]}
                ref={GENDER}
                type="number"
                label="Gender"
                hidePlaceholder={true}
                className="flex-1 mb1"
              />
            </div>
          </div>
          <div className="flex horizontally center-vertically mt2">
            <Input
              ref={EMAIL}
              type="text"
              label="Requestor Email Address"
              placeholder="Email Address"
              className="flex-1 mr1"
              defaultValue={formData.email}
            />
            <Input
              onChange={onBlur}
              ref={NUMBEROFROOMS}
              type="number"
              label="Number Of Rooms"
              placeholder="Enter number of rooms"
              className="flex-1 ml1"
            />
          </div>
          <div className="flex horizontally center-vertically mt2">
            <Input
              ref={VOUCHER}
              type="text"
              label="Confirmation Number"
              placeholder="Confirmation Number"
              className="mr1 flex-1"
            />
            <Input
              ref={NAME}
              type="text"
              label="Hotel Name"
              placeholder="Hotel Name"
              className="ml1 flex-1"
            />
          </div>
          <div className="flex horizontally center-vertically mt2">
            <Input
              ref={ADDRESS}
              type="text"
              label="Hotel Address"
              placeholder="Hotel Address"
              className="flex-1"
            />
          </div>
          <div className="flex horizontally center-vertically mt2">
            <Input
              ref={CITY}
              type="text"
              label="City"
              disabled={true}
              placeholder="Enter City"
              className="mr1 flex-1"
              defaultValue={formData.city}
            />
            <Input
              ref={COUNTRY}
              defaultValue = {formData.country}
              type="text"
              label="Country"
              placeholder="Enter Country"
              disabled={true}
              className="ml1 mb1 flex-1"
            />
          </div>
          <div className="flex horizontally center-vertically mt2">
            <Input
              type="date"
              onChange={onBlur}
              label="Check In"
              ref={CHECKIN}
              placeholder="Check In"
              className="mr1 flex-1"
            />
            <Input
              type="date"
              label="Check Out"
              ref={CHECKOUT}
              onChange={onBlur}
              placeholder="Check Out"
              className="ml1 flex-1"
            />
          </div>
          <div className="flex horizontally center-vertically mt2">
            <Input
              ref={ROOMNAME}
              type="text"
              label="Room Name"
              placeholder="Room Name"
              className="mr1 flex-1"
            />
            <SelectInput
              className="flex-1 ml1 mb1"
              ref={ROOMINCULSIONS}
              label="Meal Plan"
              options={MEALPLAN.map((value) => ({ value, label: value }))}
            />
          </div>
          <div className="flex horizontally center-vertically w-100 mt2">
            <Input
              ref={CANCELLATION}
              type="text"
              label="Cancellation Policy"
              placeholder="Enter Cancellation Policy"
              className="flex-1 mr1"
            />
            <Input
              ref={PRICEPERNIGHT}
              type="number"
              onChange={onBlur}
              label="Price Per Night Per Room Exc. of Gst"
              placeholder="Price Per Night Per Room Exc. of Gst"
              className="ml1 flex-1"
            />
          </div>
          <div className="flex horizontally center-vertically">
            <SelectInput
              options={[
                { label: 'CONTRACTED', value: 'CONTRACTED' },
                { label: 'NON-CONTRACTED-VTP', value: 'NON-CONTRACTED-VTP' },
                { label: 'Trip Jack', value: 'Trip Jack' },
                { label: 'Cleartrip', value: 'Cleartrip' },
                { label: 'Others', value: 'Others' },
              ]}
              ref={TYPE}
              type="number"
              label="Type"
              hidePlaceholder={true}
              className="mr1 flex-1 mb1"
            />
            <Input
              ref={REMARKS}
              type="text"
              label="Remarks"
              placeholder="Internal remarks"
              className="ml1 flex-1"
            />
          </div>
            {/* modal to add multiple travellers */}
             <AddTravellerModal
            travellers={travellers}
            setTravellers={setTravellers}
          />
          <div className="flex horizontally center-vertically mt4 ">
            <Button
              onClick={() => setFormData({})}
              className="btn-black btn-primary mr4"
              margin="pd2"
            >
              <i className="fa-solid fa-arrow-left mr1" />
              Previous
            </Button>
            <Button
              onClick={() => {
                let pricePerNight = parseInt(PRICEPERNIGHT.current.value, 10);
                let gst = pricePerNight < 7500 ? 12 : 18;
                let meal = ROOMINCULSIONS.current.value;
                let city = CITY.current.value;
                let cancellation = CANCELLATION.current.value;
                let roomName = ROOMNAME.current.value;
                let name = NAME.current.value;
                let address = ADDRESS.current.value;
                let voucher = VOUCHER.current.value;
                let remarks = REMARKS.current.value;
                let guestName = GUEST.current.value;
                let gender = GENDER.current.value;
                let checkIn = CHECKIN.current.value;
                let checkOut = CHECKOUT.current.value;

                if (!price || price < 100) {
                  TOAST.error('Enter a valid amount');
                  return;
                }

                if (!voucher) {
                  TOAST.error('Enter a valid voucher');
                  return;
                }
                // if (!selected) {
                //   TOAST.error('Enter a valid client');
                //   return;
                // }
                if (!guestName) {
                  TOAST.error('Enter a valid guestName');
                  return;
                }

                if (!name) {
                  TOAST.error('Enter a valid name');
                  return;
                }

                if (!checkIn) {
                  TOAST.error('Enter a valid checkIn');
                  return;
                }

                if (!checkOut) {
                  TOAST.error('Enter a valid checkOut');
                  return;
                }

                if (!address) {
                  TOAST.error('Enter a valid address');
                  return;
                }

                if (!city) {
                  TOAST.error('Enter a valid city');
                  return;
                }
                if (!COUNTRY.current.value) {
                  TOAST.error('Please select a country');
                  return;
                }
                if (!roomName) {
                  TOAST.error('Enter a valid roomName');
                  return;
                }

                if (!cancellation) {
                  TOAST.error('Enter a valid cancellation');
                  return;
                }

                if (!meal) {
                  TOAST.error('Enter a valid meal');
                  return;
                }

                if (pricePerNight < 500) {
                  TOAST.error('Enter a valid price per night');
                  return;
                }
                setLoading(true);
                checkOut = Moment(checkOut, 'YYYY-MM-DD').format('DD-MM-YYYY');
                checkIn = Moment(checkIn, 'YYYY-MM-DD').format('DD-MM-YYYY');

                let nights = Moment(checkOut, 'DD-MM-YYYY').diff(
                  Moment(checkIn, 'DD-MM-YYYY'),
                  'days'
                );
                let amount = nights * pricePerNight;
                let data = {
                  hotel: {
                    name,
                    address,
                    city,
                    country:COUNTRY.current.value,
                  },
                  name: guestName,
                  gender,
                  room: {
                    name: roomName,
                    meal: meal,
                    cancellation: cancellation,
                  },
                  pricePerNight,
                  amount: price,
                  purchasePrice: price,
                  email : formData.email,
                  totalPrice: price,
                  gst,
                  account: ACCOUNT.current.value,
                  pgId: PG.current?.value,
                  numberOfRooms: parseInt(NUMBEROFROOMS.current.value, 10),
                  code: formData.clientCode,
                  client: formData.clientCode,
                  checkIn,
                  checkOut,
                  gstin: GSTIN.current.value,
                  type: TYPE.current.value,
                  travellers: travellers,
                  voucher,
                  remarks,
                };
                post(`/meta/hotel/import/manual`, data, (e, r) => {
                  if (e) {
                    TOAST.handleError(e);
                  } else {
                    if(r?.refId){
                      HISTORY.push(`/app/apps/meta/transaction/${r.refId}`)
                    }
                    if(r?.transId){
                      HISTORY.push(`/app/apps/meta/transaction/${r.transId}`)
                    }
                    TOAST.success('Hotel imported successfully!');
                    CHECKOUT.current.value = '';
                    CHECKIN.current.value = '';
                    PRICEPERNIGHT.current.value = '';
                    NUMBEROFROOMS.current.value = '';
                    NAME.current.value = '';
                    REMARKS.current.value = '';
                    EMAIL.current.value = '';
                    ROOMNAME.current.value = '';
                    GUEST.current.value = '';
                    setPrice(0);
                  }
                  setLoading(false);
                });
              }}
              className="btn-black btn-primary"
              margin="pd2"
              loading={loading}
            >
              Confirm Booking (INR {price})
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export const PrefferedGST = ({ data, setData, category = '' }) => {
  const [selected, setSelect] = useState(undefined);
  const EMAIL = useRef();
    const [Citi, setCiti] = useState({country:"india"});
  const getGst = () => {
    if(!Citi){
      TOAST.error('Enter a valid city')
      return
    }
    const getGsts = ()=>{ get(
      `meta/gstins`,
      (e, r) => {
        if (e) {
          TOAST.handleError(e);
          return;
        }
        setData({
          ...data,
          prefferedGst: r.preferred?.gstin||"-",
          prefferedGstName: r.preferred?.name||"-",
          clientCode: selected.code,
          clientId: selected.id,
          city: Citi.name || '',
          country:Citi.country || '',
          email: EMAIL.current.value,
          allGsts: r.gstins,
        });
      },
      {
        clientCode: selected.code,
        email: EMAIL.current.value,
        city: Citi || '',
        category,
      }
    )};
    if (!selected || !EMAIL.current.value) {
      TOAST.error('Fill all the fields');
      return;
    }
    let isEmail = RegExp(/\S+@\S+\.\S+/).test(EMAIL.current.value);
    if (!isEmail) {
      TOAST.error('Enter a valid email');
      return;
    }
    if (category.toUpperCase() === 'HOTEL' && !Citi) {
      TOAST.error('Fill all the fields');
      return;
    }
    post(
      '/meta/clientEmpVerify',{
        email:EMAIL.current.value,
        clientCode: selected.code,
      },
      (e, r) => {
        if (e) {
          console.log('error ocurred')
          TOAST.handleError(e);
          return;
        }
        const {success} = r;
        if(success){
          if(confirm("User email does not exists in this client. Do you still want to continue")){
            getGsts();
          }else{
           return false;
          }
        }else{
          getGsts();
        }
      }
    )
  };
  return (
    <div className="pd4 border rounded-md">
      <SearchInput
        renderResult={(item) => (
          <div>
            <p>{item.name}</p>
            <p>{item.code}</p>
          </div>
        )}
        resultKey="items"
        api="clients"
        morph={true}
        label="Select Client"
        onSelect={setSelect}
        selected={selected}
      />
      <Input
        type="text"
        label="Primary Traveller"
        placeholder="Traveller Email"
        ref={EMAIL}
      />
      {category.toUpperCase() === 'HOTEL' && (
        <div style={{marginTop:'.5rem'}}>
        <label>Select City</label>
        <HotelSearchInput 
        recentCities={true}
        hide={true}
        className="mt1"
        api="/meta/cities/search"
        onSelect={(city)=>{setCiti(city);
        }}
        selected={Citi}
        placeholder={"Search City"}
        renderResult={(item) => (
          <div>
            <p>{item?.name}{item.country==='India' ? `, ${item?.state}` : ''}, {item?.country}</p>
          </div>
        )}
         />
         </div>
      )}
      <div className="flex flex-1 vertical mt4">
        <Button onClick={getGst} className="btn-black btn-primary" margin="pd2">
          Next
          <i className="fa-solid fa-arrow-right ml1" />
        </Button>
      </div>
    </div>
  );
};

// component to add travellers
export const AddTravellerModal = ({ travellers, setTravellers }) => {
  const [showModal, setShowModal] = useState(false);

  const TNAME = useRef();
  const TEMAIL = useRef();
  const TTYPE = useRef();
  const TMOBILE = useRef();
  const TGENDER = useRef();
  const TSEAT = useRef();

  const addTraveller = () => {
    if (!TNAME.current.value) {
      TOAST.error('Enter valid name');
      return;
    }
    if (!TEMAIL.current.value) {
      TOAST.error('Enter valid email');
      return;
    }
    if (!TSEAT.current.value) {
      TOAST.error('Enter valid seat number');
      return;
    }
    setTravellers([
      ...travellers,
      {
        name: TNAME.current.value,
        email: TEMAIL.current.value,
        mobile: TMOBILE.current.value,
        type: TTYPE.current.value,
        seatNo: TSEAT.current.value,
        gender: TGENDER.current.value,
      },
    ]);
    setShowModal(false);
  };

  const removeTraveller = (email) => {
    let tempTravellers = travellers.filter(
      (traveller) => traveller.email !== email
    );
    setTravellers([...tempTravellers]);
  };

  return (
    <>
      {showModal && (
        <Modal
          onClose={() => setShowModal(false)}
          title="Add Traveller Details"
          width={675}
        >
          <div className="flex horizontally center-vertically mt1">
            <Input
              morph={true}
              label="Name"
              placeholder="Traveller Name"
              ref={TNAME}
              className="flex-1 mr1"
            />
            <Input
              morph={true}
              label="Type"
              placeholder="Traveller Type"
              ref={TTYPE}
              className="flex-1 ml1"
            />
          </div>
          <div className="flex horizontally center-vertically mt1">
            <Input
              morph={true}
              label="Email"
              placeholder="Traveller Email"
              ref={TEMAIL}
              className="flex-1 mr1"
            />
            <Input
              morph={true}
              label="Mobile"
              placeholder="Traveller Mobile"
              ref={TMOBILE}
              className="flex-1 ml1"
            />
          </div>
          <div className="flex horizontally center-vertically mt1">
            <Input
              morph={true}
              label="Seat Number"
              placeholder="Traveller Seat Number"
              ref={TSEAT}
              className="flex-1 mr1"
            />
            <SelectInput
              options={[
                { label: 'MALE', value: 'MALE' },
                { label: 'FEMALE', value: 'FEMALE' },
              ]}
              ref={TGENDER}
              type="number"
              label="Gender"
              hidePlaceholder={true}
              className="flex-1 ml1"
            />
          </div>
          <Button className="btn btn-primary" onClick={addTraveller}>
            <i className="fas fa-plus mr1"></i>
            Add Traveller
          </Button>
        </Modal>
      )}
      <div className="pd2 border mt2">
        {travellers.map((traveller) => {
          return (
            <div
              key={traveller.email}
              className="flex justify-between pd1 border-bottom"
            >
              <span style={{ width: '20%' }}>{traveller.name}</span>
              <span style={{ width: '15%' }}>{traveller.type}</span>
              <span style={{ width: '25%' }}>{traveller.email}</span>
              <span style={{ width: '20%' }}>{traveller.mobile}</span>
              <span style={{ width: '5%' }}>{traveller.seatNo}</span>
              <span style={{ width: '10%' }}>{traveller.gender}</span>
              <i
                className="fas fa-trash pointer"
                style={{ width: '3%', color: 'red' }}
                onClick={() => removeTraveller(traveller.email)}
              />
            </div>
          );
        })}

        <Button
          className="btn btn-sm btn-primary"
          onClick={() => setShowModal(true)}
        >
          <i className="fas fa-plus mr1"></i>
          Add Traveller
        </Button>
      </div>
    </>
  );
};
