import React from 'react';
import moment from 'moment';

function Index({airline, arrival, arrivalTime, arrrivalDate, departure, departureDate, departureTime, durationInMin, flightCode, flightNumber, id, logo, meals, rank}) {
    
    arrivalTime = Number(arrivalTime)
    departureTime = Number(departureTime)    

    const duration = moment.duration(moment(arrivalTime).diff(moment(departureTime)));
    const hours = parseInt(duration.asHours());
    const minutes = parseInt(duration.asMinutes()) % 60;

    return (
        <>
        <div className="row flight-card mt3 pd2">
            <div className="col-12 col-md-3 text-center text-small pr2">
                <img className="mb1" src={logo} alt='logo' style={{width: 30, height: 30, objectFit: 'cover', borderRadius: 30}} />
                <div>{airline}</div>
                <span className="text-muted">{flightCode}-{flightNumber}</span>
            </div>
            <div className='col-12 col-md-3 text-start mt1'>
                <h3 className="mb0 fw-bold">{moment(departureTime).format('HH:mm')}</h3>
                <div>{moment(departureTime).format('DD MMM YY')}</div>
                <span className="text-muted" style={{fontSize: '0.8rem'}}>{departure?.city}</span>
            </div>
            <div className='col-12 col-md-3 mt1'>
                <div className="text-center relative" style={{fontSize: '0.8rem', borderTop: '2px dashed #999', marginTop: 30}}>
                    <div className="absolute text-nowrap" style={{bottom: '-30px', left:'50%', transform: 'translateX(-50%)'}}>
                        <span><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" className="bi bi-clock mr1">
                            <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                        </svg>
                        {hours}h {minutes}m</span>
                    </div>
                </div>
            </div>
            <div className="col-12 col-md-3 text-end mt1">
                <h3 className="mb0 fw-bold">{moment(arrivalTime).format('HH:mm')}</h3>
                <div>{moment(arrivalTime).format('DD MMM YY')}</div>
                <span className="text-muted" style={{fontSize: '0.8rem'}}>{arrival?.city}</span>
            </div>
        </div>
        </>
    )
}

export default Index;
