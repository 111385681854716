import React from 'react'
import {KeyValue} from "../../../../retro";

export default function ApprovalView({data}) {
	if(data.type==="UPDATE_CONFIG"){
		let request = JSON.parse(data.request);
		return (
			<div>
				<KeyValue title="Key" value={request.key}/>
				<KeyValue title="Old Value" value={request.oldValue||"-"}/>
				<KeyValue title="New Value" value={request.value}/>
			</div>
		)
	}
	if(data.type==="CLIENT_TSP_KEYS"){
		let request = JSON.parse(data.request);
		return (
			<div>
				<KeyValue title="Old Partner Key" value={request.oldPartner}/>
				<KeyValue title="New Partner Key" value={request.partner}/>
				<KeyValue title="Old Tsp Key" value={request.oldTsp}/>
				<KeyValue title="New Tsp Key" value={request.tsp}/>
			</div>
		)
	}
	if(data.type==="ADMIN_CREATED"){
		let request = JSON.parse(data.request);
		return (
			<div>
				<KeyValue title="Name" value={request.name}/>
				<KeyValue title="Email" value={request.email}/>
				<KeyValue title="Mobile" value={request.mobile}/>
				<KeyValue title="Code" value={request.code}/>
				<p className="mt2 fw-bold">
					Roles:
				</p>
				<div className="flex flex-wrap">
					{
						request.roles?.includes("SUPER")
						? (
							<div className="mt1 mr1 status-chip pending text-truncate">
							SUPER
						</div>
						)
						:
						request.roles?.map(item=>{
							return (
								<div className="mt1 mr1 status-chip pending text-truncate">
									{item}
								</div>
							)
						})
					}
				</div>
			</div>
		)
	}
	if(data.type==="ADMIN_DEACTIVATE" || data.type==="ADMIN_ACTIVATE"){
		let request = JSON.parse(data.request);
		return (
			<div>
				<KeyValue title="Name" value={request.name}/>
				<KeyValue title="Email" value={request.email}/>
			</div>
		)
	}
	if(data.type==="CLIENT_CREATED"){
		let request = JSON.parse(data.request);
		return (
			<div>
				<KeyValue title="Name" value={request.name}/>
				<KeyValue title="Email" value={request.email}/>
				<KeyValue title="Code" value={request.code}/>
				<KeyValue title="Reference" value={request.reference}/>
				<KeyValue title="Account" value={request.account}/>
				<KeyValue title="Ifsc Code" value={request.ifsc}/>
				<KeyValue title="Partner Key" value={request.partner}/>
				<KeyValue title="TSP Key" value={request.tsp}/>
				{request?.gstin&&(<KeyValue title="GSTIN" value={request?.gstin}/>)}
				{request.moduleName && request.moduleName.length > 0 && <KeyValue title="Module" value={request.moduleName.join(', ')}/>}
			</div>
		)
	}
	if(data.type==="CLIENT_STATUS_CHANGE"){
		let request = JSON.parse(data.request);
		return (
			<div>
				<KeyValue title="Status" value={request.status}/>
			</div>
		)
	}
	if(data.type==="ADMIN_UPDATE"){
		let request = JSON.parse(data.request);
		return (
			<div>
				{request.name && <KeyValue title="Name" value={`${request.currentName} -> ${request.name}`}/>}
				{request.email&&<KeyValue title="Email" value={`${request.currentEmail} -> ${request.email}`}/>}
				{request.mobile&&<KeyValue title="Mobile" value={`${request.currentMobile} -> ${request.mobile}`}/>}
				{request.code&&<KeyValue title="Code" value={`${request.currentCode} -> ${request.code}`}/>}
				{
					request.currentRoles && <>
						<p className="mt2 fw-bold">
							Current Roles:
						</p>
						<div className="flex flex-wrap">
							{
								request.currentRoles.includes("SUPER")
								? (
									<div className="mt1 mr1 status-chip pending text-truncate">
									SUPER
								</div>
								)
								: request.currentRoles.map(item=>{
									return (
										<div className="mt1 mr1 status-chip pending text-truncate">
											{item}
										</div>
									)
								})
							}
						</div>
					</>
				}
				{
					request.roles && <>
						<p className="mt2 fw-bold">
							Updated Roles:
						</p>
						<div className="flex flex-wrap">
							{
								request.roles.includes("SUPER")
								? (
									<div className="mt1 mr1 status-chip pending text-truncate">
									SUPER
								</div>
								)
								: request.roles.map(item=>{
									return (
										<div className="mt1 mr1 status-chip pending text-truncate">
											{item}
										</div>
									)
								})
							}
						</div>
					</>
				}
			</div>
		)
	}
	if(data.type==="CLIENT_RM"){
		let request = JSON.parse(data.request);
		return(
		<div>
			<KeyValue title="Client Name" value={request?.name || "-"}/>
			<KeyValue title="Client Id" value={request?.clientId || "-"}/>
			<KeyValue title="New RM Name" value={request?.newManager?.name || "-"}/>
			<KeyValue title="New RM Email" value={request?.newManager?.email || "-"}/>
			<KeyValue title="New RM Mobile" value={request?.newManager?.mobile || "-"}/>
			<KeyValue title="New RM Code" value={request?.newManager?.code || "-"}/>
			<KeyValue title="New RM Branch" value={request?.newManager?.branch || "-"}/>
		</div>
		)
	}
	if(data.type==="CLIENT_MODULE"){
		let request = JSON.parse(data.request);

		const getNewStatus = () => {
			return request.value === 'false'
				? ' is disabled'
				: request.value === 'true'
					? ' is enabled'
					: ''
		}
		const valueString = request.type ? `${request.type}${getNewStatus()}` : '-';

		return(
			<div>
				<KeyValue title="Changed Status for module" value={valueString}/>
			</div>
		)
	}
	if (data.type === 'CLIENT_VA_KEYS') {
		const request = JSON.parse(data.request);

		return (
			<div>
				<KeyValue title="Old Partner Key" value={request?.oldPartner || '-'} />
				<KeyValue title="Old TSP Key" value={request?.oldTsp || '-'} />
				<KeyValue title="New Partner Key" value={request?.partner || '-'} />
				<KeyValue title="New TSP Key" value={request?.tsp || '-'} />
			</div>
		);
	}
	return (
		<div>

		</div>
	)
}
