import Common,{Common2,placeOfSupply,sellerAddress} from "./Common";
import Moment from "moment";

const roundOff = num => {
	return (Math.round(num * 100) / 100).toFixed(2);
}


let traveller = (item) => {
	let string = undefined;
	try {
		item.ticket.travellers.forEach((traveller) => {
			if (!string) {
				string = `${traveller.name}`;
			} else {
				string = `${string} , ${traveller.name}`;
			}
		});
	}catch (e){
		return "-";
	}
	return string;
};


// Checks whether the booking is International based on the segments
const isInternationalHotel = (booking) =>{
	// gets the criteria array from the ticket
	const criteria = booking?.ticket?.criteria;
	// if no criteria object retrun false
	if(!criteria){
		return false;
	}
	const country = criteria.country.toUpperCase();
	return country !== "INDIA";
}


const getMealPlan = (booking) =>{
	// list of allowed meal plans
	const MEALPLANS = [
		'Room Only',
		'Continental Plan',
		'Modified American Plan',
		'American Plan'
	]
	if(booking?.ticket?.mealPlan){
		return booking.ticket.mealPlan;
	}
	let roomdesc = booking?.ticket?.roomDescription || "";
	if(roomdesc){
		roomdesc = roomdesc.split("<")[0];
		if(roomdesc && MEALPLANS.includes(roomdesc)){
			return roomdesc;
		}
	}
	return "-"
}

export default function offline(item,dates){
	console.log(item)
	let values = []
	values.push(...Common(item,dates))
	values.push(
		...[
			'Flight Amendment',
			'-',
			'-',
			'-',
			'-',
			'-',
			'-',
			'-',
			'-',
			'-',
			'-',
			'-',
			'-',
			'-',
			'-',
			'-',
			'-',
			'-',
			'-',
			'-',
			'-',
			'-',
			'-',
			'-',
			'-',
			'-',
			'-',
		]
	)
	let settlement = item.item;
	let transId = item?.transId;
	if(item.reseller){
		if (settlement.financeType === 'DEBIT') {
			values.push(...[
				"YES",
				settlement.config.invoiceIrn?'Generated':"Pending",
				'Invoice',
				settlement.config.invoiceNo||"-",
				Moment(settlement.createdAt).format('DD MMM YYYY'),
				settlement.config.invoiceIrn||"-",
				settlement.config.invoice||"-",
				settlement.config.irnFare?.base,
				roundOff(settlement.config.irnFare?.base || 0),
				roundOff(settlement.config.irnFare?.oth || 0),
				roundOff(settlement.config.irnFare?.slab || 0),
				roundOff(settlement.config.irnFare?.iGst || 0),
				roundOff(settlement.config.irnFare?.sGst || 0),
				roundOff(settlement.config.irnFare?.cGst || 0),
				roundOff(settlement.config.irnFare?.total || 0),
				"-",
				settlement.config.sellerGst?.gstin||"27AAHCC1775A1ZX",
				sellerAddress(settlement.config.sellerGst||"27AAHCC1775A1ZX"),
				settlement.config.userGst?.gstin||"-",
				settlement.config.userGst?.address||"-",
				settlement.config.placeOfSupply||"-",
				settlement.config.placeOfSupply||"-",
				'996311',
				`IN - ${transId}`,
			]);
		}else{
			values.push(...[
				"YES",
				settlement.config.creditIrn?'Generated':"Pending",
				'Credit Note',
				settlement.config.crnNo||"-",
				settlement.config.creditNoteDate||"-",
				settlement.config.creditIrn||"-",
				settlement.config.creditNote||"-",
				'-',
				roundOff(-1 * settlement.config.crnFare?.base || 0),
				roundOff(-1 * settlement.config.crnFare?.oth || 0),
				roundOff(-1 * settlement.config.crnFare?.slab || 0),
				roundOff(-1 * settlement.config.crnFare?.iGst || 0),
				roundOff(-1 * settlement.config.crnFare?.sGst || 0),
				roundOff(-1 * settlement.config.crnFare?.cGst || 0),
				roundOff(-1 * settlement.config.crnFare?.total || 0),
				"-",
				settlement.config.sellerGst?.gstin||"27AAHCC1775A1ZX",
				sellerAddress(settlement.config.sellerGst||"27AAHCC1775A1ZX") || "",
				settlement.config.userGst?.gstin||"-",
				settlement.config.userGst?.address||"-",
				settlement.config.placeOfSupply||"-",
				settlement.config.placeOfSupply||"-",
				'996311',
				`CN - ${transId}`,
			]);
		}
		values.push(...Common2(item,settlement.config.invoiceNo||"-"))
	}else{
		if (settlement.financeType === 'DEBIT') {
			values.push(...[
				'No',
				'Generated',
				'Invoice + Management Fee',
				settlement.config.serviceInvoiceNo|| "-",
				settlement.config.serviceChargeDate|| "-",
				settlement.config.serviceIrn || "-",
				settlement.config.serviceInvoice|| "-",
				'-',
				roundOff(settlement.config.serviceFare?.base || 0),
				roundOff(settlement.amount - settlement.config.serviceFare?.total || 0),
				roundOff(settlement.config.serviceFare?.slab || 0),
				roundOff(settlement.config.serviceFare?.iGst || 0),
				roundOff(settlement.config.serviceFare?.sGst || 0),
				roundOff(settlement.config.serviceFare?.cGst || 0),
				roundOff(settlement.amount || 0),
				"-",
				settlement.config.sellerGst?.gstin||"-",
				settlement.config.sellerGst?.address?sellerAddress(settlement.config.sellerGst):"-",
				settlement.config.userGst?.gstin||"-",
				settlement.config.userGst?.address||"-",
				'Maharashtra',
				placeOfSupply(settlement.config.userGst?.gstin||"-"),
				'998551',
				`MFI - ${transId}`,
			]);
		}else{
			values.push(...[
				'No',
				'Generated',
				'Credit Note + Management Fee',
				settlement.config.cancellationInvoiceNo|| "-",
				settlement.config.cancellationDate|| "-",
				settlement.config.cancellationIrn || "-",
				settlement.config.cancellationCharges|| "-",
				'-',
				roundOff(settlement.config.cancellationFare?.base || 0),
				roundOff(settlement.amount - settlement.config.cancellationFare?.total || 0),
				roundOff(settlement.config.cancellationFare?.slab || 0),
				roundOff(settlement.config.cancellationFare?.iGst || 0),
				roundOff(settlement.config.cancellationFare?.sGst || 0),
				roundOff(settlement.config.cancellationFare?.cGst || 0),
				-1 * roundOff(settlement.amount || 0),
				"-",
				settlement.config.sellerGst?.gstin||"-",
				settlement.config.sellerGst?.address?sellerAddress(settlement.config.sellerGst):"-",
				settlement.config.userGst?.gstin||"-",
				settlement.config.userGst?.address||"-",
				'Maharashtra',
				placeOfSupply(settlement.config.userGst?.gstin||"-"),
				'998551',
				`MFI - ${transId}`,
			]);
		}
		values.push(...Common2(item,settlement.config.serviceInvoiceNo))
	}
	return values;
}
