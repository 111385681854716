const StatesList = [
    { label: 'Andhra Pradesh', value: 'ANDHRAPRADESH' },
    { label: 'Arunachal Pradesh', value: 'ARUNACHALPRADESH' },
    { label: 'Assam', value: 'ASSAM' },
    { label: 'Bihar', value: 'BIHAR' },
    { label: 'Chattisgarh', value: 'CHATTISGARH' },
    { label: 'Goa', value: 'GOA' },
    { label: 'Gujarat', value: 'GUJARAT' },
    { label: 'Haryana', value: 'HARYANA' },
    { label: 'Himachal Pradesh', value: 'HIMACHALPRADESH' },
    { label: 'Jharkhand', value: 'JHARKHAND' },
    { label: 'Karnataka', value: 'KARNATAKA' },
    { label: 'Kerala', value: 'KERALA' },
    { label: 'Madhya Pradesh', value: 'MADHYAPRADESH' },
    { label: 'Maharashtra', value: 'MAHARASHTRA' },
    { label: 'Manipur', value: 'MANIPUR' },
    { label: 'Meghalaya', value: 'MEGHALAYA' },
    { label: 'Mizoram', value: 'MIZORAM' },
    { label: 'Nagaland', value: 'NAGALAND' },
    { label: 'Odisha', value: 'ODISHA' },
    { label: 'Punjab', value: 'PUNJAB' },
    { label: 'Rajasthan', value: 'RAJASTHAN' },
    { label: 'Sikkim', value: 'SIKKIM' },
    { label: 'Tamil Nadu', value: 'TAMILNADU' },
    { label: 'Telangana', value: 'TELANGANA' },
    { label: 'Tripura', value: 'TRIPURA' },
    { label: 'Uttar Pradesh', value: 'UTTARPRADESH' },
    { label: 'Uttarakhand', value: 'UTTARAKHAND' },
    { label: 'West Bengal', value: 'WESTBENGAL' },
    { label: 'Andaman and Nicobar Islands', value: 'ANDAMANNICOBAR' },
    { label: 'Chandigarh', value: 'CHANDIGARH' },
    { label: 'Dadra and Nagar Haveli and Daman and Diu', value: 'DADRANAGARHAVELI' },
    { label: 'Lakshadweep', value: 'LAKSHADWEEP' },
    { label: 'Delhi', value: 'DELHI' },
    { label: 'Puducherry', value: 'PUDUCHERRY' },
    // { label: 'Ladakh', value: 'Ladakh' },
    { label: 'Jammu and Kashmir', value: 'JAMMUANDKASHMIR' }
  ];
  
  export default StatesList;