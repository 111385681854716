const ROLES = {
  SUPER: 'SUPER', // Admin access to everything
  API: 'API', // Fetch logs of all the APIs consumed
  FINANCE: 'FINANCE', // PG Reports, Da Reports, MIS Reports
  FINANCE_WRITE: 'FINANCE_WRITE', // Reacharge DA accounts, create credit accounts and PG reconciliation
  CLIENTS: 'CLIENTS', // Show list of Clients
  CLIENTS_WRITE: 'CLIENTS_WRITE', // Create new Clients
  REPORTS: 'REPORTS', // Show Download Data button if REPORTS role is passed
  APPROVALS: 'APPROVALS', // View Approvals
  APPROVALS_WRITE: 'APPROVALS_WRITE', // Approved pending approvals
  SUPPORT: 'SUPPORT', // View all transaction related data
  SUPPORT_WRITE: 'SUPPORT_WRITE', // Issue refund & mark transaction failed
};

export const YESBNK_ROLES = {
  CLIENTS: 'CLIENTS', // Show list of Clients
  CLIENTS_WRITE: 'CLIENTS_WRITE', // Create new Clients
  REPORTS: 'REPORTS', // Show Download Data button if REPORTS role is passed
  APPROVALS: 'APPROVALS', // View Approvals
  APPROVALS_WRITE: 'APPROVALS_WRITE', // Approved pending approvals
}

export const CT_ROLES = {
  FINANCE : ['API','CLIENTS','CLIENTS_WRITE','FINANCE','FINANCE_WRITE','REPORTS','SUPPORT'],
  SALES : ['API','CLIENTS','FINANCE','REPORTS','SUPPORT'],
  OPERATIONS :['API','CLIENTS','FINANCE','SUPPORT','SUPPORT_WRITE'],
}
export default ROLES;
