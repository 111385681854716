import React, {useEffect} from 'react'
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';
import Login from './Pages/Login'
import {Provider, useDispatch} from 'react-redux';
import Store from "./Redux";
import App from './Pages/App'
import {Loader, MainContainer, NotFound} from "../retro";
import './Assets/styles.css'
import {get} from "./Network/Axios";
import {AuthActions} from "./Redux/Reducers/auth";
import {useAppHook} from "./Hooks/useAppHook";
import Helmet from "./Hooks/useHelmet";

const ReduxStore = Store();

function RedirectToApp() {
	return (
		<Redirect to="/app"/>
	)
}

function RedirectToLogin() {
	return (
		<Redirect to="/"/>
	)
}

function AppWrapper() {
	const dispatch = useDispatch();
	const APP = useAppHook();
	useEffect(() => {
		if (APP.authenticated && APP.loading) {
			get('/config', (e, r) => {
				if (r) {
					dispatch({
						type: AuthActions.Config,
						data: r
					})
				}
			})
		}
	}, [APP, dispatch])
	if (!APP.authenticated) {
		return (
			<div>
				<Helmet/>
				<Switch>
					<Route path="/" component={Login} exact/>
					<Route path="/login" component={Login} exact/>
					<Route path="/app" component={RedirectToLogin}/>
					<NotFound/>
				</Switch>
			</div>
		)
	}
	if (APP.loading) {
		return (
			<div className="flex h-100h w-100w center">
				<Helmet/>
				<Loader/>
			</div>
		)
	}
	return (
		<MainContainer flexible={true} key="container">
			<Helmet/>
			<App/>
		</MainContainer>
	)
}

function Index() {
	return (
		<Provider store={ReduxStore}>
			<BrowserRouter>
				<AppWrapper/>
			</BrowserRouter>
		</Provider>
	);
}

export default Index;
