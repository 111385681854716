import React, {useEffect, useState} from 'react'
import {Route, Switch, useHistory, useParams} from "react-router-dom";
import {get} from "../../../../../Network/Axios";
import {Loader, Tabs, Wrapper} from "../../../../../../retro";
import RoomTypes from "./RoomTypes";
import Contracts from "./Contracts";

export default function index() {
	const {id} = useParams()
	const [details,setDetails] = useState({
		loading:true,
		images:[]
	})
	const HISTORY = useHistory();
	const load = id => {
		get(`/contracted/${id}/details`,(e,r)=>{
			if(r){
				setDetails({
					...r,
					loading: false
				})
			}
		})
	}
	useEffect(()=>{
		load(id)
	},[id])
	if(details.loading){
		return (<Loader/>)
	}
	const tabs = [];
	tabs.push({
		title: "Room Types",
		path: `/app/apps/contracted/${id}`
	})
	tabs.push({
		title: "Contracts",
		path: `/app/apps/contracted/${id}/contracts`
	})
	return (
		<Wrapper>
			<div className='FadeInRight-Effect navigator-web'>
				<div className="flex horizontally mt2">
					<div className='title-wrapper'>
						<div onClick={()=>{
							HISTORY.go(-1)
						}} className='back'>
							<i className="fas fa-arrow-left"/>
						</div>
					</div>
					<img src={details.image} style={{
						width:70,
						height:70,
						background:'#dedede',
						borderRadius:35,
						border:'1px solid #111',
						marginRight:16,
					}}/>
					<div >
						<h1 className="fw-bold">{details.name}</h1>
						<p>{details.address}</p>
					</div>
				</div>
			</div>
			<Tabs className="mb0" tabs={tabs}/>
			<Switch>
				<Route  path="/app/apps/contracted/:id" exact={true}>
					<RoomTypes roomTypes={details.roomTypes||[]} onAdd={add=>{
						load(id)
					}}/>
				</Route>
				<Route  path="/app/apps/contracted/:id/contracts" exact={true}>
					<Contracts/>
				</Route>
			</Switch>
		</Wrapper>
	)
}
