import React, {useEffect, useState} from 'react'
import {get, post} from "App/Network/Axios";
import {Button, FileInput, Modal, NavDivider, Navigation, TOAST, Wrapper} from "retro/index";

export default function MarketPlace() {
    const [apps,setApps] = useState([])
    const [modal,setModal] = useState(false)
    const [data,setData] = useState(undefined)
    const load = () => {
        get(`/apps`,(e,r)=>{
            if(r){
                setApps(r.apps)
            }
        })
    }
    useEffect(()=>{
        load()
    },[])
    useEffect(()=>{
        console.log(data)
    },[data])
	return (
		<Wrapper>
            {
                modal && <Modal actions={<>
                    <Button onClick={()=>{
                        post(`/apps`,data,(e,r)=>{
                            if(e){
                                TOAST.handleError(e)
                            }else{
                                TOAST.success("App creaetd successfully")
                                setModal(false)
                                setData(undefined)
                                load()
                            }
                        })
                    }} className="btn btn-primary" margin="">
                        Upload App
                    </Button>
                </>} onClose={()=>{
                    setModal(false)
                    setData(undefined)
                }} title="Add App" description="Upload manifest file to create new app">
                    <p className="text-small mb1">
                        Please upload the manifest file to create new app
                    </p>
                    <input className="w-100 pd2" type="file" multiple={false} accept="application/json"  onChange={file=>{
                        let aFile = file.target.files[0];
                        let reader = new FileReader();
                        reader.onload = function(e) {
                            setData(JSON.parse(reader.result))
                        }
                        reader.readAsText(aFile)
                    }}/>
                </Modal>
            }
            <div className="flex horizontally center-vertically">
                <div className="flex-1">
                    <Navigation
                        title='All Apps'
                        description='All pending approvals appears below'
                    />
                </div>
                <Button onClick={()=>{
                    setModal(true)
                }} margin="">
                    <i className="fa fa-add mr1"/> Add App
                </Button>
            </div>
            {
                apps.length===0 && <div className="flex center vertically mt4" style={{
                    border:'1px dashed #dedede',
                    borderRadius:'8px',
                    padding:18
                }}>
                    <i className="fa fa-layer-group"/>
                    <div className="mt1">No Apps</div>
                </div>
            }
            <div className="mt2 card-container">
                {
                    apps.map(item=>{
                        return(
                            <div className="retro-card pd2" key={item.appId}>
                                <img src={item.logo} style={{height:40}}/>
                                <p className="mt2">
                                    {item.app}
                                </p>
                                <p className="text-small">
                                    {item.description}
                                </p>
                            </div>
                        )
                    })
                }
            </div>
		</Wrapper>
	)
}
