import React, {useEffect, useRef, useState} from 'react'
import {Button, ProgressIndicator, Form, Input, TOAST, Pages} from "../../../../../../retro";
import { post } from 'App/Network/Axios';

export default function CreateClient({onNext,data, setData}) {
    const NAME = useRef();
    const GSTIN = useRef();
    const REFERENCE = useRef();
    const EMAIL = useRef();
    const CODE = useRef();
    const [gstValid,setGstValid] = useState({
		valid: false,
		message: 'Enter a valid gstin'
	});
    // validate gst function
    const validateGst = (gstin="")=>{
        if(gstin.length > 10){
            post('/validate/gstin',{gstin}, (e,r)=>{
                if(e){
                    console.log(e);
                    return;
                }
				if (r.duplicate){
					setGstValid({
						valid: false,
						message: 'Client with entered GSTIN already exist'
					});
					return;
				}
				if (!r.valid) {
					setGstValid({
						valid: false,
						message: 'Enter a valid gstin'
					});
					setData(formData => ({
						...formData,
						name: ' '
					}))
					return;
				}

                if(r.valid){
					setData(formData => ({
						...formData,
						name: r.registeredName
					}))
                    setGstValid({
						valid: true,
						message: ''
					});
                }
            })
        }
    }
    function validateClientCode(code) {
		var pattern = /^[a-zA-Z0-9\s']+$/;
	
		return pattern.test(code);
	}
    function validateEmail(email) {
        var pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return pattern.test(email);
        }
    // check gst on blur
    const onBlur = ()=>{
        const gstin = GSTIN.current.value;
        validateGst(gstin);
    }
    // check gst on load
    useEffect(()=>{
        validateGst(data.gstin);
    },[]);
    return (
        <>
            <ProgressIndicator active={1} stages={2}/>
            <Pages.Title className="mt4" title="Basic Details" description="Enter basic details to on-board client"/>
            <Form focus={true} className="mt4" containerStyle={{width:'100%',maxWidth:600}}>
                <Input defaultValue={data.gstin||""} ref={GSTIN} type="text" label="Client Gstin *" onBlur={onBlur} placeholder="enter client gstin" morph={true}/>
                <Input defaultValue={data.code||""} ref={CODE} type="text" label="Client Code *" placeholder="enter client code" morph={true}/>
                <Input defaultValue={data.name||""} ref={NAME} type="text" label="Client Name *" placeholder="enter client name" morph={true} disabled={data.name && data.name !== '' && gstValid.valid} />
                <Input defaultValue={data.email||""} ref={EMAIL} type="text" label="Email Address *" placeholder="enter email address" morph={true}/>
                <Input defaultValue={data.reference||""} ref={REFERENCE} type="text" label="Reference Id *" placeholder="enter onboarding id" morph={true}/>
                <Button onClick={()=>{
                    let name = NAME.current.value;
                    let registered = NAME.current.value;
                    let email = EMAIL.current.value;
                    let gstin = GSTIN.current.value;
                    let reference = REFERENCE.current.value;
                    let code = CODE.current.value;
                    if(!name || name[0]===" " || !/.*[a-zA-Z]+.*/.test(name)){
                        TOAST.error("Enter a valid name")
                        NAME.current.style.border = '2px solid red';
                        return
                    }
                    // GSTIN validation
                    if(!gstin){
                        GSTIN.current.style.border = '2px solid red';
                        TOAST.error("Enter a valid gstin")
                        return
                    }
					if (!gstValid.valid) {
						GSTIN.current.style.border = '2px solid red';
						TOAST.error(gstValid.message)
						return
					}
                    if(!code || code.length<2 || !validateClientCode(code)){
                        TOAST.error("Enter a valid code");
                        CODE.current.style.border = '2px solid red';
                        return
                    }
                    if(!email || !validateEmail(email)){
                        TOAST.error("Enter a valid email")
                        EMAIL.current.style.border = '2px solid red';
                        return
                    }
                    if(!reference || !validateClientCode(reference)){
                        TOAST.error("Enter a reference id")
                        REFERENCE.current.style.border = '2px solid red';
                        return
                    }
                    let data = {
                        name,email,reference,registered,code,gstin
                    }
                    onNext(data)
                }} margin="mt4">
                    Continue
                </Button>
            </Form>
        </>
    )
}
