import React, {useRef, useState} from 'react'
import {Button, Input, NavDivider, Navigation, TOAST} from "retro/index";
import {get, post} from "App/Network/Axios";

export default function RegenerateZeroChargeInvoiceController() {
    const TRANSID = useRef()
    const INVPREFIX = useRef()
    const GST = useRef()
    const [value,setValue] = useState(0)
    const [loading,setLoading] = useState(false)
    const CRNPREFIX = useRef()
    const REMARKS = useRef()
    return (
        <div>
            <Navigation backUrl={'back'} title="Regenerate Financial Cancellation Charges" />
            <NavDivider />
            <Input
                ref={TRANSID}
                type="text"
                onChange={e=>{
                    setValue(0)
                    let transId = TRANSID.current.value;
                    get(`/meta/${transId}/details`,(e,r)=>{
                        if(r){
                            setValue(`${r.ticket.fareComponent.baseFare/20}`)
                            GST.current.value = `${r.ticket.fareComponent.baseFare/20}`
                        }
                    })
                }}
                label="Transaction Id"
                placeholder="Transaction Id"
            />
            <Input
                defaultValue="INC"
                ref={INVPREFIX}
                type="text"
                label="Invoice Prefix"
                placeholder="Invoice Prefix"
            />
            <Input
                defaultValue="CR"
                ref={CRNPREFIX}
                type="text"
                label="Credit Note Prefix"
                placeholder="Credit Note Prefix"
            />
            <Input
                ref={GST}
                type="number"
                label={`Gst Amount(Calculated - ${value})`}
                placeholder="Gst Amount"
            />
            <Input
                ref={REMARKS}
                defaultValue="Updating Disputed Cases Of Zero Rs"
                type="text"
                label="Remarks"
                placeholder="Remarks"
            />
            <Button
                onClick={()=>{
                    setLoading(true)
                    let gst = GST.current.value;
                    if(!gst) {
                        TOAST.error("Enter a valid gst amount")
                        return
                    }
                    let transId = TRANSID.current.value;
                    if(!transId) {
                        TOAST.error("Enter a valid transaction id")
                        return
                    }
                    let inv = INVPREFIX.current.value;
                    if(!inv) {
                        TOAST.error("Enter a valid invoice id")
                        return
                    }
                    let crn = CRNPREFIX.current.value;
                    if(!crn) {
                        TOAST.error("Enter a valid credit note id")
                        return
                    }
                    let remarks = REMARKS.current.value;
                    if(!remarks) {
                        TOAST.error("Enter a valid remarks")
                        return
                    }
                    post(`/meta/${transId}/flight/regenerate/zero/invoice`,{
                        crn,
                        gst:parseInt(gst,10),
                        inv,
                        remarks
                    },(e,r)=>{
                        setLoading(false)
                        if(r){
                            TOAST.success("Regeneration done successfully")
                            TRANSID.current.value = ""
                            INVPREFIX.current.value = ""
                            CRNPREFIX.current.value = ""
                        }else{
                            TOAST.handleError(e)
                        }
                    })
                }}
                className="btn-black btn-primary"
                margin="pd2 mt4"
                loading={loading}
            >
                Regenerate
            </Button>
        </div>
    )
}
