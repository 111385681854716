import React, {useState} from 'react'
import {NavDivider, Navigation, Tabs} from "../../../../retro";
import AllAdmin from './AllAdmins'
import InactiveAdmins from './InactiveAdmins'

export default function Admins() {
	const [tab,setTab] = useState("all")
	return (
		<div>
			<Navigation backUrl={''} title="Admins" chips={["All Admins"]} description="Details of all admins appears below."/>
			<Tabs style={{marginBottom:'calc(-2rem - 1px)'}} className="" tabs={[
				{
					title:"Active",
					onClick:()=>{
						setTab("all")
					},
					active:tab==="all"
				},
				{
					title:"In-Active",
					onClick:()=>{
						setTab("inactive")
					},
					active:tab==="inactive"
				}
			]} active={tab}/>
			<NavDivider/>
			{
				tab==="all" && <AllAdmin/>
			}
			{
				tab==="inactive" && <InactiveAdmins/>
			}
		</div>
	)
}
