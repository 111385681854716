import React, { useState } from 'react';
import { useEffect } from 'react';
import { SidePane, StatelessTab } from 'retro/index';
import UploadCsv from './UploadCsv';
import SampleBulkUploadFormat from './SampleBulkUploadFormat';
import ShowUploadStatus from './ShowUploadStatus';

const BulkPaymentPending = ({
  setShowBulkPay,
  allQueuData,
  loadPage,
  search,
}) => {
  const [tab, setTab] = useState('Sample Format');
  const [csvData, setCsvData] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [status, setStatus] = useState([]);
  const [showStatusButton, setShowStatusButton] = useState(false);
  const [uploads, setUploads] = useState({});

  return (
    <div>
      <SidePane
        title="Bulk UTR Upload"
        description="Upload a csv file with proper fields"
        tabs={
          <StatelessTab
            onClick={setTab}
            style={{ marginBottom: 'calc(-1rem - 1px)' }}
            tabs={['Sample Format', 'Upload File', 'Report Status']}
            active={tab}
          />
        }
        onClose={() => {
          setShowBulkPay(false);
        }}
      >
        {tab === 'Sample Format' && <SampleBulkUploadFormat />}

        {tab === 'Upload File' && (
          <UploadCsv
            setCsvData={setCsvData}
            csvData={csvData}
            uploading={uploading}
            setUploading={setUploading}
            status={status}
            setStatus={setStatus}
            search={search}
            loadPage={loadPage}
            allQueuData={allQueuData}
            setShowStatusButton={setShowStatusButton}
            setUploads={setUploads}
            uploads={uploads}
          />
        )}

        {tab === 'Report Status' && (
          <>
            {!showStatusButton && (
              <div>
                <p className="mt4">Please upload a report to view status</p>
              </div>
            )}
            {showStatusButton && (
              <div>
                <ShowUploadStatus status={status} csvData={csvData} />
              </div>
            )}
          </>
        )}
      </SidePane>
    </div>
  );
};

export default BulkPaymentPending;
