import React, {useCallback, useEffect, useState} from 'react'
import {Loader, ScrollableDynamicTable, SearchBox, TOAST as Alert, Navigation, Button} from "retro";
import {get} from "App/Network/Axios";
import OpenIcon from "@atlaskit/icon/glyph/open";
import ViewRequest from "../ViewRequest";
import Moment from "moment";
import FilterDropDown from 'App/Components/Filter/FilterDropDown';
import Filter from 'retro/Filter';
import moment from 'moment';
import RBAC from 'App/HOCs/RBAC';
import ROLES from 'App/Constants/Roles';


export default function index(props) {
	let [loading, setLoading] = useState(true);
	const [id,setId] = useState(undefined)
	const [sort,setSort] = useState("createdAt")
	const [filterModal,setFilterModal] = useState(false);
	const [filter, setFilter] = useState({
		  client: [],
		  traveldate: []
	  });
	const [search, setSearch] = useState({
		page: 0,
		sort: 'createdAt',
		order: 'desc'
	})
	const [Data, SetData] = useState({
		total: 0,
		currentPage: 0,
		totalPages: 0,
		items: []
	})
	const loadPage = useCallback((search) => {
		const clientIds = filter.client.map(client => client.id);
		get(`/meta/visa/assigned`, (e, r) => {
			if (r) {
				SetData({
					...r,
					total:r.total||Data.total,
					totalPages:r.totalPages||Data.totalPages
				})
				setLoading(false)
			} else {
				Alert.handleError(e)
			}
		}, {
			offset: search.page,
			order: search.order,
			q:search.q,
			sort : search.sort,
			start: filter.traveldate.length > 0 ?
			filter.traveldate[0] === 'Upcoming 24 hours' ?
						Moment().valueOf().toString() : Moment().add(24, 'hours').valueOf().toString()
					: undefined,
			end: filter.traveldate.length > 0 ?
				filter.traveldate[0] === 'Upcoming 24 hours' ? Moment().add(24, 'hours').valueOf().toString() : Moment().add(48, 'hours').valueOf().toString()
				: undefined,
			clientIds: clientIds.length > 0 ? clientIds : undefined
		})
	}, [Data,SetData,filter.traveldate, filter.client])
	useEffect(() => {
		loadPage(search)
	}, [search,filter.traveldate, filter.client])
	useEffect(()=>{
		setSearch({
			...search,
			page : 0,
			sort
		})
	},[sort])
	if(loading){
		return (<Loader/>)
	}
	return (
		<div
		style={{
		  width: 'calc(100vw - 475px)',
		}}
	  >			 <div className='mb2 relative'>
        <Navigation title='Visa Assigned Requests' />
        <RBAC role={ROLES.REPORTS}>
          <Button
            onClick={() => {
              setLoading(true);
            


              get(`meta/visa/assigned`, (e, r) => {
                if (r) {
                  let data = [];
                  const headers = [
                    'Request Id',
                    'Client',
                    'traveller Name',
                    'traveller Email',
                    "traveller Mobile",
                    'Travel Date',
                    'Request Date',
                    "Passport",
                    "VisaType",
                  ];
                  data.push(headers);

                  r.items.forEach((item) => {

                    const {
                      client,
                      requestId,
                    } = item;
                    let line = [];
                    line.push([requestId]);
                    line.push([`${client.clientCode}`]);
                    line.push([`${item?.traveller?.name||"-"}`])
                    line.push([`${item?.traveller?.email||"-"}`])
                    line.push([`${item?.traveller?.mobile||"-"}`])
                    line.push([`${item?.visa?.startDate||"-"}`])
                    line.push([`${moment(item?.createdAt).format('DD MMM YYYY HH:mm a')||"-"}`])
                    line.push([`${item?.visa?.passportNumber||"-"}`])
                    line.push([`${item?.visa?.visaType||"-"}`])
                    data.push(line);
                  });

                  DownloadCsv(
                    `general_queue_${moment().format('DD_MM_YYYY')}}.csv`,
                    data
                  );
                }
                setLoading(false);
              }, {
                offset: 0,
              });
            }}
            loading={loading}
            className='btn btn-primary btn-sm absolute top-2 right-5'
            style={{ zIndex: 10 }}
          >
            <i className='fa fa-download mr1' />
            Download Data
          </Button>
        </RBAC>
      </div>
			  <Filter
			margin="0 -4rem"
			onSearch={(q) => {
				setSearch({
					...search,
					page: 0,
					q,
				})
			}}
			onFilter={() => {
				setFilterModal(true)
			}} 
		/>


<FilterDropDown
			shown={filterModal}
			filters={filter}
			setFilters={setFilter}
			choices={{
				client: [],
				traveldate: [
					{
						title: 'Upcoming 24 hours',
						type: 'radio'
					},
					{
						title: 'Upcoming 48 hours',
						type: 'radio'
					}
				]
			}}
			types={["Client", "traveldate"]}
			onClose={() => {
				setFilterModal(false);
			}}
		/>
			
			<div className="mt4">
				<p>
					Sort By
				</p>
				<div className="flex horizontally center-vertically mt1 mb2">
					<div  onClick={()=>{
						setSort('createdAt')
					}} className={`chip ${sort==="createdAt"?'active chip-active':''}`}>
						Created At
					</div>
					<div onClick={()=>{
						setSort('traveldate')
					}} className={`chip ${sort!=="createdAt"?'active  chip-active':''}`}>
						Travel Date
					</div>
				</div>
			</div>
			<ScrollableDynamicTable
				setSearch={(search) => {
					setSearch({
						...search,
						page: 0
					})
				}} search={search}
				tableStyle={{
					borderSpacing: 0
				}}
				setPage={page => {
					setSearch({
						...search,
						page
					})
				}}
				data={{
					current: Data.currentPage,
					total: Data.totalPages,
					items: Data.items.map(item => {
						const click = () => {
							setId(item.queueId)
						}
						return {
							content: item,
							render: [
								{
								  children: (
									<div>
									  <p className="text">{item?.requestId}</p>
									  {/* 
									  <p className='text-small text-light'>
										Quota: {item.request.attrs.quota || '-'}
									  </p>
									  <p className='text-small text-light'>
										Train: {item.request.attrs.train || '-'}
									  </p> */}
									</div>
								  ),
								  weight: 1.5,
								},
								{
								  weight: 1.5,
								  children: (
									<div>
									  <p>{item?.client?.clientName}</p>
									  
									  <p className='text-small'>{item?.traveller?.name}</p>
									  <p className='text-small'>{item?.traveller?.email}</p>
									</div>
								  ),
								},
								{
								  weight: 1.5,
								  children: (
									<div>
									  <p>{item?.visa?.country||"-"}</p>
									  {/* 
									  <p className='text-small'>{item.guests[0].name}</p>
									  <p className='text-small'>{item.guests[0].email}</p> */}
									</div>
								  ),
								},
								{
								  weight: 1,
								  title: item?.status||"-",
								},
								{
								  weight: 1,
								  title: item?.visa?.startDate||"-",
								},
								{
								  weight: 1,
								  title: moment(item?.createdAt).format('DD-MMM-YYYY')||"-",
								},
							   
								{
								  weight: 1,
								  buttons: [
									{
									  title: 'View',
									  className: 'btn-secondary btn-sm',
									  before: <OpenIcon size="small" />,
									  onClick: click,
									},
								  ],
								  actions: [
									{
									  title: 'Quick Actions',
									  actions: [
										{
										  title: 'View',
										  before: <OpenIcon size="small" />,
										  onClick: click,
										},
									  ],
									},
								  ],
								},
							  ],
						}
					})
				}}
				
				headers={[
					{
					  weight: 1.5,
					  title: 'Details',
					},
					{
					  weight: 1.5,
					  title: 'Client',
					},
					{
					  weight: 1,
					  title: 'Country',
					},
					{
					  weight: 1,
					  title: 'Queue Status',
					},
					{
					  weight: 1,
					  title: 'Travel Date',
					},
					{
					  weight: 1,
					  title: 'Request Date',
					},
					{
					  weight: 1,
					  title: 'Actions',
					  style: {
						justifyContent: 'end',
					  },
					},
				  ]}
				
				/>
			{
				id!==undefined && <ViewRequest  id={id} onClose={()=>{
					setId(undefined)
					loadPage(search)
					props.getCount()
				}}/>
			}
		</div>
	)
}
