import { post } from 'App/Network/Axios';
import CsvToJson from 'App/Utils/Csv';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Button, FileInput, TOAST } from 'retro/index';

const UploadFile = ({
  setUploading,
  setShowStatusButton,
  setCsvData,
  csvData,
  setStatus,
  uploaded,
  setUploaded,
}) => {
  const file = useRef();
  const [uploads, setUploads] = useState({});

  const uploadCsv = (file) => {
    setUploading(true);
    CsvToJson(
      file,
      (rows) => {
        setCsvData(rows);
      },
      (row) => {
        if (row.Code) return true;
        setUploading(false);
        return false;
      }
    );
  };



  useEffect(() => {
    // When csvData changes, trigger the recursiveUpload function
    if (csvData.length > 0 && !uploaded) {
      recursiveUpload(csvData, 0);
    }
  }, [csvData]); // Add csvData as a dependency to this effect

  const upload = (csvData, cb) => {
    const { Status: status, Code:clientCode } = csvData;

    let data = {
      status: status.toUpperCase(),
      clientCode:String(clientCode)
    };

    post(`clients/toggle/status`, data, (e, r) => {
      if (r) {
        TOAST.success('Status Changed Successfully');
        setStatus((prevState) => [
          ...prevState,

          {
            uploadStatus: 'Success',
            remarkMessage: 'Status Changed Successfully',
          },
        ]);
        cb('Upload completed');
      } else {
        TOAST.error(e?.data?.message);
        setStatus((prevState) => [
          ...prevState,
          {
            uploadStatus: 'Failed',
            remarkMessage: e?.data?.message,
          },
        ]);
        cb(e.data.message);
      }
    });
  };

  const recursiveUpload = (csvData, i = 0, mState = {}) => {
    if (i >= csvData.length) {
      setUploaded(true);
      setUploading(false);
      setUploads({ ...mState });
      setShowStatusButton(true);
    } else {
      const row = csvData[i];
      upload(row, (message) => {
        const key = row['Code']; // Assuming "Amount" is unique
        const state = { ...mState };
        state[key] = message;
        setUploads(state);
        recursiveUpload(csvData, i + 1, state);
      });
    }
  };

  return (
    <>
      <FileInput
        onSelect={uploadCsv}
        extensions={['.csv']}
        className="display-none"
        ref={file}
      />
      <Button
        onClick={() => {
          file.current.click();
          setShowStatusButton(false);
        }}
        className="btn btn-secondary text-small rounded-pill mr1"
      >
        <i className="fa fa-upload mr1" />
        Upload Csv
      </Button>
    </>
  );
};

export default UploadFile;
