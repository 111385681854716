import Common,{Common2,placeOfSupply,sellerAddress} from "./Common";
import Moment from "moment";

const roundOff = num => {
	return (Math.round(num * 100) / 100).toFixed(2);
}


let traveller = (item) => {
	let string = undefined;
	try {
		item.ticket.travellers.forEach((traveller) => {
			if (!string) {
				string = `${traveller.name}`;
			} else {
				string = `${string} , ${traveller.name}`;
			}
		});
	}catch (e){
		return "-";
	}
	return string;
};


let airlines = (item) => {
	let string = undefined;
	try {
		item.ticket.segments.forEach((segment) => {
			if (!string) {
				string = `${segment.airline}`;
			} else {
				string = `${string} , ${segment.airline}`;
			}
		});
	}catch (e){
		return "-";
	}
	return string;
};
let flightCode = (item) => {
	let string = undefined;
	try{
		item.ticket.segments.forEach((segment) => {
			if (!string) {
				string = `${segment.flightCode} ${segment.flightNumber}`;
			} else {
				string = `${string} , ${segment.flightCode}  ${segment.flightNumber}`;
			}
		});
	}catch (e){
		return "-"
	}
	return string;
};
let segments = (item) => {
	let string = undefined;
	try {
		item.ticket.segments.forEach((segment) => {
			if (!string) {
				string = `${segment.departure.code}-${segment.arrival.code}`;
			} else {
				string = `${string} , ${segment.departure.code}-${segment.arrival.code}`;
			}
		});
	}catch (e){
		return "-"
	}
	return string;
};
let pnrs = (item) => {
	let set = {};
	let string = undefined;
	try {
		item.ticket.travellers.forEach((traveller) => {
			Object.values(traveller.pnrs).forEach((item) => {
				set[item] = item;
			});
		});
		Object.values(set).forEach((value) => {
			if (!string) {
				string = `${value}`;
			} else {
				string = `${string} , ${value}`;
			}
		});
	}catch (e){
		return "-"
	}
	return string;
};
let tickets = (item) => {
	let set = {};
	let string = undefined;
	try {
		item.ticket.travellers.forEach((traveller) => {
			Object.values(traveller.tickets).forEach((item) => {
				set[item] = item;
			});
		});
		Object.values(set).forEach((value) => {
			if (!string) {
				string = `${value}`;
			} else {
				string = `${string} , ${value}`;
			}
		});
	}catch (e){
		return "-";
	}
	return string;
};

export default function flight(item,dates){
	let values = []
	values.push(...Common(item,dates))
	try {
		values.push(
			...[
				flightCode(item),
				airlines(item),
				traveller(item),
				'-',
				'-',
				'-',
				'-',
				'-',
				'-',
				'-',
				'-',
				'-',
				'-',
				'-',
				'-',
				'-',
				'-',
				segments(item),
				item.ticket.segments && item.ticket.segments.length ? item.ticket.segments[0].departure.city:"-",
				item.ticket.segments && item.ticket.segments.length ? item.ticket.segments[0].arrival.city:"-",
				pnrs(item),
				tickets(item),
				item.ticket.cabinClass || 'ECONOMY',
				item.ticket.fareType || '-',

				item.ticket.segments && item.ticket.segments.length?Moment(item.ticket.segments[0].departureTime).format('DD MMM YYYY'):"-",
				item.ticket.segments && item.ticket.segments.length?Moment(
					item.ticket.segments[item.ticket.segments.length - 1].arrivalTime
				).format('DD MMM YYYY'):"-",
				item.service.toUpperCase() === 'FLIGHT' ? 'One Way' : 'Round',
			]
		)
	}catch (e){
		console.log(e,item)
	}
	let settlement = item.item;
	let travellers=item?.ticket?.travellers

	let othercharge=0
	travellers?.map((i)=>{
		i?.allSequences?.map((refund)=>{
			othercharge=othercharge+refund?.platformCharges+refund?.refundAmount

		})
	})

	let transId = item?.transId;

	if(item.reseller){
		if (settlement.financeType === 'DEBIT') {
			values.push(...[
				"YES",
				settlement.config.invoiceIrn?'Generated':"Pending",
				'Invoice',
				settlement.config.invoiceNo||"-",
				Moment(settlement.createdAt).format('DD MMM YYYY'),
				settlement.config.invoiceIrn||"-",
				settlement.config.invoice||"-",
				settlement.config.irnFare?.base || 0,
				roundOff(settlement.config.irnFare?.base || 0),
				roundOff(settlement.config.irnFare?.oth || 0),
				roundOff(settlement.config.irnFare?.slab || 0),
				roundOff(settlement.config.irnFare?.iGst || 0),
				roundOff(settlement.config.irnFare?.sGst || 0),
				roundOff(settlement.config.irnFare?.cGst || 0),
				roundOff(settlement.config.irnFare?.total || 0),
				"-",
				settlement.config.sellerGst?.gstin||"27AAHCC1775A1ZX",
				sellerAddress(settlement.config.sellerGst||{gstin:"27AAHCC1775A1ZX"}),
				settlement.config.userGst?.gstin||"-",
				settlement.config.userGst?.address||"-",
				settlement.config.placeOfSupply||"-",
				settlement.config.placeOfSupply||"-",
				'996311',
				`IN - ${transId}`,
			]);
		}else{
			values.push(...[
				"YES",
				settlement.config.creditIrn?'Generated':"Pending",
				'Credit Note',
				settlement.config.crnNo||"-",
				settlement.config.creditNoteDate||"-",
				settlement.config.creditIrn||"-",
				settlement.config.creditNote||"-",
				'-',
				roundOff(-1 * settlement.config.crnFare?.base || -1 * settlement.config.irnFare?.base || 0),
				roundOff(-1 * settlement.config.crnFare?.oth || -1 * settlement.config.irnFare?.oth || 0),
				roundOff(-1 * settlement.config.crnFare?.slab || -1 * settlement.config.irnFare?.slab || 0),
				roundOff(-1 * settlement.config.crnFare?.iGst || -1 *  settlement.config.irnFare?.iGst || 0),
				roundOff(-1 * settlement.config.crnFare?.sGst || -1 * settlement.config.irnFare?.sGst || 0),
				roundOff(-1 * settlement.config.crnFare?.cGst || -1 * settlement.config.irnFare?.cGst || 0),
				roundOff(-1 * settlement.config.crnFare?.total || -1 * settlement.config.irnFare?.total || 0),
				"-",
				settlement.config.sellerGst?.gstin||"27AAHCC1775A1ZX",
				sellerAddress(settlement.config.sellerGst||{gstin:"27AAHCC1775A1ZX"}),
				settlement.config.userGst?.gstin||"-",
				settlement.config.userGst?.address||"-",
				settlement.config.placeOfSupply||"-",
				settlement.config.placeOfSupply||"-",
				'996311',
				`CN - ${transId}`,
			]);
		}
		values.push(...Common2(item,settlement.config.invoiceNo||"-"))
	}else{
		if (settlement.financeType === 'DEBIT') {
			values.push(...[
				'No',
				'Generated',
				'Invoice + Management Fee',
				settlement.config.serviceInvoiceNo|| "-",
				settlement.config.serviceChargeDate|| "-",
				settlement.config.serviceIrn || "-",
				settlement.config.serviceInvoice|| "-",
				'-',
				roundOff(settlement.config.serviceFare?.base || 0),
				roundOff(settlement.amount - settlement.config.serviceFare?.total || 0),
				roundOff(settlement.config.serviceFare?.slab || 0),
				roundOff(settlement.config.serviceFare?.iGst || 0),
				roundOff(settlement.config.serviceFare?.sGst || 0),
				roundOff(settlement.config.serviceFare?.cGst || 0),
				roundOff(settlement.amount || 0),
				"-",
				settlement.config.sellerGst?.gstin||"27AAHCC1775A1ZX",
				sellerAddress(settlement.config.sellerGst||{gstin:"27AAHCC1775A1ZX"}),
				settlement.config.userGst?.gstin||"-",
				settlement.config.userGst?.address||"-",
				'Maharashtra',
				placeOfSupply(settlement.config.userGst?.gstin||"-"),
				'998551',
				`MFI - ${transId}`,
			]);
		}else{
			if(settlement.creditNote!=="SKIPPED"){
				values.push(...[
					'No',
					'Generated',
					'Credit Memo',
					settlement.config.crnNo|| "-",
					settlement.config.cancellationDate|| "-",
					"-",
					settlement.config.creditNote|| "-",
					'-',
					roundOff(0),
					-1 * roundOff(othercharge),
					// -1 * roundOff(settlement.amount + settlement.config.cancellationFare?.total || 0),
					0,0,0,0,
					-1 * roundOff(othercharge  || 0),
					"-",
					settlement.config.sellerGst?.gstin||"-",
					settlement.config.sellerGst?.address?sellerAddress(settlement.config.sellerGst):"-",
					settlement.config.userGst?.gstin||"-",
					settlement.config.userGst?.address||"-",
					'Maharashtra',
					placeOfSupply(settlement.config.userGst?.gstin||"-"),
					'998551',
					`MFI - ${transId}`,
				]);
			}else{
				values.push(...[
					'No',
					'Generated',
					'Credit Note + Management Fee',
					settlement.config?.cancellationInvoiceNo|| "-",
					settlement.config.cancellationDate|| "-",
					settlement.config.cancellationIrn || "-",
					settlement.config.cancellationCharges|| "-",
					'-',
					roundOff(settlement.config.cancellationFare?.base || 0),
					roundOff(settlement.amount - settlement.config.cancellationFare?.total || 0),
					roundOff(settlement.config.cancellationFare?.slab || 0),
					roundOff(settlement.config.cancellationFare?.iGst || 0),
					roundOff(settlement.config.cancellationFare?.sGst || 0),
					roundOff(settlement.config.cancellationFare?.cGst || 0),
					-1 * roundOff(settlement.amount || 0),
					"-",
					settlement.config.sellerGst?.gstin||"-",
					settlement.config.sellerGst?.address?sellerAddress(settlement.config.sellerGst):"-",
					settlement.config.userGst?.gstin||"-",
					settlement.config.userGst?.address||"-",
					'Maharashtra',
					placeOfSupply(settlement.config.userGst?.gstin||"-"),
					'998551',
					`MFI - ${transId}`,
				]);
			}
		}
		values.push(...Common2(item,settlement.config.serviceInvoiceNo))
	}
	return values;
}


export  function flightCancellationLine(item,dates){
	let values = []
	values.push(...Common(item,dates))
	try {
		values.push(
			...[
				flightCode(item),
				airlines(item),
				traveller(item),
				'-',
				'-',
				'-',
				'-',
				'-',
				'-',
				'-',
				'-',
				'-',
				'-',
				'-',
				'-',
				'-',
				'-',
				segments(item),
				item.ticket.segments && item.ticket.segments.length ? item.ticket.segments[0].departure.city:"-",
				item.ticket.segments && item.ticket.segments.length ? item.ticket.segments[0].arrival.city:"-",
				pnrs(item),
				tickets(item),
				item.ticket.cabinClass || 'ECONOMY',
				item.ticket.fareType || '-',

				item.ticket.segments && item.ticket.segments.length?Moment(item.ticket.segments[0].departureTime).format('DD MMM YYYY'):"-",
				item.ticket.segments && item.ticket.segments.length?Moment(
					item.ticket.segments[item.ticket.segments.length - 1].arrivalTime
				).format('DD MMM YYYY'):"-",
				item.service.toUpperCase() === 'FLIGHT' ? 'One Way' : 'Round',
			]
		)
	}catch (e){
		console.log(e,item)
	}
	let settlement = item.item;
	let transId = item?.transId;

	values.push(...[
		'No',
		'Generated',
		'Cancellation Charges Invoice',
		settlement.config?.cancellationInvoiceNo|| "-",
		settlement.config.cancellationDate|| "-",
		settlement.config.cancellationIrn || "-",
		settlement.config.cancellationCharges|| "-",
		'-',
		roundOff(settlement.config.cancellationFare?.base || 0),
		0,
		roundOff(settlement.config.cancellationFare?.slab || 0),
		roundOff(settlement.config.cancellationFare?.iGst || 0),
		roundOff(settlement.config.cancellationFare?.sGst || 0),
		roundOff(settlement.config.cancellationFare?.cGst || 0),
		settlement.config.cancellationFare?.total,
		item?.item?.config?.refundInfos.reduce((acc,cur)=>acc+cur?.airlineCharges,0)||"-",
		settlement.config.sellerGst?.gstin||"-",
		settlement.config.sellerGst?.address?sellerAddress(settlement.config.sellerGst):"-",
		settlement.config.userGst?.gstin||"-",
		settlement.config.userGst?.address||"-",
		'Maharashtra',
		placeOfSupply(settlement.config.userGst?.gstin||"-"),
		'998551',
		`MFI - ${transId}`,
	]);
	values.push(...Common2(item,settlement.config.serviceInvoiceNo))
	return values;
}
