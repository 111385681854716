import React, {useRef, useState} from 'react'
import {Button, Input, Navigation, TOAST, Wrapper} from "../../../../../../retro";
import {post} from "../../../../../Network/Axios";
import BulkUpload from "./BulkUpload";

export default function Index({}) {
    const EMAIL = useRef();
    const [upload,setUpload] = useState(false)
    const REMARKS = useRef();
    const COINS = useRef();
    const PASSWORD = useRef();
	return (
        <Wrapper id="wrapper-nav">
            <Navigation title="Reward Coins" chips={["Perks","Reward"]} description="Reward coins to end user."/>
            <Button onClick={()=>{setUpload(true)}} className="btn btn-black mt4 mb4">
                Bulk Upload
            </Button>
            <Input className="mt4" ref={EMAIL} type="text" label="Email Address" placeholder="enter email address" morph={true}/>
            <Input ref={REMARKS} type="text" label="Remarks" placeholder="enter remarks" morph={true}/>
            <Input ref={COINS} type="text" label="Coins" placeholder="enter number of coins" morph={true}/>
            <Input ref={PASSWORD} type="text" label="Password" placeholder="enter password" morph={true}/>
            <Button onClick={()=>{
                let email = EMAIL.current.value;
                let remarks = REMARKS.current.value;
                let password = PASSWORD.current.value;
                let coins = COINS.current.value;
                if(!email){
                    TOAST.error("Enter a valid email")
                    return;
                }
                if(!remarks){
                    TOAST.error("Enter a valid remarks")
                    return;
                }
                if(!password){
                    TOAST.error("Enter a valid password")
                    return;
                }
                if(!coins){
                    TOAST.error("Enter a valid coins")
                    return;
                }
                let data = {
                    email,
                    remarks,
                    password,
                    coins : parseInt(coins,10)
                }
                post(`personal/coins`,data,(e,r)=>{
                    if(e){
                        TOAST.handleError(e)
                    }else{
                        EMAIL.current.value = ""
                        REMARKS.current.value = ""
                        PASSWORD.current.value = ""
                        COINS.current.value = ""
                        TOAST.success("Coins rewarded successfully!")
                    }
                })
            }} className="btn btn-black" margin="mt4">
                Reward Coins
            </Button>
            {
                upload && <BulkUpload onClose={()=>{setUpload(false)}}/>
            }
        </Wrapper>
	)
}
