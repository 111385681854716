import React, {useRef} from 'react'
import CsvToJson from "../../../../../Utils/Csv";
import {Button, FileInput} from "../../../../../../retro";
import Template from './Template.csv'

export default function Step1({onNext}) {
	const FILE = useRef();
	const BulkUpload = (file) => {
		CsvToJson(file,(rows) => {
			onNext(rows)
		},row=>{
			if(row.HotelId && row.RoomName)
				return true;
			return false;
		})
	}
	return (
		<div className="pd4 border mt4">
			<h2 className="fw-bold">
				Upload Contracted Hotels
			</h2>
			<div className='text-small mb2'>
				Add your contracted hotels in one go by uploading CSV file with client details. Download CSV template first to know what fields are required.
			</div>
			<FileInput onSelect={BulkUpload} extensions={['.csv']} className='display-none' ref={FILE}/>
			<Button onClick={()=>FILE.current.click()} className='btn btn-secondary mr2'><i className='fa fa-upload mr1'/>Upload File</Button>
			<Button onClick={()=>window.open(Template,'_blank')} className='btn btn-secondary'><i className='fa fa-download mr1'/>Download Template</Button>
		</div>
	)
}
