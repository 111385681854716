import React from 'react';
import { DownloadCsv } from 'retro/Utils';
import { Button } from 'retro/index';

const SampleBulkUploadFormat = () => {
  const downloadSampleData = () => {
    let data = [];
    let headers = ['TransId', 'Payment Type', 'UTR', 'Remarks'];
    data.push(headers);

    DownloadCsv('Bulk_utr_format', data);
  };

  return (
    <div>
      <Button
        onClick={downloadSampleData}
        className="btn mt4 btn-secondary text-small rounded-pill mr1"
        // style={{ float: "right" }}
      >
        <i className="fa fa-download mr1" />
        Download Sample Format For Bulk Pay
      </Button>
    </div>
  );
};

export default SampleBulkUploadFormat;
