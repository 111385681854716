import JSZip from 'jszip'
import {saveAs} from 'file-saver'
import * as JSZipUtils from 'jszip-utils'

const downloadAll = (name,urls=[],cb=()=>{}) => {
	const zip = new JSZip();
	let count = 0;
	let zipFilename = name + ".zip";
	urls.forEach(function({title,file}){
		JSZipUtils.getBinaryContent(file, function (err, data) {
			if(err) {
				count++;
				return;
			}
			zip.file(title, data, {binary:true});
			count++;
			if (count === urls.length) {
				zip.generateAsync({type:'blob'}).then(function(content) {
					saveAs(content, zipFilename);
					cb()
				});
			}
		});
	});
}

export {downloadAll}
