import React, { useState } from 'react';
import { Button, DateRange, Navigation, TOAST } from 'retro';
import Moment from 'moment';
import { get } from 'App/Network/Axios';
import { DownloadCsv } from 'retro/Utils';
import Graph from './graph-stats-circle.svg';
import RBAC from 'App/HOCs/RBAC';
import ROLES from 'App/Constants/Roles';
import { placeOfSupply } from '../Flight/BosTravellerFile/Utils';

export default function index() {
  const [Dates, setDates] = useState();
  const [loading, setLoading] = useState(false);
  const downloadData = () => {
    setLoading(true);
    get(
      `/meta/mis/amendment`,
      (e, r) => {
        // if (e) {
        //   setLoading(false);
        //   TOAST.error('Unable to download');
        //   return;
        // }
        let data = [];
        let headers = [
          'Id',
          'Trans Id',
          'Refrence Id',
          'Parent Refrence Id',
          'Provider',
          'Booking Status',
          'Client Code',
          'Cust Code',
          'Gstin',
          'Pan',
          'Tan',
          'Client name',
          'Created On Date',
          'Created On Time',
          'CT Gstin',
          'Ct State',
          'Buyer Entity',
          'Buyer Gstin',
          'State Of Supply',
          'Payment Type',
          'Amount Collected',
          'Line Item Amount',
          'Ledger Id',
          'Ledger Amount',
          'Ledger Balance',
          'Hsn',
          'Document Type',
          'Document Number',
          'Document Irn',
          'Document File', //
          'Booking Voucher',
          'Document Date' /* Invoice Date */,
          'Hotel Name',
          'Hotel State',
          'Hotel Check In',
          'Hotel Check Out',
          'Traveller Name',
          'Traveller Email',
          'Traveller Gender',
          'Traveller Type',
          'PNR',
          'Base Fare',
          'Slab',
          'Other Charges',
          'SGST',
          'CGST',
          'IGST',
          'Total',
          'Remarks',
          'Invoice Error',
        ];
        data.push(headers);
        if (e) {
          console.log('error', e);
        }
        r?.items.map((item) => {
          console.log('item', item);
          // const parsedServiceRequest = JSON.parse(
          //   item?.invoiceConfig?.serviceRequest || {}
          // );
          // console.log('parsedServiceRequest', parsedServiceRequest);
          const { invoiceRequest } = item.invoiceConfig;

          /* Parse Invoice date if present */
          const parsedInvoiceRequest = invoiceRequest
            ? JSON.parse(invoiceRequest)
            : {};
          const invoiceDate = parsedInvoiceRequest
            ? Moment(
                parsedInvoiceRequest?.data?.docDtls?.dt,
                'DD/MM/YYYY'
              ).format('DD MMM YYYY')
            : '-';
          // let nights = Moment(
          //   item.ticket.criteria.dates.out,
          //   'DD-MM-YYYY'
          // ).diff(Moment(item.ticket.criteria.dates.in, 'DD-MM-YYYY'), 'days');
          data.push([
            item?.id || '-',
            item?.transId || '-',
            item.referenceId || '-',
            item?.attrs?.['parent_reference_id'] || '-',
            item?.provider || '-',
            item?.status || '-',
            item?.client?.code || '-',
            '-',
            item.invoiceConfig?.userGst?.gstin || '-',
            item.attrs?.pan || '-',
            item.attrs?.tan || '-',
            item?.client?.name || '-',
            Moment(item.createdAt).format('DD MMM YYYY'),
            Moment(item.createdAt).format('HH:mm'),
            item.invoiceConfig?.sellerGst?.name || '-',
            item.invoiceConfig?.sellerGst?.gstin || '-',
            item.invoiceConfig?.userGst?.name || '-',
            item.invoiceConfig?.userGst?.gstin || '-',
            placeOfSupply(item.invoiceConfig?.userGst?.gstin || undefined),
            item?.paymentType || '-',
            item?.amount || '-',
            item.invoiceConfig?.irnFare?.total || '-',
            item.ledger?.ledgerId || '-',
            item.ledger?.amount || '-',
            item.ledger?.balance || '-',
            // parsedServiceRequest?.data?.itemList?.[0]?.hsnCd || '-',
            "996311",
            'Invoice',
            item.invoiceConfig?.invoiceNo || '-',
            item.invoiceConfig?.invoiceIrn,
            item.invoiceConfig?.invoice || '-',
            item?.invoiceConfig?.file || '-',
            invoiceDate,
            item.booking?.ticket?.name || '-',
            item.booking?.ticket?.state || '-',
            item.booking?.ticket?.date?.in || '-',
            item.booking?.ticket?.date?.out || '-', //
            traveller(item, 'name'),
            traveller(item, 'email'),
            traveller(item, 'gender'),
            traveller(item, 'type'),
            item.ticket?.ticket || item.ticket?.pnr || '-',
            item.invoiceConfig?.irnFare?.base || '0',
            item.invoiceConfig?.irnFare?.slab || '-',
            item.invoiceConfig?.irnFare?.oth || '0',
            item.invoiceConfig?.irnFare?.sGst || '0',
            item.invoiceConfig?.irnFare?.cGst || '0',
            item.invoiceConfig?.irnFare?.iGst || '0',
            item.invoiceConfig?.irnFare?.total || '0',
            item?.invoiceConfig?.invoiceResponse?.message ||
              '-' /* Remarks Column */,
            item?.invoiceConfig?.invoiceError ||
              '-' /* Invoice Error message */,
          ]);
        });
        DownloadCsv(
          `amendmentReport_${Moment(Dates.startDate).format(
            'DD_MM_YYYY'
          )}_${Moment(Dates.endDate).format('DD_MM_YYYY')}.csv`,
          data
        );
        setLoading(false);
      },
      {
        start: Dates.startDate,
        end: Dates.endDate,
      }
    );
  };

  let traveller = (item, detail) => {
    let string = undefined;
    item?.ticket?.travellers?.forEach((traveller) => {
      if (!string) {
        string = `${traveller[detail]}`;
      } else {
        string = `${string} , ${traveller[detail]}`;
      }
    });
    return string || '-';
  };
  return (
    <div style={{ width: '100%' }}>
      <Navigation
        backUrl={'back'}
        title="Amendment Report"
        chips={['Meta', 'All Bookings']}
        description="Amendment report data appears below."
      />
      <div
        style={{
          margin: '0 -4rem',
          padding: '2rem 4rem',
          borderBottom: '1px solid #dedede',
        }}
      >
        <div className="flex horizontally center-vertically">
          <div className="flex-1">
            <DateRange
              defaultValue={{
                startDate: Moment().add(-31, 'd').valueOf(),
                endDate: Moment().valueOf(),
              }}
              onChange={(dates) => {
                setDates({
                  ...dates,
                  page: 0,
                });
              }}
              className=""
              label="Export from and to"
              type="range"
            />
          </div>
        </div>
      </div>
      <div>
        <RBAC role={ROLES.REPORTS}>
          <Button
            loading={loading}
            onClick={downloadData}
            className="btn btn-black"
          >
            Download Data
          </Button>
        </RBAC>

        <div className="border pd6 mt4 flex vertically center">
          <img
            alt="fetch"
            src={Graph}
            style={{ width: '5rem', height: '5rem' }}
          />
          <h3 className="fw-bold mt4">No Data Found</h3>
          <p>No data found in this configuration</p>
        </div>
      </div>
    </div>
  );
}
