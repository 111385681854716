import React, {useContext, useEffect, useRef, useState} from 'react'
import {Button, Input, Loader, Seperator, TOAST} from "retro/index";
import { post } from 'App/Network/Axios';

export default function FlightCancelBooking({transId,cancelBooking, onReload, setCancellationModal}) {
	const [loading,setLoading] = useState(false)
    const [sequences,setSequences] = useState(undefined)
	const [selected,setSelected] = useState({})
	const [cancellationCharges,setCancellationCharges] = useState(undefined);
    const [cancellationData, setCancellationData]=useState({});
    const [fetchedCancelledData, setFetchedCancelletedData]=useState(false);
    const REMARKS=useRef();

    useEffect(()=>{
        post(`/meta/${transId}/sequences`,{},(e,r)=>{
			if(r){
				setSequences(r)
			}else{
				setSequences({
					supportPartial : false
				})
			}
        })
    },[transId])
    if(!sequences){
       return  (
		   <Loader/>
       )
    }
	if(!sequences.supportPartial){
		return (
			<>
				<p>
					This booking doesn't supports partial cancellation . Kindly enter details below to cancel full booking.
				</p>
				<Input label='Remarks' className='' placeholder='Enter Remarks' ref={REMARKS}/>
				<Seperator/>
				<Button loading={loading} onClick={()=>{
					setLoading(true);
                    let remarks=REMARKS.current.value;
                    post(`/meta/cancel/${transId}/flight`,{remarks},(e,r)=>{
                        setLoading(false)
                        if(e){
                            TOAST.handleError(e)
                        }else{
                            TOAST.success("Booking cancelled successfully")
                            setCancellationModal(false)
                            onReload();
                        }
                    })
				}} className="btn btn-black" margin="">
					Cancel Booking
				</Button>
			</>
		)
	}
	let onwards = {};
	let returns = {}
	sequences.travellers.forEach(item=>{
		if(item.onwardJourney){
			onwards[`${item.firstName} ${item.lastName}`] = {
				...item,
				journey:item.onwardJourney,
				type:"onward"
			}
		}
		if(item.returnJourney){
			returns[`${item.firstName} ${item.lastName}`] = {
				...item,
				journey:item.returnJourney,
				type:"return"
			}
		}
	})
	return (
		<>

			{
				cancellationCharges && cancellationCharges> 0 ? <>
					<div className="border rounded-md mb2">
						{
							cancellationCharges > 10 ? <div className="bg-container pd2 flex horizontally fw-500 center-vertically">
								<div style={{height:30,width:4,background:"#6367FA",marginRight:12}}/>
								You will get {cancellationCharges} refund amount for selected booking.
							</div>:<div className="bg-container rounded-md pd2 flex horizontally fw-500">
								<div style={{height:30,width:4,background:"#6367FA",marginRight:12}}/>
								We are unable to fetch refund amount. You can still go ahead with partial cancellation
							</div>
						}
						{
							fetchedCancelledData &&
							<div className="pd2">
								<div className="mb2 pb2" style={{
									borderBottom:'1px solid #dedede'
								}}>
									<h3 className="fw-500 mb1">
										Selected Travellers :
									</h3>
									{
										Object.keys(selected).map(item=>{
											return (<div className="mb1" key={item}>
												{item.replace("-"," ").replace(":"," - ").toUpperCase()} JOURNEY
											</div>)
										})
									}
								</div>
								<div className='mt2'>
									<p className='mb2'><span className='fw-bold'>Airline Refund: </span> {cancellationData?.airlineCharges}</p>
									<p className='mb2'><span className='fw-bold'>Platform Charges: </span> {cancellationData?.platformCharges}</p>
									<div className="pt1 pb1" style={{
										borderTop:'1px solid #dedede'
									}}/>
									<p className='mb2'><span className='fw-bold'>Total Refund: </span> {cancellationData?.refundAmount}</p>
								</div>
							</div>
						}
					</div>
					<Input label='Remarks' className='' placeholder='Enter Remarks' ref={REMARKS}/>
					<Seperator/>
					<Button loading={loading} onClick={()=>{
						setLoading(true)
					let remarks=REMARKS.current.value;
					if(!remarks){
						TOAST.error('Please Enter Remarks');
						setLoading(false);
						return;
					}	
						post(`/meta/cancel/${transId}/flight/partial`, {remarks,transId,travellers:Object.keys(selected)}, (e,r)=>{
							if(r){
								onReload()
								TOAST.success('Booking Cancelled');
                                setLoading(false);
                                setCancellationModal(false);
							}else if(e){
								TOAST.handleError(e);
								setLoading(false);
							}
						})
					}} className="btn btn-black" margin="">
						Cancel Booking
					</Button>
				</>:<>
					<h4>
						Onward Journey
					</h4>
					{
						Object.values(onwards).map(item=>{
							return (
								<div className="mt2 flex horizontally ">
									{
										item.journey.status==="PENDING" || item.journey.status==="CONFIRMED"?<div onClick={()=>{
											if(selected[`${item.firstName}-${item.lastName}:onward`]) {
												let ns = {...selected}
												delete ns[`${item.firstName}-${item.lastName}:onward`]
												setSelected(ns)
											}else {
												let ns = {...selected}
												ns[`${item.firstName}-${item.lastName}:onward`] = true
												setSelected(ns)
											}
										}} style={{
											border:'1px solid #dedede',
											cursor:'pointer',
											background:selected[`${item.firstName}-${item.lastName}:onward`]?"#6367FA":"#fff",
											width:12,
											height:12,
											marginRight:8,
											marginTop:8
										}}/>:<div style={{
											border:'1px solid #dedede',
											background:"#dedede",
											width:12,
											height:12,
											marginRight:8,
											marginTop:8
										}}/>
									}
									<div className="text-truncate" style={{
										flex:1
									}}>
										<p className="text-truncate">
											{item.firstName} {item.lastName} ({item.journey.segments.map((item,index)=>{
											return `${index!==0?', ':''}${item.origin} - ${item.destination} ${item.pnr}`
										})})
										</p>
										<p className="text-small">
											{item.journey.status==="REFUNDING" && "This booking has been cancelled"}
											{item.journey.status==="PASSED" && "This booking has been completed"}
										</p>
									</div>
								</div>
							)
						})
					}
					{
						Object.keys(returns).length>0 && <>
							<h4 className="mt4">
								Return Journey
							</h4>
							{
								Object.values(returns).map(item=>{
									return (
										<div className="mt2 flex horizontally ">
											{
												item.journey.status==="PENDING" || item.journey.status==="CONFIRMED"?<div onClick={()=>{
													if(selected[`${item.firstName}-${item.lastName}:return`]) {
														let ns = {...selected}
														delete ns[`${item.firstName}-${item.lastName}:return`]
														setSelected(ns)
													}else {
														let ns = {...selected}
														ns[`${item.firstName}-${item.lastName}:return`] = true
														setSelected(ns)
													}
												}} style={{
													border:'1px solid #dedede',
													cursor:'pointer',
													background:selected[`${item.firstName}-${item.lastName}:return`]?"#6367FA":"#fff",
													width:12,
													height:12,
													marginRight:8,
													marginTop:8
												}}/>:<div style={{
													border:'1px solid #dedede',
													background:"#dedede",
													width:12,
													height:12,
													marginRight:8,
													marginTop:8
												}}/>
											}
											<div className="text-truncate" style={{
												flex:1
											}}>
												<p className="text-truncate">
													{item.firstName} {item.lastName} ({item.journey.segments.map((item,index)=>{
													return `${index!==0?', ':''}${item.origin} - ${item.destination} ${item.pnr}`
												})})
												</p>
												<p className="text-small">
													{item.journey.status==="REFUNDING" && "This booking has been cancelled"}
													{item.journey.status==="PASSED" && "This booking has been completed"}
												</p>
											</div>
										</div>
									)
								})
							}
						</>
					}
					<Seperator margin={4}/>
					<Button onClick={()=>{
						if(Object.keys(selected).length===0){
							TOAST.error("Kindly select segment to cancel")
							return
						}
						setLoading(true)
						post(`meta/${transId}/sequences/charges`,{bookingId:transId,segments:Object.keys(selected)},(e,r)=>{
							if(r && r.refundAmount){
								setFetchedCancelletedData(true)
								setCancellationCharges(r.refundAmount);
								setCancellationData(r);
                                onReload();
							}else{
								setSequences({
									supportPartial:false
								})
							}
							setLoading(false)
						})
					}}  loading={loading} className="btn btn-black" margin="mt0">
						Fetch Cancellation Charges
					</Button>
				</>
			}
		</>
	)
}
