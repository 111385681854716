import Common, {
	Common2,
	placeOfSupply,
	sellerAddress
} from "./Common";

const roundOff = num => {
	return (Math.round(num * 100) / 100).toFixed(2);
}


let traveller = (item) => {
	let string = undefined;
	try {
		item.ticket.travellers.forEach((traveller) => {
			if (!string) {
				string = `${traveller.name}`;
			} else {
				string = `${string} , ${traveller.name}`;
			}
		});
	}catch (e){
		return "-";
	}
	return string;
};

export default function busLine(item,dates){
	let values = []
	values.push(...Common(item,dates))
	try {
		values.push(
			...[
				item.ticket?.trainNumber || "-", //product code
				item.ticket?.trainName || "-", //product name
				traveller(item),
				"-",
				"-",
				"-",
				"-",
				"-",
				"-",
				"-",
				"-",
				"-",
				"-",
				"-",
				"-",
				"-",
				"-",
				`${item.allFields?.origin} - ${item.allFields?.destination}` || "-",
				item.allFields?.origin || "-",
				item.allFields?.destination || "-",
				item.ticket?.trainPnr || "-",
				item.ticket?.trainPnr || "-",
				item.ticket?.cabinClass || "-",
				"-",
				item.ticket?.date || "-",
				item.ticket?.date || "-",
				'One Way'
			]
		)
	}catch (e){
		console.log(e)
	}
	let settlement = item.item;
	let transId = item?.transId;
	if (settlement.financeType === 'DEBIT') {
		values.push(...[
			'No',
			'Generated',
			'Invoice + Management Fee',
			settlement.config.serviceInvoiceNo||"-",
			settlement.config.serviceChargeDate||"-",
			settlement.config.serviceIrn || "-",
			settlement.config.serviceInvoice||"-",
			'-',
			roundOff(settlement.config.serviceFare?.base || 0),
			roundOff(settlement.amount - settlement.config.serviceFare?.total || 0),
			roundOff(settlement.config.serviceFare?.slab || 0),
			roundOff(settlement.config.serviceFare?.iGst || 0),
			roundOff(settlement.config.serviceFare?.sGst || 0),
			roundOff(settlement.config.serviceFare?.cGst || 0),

			roundOff(settlement.amount || 0),
			"-",
			settlement.config.sellerGst?.gstin||"-",
			settlement.config.sellerGst?.address?sellerAddress(settlement.config.sellerGst):"-",
			settlement.config.userGst?.gstin||"-",
			settlement.config.userGst?.address||"-",
			'Maharashtra',
			placeOfSupply(settlement.config.userGst?.gstin||"-"),
			'998551',
			`MFI - ${transId}`,
		]);
	}else{
		if(settlement.creditNote!=="SKIPPED"){
			values.push(...[
				'No',
				'Generated',
				'Credit Memo',
				settlement.config.crnNo|| "-",
				settlement.config.cancellationDate|| "-",
				"-",
				settlement.config.creditNote|| "-",
				'-',
				roundOff(0),
				-1 * roundOff(settlement.amount + settlement.config.cancellationFare?.total || 0),
				0,0,0,0,
				-1 * roundOff(settlement.amount +  settlement.config.cancellationFare?.total || 0 || 0),
				"-",
				settlement.config.sellerGst?.gstin||"-",
				settlement.config.sellerGst?.address?sellerAddress(settlement.config.sellerGst):"-",
				settlement.config.userGst?.gstin||"-",
				settlement.config.userGst?.address||"-",
				'Maharashtra',
				placeOfSupply(settlement.config.userGst?.gstin||"-"),
				'998551',
				`MFI - ${transId}`,
			]);
		}else{
			values.push(...[
				'No',
				'Generated',
				'Credit Note + Management Fee',
				settlement.config?.cancellationInvoiceNo|| "-",
				settlement.config.cancellationDate|| "-",
				settlement.config.cancellationIrn || "-",
				settlement.config.cancellationCharges|| "-",
				'-',
				roundOff(settlement.config.cancellationFare?.base || 0),
				roundOff(settlement.amount - settlement.config.cancellationFare?.total || 0),
				roundOff(settlement.config.cancellationFare?.slab || 0),
				roundOff(settlement.config.cancellationFare?.iGst || 0),
				roundOff(settlement.config.cancellationFare?.sGst || 0),
				roundOff(settlement.config.cancellationFare?.cGst || 0),
				-1 * roundOff(settlement.amount || 0),
				"-",
				settlement.config.sellerGst?.gstin||"-",
				settlement.config.sellerGst?.address?sellerAddress(settlement.config.sellerGst):"-",
				settlement.config.userGst?.gstin||"-",
				settlement.config.userGst?.address||"-",
				'Maharashtra',
				placeOfSupply(settlement.config.userGst?.gstin||"-"),
				'998551',
				`MFI - ${transId}`,
			]);
		}
	}
	values.push(...Common2(item,settlement.config.serviceInvoiceNo))
	return values;
}


export function trainCancellationLine(item,dates){
	let values = []
	values.push(...Common(item,dates))
	try {
		values.push(
			...[
				item.ticket?.trainNumber || "-", //product code
				item.ticket?.trainName || "-", //product name
				traveller(item),
				"-",
				"-",
				"-",
				"-",
				"-",
				"-",
				"-",
				"-",
				"-",
				"-",
				"-",
				"-",
				"-",
				"-",
				`${item.allFields?.origin} - ${item.allFields?.destination}` || "-",
				item.allFields?.origin || "-",
				item.allFields?.destination || "-",
				item.ticket?.trainPnr || "-",
				item.ticket?.trainPnr || "-",
				item.ticket?.cabinClass || "-",
				"-",
				item.ticket?.date || "-",
				item.ticket?.date || "-",
				'One Way'
			]
		)
	}catch (e){
		console.log(e)
	}
	let settlement = item.item;
	let transId = item?.transId;
	values.push(...[
		'No',
		'Generated',
		'Cancellation Charges Invoice',
		settlement.config?.cancellationInvoiceNo|| "-",
		settlement.config.cancellationDate|| "-",
		settlement.config.cancellationIrn || "-",
		settlement.config.cancellationCharges|| "-",
		'-',
		roundOff(settlement.config.cancellationFare?.base || 0),
		0,
		roundOff(settlement.config.cancellationFare?.slab || 0),
		roundOff(settlement.config.cancellationFare?.iGst || 0),
		roundOff(settlement.config.cancellationFare?.sGst || 0),
		roundOff(settlement.config.cancellationFare?.cGst || 0),
		settlement.config.cancellationFare?.total,
		"-",
		settlement.config.sellerGst?.gstin||"-",
		settlement.config.sellerGst?.address?sellerAddress(settlement.config.sellerGst):"-",
		settlement.config.userGst?.gstin||"-",
		settlement.config.userGst?.address||"-",
		'Maharashtra',
		placeOfSupply(settlement.config.userGst?.gstin||"-"),
		'998551',
		`MFI - ${transId}`,
	]);
	values.push(...Common2(item,settlement.config.serviceInvoiceNo))
	return values;
}
