import React from "react";
import {
  Button,
  Headings,
  Icons,
  Navigation,
  Texts,
  TOAST as Alert,
  Wrapper,
} from "../../../../../retro";
import { useHistory } from "react-router-dom";
import BookIcon from "@atlaskit/icon/glyph/book";
import Person from "@atlaskit/icon/glyph/person";
import Status from "@atlaskit/icon/glyph/status";
import { get } from "../../../../Network/Axios";
import { DownloadCsv } from "../../../../../retro/Utils";

export default function Categories() {
  const HISTORY = useHistory();
  return (
    <Wrapper id='wrapper-nav'>
      <Navigation
        title='All Categories'
        chips={["Perks", "Categories"]}
        description='Details of all categories appears below.'
      />
      <div className='card-container mt5'>
        <div className='retro-card'>
          <div className='relative '>
            <BookIcon size='xlarge' />
            <Headings.Regular margin='mt4'>Flight</Headings.Regular>
            <Texts.Small style={{ maxLines: 2 }} className=''>
              All flight details are here
            </Texts.Small>
            <Button
              onClick={() => {
                HISTORY.push("/app/apps/perks/flights");
              }}
              className='mt3 btn-max btn-primary'
            >
              <Icons.View size='small' />
              View
            </Button>
          </div>
        </div>
        <div className='retro-card'>
          <div className='relative '>
            <BookIcon size='xlarge' />
            <Headings.Regular margin='mt4'>Hotel</Headings.Regular>
            <Texts.Small style={{ maxLines: 2 }} className=''>
              All hotels details are here
            </Texts.Small>
            <Button
              onClick={() => {
                HISTORY.push("/app/apps/perks/hotels");
              }}
              className='mt3 btn-max btn-primary'
            >
              <Icons.View size='small' />
              View
            </Button>
          </div>
        </div>
        <div className='retro-card'>
          <div className='relative '>
            <BookIcon size='xlarge' />
            <Headings.Regular margin='mt4'>Bus</Headings.Regular>
            <Texts.Small style={{ maxLines: 2 }} className=''>
              All bus details are here
            </Texts.Small>
            <Button
              onClick={() => {
                HISTORY.push("/app/apps/perks/bus");
              }}
              className='mt3 btn-max btn-primary'
            >
              <Icons.View size='small' />
              View
            </Button>
          </div>
        </div>
        <div className='retro-card'>
          <div className='relative '>
            <Person size='xlarge' />
            <Headings.Regular margin='mt4'>Users</Headings.Regular>
            <Texts.Small style={{ maxLines: 2 }} className=''>
              All user dump can be download here
            </Texts.Small>
            <Button
              onClick={() => {
                get(`/personal/users/dump`, (e, r) => {
                  if (r) {
                    DownloadCsv("users_dump.csv", r.data);
                  } else {
                    Alert.handleError(e);
                  }
                });
              }}
              className='mt3 btn-max btn-primary'
            >
              <Icons.View size='small' />
              Download User Dump
            </Button>
          </div>
        </div>
        <div className='retro-card'>
          <div className='relative '>
            <Status size='xlarge' />
            <Headings.Regular margin='mt4'>Reward Coins</Headings.Regular>
            <Texts.Small style={{ maxLines: 2 }} className=''>
              Reward coins to user one time
            </Texts.Small>
            <Button
              onClick={() => {
                HISTORY.push(`/app/apps/perks/reward`);
              }}
              className='mt3 btn-max btn-primary'
            >
              Reward Coins
            </Button>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}
