import React, { useEffect, useState } from 'react';
import { Button, DateRange, Navigation, TOAST } from 'retro/index';
import Moment from 'moment';
import { get } from 'App/Network/Axios';
import { DownloadCsv } from 'retro/Utils';
import Graph from './graph-stats-circle.svg';
import RBAC from 'App/HOCs/RBAC';
import ROLES from 'App/Constants/Roles';
import MultiSelectDropdown from 'App/Components/MultiSelectDropdown';
import {fetchData, fetchDataChunked} from 'App/Utils/Utils';
import { placeOfSupply } from '../Flight/BosTravellerFile/Utils';

const Invoices = () => {
  const [Dates, setDates] = useState({
    startDate: Moment().add(-31, 'd').valueOf(),
    endDate: Moment().valueOf(),
  });
  const [loading, setLoading] = useState(false);
  const [selectedClients, setselectedClients] = useState([]);
  const [clients, setClients] = useState([]);

  // get the list of clients for dorpdown
  useEffect(() => {
    get(
      '/clients/dump',
      (e, r) => {
        if (r) {
          setClients([
            ...r.items.map((client) => {
              return { code: client.code, id: client.id };
            }),
          ]);
        }
      },
      { active: true }
    );
  }, []);

  const downloadData = () => {
    setLoading(true);
    // setting the filter by ids based on client code
    let clientsFilter = [];
    if (selectedClients.length > 0) {
      clientsFilter = clients
        .filter((client) => {
          if (selectedClients.includes(client.code)) {
            return client.id;
          }
        })
        .map((client) => client.id);
    }
	  fetchDataChunked(
		  `/meta/mis/invoice/success/chunked`,
		  Dates.startDate,
		  Dates.endDate,
		  (items, e) => {
			  if (e) {
				  setLoading(false);
				  console.log(e);
				  TOAST.error('Error downloading report');
				  return;
			  }
			  let data = [];
			  let headers = [
				  'Booking Id',
				  'Category',
				  'Reseller',
				  'Amount',
				  'Provider',
				  'Provider Id',
				  'Reference Id',
				  'Trans Id',
				  'Client Name',
				  'Client Code',
				  'Ledger Id',
				  'Ledger Amount',
				  'Ledger Balance',
				  'Refund Ledger Id',
				  'Refund Amount',
				  'Refund Balance',
				  'Booking Date',
				  'Payment Type',
				  'Document Type',
				  'Document Date',
				  'Document Number',
				  'Document URL',
				  'IRN',
				  'Document Provider',
				  'HSN Code',
				  'BASE',
				  'SGST',
				  'CGST',
				  'IGST',
				  'GST Slab',
				  'OTHER CHARGES',
				  'TOTAL FARE',
				  'Seller Gst Name',
				  'Seller Gst Gstin',
				  'Buyer Gst Name',
				  'Buyer Gst Gstin',
				  'Buyer State',
				  'Status',
				  'Segment',
				  'Cabin Class',
				  'Fare Type',
				  'Airline',
				  'Ticket Number',
				  'Flight Number',
				  'Booker Name',
				  'Booker Email',
				  'Traveller Name',
				  'Traveller Email ID',
				  'Hotel Name',
				  'Hotel State',
				  'Hotel Check In',
				  'Hotel Check Out',
				  'Place of Supply',
			  ];
			  data.push(headers);
			  items.forEach((item) => {
				  try {
					  let line = [];
					  line.push(item.booking?.id || '-');
					  line.push(item.booking?.category || '-');
					  line.push(item.booking?.invoiceConfig?.reseller ? 'True' : 'False');
					  line.push(item.booking?.amount || '-');
					  line.push(item.booking?.provider || '-');
					  line.push(item.booking?.providerId || '-');
					  line.push(item.booking?.referenceId || '-');
					  line.push(item.booking?.transId || '-');
					  line.push(item.client?.code || '-');
					  line.push(item.client?.name || '-');
					  line.push(item.booking?.ledger?.ledgerId || '-');
					  line.push(item.booking?.ledger?.amount || '-');
					  line.push(item.booking?.ledger?.balance || '-');
					  line.push(item.booking?.refund?.ledgerId !== 0 ? item.booking?.refund?.ledgerId : '-');
					  line.push(item.booking?.refundAmount || '0');
					  line.push(item.booking?.refund?.balance || '-');
					  line.push(
						  Moment(item.booking?.createdAt).format('DD-MMM-YYYY') || '-'
					  );
					  line.push(item.booking?.paymentType || '-');
					  line.push(item.docType || '-');
					  line.push(Moment(item.docDate).format('DD-MMM-YYYY'));
					  line.push(item.docNo || '-');
					  line.push(getDocUrl(item.booking?.invoiceConfig, item.docType));
					  line.push(item.irn || '-');
					  line.push(item.provider || '-');
					  line.push('998551'); // HSN Code
					  line.push(item.fare?.base || '0');
					  line.push(item.fare?.sGst || '0');
					  line.push(item.fare?.cGst || '0');
					  line.push(item.fare?.iGst || '0');
					  line.push(item.fare?.slab || '-');
					  line.push(item.fare?.oth || '0');
					  line.push(item.fare?.total || '0');
					  line.push(item.sellerGstin?.name || '-');
					  line.push(item.sellerGstin?.gstin || '-');
					  line.push(item.buyerGstin?.name || '-');
					  line.push(item.buyerGstin?.gstin || '-');
					  line.push(placeOfSupply(item.buyerGstin?.gstin) || '-');
					  line.push(item.status || '-');
					  line.push(getSegments(item.booking));
					  line.push(item.booking?.ticket?.cabinClass || '-');
					  line.push(item.booking?.ticket?.fareType || '-');
					  line.push(getAirlines(item.booking));
					  line.push(getTickets(item.booking) || "-");
					  line.push(getFlightNumber(item.booking));
					  line.push(item.booking?.invoiceConfig?.bookedBy || '-');
					  line.push(item.booking?.invoiceConfig?.bookedByEmail || '-');
					  line.push(item.booking?.ticket?.travellers[0].name || '-');
					  line.push(item.booking?.ticket?.travellers[0].email || '-');
					  line.push(item.booking?.ticket?.name || '-');
					  line.push(item.booking?.ticket?.state || '-');
					  line.push(item.booking?.ticket?.date?.in || '-')
					  line.push(item.booking?.ticket?.date?.out || '-')
					  line.push(item.booking?.invoiceConfig?.placeOfSupply || '-');
					  data.push(line);
				  }catch (e){
					  console.log(e)
				  }
			  });
			  DownloadCsv(
				  `successful_invoices_mis_${Moment(Dates.startDate).format(
					  'DD_MM_YYYY'
				  )}_${Moment(Dates.endDate).format('DD_MM_YYYY')}.csv`,
				  data
			  );
			  setLoading(false);
		  },
		  {
			  client: clientsFilter.toString(),
		  },
		  8
	  );
  };

  return (
    <div style={{ width: '100%' }}>
      <Navigation
        backUrl={'back'}
        title="Successful Invoices MIS"
        chips={['Meta', 'MIS', 'Invoices']}
        description="Successful Invoices MIS"
      />
      <div className="flex horizontally center-vertically">
        <DateRange
          defaultValue={{
            startDate: Moment().add(-31, 'd').valueOf(),
            endDate: Moment().valueOf(),
          }}
          onChange={(dates) => {
            setDates({
              ...dates,
              page: 0,
            });
          }}
          className="flex-1 mt1"
          label=""
          type="range"
        />
        <MultiSelectDropdown
          className="flex-1 mt1 ml1"
          options={clients.map((client) => client.code)}
          selectedOptions={selectedClients}
          setSelectedOptions={setselectedClients}
          placeholder="Select Clients"
          showSelectedOptins={false}
        />
      </div>
      <div>
        <div className="border pd6 mt4 flex vertically center">
          <img
            alt="fetch"
            src={Graph}
            style={{ width: '5rem', height: '5rem' }}
          />
          <RBAC role={ROLES.REPORTS}>
            <Button
              onClick={downloadData}
              className="btn btn-black mt4"
              loading={loading}
            >
              Download Data
            </Button>
          </RBAC>

          <p className="mt2">Download MIS Data</p>
        </div>
      </div>
    </div>
  );
};

export const getDocUrl = (invoiceConfig = undefined, docType = "")=>{
  if(!invoiceConfig){
    return "-"
  }
  let docUrl = "";
  switch (docType) {
    case 'INVOICE':
      docUrl = invoiceConfig.invoice;
      break;
    case 'SERVICE':
      docUrl = invoiceConfig.serviceInvoice;
      break;
    case 'AGENCY':
      docUrl = invoiceConfig.serviceInvoice;
      break;
    case 'CANCELLATION':
      docUrl = invoiceConfig.cancellationCharges;
      break;
    case 'CREDITNOTE':
      docUrl = invoiceConfig.creditNote;
      break;
    case 'ANCILLARY':
      docUrl = invoiceConfig.ancillaryInvoice;
      break;
    default:
      docUrl = invoiceConfig.invoice;
      break;
  }
  return docUrl;
}

export const getSegments = (booking) => {
  let string = undefined;
  if(!booking?.ticket?.segments){
    return "-";
  }
  booking.ticket.segments.forEach((segment) => {
    if (!string) {
      string = `${segment.departure.code}-${segment.arrival.code}`;
    } else {
      string = `${string} , ${segment.departure.code}-${segment.arrival.code}`;
    }
  });
  return string;
};

export const getAirlines = (booking) => {
  let string = undefined;
  if(!booking?.ticket?.segments){
    return "-";
  }
  booking.ticket.segments.forEach((segment) => {
    if (!string) {
      string = segment.airline;
    } else {
      string = `${string} , ${segment.airline}`;
    }
  });
  return string;
};

export const getFlightNumber = (booking) => {
  let string = undefined;
  if(!booking?.ticket?.segments){
    return "-";
  }
  booking.ticket.segments.forEach((segment) => {
    if (!string) {
      string = `${segment.flightCode} - ${segment.flightNumber}`;
    } else {
      string = `${string} , ${segment.flightCode} - ${segment.flightNumber}`;
    }
  });
  return string;
};

export const getTickets = (booking) => {
  let travellers = booking?.ticket?.travellers;
  if(!travellers || travellers.length === 0){
    return '-'
  }
  let string = "";
  travellers.forEach((traveler)=>{
    let tickets = traveler.tickets;
    if(!tickets){
      return;
    }
    Object.keys(tickets).forEach((key)=>{
      if (!string) {
        string = tickets[key];
      } else {
        string = `${string} , ${tickets[key]}`;
      }
    })
  })
  return string;
};

export default Invoices;
