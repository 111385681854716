import React, {useEffect, useState} from 'react'
import {DateRange, Loader, Navigation, ScrollableDynamicTable} from "../../../../../../retro";
import Moment from 'moment'
import {get} from "../../../../../Network/Axios";
import {useHistory} from "react-router-dom";

export default function index() {
	const HISTORY = useHistory();
	const [Dates, setDates] = useState();
	const [loading] = useState(false)
	const [Data, SetData] = useState({
		total: 0,
		currentPage: 0,
		totalPages: 0,
		items: []
	})

	useEffect(()=>{
		if(Dates){
			get(`/meta/hotel`,(e,r)=>{
				if(r){
					SetData({
						...r,
						currentPage: Dates.page ? Dates.page : 0,
						totalPages: r.totalPages > 0 ? r.totalPages : Data.totalPages,
						total: r.total > 0 ? r.total : Data.total
					})
				} else {
					Alert.handleError(e)
				}
			},{
				start:Dates.startDate,
				end:Dates.endDate,
				offset:Dates.page
			})
		}
	},[Dates])
	if(loading){
		return (
			<div>
				<div style={{
					margin:'0 -4rem',
					padding:'2rem 4rem',
					borderBottom:'1px solid #dedede'
				}}>
					<div className="flex horizontally center-vertically">
						<div className="flex-1">
							<DateRange  defaultValue={{
								startDate:Moment().add(-31,'d').valueOf(),
								endDate : Moment().valueOf()
							}}  onChange={()=>{}} className='' label='Export from and to' type='range'/>
						</div>
					</div>
				</div>
				<div className="pd6">
					<Loader/>
				</div>
			</div>
		)
	}

	let items = [];
	Data.items.forEach(item=>{
		items.push({
			content: item,
			render: [
				item.transId,item.client.code,item.client.name,item.provider,item.paymentType
				,item.amount,{content:(<p className="fw-bold pointer" onClick={()=>{
						HISTORY.push(`/app/apps/meta/transaction/${item.transId}`)
					}}>View</p>)}

			].map(title=>{
				if(title.content){
					return {weight:2,children:title.content}
				}else{
					return {weight:2,title}
				}
			})
		})
	})
	return (
		<div style={{width:'100%'}}>
            <Navigation backUrl={'back'} title="Hotel Bookings" chips={['Meta',"Hotel","Bookings"]} description="Hotel bookings appears below."/>
			<div style={{
				margin:'0 -4rem',
				padding:'2rem 4rem',
				borderBottom:'1px solid #dedede'
			}}>
				<div className="flex horizontally center-vertically">
					<div className="flex-1">
						<DateRange  defaultValue={{
							startDate:Moment().add(-31,'d').valueOf(),
							endDate : Moment().valueOf()
						}}  onChange={dates=>{
							setDates({
								...dates,
								page:0
							})
						}} className='' label='Export from and to' type='range'/>
					</div>
				</div>
			</div>
			<div >

				<ScrollableDynamicTable
					autoTrigger={false}
					tableStyle={{
						borderSpacing: 0
					}}
					width={150}
					search={Dates}
					setPage={page => {
						setDates({
							...Dates,
							page,
							total:Dates.totalPages
						})
					}}
					data={{
						current: Data.currentPage,
						total: Data.totalPages,
						items
					}} headers={[
					"Trans Id","Client Code","Client Name","Provider","Payment Type",
					"Amount","Actions"
				].map((item)=>({
					weight: 2,
					title: item,
				}))}/>
			</div>
        </div>
	)
}
