import React from 'react';
import {NavDivider, Navigation} from '../../../../../retro';
import {ReportRouteCard} from '../All/routes';
import {useSelector} from "react-redux";

export default function index() {
	// Origin Code
	const {code} = useSelector((data) => ({
		code: data?.auth?.config?.origin?.code || '',
	}));
	const routes = [
		{
			title: 'Transfer Da',
			desc: 'All bank transfer appears here',
			path: '/app/apps/meta/da/transfers',
			btnLabel: 'View File',
			codes: ['CLRTRP','ADANI','ADANI-PROD'],
		},
		{
			title: 'Recharges',
			desc: 'All recharges information appear here',
			path: '/app/apps/meta/da/recharges',
			btnLabel: 'View File',
			codes: [],
		},
		{
			title: 'Da Accounts',
			desc: 'All da accounts with their details appears here',
			path: '/app/apps/meta/da/accounts',
			btnLabel: 'View File',
			codes: [],
		},
		{
			title: 'Ledgers',
			desc: 'All ledgers details can be downloaded',
			path: '/app/apps/meta/da/ledger',
			btnLabel: 'View File',
			codes: [],
		},
		{
			title: 'Settlements',
			desc: 'All settlements details can be downloaded',
			path: '/app/apps/meta/da/settlements',
			btnLabel: 'View File',
			codes: ['CLRTRP','CLTP-STG','ADANI','ADANI-PROD'],
		},
		{
			title: 'Complete Settlements',
			desc: 'All settlements details can be downloaded',
			path: '/app/apps/meta/da/completementSettlements',
			btnLabel: 'View File',
			codes: ['CLRTRP','CLTP-STG','ADANI','ADANI-PROD'],
		},
		{
			title: 'Flight Segment Report',
			desc: 'All flight segment settlements details can be downloaded',
			path: '/app/apps/meta/da/flightSegment',
			btnLabel: 'View File',
			codes: ['CLRTRP','CLTP-STG'],
		},
		{
			title: 'Upload Settlement',
			desc: 'Settlements details can be uploaded',
			path: '/app/apps/meta/da/uploadSetllement',
			btnLabel: 'Upload File',
			codes: [],
		},

		{
			title: 'Settlements',
			desc: 'Settlements',
			path: '/app/apps/meta/da/adaniAgencyBos',
			btnLabel: 'View File',
			codes: [],
		},
		{
			title: 'Gstin Utility',
			desc: 'Gstin utility to view gstin for client',
			path: '/app/apps/meta/da/gstins',
			btnLabel: 'View File',
			codes: [],
		},
		{
			title: 'Sucessful Invoices',
			desc: 'Sucessful Invoices Report',
			path: '/app/apps/meta/da/invoices',
			btnLabel: 'View File',
			codes: ['CLRTRP', 'CLTP-STG','ADANI','ADANI-PROD'],
		},
		{
			title: 'Failed Invoices',
			desc: 'Failed Invoices Report',
			path: '/app/apps/meta/da/invoices/failed',
			btnLabel: 'View File',
			codes: ['CLRTRP', 'CLTP-STG','ADANI','ADANI-PROD'],
		},
		{
			title: 'Amendment Report ',
			desc: 'Amendment Report',
			path: '/app/apps/meta/da/amendmentReport',
			btnLabel: 'View File',
			codes: ['CLRTRP', 'CLTP-STG','ADANI','ADANI-PROD'],
		},
		{
			title: 'Account Mis File',
			desc: 'All accounting mis can be downloaded here.',
			path: '/app/apps/meta/import/mis',
			btnLabel: 'View File',
			codes: ['CLRTRP', 'CLTP-STG','ADANI','ADANI-PROD'],
		  },
	];
	return (
		<div>
			<Navigation
				backUrl={''}
				title="DA Mis"
				chips={['All Mis']}
				description="All da related mis are listed below."
			/>
			<NavDivider/>
			<div className="card-container mt4">
				<div className="card-container mt4">
					{routes.map(
						(report) => (
							// return only if noone  or the current one is not restricted
							(report.codes.length === 0 || report.codes.includes(code)) ? (
								<ReportRouteCard reportRoute={report}/>
							) : <></>
						)
					)}
				</div>
			</div>
		</div>
	);
}




