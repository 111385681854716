import CleartripLogo from './assets/cleartrip.png'
import DiceLogo from './assets/dice.png'
import YesLogo from './assets/yesbank.png'

const useLogoHooks = () => {
	 if(window.origin.includes("cleartrip")){
		 return CleartripLogo;
	 }
	 if(window.origin.includes("yesleap") || window.origin.includes('yespayleaf')){
		 return YesLogo;
	 }
	return DiceLogo;
}


export default useLogoHooks;
