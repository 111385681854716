import React, {useEffect, useState} from 'react'
import {DateRange, Graphs, Loader, Navigation, Wrapper} from "../../../../../../retro";
import Moment from 'moment'
import {calculateFlights} from "./calculate";
import {get} from "../../../../../Network/Axios";
import moment from "moment/moment";

export default function Index() {
	const [loading,setLoading] = useState(true)
	const [range,setRange] = useState(undefined)
	const [flight,setFlight] = useState({
		tiles : [
			{
				title:"Total Expenses",
				value : '0'
			},
			{
				title:"Total Categories",
				value : '0'
			},
			{
				title:"Total Value",
				value : '0'
			},
			{
				title:"Claimed Value",
				value : '0'
			},
		]
	})
	useEffect(()=>{
		if(range){
			get(`/personal/flights/range`,(e,r)=>{
				if(r){
					setFlight(calculateFlights(r.items))
					setLoading(false)
				}
			},{
				start: Moment(range.startDate).valueOf(),
				end: Moment(range.endDate).valueOf()
			})
			get(`/personal/users/range`,(e,r)=>{
				if(r){

				}
			},{
				start: Moment(range.startDate).valueOf(),
				end: Moment(range.endDate).valueOf()
			})
		}
	},[range])
	console.log(flight)
	let SERIES = [];
	let DATES = [];
	let allSets = {};
	if(flight.byAirline){
		Object.values(flight.byAirline)
			.map(item=>{
				Object.keys(item.sectors)
					.forEach(a=>{
						if(item.sectors[a].count>3){
							allSets[a] = true
						}
					})
			})
		DATES = Object.keys(allSets)
		Object.keys(flight.byAirline)
			.map(value=>{
				const A = [];
				const itemMap = flight.byAirline[value];
				DATES.forEach(xValue=>{
					if(itemMap.sectors[xValue]){
						A.push(itemMap.sectors[xValue].value)
					}else{
						A.push(0)
					}
				})
				SERIES.push({
					name:value,
					data:A
				})
			})
	}
	return (
		<Wrapper  id="wrapper-nav">
			<Navigation title="Dashboard" chips={["Perks","Dashboard"]} description="Details of dashboard appears below."/>
			<div className="mt2">
				<DateRange type="range" onChange={setRange}/>
			</div>
			{
				loading ? <Loader/> :
					<div className="mt4">
						<Graphs.AnalyticTiles items={flight.tiles} title="Overview"/>
						<div className="card-container">
							<Graphs.TimeseriesGraph blank={flight.dateSeries.length===0} type="line" series={[
								{
									name: "Amount",
									data :  flight.dateSeries
								}
							]} title="Daily Flight's"/>
							<Graphs.BarGraph labels={Object.keys(flight.byStatus)} series={[{
								name:"Transaction Count",
								data:Object.keys(flight.byStatus).map(item=>{
									return flight.byStatus[item]||0
								})
							}]} title="By Status Plot" />
							<Graphs.TimeseriesGraph  type="line" series={Object.keys(flight.statusSeries).map(item=>{
								let xItem = flight.statusSeries[item];
								return{
									name:item,
									data: Object.keys(flight.statusSeries[item]).map(item=>({
										time:moment(item,"DD-MM-YYYY").valueOf(),
										value:xItem[item].count
									})).sort((a,b)=>a.time-b.time).map(a=>{
										return [a.time,a.value]
									})
								}
							})} title="Number Of Booking's"/>
							<Graphs.BarGraph labels={Object.keys(flight.byStatusValue)} series={[{
								name:"Transaction Value",
								data:Object.keys(flight.byStatusValue).map(item=>{
									return flight.byStatusValue[item]||0
								})
							}]} title="By Status Value" />
							<Graphs.DonutGraph labels={Object.keys(flight.byAirline)} series={Object.keys(flight.byAirline).map(item=>{
								return flight.byAirline[item].count||0
							})} title="By Airline Txns" />
							<Graphs.PieGraph labels={Object.keys(flight.byAirline)} series={Object.keys(flight.byAirline).map(item=>{
								return flight.byAirline[item].value||0
							})} title="By Airline Value" />
							<Graphs.WordCloud title="Top 6 Domain's" words={Object.keys(flight.byDomain).sort((a, b) => flight.byDomain[b]-flight.byDomain[a]).map(item=>{
								return {
									text : item,
									value:flight.byDomain[item]
								}
							}).slice(0,5)}/>
							<Graphs.TimeseriesGraph  type="line" series={Object.keys(flight.byDomain).sort((a, b) => flight.byDomain[b]-flight.byDomain[a])
								.slice(0,6).map(value => {
									let xItem = flight.byDomainSeries[value];
									return{
										name:value,
										data: Object.keys(flight.byDomainSeries[value]).map(item=>({
											time:moment(item,"DD-MM-YYYY").valueOf(),
											value:xItem[item].value
										})).sort((a,b)=>a.time-b.time).map(a=>{
											return [a.time,a.value]
										})
									}
								})} title="Number Of Booking's"/>

							<Graphs.HeatMap labels={DATES} series={SERIES} title="Airline Map" />
						</div>
					</div>
			}
		</Wrapper>
	)
}
