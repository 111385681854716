import Moment from 'moment/moment';
import {DownloadCsv} from 'retro/Utils';
import Bus, {busCancellationLine} from './Bus'
import Train, {trainCancellationLine} from './Train'
import Hotel, {hotelCancellationLine} from './Hotel'
import Flight, {flightCancellationLine} from './Flight'
import FlightServiceCharges from './FlightServiceCharges'
import HotelServiceCharge from './HotelServiceCharge'
import OfflineServiceCharges from './Offline_Amendment_Service'
import FlightAncillary from './FlightAncillary'
import FlightCancellation from './FlightCancellation'
import OfflineAmendment from './Offline_Amendment'
import Visa from './Visa'
import Insurance from './Insurance'


export function downloadPendingSettlement(items, Dates, SelectedColumns = [], SelectedProducts= []) {
    let data = [];
    const allHeaders = [
        'Statement Period',
        'Advance Booking Days',
        "Client Name",
        "Client ID",
        'Vendor Name',
        'Ledger Id',
        'Account Id',
        'Account Number',
        'Entry Type',
        'Type',
        'Amount',
        'Refunded Amount',
        'Settled',
        'Start Balance',
        'End Balance',
        'Created On Date',
        'Created On Time',
        'Booking Type',
        'Entity Name',
        'Department Name',
        'Trip Title',
        'Trip Id',
        'Trip Status',
        'Child Id',
        'Reason For Travel',
        'Booking Status',
        'Cancellation Reason',
        'Emulated Type',
        'Emulated By',
        'User Type',
        'Traveller Name',
        'Traveller Id',
        'Traveller Cost Center',
        'Traveller Department',
        'Traveller Grade',
        'Traveller Location Code',
        'Traveller Entity',
        'Approval One Name',
        'Approval One Code',
        'Approval One Cost Center',
        'Approval One Grade',
        'Approval One Entity',
        'Approval One Status',
        'Approval One Sent On',
        'Approval One Updated On',
        'Approval Two Name',
        'Approval Two Code',
        'Approval Two Cost Center',
        'Approval One Grade',
        'Approval Two Entity',
        'Approval Two Status',
        'Approval Two Sent On',
        'Approval Two Updated On',
        'Itinerary Type',
        'Product Type',
        'Product Code',
        'Product Name',
        'All Travellers',
        'Hotel Chain',
        'Hotel Name',
        "Hotel Category",
        'Hotel Address',
        "Meal Plan",
        'Requested City',
        'Booked City',
        'Booking Type',
        'Contracted/Non Contracted',
        'Voucher Number',
        'Check In Date',
        'Check Out Date',
        'Number Of Rooms',
        'Total Nights',
        'Sectors',
        'Origin',
        'Destination',
        'Pnr Number',
        'Ticket Number',
        'Fare Type',
        'Cabin Class',
        'Travel Start Date',
        'Travel End Date',
        'Journey Type',
        'Reseller',
        'Document Status',
        'Document Type',
        'Document Number',
        'Document Date',
        'Document Irn',
        'Document File',
        'Price Per Night',
        'Base Fare',
        'Other Charges',
        'Gst Slab',
        'iGst Charges',
        'sGst Charges',
        'cGst Charges',
        'Total Fare',
        "Airline cancellation fees",
        'Seller Gstin',
        'Seller Gstin Address',
        'Buyer Gstin',
        'Buyer Gstin Address',
        'State Billing From',
        'State Billing To',
        'Seller Hsn Code',
        'Unique Id',
        'Traveller Email Address',
        'Fare type',
        'Occupancy details',
        'Tax invoice reference number',
        "Reference Id",
        'Voucher File',
        "Booker Name",
        "Business Area",
        "Company Code",
        "Cost Center",
        "Employee Id",
        "Employee Name",
        "TR ID",
        "Vendor Code",
        "BookedBy Name",
        "BookedBy Id"
    ];
    

    data.push(allHeaders);
    items.forEach(item => {
        if (item.status === "FAILED")
            return;
        if (item.status === "ONGOING")
            return;
        if (item.transId === "RI20040124032000247") {
            console.log(item)
        }
        if (item.service) {
            if (item.service.toUpperCase() === "BUS") {
                let settlement = item.item;
                if (settlement.financeType === 'CREDIT') {
                    data.push(Bus(item, Dates))
                    if (settlement?.cancellationCharges !== "SKIPPED") {
                        data.push(busCancellationLine(item, Dates))
                    }
                } else {
                    data.push(Bus(item, Dates))
                }
            } else if (item.service.toUpperCase() === "TRAIN") {
                let settlement = item.item;
                if (settlement.financeType === 'CREDIT') {
                    data.push(Train(item, Dates))
                    if (settlement?.cancellationCharges !== "SKIPPED") {
                        data.push(trainCancellationLine(item, Dates))
                    }
                } else {
                    data.push(Train(item, Dates))
                }
            } else if (item.service.toUpperCase() === "HOTEL") {
                let settlement = item.item;
                if (item.reseller) {
                    data.push(Hotel(item, Dates))
                } else {
                    if (settlement.financeType === 'CREDIT') {
                        data.push(Hotel(item, Dates))
                        if (settlement?.cancellationCharges !== "SKIPPED") {
                            data.push(hotelCancellationLine(item, Dates))
                        }
                    } else {
                        data.push(Hotel(item, Dates))
                    }
                }
                if (item.item.financeType === "DEBIT" && item.reseller) {
                    //Add Service Charge Invoice
                    if (item.item.config.serviceCharges || item.item.config.serviceIrn) {
                        data.push(HotelServiceCharge(item, Dates))
                    }
                }
            } else if (item.service.toUpperCase() === "FLIGHT" || item.service.toUpperCase() === "FLIGHT ROUND") {
                let settlement = item.item;
                if (item.reseller) {
                    data.push(Flight(item, Dates))
                    //Add Cancellation Invoice
                    if (item.item.financeType === "CREDIT") {
                        data.push(FlightCancellation(item, Dates))
                    } else {
                        //Add Ancillary Invoice
                        if (item.item.config.ancillaryIrn || (item.item.config.ancillaryFare && item.item.config.ancillaryFare.total)) {
                            data.push(FlightAncillary(item, Dates))
                        }
                        //Add Service Charge Invoice
                        if (item.item.config.serviceCharges || item.item.config.serviceIrn) {
                            data.push(FlightServiceCharges(item, Dates))
                        }
                    }
                } else {
                    if (settlement.financeType === 'CREDIT') {
                        data.push(Flight(item, Dates))
                        if (settlement?.cancellationCharges !== "SKIPPED") {
                            data.push(flightCancellationLine(item, Dates))
                        }
                    } else {
                        data.push(Flight(item, Dates))
                    }
                }

            } else if (item.service.toUpperCase() === "FLIGHT_AMENDMENT" || item.service.toUpperCase() === "FLIGHT_OFFLINE_AMENDMENT") {
                data.push(OfflineAmendment(item, Dates))
                if (item.item.financeType === "DEBIT" && item.reseller) {
                    //Add Service Charge Invoice
                    if (item.item.config.serviceCharges || item.item.config.serviceIrn) {
                        data.push(OfflineServiceCharges(item, Dates))
                    }
                }
            } else if (item.service.toUpperCase() === "VISA") {
                data.push(Visa(item, Dates))
            } else if (item.service.toUpperCase() === "INSURANCE") {
                data.push(Insurance(item, Dates))
            }
        }
    })

    const selectedHeaders = SelectedColumns.map(column =>{
        return column.value;
    })
    const headersIndex = selectedHeaders.map(header => allHeaders.findIndex(h => h === header));
    const productTypeIndex = allHeaders.findIndex(h => h === 'Product Type')

    const selectedData = data
        .map((d) => headersIndex.map(i => d[i]))
        .filter((_, index) => index === 0 || SelectedProducts.length === 0 || SelectedProducts.includes(data[index][productTypeIndex]));

    DownloadCsv(
        `Complete_settlement_Report_${Moment(Dates.startDate).format(
            'DD_MM_YYYY'
        )}_${Moment(Dates.endDate).format('DD_MM_YYYY')}.csv`,
        selectedHeaders.length === 0 ? data : selectedData
    );
}

