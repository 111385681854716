import Common,{Common2,placeOfSupply,sellerAddress} from "./Common";
import Moment from "moment";

const roundOff = num => {
	return (Math.round(num * 100) / 100).toFixed(2);
}


let traveller = (item) => {
	let string = undefined;
	try {
		item.ticket.travellers.forEach((traveller) => {
			if (!string) {
				string = `${traveller.name}`;
			} else {
				string = `${string} , ${traveller.name}`;
			}
		});
	}catch (e){
		return "-";
	}
	return string;
};

export default function insuranceLine(item,dates){
	let values = []
	values.push(...Common(item,dates))
	try {
		values.push(
			...[
				item.ticket?.insurer || "-",
				item.ticket?.vendorName || "-",
				"-",
				"-",
				"-",
				"-",
				"-",
				"-",
				"-",
				"-",
				"-",
				"-",
				"-",
				"-",
				"-",
				"-",
				"-",
				"-",
				"-",
				 "-",
				item.ticket?.confirmation || "-",
				item.ticket?.vendorInvoice || "-" || "-",
				 "-",      // Cabin class means Ac-Non- Ac, Sleeper
				"-",
				item.ticket?.start,
				item.ticket?.end,
				'One Way'
			]
		)
	}catch (e){
		console.log(e)
	}
	let settlement = item.item;
	let transId = item?.transId;
	if (settlement.financeType === 'DEBIT') {
		values.push(...[
			'No',
			'Generated',
			'Invoice + Management Fee',
			settlement.config.serviceInvoiceNo|| "-",
			settlement.config.serviceChargeDate|| "-",
			settlement.config.serviceIrn || "-",
			settlement.config.serviceInvoice|| "-",
			'-',
			roundOff(settlement.config.serviceFare?.base || 0),
			roundOff(settlement.amount - settlement.config.serviceFare?.total || 0),
			roundOff(settlement.config.serviceFare?.slab || 0),
			roundOff(settlement.config.serviceFare?.iGst || 0),
			roundOff(settlement.config.serviceFare?.sGst || 0),
			roundOff(settlement.config.serviceFare?.cGst || 0),
			roundOff(settlement.amount || 0),
			"-",
			settlement.config.sellerGst?.gstin||"-",
			settlement.config.sellerGst?.address?sellerAddress(settlement.config.sellerGst):"-",
			settlement.config.userGst?.gstin||"-",
			settlement.config.userGst?.address||"-",
			'Maharashtra',
			placeOfSupply(settlement.config.userGst?.gstin||"-"),
			'998551',
			`MFI - ${transId}`,
		]);
	}else{
		values.push(...[
			'No',
			'Generated',
			'Credit Note + Management Fee',
			settlement.config.cancellationInvoiceNo|| "-",
			settlement.config.cancellationDate|| "-",
			settlement.config.cancellationIrn || "-",
			settlement.config.cancellationCharges|| "-",
			'-',
			roundOff(settlement.config.cancellationFare?.base || 0),
			roundOff(settlement.amount - settlement.config.cancellationFare?.total || 0),
			roundOff(settlement.config.cancellationFare?.slab || 0),
			roundOff(settlement.config.cancellationFare?.iGst || 0),
			roundOff(settlement.config.cancellationFare?.sGst || 0),
			roundOff(settlement.config.cancellationFare?.cGst || 0),
			-1 * roundOff(settlement.amount || 0),
			settlement.config.sellerGst?.gstin||"-",
			settlement.config.sellerGst?.address?sellerAddress(settlement.config.sellerGst):"-",
			settlement.config.userGst?.gstin||"-",
			settlement.config.userGst?.address||"-",
			'Maharashtra',
			placeOfSupply(settlement.config.userGst?.gstin||"-"),
			'998551',
			`MFI - ${transId}`,
		]);
	}
	values.push(...Common2(item,settlement.config.serviceInvoiceNo))
	return values;
}
