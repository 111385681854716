import moment from "moment";
import React from "react";
import { Attachment, Grids } from "../../../../../retro";

function PaymentDocument({ data }) {
  let { invoice, invoiceNo, remarks, type, utr } = data;
  return (
    <>
      <p className='fw-bold text-medium mb4'>Documents</p>
      <div className='text-small'>
        <Grids.Row>
          <Grids.Column grid={{ mobile: 12, medium: 6, large: 6 }}>
            {invoice ? (
              <>
                <Attachment url={invoice} />
                <p style={{ marginLeft: "8px" }}>Invoice</p>
              </>
            ) : (
              <>
                <p className='fw-bold'>Invoice</p>
                <div>{"-"}</div>
              </>
            )}
          </Grids.Column>
          <Grids.Column grid={{ mobile: 12, medium: 6, large: 6 }}>
            <div className='fw-bold'>Invoice No</div>
            <div className=''>{invoiceNo || "-"}</div>
          </Grids.Column>
        </Grids.Row>
        <Grids.Row className='mt3'>
          <Grids.Column grid={{ mobile: 12, medium: 6, large: 6 }}>
            <div className=' fw-bold'>Remarks</div>
            <div className=''>{remarks || "-"}</div>
          </Grids.Column>
          <Grids.Column grid={{ mobile: 12, medium: 6, large: 6 }}>
            <div className=' fw-bold'>Type</div>
            <div>{type || "-"}</div>
          </Grids.Column>
        </Grids.Row>
        <Grids.Row className='mt3'>
          <Grids.Column grid={{ mobile: 12, medium: 6, large: 6 }}>
            <div className=' fw-bold'>UTR</div>
            <div>{utr || "-"}</div>
          </Grids.Column>
        </Grids.Row>
      </div>
    </>
  );
}

export default PaymentDocument;
