import React, {useEffect, useRef, useState} from 'react'
import {BrowserFrame, Button, ColorInput, Input, Loader, SelectInput, Seperator} from "../../../../retro";
import {calculateColors} from "../../../../retro/Theme/Theme";
import SectionMessage from "@atlaskit/section-message";
import {post} from "../../../Network/Axios";
import useFunctionallityHook from "../../../Hooks/useFunctionallityHook";

export default function Theme() {
	const [config,setConfig] = useState({
		loading:true
	})
	const FONTSIZE = useRef();
	const [font, setFont] = useState(14)
	const [fontFamily, setFontFamily] = useState('Dice')
	const FONTFAMILY = useRef();
	const [color,setColor] = useState("#6367FA")
	let domain = useFunctionallityHook().domain
	const [url] = useState(domain);
	const load = () => {
		
		let baseURL = `https://provider.dice.tech/v1/domain/${domain}`;

		if(window.location.href.includes('superadmin.yesleap.in')) {
			baseURL = `https://provider.yesleap.in/v1/domain/${domain}`
		}

		if(window.location.href.includes('admin.yespayleaf.in')) {
			baseURL = `https://provider.yespayleaf.in/v1/domain/${domain}`
		}
		
		
		fetch(baseURL)
			.then(success=>{
				return success.json()
			}).then(value => {
			setConfig(value)
			setColor(value.color)
			setFontFamily(value.fontFamily)
		})
	}
	useEffect(()=>{
		load()
	},[])
	const submit = () => {
		post(`/origin/update`,{
			color,
			fontSize:font,
			font:fontFamily
		},(e,r)=>{
			load()
		})
	}
	if(config.loading){
		return (<Loader/>)
	}
	const colors = calculateColors(color);
	return (
		<div className="flex relative horizontally mt4">
			<div className="mr4 flex-1" style={{}}>
				<div className="mb4">
					<SectionMessage appearance="discovery" title="Theme Customization">
						<p>
							Theme has been setup for your domain by <b>Dice Team.</b> Contact your system admin for any change in base theme.
						</p>
					</SectionMessage>
				</div>
				<ColorInput onChange={hex => {
					setColor(hex)
				}} morph={true} defaultColor={color} label="Platform Color"/>
				<Input morph={true} label="Font Size" type="number" defaultValue={`14`} ref={FONTSIZE}
					   onChange={e => {
						   if(FONTSIZE.current){
							   setFont(parseInt(FONTSIZE.current.value, 10))
						   }
					   }}/>
				<SelectInput onChange={() => {
					setFontFamily(FONTFAMILY.current.value)
				}} ref={FONTFAMILY} defaultValue={fontFamily} label="Font Family" options={[
					{
						label: "Dice",
						value: 'Dice'
					},
					{
						label: "Gilroy",
						value: 'Gilroy'
					},
					{
						label: "Roboto",
						value: 'Roboto'
					},
					{
						label: "Proxima",
						value: 'proxima-nova'
					},
					{
						label: "Lato",
						value: 'Lato'
					},
					{
						label: "Open Sans",
						value: 'Open Sans'
					},
					{
						label: "Rubik",
						value: 'Rubik'
					}
				]}/>
				<Button onClick={submit} className="btn-secondary mt3">
					Update Platform Settings
				</Button>

			</div>
			<div className="flex-1" style={{}}>
				<BrowserFrame url={url}>
					<div className="pd5" style={{
						background: colors.bg
					}}>
						<div className="relative">
							<p style={{
								color: colors.link,
								fontWeight: 600,
								position: 'absolute',
								right: 0,
								top: 12,
								cursor: 'pointer',
								textDecoration: 'underline',
								fontSize: .8 * font,
								fontFamily: `${fontFamily},proxima-nova,sans-serif`
							}}>
								+ Sample Link
							</p>
							<img src={config.logo} alt="Logo" style={{
								height:40
							}}/>
							<h2 style={{
								fontSize: font * 2,
								color: colors.text,
								fontFamily: `${fontFamily},proxima-nova,sans-serif`
							}}>
								Color Settings
							</h2>
							<p style={{
								fontSize: font,
								color: colors.text,
								fontFamily: `${fontFamily},proxima-nova,sans-serif`
							}}>
								Color appearance as per your theme appears below.
							</p>
						</div>
						<div className="mt4">
							<div style={{
								height: 25,
								background: colors.surfaceVariant,
								width: "65%"
							}}/>
							<div className="mt1" style={{
								height: 20,
								background: colors.surfaceVariant,
								width: "45%"
							}}/>
							<div className="mt1" style={{
								height: 10,
								background: colors.surfaceVariant,
								width: "25%"
							}}/>
							<div className="mt1" style={{
								height: 10,
								background: colors.surfaceVariant,
								width: "25%"
							}}/>
							<div className="mt4 horizontally center-vertically">
								<button style={{
									fontFamily: `${fontFamily},proxima-nova,sans-serif`,
									fontSize: .8 * font,
									background: colors.primary,
									borderRadius: 4,
									color: "#fff",
									border: 'none',
									padding: '8px 20px',
									marginRight: 12,
								}}>
									Primary Button
								</button>
								<button style={{
									fontFamily: `${fontFamily},proxima-nova,sans-serif`,
									fontSize: .8 * font,
									background: colors.bg,
									borderRadius: 4,
									color: colors.secondary,
									border: `2px solid ${colors.secondary}`,
									padding: '8px 20px',
									marginRight: 12,
								}}>
									Primary Button
								</button>
							</div>
						</div>
						<Seperator/>
						<div style={{
							fontSize: font * .7,
							fontFamily: `${fontFamily},proxima-nova,sans-serif`
						}} className="text-light">
							Powered by DICE.Tech
						</div>
					</div>
				</BrowserFrame>

			</div>
		</div>
	)
}
