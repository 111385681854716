import React, {useState} from 'react'
import {Loader} from "../../../../retro";
import {get} from "../../../Network/Axios";

export default function Balance({item}) {
    const [loading,setLoading] = useState(false)
    const [balance,setBalance] = useState(-1)
    const fetchBalance = () => {
        get(`accounts/${item.id}`,(e,r)=>{
            if(r){
                setLoading(false)
                let balance = 0;
                r.items.forEach(item=>{
                    if(item.balance>0)
                      balance = balance + item.balance;
                })
                setBalance(balance)
            }
        });
    }
    if(loading){
        return (
            <div>
               <Loader type="pulse"/>
            </div>
        )
    }
    if(balance>=0){
        return (
            <p>
               INR {`${balance}`}
            </p>
        )
    }
	return (
		<div>
            <p onClick={()=>{
                setLoading(true)
                fetchBalance()
            }} className="pointer" style={{
                textDecoration:'underline'
            }}>
                View Balance
            </p>
		</div>
	)
}
