import React, { useEffect, useState } from 'react';
import {
  Button,
  DateRange,
  Loader,
  Navigation,
  ScrollableDynamicTable,
  TOAST,
} from 'retro';
import Moment from 'moment';
import { get } from 'App/Network/Axios';
import { DownloadCsv } from 'retro/Utils';
import RBAC from 'App/HOCs/RBAC';
import ROLES from 'App/Constants/Roles';

export default function index() {
  const [Dates, setDates] = useState();
  const [loading, setLoading] = useState(false);
  const [Data, SetData] = useState({
    total: 0,
    currentPage: 0,
    totalPages: 0,
    items: [],
  });
  const downloadData = () => {
    setLoading(true);
    get(
      `/meta/bus/all`,
      (e, r) => {
        if (e) {
          setLoading(false);
          console.log(e);
          TOAST.error('Unable to download');
          return;
        }
        let data = [];
        let headers = [
          'Id',
          'Provider',
          'Category',
          'Booking Id',
          'Status',
          'Provider Id',
          'Payment Type',
          'Amount',
          'Ledger Id',
          'Ledger Amount',
          'Ledger Balance',
          'Voucher',
          'Gstin',
          'Client Code',
          'Client Name',
          'Created On',
          'Updated On',
          'Bus Label',
          'Bus Provider',
          'Seating',
          'Departure',
          'Arrival',
          'Ac',
          'Govt',
          'Traveller Name',
          'Traveller Gender',
          'Traveller Type',
          'Traveller Seat',
          'Traveller Email',
          'Traveller Mobile',
          'Refund Amount',
        ];
        data.push(headers);
        r.bookings.map((item) => {
          if (item.ticket) {
            item.ticket.travellers.forEach((traveller) => {
              data.push([
                item.id,
                item.provider,
                item.category,
                item.transId,
                item.status,
                item.ticket?.ticket || item.ticket?.pnr || '-',
                item.paymentType,
                item.amount,
                item.ledger?.ledgerId || '-',
                item.ledger?.amount || '-',
                item.ledger?.balance || '-',
                item.invoiceConfig?.voucher || '-',
                item.invoiceConfig?.userGst?.gstin || '-',
                item.client.code,
                item.client.name,
                Moment(item.createdAt).format('DD MMM YYYY HH:mm a'),
                Moment(item.updatedAt).format('DD MMM YYYY HH:mm a'),
                item.ticket?.bus?.label || '-',
                item.ticket?.bus?.provider || '-',
                item.ticket?.bus?.seating || '-',
                item.ticket?.bus?.departureTime || '-',
                item.ticket?.bus?.arrivalTime || '-',
                item.ticket?.bus?.isAc ? 'Yes' : 'No',
                item.ticket?.bus?.isGovt ? 'Yes' : 'No',
                traveller.name,
                traveller.gender,
                traveller.type,
                traveller.seatNo || '-',
                traveller.email || '-',
                traveller.mobile || '-',
                item.refundAmount || '-',
              ]);
            });
          } else {
            data.push([
              item.id,
              item.provider,
              item.category,
              item.transId,
              item.status,
              '-',
              item.paymentType,
              item.amount,
              item.ledger?.ledgerId || '-',
              item.ledger?.amount || '-',
              item.ledger?.balance || '-',
              item.invoiceConfig?.voucher || '-',
              item.invoiceConfig?.userGst?.gstin || '-',
              item.client.code,
              item.client.name,
              Moment(item.createdAt).format('DD MMM YYYY HH:mm a'),
              Moment(item.updatedAt).format('DD MMM YYYY HH:mm a'),
              '-',
              '-',
              '-',
              '-',
              '-',
              '-',
              '-',
              '-',
              '-',
              '-',
              '-',
              '-',
              '-',
              item.refundAmount || '-',
            ]);
          }
        });
        DownloadCsv(
          `bus_bos_${Moment(Dates.startDate).format('DD_MM_YYYY')}_${Moment(
            Dates.endDate
          ).format('DD_MM_YYYY')}.csv`,
          data
        );
        setLoading(false);
      },
      {
        start: Dates.startDate,
        end: Dates.endDate,
      }
    );
  };
  useEffect(() => {
    if (Dates) {
      get(
        `/meta/bus`,
        (e, r) => {
          if (r) {
            SetData({
              ...r,
              currentPage: Dates.page ? Dates.page : 0,
              totalPages: r.totalPages > 0 ? r.totalPages : Data.totalPages,
              total: r.total > 0 ? r.total : Data.total,
            });
          } else {
            Alert.handleError(e);
          }
        },
        {
          start: Dates.startDate,
          end: Dates.endDate,
          offset: Dates.page,
        }
      );
    }
  }, [Dates]);
  if (loading) {
    return (
      <div>
        <div
          style={{
            margin: '0 -4rem',
            padding: '2rem 4rem',
            borderBottom: '1px solid #dedede',
          }}
        >
          <div className='flex horizontally center-vertically'>
            <div className='flex-1'>
              <DateRange
                defaultValue={{
                  startDate: Moment().add(-31, 'd').valueOf(),
                  endDate: Moment().valueOf(),
                }}
                onChange={() => {}}
                className=''
                label='Export from and to'
                type='range'
              />
            </div>
          </div>
        </div>
        <div className='pd6'>
          <Loader />
        </div>
      </div>
    );
  }

  let items = [];
  Data.items.forEach((item) => {
    if (item.status === 'FAILED' || item.status === 'ONGOING') {
      items.push({
        content: item,
        render: [
          item.id,
          item.provider,
          item.category,
          item.transId,
          item.status,
          '-',
          item.paymentType,
          item.amount,
          item.ledger?.ledgerId || '-',
          item.ledger?.amount || '-',
          item.ledger?.balance || '-',
          item.invoiceConfig?.voucher || '-',
          item.invoiceConfig?.userGst?.gstin || '-',
          item.client.code,
          item.client.name,
          Moment(item.createdAt).format('DD MMM YYYY HH:mm a'),
          Moment(item.updatedAt).format('DD MMM YYYY HH:mm a'),
          '-',
          '-',
          '-',
          '-',
          '-',
          '-',
          '-',
          '-',
          '-',
          '-',
          '-',
          '-',
          '-',
          item.refundAmount || '-',
        ].map((title) => {
          if (title.content) {
            return { weight: 2, children: title.content };
          } else {
            return { weight: 2, title };
          }
        }),
      });
    } else {
      item.ticket.travellers.forEach((traveller) => {
        items.push({
          content: item,
          render: [
            item.id,
            item.provider,
            item.category,
            item.transId,
            item.status,
            item.ticket?.ticket || item.ticket?.pnr || '-',
            item.paymentType,
            item.amount,
            item.ledger?.ledgerId || '-',
            item.ledger?.amount || '-',
            item.ledger?.balance || '-',
            item.invoiceConfig?.voucher || '-',
            item.invoiceConfig?.userGst?.gstin || '-',
            item.client.code,
            item.client.name,
            Moment(item.createdAt).format('DD MMM YYYY HH:mm a'),
            Moment(item.updatedAt).format('DD MMM YYYY HH:mm a'),
            item.ticket?.bus?.label || '-',
            item.ticket?.bus?.provider || '-',
            item.ticket?.bus?.seating || '-',
            item.ticket?.bus?.departureTime || '-',
            item.ticket?.bus?.arrivalTime || '-',
            item.ticket?.bus?.isAc ? 'Yes' : 'No',
            item.ticket?.bus?.isGovt ? 'Yes' : 'No',
            traveller.name,
            traveller.gender,
            traveller.type,
            traveller.seatNo || '-',
            traveller.email || '-',
            traveller.mobile || '-',
            item.refundAmount || '-',
          ].map((title) => {
            if (title.content) {
              return { weight: 2, children: title.content };
            } else {
              return { weight: 2, title };
            }
          }),
        });
      });
    }
  });
  return (
    <div style={{ width: '100%' }}>
      <Navigation
        backUrl={'back'}
        title='Bus Bookings'
        chips={['Meta', 'Bus', 'Bookings']}
        description='Bus bookings appears below.'
      />
      <div
        style={{
          margin: '0 -4rem',
          padding: '2rem 4rem',
          borderBottom: '1px solid #dedede',
        }}
      >
        <div className='flex horizontally center-vertically'>
          <div className='flex-1'>
            <DateRange
              defaultValue={{
                startDate: Moment().add(-31, 'd').valueOf(),
                endDate: Moment().valueOf(),
              }}
              onChange={(dates) => {
                setDates({
                  ...dates,
                  page: 0,
                });
              }}
              className=''
              label='Export from and to'
              type='range'
            />
          </div>
        </div>
      </div>
      <div>
        <RBAC role={ROLES.REPORTS}>
          <Button onClick={downloadData} className='btn btn-black'>
            Download Data
          </Button>
        </RBAC>
        <ScrollableDynamicTable
          autoTrigger={false}
          tableStyle={{
            borderSpacing: 0,
          }}
          width={150}
          search={Dates}
          setPage={(page) => {
            setDates({
              ...Dates,
              page,
              total: Dates.totalPages,
            });
          }}
          data={{
            current: Data.currentPage,
            total: Data.totalPages,
            items,
          }}
          headers={[
            'Id',
            'Provider',
            'Category',
            'Booking Id',
            'Status',
            'Provider Id',
            'Payment Type',
            'Amount',
            'Ledger Id',
            'Ledger Amount',
            'Ledger Balance',
            'Voucher',
            'Gstin',
            'Client Code',
            'Client Name',
            'Created On',
            'Updated On',
            'Bus Label',
            'Bus Provider',
            'Seating',
            'Departure',
            'Arrival',
            'Ac',
            'Govt',
            'Traveller Name',
            'Traveller Gender',
            'Traveller Type',
            'Traveller Seat',
            'Traveller Email',
            'Traveller Mobile',
            'Refund Amount',
          ].map((item) => ({
            weight: 2,
            title: item,
          }))}
        />
      </div>
    </div>
  );
}
