import React from 'react';
import {Button, Headings, Seperator} from 'retro';
import packageJson from '../../../../../package.json';


function Updates() {

    const clearCache = () => {
		if (caches) {
		  caches.keys().then(function(names) {
			for (let name of names) caches.delete(name);
		  });
		  console.log('Cache Cleared!!')
		  window.location.reload();
		}
	  }

    return (
        <>
        <>
            <div className='border rounded-md pd4 mt3' style={{display:'inline-block'}}>
                <Headings.Regular>You are on Version {packageJson.version}</Headings.Regular>
                <Seperator/>
                <div className='mr3 mt2'>
                    <div>Fetch the latest version</div>
                </div>
                <Button onClick={()=>{clearCache()}} className='btn-black text-small' margin='mt2'>Force Update</Button>
            </div>
        </>
        </>
    );
}

export default Updates;
