import React, {useCallback, useEffect, useState} from 'react'
import {Loader, Navigation, ScrollableDynamicTable, SearchBox, TOAST as Alert, Wrapper, NavDivider, DateRange, Button, TOAST} from "../../../../../retro";
import {useHistory} from "react-router-dom";
import {get} from "../../../../Network/Axios";
import Moment from "moment/moment";
import OpenIcon from "@atlaskit/icon/glyph/open";
import RBAC from 'App/HOCs/RBAC';
import ROLES from 'App/Constants/Roles';
import { DownloadCsv } from 'retro/Utils';


export default function Refunds() {
    let [loading, setLoading] = useState(true);
    const History = useHistory()
    const [search, setSearch] = useState({
        page: 0,
        sort: 'id',
        order: 'desc',
        q: undefined
    })
    const [Dates, setDates] = useState({
        page: 0,
    });
    const [Data, SetData] = useState({
        total: 0,
        currentPage: 0,
        totalPages: 0,
        items: []
    })
    const loadPage = useCallback((search) => {
        get(`/personal/refund/queue`, (e, r) => {
            if (r) {
                SetData({
                    ...r
                })
                setLoading(false)
            } else {
                Alert.handleError(e)
            }
        }, {
            offset: search.page,
            sort: search.sort,
            order: search.order,
            q: search.q,
            start:search.startDate,
            end: search.endDate
        })
    }, [SetData])
    const downloadData = () => {
        setLoading(true);
        get(
          `/personal/refund/queue/all`,
          (e, r) => {
            if (e) {
              setLoading(false);
              console.log(e);
              TOAST.error('Unable to download');
              return;
            }
            let data = [];
            let headers = [
                'Trans Id',
                'Booking Id',
                'Status',
                'Created At',
                'User Name',
                'User Email',
                'User Id',
                'Amount',
                'Payment Id',
                'Category',
                'Remarks',
            ];
            data.push(headers);
            r?.items?.map((item) => {
                data.push([
                    item?.transId || '-',
                    item?.bookingId || '-',
                    item?.status || '-',
                    Moment(item.createdAt).format("DD MMM YYYY HH:mm a")|| '-',
                    item?.user?.name || '-',
                    item?.user?.email || '-',
                    item?.user?.id || '-',
                    item?.amount || '-',
                    item?.paymentId || '-',
                    item?.category || '-',  
                    item?.remarks || '-'
                ])
            });
            DownloadCsv(
              `Perks_${Moment(Dates.startDate).format('DD_MM_YYYY')}_${Moment(
                Dates.endDate
              ).format('DD_MM_YYYY')}.csv`,
              data
            );
            setLoading(false);
          },
          {
            start: Dates.startDate,
            end: Dates.endDate,
          }
        );
      };
    useEffect(() => {
        loadPage(search)
    }, [search])
    useEffect(() => {
        if (Dates && Dates.startDate) {
            loadPage(Dates);
        }
    }, [Dates]);
    return (
        <Wrapper id="wrapper-nav">
            <Navigation title="Refund Queue" chips={["Perks","Refund Queue"]} description="Details of all active refunds appears below."/>
            <NavDivider/>
            <div className='flex horizontally center-vertically'>
                <div className='flex-1'>
                <DateRange
                    defaultValue={{
                    startDate: Moment().add(-31, 'd').valueOf(),
                    endDate: Moment().valueOf(),
                    }}
                    onChange={(dates) => {
                    setDates({
                        ...dates,
                        page: 0,
                    });
                    }}
                    className='mt2'
                    label=''
                    type='range'
                />
                </div>
            </div>
            {
                loading ?
                    <Loader/> :
                    <div className="relative mt1">
                         <RBAC>
                            <Button onClick={downloadData} className='btn btn-black'>
                                Download Data
                            </Button>
                        </RBAC>
                        <SearchBox onChange={(q) => {
                            setSearch({
                                ...search,
                                q: q && q.length > 0 ? q : undefined,
                                page: 0
                            })
                        }} containerClass="" placeholder="Enter your search criteria"/>
                        <ScrollableDynamicTable
                            setSearch={(search) => {
                                setSearch({
                                    ...search,
                                    page: 0
                                })
                            }} search={search}
                            tableStyle={{
                                borderSpacing: 0
                            }}
                            width={150}
                            setPage={page => {
                                setSearch({
                                    ...search,
                                    page
                                })
                            }}
                            data={{
                                current: Data.currentPage,
                                total: Data.totalPages,
                                items: Data.items.map(item => {
                                    const click = () => {
                                        onClick(item)
                                    }
                                    return {
                                        content: item,
                                        render: [
                                            {
                                                class: "table-sticky-row-first",
                                                children: (
                                                    <div>
                                                        <p className="text">
                                                            {item.transId}
                                                        </p>
                                                        <p className="text-small text-light">
                                                            {item.email}
                                                        </p>
                                                        <p className="text-small text-light">
                                                            {Moment(item.createdAt).format("DD MMM YYYY HH:mm a")}
                                                        </p>
                                                    </div>
                                                ),
                                                weight: 1.5,
                                            },
                                            {
                                                weight: 1,
                                                children: (<div>
                                                    <p className="text">
                                                        {item.user.name}
                                                    </p>
                                                    <p className="text-small">
                                                        {item.user.email}
                                                    </p>
                                                </div>)
                                            },
                                            {
                                                weight: 1,
                                                title: item.amount
                                            },
                                            {
                                                weight: 1,
                                                buttons: [
                                                    {
                                                        title: "View",
                                                        className: 'btn-secondary btn-sm',
                                                        before: (<OpenIcon size="small"/>),
                                                        onClick: click
                                                    }
                                                ],
                                                actions: [
                                                    {
                                                        title: "Quick Actions",
                                                        actions: [
                                                            {
                                                                title: "View",
                                                                before: (<OpenIcon size="small"/>),
                                                                onClick: click
                                                            }
                                                        ]
                                                    }
                                                ]
                                            }
                                        ]
                                    }
                                })
                            }} headers={[
                            {
                                class: "table-sticky-row-first",
                                weight: 1.5,
                                title: "Details",
                            },
                            {
                                weight: 1,
                                title: "User",
                            },
                            {
                                weight: 1,
                                title: "Amount",
                            },
                            {
                                weight: 1,
                                title: "Actions",
                                style: {
                                    justifyContent: "end"
                                }
                            }
                        ]}/>
                    </div>
            }
        </Wrapper>
    )
}
