import React, {useRef, useState} from 'react'
import {Button, Input, NavDivider, Navigation, TOAST} from "retro/index";
import {post} from "App/Network/Axios";

export default function CancelInvoiceController() {
	const TRANSID = useRef()
	const CRN = useRef()
	const INV = useRef()
	const GST = useRef()
	const SVC = useRef()
	const REMARKS = useRef()
    const [loading,setLoading] = useState(false)
	return (
		<div>
            <Navigation backUrl={'back'} title="Shift Flight Reseller Entity Controller" />
            <NavDivider />
			<Input
				ref={TRANSID}
				type="text"
				label="Transaction Id"
				placeholder="Transaction Id"
			/>
			<Input
				ref={GST}
				type="text"
				label="New Gst Number"
				placeholder="New Gst Number"
			/>
			<Input
				ref={CRN}
				type="text"
				defaultValue="CRFN"
				label="Credit Note Prefix"
				placeholder="Credit Note Prefix"
			/>
			<Input
				ref={INV}
				defaultValue="INC"
				type="text"
				label="Invoice Prefix"
				placeholder="Invoice Prefix"
			/>
			<Input
				ref={SVC}
				type="text"
				defaultValue="SNC"
				label="Service Prefix"
				placeholder="Service Prefix"
			/>
			<Input
				ref={REMARKS}
				type="text"
				label="Remarks"
				placeholder="Remarks"
			/>
            <Button
                onClick={()=>{
					let transId = TRANSID.current.value;
					let remarks = REMARKS.current.value;
					let crn = CRN.current.value;
					let svc = SVC.current.value;
					let gstin = GST.current.value;
					let inv = INV.current.value;
					if(!transId){
						TOAST.error("Enter a valid trans id")
						return
					}
					if(!svc){
						TOAST.error("Enter a valid service charges")
						return
					}
					if(!inv){
						TOAST.error("Enter a valid invoice number")
						return
					}
					if(!crn){
						TOAST.error("Enter a valid credit number")
						return
					}
					if(!gstin){
						TOAST.error("Enter a valid gstin")
						return
					}
					setLoading(true)
					post(`/meta/${transId}/flight/cancelAndNewEntity`,{
						remarks,
						gstin,
						inv,
						svc,
						crn
					},(e,r)=>{
						setLoading(false)
						if(r){
							TOAST.success("Regeneration done successfully")
							TRANSID.current.value = ""
							REMARKS.current.value = ""
						}else{
							TOAST.handleError(e)
						}
					})
                }}
                className="btn-black btn-primary"
                margin="pd2 mt4"
                loading={loading}
            >
                Regenerate
            </Button>
		</div>
	)
}
