import React, { useCallback, useEffect, useState } from 'react';
import {
  Loader,
  ScrollableDynamicTable,
  SearchBox,
  TOAST as Alert,
  Navigation,
  Button,
} from 'retro';
import { get } from 'App/Network/Axios';
import OpenIcon from '@atlaskit/icon/glyph/open';
import ViewRequest from './ContractedRequest';
import moment from 'moment';
import { DownloadCsv } from 'retro/Utils';
import RBAC from 'App/HOCs/RBAC';
import ROLES from 'App/Constants/Roles';
import Filter from 'retro/Filter';
import FilterDropDown from 'App/Components/Filter/FilterDropDown';

/**
 * Search Criteria
 * Trip Id
 * Guest Name
 * Guest Mobile
 *
 *
 * Break Up
 * @returns {JSX.Element}
 */
export default function ongoing() {
  let [loading, setLoading] = useState(true);
  const [id, setId] = useState(undefined);
  const [search, setSearch] = useState({
    page: 0,
    sort: 'createdAt',
    order: 'desc',
  });
  const [Data, SetData] = useState({
    total: 0,
    currentPage: 0,
    totalPages: 0,
    items: [],
  });
  const [filterModal,setFilterModal] = useState(false);
  const [filter, setFilter] = useState({
		client: [],
		checkin: []
	});
  const loadPage = useCallback(
    (search) => {
      const clientIds = filter.client.map(client => client.id);
      get(
        `/meta/contracted/pending`,
        (e, r) => {
          if (r) {
            SetData({
              ...r,
              total: r.total ? r.total : Data.total,
              currentPage: r.currentPage,
              totalPages: r.totalPages ? r.totalPages : Data.totalPages,
            });
            setLoading(false);
          } else {
            Alert.handleError(e);
          }
        },
        {
          offset: search.page,
          sort: search.sort,
          order: search.order,
          q: search.q,
          start: filter.checkin.length > 0 
          ? moment().valueOf().toString()
					: undefined,
			    end: filter.checkin.length > 0 
          ? filter.checkin[0] === 'Upcoming 24 hours' 
            ? moment().add(24, 'hours').valueOf().toString() 
            : moment().add(48, 'hours').valueOf().toString()
				  : undefined,
			    clientIds: clientIds.length > 0 ? clientIds : undefined
        }
      );
    },
    [Data, SetData, filter.checkin, filter.client]
  );
  useEffect(() => {
    loadPage(search);
  }, [search, filter.checkin, filter.client]);
  if (loading) {
    return <Loader />;
  }
  return (
    <div
    style={{
      width: 'calc(100vw - 475px)',
    }} 
    className='overScrollY'
    >
      <div className='mb2 relative'>
        <Navigation title='Api Queues' />
        <RBAC role={ROLES.REPORTS}>
          <Button
            onClick={() => {
              setLoading(true);
              get(`/meta/contracted/pending/all`, (e, r) => {
                if (r) {
                  let data = [];
                  const headers = [
                    'Request Id',
                    'City',
                    'Hotel Name',
                    'Hotel Address',
                    'Created At',
                    'Client Code',
                    'Guest Name',
                    'Guest Email',
                    'Search Details',
                    'Room Name',
                    'Booking Details',
                  ];
                  data.push(headers);
                  r.items.forEach((item) => {
                    let line = [];
                    line.push([item.referenceId]);
                    line.push([item.hotel?.city || '-']);
                    line.push([item.hotel?.name || '-']);
                    line.push([item.hotel?.address || '-']);
                    line.push([
                      moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss') ||
                        '-',
                    ]);
                    line.push([item.client.clientCode]);
                    line.push([item.guests[0].name]);
                    line.push([item.guests[0].email]);
                    line.push([
                      `${item.searchRequest.dates.in} - ${item.searchRequest.dates.out}`,
                    ]);
                    line.push([item.room?.name || '-']);
                    line.push([
                      `${item.totalNight} nights * ${item.numberOfRooms} rooms @${item.pricePerNight} / per night`,
                    ]);
                    data.push(line);
                  });
                  DownloadCsv(
                    `api_queue${moment().format('DD_MM_YYYY')}}.csv`,
                    data
                  );
                }
                setLoading(false);
              });
            }}
            loading={loading}
            className='btn btn-primary btn-sm absolute top-2 right-0'
            style={{ zIndex: 10 }}
          >
            <i className='fa fa-download mr1' />
            Download Data
          </Button>
        </RBAC>
      </div>
      <Filter
  			margin="0 -4rem"
	  		onSearch={(q) => {
        setSearch({
          ...search,
          page: 0,
          q,
          });
        }}
			  onFilter={() => {
				  setFilterModal(true)
			  }}
		  />

		<FilterDropDown
			shown={filterModal}
			filters={filter}
			setFilters={setFilter}
			choices={{
				client: [],
				checkin: [
					{
						title: 'Upcoming 24 hours',
						type: 'radio'
					},
					{
						title: 'Upcoming 48 hours',
						type: 'radio'
					}
				]
			}}
			types={["Client", "CheckIn"]}
			onClose={() => {
				setFilterModal(false);
			}}
		/>
      <ScrollableDynamicTable
        setSearch={(search) => {
          setSearch({
            ...search,
            page: 0,
          });
        }}
        search={search}
        tableStyle={{
          borderSpacing: 0,
        }}
        setPage={(page) => {
          setSearch({
            ...search,
            page,
          });
        }}
        data={{
          current: Data.currentPage,
          total: Data.totalPages,
          items: Data.items.map((item) => {
            const click = () => {
              setId(item.queueId);
            };
            return {
              content: item,
              render: [
                {
                  children: (
                    <div>
                      <p className='text fw-bold'>{item.referenceId}</p>
                      <p className='text-small text-light'>{item.hotel.name}</p>
                      <p className='text-small text-light'>
                        {item.hotel.address}
                      </p>
                      <p className='text-small'>
                        Created On{' '}
                        {moment(item.createdAt).format('DD MMM YYYY HH:MM A')}
                      </p>
                    </div>
                  ),
                  weight: 1.5,
                },
                {
                  weight: 1,
                  children: (
                    <>
                      <p>{item.client.clientCode}</p>
                      <p className='text-small'>{item.guests[0].name}</p>
                    </>
                  ),
                },
                {
                  weight: 1,
                  children: (
                    <div>
                      <p>{item.hotel.city}</p>
                      <p className='text-small'>
                        {item.searchRequest.dates.in} -{' '}
                        {item.searchRequest.dates.out}
                      </p>
                      <p className='text-small'>{item.room.name}</p>
                    </div>
                  ),
                },
                {
                  weight: 2,
                  children: (
                    <>
                      <p>INR {item.totalPrice}</p>
                      <p className='text-small'>
                        {item.totalNight} nights * {item.numberOfRooms} rooms @{' '}
                        {item.pricePerNight} / per night
                      </p>
                    </>
                  ),
                },
                {
                  weight: 1,
                  title: moment(item.createdAt).format('DD MMM YYYY HH:mm a'),
                },
                {
                  weight: 1,
                  buttons: [
                    {
                      title: 'View',
                      className: 'btn-secondary btn-sm',
                      before: <OpenIcon size='small' />,
                      onClick: click,
                    },
                  ],
                  actions: [
                    {
                      title: 'Quick Actions',
                      actions: [
                        {
                          title: 'View',
                          before: <OpenIcon size='small' />,
                          onClick: click,
                        },
                      ],
                    },
                  ],
                },
              ],
            };
          }),
        }}
        headers={[
          {
            weight: 1.5,
            title: 'Details',
          },
          {
            weight: 1,
            title: 'Client',
          },
          {
            weight: 1,
            title: 'Search Details',
          },
          {
            weight: 2,
            title: 'Booking Details',
          },
          {
            weight: 1,
            title: 'Request Date',
            sort: 'createdAt'
          },
          {
            weight: 1,
            title: 'Actions',
            style: {
              justifyContent: 'end',
            },
          },
        ]}
      />
      {id !== undefined && (
        <ViewRequest
          id={id}
          onClose={() => {
            setId(undefined);
            loadPage(search);
          }}
        />
      )}
    </div>
  );
}
