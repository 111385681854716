import {Pages} from "../../../../../../retro";
import QueueType from "./QueueType";


export default function QueueCommunications() {

    // QueueTypes array to storing objects of all service queues like Hotel, Visa, etc
    // name is used to show the sub headings and for showing the keyword on UI.
    // value stores the keyword that needs to be passed in an api as serviceType to fetch data of emails   
    const queueTypes = [
        {
            name:"Hotel Queue",
            value:"hotel"
        }
    ]
     return (
        <>
            <Pages.Title title="Queue Communications" description="All the Queue Communications appear here."/>
            {
                queueTypes.map((item)=>{
                    return (
                        <>
                            <QueueType key={item} queueType={item}/>
                        </>
                    )
                })
            }
            
        </>
    )
}