import React from 'react';
import { ExpandableSidebar, Pages, Wrapper } from 'retro';
import AllClients from './Clients';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import QueuesIcon from '@atlaskit/icon/glyph/queues';
import PeopleGroupIcon from '@atlaskit/icon/glyph/people-group';
import ClientDetails from '../ClientDetails';
import useRoleHook from 'App/Hooks/useRoleHook';
import CreateClient from './CreateClient/index';
import ROLES from 'App/Constants/Roles';
import RBAC from 'App/HOCs/RBAC';
import Gstins from "App/Pages/App/MetaApis/Da/Gstins";
import useFunctionallityHook from 'App/Hooks/useFunctionallityHook';

function AllClientsComponent() {
  const HISTORY = useHistory();
  return (
    <RBAC role={ROLES.CLIENTS} renderNoAccess>
      <AllClients
        onClick={(item) => {
          HISTORY.push(`/app/client/${item.id}/users?type=clients`);
        }}
      />
    </RBAC>
  );
}

function ClientSwitch() {
  return (
    <Pages.Page>
      <Switch>
        <Route component={AllClientsComponent} path='/app/clients' exact />
        <Route component={CreateClient} exact path='/app/clients/new' />
      </Switch>
    </Pages.Page>
  );
}

export default function Render() {
  const items = [];
  const HISTORY = useHistory();
  const Location = useLocation();
  const isClientWrite = useRoleHook('CLIENTS_WRITE');
  const isClient = useRoleHook('CLIENTS');
  const {gstUtility} = useFunctionallityHook();
  items.push({
    title: 'Control Room',
    actions: [
      {
        title: 'All clients',
        icon: <QueuesIcon size='small' />,
        active: Location.pathname === '/app/clients',
        onClick: () => {
          HISTORY.push('/app/clients');
        },
      },
      {
        title: 'Create New Client',
        icon: <PeopleGroupIcon size='small' />,
        active: Location.pathname.includes('/app/clients/new'),
        onClick: () => {
          HISTORY.push('/app/clients/new');
        },
      },
    ],
  });
  if(gstUtility){
    items[0].actions.push(
      {
        title: 'Gst Utility',
        icon: <QueuesIcon size='small' />,
        active: Location.pathname === '/app/clients/gst',
        onClick: () => {
          HISTORY.push('/app/clients/gst');
        },
      },
    );
  }
  if (!isClientWrite) {
    if (isClient){
      items[0].actions.splice(1, 1);
    }
    else{
      items[0].actions.splice(1, 2);
    }
  }

  return (
    <div className='flex h-100h w-100'>
      <ExpandableSidebar items={items} />
      <Wrapper>
        <Switch>
          <Route path='/app/client/:id'>
            <ClientDetails />
          </Route>
          <Route component={ClientSwitch} path='/app/clients' exact />
          <Route component={ClientSwitch} path='/app/clients/new' exact />
          <Route component={Gstins} path='/app/clients/gst' exact />
        </Switch>
      </Wrapper> 
    </div>
  );
}
