import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  DateRange,
  Loader,
  Navigation,
  ScrollableDynamicTable,
  TOAST,
  TOAST as Alert,
  Wrapper,
} from "../../../../../retro";
import { get } from "../../../../Network/Axios";
import OpenIcon from "@atlaskit/icon/glyph/open";
import Moment from "moment";
import CopyIcon from "@atlaskit/icon/glyph/copy";
import { DownloadCsv } from "../../../../../retro/Utils";

export default function AllClients({}) {
  let [loading, setLoading] = useState(true);
  const [search, setSearch] = useState({
    page: 0,
    sort: "id",
    order: "desc",
    q: undefined,
  });
  const [range, setRange] = useState(undefined);
  const [Data, SetData] = useState({
    total: 0,
    currentPage: 0,
    totalPages: 0,
    items: [],
  });
  const downloadDump = () => {
    get(
      `meta/bus/perks/all`,
      (e, r) => {
        if (e) {
          Alert.handleError(e);
        }
        let data = [];
        let headers = [
          "Details",
          "Category",
          "Status",
          "Amount",
          "Travelling City",
          "Boarding Point",
          "Dropping Point",
          "Travellers",
          "Traveller Email",
          "Traveller Mobile No",
          "Booking Date",
          "Voucher",
        ];
        data.push(headers);
        r.bookings.map((item) => {
          try {
            data.push([
              `${item.transId} - ${Moment(item.updatedAt).format(
                "DD MMM YYYY HH:mm a"
              )}`,
              item.category || "-",
              item.status || "-",
              item.amount || "-",
              `From ${item.ticket?.search?.from} To ${item.ticket?.search?.to} ` ||
                "-",
              boardingPoint(item),
              droppingPoint(item),
              item.ticket.travellers?.[0]?.name || "-",
              item.ticket?.travellers?.[0]?.email || "-",
              item.ticket?.travellers?.[0]?.mobile || "-",
              Moment(item.createdAt).format("DD MMM YYYY") || "-",
              item?.invoiceConfig?.file,
            ]);
          } catch (error) {}
        });
        console.log("r", r);
        DownloadCsv("bus_dump.csv", data);
      },
      {
        start: search.startDate,
        end: search.endDate,
      }
    );
  };
  const loadPage = useCallback(
    (search) => {
      get(
        `meta/bus/perks`,
        (e, r) => {
          if (r) {
            SetData({
              ...r,
            });
            setLoading(false);
          } else {
            Alert.handleError(e);
          }
        },
        {
          offset: search.page,
          sort: search.sort,
          order: search.order,
          start: search.startDate,
          end: search.endDate,
        }
      );
    },
    [SetData]
  );

  useEffect(() => {
    if (search.startDate) {
      loadPage(search);
    }
  }, [search]);
  useEffect(() => {
    if (range) {
      setSearch({
        ...search,
        page: 0,
        startDate: Moment(range.startDate).valueOf(),
        endDate: Moment(range.endDate).valueOf(),
      });
    }
  }, [range, SetData]);

  function boardingPoint(item) {
    const pickupId = item.ticket.pickupId;

    const boardingPoint = item.ticket.bus.boarding.find((boarding) => {
      return boarding.code === pickupId;
    });

    if (boardingPoint) {
      return `${boardingPoint.name} - ${Moment(boardingPoint.time).format(
        "hh:mm a"
      )}`;
    } else {
      return "-"; // Return "-" if pickupId is not found in boarding points
    }
  }

  function droppingPoint(item) {
    const dropId = item.ticket.dropId;

    const dropping = item.ticket.bus.dropping.find((dropping) => {
      return dropping.code === dropId;
    });

    if (dropping) {
      return `${dropping.name} - ${Moment(dropping.time).format("hh:mm a")}`;
    } else {
      return "-"; // Return null if dropId is not found in boarding points
    }
  }

  return (
    <Wrapper id='wrapper-nav' className='w-sidebar-minus'>
      <Navigation
        title='All Bus'
        chips={["Perks", "Bus"]}
        backUrl='back'
        description='Details of all bus bookings appears below.'
      />
      {loading ? (
        <div
          style={{
            display: "none",
          }}
        >
          <Loader />
          <DateRange type='range' onChange={setRange} />
        </div>
      ) : (
        <div className='relative'>
          <ScrollableDynamicTable
            onRange={setRange}
            tableStyle={{
              borderSpacing: 0,
            }}
            width={180}
            setSearch={(search) => {
              setSearch({
                ...search,
                page: 0,
              });
            }}
            search={search}
            setPage={(page) => {
              setSearch({
                ...search,
                page,
              });
            }}
            data={{
              current: Data.currentPage,
              total: Data.totalPages,
              items: Data.items.map((item) => {
                const click = () => {
                  onClick(item);
                };
                return {
                  content: item,
                  render: [
                    {
                      class: "table-sticky-row-first",
                      children: (
                        <div>
                          <p className='text'>{item.transId}</p>
                          {item.paymentId ? (
                            <p className='text-small text-light flex horizontally center-vertically'>
                              {item.paymentId}
                              <span
                                className='ml1'
                                onClick={() => {
                                  navigator.clipboard
                                    .writeText(item.paymentId)
                                    .then(
                                      function () {
                                        TOAST.success("Copy Completed");
                                      },
                                      function (err) {
                                        TOAST.error("Could not copy text");
                                      }
                                    );
                                }}
                              >
                                <CopyIcon size='small' />
                              </span>
                            </p>
                          ) : undefined}
                          <p className='text-small text-light'>
                            {Moment(item.updatedAt).format(
                              "DD MMM YYYY HH:mm a"
                            )}
                          </p>
                        </div>
                      ),
                      weight: 1.6,
                    },

                    {
                      weight: 1.5,
                      title: item.category,
                    },

                    {
                      weight: 1,
                      title: item.status,
                    },
                    {
                      weight: 1,
                      title: item.amount,
                    },
                    {
                      weight: 1,
                      children: (
                        <div>{`From ${item.ticket?.search?.from} To ${item.ticket?.search?.to}`}</div>
                      ),
                    },
                    {
                      weight: 1,
                      title: boardingPoint(item),
                    },
                    {
                      weight: 1,
                      title: droppingPoint(item),
                    },
                    {
                      weight: 1,
                      children: (
                        <div>
                          {item.ticket && item.ticket.travellers?.length && (
                            <div>
                              {item.ticket.travellers[0].fName}{" "}
                              {item.ticket.travellers[0].lName}
                            </div>
                          )}
                          <div>
                            {item.ticket
                              ? item.ticket.travellers?.length > 1
                                ? `+ ${
                                    item.ticket.travellers?.length - 1
                                  } travellers`
                                : ""
                              : "-"}
                          </div>
                        </div>
                      ),
                    },
                    {
                      weight: 2,
                      children: (
                        <div>{item.ticket?.travellers?.[0]?.email}</div>
                      ),
                    },
                    {
                      weight: 1,
                      children: (
                        <div>{item.ticket?.travellers?.[0]?.mobile}</div>
                      ),
                    },

                    {
                      weight: 2,
                      children: (
                        <div>
                          {Moment(item.createdAt).format("DD MMM YYYY")}
                        </div>
                      ),
                    },
                    {
                      weight: 2,
                      children: (
                        <div>
                          <a
                            href={item?.invoiceConfig?.file}
                            target='_blank'
                            style={{ textDecoration: "underline" }}
                          >
                            Voucher
                          </a>
                        </div>
                      ),
                    },
                    {
                      weight: 1,
                      actions: [
                        {
                          title: "Quick Actions",
                          actions: [
                            {
                              title: "View",
                              before: <OpenIcon size='small' />,
                              onClick: click,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                };
              }),
            }}
            headers={[
              {
                class: "table-sticky-row-first",
                weight: 1.6,
                title: "Details",
              },
              {
                weight: 1.5,
                title: "Category",
                sort: "category",
              },

              {
                weight: 1,
                title: "Status",
              },
              {
                weight: 1,
                title: "Amount",
                sort: "amount",
              },
              {
                weight: 1,
                title: "Travelling City",
              },
              {
                weight: 1,
                title: "Boarding Point",
              },
              {
                weight: 1,
                title: "Dropping Point",
              },
              {
                weight: 1,
                title: "Travellers",
              },
              {
                weight: 1,
                title: "Traveller Email",
              },
              {
                weight: 1,
                title: "Traveller Mobile No",
              },
              {
                weight: 1,
                title: "Booking Date",
              },
              {
                weight: 1,
                title: "Voucher",
              },
              {
                weight: 1,
                title: "Actions",
                style: {
                  justifyContent: "end",
                },
              },
            ]}
          >
            <div className='flex-1'>
              <Button
                debounce={true}
                onClick={downloadDump}
                className='btn-primary'
                margin=''
              >
                Download Dump
              </Button>
            </div>
          </ScrollableDynamicTable>
        </div>
      )}
    </Wrapper>
  );
}
