import React, {useEffect, useState} from 'react'
import {get} from "../../../../Network/Axios";
import {useParams} from "react-router-dom";
import FileIcon from "../../../../../retro/Attachment/Icons/file.png";

export default function Documents() {
    const {id} = useParams();
    const [keys,setKeys] = useState({
        pvtKey:"",
        pubKey:""
    })
    const [data,setData] = useState({
        name:""
    })
    const load = (id)=>{
        get(`/clients/theme/${id}/certs`,(e,r)=>{
            if(r){
                setKeys(r)
            }
        })
        get(`/clients/${id}`, (e, r) => {
            if (r) {
                setData({
                    ...r,
                })
            }
        })
    }
    useEffect(()=>{
        load(id)
    },[id])


    return (
		<div>
            <h3 className="fw-bold mt2">
                Documents
            </h3>
            <p>
                All documents assigned to client appears below.
            </p>
            <div className="flex-wrap mt2 flex">
                <div className="mr2">
                    <img style={{width:30,height:30,borderRadius:12,cursor:'pointer'}} alt="attachments" onClick={()=>{
                        const element = document.createElement("a");
                        const file = new Blob([keys.pubKey], {type: 'text/plain'});
                        element.href = URL.createObjectURL(file);
                        element.download = `${data.name}_pub_key.cer`;
                        document.body.appendChild(element);
                        element.click();
                    }} src={FileIcon}/>
                    <p className="text-small">
                        Pub Key
                    </p>
                </div>
                <div className="mr2">
                    <img  style={{width:30,height:30,borderRadius:12,cursor:'pointer'}} alt="attachments" onClick={()=>{
                        const element = document.createElement("a");
                        const file = new Blob([keys.pvtKey], {type: 'text/plain'});
                        element.href = URL.createObjectURL(file);
                        element.download = `${data.name}_pvt_key.cer`;
                        document.body.appendChild(element);
                        element.click();
                    }} src={FileIcon}/>
                    <p className="text-small">
                        Pvt Key
                    </p>
                </div>
            </div>
		</div>
	)
}
