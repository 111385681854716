import React from 'react'
import {ExpandableSidebar, NavDivider, Navigation, NotFound, Page, Tabs, Wrapper} from "../../../../retro";
import {Route, Switch, useHistory, useLocation} from "react-router-dom";
import Theme from './Theme'
import Assets from './Assets'
import Domains from './Domain'
import Settings from "@atlaskit/icon/glyph/settings";
import LockIcon from "@atlaskit/icon/glyph/lock";
import BookIcon from "@atlaskit/icon/glyph/book";
import QueuesIcon from "@atlaskit/icon/glyph/queues";
import FileIcon from "@atlaskit/icon/glyph/file";

export default function index() {
	const History = useHistory();
	const location = useLocation();
	const tabs = [];
	tabs.push({
		title: "Theme",
		path: "/app/customize"
	})
	tabs.push({
		title: "Manage Assets",
		path: "/app/customize/manage"
	})
	tabs.push({
		title: "Domain Mapping",
		path: "/app/customize/domains"
	})
	return (
		<div className="flex h-100h w-100">
			<ExpandableSidebar items={ [
				{
					title:"Actions",
					actions:[
						{
							title: "Theme",
							icon : (<Settings size="small"/>),
							active:location.pathname==='/app/customize',
							onClick:()=>{
								History.push('/app/customize')
							},
						},
						{
							title: "Manage Assets",
							icon : (<Settings size="small"/>),
							active:location.pathname==='/app/customize/manage',
							onClick:()=>{
								History.push('/app/customize/manage')
							},
						},
						{
							title: "Manage Domains",
							icon : (<Settings size="small"/>),
							active:location.pathname==='/app/customize/domains',
							onClick:()=>{
								History.push('/app/customize/domains')
							},
						},
					]
				}
			]}/>
			<Wrapper>
				<Navigation title="Customization" chips={["Dice Customization"]} description="Customize your platform for your look and feel."/>
				<NavDivider/>
				<Switch>
					<Route path="/app/customize" exact={true}>
						<Theme/>
					</Route>
					<Route path="/app/customize/manage" exact={true}>
						<Assets/>
					</Route>
					<Route path="/app/customize/domains" exact={true}>
						<Domains/>
					</Route>
					<NotFound/>
				</Switch>
			</Wrapper>
		</div>
	)
}
