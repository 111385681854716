import { get, post } from 'App/Network/Axios';
import Moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Alert, TOAST } from 'retro/Alert/Alert';
import { DownloadCsv } from 'retro/Utils';
import { Button, DateRange, Loader } from 'retro/index';

const DownloadLedgerReport = ({ id }) => {
  const [Dates, setDates] = useState();
  const [uploading, setUploading] = useState(false);

  const downloadData = () => {
    setUploading(true);
    if (Dates) {
      post(
        `/accounts/account/${id}/ledgers/download`,
        {
          start: String(Dates.startDate),
          end: String(Dates.endDate),
        },
        (e, r) => {
          setUploading(false);
          if (r) {
            let data = [];
            let headers = [
              'Details',
              'Code',
              'Date',
              'Remarks',
              'Status',
              'Category',
              'Reference Id',
              'Amount',
              'Start Balance',
              'End Balance',
            ];
            data.push(headers);
            r?.ledgers.forEach((item) => {
              data.push([
                `#${item.id}` || '-',
                item.referenceType || '-',
                Moment(item.date).format('dd DD MMM yyyy HH:mm'),
                item.remarks || '-',
                item.type || '-',
                item?.transaction?.category || '-',
                item?.transaction?.referenceId || '-',
                `${Math.floor(item.amount, 10)}` || '-',
                `INR ${Math.abs(item?.startBalance || 0)} ${(item?.startBalance || 0)>=0?"CR":"DB"}`,
                `INR ${Math.abs(item?.endBalance || 0)} ${(item?.endBalance || 0)>=0?"CR":"DB"}`
              ]);
            });

            DownloadCsv(
              `Da_report_${Moment(Dates.startDate).format(
                'DD_MM_YYYY'
              )}_${Moment(Dates.endDate).format('DD_MM_YYYY')}.csv`,
              data
            );
          } else {
            TOAST.handleError(e);
          }
        }
      );
    }
  };

  return (
    <div>
      <div className="flex vertically ">
        <div className="flex-1">
          <DateRange
            defaultValue={{
              startDate: Moment().add(-1, 'd').valueOf(),
              endDate: Moment().valueOf(),
            }}
            onChange={(dates) => {
              setDates({
                ...dates,
                page: 0,
              });
            }}
            className=""
            label="Export from and to"
            type="range"
          />
        </div>
        <div className="download_btn mt4 pb4">
          <Button
            className="btn btn-primary"
            loading={uploading}
            onClick={downloadData}
          >
            Download Data
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DownloadLedgerReport;
