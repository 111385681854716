import React, {useEffect, useState} from 'react'
import {
	Loader,
	NavDivider,
	Navigation,
	ScrollableDynamicTable,
	Seperator,
	TOAST,
	TableViewSwitch
} from "../../../../../../retro";
import Moment from 'moment'
import {get} from "../../../../../Network/Axios";
import {useHistory} from "react-router-dom";
import { Button } from '@material-ui/core';
import { DownloadCsv } from 'retro/Utils';

export default function index() {
	const [Dates, setDates] = useState({
		page:0
	});
	const [VIEW, SETVIEW] = useState("list")
	const HISTORY = useHistory();
	const [loading,setLoading] = useState(false)
	const [Data, SetData] = useState({
		total: 0,
		currentPage: 0,
		totalPages: 0,
		items: []
	})
	useEffect(()=>{
		if(Dates){
			get(`/meta/flight/invoices`,(e,r)=>{
				if(r){
					SetData({
						...r,
						currentPage: Dates.page ? Dates.page : 0,
						totalPages: r.totalPages > 0 ? r.totalPages : Data.totalPages,
						total: r.total > 0 ? r.total : Data.total
					})
				} else {
					Alert.handleError(e)
				}
			},{
				offset:Dates.page
			})
		}
	},[Dates])
	if(loading){
		return (
				<div className="pd6">
					<Loader/>
				</div>
		)
	}
	let items = [];
	Data.items.forEach(item=>{
		items.push({
			content: item,
			render: [
				{
					content:(
					<p className="fw-bold text-underline" 
					onClick={()=>{window.open(`/app/apps/meta/transaction/${item.transId}`,"_blank")}}
					>
						{item.transId}
					</p>)
				},
				,item.client.code,item.client.name,item.provider,item.paymentType
				,item.status,item.amount
			].map(title=>{
				if(title.content){
					return {weight:2,children:title.content}
				}else{
					return {weight:2,title}
				}
			})
		})
	})

	// function to get and concat invoie generation errors
	const getInvoiceErrors = (errors=[])=>{
		if(errors.length===0){
			return '-';
		}
		let errString = "";
		errors.forEach((err,idx)=>{
			errString += `${err.errorMsg}${idx===err.length-1 ? "": ", "}`;
		})
		return errString;
	}
	// function to get the end date for the invoice
	const getEnddate = (ticket = undefined) => {
		let segments = ticket?.segments;
		if(!segments){
			return "-";
		}
		return segments[segments.length-1]?.arrrivalDate?.split('T')[0] || "-"
	}

	const downloadreport = ()=>{
		setLoading(true);
        let data = [];
		let headers = [
			"Transaction ID",
			"Client Code",
			"Client Name",
			"Category",
			"Provider",
			"Payment Type",
			'Amount',
			"Status",
			"Document Type",
			"Document",
			"Voucher",
			"Generation Remarks",
			"Created At",
			"End Date"
		];
		data.push(headers);
		get("/meta/flight/invoices",(e,r)=>{
			if(e){
				setLoading(false);
				TOAST.handleError(e);
				return;
			}
			r.items.map((item)=>{
				try {
					if (item.invoiceConfig?.reseller){
						data.push([
							item?.transId || "-",
							item?.client?.code || "-",
							item?.client?.name || "-",
							item?.category || "-",
							item?.provider || "-",
							item?.paymentType || "-",
							item?.amount || "-",
							item?.status || "-",
							"Invoice",
							item?.invoiceConfig?.invoice || "-",
							item?.invoiceConfig?.file || "-",
							getInvoiceErrors(item?.invoiceConfig?.invoiceResponse?.response?.error),
							Moment(item?.createdAt).format("DD-MMM-YYYY HH:mm") || "-",
							getEnddate(item.ticket),
						]);
					}
					if (
						item.invoiceConfig != null &&
						item.invoiceConfig.serviceInvoice != null &&
						item.invoiceConfig.serviceInvoice !== "SKIPPED"
					  ) {
						data.push([
							item?.transId || "-",
							item?.client?.code || "-",
							item?.client?.name || "-",
							item?.category || "-",
							item?.provider || "-",
							item?.paymentType || "-",
							item?.amount || "-",
							item?.status || "-",
							"Service Invoice",
							item.invoiceConfig?.serviceInvoice || "-",
							item?.invoiceConfig?.file || "-",
							getInvoiceErrors(item?.invoiceConfig?.invoiceResponse?.response?.error),
							Moment(item?.createdAt).format("DD-MMM-YYYY HH:mm") || "-",
							getEnddate(item.ticket),
						]);
					}
					if (
						item.invoiceConfig !== null &&
						item.invoiceConfig.ancillaryInvoice !== null &&
						item.invoiceConfig.ancillaryInvoice !== "SKIPPED"
					) {
						data.push([
							item?.transId || "-",
							item?.client?.code || "-",
							item?.client?.name || "-",
							item?.category || "-",
							item?.provider || "-",
							item?.paymentType || "-",
							item?.amount || "-",
							item?.status || "-",
							"Ancillery Charges",
							item.invoiceConfig?.ancillaryInvoice || "-",
							item?.invoiceConfig?.file || "-",
							getInvoiceErrors(item?.invoiceConfig?.invoiceResponse?.response?.error),
							Moment(item?.createdAt).format("DD-MMM-YYYY HH:mm") || "-",
							getEnddate(item.ticket),
						]);
					}
					if (
						item.invoiceConfig !== null &&
						item.invoiceConfig.creditNote !== null &&
						item.invoiceConfig.creditNote !== "PENDING"
					) {
						data.push([
							item?.transId || "-",
							item?.client?.code || "-",
							item?.client?.name || "-",
							item?.category || "-",
							item?.provider || "-",
							item?.paymentType || "-",
							item?.amount || "-",
							item?.status || "-",
							"Credit Note",
							item.invoiceConfig?.creditNote || "-",
							item?.invoiceConfig?.file || "-",
							getInvoiceErrors(item?.invoiceConfig?.invoiceResponse?.response?.error),
							Moment(item?.createdAt).format("DD-MMM-YYYY HH:mm") || "-",
							getEnddate(item.ticket),
						]);
					}
					if (
						item.invoiceConfig !== null &&
						item.invoiceConfig.cancellationCharges !== null &&
						item.invoiceConfig.cancellationCharges !== "PENDING"
					) {
						data.push([
							item?.transId || "-",
							item?.client?.code || "-",
							item?.client?.name || "-",
							item?.category || "-",
							item?.provider || "-",
							item?.paymentType || "-",
							item?.amount || "-",
							item?.status || "-",
							"Cancellation Charges",
							item.invoiceConfig?.cancellationCharges || "-",
							item?.invoiceConfig?.file || "-",
							getInvoiceErrors(item?.invoiceConfig?.invoiceResponse?.response?.error),
							Moment(item?.createdAt).format("DD-MMM-YYYY HH:mm") || "-",
							getEnddate(item.ticket),
						]);
					}
				} catch (error) {
					console.log("error invoice queue", e);
				}
			})
			DownloadCsv(`invoice_queue_${Moment().format("DD_MM_YYYY")}.csv`,data);
			setLoading(false);
		})
	}

	return (
		<div style={{width:'100%'}}>
			<Navigation backUrl={'back'} title="Invoice Queue"  description="Flight refund queue file appears below."/>
			<NavDivider/>
			<Button onClick={downloadreport} className='btn btn-black'>Download Data</Button>
			<div>
				<ScrollableDynamicTable
					autoTrigger={false}
					tableStyle={{
						borderSpacing: 0
					}}
					View={VIEW}
					width={150}
					search={Dates}
					setPage={page => {
						setDates({
							...Dates,
							page,
							total:Dates.totalPages
						})
					}}
					data={{
						current: Data.currentPage,
						total: Data.totalPages,
						items,
						cards: Data.items.map(item => ({
							content: item,
							render: (
								<div 
								onClick={()=>{window.open(`/app/apps/meta/transaction/${item.transId}`,"_blank")}}
								className="retro-card">
									<p className="fw-bold">
										INR {item.amount}
									</p>
									<h3 className="fw-bold">
										{item.transId}
									</h3>
									<p>
										{item.client.name}
									</p>
									<p>
										{item.provider}
									</p>
									<Seperator/>
									<p>
										{Moment(item.createdAt).format("DD MMM YY HH:mm")}
									</p>
									<p>
										{item.status}
									</p>
								</div>
							)
						}))
					}} headers={[
					"Trans Id","Client Code","Client Name","Provider","Payment Type",
					"Status","Amount"
				].map((item)=>({
					weight: 2,
					title: item,
				}))}>
					<div style={{
						height:50,
					}}>
						<div style={{
							position:'absolute',
							top:0,
							right:0
						}}>
							<TableViewSwitch selected={VIEW} setSelected={SETVIEW}/>
						</div>
					</div>
				</ScrollableDynamicTable>
			</div>
		</div>
	)
}
