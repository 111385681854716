import React, {useRef, useState} from 'react'
import {Button, ChipsSelect, Input, Modal as SidePane} from "../../../../../retro";
import {useSelector} from "react-redux";

export default function Modal({onClose,onSubmit}) {
    const Title = useRef();
    const Partner = useRef();
    const VA = useRef();
    const IFSC = useRef();
    const Tsp = useRef();
    const {code} = useSelector(data=>({code:data?.auth?.config?.origin?.code||""}));
    const Limit = useRef();
    const [type,setType] = useState("DEPOSIT")
    if(code==="YESUAT" || code ==='YES-PROD'){
        return (
            <SidePane title="Virtual Account" description="fill below details to create virtual account" onClose={()=>{
                onClose(false)
            }}>
                <Input label="Title" placeholder="Enter title" morph={true} type="text" ref={Title}/>
                <Input label="Account Number" placeholder="Enter account number" morph={true} type="text" ref={VA}/>
                <Input label="IFSC Code" placeholder="Enter ifsc code" morph={true} type="text" ref={IFSC}/>
                <Input label="Partner Key" placeholder="Enter partner key for VA apis" morph={true} type="text" ref={Partner}/>
                <Input label="Partner Code" placeholder="Enter partner code for TSP Apis" morph={true} type="text" ref={Tsp}/>
                <Button className="btn-primary mt4 btn-max" onClick={()=>{
                    let title = Title.current.value;
                    let partner = Partner.current.value;
                    let ifsc = IFSC.current.value;
                    let account = VA.current.value;
                    let tsp = Tsp.current.value;
                    let data = {
                        title,tsp,partner,ifsc,account
                    }
                    onSubmit(data)
                }}>
                    + Add Account
                </Button>
            </SidePane>
        )
    }
	return (
		<SidePane title="Virtual Account" description="fill below details to create virtual account" onClose={()=>{
            onClose(false)
        }}>
            <Input label="Title" placeholder="Enter title" morph={true} type="text" ref={Title}/>
            <ChipsSelect type="single" selected={type} onSelect={setType} options={['DEPOSIT',"CREDIT"]}/>
            {
                type==="CREDIT"&&
                <Input label="Limit" placeholder="Enter credit limit" morph={true} type="number" ref={Limit}/>
            }
            <Button className="btn-primary mt4 btn-max" onClick={()=>{
                let title = Title.current.value;
                let limit = 0;
                if(type==="CREDIT"){
                    limit = parseInt(Limit.current.value,10);
                }
                let data = {
                    title,limit,type
                }
                onSubmit(data)
            }}>
                + Add Account
            </Button>
        </SidePane>
	)
}
