import React, {useEffect, useRef, useState} from 'react'
import {Button, Form, Grid, Input, TOAST} from "../../../retro";
import {post} from "../../Network/Axios";
import {useLoginHook} from "../../Hooks/useLoginHook";
import useLogoHooks from "../../Hooks/useLogoHooks";
import Captcha from "./Captcha";
import { hasWhiteSpace } from 'App/Utils/WhiteSpaceCheck';

export default function Render() {
	const Logo_Icon = useLogoHooks();
	const [timer,setTimer] = useState(0)
	const [captcha,setCaptcha] = useState(false)
	const  EMAIL = useRef(),  OTP = useRef(), PASSWORD = useRef();
	const CONFIRM = useRef();
	const CAPTCHA = useRef();
	const LOGIN = useLoginHook();
	const [password,setPassword] = useState(false)
	const [otpSent,setOtpSent] = useState(undefined)
	const [showPassword,setShowPassword]= useState(false)
	const [loading, setLoading] = useState(false)
	const submitForm = () => {
		let email = EMAIL.current.value;
		let password = PASSWORD.current.value;
		if(!captcha){
			TOAST.error("Kindly fill a valid captcha.");
			return;
		}
		if(!email){
			TOAST.error("Please enter a valid email address");
			return;
		}
		if(!password){
			TOAST.error("Please enter a valid password");
			return;
		}
		post(`/auth/token`,{username:email,password},(e,r)=>{
			if(e){
				setCaptcha(false)
				TOAST.error(e.data.message)
				if (CAPTCHA.current) {
					CAPTCHA.current()
				}
			}else{
				LOGIN.onLogin(r)
			}
		})
	}
	useEffect(()=>{
		let timeout = undefined;
		if(timer && timer>0){
			console.log(timer)
			timeout = setTimeout(()=>{
				setTimer(timer-1)
			},1000)
		}
		return ()=>{
			if(timeout)
				window.clearTimeout(timeout)
		}
	},[timer])

	return (
		<div  style={{overflow:'auto',height:'100vh'}}>
			<div style={{width:500,margin:"4rem auto",border:'1px solid #dedede'}}>
				{
					!password ?
						<Grid style={{}} className="pd6 center-vertically flex vertically" grid={{

						}}>
							<img className='mb3' src={Logo_Icon} alt='Logo' style={{width:140,objectFit:'contain'}}/>
							<h1>Welcome to <b>Back Office</b></h1>
							<p className='mb4'>Enter your credentials to continue</p>
							<Form>
								<Input ref={EMAIL} type='email' validation={(val)=>val.includes('@')}  margin='mt2' label='Enter Email' placeholder='Enter your email'/>


								<div className='relative' style={{ display:"flex",flexDirection:"row"}}>

								<Input ref={PASSWORD} type={showPassword? 'text':'password'} style={{flex:1}} label='Password' placeholder='Enter your password'/>
								<i className={`${showPassword ? 'fa fa-eye-slash pointer' : 'fa fa-eye pointer'} absolute`} style={{ right:'15px',top:"50px" }} onClick={() => setShowPassword(!showPassword)} />


								</div>

								<Captcha listener={CAPTCHA} onChange={setCaptcha}/>

								<div className="mt2">
									<span className="fw-bold pointer" onClick={()=>{
										setPassword(true)
										EMAIL.current.value = ''
									}}>
									Forgot Password?
								</span>
								</div>
								<Button onClick={submitForm} style={{
									background:"#000",
									color:'white'
								}} className='w-100 btn-black' margin="mt4 pd2">Login</Button>
							</Form>
						</Grid>:
						otpSent!==undefined?
							<Grid style={{}} className="pd6 center-vertically flex vertically" grid={{

							}}>
								<img className='mb3' src={Logo_Icon} alt='Logo' style={{width:140,objectFit:'contain'}}/>
								<h1>Welcome to <b>Back Office</b></h1>
								<p className='mb4'>Enter your email address to reset password.</p>
								<Form>
									<p>
										We have sent an otp to {otpSent}. kindly enter otp in below fields
									</p>
									<Input ref={OTP} defaultValue=" " type='text' margin='mt2' label='Enter Otp' placeholder='Enter your otp'/>
									<Input ref={PASSWORD} type='password' margin='mt2' label='Enter Password' placeholder='Enter your new password'/>

									<Input ref={CONFIRM} type='password' margin='mt2' label='Enter Confirm Password' placeholder='Enter your confirm password'/>

									<p className="text-small primary-color mt2">
										Password Policy: Password length should be minimum 8 characters with One upper case and one lower case and 1 special character and at least one number(0-9).
									</p>

									{
										timer>0 ?<p className="mt2 fw-bold pointer">
											You can resend otp in {timer} seconds.
										</p>
											:<p className="mt2 fw-bold pointer" onClick={()=>{
											setTimer(30)
											post(`/auth/otp`,{username:otpSent},(e,r)=>{
												if(r){
													TOAST.success(r.message || "OTP sent successfully")
												}else{
													TOAST.handleError(e)
												}
											})
										}}>
											Resend Otp?
										</p>}
									<Button onClick={()=>{
										let otp = OTP.current.value;
										let password = PASSWORD.current.value;
										let confirm = CONFIRM.current.value;

										if(!otp) {
											TOAST.error("Enter a valid otp!!")
                                            return
										}
										if(password.length<8){
											TOAST.error("Enter a valid password!!")
											return
										}
										if(confirm!==password){
											TOAST.error("Both password don't match")
											return
										}
										if(password.indexOf(' ') >= 0) {
											TOAST.error('Password cannot contain spaces')
											return
										}

										if(hasWhiteSpace(password)) {
											TOAST.error('Password cannot contain spaces')
											return
										}
										setLoading(true)
										post(`/auth/password`,{username:otpSent,otp,password},(e,r)=>{
											setLoading(false)
											if(r){
												TOAST.success("Password reset successfully!!")
												setOtpSent(undefined)
												setPassword(false)
											}else{
												TOAST.handleError(e)
											}
										})
									}} style={{
										background:"#000",
										color:'white'
									}} className='w-100 btn-black' loading={loading} margin="mt4 pd2">Verify & Change Password</Button>
								</Form>
							</Grid>:
							<Grid style={{}} className="pd6 center-vertically flex vertically" grid={{

							}}>
								<img className='mb3' src={Logo_Icon} alt='Logo' style={{width:140,objectFit:'contain'}}/>
								<h1>Welcome to <b>Back Office</b></h1>
								<p className='mb4'>Enter your email address to reset password.</p>
								<Form>
									<Input ref={EMAIL} type='email' defaultValue=' ' validation={(val)=>val.includes('@')}  margin='mt2 mb2' label='Enter Email' placeholder='Enter your email'/>
									<div className="pt2">
										<Captcha onChange={setCaptcha}/>
									</div>
									<div className="mt2">
										Already know password.
										<span className="fw-bold pointer" onClick={()=>{
											setPassword(false)
										}}>
												&nbsp;Login?
											</span>
									</div>
									<Button onClick={()=>{
										if(!captcha){
											TOAST.error("Kindly fill the valid value in captcha")
											return
										}
										let email = EMAIL.current.value;
										if(!email){
											TOAST.error("Enter a valid email address")
											return
										}
										setLoading(true)
										post(`/auth/otp`,{username:email},(e,r)=>{
											setLoading(false)
											if(r){
												setOtpSent(email)
												setTimer(30)
											}else{
												TOAST.handleError(e)
											}
										})
									}} style={{
										background:"#000",
										color:'white'
									}} className='w-100 btn-black pd2' margin="mt4" loading={loading}>Send Otp</Button>
								</Form>
							</Grid>
				}
			</div>
		</div>
	)
}
