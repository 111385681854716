import React, { useEffect, useRef, useState } from "react";
import { get, post } from "../../../../Network/Axios";
import { useHistory, useParams } from "react-router-dom";
import {
  Button,
  Headings,
  Input,
  Loader,
  Seperator,
  SidePane,
  Switch,
  Texts,
  TOAST,
} from "../../../../../retro";
import Bank from "./bank.png";
import useFunctionallityHook from "../../../../Hooks/useFunctionallityHook";
export default function BulkPe() {
  const { id } = useParams();
  const NAME = useRef();
  const UDF1 = useRef();
  const UDF2 = useRef();
  const UDF3 = useRef();
  const [Modal, SetModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const MULTI = useFunctionallityHook().multiDeposit;
  const [details, setDetails] = useState();
  const load = () => {
    setLoading(true);

    get(`/bulkPe/get-va/${id}`, (e, r) => {
      if (r) {
        setLoading(false);
        setDetails(r);
      }
    });
  };
  useEffect(() => {
    load();
  }, []);



  function updateBulkPe() {
    try {

      const gstinRegex = /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/;

     const panPattern = /^[A-Z]{3}P[A-Z0-9]{1}[0-9]{4}[A-Z]{1}$/;


if(UDF1.current.value){
  if (!panPattern.test(UDF1.current.value)) {
    TOAST.error('Invalid PAN card number');
    return;
  }  
}
			
      if(UDF2.current.value){
        if (!gstinRegex.test(UDF2.current.value)) {
          TOAST.error('Invalid GST number');
          return;
        } 
      }

      if(!UDF1.current.value && !UDF2.current.value){
        TOAST.error('Please Enter GST or PAN Number');

        return
      }
     
      
      
      let data = {
        name: NAME.current.value,
        settleTo: "PARENT",
        vaId: details?.data?.vaId, // base64
        udf1: UDF1.current.value,
        udf2: UDF2.current.value,
        udf3: UDF3.current.value,
      
      };
        post(
          `/bulkPe/update-va/${id}`,
          data,
          (e, r) => {
            if (r) {


              if(!r.status){
                TOAST.error(JSON.stringify(r?.message));
                return


              }else{

                TOAST.success(r?.message);

              }
              SetModal(false)
              load();
            }
          }
        );

        
    } catch (error) {
      console.log(error);
    }
  }


  if (loading) {
    return <Loader />;
  }





  return (
    <>


    {details?.status?(
           <div className="card-container mt4">
           {
             <div
               onClick={() => {
                 // HISTORY.push(`/app/accounts/${id}/${item.id}`)
               }}
               key={details?.data?.title}
               className="retro-card"
             >
               <div className="flex flex-1 justify-end"  >
   
               <Switch
                 state={details?.data?.active}
                 onChange={(e) => {
                   post(
                     `/bulkPe/toggle-va/${id}`,
                     {
                       vaId: details?.data?.vaId,
                       isActive: e,
                     },
                     (e, r) => {
                       if (r) {
                         load()
                         TOAST.success(r?.message);
                       }
                     }
                   );
                 }}
                 size="large"
               />
               </div>
   
               <Headings.Regular>{details?.data?.name}</Headings.Regular>
               <div className="mb3 mt3">
                 <Texts.Regular>{details?.data?.account}</Texts.Regular>
                 <Texts.Light>{details?.data?.ifsc}</Texts.Light>
                 <Texts.Light>{details?.data?.vaId}</Texts.Light>

               </div>
               <Headings.Small>INR {details?.data?.balance}</Headings.Small>
<div className="flex flex-1 justify-end" >

               <i  onClick={()=>{
                SetModal(true)
               }} className="fa fa-edit"/>
</div>
             </div>

           }
         </div>
    ):(
        <>
	<div style={{
					height:600
				}} className="center w-100 flex vertically">
					<img alt="bank" src={Bank} style={{
						height:250,
						width :250
					}}/>
					<Headings.Large>
						No Details Found 
					</Headings.Large>
					{/* <Texts.Regular>
						no virtual account found in repository
					</Texts.Regular> */}
				</div>

        </>
    )}
      
   



      {/* {
				!accounts.length &&
				<div style={{
					height:600
				}} className="center w-100 flex vertically">
					<img alt="bank" src={Bank} style={{
						height:250,
						width :250
					}}/>
					<Headings.Large>
						No virtual accounts
					</Headings.Large>
					<Texts.Regular>
						no virtual account found in repository
					</Texts.Regular>
				</div>
			} */}
      {
				Modal &&   <SidePane
                onClose={() => {
                  SetModal(undefined);
                }}
                description="Enter details below to update BulkPe"
                title="update BulkPe"
                width={700}
              >
                <Input
                defaultValue={details?.data?.name}
                disabled={true}
                ref={NAME} placeholder="Enter Name" label="Name *" />
                <Input ref={UDF1}
                defaultValue={details?.data?.udf1}
                placeholder="Enter PAN Number" label="PAN Number" />
                <Input ref={UDF2}
                                defaultValue={details?.data?.udf2}

                placeholder="Enter GST Number" label="GST Number" />
                <Input ref={UDF3}
                                defaultValue={details?.data?.udf3}

                placeholder="Enter Others" label="Others " />
                <Button onClick={updateBulkPe} className="btn-primary mt5">
                  update Bulkpe
                </Button>
              </SidePane>
			}
    </>
  );
}
