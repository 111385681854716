import React, {useEffect, useState} from 'react'
import {DateRange, Empty, Loader,Texts,Attachment} from "../../../../../../retro";
import Moment from "moment/moment";
import {get} from "../../../../../Network/Axios";
import {downloadAll} from "./utils";
import {saveAs} from 'file-saver'

export default function index() {
	const [Dates, setDates] = useState();
	const [downloading,setDownloading] = useState(false)
	const [loading,setLoading] = useState(true)
	let [txns,setTxns] = useState([])
	useEffect(()=>{
		if(Dates){
			setLoading(true)
			get(`/meta/flight/all`,(e,r)=>{
				setLoading(false)
				if(r){
					setTxns(r.bookings.filter(item=>item.status !== "FAILED" && item.status !== "ONGOING" && item.invoiceConfig))
				} else {
					Alert.handleError(e)
				}
			},{
				start:Dates.startDate,
				end:Dates.endDate
			})
		}
	},[Dates])
	txns = txns.map(item=>{
		let documents = [];
		if(item.invoiceConfig.file){
			documents.push({
				title:"Voucher",
				file:item.invoiceConfig.file
			})
		}

		if(item.invoiceConfig.invoice && item.invoiceConfig.invoice!=="SKIPPED" && item.invoiceConfig.invoice!=="PENDING"){
			documents.push({
				title:"Invoice",
				file:item.invoiceConfig.invoice
			})
		}

		if(item.invoiceConfig.serviceInvoice && item.invoiceConfig.serviceInvoice!=="SKIPPED" && item.invoiceConfig.serviceInvoice!=="PENDING"){
			documents.push({
				title:"Service Invoice",
				file:item.invoiceConfig.serviceInvoice
			})
		}

		if(item.invoiceConfig.creditNote && item.invoiceConfig.creditNote!=="SKIPPED" && item.invoiceConfig.creditNote!=="PENDING"){
			documents.push({
				title:"Credit Note",
				file:item.invoiceConfig.creditNote
			})
		}

		if(item.invoiceConfig.cancellationInvoice && item.invoiceConfig.cancellationInvoice!=="SKIPPED" && item.invoiceConfig.cancellationInvoice!=="PENDING"){
			documents.push({
				title:"Cancellation Invoice",
				file:item.invoiceConfig.cancellationInvoice
			})
		}
		item.documents = documents;
		return item;
	})
	let FILTERED = []
	txns.filter(item=>{
		return item.documents.length>0;
	}).forEach(item=>{
		if(item.documents.length>0){
			item.documents.forEach((doc,index)=>{
				let data = {
					title : `${item.transId}-${doc.title.replaceAll(" ","-")}`,
					file:doc.file
				}
				data.title = data.title + "." + doc.file.split('.').pop()
				FILTERED.push(data)
			})
		}
	})
	return (
		<div>
			<div style={{
				margin:'0 -4rem',
				padding:'2rem 4rem',
				paddingTop:0,
				borderBottom:'1px solid #dedede'
			}}>
				<div className="flex horizontally center-vertically">
					<div className="flex-1">
						<DateRange  defaultValue={{
							startDate:Moment().add(-7,'d').valueOf(),
							endDate : Moment().valueOf()
						}}  onChange={dates=>{
							setDates({
								...dates,
								page:0
							})
						}} className='' label='Export from and to' type='range'/>
					</div>
				</div>
			</div>
			<div className="flex horizontally center-vertically mt4">
				<div className="flex-wrap horizontally flex  flex-1">
					{
						downloading?<Loader/>:<div onClick={()=>{
							setDownloading(true)
							downloadAll(`flight_dump`,FILTERED,()=>{
								setDownloading(false)
							})
						}} className="chip" style={{
							background:'black',
							color:'white',
							border:'black'
						}}>
							Download All(Zip)
						</div>
					}
				</div>
			</div>
			{
				loading?<Loader/>:txns.length===0?<div className="pd6">
					<Empty/>
				</div>:undefined
			}
			<div className="card-container">
				{
					txns.map(item=>{
						return(
							<div style={{minWidth:'calc(50% - 1rem)'}} className="border pd4 mt2 mr2" key={item.transId}>
								<div className="flex horizontally">
									<div className="flex-1">
										<h3 className="fw-bold">
											{item.transId}
										</h3>
										<p className="mb2">
											All documents of this txn appears below.
										</p>
									</div>
									<div>
										<p onClick={()=>{
											downloadAll(item.transId,item.documents.map(item=>{
												let name = item.title;
												name = name + "." + item.file.split('.').pop()
												return {title:name,file:item.file}
											}))
										}} className="text-small pointer text-secondary-color fw-600" style={{marginTop:2}}>
											Download
										</p>
									</div>
								</div>
								<div className="flex-wrap mt2 flex">
									{
										item.documents.map(item=>{
											return(
												<div className="mr3 mb2 pointer" style={{width:125}} key={item.title}>
													<div style={{margin:'.25rem -.25rem'}}>
														<Attachment url={item.file}/>
													</div>
													<Texts.Small className="mt2">
														{item.title}
													</Texts.Small>
													<p onClick={()=>{
														saveAs(item.file,item.title)
													}} className="text-small text-secondary-color fw-600" style={{marginTop:2}}>
														Download
													</p>
												</div>
											)
										})
									}
								</div>
							</div>
						)
					})
				}
			</div>
		</div>
	)
}
