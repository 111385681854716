import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Input,
  NavDivider,
  Navigation,
  SelectInput,
  TOAST,
} from 'retro/index';
import Moment from 'moment';
import { get, post } from 'App/Network/Axios';
import { PrefferedGST } from '../ImportHotel';
import {useHistory} from "react-router-dom";

export default function index() {
  const [formData, setFormData] = useState({
    prefferedGst: undefined,
    prefferedGstName: undefined,
    clientCode: undefined,
    clientId: undefined,
    city: undefined,
    email: undefined,
    allGsts: [],
  });
  const ID = useRef();
  const GSTIN = useRef();
  const EMAIL = useRef();
  const DATE = useRef();
  const PG = useRef();
  const ACCOUNT = useRef();
  const AMOUNT = useRef();
  const TYPE = useRef();
  const JOURNEY_TYPE=useRef();
  const HISTORY = useHistory();
  const GSTTAX = useRef();
  const CABIN_CLASS = useRef();
  const [loading, setLoading] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [accountType, setAccountType] = useState(undefined);
  // set dropdowns as per the cleint
  useEffect(() => {
    setAccounts([]);
    if (formData.clientCode) {
      // get the list of accounts for the client
      get(`/accounts/${formData.clientId}`, (e, r) => {
        if (r) {
          setAccounts(r.items);
          setAccountType(undefined);
        }
      });
    }
  }, [formData.clientCode]);

  const importBooking = () => {
    let email = EMAIL.current.value;
    let service=JOURNEY_TYPE?.current?.value;
    if (!email) {
      TOAST.error('Enter a valid email address');
      return;
    }
    let id = ID.current.value;
    if (!id) {
      TOAST.error('Enter a valid id address');
      return;
    }
    let date = DATE.current.value;
    if (!date) {
      TOAST.error('Enter a valid date');
      return;
    }
    let amount = AMOUNT.current.value;

    if (!amount) {
      TOAST.error('Enter a valid amount');
      return;
    }

    setLoading(true);
    let data = {
      email,
      date: Moment(date, 'YYYY-MM-DD').format('DD-MM-YYYYY'),
      id,
      account: ACCOUNT.current.value,
      pgId: PG.current?.value,
      amount: parseInt(amount, 10),
      type: TYPE.current.value,
      client: formData.clientCode,
      gstin: GSTIN.current.value,
      gst: GSTTAX.current.value,
      service,
      code: formData.clientCode,
      cabinClass:CABIN_CLASS.current.value,
    };
    post(`/meta/flight/import/cleartrip`, data, (e, r) => {
      if (e) {
        TOAST.handleError(e);
      } else {
        TOAST.success('Flight imported successfully!');
        ID.current.value = '';
        AMOUNT.current.value = '';
        DATE.current.value = '';
        EMAIL.current.value = '';
        if(r?.refId){
          HISTORY.push(`/app/apps/meta/transaction/${r.refId}`)
        }
        if(r?.transId){
          HISTORY.push(`/app/apps/meta/transaction/${r.transId}`)
        }
      }
      setLoading(false);
    });
  };
  return (
    <div>
      <Navigation backUrl={'back'} title="Import Cleartrip Flight Booking" />
      <NavDivider />
      {!formData.prefferedGst ? (
        <PrefferedGST data={formData} setData={setFormData} category="flight" />
      ) : (
        <div className="mt4 pd4 border rounded-md">
          <SelectInput
            placeholder="Select"
            hidePlaceholder={false}
            options={[
              ...accounts.map((item) => {
                return {
                  label: `${item.title} (INR ${item.balance})`,
                  value: item.accountId,
                };
              }),
              { label: 'RAZORPAY', value: 'RAZORPAY' },
            ]}
            onChange={setAccountType}
            ref={ACCOUNT}
            label="Select Deposit Account"
            className="flex-1"
          />
          <SelectInput
            placeholder="Select"
            hidePlaceholder={false}
            options={[
              {
                label: `${formData.prefferedGst} - ${formData.prefferedGstName} (Preffered GST)`,
                value: formData.prefferedGst,
              },
              ...formData.allGsts.map((gst) => {
                return {
                  label: `${gst.gstin} - ${gst.name}`,
                  value: gst.gstin,
                };
              }),
            ]}
            defaultValue={formData.prefferedGst}
            ref={GSTIN}
            label="Select the GSTIN"
            className="flex-1 mt2"
          />
          {accountType === 'RAZORPAY' && (
            <Input
              ref={PG}
              type="text"
              label="Enter Pg Id"
              placeholder="Enter Pg Id"
              className="mb2"
            />
          )}
          <Input
            ref={EMAIL}
            type="text"
            label="Traveller Email Address"
            placeholder="Traveller Address"
            className="flex-1 mt2"
            defaultValue={formData.email}
          />
          <Input
            ref={GSTTAX}
            type="text"
            label="GST Amount"
            placeholder="GST"
            className="flex-1 mt2"
          />
          <div className="flex horizontally center-vertically mt2">
            <Input
              ref={ID}
              type="text"
              label="Cleartrip Id"
              placeholder="Cleartrip Id"
              className="mr1 flex-1"
            />
            <Input
              ref={AMOUNT}
              type="number"
              label="Amount"
              placeholder="Entry Amount"
              className="flex-1 ml1"
            />
          </div>
          <div className="flex horizontally center-vertically mt2">
            <Input
              ref={DATE}
              type="date"
              label="Onward Travel Date"
              placeholder="Onward Travel Date"
              className="mr1 flex-1"
            />
            <SelectInput
              options={[
                { label: 'ROUND', value: 'ROUND' },
                { label: 'ONEWAY', value: 'ONEWAY' },
              ]}
              ref={TYPE}
              type="number"
              label="Type"
              hidePlaceholder={true}
              className="ml1 flex-1 mb1"
            />
          </div>

          <div className="flex horizontally center-vertically mt2" >
            <SelectInput
              options={[
                { label: 'Domestic', value: 'DOMESTIC' },
                { label: 'International', value: 'INTERNATIONAL' },
              ]}
              ref={JOURNEY_TYPE}
              label="Service"
              hidePlaceholder={true}
              className="flex-1 mb1 mr1"
            />
            <SelectInput
              options={[
                { label: 'Economy', value: 'ECONOMY' },
                { label: 'Premium', value: 'PREMIUM' },
                { label: 'Business', value: 'BUSINESS' },
                { label: 'First', value: 'FIRST' },
              ]}
              ref={CABIN_CLASS}
              label="Cabin Class"
              hidePlaceholder={true}
              className="flex-1 mb1 ml1"
            />
          </div>
          
          <Button
            onClick={() => setFormData({})}
            className="btn-black btn-primary mr4 mt2"
            margin="pd2"
          >
            <i className="fa-solid fa-arrow-left mr1" />
            Previous
          </Button>
          <Button
            onClick={importBooking}
            loading={loading}
            className="btn-black btn-primary mr4 mt2"
            margin="pd2"
          >
            Import Flight
          </Button>
        </div>
      )}
    </div>
  );
}
