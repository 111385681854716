import React, {useEffect, useRef, useState} from 'react'
import {Button, Input, Modal, Pages, TOAST, SelectInput} from "../../../../../retro";
import {get, post} from "../../../../Network/Axios";
import {useParams} from "react-router-dom";
import StatesList from '../../Apps/ContractedHotels/LinkHotel/StatesList';

export default function B2CBilling() {
	const [modal,setModal] = useState(false)
    const [data,setData] = useState();
	const [State,setState] = useState();
	const ADDRESS = useRef();
	const {id} = useParams();

	// function to get the billing address data
	const load = id => {
		get(`clients/billing/${id}/billingAddress`, (e, r) => {
			if (r) {
				setData({
					...r
				})
			}
		})
	}

	// useEffect to fetch data whenever there is a change in id and first time page loads

	useEffect(()=>{
		load(id)
	},[id])
	return (
		<div>
			<Pages.Title title="B2C Billing" description="State and address of supply"/>
			<div className="border mt2 mb2 rounded-sm pd2">
				{
					// if data exists show data else show No billing address found
					data?.state?<>
						<p className="fw-bold">
							{data.state}
						</p>
						<p className="text-small">
							{data.address}
						</p>
					</>:<p>
						No billing address found.
					</p>
				}
			</div>
			<Button onClick={()=>{
				setModal(true)
			}} className="btn btn-black">
				{data?.state?"Edit":"Add"}
			</Button>
			{
				// modal to add or update the billing address data which pops up when user clicks on Add or Edit button
				modal&&
				<Modal title="B2C Billing" description="Select billing state and address." onClose={()=>{
					setModal(false)
				}}>
					<SelectInput label="Billing State" className="mt2" onChange={(val)=>{setState(val)}} placeholder="Select State" options={StatesList}/>
					<Input ref={ADDRESS} type="text" label="Billing Address" placeholder="Enter Address"/>
					<Button debounce={true} className="btn-primary btn-max mt4" onClick={()=>{
						let address = ADDRESS.current.value;
						if(!State || State==="NONE" ){
							TOAST.error("Select State");
							return;
						}
                        if(!address){
                            TOAST.error("Enter Address");
							return;
                        }
						post(`clients/billing/${id}/billingAddress`,{state:State,address},(e,r)=>{
							if(r){
								load(id)
								setModal(false)
								TOAST.success("B2C Billing Address Updated Successfully!")
							}else{
								TOAST.handleError(e)
							}
						})
					}}>
						Add
					</Button>
				</Modal>
			}
		</div>
	)
}
