import React from 'react'
import {NavDivider, Pages, Tabs} from "../../../../../retro";
import {Route, Switch} from "react-router-dom";
import Queued from './Queued'
import Past from './Past'
import Refund from './Refund';

export default function index() {
	return(
		<div className='flex-1'>
			<Tabs style={{marginBottom:'calc(-2rem - 1px)'}} className="" tabs={[
				{
					title: "Queued",
					path: "/app/apps/meta/trainQueue",
				},
				{
					title: "History",
					href: "/app/apps/meta/trainQueue/past",
				},
				{
					title: "Refund Queue",
					href: "/app/apps/meta/trainQueue/refund",
				}
			]}/>
			<Pages.Page>
				<Switch>
					<Route path="/app/apps/meta/trainQueue/past" exact>
						<Past/>
					</Route>
					<Route path="/app/apps/meta/trainQueue/refund">
					<div
            			className='overScrollY pd6'
            			style={{ height: 'calc(100vh - 120px)' }}
          			>
						<Refund/>
					</div>
					</Route>
					<Route path="/app/apps/meta/trainQueue">
						<Queued/>
					</Route>
				</Switch>
			</Pages.Page>
		</div>
	)
}
