import React from 'react'
import {ExpandableSidebar} from "../../../../../retro";
import {Route, Switch, useHistory, useLocation} from "react-router-dom";
import AllUsers from './AllUsers'
import AllPayments from './AllPayments'
import AllBookings from './AllBookings'
import AllFlights from './AllFlights'
import ControlTower from './ControlTower'
import Categories from "./Categories";
import Dashboard from './Dashboard'
import Reward from './Reward'
import Queue from './Queue'
import Refunds from './Refunds'
import BookingDetails from './BookingDetails'
import AppIcon from '@atlaskit/icon/glyph/app-switcher'
import Billing from '@atlaskit/icon/glyph/billing'
import QueuesIcon from '@atlaskit/icon/glyph/queues'
import PeopleGroupIcon from '@atlaskit/icon/glyph/people-group'
import LockIcon from '@atlaskit/icon/glyph/lock'
import CalendarIcon from '@atlaskit/icon/glyph/calendar'
import AllHotels from './AllHotels';
import AllBus from './AllBus';

export default function index() {
	const History = useHistory();
	const location = useLocation();
	return (
		<div className="flex h-100h w-100">
			<ExpandableSidebar items={ [
				{
					title:"Actions",
					actions:[
						{
							title: "Dashboard",
							icon : (<LockIcon size="small"/>),
							active:location.pathname==='/app/apps/perks',
							onClick:()=>{
								History.push('/app/apps/perks')
							},
						},
						{
							title: "Perks Users",
							icon : (<PeopleGroupIcon size="small"/>),
							active:location.pathname==='/app/apps/perks/users',
							onClick:()=>{
								History.push('/app/apps/perks/users')
							},
						},
						{
							title: "Control Tower",
							icon : (<QueuesIcon size="small"/>),
							active:location.pathname==='/app/apps/perks/tower',
							onClick:()=>{
								History.push('/app/apps/perks/tower')
							},
						},
						{
							title: "Perks Bookings",
							href: "/app/apps/perks/bookings",
							icon : (<CalendarIcon size="small"/>),
							active:location.pathname==='/app/apps/perks/bookings',
							onClick:()=>{
								History.push('/app/apps/perks/bookings')
							},
						},
						{
							title: "Bookings Queue",
							href: "/app/apps/perks/bookings",
							icon : (<CalendarIcon size="small"/>),
							active:location.pathname==='/app/apps/perks/queue',
							onClick:()=>{
								History.push('/app/apps/perks/queue')
							},
						},
						{
							title: "Refund Queue",
							href: "/app/apps/perks/bookings",
							icon : (<CalendarIcon size="small"/>),
							active:location.pathname==='/app/apps/perks/refunds',
							onClick:()=>{
								History.push('/app/apps/perks/refunds')
							},
						},
						{
							title: "Payment Gateway",
							href: "/app/apps/perks/payments",
							icon : (<Billing size="small"/>),
							active:location.pathname==='/app/apps/perks/payments',
							onClick:()=>{
								History.push('/app/apps/perks/payments')
							},
						},
						{
							title: "All Categories",
							href: "/app/apps/perks/categories",
							icon : (<AppIcon size="small"/>),
							active:location.pathname==='/app/apps/perks/categories',
							onClick:()=>{
								History.push('/app/apps/perks/categories')
							},
						}
					]
				}
			]}/>
			<div className="flex-1 overScrollY">
				<Switch>
					<Route component={Dashboard} path="/app/apps/perks" exact={true}/>
					<Route component={ControlTower} path="/app/apps/perks/tower"/>
					<Route component={AllUsers} path="/app/apps/perks/users"/>
					<Route component={AllBookings} path="/app/apps/perks/bookings"/>
					<Route component={Categories} path="/app/apps/perks/categories"/>
					<Route component={AllFlights} path="/app/apps/perks/flights"/>
					<Route component={AllHotels} path="/app/apps/perks/hotels"/>
					<Route component={AllBus} path="/app/apps/perks/bus"/>
					<Route component={Refunds} path="/app/apps/perks/refunds"/>
					<Route component={Queue} path="/app/apps/perks/queue"/>
					<Route component={BookingDetails} path="/app/apps/perks/booking/:transId"/>
					<Route component={Reward} path="/app/apps/perks/reward"/>
					<Route component={AllPayments} path="/app/apps/perks/payments"/>
				</Switch>
			</div>
		</div>
	)
}
