import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Loader,
  ScrollableDynamicTable,
  Button,
  TOAST as Alert,
  FileInput,
  Attachment,
  TOAST,
  Navigation,
} from "retro";
import { get, post } from "App/Network/Axios";
import OpenIcon from "@atlaskit/icon/glyph/open";
import ViewRequest from "../ViewRequest";
import Moment from "moment";
import Modal from "retro/Modal/Modal";
import Input from "retro/Inputs/Input";
import { DownloadCsv } from "retro/Utils";
import RBAC from "App/HOCs/RBAC";
import ROLES from "App/Constants/Roles";
import Filter from 'retro/Filter';
import FilterDropDown from 'App/Components/Filter/FilterDropDown';

export default function queued() {
  let [loading, setLoading] = useState(true);
  const [id, setId] = useState(undefined);
  const [file, setFile] = useState(undefined);
  const INVOICE = useRef();
  const FILE = useRef();
  const [modal, setModal] = useState(undefined);
  const [search, setSearch] = useState({
    page: 0,
    sort: "id",
    order: "desc",
  });
  const [Data, SetData] = useState({
    total: 0,
    currentPage: 0,
    totalPages: 0,
    items: [],
  });
  const [gstin, setGstin] = useState('')
  const [filterModal,setFilterModal] = useState(false);
	const [filter, setFilter] = useState({
		client: [],
		checkin: []
	});
  const loadPage = useCallback(
    (search) => {
      const clientIds = filter.client.map(client => client.id);
      get(
        `/meta/hotel/queue/completed/INVOICE`,
        (e, r) => {
          if (r) {
            SetData({
              ...r,
              total: r.total || Data.total,
              totalPages: r.totalPages || Data.totalPages,
            });
            setLoading(false);
          } else {
            Alert.handleError(e);
          }
        },
        {
          offset: search.page,
          order: search.order,
          q: search.q,
          start: filter.checkin.length > 0 ?
              filter.checkin[0] === 'Upcoming 24 hours' ?
                Moment().valueOf().toString() : Moment().add(24, 'hours').valueOf().toString()
              : undefined,
          end: filter.checkin.length > 0 ?
            filter.checkin[0] === 'Upcoming 24 hours' ? Moment().add(24, 'hours').valueOf().toString() : Moment().add(48, 'hours').valueOf().toString()
            : undefined,
          clientIds: clientIds.length > 0 ? clientIds : undefined
        }
      );
    },
    [Data, SetData, filter.checkin, filter.client]
  );
  useEffect(() => {
    loadPage(search);
  }, [search, filter.checkin, filter.client]);
  if (loading) {
    return <Loader />;
  }
  return (
    <div 
    style={{
      width: 'calc(100vw - 475px)',
    }}
    >
      {modal && (
        <Modal
          title='Add Invoice'
          description='Add invoice for this booking'
          onClose={() => {
            setModal(undefined);
          }}
        >
          <Input
            label='Invoice No'
            allowSpecialCharacters={true}
            placeholder='Enter invoice number'
            ref={INVOICE}
          />
          <Input
            label='GSTIN'
            placeholder='Enter GSTIN'
			defaultValue={gstin}
            onChange={value => setGstin(value.toUpperCase())}
          />
          <div className='flex horizontally center-vertically mt2'>
            <div className='flex mb2 mt2'>
              <FileInput
                updateFiles={(url) => {
                  setFile(url);
                }}
                label=''
                ref={FILE}
              />
              <Attachment url={file} />
            </div>
          </div>
          <Button
				onClick={() => {
					if (!file) {
						TOAST.error("Kindly select a file");
						return;
					}
					let invoice = INVOICE.current.value;
					if (!invoice) {
						TOAST.error("Kindly enter a valid invoice no.");
						return;
					}

					const gstinRegex = /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/;

					/* Only check if GSTIN is entered */
					if (gstin.trim() !== '' && !gstinRegex.test(gstin)) {
						return TOAST.error('Invalid GSTIN');
					}

					let data = {
						invoiceNo: invoice,
						invoice: file,
						gstin: gstin.length > 0 ? gstin : undefined
					};

					post(`/meta/hotel/queue/${modal.queueId}/invoice`, data, (e, r) => {
						if (r) {
							TOAST.success("Invoice added successfully");
							loadPage(search);
							setModal(false);
						} else {
							TOAST.handleError(e);
						}
					});
				}}
				className='btn btn-black '
		>
			Add Invoice
		</Button>
	</Modal>
	)}
      <div className='mb2 relative'>
        <Navigation title='Invoice Collection' />
        <RBAC role={ROLES.REPORTS}>
          <Button
            onClick={() => {
              setLoading(true);
              get(`/meta/hotel/queue/completed/INVOICE/all`, (e, r) => {
                if (r) {
                  let data = [];
                  const headers = [
                    "Request Id",
                    "Transaction Id",
                    "City",
                    "Requested Hotel Name",
                    "Requested Hotel Addres",
                    "Boooked Hotel Name",
                    "Booked Hotel Addres",
                    "Confirmation Number",
                    "GSTIN",
                    "Client Code",
                    "Guest Name",
                    "Guest Email",
                    "Entity",
                    "Travel Date",
                    "Submit Invoice",
                    "Regenrated Remarks"
                  ];
                  data.push(headers);
                  r.items.forEach((item) => {
                    let line = [];
                    line.push([item.requestId]);
                    line.push(item.transId || "-");
                    line.push([item?.hotel?.city || item?.ticket?.city || "-"]);
                    line.push(item?.hotel?.name || "-");
                    line.push(item?.hotel?.address || "-");
                    line.push(item?.ticket?.name || "-");
                    line.push(item?.ticket?.address || "-");
                    line.push(item?.ticket?.pnr || "-");
                    line.push([item.gstDetails?.gstin || "-"]);
                    line.push([item.client.clientCode]);
                    line.push([item.guests[0].name]);
                    line.push([item.guests[0].email]);
                    line.push(item.entity || "-");
                    line.push(
                      `${item.stayDetails.checkIn} - ${item.stayDetails.checkOut}`
                    );
                    line.push(
                      Moment(item.createdAt).format("DD MMM YYYY hh:mm A"),
                    );
                    line.push(item.paymentDocument?.attrs?.cancellationRemarks || "-");
                    data.push(line);
                  });
                  DownloadCsv(
                    `invoice_collection${Moment().format("DD_MM_YYYY")}}.csv`,
                    data
                  );
                }
                setLoading(false);
              });
            }}
            loading={loading}
            className='btn btn-primary btn-sm absolute top-2 right-0'
            style={{ zIndex: 10 }}
          >
            <i className='fa fa-download mr1' />
            Download Data
          </Button>
        </RBAC>
      </div>
      <Filter
			margin="0 -4rem"
			onSearch={(q) => {
				setSearch({
					...search,
					page: 0,
					q,
				})
			}}
			onFilter={() => {
				setFilterModal(true)
			}}
		/>

		<FilterDropDown
			shown={filterModal}
			filters={filter}
			setFilters={setFilter}
			choices={{
				client: [],
				checkin: [
					{
						title: 'Upcoming 24 hours',
						type: 'radio'
					},
					{
						title: 'Upcoming 48 hours',
						type: 'radio'
					}
				]
			}}
			types={["Client", "CheckIn"]}
			onClose={() => {
				setFilterModal(false);
			}}
		/>
      <ScrollableDynamicTable
        setSearch={(search) => {
          setSearch({
            ...search,
            page: 0,
          });
        }}
        search={search}
        tableStyle={{
          borderSpacing: 0,
        }}
        setPage={(page) => {
          setSearch({
            ...search,
            page,
          });
        }}
        data={{
          current: Data.currentPage,
          total: Data.totalPages,
          items: Data.items.map((item) => {
            const click = () => {
              setId(item.queueId);
            };
            return {
              content: item,
              render: [
                {
                  children: (
                    <div>
                      <p className='text'>{item.requestId}</p>
                      <p className='text'>
                        {item?.hotel?.city || item?.ticket?.city || "-"}
                      </p>

                      <p className='text-small text-light'>
                        {item?.hotel?.name || item?.ticket?.name || "-"}
                      </p>
                      <p className='text-small text-light'>
                        {item?.hotel?.address || item?.ticket?.address || "-"}
                      </p>
                    </div>
                  ),
                  weight: 1.5,
                },
                {
                  weight: 1.5,
                  children: (
                    <div>
                      <p>{item.client.clientCode}</p>
                      <p className='text-small'>{item.guests[0].name}</p>
                      <p className='text-small'>{item.guests[0].email}</p>
                    </div>
                  ),
                },

                {
                  weight: 1.5,
                  children: (
                    <>
                      <Button
                        className='btn btn-sm btn-black'
                        margin=''
                        onClick={() => {
                          setModal(item);
                        }}
                      >
                        Add Invoice
                      </Button>
                    </>
                  ),
                },
                {
                  weight: 1,
                  title: Moment(item.createdAt).format("DD MMM YYYY HH:mm a"),
                },
                {
                  weight: 1,
                  buttons: [
                    {
                      title: "View",
                      className: "btn-secondary btn-sm",
                      before: <OpenIcon size='small' />,
                      onClick: click,
                    },
                  ],
                  actions: [
                    {
                      title: "Quick Actions",
                      actions: [
                        {
                          title: "View",
                          before: <OpenIcon size='small' />,
                          onClick: click,
                        },
                      ],
                    },
                  ],
                },
              ],
            };
          }),
        }}
        headers={[
          {
            weight: 1.5,
            title: "Details",
          },
          {
            weight: 1.5,
            title: "Client",
          },
          {
            weight: 1,
            title: "Requested Date",
          },
          {
            weight: 1,
            title: "Submit Invoice",
          },
          {
            weight: 1,
            title: "Actions",
            style: {
              justifyContent: "end",
            },
          },
        ]}
      />
      {id !== undefined && (
        <ViewRequest
          id={id}
          onClose={() => {
            setId(undefined);
            loadPage(search);
          }}
        />
      )}
    </div>
  );
}
