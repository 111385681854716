import Common,{Common2,placeOfSupply,sellerAddress} from "./Common";
import Moment from "moment";

const roundOff = num => {
	return (Math.round(num * 100) / 100).toFixed(2);
}


let traveller = (item) => {
	let string = undefined;
	try {
		item.ticket.travellers.forEach((traveller) => {
			if (!string) {
				string = `${traveller.name}`;
			} else {
				string = `${string} , ${traveller.name}`;
			}
		});
	}catch (e){
		return "-";
	}
	return string;
};


let airlines = (item) => {
	let string = undefined;
	try {
		item.ticket.segments.forEach((segment) => {
			if (!string) {
				string = `${segment.airline}`;
			} else {
				string = `${string} , ${segment.airline}`;
			}
		});
	}catch (e){
		return "-";
	}
	return string;
};
let flightCode = (item) => {
	let string = undefined;
	try{
		item.ticket.segments.forEach((segment) => {
			if (!string) {
				string = `${segment.flightCode} ${segment.flightNumber}`;
			} else {
				string = `${string} , ${segment.flightCode}  ${segment.flightNumber}`;
			}
		});
	}catch (e){
		return "-"
	}
	return string;
};
let segments = (item) => {
	let string = undefined;
	try {
		item.ticket.segments.forEach((segment) => {
			if (!string) {
				string = `${segment.departure.code}-${segment.arrival.code}`;
			} else {
				string = `${string} , ${segment.departure.code}-${segment.arrival.code}`;
			}
		});
	}catch (e){
		return "-"
	}
	return string;
};
let pnrs = (item) => {
	let set = {};
	let string = undefined;
	try {
		item.ticket.travellers.forEach((traveller) => {
			Object.values(traveller.pnrs).forEach((item) => {
				set[item] = item;
			});
		});
		Object.values(set).forEach((value) => {
			if (!string) {
				string = `${value}`;
			} else {
				string = `${string} , ${value}`;
			}
		});
	}catch (e){
		return "-"
	}
	return string;
};
let tickets = (item) => {
	let set = {};
	let string = undefined;
	try {
		item.ticket.travellers.forEach((traveller) => {
			Object.values(traveller.tickets).forEach((item) => {
				set[item] = item;
			});
		});
		Object.values(set).forEach((value) => {
			if (!string) {
				string = `${value}`;
			} else {
				string = `${string} , ${value}`;
			}
		});
	}catch (e){
		return "-";
	}
	return string;
};

export default function flightServiceCharges(item,dates){	
	let values = []
	values.push(...Common(item,dates))
	try {
		values.push(
			...[
				flightCode(item),
				airlines(item),
				traveller(item),
				'-',
				'-',
				'-',
				'-',
				'-',
				'-',
				'-',
				'-',
				'-',
				'-',
				'-',
				'-',
				'-',
				'-',
				segments(item),
				item.ticket.segments && item.ticket.segments.length ? item.ticket.segments[0].departure.city:"-",
				item.ticket.segments && item.ticket.segments.length ? item.ticket.segments[0].arrival.city:"-",
				pnrs(item),
				tickets(item),
				item.ticket.cabinClass || 'ECONOMY',
				item.ticket.fareType || '-',
				item.ticket.segments && item.ticket.segments.length ?Moment(item.ticket.segments[0].departureTime).format('DD MMM YYYY'):"-",
				item.ticket.segments && item.ticket.segments.length ?Moment(
					item.ticket.segments[item.ticket.segments.length - 1].arrivalTime
				).format('DD MMM YYYY'):"-",
				item.service.toUpperCase() === 'FLIGHT' ? 'One Way' : 'Round',
			]
		)
	}catch (e){
		console.log(e,item)
	}
	let settlement = item.item;
	let transId = item?.transId;
	values.push(...[
		'Yes',
		settlement.config.serviceIrn?'Generated':'Pending',
		'Management Fee',
		settlement.config.serviceInvoiceNo|| "-",
		settlement.config.serviceChargeDate|| "-",
		settlement.config.serviceIrn || "-",
		settlement.config.serviceInvoice|| "-",
		'-',
		roundOff(settlement.config.serviceFare?.base || 0),
		"-",
		roundOff(settlement.config.serviceFare?.slab || 0),
		roundOff(settlement.config.serviceFare?.iGst || 0),
		roundOff(settlement.config.serviceFare?.sGst || 0),
		roundOff(settlement.config.serviceFare?.cGst || 0),
		roundOff(settlement.config.serviceFare?.total || 0),
		"-",
		settlement.config.managementServiceGst?.gstin||settlement.config.sellerGst?.gstin||"-",
		settlement.config.managementServiceGst?.address?sellerAddress(settlement.config.managementServiceGst):settlement.config.sellerGst?.address?sellerAddress(settlement.config.sellerGst):"-",
		settlement.config.managementUserGst?.gstin||settlement.config.userGst?.gstin||"-",
		settlement.config.managementUserGst?.address||settlement.config.userGst?.address||"-",
		'Maharashtra',
		placeOfSupply(settlement.config.userGst?.gstin||"-"),
		'998551',
		`MFI - ${transId}`,
	]);
	values.push(...Common2(item,settlement.config.serviceInvoiceNo))
	return values;
}
