import React, { useEffect, useState } from 'react';
import {
  Button,
  DateRange,
  NavDivider,
  Navigation,
  TOAST as Alert,
} from 'retro';
import { get } from 'App/Network/Axios';
import { DownloadCsv } from 'retro/Utils';
import Moment from 'moment/moment';
import Graph from '../Flight/BosTravellerFile/graph-stats-circle.svg';
import RBAC from 'App/HOCs/RBAC';
import ROLES from 'App/Constants/Roles';
import { fetchData,fetchDataChunked } from 'App/Utils/Utils';
import MultiSelectDropdown from 'App/Components/MultiSelectDropdown';

export default function AllClients() {
  const [Dates, setDates] = useState({
    page: 0,
    startDate: Moment().add(-31, 'd').valueOf(),
    endDate: Moment().valueOf(),
  });
  const [loading, setLoading] = useState(false);
  const [selectedClients, setselectedClients] = useState([]);
  const [clients, setClients] = useState([]);
  // get the list of clients for dorpdown
  useEffect(() => {
    get(
      '/clients/dump',
      (e, r) => {
        if (r) {
          setClients([
            ...r.items.map((client) => {
              return { code: client.code, id: client.id };
            }),
          ]);
        }
      },
      { active: true }
    );
  }, []);
  const downloadReport = () => {
    setLoading(true);
    // setting the filter by ids based on client code
    let clientsFilter = [];
    if (selectedClients.length > 0) {
      clientsFilter = clients
        .filter((client) => {
          if (selectedClients.includes(client.code)) {
            return client.id;
          }
        })
        .map((client) => client.id);
    }
    fetchDataChunked(
      `/meta/da/ledger/download/chunked`,
      Dates.startDate,
      Dates.endDate,
      (items, e) => {
        if (e) {
          Alert.error('Error Downloading');
          setLoading(false);
          return;
        }
        let data = [];
        let headers = [
          'Id',
          'Client Customer Code',
          'Client Code',
          'Client Name',
          // 'Client Email', 
          'Account Id',
          // 'Account Number',
          // 'Ledger Id',
          // 'Type',
          'Finance Type',
          'Invoice Amount',
          'Opening Balance',
          'Closing Balance',
          'Remarks',
          'Date',
          'Time',
          'Trans Id',
          'Booking Id',
          "RI ID",
          'Category',
          // 'Reseller',
          // 'Invoice',
          // 'Service Charges',
          // 'Service Charges Invoice',
          // 'Cancellation Charges Invoice',
          // 'Credit Note Invoice',
          // 'Ancillary Invoice',
          'Booker Name',
          'Description',
          'Traveller Name',
          "TDS",
          'Paid_Amount',
          'Payment_Date',
          'UTR',
        ];
        data.push(headers);
        items.forEach((item) => {
          let line = [];
          line.push(item.id);
          line.push(item.clientAttrs?.CUST_CODE || '-');
          line.push(item.clientCode);
          line.push(item.clientName);
          // line.push(item.clientEmail);
          line.push(item.accountId || '-');
          // line.push(item.accountNumber || '-');
          // line.push(item.ledgerId);
          // line.push(item.type);
          line.push(item.financeType);
          line.push(item.financeType === 'CREDIT' ? `-${item.amount}`: item.amount);
          line.push(
            item.financeType === 'CREDIT'
              ? item.balance - item.amount
              : item.balance + item.amount
          );
          line.push(item.balance);
          line.push(item.remarks);
          line.push(Moment(item.createdAt).format('DD-MMM-YYYY'));
          line.push(Moment(item.createdAt).format('HH:mm a'));
          line.push(item.attrs.trans_id || '-');
          line.push(item.booking?.provider || '-');
          line.push(item.attrs?.reference_id || '-');
          line.push(item.booking?.category || '-');
          // line.push(item.booking?.config?.reseller?"Yes":"NO" || '-');
          // if (item.type === 'REFUND') {
          //   line.push('-');
          //   line.push('-');
          //   line.push('-');
          //   line.push(item.booking?.config?.cancellationCharges || '-');
          //   line.push(item.booking?.config?.creditNote || '-');
          //   line.push('-');
          // } else {
          //   line.push(item.booking?.config?.invoice || '-');
          //   line.push(item.booking?.config?.serviceCharges || '-');
          //   line.push(item.booking?.config?.serviceInvoice || '-');
          //   line.push('-');
          //   line.push('-');
          //   line.push(item.booking?.config?.ancillaryInvoice || '-');
          // }
          if (item.booking) {
            line.push(item.booking?.attrs?.bookedByEmail || '-');
            if (item.booking.ticket) {
              if (
                item.booking.category.includes('FLIGHT') &&
                item.booking.ticket.travellers &&
                item.booking.ticket.travellers.length > 0
              ) {
                try{
                  line.push(
                      `${item.booking.ticket.segments[0].airline}/${
                          item.booking.ticket.segments[0].departureDate
                      }/${item.booking.ticket.segments[0].arrrivalDate}/${
                          Object.keys(item.booking.ticket.travellers[0].pnrs)[0]
                      }/${Object.values(item.booking.ticket.travellers[0].pnrs)[0]}`
                  );
                  line.push(item.booking.ticket.travellers[0].name);
                }catch (e){
                  console.log(e)
                  line.push('-')
                  line.push('-')
                }
              } else if (
                item.booking.category.includes('HOTEL') &&
                item.booking.ticket.travellers &&
                item.booking.ticket.travellers.length > 0
              ) {
                try{
                  line.push(
                      `${item.booking.ticket.city}/${item.booking.ticket.name}/${
                          item.booking.ticket?.date?.in || '-'
                      }/${item.booking.ticket?.date?.out || '-'}`
                  );
                  line.push(
                      item.booking.ticket.travellers
                          ? item.booking.ticket.travellers[0].name
                          : '-'
                  );
                }catch (e){
                  line.push('-');
                  line.push('-');
                }
              } else {
                line.push('-');
                line.push('-');
              }
            } else {
              line.push('-');
              line.push('-');
            }
          } else {
            line.push('-');
            line.push('-');
            line.push('-');
          }
          line.push(item.tdsAmount || '-');
          line.push(item.paidAmount || '-');
          line.push(item.paymentDate || '-');
          line.push(item.utr || '-');
          data.push(line);
        });
        DownloadCsv(`ledger_${Moment(Dates.startDate).format('DD-MMM-YYYY')}_${Moment(Dates.endDate).format('DD-MMM-YYYY')}.csv`, data);
        setLoading(false);
      },
      {
        client: clientsFilter.toString(),
      },
      7
    );
  };
  return (
    <>
      <Navigation
        backUrl={'back'}
        title="DA Ledger"
        chips={[]}
        description="All ledgers are listed below."
      />
      <div className="flex horizontally center-vertically">
        <DateRange
          defaultValue={{
            startDate: Moment().add(-31, 'd').valueOf(),
            endDate: Moment().valueOf(),
          }}
          onChange={(dates) => {
            setDates({
              ...dates,
              page: 0,
            });
          }}
          className="flex-1 mt1"
          label=""
          type="range"
        />
        <MultiSelectDropdown
          className="flex-1 mt1 ml1"
          options={clients.map((client) => client.code)}
          selectedOptions={selectedClients}
          setSelectedOptions={setselectedClients}
          placeholder="Select Clients"
          showSelectedOptins={false}
        />
      </div>
      <NavDivider />

      <RBAC role={ROLES.REPORTS}>
        <div className="border pd6 mt4 flex vertically center">
          <img
            alt="fetch"
            src={Graph}
            style={{ width: '5rem', height: '5rem' }}
          />
          <h3 className="fw-bold mt4">Download Data</h3>
          <p>Click on button to download</p>
          <Button
            onClick={downloadReport}
            loading={loading}
            className="btn btn-black"
            margin="mt2 ml2"
          >
            Download Data
          </Button>
        </div>
      </RBAC>
    </>
  );
}
