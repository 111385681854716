import React, {useCallback, useEffect, useState} from 'react'
import {
	Button,
	Loader,
	ScrollableDynamicTable as DynamicTable,
	TOAST,
	TOAST as Alert
} from "../../../../../../../retro";
import {get, post} from "../../../../../../Network/Axios";
import {useParams} from "react-router-dom";
import Moment from 'moment'
import useFunctionallityHook from "../../../../../../Hooks/useFunctionallityHook";

export default function AllScanners() {
	let [loading, setLoading] = useState(true);
	const JUMP = useFunctionallityHook().jumpServer;
	const FINANCE_DOMAIN = useFunctionallityHook().financeDomain;
	const apiOrigin=useFunctionallityHook().apiOrigin
	const {id} = useParams()
	const [search, setSearch] = useState({
		page: 0,
		sort: 'id',
		order: 'desc',
		q:undefined
	})
	const [Data, SetData] = useState({
		total: 0,
		currentPage: 0,
		totalPages: 0,
		items: []
	})
	const loadPage = useCallback((search) => {
		get(`/logins/${id}`, (e, r) => {
			if (r) {
				SetData({
					...r
				})
				setLoading(false)
			} else {
				Alert.handleError(e)
			}
		}, {
			offset: search.page,
			sort: search.sort,
			order: search.order,
			q:search.q
		})
	}, [SetData])
	useEffect(() => {
		loadPage(search)
	}, [search])
	return (
		<div>
			{
				loading ?
					<Loader/> :
					<div className="relative">
						<DynamicTable
							setSearch={(search) => {
								setSearch({
									...search,
									page: 0
								})
							}} search={search}
							setPage={page => {
								setSearch({
									...search,
									page
								})
							}}
							data={{
								current: Data.currentPage,
								total: Data.totalPages,
								items: Data.items.map(item => {
									return {
										content: item,
										render: JUMP?[
											{
												title:`#${item.id}`,
												weight: .3,
											},{
												title:item.title,
												weight: 1,
											},
											{
												weight: 2,
												title: item.email
											},
											{
												weight: 1,
												title: `${Moment(item.createdAt).format("DD MMM YYYY HH:mm")}`
											},
											{
												weight: 1,
												children:(<div className="flex horizontally center-vertically">
													<Button onClick={()=>{
														post(`/logins/${id}/jump`,{email:item.email},(e,r)=>{
															if(r){
																window.open(
																	`${FINANCE_DOMAIN}authorize?grant_type=authorization_code&apiOrigin=${apiOrigin}/&code=${r.code}&twoFactor=no&origin=${FINANCE_DOMAIN}`
																	,"_blank"
																)
															}else{
																TOAST.handleError(e)
															}
														})
													}} margin="" className="btn btn-black btn-sm">
														Jump
													</Button>
													<Button onClick={()=>{
														post(`/logins/${id}/super`,{email:item.email},(e,r)=>{
															if(r){
																TOAST.success("Promoted to super")
															}else{
																TOAST.handleError(e)
															}
														})
													}} margin="ml2" className="btn btn-black btn-sm">
														Promote To Super
													</Button>
												</div>)
											}
										]:[
											{
												title:`#${item.id}`,
												weight: .3,
											},{
												title:item.title,
												weight: 1,
											},
											{
												weight: 2,
												title: item.email
											},
											{
												weight: 1,
												title: `${Moment(item.createdAt).format("DD MMM YYYY HH:mm")}`
											}
										]
									}
								})
							}} headers={
							JUMP?[
								{
									weight: .3,
									sort: 'id',
									title: "Id",
								},{
									weight: 1,
									sort: 'name',
									title: "Name",
								},
								{
									weight: 2,
									title: "Email",
									sort: 'email'
								},
								{
									weight: 1,
									title: "Created On",
								},
								{
									weight: 1,
									title: "Actions",
								}
							]:[
								{
									weight: .3,
									sort: 'id',
									title: "Id",
								},{
									weight: 1,
									sort: 'name',
									title: "Name",
								},
								{
									weight: 2,
									title: "Email",
									sort: 'email'
								},
								{
									weight: 1,
									title: "Created On",
								}
							]
						}/>
					</div>
			}
		</div>
	)
}
