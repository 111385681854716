import React, {useCallback, useEffect, useState} from 'react'
import {Loader, Navigation, ScrollableDynamicTable, TOAST as Alert, Wrapper} from "../../../../../retro";
import {get} from "../../../../Network/Axios";
import {useHistory} from "react-router-dom";
import Moment from 'moment'

export default function AllClients({}) {
	let [loading, setLoading] = useState(true);
	const History = useHistory()
	const [search, setSearch] = useState({
		page: 0,
		sort: 'id',
		order: 'desc',
		q: undefined
	})
	const [Data, SetData] = useState({
		total: 0,
		currentPage: 0,
		totalPages: 0,
		items: []
	})
	const loadPage = useCallback((search) => {
		get(`/personal/tower`, (e, r) => {
			if (r) {
				SetData({
					...r
				})
				setLoading(false)
			} else {
				Alert.handleError(e)
			}
		}, {
			offset: search.page,
			sort: search.sort,
			order: search.order,
			q: search.q
		})
	}, [SetData])
	useEffect(() => {
		loadPage(search)
	}, [search])
	useEffect(()=>{
		const interval = setInterval(()=>{
			loadPage(search)
		},5000)
		return () => {
			if(interval){
				window.clearInterval(interval)
			}
		}
	},[search])
	return (
		<Wrapper className="w-sidebar-minus-three" id="wrapper-nav">
			<Navigation title="Control Tower" chips={["Perks","Control Tower"]} description="Details of control tower appears below."/>
			{
				loading ?
					<Loader/> :
					<div className="relative">
						<ScrollableDynamicTable
							setSearch={(search) => {
								setSearch({
									...search,
									page: 0
								})
							}} search={search}
							width={150}
							setPage={page => {
								setSearch({
									...search,
									page
								})
							}}
							data={{
								current: Data.currentPage,
								total: Data.totalPages,
								items: Data.items.map(item => {
									const click = () => {
										onClick(item)
									}
									return {
										content: item,
										render: [
											{
												children: (
													<div>
														<p className="text">
															# {item.id}
														</p>
														<p className="text-small text-light">
															{Moment(item.updatedAt).format("DD MMM YYYY HH:mm a")}
														</p>
													</div>
												),
												weight: 2,
											},
											{
												children: (
													<div>
														<p className="text">
															{item.user.name}
														</p>
														<p className="text-small text-light">
															{item.user.email}
														</p>
													</div>
												),
												weight: 2,
											},
											{
												weight: 1,
												title: item.type
											},
											{
												weight: 2,
												title: item.remarks
											}
										]
									}
								})
							}} headers={[
							{
								weight: 2,
								title: "Details",
							},
							{
								weight: 2,
								title: "User",
							},
							{
								weight: 1,
								title: "Type",
								sort: 'type'
							},
							{
								weight: 2,
								title: "Remarks",
								sort: 'remarks'
							}
						]}/>
					</div>
			}
		</Wrapper>
	)
}
