import React from 'react'
import {Button, Headings, NavDivider, Navigation, Texts} from "../../../../../retro";
import {useHistory} from "react-router-dom";
import BookIcon from '@atlaskit/icon/glyph/book'
import { ReportRouteCard } from '../All/routes';

export default function index() {
	const HISTORY = useHistory();
	const routes = [
		{
			title: 'Payments Queue',
			desc: 'All payments queue appears here',
			path: '/app/apps/meta/pg/payments',
			btnLabel: 'View File',
		},
		{
			title: 'Paytm Payments Queue',
			desc: 'All Paytm payments queue appears here',
			path: '/app/apps/meta/pg/paytmPayments',
			btnLabel: 'View File',
		}
	]

	return (
		<div>
			<Navigation backUrl={''} title="Pg Mis" chips={['All Pg Mis']} description="All pg related mis are listed below."/>
			<NavDivider/>
			<div className="card-container mt4">
				{routes.map((report) => (
					<>
						<div className="retro-card">
							<div className="relative ">
								<BookIcon size="xlarge" />
								<Headings.Regular margin="mt4">{report.title}</Headings.Regular>
								<Texts.Small style={{ maxLines: 2 }} className="">
								{report.desc}
								</Texts.Small>
								<Button
								onClick={() => {
									HISTORY.push(`${report.path}`);
								}}
								className="mt3 btn-max btn-primary"
								>
								{report.btnLabel}
								</Button>
							</div>
						</div>	
					</>
				)
				)}
			</div>
		</div>
	)
}
