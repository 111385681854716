import React, {useCallback, useEffect, useState} from 'react'
import {Loader, Navigation, ScrollableDynamicTable, TOAST, TOAST as Alert, Wrapper, DateRange, NavDivider} from "../../../../../retro";
import {get} from "../../../../Network/Axios";
import OpenIcon from '@atlaskit/icon/glyph/open'
import Moment from 'moment'
import CopyIcon from "@atlaskit/icon/glyph/copy";
import { Button } from '@material-ui/core';
import RBAC from 'App/HOCs/RBAC';
import { DownloadCsv } from 'retro/Utils';

export default function AllClients({}) {
	let [loading, setLoading] = useState(true);
	const [search, setSearch] = useState({
		page: 0,
		sort: 'id',
		order: 'desc',
		q: undefined
	})
	const [Dates, setDates] = useState({
        page: 0,
    });
	const [Data, SetData] = useState({
		total: 0,
		currentPage: 0,
		totalPages: 0,
		items: []
	})
	const loadPage = useCallback((search) => {
		get(`/personal/payments`, (e, r) => {
			if (r) {
				SetData({
					...r
				})
				setLoading(false)
			} else {
				Alert.handleError(e)
			}
		}, {
			offset: search.page,
			sort: search.sort,
			order: search.order,
			q: search.q
		})
	}, [SetData])
	const downloadData = () => {
        setLoading(true);
        get(
          `/personal/payments/all`,
          (e, r) => {
            if (e) {
              setLoading(false);
              console.log(e);
              TOAST.error('Unable to download');
              return;
            }
            let data = [];
            let headers = [
                'Order Id',
                'Payment Id',
                'Status',
                'Created At',
                'User Name',
                'User Email',
                'User Id',
                'Amount'
            ];
            data.push(headers);
            r?.items?.map((item) => {
                data.push([
                    item?.bookingId || '-',
                    item?.paymentId || '-',
                    item?.status || '-',
                    Moment(item.createdAt).format("DD MMM YYYY HH:mm a")|| '-',
                    item?.user?.name || '-',
                    item?.user?.email || '-',
                    item?.user?.id || '-',
                    item?.amount || '-'
                ])
            });
            DownloadCsv(
              `Perks_PG_${Moment(Dates.startDate).format('DD_MM_YYYY')}_${Moment(
                Dates.endDate
              ).format('DD_MM_YYYY')}.csv`,
              data
            );
            setLoading(false);
          },
          {
            start: Dates.startDate,
            end: Dates.endDate,
          }
        );
      };
	useEffect(() => {
		loadPage(search)
	}, [search])
	useEffect(() => {
        if (Dates && Dates.startDate) {
            loadPage(Dates);
        }
    }, [Dates]);
	return (
		<Wrapper className="w-sidebar-minus-two" id="wrapper-nav">
			<Navigation title="All Payments" chips={["Perks","Payments"]} description="Details of all payments appears below."/>
			<NavDivider/>
			<div className='flex horizontally center-vertically'>
                <div className='flex-1'>
                <DateRange
                    defaultValue={{
                    startDate: Moment().add(-31, 'd').valueOf(),
                    endDate: Moment().valueOf(),
                    }}
                    onChange={(dates) => {
                    setDates({
                        ...dates,
                        page: 0,
                    });
                    }}
                    className='mt2'
                    label=''
                    type='range'
                />
                </div>
            </div>
			<div>
				{
					loading ?
						<Loader/> :
						<div className="relative">
							<RBAC>
								<Button onClick={downloadData} className='btn btn-black mt4'>
									Download Data
								</Button>
							</RBAC>
							<ScrollableDynamicTable
								tableStyle={{
									borderSpacing: 0
								}}
								width={150}
								setSearch={(search) => {
									setSearch({
										...search,
										page: 0
									})
								}} search={search}
								setPage={page => {
									setSearch({
										...search,
										page
									})
								}}
								data={{
									current: Data.currentPage,
									total: Data.totalPages,
									items: Data.items.map(item => {
										const click = () => {
											onClick(item)
										}
										return {
											content: item,
											render: [
												{
													class: "table-sticky-row-first",
													children: (
														<div>
															<p className="text-small text-light flex horizontally center-vertically">
																{item.bookingId}
																<span className="ml1" onClick={()=>{
																	navigator.clipboard.writeText(item.bookingId).then(function() {
																		TOAST.success("Copy Completed")
																	}, function(err) {
																		TOAST.error("Could not copy text")
																	});
																}}>
																<CopyIcon size="small"/>
															</span>
															</p>
															{item.paymentId?<p className="text-small text-light flex horizontally center-vertically">
																{item.paymentId}
																<span className="ml1" onClick={()=>{
																	navigator.clipboard.writeText(item.paymentId).then(function() {
																		TOAST.success("Copy Completed")
																	}, function(err) {
																		TOAST.error("Could not copy text")
																	});
																}}>
																<CopyIcon size="small"/>
															</span>
															</p>:undefined}
															<p className="text-small text-light">
																{Moment(item.updatedAt).format("DD MMM YYYY HH:mm a")}
															</p>
														</div>
													),
													weight: 2,
												},
												{
													children: (
														<div>
															<p className="text">
																{item.user.name}
															</p>
															<p className="text-small text-light">
																{item.user.email}
															</p>
														</div>
													),
													weight: 2,
												},
												{
													weight: 1,
													title: item.status
												},
												{
													weight: 1,
													title: item.amount
												},
												{
													weight: 1,
													actions: [
														{
															title: "Quick Actions",
															actions: [
																{
																	title: "View",
																	before: (<OpenIcon size="small"/>),
																	onClick: click
																}
															]
														}
													]
												}
											]
										}
									})
								}} headers={[
								{
									class: "table-sticky-row-first",
									weight: 2,
									title: "Details",
								},
								{
									weight: 2,
									title: "User",
								},
								{
									weight: 1,
									title: "Status",
									sort: 'status'
								},
								{
									weight: 1,
									title: "Amount",
									sort: 'amount'
								},
								{
									weight: 1,
									title: "Actions",
									style: {
										justifyContent: "end"
									}
								}
							]}/>
						</div>
				}
			</div>
		</Wrapper>
	)
}
