import React, {useState} from 'react'
import {Button, Headings, Icons, Texts} from "../../../../../retro";
import {useHistory, useParams} from "react-router-dom";
import useFunctionallityHook from "../../../../Hooks/useFunctionallityHook";
import useConfigHook from 'App/Hooks/useConfigHook';

export default function Users(){
	const History = useHistory();
	const CONFIG=useConfigHook()
	const {id} = useParams();
	const SCANNER = useFunctionallityHook().scanner
	const [AllCards] = useState([
		{
			name: "Employees",
			des: "All employee users linked to your account appears here",
			path: `/app/client/${id}/employees`,
		},
		{
			name: "Admin",
			des: "All admin users linked to your account appears here",
			path: `/app/client/${id}/admins`,
		},
        ... CONFIG?.origin?.code?[
			{
				name: "Office",
				des: "All Office  linked to your account appears here",
				path: `/app/client/${id}/offices`,
			}
		]:[]	
	])
	const item = (item) => {
		return (
			<div key={item.name} className="retro-card">
				<Headings.Medium>
					{item.name}
				</Headings.Medium>
				<Texts.Small style={{maxLines: 2}} className="mt4">
					{item.des}
				</Texts.Small>
				<Button onClick={()=>{
					History.push(item.path);
				}} className="mt3 btn-max btn-primary">
					<Icons.View size="small"/>
					View
				</Button>
			</div>
		)
	}
	return (
		<div className="card-container">
			{
				AllCards.map(item)
			}
		</div>
	)
}
