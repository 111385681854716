/**
 * Author[Lakshay Jain]
 * Version 1.0.1
 * 5th January 2024 | 1.0.1 | Shubham Rawat | added filters and documents download option
 */
import React, { useEffect, useRef, useState } from "react";
import { Button, DateInput,DateRange, Loader, Navigation, SelectInput, TOAST } from "retro";
import Moment from "moment";
import { get } from "App/Network/Axios";
import { DownloadCsv } from "retro/Utils";
import Graph from "./graph-stats-circle.svg";
import RBAC from "App/HOCs/RBAC";
import ROLES from "App/Constants/Roles";
import { placeOfSupply } from "../AgencyBosTravellerFile/Utils";
import MultiSelectDropdown from "App/Components/MultiSelectDropdown";
import { downloadAll } from "../Repository/utils";
import useConfigHook from "App/Hooks/useConfigHook";


export default function index() {
  const [loading, setLoading] = useState(false);
  const DATETYPE = useRef();
  const CONFIG=useConfigHook();

  const [dates,setDates] = useState({
    start: Moment().add(-31,'d').valueOf(),
    end: Moment().valueOf(),
  });
  const PERSONS = {
    "aditya.vyawhare@antrepriz.com":true,
    "aavishkar@antrepriz.com":true,
  }

  const STARTDATE = useRef();
  const ENDDATE = useRef();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedDoc, setSelectedDoc] = useState([]);
  const [clientList, setClientList] = useState([]);
  
  const docTypes = [
    "Invoice", 
    "Service Invoice", 
    "Ancillary Charges", 
    "Credit Note", 
    "Cancellation Charges"
  ]

  // setting the client list on load
  useEffect(()=>{
    let templist = [];
    get('/clients/dump',(e,r)=>{
      if(r){
        r.items.map((client)=>{
          templist.push(client.code)
        })
        setClientList([...templist]);
      }
      },{
      active:true
    })
  },[])

  let airlines = (item) => {
    let string = undefined;
    item.ticket.segments.forEach((segment) => {
      if (!string) {
        string = `${segment.airline}`;
      } else {
        string = `${string} , ${segment.airline}`;
      }
    });
    return string;
  };
  let flightCode = (item) => {
    let string = undefined;
    item.ticket.segments.forEach((segment) => {
      if (!string) {
        string = `${segment.flightCode} ${segment.flightNumber}`;
      } else {
        string = `${string} , ${segment.flightCode}  ${segment.flightNumber}`;
      }
    });
    return string;
  };
  let segments = (item) => {
    let string = undefined;
    item.ticket.segments.forEach((segment) => {
      if (!string) {
        string = `${segment.departure.code}-${segment.arrival.code}`;
      } else {
        string = `${string} , ${segment.departure.code}-${segment.arrival.code}`;
      }
    });
    return string;
  };

  let times = (item) => {
    let string = undefined;
    item.ticket.segments.forEach((segment) => {
      if (!string) {
        string = `${segment.departureDate}-${segment.arrrivalDate}`;
      } else {
        string = `${string} , ${segment.departureDate}-${segment.arrrivalDate}`;
      }
    });
    return string;
  };
  let traveller = (item) => {
    let string = undefined;
    item.ticket.travellers.forEach((traveller) => {
      if (!string) {
        string = `${traveller.name}`;
      } else {
        string = `${string} , ${traveller.name}`;
      }
    });
    return string;
  };
  // method to concat all the traveller emails for a booking
  let travellerEmails = (item)=>{
    let emailString = undefined;
    item.ticket.travellers.forEach((traveller) => {
      if (!emailString) {
        emailString = `${traveller.email}`;
      } else {
        emailString = `${emailString} , ${traveller.email}`;
      }
    });
    return emailString;
  }
  let pnrs = (item) => {
    let set = {};
    let string = undefined;
    item.ticket.travellers.forEach((traveller) => {
      Object.values(traveller.pnrs).forEach((item) => {
        set[item] = item;
      });
    });
    Object.values(set).forEach((value) => {
      if (!string) {
        string = `${value}`;
      } else {
        string = `${string} , ${value}`;
      }
    });
    return string;
  };
  let tickets = (item) => {
    let set = {};
    let string = undefined;
    item.ticket.travellers.forEach((traveller) => {
      Object.values(traveller.tickets).forEach((item) => {
        set[item] = item;
      });
    });
    Object.values(set).forEach((value) => {
      if (!string) {
        string = `${value}`;
      } else {
        string = `${string} , ${value}`;
      }
    });
    return string;
  };

  // Checks whether the booking is International based on the segments
  const isInternational = (booking) =>{
    // gets the segments array from the ticket
    const {segments=[]} = booking?.ticket;
    // if no segments retrun false
    if(segments.length === 0){
      return false;
    }
    let international = false;
    // iterate over flight segments and check if country is not India for arrival or departure
    segments.forEach((segment)=>{
      let arrivalCountry = segment.arrival?.country?.toUpperCase();
      let departureCountry = segment.departure?.country?.toUpperCase();
      if(arrivalCountry!=="INDIA" || departureCountry!=="INDIA"){
        international=true;
        return;
      }
    })
    return international;
  }

  // download docs function
  const downloadDocs = () => {
      if(selectedDoc.length === 0){
        TOAST.error('Select documnet types');
        return;
      }
      setLoading(true);
      get(
        `/meta/flight/reseller`,
        (e, r) => {
          let documents = [];
          if (e) {
            console.log("e", e);
            setLoading(false);
            TOAST.error("Unable to download");
            return;
          }
          r.bookings.map((item) => {
            try {
              // filter data based on selected clients
              if(selectedOptions.length>0 && !selectedOptions.includes(item.client?.code)){
                return; // return if client code does not match
              }
              // filter the documents on the basis of doctype
              if(
                item.invoiceConfig.invoice && 
                item.invoiceConfig.invoice!=="SKIPPED" && 
                item.invoiceConfig.invoice!=="PENDING" &&
                item.invoiceConfig.invoice!=="FAILED" &&
                selectedDoc.includes('Invoice')
              ){
                documents.push({
                  title: `${item.transId}_${item.client.code}_Invoice.pdf`,
                  file: item.invoiceConfig.invoice
                });
              }
              if(
                item.invoiceConfig.ancillaryInvoice && 
                item.invoiceConfig.ancillaryInvoice!=="SKIPPED" && 
                item.invoiceConfig.ancillaryInvoice!=="PENDING" &&
                item.invoiceConfig.ancillaryInvoice!=="FAILED" &&
                selectedDoc.includes('Ancillary Charges')
              ){
                documents.push({
                  title: `${item.transId}_${item.client.code}_Ancillary_Charges.pdf`,
                  file: item.invoiceConfig.ancillaryInvoice
                });
              }
              if(
                item.invoiceConfig.serviceInvoice && 
                item.invoiceConfig.serviceInvoice!=="SKIPPED" && 
                item.invoiceConfig.serviceInvoice!=="PENDING" &&
                item.invoiceConfig.serviceInvoice!=="FAILED" &&
                selectedDoc.includes('Service Invoice')
              ){
                documents.push({
                  title: `${item.transId}_${item.client.code}_Service_Invoice.pdf`,
                  file: item.invoiceConfig.serviceInvoice
                });
              }
              if(
                item.invoiceConfig.creditNote && 
                item.invoiceConfig.creditNote!=="SKIPPED" && 
                item.invoiceConfig.creditNote!=="PENDING" &&
                item.invoiceConfig.creditNote!=="FAILED" &&
                selectedDoc.includes('Credit Note')
              ){
                documents.push({
                  title: `${item.transId}_${item.client.code}_Credit_Note.pdf`,
                  file: item.invoiceConfig.creditNote
                });
              }
              if(
                item.invoiceConfig.cancellationInvoice && 
                item.invoiceConfig.cancellationInvoice!=="SKIPPED" && 
                item.invoiceConfig.cancellationInvoice!=="PENDING" &&
                item.invoiceConfig.cancellationInvoice!=="FAILED" &&
                selectedDoc.includes('Cancellation Charges')
              ){
                documents.push({
                  title: `${item.transId}_${item.client.code}_Cancellation_Charges.pdf`,
                  file: item.invoiceConfig.cancellationInvoice
                });
              }
              }
              catch(e){}
            })
            // download and zip if the docs are found
            if(documents.length>0){
              downloadAll(`document_collection`,documents,()=>{
                setLoading(false);
              })
            }else{
              TOAST.error("Found No Documents");
            }
        },{
          start: Moment(STARTDATE.current.value).valueOf(),
          end: Moment(ENDDATE.current.value).add(1,'d').valueOf(),
          filterOn: DATETYPE.current.value, 
        }
      )
    }

  // download report function
  const downloadData = () => {
    if(selectedDoc.length === 0){
      TOAST.error('Select documnet types');
      return;
    }
    setLoading(true);
    get(
      `/meta/flight/reseller`,
      (e, r) => {
        if (e) {
          console.log("e", e);
          setLoading(false);
          TOAST.error("Unable to download");
          return;
        }
        let data = [];
        // report headers
        let headers = [
          "Id",
          "Trans Id",
          "Reference Id",
          "Provider",
          "Provider Id",
          "Cart Type",
          "Booking Status",
          "Provider Id",
          "Journey Type",
          "Category",
          "Cabin Class",
          "Client Code",
          "Cust Code",
          "Gstin",
          "Pan",
          "Tan",
          "Client Name",
          "Booker Name",
          "Booker Email Address",
          "Created On Date",
          "Created On Time",
          "Updated On Date",
          "Updated On Time",
          "CT Gstin",
          "Ct State",
          "Buyer Entity",
          "Buyer Gstin",
          "State Of Supply",
          "Payment Type",
          "Amount Collected",
          "Line Item Amount",
          "Ledger Id",
          "Ledger Amount",
          "Ledger Balance",
          "Pg Order Id",
          "Pg Payment Id",
          "Pg Refund Id",
          "Hsn",
          "Document Type",
          "Document Number",
          "Document Irn",
          "Document",
          "Booking Voucher",
		  "Document Date", /* Invoice Date */
          "Base Fare",
          "Slab",
          "Other Charges",
          "SGST",
          "CGST",
          "IGST",
          "TOTAL",
          "Airlines",
          "Segments",
          "Dates",
          "Flight Code/Number",
          "Travellers",
          "Traveller Emails",
          "PNRs",
          "Tickets",
          "Statement",
		  "Remarks", /* Generation Message */
        ];

        if(PERSONS[CONFIG.email]){
          headers.push("Platform Markup")
          }
        data.push(headers);
        // let resellerData = [];
        // resellerData.push(r.bookings);

		r.bookings.map((item) => {
			const { invoiceRequest, serviceRequest } = item.invoiceConfig;

			/* Parse Invoice date if present */
			const parsedInvoiceRequest = invoiceRequest ? JSON.parse(invoiceRequest) : undefined;
			const invoiceDate = parsedInvoiceRequest ? Moment(parsedInvoiceRequest.data.docDtls.dt, 'DD/MM/YYYY').format('DD MMM YYYY') : '-'

			/* Parse Service date if present */
			const parsedServiceRequest = serviceRequest ? JSON.parse(serviceRequest) : undefined;
			const serviceDate = parsedServiceRequest ? Moment(parsedServiceRequest.data.docDtls.dt, 'DD/MM/YYYY').format('DD MMM YYYY') : '-';

		  try {
        // filter data based on selected clients
        if(selectedOptions.length>0 && !selectedOptions.includes(item.client?.code)){
          return; // return if client code does not match
        }
            if (item.status !== "FAILED" && item.status !== "ONGOING") {
              if (item.invoiceConfig?.reseller && selectedDoc.includes('Invoice'))
                data.push([
                  item.id,
                  item.transId,
                  item.referenceId||"-",
                  item.provider,
                  item.providerId || item.ticket?.flowId ||  "-",
                  "Booking",
                  item.status,
                  item.ticket?.flowId || item.providerId || "-",
                  item.category === "DOMESTICROUNDFLIGHT"
                    ? "Round Trip"
                    : "One Way",
                  // Displays "International" in provider column in CSV if flight is `international`
                  isInternational(item) ? 'International' : 'Domestic',
                  "Economy",
                  item.client.code,
                  item.client.attrs?.CUST_CODE || "-",
                  item.client.attrs?.gstin || "-",
                  item.client.attrs?.pan || "-",
                  item.client.attrs?.tan || "-",
                  item.client.name,
                  item.attrs["bookedBy"] || "-",
                  item.attrs["bookedByEmail"] || "-",
                  Moment(item.createdAt).format("DD MMM YYYY"),
                  Moment(item.createdAt).format("HH:mm a"),
                  Moment(item.updatedAt).format("DD MMM YYYY"),
                  Moment(item.updatedAt).format("HH:mm a"),
                  "27AAHCC1775A1ZX",
                  "Maharashtra",
                  item.invoiceConfig?.userGst?.name || "-",
                  item.invoiceConfig?.userGst?.gstin || "-",
                  placeOfSupply(item.invoiceConfig?.userGst?.gstin || "-"),
                  item.paymentType,
                  item.amount,
                  item.invoiceConfig?.irnFare?.total,
                  item.ledger?.ledgerId || "-",
                  item.ledger?.amount || "-",
                  item.ledger?.balance || "-",
                  item.attrs["orderId"] || "-",
                  item.attrs["paymentId"] || "-",
                  item.attrs["refundId"] || "-",
                  "996425",
                  "Invoice",
                  item.invoiceConfig?.invoiceNo || "-",
                  item.invoiceConfig?.invoiceIrn,
                  item.invoiceConfig?.invoice || "-",
                  item?.invoiceConfig?.file || "-",
				  invoiceDate,
                  item.invoiceConfig?.irnFare?.base || "-",
                  item.invoiceConfig?.irnFare?.slab || "-",
                  item.invoiceConfig?.irnFare?.oth || "-",
                  item.invoiceConfig?.irnFare?.sGst || "-",
                  item.invoiceConfig?.irnFare?.cGst || "-",
                  item.invoiceConfig?.irnFare?.iGst || "-",
                  item.invoiceConfig?.irnFare?.total || "-",
                  airlines(item),
                  segments(item),
                  times(item),
                  flightCode(item),
                  traveller(item),
                  travellerEmails(item),
                  pnrs(item),
                  tickets(item),
                  `${Moment(dates.start).format("DD_MM_YYYY")}-${Moment(
                    dates.end
                  ).format("DD_MM_YYYY")}`,
				  item?.invoiceConfig?.invoiceResponse?.message || "-" ,/* Remarks Column */

          PERSONS[CONFIG.email]?
          item.invoiceConfig?.platformMarkup || "-":"",
                ]);


               
                
              if (
                item.invoiceConfig != null &&
                item.invoiceConfig.serviceInvoice != null &&
                item.invoiceConfig.serviceInvoice !== "SKIPPED" &&
                selectedDoc.includes('Service Invoice')
              ) {
                data.push([
                  item.id,
                  item.transId,
                  item.referenceId||"-",
                  item.provider,
                  item.providerId || item.ticket?.flowId ||  "-",
                  "Service Charge",
                  item.status,
                  item.ticket?.flowId || item.providerId || "-",
                  item.category === "DOMESTICROUNDFLIGHT"
                    ? "Round Trip"
                    : "One Way",
                  // Displays "International" in provider column in CSV if flight is `international`
                  isInternational(item) ? 'International' : 'Domestic',
                  "Economy",
                  item.client.code,
                  item.client.attrs?.CUST_CODE || "-",
                  item.client.attrs?.gstin || "-",
                  item.client.attrs?.pan || "-",
                  item.client.attrs?.tan || "-",
                  item.client.name,
                  item.attrs["bookedBy"] || "-",
                  item.attrs["bookedByEmail"] || "-",
                  Moment(item.createdAt).format("DD MMM YYYY"),
                  Moment(item.createdAt).format("HH:mm a"),
                  Moment(item.updatedAt).format("DD MMM YYYY"),
                  Moment(item.updatedAt).format("HH:mm a"),
                  "27AAHCC1775A1ZX",
                  "Maharashtra",
                  item.invoiceConfig?.userGst?.name || "-",
                  item.invoiceConfig?.userGst?.gstin || "-",
                  placeOfSupply(
                    item.invoiceConfig?.userGst?.gstin || undefined
                  ),
                  item.paymentType,
                  item.amount,
                  item.invoiceConfig?.serviceFare.total,
                  item.ledger?.ledgerId || "-",
                  item.ledger?.amount || "-",
                  item.ledger?.balance || "-",
                  item.attrs["orderId"] || "-",
                  item.attrs["paymentId"] || "-",
                  item.attrs["refundId"] || "-",
                  "998551",
                  "Service Invoice", //
                  item.invoiceConfig?.serviceInvoiceNo || "-",
                  item.invoiceConfig?.serviceResponse?.response?.data?.Irn ||
                    "-",
                  item.invoiceConfig?.serviceInvoice || "-",
                  item?.invoiceConfig?.file || "-",
				  serviceDate,
                  item.invoiceConfig?.serviceFare.base || "-",
                  item.invoiceConfig?.serviceFare.slab || "-",
                  item.invoiceConfig?.serviceFare.oth || "-",
                  item.invoiceConfig?.serviceFare.sGst || "-",
                  item.invoiceConfig?.serviceFare.cGst || "-",
                  item.invoiceConfig?.serviceFare.iGst || "-",
                  item.invoiceConfig?.serviceFare.total || "-",
                  airlines(item),
                  segments(item),
                  times(item),
                  flightCode(item),
                  traveller(item),
                  travellerEmails(item),
                  pnrs(item),
                  tickets(item),
                  `${Moment(dates.start).format("DD_MM_YYYY")}-${Moment(
                    dates.end
                  ).format("DD_MM_YYYY")}`,
				  item?.invoiceConfig?.invoiceResponse?.message || "-", /* Remarks Column */
          PERSONS[CONFIG.email]?
          item.invoiceConfig?.platformMarkup || "-":"",

                ]);
              }
              if (
                item.invoiceConfig !== null &&
                item.invoiceConfig.ancillaryInvoice !== null &&
                item.invoiceConfig.ancillaryInvoice !== "SKIPPED" &&
                selectedDoc.includes('Ancillary Charges')
              ) {
                let lineItem = JSON.parse(item.invoiceConfig.ancillaryRequest);
                data.push([
                  item.id,
                  item.transId,
                  item.referenceId||"-",
                  item.provider,
                  item.providerId || item.ticket?.flowId ||  "-",
                  "Meal/Seat",
                  item.status,
                  item.ticket?.flowId || item.providerId || "-",
                  item.category === "DOMESTICROUNDFLIGHT"
                    ? "Round Trip"
                    : "One Way",
                  // Displays "International" in provider column in CSV if flight is `international`
                  isInternational(item) ? 'International' : 'Domestic',
                  "Economy",
                  item.client.code,
                  item.client.attrs?.CUST_CODE || "-",
                  item.client.attrs?.gstin || "-",
                  item.client.attrs?.pan || "-",
                  item.client.attrs?.tan || "-",
                  item.client.name,
                  item.attrs["bookedBy"] || "-",
                  item.attrs["bookedByEmail"] || "-",
                  Moment(item.createdAt).format("DD MMM YYYY"),
                  Moment(item.createdAt).format("HH:mm a"),
                  Moment(item.updatedAt).format("DD MMM YYYY"),
                  Moment(item.updatedAt).format("HH:mm a"),
                  "27AAHCC1775A1ZX",
                  "Maharashtra",
                  item.invoiceConfig?.userGst?.name || "-",
                  item.invoiceConfig?.userGst?.gstin || "-",
                  placeOfSupply(
                    item.invoiceConfig?.userGst?.gstin || undefined
                  ),
                  item.paymentType,
                  item.amount,
                  lineItem?.data?.valDtls?.totInvVal || "-",
                  item.ledger?.ledgerId || "-",
                  item.ledger?.amount || "-",
                  item.ledger?.balance || "-",
                  item.attrs["orderId"] || "-",
                  item.attrs["paymentId"] || "-",
                  item.attrs["refundId"] || "-",
                  "998551",
                  "Ancillery Charges",
                  lineItem?.data?.docDtls?.no || "-",
                  item.invoiceConfig?.ancillaryIrn || "-",
                  item.invoiceConfig?.ancillaryFare?.total || "-",
                  item?.invoiceConfig?.file || "-",
                  item?.invoiceConfig?.ancillaryDate || "-",
                  lineItem?.data?.valDtls?.assVal || "-",
                  item.invoiceConfig?.ancillaryFare?.slab || "-",
                  `0`,
                  lineItem?.data?.valDtls?.sgstVal || "-",
                  lineItem?.data?.valDtls?.cgstVal || "-",
                  lineItem?.data?.valDtls?.igstVal || "-",
                  lineItem?.data?.valDtls?.totInvVal || "-",
                  airlines(item),
                  segments(item),
                  times(item),
                  flightCode(item),
                  traveller(item),
                  travellerEmails(item),
                  pnrs(item),
                  tickets(item),
                  `${Moment(dates.start).format("DD_MM_YYYY")}-${Moment(
                    dates.end
                  ).format("DD_MM_YYYY")}`,
				  item?.invoiceConfig?.invoiceResponse?.message || "-", /* Remarks Column */
          PERSONS[CONFIG.email]?
          item.invoiceConfig?.platformMarkup || "-":"",
                ]);
              }
              if (
                item.invoiceConfig !== null &&
                item.invoiceConfig.creditNote !== null &&
                item.invoiceConfig.creditNote !== "PENDING" &&
                selectedDoc.includes('Credit Note')
              ) {
                let lineItem = JSON.parse(item.invoiceConfig.creditRequest);
                data.push([
                  item.id,
                  item.transId,
                  item.referenceId||"-",
                  item.provider,
                  item.providerId || item.ticket?.flowId ||  "-",
                  "Credit Note",
                  item.status,
                  item.ticket?.flowId || item.providerId || "-",
                  item.category === "DOMESTICROUNDFLIGHT"
                    ? "Round Trip"
                    : "One Way",
                  // Displays "International" in provider column in CSV if flight is `international`
                  isInternational(item) ? 'International' : 'Domestic',
                  "Economy",
                  item.client.code,
                  item.client.attrs?.CUST_CODE || "-",
                  item.client.attrs?.gstin || "-",
                  item.client.attrs?.pan || "-",
                  item.client.attrs?.tan || "-",
                  item.client.name,
                  item.attrs["bookedBy"] || "-",
                  item.attrs["bookedByEmail"] || "-",
                  Moment(item.createdAt).format("DD MMM YYYY"),
                  Moment(item.createdAt).format("HH:mm a"),
                  Moment(item.updatedAt).format("DD MMM YYYY"),
                  Moment(item.updatedAt).format("HH:mm a"),
                  "27AAHCC1775A1ZX",
                  "Maharashtra",
                  item.invoiceConfig?.userGst?.name || "-",
                  item.invoiceConfig?.userGst?.gstin || "-",
                  placeOfSupply(
                    item.invoiceConfig?.userGst?.gstin || undefined
                  ),
                  item.paymentType,
                  item.amount,
                  item.invoiceConfig?.irnFare?.total,
                  item.refund?.ledgerId || "-",
                  item.refund?.amount || "-",
                  item.refund?.balance || "-",
                  item.attrs["orderId"] || "-",
                  item.attrs["paymentId"] || "-",
                  item.attrs["refundId"] || "-",
                  "996425",
                  "Credit Note",
                  lineItem?.data?.docDtls?.no || "-",
                  item.invoiceConfig?.creditIrn || "-",
                  item.invoiceConfig?.creditNote || "-",
                  item?.invoiceConfig?.file || "-",
                  item?.invoiceConfig?.creditNoteDate || '-',
				  // "-",
                  item.invoiceConfig?.irnFare?.base || "-",
                  item.invoiceConfig?.irnFare?.slab || "-",
                  item.invoiceConfig?.irnFare?.oth || "-", //
                  item.invoiceConfig?.irnFare?.sGst || "-",
                  item.invoiceConfig?.irnFare?.cGst || "-",
                  item.invoiceConfig?.irnFare?.iGst || "-",
                  item.invoiceConfig?.irnFare?.total || "-",
                  airlines(item),
                  segments(item),
                  times(item),
                  flightCode(item),
                  traveller(item),
                  travellerEmails(item),
                  pnrs(item),
                  tickets(item),
                  `${Moment(dates.start).format("DD_MM_YYYY")}-${Moment(
                    dates.end
                  ).format("DD_MM_YYYY")}`,
				  item?.invoiceConfig?.invoiceResponse?.message || "-", /* Remarks Column */
          PERSONS[CONFIG.email]?
          item.invoiceConfig?.platformMarkup || "-":"",
                ]);
              }
              if (
                item.invoiceConfig !== null &&
                item.invoiceConfig.cancellationCharges !== null &&
                item.invoiceConfig.cancellationCharges !== "PENDING" &&
                selectedDoc.includes('Cancellation Charges')
              ) {
                let lineItem = JSON.parse(
                  item.invoiceConfig.cancellationRequest
                );
                data.push([
                  item.id,
                  item.transId,
                  item.referenceId||"-",
                  item.provider,
                  item.providerId || item.ticket?.flowId ||  "-",
                  "Cancellation Charges",
                  item.status,
                  item.ticket?.flowId || item.providerId || "-",
                  item.category === "DOMESTICROUNDFLIGHT"
                    ? "Round Trip"
                    : "One Way",
                  // Displays "International" in provider column in CSV if flight is `international`
                  isInternational(item) ? 'International' : 'Domestic',
                  "Economy",
                  item.client.code,
                  item.client.attrs?.CUST_CODE || "-",
                  item.client.attrs?.gstin || "-",
                  item.client.attrs?.pan || "-",
                  item.client.attrs?.tan || "-",
                  item.client.name,
                  item.attrs["bookedBy"] || "-",
                  item.attrs["bookedByEmail"] || "-",
                  Moment(item.createdAt).format("DD MMM YYYY"),
                  Moment(item.createdAt).format("HH:mm a"),
                  Moment(item.updatedAt).format("DD MMM YYYY"),
                  Moment(item.updatedAt).format("HH:mm a"),
                  "27AAHCC1775A1ZX",
                  "Maharashtra",
                  item.invoiceConfig?.userGst?.name || "-",
                  item.invoiceConfig?.userGst?.gstin || "-",
                  placeOfSupply(
                    item.invoiceConfig?.userGst?.gstin || undefined
                  ),
                  item.paymentType,
                  item.amount,
                  lineItem?.data?.valDtls?.totInvVal || "-",
                  item.refund?.ledgerId || "-",
                  item.refund?.amount || "-",
                  item.refund?.balance || "-",
                  item.attrs["orderId"] || "-",
                  item.attrs["paymentId"] || "-",
                  item.attrs["refundId"] || "-",
                  "996425",
                  "Cancellation Charges",
                  lineItem?.data?.docDtls?.no || "-",
                  item.invoiceConfig?.cancellationIrn || "-",
                  item.invoiceConfig?.cancellationCharges || "-",
                  item?.invoiceConfig?.file || "-",
                  item?.invoiceConfig?.cancellationDate || "-",
                  lineItem?.data?.valDtls?.assVal || "-",
                  item.invoiceConfig?.irnFare?.slab || "-",
                  `0`,
                  lineItem?.data?.valDtls?.sgstVal || "-",
                  lineItem?.data?.valDtls?.cgstVal || "-",
                  lineItem?.data?.valDtls?.igstVal || "-",
                  lineItem?.data?.valDtls?.totInvVal || "-",
                  airlines(item),
                  segments(item),
                  times(item),
                  flightCode(item),
                  traveller(item),
                  travellerEmails(item),
                  pnrs(item),
                  tickets(item),
                  `${Moment(dates.start).format("DD_MM_YYYY")}-${Moment(
                    dates.end
                  ).format("DD_MM_YYYY")}`,
				  item?.invoiceConfig?.invoiceResponse?.message || "-", /* Remarks Column */
          PERSONS[CONFIG.email]?
          item.invoiceConfig?.platformMarkup || "-":"",
                ]);
              }
            }
          } catch (e) {
            console.log("error reseller", e);
          }
        });
        DownloadCsv(
          `flight_resller_bos_${Moment(STARTDATE.current.value).format(
            "DD_MM_YYYY"
          )}_${Moment(ENDDATE.current.value).format("DD_MM_YYYY")}.csv`,
          data
        );
        setLoading(false);
      },
      {
        start: Moment(STARTDATE.current.value).valueOf(),
        end: Moment(ENDDATE.current.value).add(1,'d').valueOf(),
        filterOn: DATETYPE.current.value,
      }
    );
  };

  return (
    <div style={{ width: "100%" }}>
    <Navigation
      backUrl={"back"}
      title='Flight Reseller Bookings'
      chips={["Meta", "Flight", "Reseller"]}
      description='Reseller bookings appears below.'
    />
    <div
      style={{
        margin: "0 -4rem",
        padding: "1rem 4rem",
        borderBottom: "1px solid #dedede",
      }}
    >
      <div className='flex horizontally center-vertically'>
        <SelectInput 
          options={[
            {label: "Created At Date", value: "createdAt"},
            {label: "Document", value: "document"},
          ]}
          ref={DATETYPE}
          type='text'
          label='Date Range Filter'
          hidePlaceholder={true}
          className='mr1 flex-1'
        />
        <DateRange 
          label="From Date"
          className='flex-1 ml1 mr1 mt1'
          ref={STARTDATE}
          defaultValue={dates.start}
          onChange={()=>{
            setDates({
              ...dates, start: STARTDATE.current.value
            })
          }}
        />
        <DateRange 
          label="To Date"
          ref={ENDDATE}
          defaultValue={dates.end}
          className='flex-1 mr1 ml1 mt1'
          onChange={()=>{
            setDates({
              ...dates, end: ENDDATE.current.value
            })
          }}
        />
        <MultiSelectDropdown 
          label="Clients" 
          placeholder="Select Clients"
          options={clientList} 
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
          className='ml1 mr1 mt1 flex-1'
        />
        <MultiSelectDropdown
          label="Document Type"
          placeholder="Select Document Type"
          options={docTypes}
          selectedOptions={selectedDoc}
          setSelectedOptions={setSelectedDoc}
          className='ml1 mr1 mt1 flex-1'
          selectAllOption={true}
          showSelectedOptins={false}
          enableSearch={false}
        />
      </div>
    </div>
    {
      loading 
        ? ( <div className='pd6'>
          <Loader />
          </div>
        )
        : (
          <div>
          <div className='border pd6 mt4 flex vertically center'>
            <img
              alt='fetch'
              src={Graph}
              style={{ width: "5rem", height: "5rem" }}
            />
            <div className="flex mt4">
              <RBAC role={ROLES.REPORTS}>
                <Button onClick={downloadData} className='btn btn-black '>
                  Download Data
                </Button>
                <Button onClick={downloadDocs} className='btn btn-black ml4'>
                  Download Documents
                </Button>
              </RBAC>
            </div>
            <p className='mt2'>Download reseller bos data</p>
          </div>
        </div>
      )
    }
  </div>
);
}
