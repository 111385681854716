import React, {useEffect, useRef, useState} from 'react'
import {useParams} from "react-router-dom";
import {get, post} from "../../../../Network/Axios";
import {Button, Input, KeyValue, Modal, TOAST} from "../../../../../retro";

export default function User() {
    const {id} = useParams();
    const ID = useRef();
    const EP = useRef();
    const CERT = useRef();
    const PW = useRef();
    const [config,setConfig] = useState({})
    const [modal,setModal] = useState(false)
    const load = id => {
        get(`/clients/saml/${id}/travel`,(e,r)=>{
            if(r){
                setConfig(r.ssoConfig)
            }
        });
    }
    const toggle = id => {
        if(!config.enabled){
            setModal(true)
        }else{
            post(`/clients/saml/${id}/travel/toggle`,{},(e,r)=>{
                if(r){
                    load(id)
                }
            });
        }
    }
    useEffect(()=>{
        load(id)
    },[id])
    return(
        <div>
            {
                modal && <Modal title="Sso Config" description="Sso configuration" onClose={()=>{setModal(false)}}>
                    <Input allowSpecialCharacters={true} type="text" ref={ID} placeholder="Enter Id" label="Name Id"/>
                    <Input allowSpecialCharacters={true} type="text" ref={EP} placeholder="Enter Endpoint" label="Endpoint"/>
                    <Input allowSpecialCharacters={true} type="text" ref={CERT} placeholder="Enter Cert" label="Certificate"/>
                    <Input allowSpecialCharacters={true} type="text" ref={PW} placeholder="Enter Password" label="Password"/>
                    <Button margin="mt4" onClick={()=>{
                        let mId = ID.current.value;
                        let ep = EP.current.value;
                        let crt = CERT.current.value;
                        let pw = PW.current.value;
                        let data = {
                            id:mId,ep,crt,pw
                        }
                        post(`/clients/saml/${id}/travel`,data,(e,r)=>{
                            if(r){
                                TOAST.success("SAML configuration successful!!")
                                setModal(false)
                                load(id)
                            }
                        })
                    }} className="btn btn-black">
                        + Add Sso
                    </Button>
                </Modal>
            }
            <div className="border rounded-md pd4 mt4">
                <h2 className="fw-bold">
                   Travel Authentication Settings
                </h2>
                <p>
                    Enable travel authentication settings appears below.
                </p>
                {
                    config.enabled&&<div className="mt4">
                        <p className="text text-light">
                            Endpoint:
                        </p>
                        <p className="">
                            {config.endPoint}
                        </p>
                        <KeyValue title="Id" value={config.id} margin="mt1"/>
                        <KeyValue title="Cert" value={config.cert}/>
                    </div>
                }
                <Button onClick={toggle.bind(this,id)} className="btn btn-black" margin="mt4" style={{width:300}}>
                    {
                        config.enabled ? "- Disable Authentication":"+ Enable Authentication"
                    }
                </Button>
            </div>
        </div>
    )
}
