import React from 'react';
import {
  Button,
  Headings,
  NavDivider,
  Navigation,
  Texts,
} from '../../../../../retro';
import { useHistory } from 'react-router-dom';
import BookIcon from '@atlaskit/icon/glyph/book';
import { useSelector } from 'react-redux';
import useConfigHook from "App/Hooks/useConfigHook";
import ROLES from 'App/Constants/Roles';
import useRoleHook from 'App/Hooks/useRoleHook';

const PERSONS = {
  "lakshay.jain@antrepriz.com":true,
  "mohamed.mansoor@flipkart.com":true,
  "mohamed.mansoor@cleartrip.com":true,
  "sampathkumar.j@flipkart.com":true,
  "maqsood.ahmed@flipkart.com":true,
  "syed.shabuddin@flipkart.com":true,
  "aavishkar@antrepriz.com" : true,
  "aditya.vyawhare@antrepriz.com" : true,
  "govind.singh@antrepriz.com" : true,
  "j.sudheer@cleartrip.com":true,
  "srirama.m@cleartrip.com":true,
  "shivasankar.mahanta@cleartrip.com":true,
  "maqsood.ahmed@cleartrip.com":true,
  "sampathkumar.j@cleartrip.com":true,
  "narayan.n@cleartrip.com":true,
  "karthikm1.vc@flipkart.com":true,
  "velvizhia.vc@cleartrip.com":true,
  "syed.shabuddin@cleartrip.com":true,
  "nikhil.n.vc@flipkart.com":true,
}

export default function Routes() {
  // Origin Code
  const { code } = useSelector((data) => ({
    code: data?.auth?.config?.origin?.code || '',
  }));
  const CONFIG = useConfigHook();
  const isReports = useRoleHook(ROLES.REPORTS);
  const routes = [];
  if (isReports){
    routes.push(
      {
        title: 'BOS File',
        desc: 'All bookings data distributed traveller wise and segment wise',
        path: '/app/apps/meta/all/bos',
        btnLabel: 'View File',
        codes: ['CLRTRP', 'CLTP-STG','ADANI','ADANI-PROD'],
      }
    )
  }
  
  return (
    <div>
      <Navigation
        backUrl={''}
        title="All Bookings"
        description="All ookings related mis are listed below."
      />
      <NavDivider />
      <div className="card-container mt4">
        <div className="card-container mt4">
          {routes.map((report) =>
            // return only if noone  or the current one is not restricted
            report.codes.length === 0 || report.codes.includes(code) ? (
              <ReportRouteCard reportRoute={report} />
            ) : (
              <></>
            )
          )}
        </div>
      </div>
    </div>
  );
}

export const ReportRouteCard = ({ reportRoute }) => {
  const HISTORY = useHistory();
  return (
    <div className="retro-card">
      <div className="relative ">
        <BookIcon size="xlarge" />
        <Headings.Regular margin="mt4">{reportRoute.title}</Headings.Regular>
        <Texts.Small style={{ maxLines: 2 }} className="">
          {reportRoute.desc}
        </Texts.Small>
        <Button
          onClick={() => {
            HISTORY.push(`${reportRoute.path}`);
          }}
          className="mt3 btn-max btn-primary"
        >
          {reportRoute.btnLabel}
        </Button>
      </div>
    </div>
  );
};
