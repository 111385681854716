import React from 'react';
import { NavDivider, Navigation } from '../../../../../retro';
import { ReportRouteCard } from '../All/routes';
import ROLES from 'App/Constants/Roles';
import useRoleHook from 'App/Hooks/useRoleHook';

export default function routes() {
  const isReports = useRoleHook(ROLES.REPORTS);
  const routes = [];
  if (isReports) {routes.push({
    title: 'BOS File',
    desc: 'Traveller wise bos file',
    path: '/app/apps/meta/train/bos',
    btnLabel: 'View File',
    codes: [],
  })}
  return (
    <div>
      <Navigation
        backUrl=""
        title="Train Bookings"
        description="All train queue appears below."
      />
      <NavDivider />
      <div className="card-container mt4">
        {routes.map(
          (report) =>
            // return only if noone  or the current one is not restricted
            (report?.codes.length === 0 || report?.codes.includes(code)) && (
              <ReportRouteCard reportRoute={report}/>
            )
        )}
      </div>
    </div>
  );
}
