/**
 * Author[Lakshay Jain]
 * Version 1.0.1
 * 7th December | 1.0.1 | Shubham Rawat | fixed the download inactive clients api call
*/
import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Loader,
  ScrollableDynamicTable,
  SearchBox,
  TOAST as Alert,
} from 'retro';
import { get } from 'App/Network/Axios';
import OpenIcon from '@atlaskit/icon/glyph/open';
import { useHistory } from 'react-router-dom';
import Moment from 'moment';
import { DownloadCsv } from 'retro/Utils';
import RBAC from 'App/HOCs/RBAC';
import ROLES from 'App/Constants/Roles';
import useRoleHook from 'App/Hooks/useRoleHook';

const HEADERS = [
  {
    weight: 2,
    title: 'Details',
  },
  {
    weight: 1,
    title: 'Code',
    sort: 'code',
  },
  {
    weight: 1,
    title: 'Created On',
  },
  {
    weight: 1,
    title: 'Type',
  },
  {
    weight: 1,
    title: 'Status',
    sort: 'status',
  },
  {
    weight: 1,
    title: 'Actions',
    style: {
      justifyContent: 'end',
    },
  },
];

export default function AllClients({}) {
  let [loading, setLoading] = useState(true);
  const isClientWrite = useRoleHook(ROLES.CLIENTS_WRITE);
  const isClient = useRoleHook(ROLES.CLIENTS);
  const History = useHistory();
  const [search, setSearch] = useState({
    page: 0,
    sort: 'id',
    order: 'desc',
    q: undefined,
  });
  let onClick = (item) => {
    History.push(`/app/client/${item.id}/users`);
  };
  const [Data, SetData] = useState({
    total: 0,
    currentPage: 0,
    totalPages: 0,
    items: [],
  });
  const loadPage = useCallback(
    (search) => {
      get(
        `/clients/inactive`,
        (e, r) => {
          if (r) {
            SetData({
              ...r,
            });
            setLoading(false);
          } else {
            Alert.handleError(e);
          }
        },
        {
          offset: search.page,
          sort: search.sort,
          order: search.order,
          q: search.q,
        }
      );
    },
    [SetData]
  );
  useEffect(() => {
    loadPage(search);
  }, [search]);
  return (
    <>
      <div className='mt4'>
        {loading ? (
          <Loader />
        ) : (
          <div className='relative'>
            <div className='flex horizontally center-vertically'>
              <div className='flex-1'>
                <SearchBox
                  onChange={(q) => {
                    setSearch({
                      ...search,
                      q: q && q.length > 0 ? q : undefined,
                      page: 0,
                    });
                  }}
                  containerClass=''
                  style={{
                    zIndex: 2,
                  }}
                  placeholder='Enter your search criteria'
                />
              </div>
              <RBAC role={ROLES.REPORTS}>
                <div className='flex-1 flex horizontally justify-end'>
                  <Button
                    onClick={() => {
                      get(
                        `/clients/dump`,
                        (e, r) => {
                          if (r) {
                            let data = [];
                            let headers = [
                              'Id',
                              'Name',
                              'Code',
                              'Email',
                              'Type',
                              'Created On',
                              'Status',
                            ];
                            data.push(headers);
                            r.items.map((item) => {
                              data.push([
                                item.id,
                                item.name,
                                item.code,
                                item.email,
                                item.sme ? 'SME' : 'CORPORATE',
                                Moment(item.createdAt).format(
                                  'DD MMM YYYY HH:mm a'
                                ),
                                item.status,
                              ]);
                            });
                            DownloadCsv(
                              `clients_on_${Moment().format('DD_MM_YYYY')}.csv`,
                              data
                            );
                          } else {
                            Alert.handleError(e);
                          }
                        },
                        {active: false}
                      );
                    }}
                    className='btn btn-black'
                    margin='ml6'
                  >
                    Download Data
                  </Button>
                </div>
              </RBAC>
            </div>
            <ScrollableDynamicTable
              setSearch={(search) => {
                setSearch({
                  ...search,
                  page: 0,
                });
              }}
              search={search}
              tableStyle={{
                borderSpacing: 0,
              }}
              setPage={(page) => {
                setSearch({
                  ...search,
                  page,
                });
              }}
              data={{
                current: Data.currentPage,
                total: Data.totalPages,
                items: Data.items.map((item) => {
                  const click = () => {
                    onClick(item);
                  };
                  return {
                    content: item,
                    render: [
                      {
                        children: (
                          <div>
                            <p className='text truncate'>{item.name}</p>
                            <p className='text-small text-light truncate'>
                              {item.email}
                            </p>
                          </div>
                        ),
                        weight: 2,
                      },
                      {
                        weight: 1,
                        title: item.code,
                      },
                      {
                        weight: 1,
                        title: Moment(item.createdAt).format(
                          'DD MMM YYYY HH:mm a'
                        ),
                      },
                      {
                        weight: 1,
                        title: item.sme ? 'SME' : 'CORPORATE',
                      },
                      {
                        weight: 1,
                        title: item.status,
                      },
                      (isClientWrite || isClient)
                        ? {
                            weight: 1,
                            buttons: [
                              {
                                title: 'View',
                                className: 'btn-secondary btn-sm',
                                before: <OpenIcon size='small' />,
                                onClick: click,
                              },
                            ],
                            actions: [
                              {
                                title: 'Quick Actions',
                                actions: [
                                  {
                                    title: 'View',
                                    before: <OpenIcon size='small' />,
                                    onClick: click,
                                  },
                                ],
                              },
                            ],
                          }
                        : {},
                    ],
                  };
                }),
              }}
              headers={
                (isClientWrite || isClient) ? HEADERS : HEADERS.slice(0, HEADERS.length - 1)
              }
            />
          </div>
        )}
      </div>
    </>
  );
}
