/**
 * Author[Shubham Rawat]
 * Version 1.0.0
 * 26th December | 1.0.0 | Shubham Rawat | created a simple toggle component 
*/

import React, { useState } from 'react';
import { Button } from 'retro/index';
import './style.css';

const ToggleButtonGroup = ({
  options = ['Option 1', 'Option 2'],
  defaultValue = "",
  onSwitch,
}) => {
  const [selectedValue, setSelectedValue] = useState(defaultValue || null);

  const handleToggle = (value) => {
    setSelectedValue(value === selectedValue ? null : value);
    onSwitch();
  };

  return (
    <div className="toggle-button-group rounded-md pd1">
      {options.map((option) => (
        <Button
          className={`toggle-button btn-sm ${
            selectedValue === option ? 'selected' : ''
          }`}
          onClick={() => handleToggle(option)}
        >
          {option}
        </Button>
      ))}
    </div>
  );
};

export default ToggleButtonGroup;
