import React from 'react';
import { NavDivider,Navigation } from 'retro/index';
import { useSelector } from 'react-redux';
import { ReportRouteCard } from '../All/routes';

export default function index() {
  // Origin Code
  const { code } = useSelector((data) => ({
    code: data?.auth?.config?.origin?.code || '',
  }));
  // show PERKS for CLRTRP only
  const routes = [
    {
        title: 'Import Hotel',
        desc: 'Import Hotel Bookings',
        path: '/app/apps/meta/import/hotel',
        btnLabel: 'Import',
        codes: ['CLRTRP', 'CLTP-STG','ADANI','ADANI-PROD'],
      },
      {
        title: 'Import Tripjack',
        desc: 'Import Tripjack Bookings',
        path: '/app/apps/meta/import/tj',
        btnLabel: 'Import',
        codes: ['CLRTRP', 'CLTP-STG','ADANI','ADANI-PROD'],
      },
      {
        title: 'Import Cleartrip',
        desc: 'Import Cleartrip Bookings',
        path: '/app/apps/meta/import/cleartrip',
        btnLabel: 'Import',
        codes: ['CLRTRP', 'CLTP-STG','ADANI','ADANI-PROD'],
      },
      {
        title: 'Import Bus',
        desc: 'Import Bus Bookings',
        path: '/app/apps/meta/import/bus',
        btnLabel: 'Import',
        codes: ['CLRTRP', 'CLTP-STG','ADANI','ADANI-PROD'],
      },
      {
        title: 'Import Train',
        desc: 'Import Train Bookings',
        path: '/app/apps/meta/import/train',
        btnLabel: 'Import',
        codes: ['CLRTRP', 'CLTP-STG','ADANI','ADANI-PROD'],
      },
      {
        title: 'Import Visa',
        desc: 'Import Visa',
        path: '/app/apps/meta/import/visa',
        btnLabel: 'Import',
        codes: ['CLRTRP', 'CLTP-STG','ADANI','ADANI-PROD'],
      },
      {
        title: 'Import Insurance',
        desc: 'Import Insurance',
        path: '/app/apps/meta/import/insurance',
        btnLabel: 'Import',
        codes: ['CLRTRP', 'CLTP-STG','ADANI','ADANI-PROD'],
      },
    
  ];
  return (
    <div>
      <Navigation
        backUrl=""
        title="Import Bookings"
        description="All import bookings appears below."
      />
      <NavDivider />
      <div className="card-container mt4">
        {routes.map(
          (report) =>
            // return only if noone  or the current one is not restricted
            (report.codes.length === 0 || report.codes.includes(code)) ? (
              <ReportRouteCard reportRoute={report} />
            ):<></>
        )}
      </div>
    </div>
  );
}
