import { ExpandableSidebar, NotFound, Page, Wrapper } from 'retro';
import AddAdmin from './AddAdmin';
import QueuesIcon from '@atlaskit/icon/glyph/queues';
import PeopleGroupIcon from '@atlaskit/icon/glyph/people-group';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import Admins from './Admins';
import RBAC from 'App/HOCs/RBAC';
import ROLES from 'App/Constants/Roles';

export default function Admin() {
  const items = [];
  const HISTORY = useHistory();
  const Location = useLocation();

  items.push({
    title: 'Control Room',
    actions: [
      {
        title: 'All Admins',
        icon: <QueuesIcon size='small' />,
        active: Location.pathname === '/app/admins',
        onClick: () => {
          HISTORY.push('/app/admins');
        },
      },
      {
        title: 'Create New Admin',
        icon: <PeopleGroupIcon size='small' />,
        active: Location.pathname.includes('/app/admins/new'),
        onClick: () => {
          HISTORY.push('/app/admins/new');
        },
      },
    ],
  });

  return (
    <RBAC role={ROLES.SUPER} renderNoAccess>
      <div className='flex h-100h w-100'>
        <ExpandableSidebar items={items} />
        <Wrapper>
          <Page>
            <Switch>
              <Route path='/app/admins/new' exact component={AddAdmin} />
              <Route path='/app/admins' exact component={Admins} />
              <Route path='/app/admins/inactive' exact component={Admins} />
              <NotFound />
            </Switch>
          </Page>
        </Wrapper>
      </div>
    </RBAC>
  );
}
