import React from 'react'
import {Route, Switch, useHistory, useLocation} from 'react-router-dom';
import {ExpandableSidebar, NavDivider, Navigation, NotFound, Wrapper} from "retro";
import PasswordChange from './Password'
import Updates from './Updates'
import FileIcon from "@atlaskit/icon/glyph/settings";

function Settings() {
	const History = useHistory();
	const location = useLocation();
	return (
		<div className="flex h-100h w-100">
			<ExpandableSidebar items={[
				{
					title: "Actions",
					actions: [

						{
							title: 'Change Password',
							icon: (<FileIcon size="small"/>),
							active: location.pathname === '/app/settings',
							onClick: () => {
								History.push('/app/settings')
							},
						},
						{
							title: 'Version Updates',
							icon: (<FileIcon size="small"/>),
							active: location.pathname === '/app/settings/updates',
							onClick: () => {
								History.push('/app/settings/updates')
							},
						}
					]
				}
			]}/>
			<Wrapper>
				<Navigation title='Account Settings' chips={['Settings']}/>
				<NavDivider/>
				<Switch>
					<Route path="/app/settings/updates" exact component={Updates}/>
					<Route path="/app/settings" exact component={PasswordChange}/>
					<NotFound/>
				</Switch>
			</Wrapper>
		</div>
	);
}

export default Settings;
