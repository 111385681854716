/**
 * Author[Lakshay Jain]
 * Version 1.0.2
 * 7th December | 1.0.1 | Shubham Rawat | fixed the download active clients api call
 * 26th December | 1.0.2 | Shubham Rawat | changed the default push route to dashboard
 */
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {
	Button,
	Loader,
	ScrollableDynamicTable,
	SearchBox,
	TOAST as Alert,
	SidePane,
	Modal,
	Input,
	TOAST
} from 'retro';
import {get, post} from 'App/Network/Axios';
import OpenIcon from '@atlaskit/icon/glyph/open';
import {useHistory} from 'react-router-dom';
import Moment from 'moment';
import {DownloadCsv} from 'retro/Utils';
import Balance from './Balance';
import RBAC from 'App/HOCs/RBAC';
import ROLES from 'App/Constants/Roles';
import useRoleHook from 'App/Hooks/useRoleHook';
import useConfigHook from "App/Hooks/useConfigHook";
import BulkStatusChange from './BulkStatusChange'

export default function AllClients({}) {
	const CONFIG = useConfigHook();
	 const [modal,setModal]= useState(false)
	 
	const PERSONS = {
		"aavishkar@antrepriz.com" : true,
		"aditya.vyawhare@antrepriz.com" : true,
		"saurabh.mhaske@antrepriz.com" : true,
		"ct-stage@dice.tech": true
	}

	let HEADERS = [
		{
			weight: 2,
			title: 'Details',
		},
		{
			weight: 1,
			title: 'Code',
			sort: 'code',
		},
		{
			weight: 1,
			title: 'Created On',
		},
		{
			weight: 1,
			title: 'Balance',
		},
		{
			weight: 1,
			title: 'Actions',
			style: {
				justifyContent: 'end',
			},
		},
	];
	if (CONFIG.origin.code === "CLRTRP") {
		HEADERS = [
			{
				weight: 2,
				title: 'Details',
			},
			{
				weight: 1,
				title: 'Code',
				sort: 'code',
			},
			{
				weight: 1,
				title: 'Created On',
			},
			{
				weight: 1,
				title: 'Balance',
			},
			{
				weight: 1,
				title: 'Flight Reseller',
			},
			{
				weight: 1,
				title: 'Hotel Reseller',
			},
			{
				weight: 1,
				title: 'Actions',
				style: {
					justifyContent: 'end',
				},
			},
		]
	}
	let [loading, setLoading] = useState(true);
	const [clientDataLoading, setClientDataLoading]=useState(false);
	const [statusModal, setStatusModal]=useState(false);
	const History = useHistory();
	const isClient = useRoleHook(ROLES.CLIENTS);
	const isCreateClient = useRoleHook(ROLES.CLIENTS_WRITE);
	const [data,setData]=useState();
	const [gstValid,setGstValid] = useState({
		valid: false,
		message: 'Enter a valid gstin'
	});

	const NAME =useRef()
	const EMAIL =useRef()
	const GSTIN =useRef()
	const REFERENCE =useRef()
	const CODE =useRef()
	const ACCOUNT =useRef()
	const IFSC =useRef()
	const PARTNER =useRef()
	const TSP =useRef()

	


	let onClick = (item) => {
		History.push(`/app/client/${item.id}/dashboard`);
	};
	const [search, setSearch] = useState({
		page: 0,
		sort: 'id',
		order: 'desc',
		q: undefined,
	});
	const [Data, SetData] = useState({
		total: 0,
		currentPage: 0,
		totalPages: 0,
		items: [],
	});
	const loadPage = useCallback(
		(search) => {
			get(
				`/clients`,
				(e, r) => {
					if (r) {
						SetData({
							...r,
						});
						setLoading(false);
					} else {
						Alert.handleError(e);
					}
				},
				{
					offset: search.page,
					sort: search.sort,
					status: "LIVE",
					order: search.order,
					q: search.q,
				}
			);
		},
		[SetData]
	);

	useEffect(() => {
		loadPage(search);
	}, [search]);
	// download report method
	const downloadReport = () => {
		setClientDataLoading(true);	
		if(CONFIG.origin.code.includes("YES")){
			get(
				`/clients/dump`,
				(e, r) => {
				setClientDataLoading(false);		
					if (r) {
						let data = [];
						let headers = [
							'Id',
							'Name',
							'Code',
							'Email',
							'Type',
							'Created On',
							'Status',
							'Gstin',
						];
						data.push(headers);
						r.items.map((item) => {
							data.push([
								item.id,
								item.name,
								item.code,
								item.email,
								item.sme ? 'SME' : 'CORPORATE',
								Moment(item.createdAt).format('DD MMM YYYY HH:mm a'),
								item.status,
								item.attrs?.gstin || '-',
							]);
						});
						DownloadCsv(`clients_on_${Moment().format('DD_MM_YYYY')}.csv`, data);
					} else {
						Alert.handleError(e);
					}
				},
				{active: true}
			);
		}else{
			get(
				`/clients/dump`,
				(e, r) => {
				setClientDataLoading(false);	
					if (r) {
						let data = [];
						let headers = [
							'Id',
							'Name',
							'Code',
							'Email',
							'Type',
							'Created On',
							'Status',
							'Cust code',
							'Pan',
							'Tan',
							'Gstin',
							'Payment Gateway',
							'Amendment Charges',
							'Bus Charges',
							'Cancellation Charges',
							'Flight Charges',
							'Flight International Charges',
							'Flight Cancellation Charges',
							'Intl. Flight Cancellation Charges',
							'Hotel Charges',
							'Hotel Contract Charges',
							'Hotel Cancellation Charges',
							'Hotel International Charges',
							'Hotel International Cancellation Charges',
							'PG Charges',
							'Train Charges',
							'Train cancellation charges',
							'Train AC Charges',
							'Train Tatkal Charges',
							'Flight Reseller',
							'Hotel Reseller',
							'CL_FLIGHT',
							'CL_FLIGHT_INT',
							'TJ_FLIGHT',
							'CL_HOTEL',
							'CL_HOTEL_INT',
							'CL_HOTEL_OFFLINE',
							'CONTRACTED',
							'TY_BUS',
							'CL_FLIGHT_X-CT-API-KEY',
							'CL_FLIGHT_X-CT-DEPOSIT',
							'CL_FLIGHT_INT_X-CT-API-KEY',
							'CL_FLIGHT_INT_X-CT-DEPOSIT',
							'CL_HOTEL_X-CT-API-KEY',
							'CL_HOTEL_X-CT-DEPOSIT',
							'CL_HOTEL_INT_X-CT-API-KEY',
							'CL_HOTEL_INT_X-CT-DEPOSIT',
						];
						data.push(headers);
						r.items.map((item) => {
							data.push([
								item.id,
								item.name,
								item.code,
								item.email,
								item.sme ? 'SME' : 'CORPORATE',
								Moment(item.createdAt).format('DD MMM YYYY HH:mm a'),
								item.status,
								item.attrs?.CUST_CODE || '-',
								item.attrs?.pan || '-',
								item.attrs?.tan || '-',
								item.attrs?.gstin || '-',
								item?.paymentGatewayType || '-',
								item.config?.travelConfig?.amendment || '0',
								item.config?.travelConfig?.bus || '0',
								item.config?.travelConfig?.cancellation || '0',
								item.config?.travelConfig?.flight || '0',
								item.config?.travelConfig?.flightInt || '0',
								item.config?.travelConfig?.flightCancellation || '0',
								item.config?.travelConfig?.flightIntCancellation || '0',
								item.config?.travelConfig?.hotel || '0',
								item.config?.travelConfig?.hotelContract || '0',
								item.config?.travelConfig?.hotelCancellation || '0',
								item.config?.travelConfig?.hotelInt || '0',
								item.config?.travelConfig?.hotelIntCancellation || '0',
								item.config?.travelConfig?.pg || '0',
								item.config?.travelConfig?.train || '0',
								item.config?.travelConfig?.trainCancellation || '0',
								item.config?.travelConfig?.trainAc || '0',
								item.config?.travelConfig?.trainTatkal || '0',
								item.config?.travelConfig?.flightReseller ? 'Yes' : 'No',
								item.config?.travelConfig?.hotelReseller ? 'Yes' : 'No',
								item.marketConfig?.['CL_FLIGHT']?.enabled ? 'Yes' : 'No',
								item.marketConfig?.['CL_FLIGHT_INT']?.enabled ? 'Yes' : 'No',
								item.marketConfig?.['TJ_FLIGHT']?.enabled ? 'Yes' : 'No',
								item.marketConfig?.['CL_HOTEL']?.enabled ? 'Yes' : 'No',
								item.marketConfig?.['CL_HOTEL_INT']?.enabled ? 'Yes' : 'No',
								item.marketConfig?.['CL_HOTEL_OFFLINE']?.enabled ? 'Yes' : 'No',
								item.marketConfig?.['CONTRACTED']?.enabled ? 'Yes' : 'No',
								item.marketConfig?.['TY_BUS']?.enabled ? 'Yes' : 'No' || '-',
								item.marketConfig?.['CL_FLIGHT']?.config?.['X-CT-API-KEY'] || '-',
								item.marketConfig?.['CL_FLIGHT']?.config?.['X-CT-DEPOSIT'] || '-',
								item.marketConfig?.['CL_FLIGHT_INT']?.config?.['X-CT-API-KEY'] || '-',
								item.marketConfig?.['CL_FLIGHT_INT']?.config?.['X-CT-DEPOSIT'] || '-',
								item.marketConfig?.['CL_HOTEL']?.config?.['X-CT-API-KEY'] || '-',
								item.marketConfig?.['CL_HOTEL']?.config?.['X-CT-DEPOSIT'] || '-',
								item.marketConfig?.['CL_HOTEL_INT']?.config?.['X-CT-API-KEY'] || '-',
								item.marketConfig?.['CL_HOTEL_INT']?.config?.['X-CT-DEPOSIT'] || '-',
							]);
						});
						DownloadCsv(`clients_on_${Moment().format('DD_MM_YYYY')}.csv`, data);
					} else {
						Alert.handleError(e);
					}
				},
				{active: true}
			);
		}
	};
	const updateClient=()=>{
		let data={
			reference: REFERENCE.current.value
			}


		post(`/clients/neo/${modal.id}/update`, data, (e, r) => {
			if (r) {
			  
			  REFERENCE.current.value = "";
			  TOAST.success("Client updated successfully");
			  setModal(undefined);
			  loadPage(search);
			} else {
			  TOAST.handleError(e);
			}
		  });
	}


	 // validate gst function
	 const validateGst = (gstin="")=>{
        if(gstin.length > 10){
            post('/validate/gstin',{gstin}, (e,r)=>{
                if(e){
                    console.log(e);
                    return;
                }
				if (r.duplicate){
					setGstValid({
						valid: false,
						message: 'Client with entered GSTIN already exist'
					});
					return;
				}
				if (!r.valid) {
					setGstValid({
						valid: false,
						message: 'Enter a valid gstin'
					});
					setData(formData => ({
						...formData,
						name: ' '
					}))
					return;
				}

                if(r.valid){
					setData(formData => ({
						...formData,
						name: r.registeredName
					}))
                    setGstValid({
						valid: true,
						message: ''
					});
                }
            })
        }
    }

	   // check gst on blur
	   const onBlur = ()=>{
        const gstin = GSTIN.current.value;
        validateGst(gstin);
    }

	return (
		<>


    {modal && (
        <SidePane
          onClose={() => {
            setModal(undefined);
          }}
          description='Enter details below to edit admin'
          title='Edit Admin'
        >

        {/* <Input defaultValue={modal?.gstin||""} ref={GSTIN} type="text" label="Client Gstin *" onBlur={onBlur} placeholder="enter client gstin" morph={true}/> */}
                <Input defaultValue={modal?.code||""} disabled={true} ref={CODE} type="text" label="Client Code *" placeholder="enter client code" morph={true}/>
                <Input defaultValue={modal?.name||""} disabled={true} ref={NAME} type="text" label="Client Name *" placeholder="enter client name" morph={true} />
                <Input defaultValue={modal?.email||""} disabled={true} ref={EMAIL} type="text" label="Email Address *" placeholder="enter email address" morph={true}/>
                <Input defaultValue={modal?.reference||""} ref={REFERENCE} type="text" label="Reference *" placeholder="enter Reference" morph={true}/>
                {/* <Input defaultValue={modal.account||""} ref={ACCOUNT} type="text" label="Account Number" placeholder="Enter Account Number" morph={true}/>
                <Input defaultValue={modal.ifsc||""} ref={IFSC} type="text" label="Ifsc Code" placeholder="Enter Ifsc Code" morph={true}/>
                <Input defaultValue={modal.partner||""} ref={PARTNER} type="text" label="Payout/Partner Key" placeholder="Enter Payout/Partner Key" morph={true}/>
                <Input defaultValue={modal.tsp||""} ref={TSP} type="text" label="TSP Key" placeholder="Enter TSP Key" morph={true}/>
               */}
         
          <Button onClick={updateClient} className='btn-primary mt5'>
            Update Client
          </Button>
        </SidePane>
      )}
			<div className='mt4'>
				{loading ? (
					<Loader/>
				) : (
					<div className='relative'>
						<div className='flex horizontally center-vertically'>
							<div className='flex-1'>
								<SearchBox
									onChange={(q) => {
										setSearch({
											...search,
											q: q && q.length > 0 ? q : undefined,
											page: 0,
										});
									}}
									containerClass=''
									style={{
										zIndex: 2,
									}}
									placeholder='Enter your search criteria'
								/>
							</div>
							<RBAC role={ROLES.REPORTS}>
								<div className='flex-1 flex horizontally justify-end'>
									<div className=''>
									<Button
										onClick={downloadReport}
										className='btn btn-black'
										margin='ml6'
										loading={clientDataLoading}
									>
									<i className='fa fa-download mr1'/>Download Data
									</Button>
									</div>
									{ PERSONS[CONFIG.email] &&
	      							<div>	
	      								<Button
	      									onClick={()=> setStatusModal(true)}
	      									className='btn btn-secondary'
	      									margin='ml2'
	      								>
	      								<i className='fa fa-upload mr1'/>Bulk Status Change
	      								</Button>		
	      							</div>
									}
								</div>
							</RBAC>
						</div>
						<ScrollableDynamicTable
							setSearch={(search) => {
								setSearch({
									...search,
									page: 0,
								});
							}}
							search={search}
							tableStyle={{
								borderSpacing: 0,
							}}
							setPage={(page) => {
								setSearch({
									...search,
									page,
								});
							}}
							data={{
								current: Data.currentPage,
								total: Data.totalPages,
								items: Data.items.map((item) => {
									const click = () => {
										onClick(item);
									};
									return {
										content: item,
										render: [
											{
												children: (
													<div>
														<p className='text truncate'>{item.name}</p>
														<p className='text-small text-light truncate'>
															{item.email}
														</p>
													</div>
												),
												weight: 2,
											},
											{
												weight: 1,
												title: item.code,
											},
											{
												weight: 1,
												title: Moment(item.createdAt).format(
													'DD MMM YYYY HH:mm a'
												),
											},
											{
												weight: 1,
												children: <Balance item={item}/>,
											},
											...CONFIG.origin.code === "CLRTRP"?[
												{
													weight: 1,
													title: item.flightReseller?"Yes":"No",
												},
												{
													weight: 1,
													title: item.hotelReseller?"Yes":"No",
												},
											]:[],
											(isCreateClient || isClient)? {
													weight: 1,
													buttons: [
														{
															title: 'View',
															className: 'btn-secondary btn-sm',
															before: <OpenIcon size='small'/>,
															onClick: click,
														},
													],
													actions: [
														{
															title: 'Quick Actions',
															actions: [
																{
																	title: 'View',
																	before: <OpenIcon size='small'/>,
																	onClick: click,
																},

																...CONFIG?.origin?.code==="YESUAT"?[{
																	title: 'Edit',
																	before: <OpenIcon size='small'/>,
																	onClick: ()=> setModal(item),
																}]:[],
															],
														},
													],
												}
												: {},
										],
									};
								}),
							}}
							headers={
								(isCreateClient || isClient) ? HEADERS : HEADERS.slice(0, HEADERS.length - 1)
							}
						/>
					{statusModal && <BulkStatusChange setStatusModal={setStatusModal}/>}	
					</div>
				)}
			</div>
		</>
	);
}
