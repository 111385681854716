import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { ExpandableSidebar, TOAST as Alert } from 'retro/index';
import React, { useEffect, useState } from 'react';
import AllQueued from './AllQueued';
import VisaCompleted from './Completed';
import Unassigned from "./Unassigned"
import Assigned from './Assigned';
import { get } from 'App/Network/Axios';

export default function index() {
  const History = useHistory();
  const location = useLocation();
  const [assigned, setAssigned] = useState(0);
  const [unassigned, setUnAssigned] = useState(0);

  const [pending, setPending] = useState(0);
  const [completed, setCompleted] = useState(0);



  const getCount=()=>{

try {
  get(
    `/meta/visa/pending`,
    (e, r) => {
      if (r) {
        setPending(r.total);
      }
    },
    {
      offset: 0,
    }
  );

  get(
    `/meta/visa/pending/QUEUED`,
    (e, r) => {
      if (r) {
        setUnAssigned(r.total);
      }
    },
    {
      offset: 0,
    }
  );

  get(
    `/meta/visa/completed`,
    (e, r) => {
      if (r) {
        setCompleted(r.total);
      } else {
        Alert.handleError(e);
      }
    },
    {
      offset: 0,
    }

  );
} catch (error) {
  console.log(error)
  
}


  }

  useEffect(() => {
    getCount()
  }, []);
  return (
    <div className="flex h-100h w-100">
      <ExpandableSidebar
        items={[
          {
            title: 'My Actions',
            actions: [
              {
                title:
                  assigned === 0
                    ? 'Assigned to me'
                    : 'Assigned to me' + ' (' + assigned + ')',
                active:
                  location.pathname === '/app/apps/meta/visaQueue/assigned',
                onClick: () => {
                  History.push('/app/apps/meta/visaQueue/assigned');
                },
              },
            ],
          },
          {
            title: 'All Pending Request',
            actions: [
              {
                title:
                  pending === 0
                    ? 'All Pending Request'
                    : 'All Pending Request' + ' (' + pending + ')',
                active: location.pathname === '/app/apps/meta/visaQueue',
                onClick: () => {
                  History.push('/app/apps/meta/visaQueue');
                },
              },
              {
                title:
                unassigned === 0
                    ? 'Unassigned'
                    : 'Unassigned' + ' (' + unassigned + ')',
                active: location.pathname === '/app/apps/meta/visaQueue/unassigned',
                onClick: () => {
                  History.push('/app/apps/meta/visaQueue/unassigned');
                },
              },
              {
                title:
                  completed === 0
                    ? 'Visa Confirmed'
                    : 'Visa Confirmed' + ' (' + completed + ')',
                active:
                  location.pathname === '/app/apps/meta/visaQueue/completed',
                onClick: () => {
                  History.push('/app/apps/meta/visaQueue/completed');
                },
              },
            ],
          },
        ]}
      />
      <div
        className="flex-1 overScrollY pd6"
        style={{
          height: 'calc(100vh - 100px)',
        }}
      >
        <Switch>
          <Route path="/app/apps/meta/visaQueue" 
          component={(props) => <AllQueued {...props} getCount={getCount} />}

          exact />
        </Switch>
        <Switch>
          <Route
            path="/app/apps/meta/visaQueue/completed"
            component={VisaCompleted}
            exact
          />
        </Switch>
        <Switch>
          <Route
            path="/app/apps/meta/visaQueue/unassigned"
            component={(props) => <Unassigned {...props} getCount={getCount} />}

            exact
          />
        </Switch>
        <Switch>
          <Route
            path="/app/apps/meta/visaQueue/assigned"
            component={(props) => <Assigned {...props} getCount={getCount} />}

            exact
          />
        </Switch>
      </div>
    </div>
  );
}
