import React, {useState} from 'react'
import {NavDivider, Navigation, Tabs} from "../../../../retro";
import AllClients from "./allClients";
import InactiveClients from "./InactiveClients";

export default function Clients() {
	const [tab,setTab] = useState("all")
	return (
		<div>
            <Navigation backUrl={''} title="Clients" chips={['Clients']} description="All clients are listed below."/>
			<Tabs style={{marginBottom:'calc(-2rem - 1px)'}} className="" tabs={[
				{
					title:"Active",
					onClick:()=>{
						setTab("all")
					},
					active:tab==="all"
				},
				{
					title:"In-Active",
					onClick:()=>{
						setTab("inactive")
					},
					active:tab==="inactive"
				}
			]} active={tab}/>
			<NavDivider/>
			{
				tab==="all" && <AllClients/>
			}
			{
				tab==="inactive" && <InactiveClients/>
			}
		</div>
	)
}
