
function placeOfSupply(stateCode){
	if(stateCode==null)
		return "Unknown";
	stateCode = stateCode.substring(0,2);
	let state;
	switch (stateCode) {
		case "01":
			state = "Jammu and Kashmir";
			break;
		case "02":
			state = "Himachal Pradesh";
			break;
		case "03":
			state = "Punjab";
			break;
		case "04":
			state = "Chandigarh";
			break;
		case "05":
			state = "Uttarakhand";
			break;
		case "06":
			state = "Haryana";
			break;
		case "07":
			state = "Delhi";
			break;
		case "08":
			state = "Rajasthan";
			break;
		case "09":
			state = "Uttar Pradesh";
			break;
		case "10":
			state = "Bihar";
			break;
		case "11":
			state = "Sikkim";
			break;
		case "12":
			state = "Arunachal Pradesh";
			break;
		case "13":
			state = "Nagaland";
			break;
		case "14":
			state = "Manipur";
			break;
		case "15":
			state = "Mizoram";
			break;
		case "16":
			state = "Tripura";
			break;
		case "17":
			state = "Meghalaya";
			break;
		case "18":
			state = "Assam";
			break;
		case "19":
			state = "West Bengal";
			break;
		case "20":
			state = "Jharkhand";
			break;
		case "21":
			state = "Odisha";
			break;
		case "22":
			state = "Chhattisgarh";
			break;
		case "23":
			state = "Madhya Pradesh";
			break;
		case "24":
			state = "Gujarat";
			break;
		case "25":
			state = "Daman and Diu";
			break;
		case "26":
			state = "Dadra and Nagar Haveli";
			break;
		case "27":
			state = "Maharashtra";
			break;
		case "28":
			state = "Andhra Pradesh (Old)";
			break;
		case "29":
			state = "Karnataka";
			break;
		case "30":
			state = "Goa";
			break;
		case "31":
			state = "Lakshadweep Islands";
			break;
		case "32":
			state = "Kerala";
			break;
		case "33":
			state = "Tamil Nadu";
			break;
		case "34":
			state = "Puducherry";
			break;
		case "35":
			state = "Andaman and Nicobar Islands";
			break;
		case "36":
			state = "Telangana";
			break;
		case "37":
			state = "Andhra Pradesh";
			break;
		default:
			state = "Unknown";
			break;
	}
	return state;
}

export {placeOfSupply}
