import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Loader,
  ScrollableDynamicTable,
  SelectInput,
  TOAST,
  TOAST as Alert,
  Navigation,
} from "retro";
import { get, post } from "App/Network/Axios";
import OpenIcon from "@atlaskit/icon/glyph/open";
import ViewRequest from "../ViewRequest";
import Moment from "moment";
import Button from "retro/Button/Button";
import Modal from "retro/Modal/Modal";
import Input from "retro/Inputs/Input";
import { Highlight } from "App/Pages/App/MetaApis/HotelQueue/Queued/Utils";
import { DownloadCsv } from "retro/Utils";
import RBAC from "App/HOCs/RBAC";
import ROLES from "App/Constants/Roles";
import Filter from 'retro/Filter';
import FilterDropDown from 'App/Components/Filter/FilterDropDown'
import BulkPaymentPending from "./BulkUtrPay";

export default function queued() {
  const SELECT = useRef();
  const INPUT = useRef();
  const REMARKS = useRef();
  const [modal, setModal] = useState(false);
  let [loading, setLoading] = useState(true);
  const [id, setId] = useState(undefined);
  const [showBulkPay, setShowBulkPay]=useState(false); // state to show sidepane on bulk pay button click
  const [search, setSearch] = useState({
    page: 0,
    sort: "id",
    order: "desc",
  });
  const [allQueuData, setAllQueueData]=useState([]); //state to store all hotel queue data
  const [Data, SetData] = useState({
    total: 0,
    currentPage: 0,
    totalPages: 0,
    items: [],
  });
  const [filterModal,setFilterModal] = useState(false);
	const [filter, setFilter] = useState({
		client: [],
		checkin: []
	});

  useEffect(() => {
    get(`/meta/hotel/queue/completed/PAYMENT/all`, (e, r) => {
      if (r) {
        console.log("res", r);
       setAllQueueData(r.items);
      }
      if(e){
        TOAST.error(e);
      }
    })
  }, [])


  const loadPage = useCallback(
    (search) => {
      const clientIds = filter.client.map(client => client.id);
      get(
        `/meta/hotel/queue/completed/PAYMENT`,
        (e, r) => {
          if (r) {
            SetData({
              ...r,
              total: r.total || Data.total,
              totalPages: r.totalPages || Data.totalPages,
            });
            setLoading(false);
          } else {
            Alert.handleError(e);
          }
        },
        {
          offset: search.page,
          order: search.order,
          q: search.q,
          start: filter.checkin.length > 0 ?
              filter.checkin[0] === 'Upcoming 24 hours' ?
                Moment().valueOf().toString() : Moment().add(24, 'hours').valueOf().toString()
              : undefined,
          end: filter.checkin.length > 0 ?
            filter.checkin[0] === 'Upcoming 24 hours' ? Moment().add(24, 'hours').valueOf().toString() : Moment().add(48, 'hours').valueOf().toString()
            : undefined,
          clientIds: clientIds.length > 0 ? clientIds : undefined
        }
      );
    },
    [Data, SetData, filter.checkin, filter.client]
  );
  useEffect(() => {
    loadPage(search);
  }, [search, filter.checkin, filter.client]);
  if (loading) {
    return <Loader />;
  }
  return (
    <div
      style={{
        width: 'calc(100vw - 475px)',
      }}
    >
      {modal && (
        <Modal
          title='Add Payment Details'
          description='Enter payment details'
          onClose={() => {
            setModal(undefined);
          }}
        >
          <SelectInput
            ref={SELECT}
            options={[
              { label: "TripJack", value: "TripJack" },
              { label: "Razorpay", value: "Razorpay" },
              { label: "TravelPlus", value: "TravelPlus" },
              { label: "Others", value: "Others" },
            ]}
          />
          <Input ref={INPUT} label='UTR' placeholder='Enter utr details' />
          <Input ref={REMARKS} label='Remarks' placeholder='Enter remarks' />
          <Button
            onClick={() => {
              let mode = SELECT.current.value;
              let input = INPUT.current.value;
              let remarks = REMARKS.current.value;
              if (!mode) {
                TOAST.error("Kindly enter a valid mode");
                return;
              }
              if (!input) {
                TOAST.error("Kindly enter a valid utr");
                return;
              }
              if (!remarks) {
                TOAST.error("Kindly enter a valid remarks");
                return;
              }
              let data = {
                type: mode,
                utr: input,
                remarks,
              };
              post(
                `/meta/hotel/queue/${modal.queueId}/payment`,
                data,
                (e, r) => {
                  if (r) {
                    TOAST.success("Payment added successfully");
                    loadPage(search);
                    setModal(false);
                  } else {
                    TOAST.handleError(e);
                  }
                }
              );
            }}
          >
            Upload Payment Details
          </Button>
        </Modal>
      )}
      <div className='mb2 relative flex justify-between mb4'>
        <Navigation title='Payment Pending' />
        <RBAC role={ROLES.REPORTS}>
        <div className="flex">
          <Button
            onClick={() => {
              setLoading(true);
              get(`/meta/hotel/queue/completed/PAYMENT/all`, (e, r) => {
                if (r) {
                 setAllQueueData(r.items);
                  let data = [];
                  const headers = [
                    "Request Id",
                    "City",
                    "Requested Hotel Name",
                    "Requested Hotel Addres",
                    "Boooked Hotel Name",
                    "Booked Hotel Addres",
                    "Confirmation Number",
                    "GSTIN",
                    "Client Code",
                    "Guest Name",
                    "Guest Email",
                    "Entity",
                    "Travel Date",
                    "Confirm Booking Date",
                    "Request Date",
                  ];
                  data.push(headers);
                  r.items.forEach((item) => {
                    let line = [];
                    line.push([item.requestId]);
                    line.push([item?.hotel?.city || item?.ticket?.city || "-"]);
                    line.push(item?.hotel?.name || "-");
                    line.push(item?.hotel?.address || "-");
                    line.push(item?.ticket?.name || "-");
                    line.push(item?.ticket?.address || "-");
                    line.push(item?.ticket?.pnr || "-");
                    line.push([item.gstDetails?.gstin || "-"]);
                    line.push([item.client.clientCode]);
                    line.push([item.guests[0].name]);
                    line.push([item.guests[0].email]);
                    line.push(item.entity || "-");
                    line.push(
                      `${item.stayDetails.checkIn} - ${item.stayDetails.checkOut}`
                    );
					line.push(
						item.confirmBookingDate ? Moment(item.confirmBookingDate).format("DD MMM YYYY hh:mm A") : '-',
					)
                    line.push(
                      Moment(item.createdAt).format("DD MMM YYYY hh:mm A")
                    );
                    data.push(line);
                  });
                  DownloadCsv(
                    `payment_pending${Moment().format("DD_MM_YYYY")}}.csv`,
                    data
                  );
                }
                setLoading(false);
              });
            }}
            loading={loading}
            className='btn btn-primary mt0 btn-sm top-2 right-0'
            style={{ zIndex: 10 }}
          >
            <i className='fa fa-download mr1' />
            Download Data
          </Button>
       <Button className="btn btn-secondary mt0 ml2" onClick={()=>setShowBulkPay(true)} >
        <i className="fa fa-credit-card mr2 outline"/>
         Bulk UTR Pay
        </Button>
       </div>
        </RBAC>
      </div>
      <Filter
			margin="0 -4rem"
			onSearch={(q) => {
				setSearch({
					...search,
					page: 0,
					q,
				})
			}}
			onFilter={() => {
				setFilterModal(true)
			}}
		/>

		<FilterDropDown
			shown={filterModal}
			filters={filter}
			setFilters={setFilter}
			choices={{
				client: [],
				checkin: [
					{
						title: 'Upcoming 24 hours',
						type: 'radio'
					},
					{
						title: 'Upcoming 48 hours',
						type: 'radio'
					}
				]
			}}
			types={["Client", "CheckIn"]}
			onClose={() => {
				setFilterModal(false);
			}}
		/>
      <ScrollableDynamicTable
        setSearch={(search) => {
          setSearch({
            ...search,
            page: 0,
          });
        }}
        search={search}
        tableStyle={{
          borderSpacing: 0,
        }}
        setPage={(page) => {
          setSearch({
            ...search,
            page,
          });
        }}
        data={{
          current: Data.currentPage,
          total: Data.totalPages,
          items: Data.items.map((item) => {
            const click = () => {
              setId(item.queueId);
            };
            return {
              content: item,
              render: [
                {
                  children: (
                    <div>
                      <Highlight item={item} />
                      <p className='text'>
                        {item?.hotel?.city || item?.ticket?.city || "-"}
                      </p>

                      <p className='text-small text-light'>
                        {item?.hotel?.name || item?.ticket?.name || "-"}
                      </p>
                      <p className='text-small text-light'>
                        {item?.hotel?.address || item?.ticket?.address || "-"}
                      </p>
                    </div>
                  ),
                  weight: 1.5,
                },
                {
                  weight: 1.5,
                  children: (
                    <div>
                      <p>{item.client.clientCode}</p>
                      <p className='text-small'>{item.guests[0].name}</p>
                      <p className='text-small'>{item.guests[0].email}</p>
                    </div>
                  ),
                },
                {
                  weight: 1.5,
                  title: item.confirmBookingDate ? Moment(item.confirmBookingDate).format("DD MMM YYYY HH:mm a") : '-',
                },
                {
                  weight: 1,
                  title: Moment(item.createdAt).format("DD MMM YYYY HH:mm a"),
                },
                {
                  weight: 1,
                  children: (
                    <>
                      <Button
                        className='btn btn-sm btn-black'
                        onClick={() => {
                          setModal(item);
                        }}
                      >
                        Add Payment
                      </Button>
                    </>
                  ),
                },
                {
                  weight: 1,
                  buttons: [
                    {
                      title: "View",
                      className: "btn-secondary btn-sm",
                      before: <OpenIcon size='small' />,
                      onClick: click,
                    },
                  ],
                  actions: [
                    {
                      title: "Quick Actions",
                      actions: [
                        {
                          title: "View",
                          before: <OpenIcon size='small' />,
                          onClick: click,
                        },
                      ],
                    },
                  ],
                },
              ],
            };
          }),
        }}
        headers={[
          {
            weight: 1.5,
            title: "Details",
          },
          {
            weight: 1.5,
            title: "Client",
          },
          {
            weight: 1.5,
            title: "Confirm Booking Date",
          },
          {
            weight: 1,
            title: "Request Date",
          },
          {
            weight: 1,
            title: "Payment",
          },
          {
            weight: 1,
            title: "Actions",
            style: {
              justifyContent: "end",
            },
          },
        ]}
      />
      {id !== undefined && (
        <ViewRequest
          id={id}
          onClose={() => {
            setId(undefined);
            loadPage(search);
          }}
        />
      )}

    {showBulkPay &&
    <BulkPaymentPending allQueuData={allQueuData} setShowBulkPay={setShowBulkPay} loadPage={loadPage} search={search}/>
    }

    </div>
  );
}
